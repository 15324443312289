import { GetNormalizedAddressInput, GetNormalizedAddressResponse, Location } from '../../../models/gen/graphql';

import { GetNormalizedAddressDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';
import { replaceTokens } from '../../../utils/strings';

type GetNormalizedAddressGraphApiResponse = GraphApiResponse<typeof GetNormalizedAddressDocument>;

const title = 'Normalize Address';
const getNormalizedAddressResponseSelector = (res: GetNormalizedAddressGraphApiResponse): GetNormalizedAddressResponse =>
  res?.getNormalizedAddress;
const convertNormalizedAddressResponseToNormalizedAddress = (input: GetNormalizedAddressResponse): NormalizedAddress => ({
  address: input?.street,
  cityName: input?.city,
  stateCode: input?.stateAbbrev,
  zipCode: input?.postalCode,
  coordinates: {
    lat: input?.geoLocationLat,
    lng: input?.geoLocationLng,
  },
});

export const [runGetNormalizedAddress] = graphApi(GetNormalizedAddressDocument, {
  onError: (res: GetNormalizedAddressGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export type NormalizedAddress = Pick<Location, 'address' | 'cityName' | 'stateCode' | 'zipCode' | 'coordinates'>;
const getNormalizedAddress = async (
  location: Pick<Location, 'address' | 'cityName' | 'stateCode' | 'zipCode'>
): Promise<NormalizedAddress> => {
  const address = replaceTokens('{{address}}, {{cityName}}, {{stateCode}}, {{zipCode}}', location);
  const input: GetNormalizedAddressInput = { address };

  const res = await runGetNormalizedAddress({ input });
  const selected = getNormalizedAddressResponseSelector(res);

  if (!selected) return;

  return convertNormalizedAddressResponseToNormalizedAddress(selected);
};

export default getNormalizedAddress;

import { GET_GROUPS } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectGroup = (props) => (
  <SelectFromQuery
    cache="Groups"
    placeholder="All Groups"
    query={GET_GROUPS}
    selector={(data) => {
      return (data?.getFilters?.filters?.groups || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }));
    }}
    {...props}
  />
);

export default SelectGroup;

import { createPersistentComponentState } from '@/state';

export type SettingsState = {
  filters: Record<string, SettingsStateFilters>;
  columns: Record<string, SettingsStateColumns>;
  headings: Record<string, SettingsStateHeadings>;
  details: Record<string, SettingsStateDetails>;
};
export type SettingsStateFilters = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStateColumns = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStateHeadings = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStateDetails = {
  remember: boolean;
  saved: Record<string, unknown>;
};

const initSettingsState: SettingsState = {
  filters: {},
  columns: {},
  headings: {},
  details: {},
};

const useSettings = createPersistentComponentState('settings', initSettingsState);

export default useSettings;

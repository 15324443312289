import React from 'react';
import SelectFromQuery from '../SelectFromQuery';
import getFcrTypes from '../../queries/fcrs/getFcrTypes';

const SelectFcrReason = (props) => (
  <SelectFromQuery
    cache="FcrTypes"
    placeholder="Reason"
    query={getFcrTypes.query}
    selector={(data) =>
      (data?.searchFcrTypes?.fcrTypeConnection?.edges || []).map((opt) => ({
        label: opt?.node?.name,
        value: opt?.node?.name,
      }))
    }
    {...props}
  />
);

export default SelectFcrReason;

import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError, queryInput } from '@/utils/custom';
import { UpdateCombineRuleInput, UpdateCombineRuleValuesInput } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { UpdateCombineRuleBulkDocument } from '@/api/queries';
import graphApi from '@/api';

export type CombineRuleUpdate = Partial<UpdateCombineRuleValuesInput> & { id: string };
// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Update Combine Rule Setting' } });

// Service Function Signature
type ServiceMethodPayload = CombineRuleUpdate[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// UpdateCombineRuleBulk Service
export const { updateCombineRuleBulk, useUpdateCombineRuleBulk } = ((): {
  updateCombineRuleBulk: ServiceMethod;
  useUpdateCombineRuleBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(UpdateCombineRuleBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof UpdateCombineRuleBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const updates: UpdateCombineRuleInput[] = payload.map(
          ({ id, ...update }): UpdateCombineRuleInput => ({
            query: [{ id: queryInput(id) }],
            value: { ...update },
          })
        );
        const res = await fn({ input: { updates } });
        return res;
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    updateCombineRuleBulk: mutate,
    useUpdateCombineRuleBulk: useService,
  };
})();

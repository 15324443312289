import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import {
  TripWithFcrInnerJoin,
  TripWithFcrInnerJoinConnection,
  TripWithFcrInnerJoinConnectionInput,
  TripWithFcrInnerJoinSearch,
} from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchTripsWithFcrDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type SearchTripsWithFcrGraphApiResponse = GraphApiResponse<typeof SearchTripsWithFcrDocument>;
type SearchTripsWithFcrOptions = { pageSize?: number; page?: number; merge?: true };

const SEARCH_FCRS_PAGE_SIZE = 1000;
const title = 'Search Complaints';

const searchTripsWithFcrResponseSelector = (res: SearchTripsWithFcrGraphApiResponse): ConnectionDetails<TripWithFcrInnerJoin> =>
  convertConnectionToDetails(res?.searchFcrs?.tripWithFcrInnerJoinConnection as TripWithFcrInnerJoinConnection);

const [runSearchTripsWithFcr, runRefetchSearchTripsWithFcr] = graphApi(SearchTripsWithFcrDocument, {
  onError: (res: SearchTripsWithFcrGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchTripsWithFcr = async (
  query: TripWithFcrInnerJoinSearch[],
  options: SearchTripsWithFcrOptions = {}
): Promise<ConnectionDetails<TripWithFcrInnerJoin>> => {
  const { pageSize = SEARCH_FCRS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: TripWithFcrInnerJoinConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query: query && !!Object.keys(query).length ? query : null,
  };

  const graphApiOptions = { merge: merge ? 'searchTripsWithFcr.tripWithFcrInnerJoinConnection' : undefined };
  const res = await runSearchTripsWithFcr({ input }, graphApiOptions);

  return searchTripsWithFcrResponseSelector(res);
};

const refetchSearchTripsWithFcr = async (
  query: TripWithFcrInnerJoinSearch[],
  options: SearchTripsWithFcrOptions = {}
): Promise<ConnectionDetails<TripWithFcrInnerJoin>> => {
  const { pageSize = SEARCH_FCRS_PAGE_SIZE, page = 0 } = options;

  const input: TripWithFcrInnerJoinConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query: query && !!Object.keys(query).length ? query : null,
  };

  const res = await runRefetchSearchTripsWithFcr({ input });

  return searchTripsWithFcrResponseSelector(res);
};

export const useSearchTripsWithFcr = createGraphApiHook(searchTripsWithFcr, {
  refetch: refetchSearchTripsWithFcr,
  merge: mergeConnectionDetails,
});

export default searchTripsWithFcr;

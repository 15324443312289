import './styles.scss';

import AirlinesTable from './AirlinesTable';

const Airlines = () => (
  <div className="Airlines page-container">
    <AirlinesTable />
  </div>
);

export default Airlines;

import React, { useMemo } from 'react';

import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { cleanString } from '../../utils';

type Props = {
  name: string;
  value: string;
  mask: any;
  placeholder?: string;
  onChange: any;
  className?: string;
  disabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
};
const MaskedInput: (a: Props) => JSX.Element = ({
  name = '',
  value: initialValue,
  mask = '',
  placeholder,
  onChange = (): void => {},
  className = 'MaskedInput',
  disabled = false,
  isValid,
  isInvalid,
}: Props): JSX.Element => {
  const value = useMemo((): string => cleanString(initialValue || ''), [initialValue]);
  const handleOnChange = (event: any): void => {
    const { name, value } = event.target;
    onChange({ target: { name, value: cleanString(value) } });
  };

  return (
    <InputMask name={name} value={value} disabled={disabled} placeholder={placeholder} onChange={handleOnChange} mask={mask}>
      {(inputProps: any): JSX.Element => (
        <Form.Control {...inputProps} isValid={isValid} isInvalid={isInvalid} className={`${className}`} disabled={disabled} />
      )}
    </InputMask>
  );
};

export default MaskedInput;

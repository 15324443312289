import { GetCorrectRatesForTripsInput, TripWithRateChange } from '../../../models/gen/graphql';

import { GetCorrectRatesForTripsDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';

export interface TripWithRateChangeRow extends TripWithRateChange {
  id: string;
}
type GetCorrectRatesForTripsGraphApiResponse = GraphApiResponse<typeof GetCorrectRatesForTripsDocument>;

const title = 'Get Suggested Rates';

const getCorrectRatesForTripsResponseSelector = (res: GetCorrectRatesForTripsGraphApiResponse): TripWithRateChangeRow[] =>
  !res.errors
    ? (res?.getCorrectRatesForTrips?.output || []).map(
        (row: TripWithRateChange): TripWithRateChangeRow => ({
          ...row,
          id: row?.trip?.id,
        })
      )
    : undefined;

const [_runGetCorrectRatesForTrips, runRefetchGetCorrectRatesForTrips] = graphApi(GetCorrectRatesForTripsDocument, {
  onError: (res: GetCorrectRatesForTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const getCorrectRatesForTrips = async (tripIds: string[]): Promise<TripWithRateChangeRow[]> => {
  const input: GetCorrectRatesForTripsInput = {
    tripIds,
  };
  const res = await runRefetchGetCorrectRatesForTrips({ input });
  return getCorrectRatesForTripsResponseSelector(res);
};

export const useGetCorrectRatesForTrips = createGraphApiHook(getCorrectRatesForTrips);

export default getCorrectRatesForTrips;

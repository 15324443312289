import ConfirmationButton, { ConfirmationButtonProps } from '../ConfirmationButton';
import FormButton, { FormButtonProps } from '@/components/FormButton';
import React, { useState } from 'react';

type PromiseButtonState = {
  loading: boolean;
};

type PromiseButtonProps = FormButtonProps & {
  onClick?: () => Promise<any>;
  onFinish?: (data: any) => Promise<any>;
};

const initPromiseButtonState: PromiseButtonState = {
  loading: false,
};

const PromiseButton = ({ onClick, onFinish, ...props }: PromiseButtonProps) => {
  const [state, setState] = useState<PromiseButtonState>(initPromiseButtonState);

  const handlePromise = async (): Promise<void> => {
    let result = undefined;
    try {
      setState((current) => ({ ...current, loading: true }));
      result = await onClick?.();
    } catch (e) {
      result = e;
    } finally {
      setState((current) => ({ ...current, loading: false }));
      onFinish?.(result);
    }
  };

  return <FormButton {...props} loading={state.loading} onClick={handlePromise} />;
};

PromiseButton.Confirmation = ({
  onClick,
  onFinish,
  ...props
}: PromiseButtonProps & Pick<ConfirmationButtonProps, 'options'>): React.JSX.Element => {
  const [state, setState] = useState<PromiseButtonState>(initPromiseButtonState);

  const handlePromise = async (): Promise<void> => {
    let result = undefined;
    try {
      setState((current: PromiseButtonState): PromiseButtonState => ({ ...current, loading: true }));
      result = await onClick?.();
    } catch (e) {
      result = e;
    } finally {
      setState((current: PromiseButtonState): PromiseButtonState => ({ ...current, loading: false }));
      onFinish?.(result);
    }
  };

  return <ConfirmationButton {...props} loading={state.loading} onConfirm={handlePromise} />;
};

export default PromiseButton;

import { GetTripsWithWrongContractedRateInput, TripWithRateChange } from '../../../models/gen/graphql';

import { GetTripsWithWrongContractedRateDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { TripWithRateChangeRow } from './getCorrectRatesForTrips';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';

type GetTripsWithWrongContractedRateGraphApiResponse = GraphApiResponse<typeof GetTripsWithWrongContractedRateDocument>;
const getTripsWithWrongContractedRateResponseSelector = (res: GetTripsWithWrongContractedRateGraphApiResponse): TripWithRateChangeRow[] =>
  !res.errors
    ? (res?.getTripsWithWrongContractedRate?.output || []).map(
        (row: TripWithRateChange): TripWithRateChangeRow => ({
          ...row,
          id: row?.trip?.id,
        })
      )
    : undefined;

const title = 'Get Contract Rates';

const [runGetTripsWithWrongContractedRate, runRefetchGetTripsWithWrongContractedRate] = graphApi(GetTripsWithWrongContractedRateDocument, {
  onError: (res: GetTripsWithWrongContractedRateGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const getTripsWithWrongContractedRate = async (query: GetTripsWithWrongContractedRateInput = null): Promise<TripWithRateChangeRow[]> => {
  try {
    const res = await runGetTripsWithWrongContractedRate({
      input: query,
    });

    return getTripsWithWrongContractedRateResponseSelector(res);
  } catch (err) {
    handleError(err, { notification: { title: 'Contracted Rates' } });
  }
};
const refetchTripsWithWrongContractedRate = async (
  query: GetTripsWithWrongContractedRateInput = null
): Promise<TripWithRateChangeRow[]> => {
  try {
    const res = await runRefetchGetTripsWithWrongContractedRate({
      input: query,
    });

    return getTripsWithWrongContractedRateResponseSelector(res);
  } catch (err) {
    handleError(err, { notification: { title: 'Refetch Contracted Rates' } });
  }
};

export const useGetTripsWithWrongContractedRate = createGraphApiHook(getTripsWithWrongContractedRate, {
  refetch: refetchTripsWithWrongContractedRate,
});

export default getTripsWithWrongContractedRate;

import Dashboard from '../../components/Dashboard';
import React from 'react';

const DashboardSandbox = (): JSX.Element => {
  // const [dashboard] = useLocalDashboard('1234');
  return (
    <>
      {/* <pre>{JSON.stringify(dashboard, null, 2)}</pre> */}
      <Dashboard id="1234" />
    </>
  );
};

export default DashboardSandbox;

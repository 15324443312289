import React from 'react';
import { Link } from 'react-router-dom';
import Lottie from '../../components/Lottie';
import './styles.scss';

const NotFound = ({ ...props }) => (
  <div className="NotFound">
    <div>
      <Lottie anim="not_found" />
      <div>
        <p>
          Click <Link to="/">here</Link> to return to the main dashboard.
        </p>
      </div>
    </div>
  </div>
);

export default NotFound;

import React, { useEffect, useRef } from 'react';

import { Container } from 'react-bootstrap';
import useEvent from 'hooks/useEvent';

type Props = {
  name: string;
  className?: string;
  children: any;
};

const ScrollableContainer = ({ name, className, children }: Props): JSX.Element => {
  const ref = useRef(undefined);

  const triggerOnScroll = useEvent('page-scroll');
  useEvent('scroll-page', (payload: (target: any) => ScrollToOptions): void => {
    console.log('Scrolling container...');
    ref.current.scrollTo(payload(ref.current));
  });
  useEffect((): any => {
    if (!ref.current) return;
    const handler = ({ target: { scrollTop = 0, offsetHeight = 0, scrollHeight = 0 } = {} }: any): void => {
      triggerOnScroll({
        percent: Math.round(((scrollTop + offsetHeight) / scrollHeight) * 100),
        position: scrollTop,
        height: scrollHeight,
      });
    };
    ref.current.addEventListener('scroll', handler);
    return (): void => {
      if (!ref.current) return;
      ref.current.removeEventListener('scroll', handler);
    };
  }, [ref.current]);

  return (
    <Container className={`${name}${className ? ` ${className}` : ''}`} fluid ref={ref}>
      {children}
    </Container>
  );
};

export default ScrollableContainer;

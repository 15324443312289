import InvoiceHistoryTable from './InvoiceHistoryTable';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import ScrollableContainer from '../../components/ScrollableContainer';

const InvoiceHistory = () => (
  <ProtectedRoute title="InvoiceHistory">
    <ScrollableContainer name="InvoiceHistory" className="page-container">
      <InvoiceHistoryTable />
    </ScrollableContainer>
  </ProtectedRoute>
);

export default InvoiceHistory;

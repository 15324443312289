import React from 'react';
import SelectFromQuery from '@/components/SelectFromQuery';
import { SelectUserFilterDocument } from '@/api/queries';
import { User } from '@/models/gen/graphql';

const SelectUser = (props: any): React.JSX.Element => (
  <SelectFromQuery
    name="users"
    placeholder="User"
    {...props}
    query={SelectUserFilterDocument}
    selector={(data: any): { label: string; value: string }[] =>
      (data?.searchUsers?.userWithAirportConnection?.edges || [])
        .sort((a, b) => {
          if (a.node.fullName.trim().toLowerCase() < b.node.fullName.trim().toLowerCase()) {
            return -1;
          }
          if (a.node.fullName.trim().toLowerCase() > b.node.fullName.trim().toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map(({ node }: { node: User }): { label: string; value: string } => ({
          label: node?.fullName,
          value: node.id,
        }))
    }
  />
);

export default SelectUser;

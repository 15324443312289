import FormField from '../FormField';
import { RateTypeEnum } from '../../models/gen/graphql';
import React from 'react';
import SelectFromEnum from '../SelectFromEnum';

const { Adhoc: _adhoc, Override: _override, ...RateTypeFiltersEnum } = RateTypeEnum;
const SelectRateType = (props: any): JSX.Element => (
  <FormField
    name="type"
    locale={{
      Noncontract: 'Non-Contract',
    }}
    {...props}
    source={RateTypeFiltersEnum}
    options={{
      input: {
        as: SelectFromEnum,
      },
    }}
  />
);

export default SelectRateType;

import FormField, { FormFieldModel } from '../FormField';

import { Point } from '../../models/gen/graphql';
import React from 'react';
import { Validation } from '../../utils/validations';
import { onEnter } from '../../utils/events';
import { parseCoordinates } from '../../utils/strings';

type Coordinates = {
  lat: number;
  lng: number;
  radius?: number;
};
type Props = {
  value: Coordinates;
  onChange: (event: { target: { name: string; value: Coordinates } }) => void;
  name: string;
  disabled?: boolean;
  options?: {
    radius?: FormFieldModel.Options;
    coordinates?: FormFieldModel.Options;
  };
  radius?: boolean;
};
const CoordinatesInput = ({ value, onChange, name, radius, disabled, options = {} }: Props) => {
  const { lat, lng } = value;
  const hasCoordinates = Validation.isNumber(lat) || Validation.isNumber(lng);
  const formattedCoordinates = hasCoordinates ? `${lat || 0}, ${lng || 0}` : '';
  const convertStringToPoint = (str: string): Point => {
    const [lat, lng] = parseCoordinates(str);
    const output: Point = {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    };
    if (Validation.isNumber(lat)) output.lat = lat;
    if (Validation.isNumber(lng)) output.lng = lng;
    return output;
  };
  const handleChange = (e) => {
    const { name: inputName, value: inputValue } = e.target;
    if (inputName === 'coordinates') {
      return onChange({ target: { name, value: { ...value, ...convertStringToPoint(inputValue) } } });
    } else if (inputName === 'radius') {
      let output: number;
      output = Math.round(parseInt(inputValue));
      if (!Validation.isNumber(output)) output = value?.radius || 0;
      return onChange({ target: { name, value: { ...value, radius: output } } });
    }
    onChange({ target: { name, value: { ...value, [inputName]: inputValue } } });
  };
  return (
    <>
      {(radius !== undefined || !!radius) && (
        <FormField
          name="radius"
          label="radius (m):"
          type="number"
          value={(value?.radius || 0).toFixed(0)}
          onChange={handleChange}
          placeholder="Radius"
          options={{ group: { className: '{max-width:100px;}' }, ...(options?.radius || {}) }}
          disabled={disabled}
          condensed
        />
      )}
      <FormField
        name="coordinates"
        label="lat & lng:"
        defaultValue={formattedCoordinates}
        onChange={() => {}}
        onBlur={handleChange}
        onKeyDown={onEnter(handleChange)}
        placeholder="Lat, Lng"
        options={{ group: { className: '{flex-grow:1;}' }, ...(options?.coordinates || {}) }}
        condensed
        disabled={disabled}
        key={formattedCoordinates}
      />
    </>
  );
};

export default CoordinatesInput;

import { useCallback, useRef } from 'react';
import useSocket from '..';
import { useThrottledCallback } from '@/hooks/useThrottledCallback';

const useChatMethods = () => {
  const socket = useSocket();
  const lastFormat = useRef(null);

  const sendMessage = useCallback((payload?: Record<string, unknown>): void => socket.emit('chat.sendMessage', payload), [socket]);
  const getUsersTyping = useThrottledCallback((payload: { user_id: string; room_id: string }): void => {
    socket.emit('chat.sendUserTyping', payload);
  }, 3000);
  const getHistory = useCallback(
    (payload?: Record<string, unknown>): void => {
      socket.emit('chat.getHistory', payload);
    },
    [socket]
  );
  const getRooms = useCallback(
    (payload?: any): void => {
      lastFormat.current = payload?.format;
      socket.emit('chat.getMetadata', payload);
    },
    [socket]
  );

  return { sendMessage, getUsersTyping, getHistory, getRooms };
};

export default useChatMethods;

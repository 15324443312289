import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdateImportProviderDocument } from '../../queries';
import { UpdateImportProviderResponse } from '../../../models/gen/graphql';
import graphApi from '../..';

type UpdateImportProviderGraphApiResponse = GraphApiResponse<typeof UpdateImportProviderDocument>;

const title = 'Update Import Provider';

const updateImportProviderResponseSelector = (res: UpdateImportProviderGraphApiResponse): UpdateImportProviderResponse =>
  !res.errors ? res?.updateImportProvider || {} : undefined;

const [runUpdateImportProvider] = graphApi(UpdateImportProviderDocument, {
  onError: (res: UpdateImportProviderGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateImportProvider = async (importId: string, providerId: string): Promise<UpdateImportProviderResponse> => {
  const input = { importId, providerId };
  const res = await runUpdateImportProvider({ input });

  const selected = updateImportProviderResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export default updateImportProvider;

import {
  bindMethods,
  generateSlice,
  getCookies,
  getLocalStorage,
  getResultFromState,
  parseStoredReduxState,
  replaceTokens,
  setLocalStorage,
} from 'utils';
import { useDispatch, useSelector } from 'react-redux';

import { GetPortalConfigResponse } from '../models/gen/graphql';
import Logger from '../utils/logs';
import config from '../config.json';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useEffect } from 'react';
import { useGetPortalConfig } from '../api/services/config/getPortalConfig';

// TODO: Expand on this type to make it more detailed where needed.
type AppState = {
  session: any;
  config: any;
  settings: any;
};

const log = Logger.of('AppState');

let env = 'local';
Object.entries(config.env || {}).forEach(
  ([name = '', domains = []]: [string, any]): string => (env = domains.includes(window.location.host) ? name : env)
);
window['__config__'] = Object.freeze({
  ...config,
  ...(window?.['__config__'] || {}),
  env: env,
  'client-api-url': replaceTokens(config['client-api-url'], { env }),
  'chat-api-url': replaceTokens(config['chat-api-url'], { env }),
});

const persistConfig = {
  key: 'app',
  version: 1,
  storage,
  blacklist: [],
};
const initAppState: AppState = {
  session: undefined,
  config: window['__config__'],
  settings: {},
};
const appSlice = generateSlice('app', initAppState);

const useAppState = (selector: any = (fullState: AppState): AppState => fullState): any[] => {
  const { _persist = {}, ...app } = useSelector((state: any): any => state?.app || {});
  const state = selector(app || {});
  const { config: { auth = undefined } = {} } = app;
  const dispatch = useDispatch();
  const [{ loading: loadingConfig }, { fetch: getPortalConfig }] = useGetPortalConfig();

  const setState = bindMethods(
    (payload: any): any => dispatch(appSlice.actions.setState({ ...getResultFromState(payload, state), _persist })),
    dispatch
  );

  const getAuth = (): void => {
    log.info('fetching config...');
    getPortalConfig().then((res: GetPortalConfigResponse): void => {
      const config = res || { auth: {} };
      window['__config__'] = Object.freeze({
        ...(window?.['__config__'] || {}),
        ...config,
      });
      setState(
        (current: AppState): AppState => ({
          ...current,
          config: { ...(current?.config || {}), ...(window['__config__'] || {}), ...config },
        })
      );
    });
  };

  useEffect((): void => {
    if (auth) return;
    const { token } = getCookies('token');
    if (!token) return;
    log.warn('config auth is not defined.');
    getAuth();
  }, [auth]);

  return [state, setState, !!loadingConfig];
};

// This is a basic "hook" format for use in functions, outside of React Components.
const appState = (selector: any = (fullState: any): any => fullState): any => {
  const stored = getLocalStorage('persist:app', initAppState);
  const { _persist = {}, ...app } = parseStoredReduxState(stored);
  const state = selector(app || {});
  const setState = (payload: any): void => setLocalStorage('persist:app', { ...getResultFromState(payload, state), _persist });
  return [state, setState];
};

export { useAppState, appState };
export default persistReducer(persistConfig, appSlice.reducer);

import { InvoiceInput, RunPreviewInvoiceInput, RunPreviewInvoiceResponse } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { RunPreviewInvoiceDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';

type RunPreviewInvoiceGraphApiResponse = GraphApiResponse<typeof RunPreviewInvoiceDocument>;

const title = 'Preview Invoice';

const runPreviewInvoiceResponseSelector = (res: RunPreviewInvoiceGraphApiResponse): RunPreviewInvoiceResponse =>
  res?.runPreviewInvoice as RunPreviewInvoiceResponse;

export const [runRunPreviewInvoice, runRefetchRunPreviewInvoice] = graphApi(RunPreviewInvoiceDocument, {
  onError: (res: RunPreviewInvoiceGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runPreviewInvoice = async (invoice: InvoiceInput): Promise<RunPreviewInvoiceResponse> => {
  const input: RunPreviewInvoiceInput = { input: invoice };

  const res = await runRefetchRunPreviewInvoice({ input });
  return runPreviewInvoiceResponseSelector(res);
};

export const useRunPreviewInvoice = createGraphApiHook(runPreviewInvoice);

export default runPreviewInvoice;

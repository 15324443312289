// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterDrawer.DetailsDrawer .FooterDrawer-Content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Reports/DynamicReport/components/DetailsDrawer/styles.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ","sourcesContent":[".FooterDrawer.DetailsDrawer .FooterDrawer-Content {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { apolloProvider } from './provider';
import graphApiCore from './core';

// This file exists mainly just to establish our global instanec of GraphApi.
// The only time a change to this file should need to be made is if we decide
// to use or add another, Provider to be used for another purpose.

const graphApi = graphApiCore(apolloProvider);

export default graphApi;

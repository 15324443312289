import {
  CreateRateBulkInput,
  CreateRateBulkResponse,
  CreateRateInput,
  Rate,
  RateCurrencyEnum,
  RateTypeEnum,
} from '../../../models/gen/graphql';
import { Datetime, Validation } from '../../../utils';
import { createNotification, handleError } from '../../../utils/custom';

import { CreateRateBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type CreateRateBulkGraphApiResponse = GraphApiResponse<typeof CreateRateBulkDocument>;

const title = 'Create Rates';

export const RATES_VALIDATION_CRITERIA = {
  airportCode: (val: any): Validation.Validity => ({ valid: true }),
  'companyId!': (val: any): Validation.Validity => ({
    valid: undefined,
    defaultValue: '',
  }),
  contractId: (val: any): Validation.Validity => ({ valid: undefined }),
  'currency!': (val: any): Validation.Validity => ({
    valid: Object.values(RateCurrencyEnum).includes(val),
    defaultValue: RateCurrencyEnum.Usd,
  }),
  duration: (val: any): Validation.Validity => ({ valid: undefined, defaultValue: 0 }),
  'endDatetime!': (val: any): Validation.Validity => ({ valid: !!val }),
  name: (val: any): Validation.Validity => ({ valid: val ? true : undefined, defaultValue: '' }),
  notes: (val: any): Validation.Validity => ({ valid: undefined, defaultValue: '' }),
  payerProviderId: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val) ? true : undefined,
    defaultValue: null,
  }),
  'radius!': (val: any): Validation.Validity => ({ valid: undefined, defaultValue: 0 }),
  'rate!': (val: any): Validation.Validity => ({ valid: !Validation.isNil(val) && !isNaN(val), defaultValue: 0 }),
  'startDatetime!': (val: any): Validation.Validity => ({ valid: !!val }),
  'thatLocationId!': (val: any): Validation.Validity => ({ valid: !!val }),
  'thisLocationId!': (val: any): Validation.Validity => ({ valid: !!val }),
  tripDistance: (val: any): Validation.Validity => ({ valid: undefined, defaultValue: 0 }),
  'type!': (val: any): Validation.Validity => ({ valid: Object.values(RateTypeEnum).includes(val) }),
};
export const createRateValidator = new Validation.Validator(RATES_VALIDATION_CRITERIA);

export const enforceRateDates = (rate: Partial<CreateRateInput>): Partial<CreateRateInput> => {
  const result = ({ ...rate } = rate);
  if (rate.startDatetime) result.startDatetime = new Datetime(rate.startDatetime).setTime('00:00:00').toString();
  if (rate.endDatetime) result.endDatetime = new Datetime(rate.endDatetime).setTime('23:59:59').toString();
  return result;
};

const createRateBulkResponseSelector = (res: CreateRateBulkGraphApiResponse): CreateRateBulkResponse =>
  !res.errors ? (res?.createRateBulk as CreateRateBulkResponse) : undefined;

const [runCreateRateBulk] = graphApi(CreateRateBulkDocument, {
  onError: (res: CreateRateBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createRateBulk = async (rates: Partial<Rate>[]): Promise<CreateRateBulkResponse> => {
  const values: CreateRateInput[] = rates.map((rate: Rate): CreateRateInput => {
    const rateWithAdjustedDates = enforceRateDates(rate);
    return createRateValidator.create(rateWithAdjustedDates);
  });
  const input: CreateRateBulkInput = { values };

  const res = await runCreateRateBulk({ input });
  const selected = createRateBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateRateBulk = createGraphApiHook(createRateBulk);

export default createRateBulk;

import { RunRateAmountOnTripInput, RunRateAmountOnTripResponse, RunRateAmountOnTripsInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { GraphApiResponse } from '@/api/core';
import { RunRateAmountOnTripsDocument } from '@/api/queries';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type RunRateAmountOnTripsGraphApiResponse = GraphApiResponse<typeof RunRateAmountOnTripsDocument>;

const title = 'Update Trip Rate';

const runRateAmountOnTripsResponseSelector = (res: RunRateAmountOnTripsGraphApiResponse): RunRateAmountOnTripResponse[] =>
  !res.errors ? ((res?.runRateAmountOnTrips?.output || []) as RunRateAmountOnTripResponse[]) : undefined;

const [runRunRateAmountOnTrips] = graphApi(RunRateAmountOnTripsDocument, {
  onError: (res: RunRateAmountOnTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runRateAmountOnTrips = async (payload: RunRateAmountOnTripInput[]): Promise<RunRateAmountOnTripResponse[]> => {
  const input: RunRateAmountOnTripsInput = {
    input: payload,
  };
  const res = await runRunRateAmountOnTrips({ input });
  const selected = runRateAmountOnTripsResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useRunRateAmountOnTrips = createGraphApiHook(runRateAmountOnTrips);

export default runRateAmountOnTrips;

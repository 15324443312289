import {
  DeleteUserAvailabilityAndUnassignBulkDocument,
  DeleteUserAvailabilityBulkDocument,
  DeleteUserAvailabilitySeriesAndUnassignBulkDocument,
  DeleteUserAvailabilitySeriesBulkDocument,
} from '@/api/queries';
import {
  DeleteUserAvailabilityBulkInput,
  DeleteUserAvailabilityBulkResponse,
  DeleteUserAvailabilityInput,
  UserAvailability,
} from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import Logger from '@/utils/logs';
import { Toast } from '@/models';
import graphApi from '@/api';

const log = Logger.of('DeleteUserAvailabilityBulk');

type DeleteUserAvailabilityBulkGraphApiResponse = GraphApiResponse<typeof DeleteUserAvailabilityBulkDocument>;
type DeleteUserAvailabilitySeriesBulkGraphApiResponse = GraphApiResponse<typeof DeleteUserAvailabilitySeriesBulkDocument>;
type DeleteUserAvailabilityAndUnassignBulkGraphApiResponse = GraphApiResponse<typeof DeleteUserAvailabilityAndUnassignBulkDocument>;
type DeleteUserAvailabilitySeriesAndUnassignBulkGraphApiResponse = GraphApiResponse<
  typeof DeleteUserAvailabilitySeriesAndUnassignBulkDocument
>;

const deleteUserAvailabilityBulkResponseSelector = (res: DeleteUserAvailabilityBulkGraphApiResponse): DeleteUserAvailabilityBulkResponse =>
  !res.errors ? res?.deleteUserAvailabilityBulk : undefined;
const deleteUserAvailabilitySeriesBulkResponseSelector = (
  res: DeleteUserAvailabilitySeriesBulkGraphApiResponse
): DeleteUserAvailabilityBulkResponse => (!res.errors ? res?.deleteUserAvailabilitySeriesBulk : undefined);
const deleteUserAvailabilityAndUnassignBulkResponseSelector = (
  res: DeleteUserAvailabilityAndUnassignBulkGraphApiResponse
): DeleteUserAvailabilityBulkResponse => (!res.errors ? res?.deleteUserAvailabilityAndUnassignBulk : undefined);
const deleteUserAvailabilitySeriesAndUnassignBulkResponseSelector = (
  res: DeleteUserAvailabilitySeriesAndUnassignBulkGraphApiResponse
): DeleteUserAvailabilityBulkResponse => (!res.errors ? res?.deleteUserAvailabilitySeriesAndUnassignBulk : undefined);

const [runDeleteUserAvailabilityBulk] = graphApi(DeleteUserAvailabilityBulkDocument, {
  onError: (res: DeleteUserAvailabilityBulkGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Delete User Availability' } }),
});
const [runDeleteUserAvailabilitySeriesBulk] = graphApi(DeleteUserAvailabilitySeriesBulkDocument, {
  onError: (res: DeleteUserAvailabilitySeriesBulkGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Delete User Availability' } }),
});
const [runDeleteUserAvailabilityAndUnassignBulk] = graphApi(DeleteUserAvailabilityAndUnassignBulkDocument, {
  onError: (res: DeleteUserAvailabilityAndUnassignBulkGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Delete User Availability And Unassign' } }),
});
const [runDeleteUserAvailabilitySeriesAndUnassignBulk] = graphApi(DeleteUserAvailabilitySeriesAndUnassignBulkDocument, {
  onError: (res: DeleteUserAvailabilitySeriesAndUnassignBulkGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Delete User Availability and Unassign' } }),
});

const deleteUserAvailabilityBulk = async (ids: string[]): Promise<DeleteUserAvailabilityBulkResponse> => {
  const input: DeleteUserAvailabilityBulkInput = {
    search: ids.map((id: string): DeleteUserAvailabilityInput => ({ id })),
  };
  const res = await runDeleteUserAvailabilityBulk({ input });

  const selected = deleteUserAvailabilityBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Delete User Availability');
  return selected;
};

export const deleteUserAvailabilitySeriesBulk = async (ids: string[]): Promise<DeleteUserAvailabilityBulkResponse> => {
  const input: DeleteUserAvailabilityBulkInput = {
    search: ids.map((id: string): DeleteUserAvailabilityInput => ({ id })),
  };
  const res = await runDeleteUserAvailabilitySeriesBulk({ input });

  const selected = deleteUserAvailabilitySeriesBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Delete User Availability');
  return selected;
};

export const deleteUserAvailabilityAndUnassignBulk = async (ids: string[]): Promise<DeleteUserAvailabilityBulkResponse> => {
  const input: DeleteUserAvailabilityBulkInput = {
    search: ids.map((id: string): DeleteUserAvailabilityInput => ({ id })),
  };
  const res = await runDeleteUserAvailabilityAndUnassignBulk({ input });

  const selected = deleteUserAvailabilityAndUnassignBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Delete User Availability And Unassign From Trips');
  return selected;
};

export const deleteUserAvailabilitySeriesAndUnassignBulk = async (ids: string[]): Promise<DeleteUserAvailabilityBulkResponse> => {
  const input: DeleteUserAvailabilityBulkInput = {
    search: ids.map((id: string): DeleteUserAvailabilityInput => ({ id })),
  };
  const res = await runDeleteUserAvailabilitySeriesAndUnassignBulk({ input });

  const selected = deleteUserAvailabilitySeriesAndUnassignBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Delete User Availability and Unassign From Trips');
  return selected;
};

export const handleDeleteUserAvailabilityBulk = async (
  selected: UserAvailability[],
  deleteSeries: boolean = false
): Promise<DeleteUserAvailabilityBulkResponse> => {
  try {
    const deleteAvailabilityPayload: string[] = selected.map(({ id }: UserAvailability): string => id);
    if (!deleteAvailabilityPayload.length) throw new Error('No availability provided to delete.');

    if (deleteSeries) return await deleteUserAvailabilitySeriesBulk(deleteAvailabilityPayload);
    return await deleteUserAvailabilityBulk(deleteAvailabilityPayload);
  } catch (err) {
    const message = err.message || err;
    log.error('handleDeleteUserAvailabilityBulk', err.message).notify({ title: 'Delete User Availability', message });
  }
};

export const handleDeleteUserAvailabilityAndUnassignBulk = async (
  selected: UserAvailability[],
  deleteSeries: boolean = false
): Promise<DeleteUserAvailabilityBulkResponse> => {
  try {
    const deleteAvailabilityPayload: string[] = selected.map(({ id }: UserAvailability): string => id);
    if (!deleteAvailabilityPayload.length) throw new Error('No availability provided to delete.');

    if (deleteSeries) return await deleteUserAvailabilitySeriesAndUnassignBulk(deleteAvailabilityPayload);
    return await deleteUserAvailabilityAndUnassignBulk(deleteAvailabilityPayload);
  } catch (err) {
    const message = err.message || err;
    log
      .error('handleDeleteUserAvailabilityAndUnassignBulk', err.message)
      .notify({ title: 'Delete User Availability And Unassign From Trips', message });
  }
};

export default deleteUserAvailabilityBulk;

import { Rate } from 'antd';
import React from 'react';
import { getClasses } from '../../utils/strings';

const RatingInput = (props: any): JSX.Element => (
  <div className="form-control justify-content-center">
    <Rate
      defaultValue={0}
      {...props}
      onChange={(value: number): void => props?.onChange?.({ target: { name: props?.name, value } })}
      className={getClasses(
        props.className,
        '{transform:scale(0.8)|translateY(-1px);}',
        '{color:rgba(255,255,255,0.25);}_.ant-rate-star-second,_.ant-rate-star-zero',
        '{color:var(--bs-secondary)!;}_.ant-rate-star-half,_.ant-rate-star-full'
      )}
      allowHalf
      allowClear
    />
  </div>
);

export default RatingInput;

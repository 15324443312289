import { AcceptInputOption, VehicleMakeEnum, VehicleModelInput } from '../../../models/gen/graphql';
import { GetVehicleModelsDocument, GetVehicleTrackerDocument } from '../../queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils';

type GetVehicleModelsGraphApiResponse = GraphApiResponse<typeof GetVehicleModelsDocument>;
type GetVehicleTrackerGraphApiResponse = GraphApiResponse<typeof GetVehicleTrackerDocument>;

const getVehicleModelsResponseSelector = (res: GetVehicleModelsGraphApiResponse): Array<AcceptInputOption> =>
  res?.getFilters?.filters?.vehicleModel?.models as Array<AcceptInputOption>;
const getVehicleTrackerResponseSelector = (res: GetVehicleTrackerGraphApiResponse): Array<AcceptInputOption> =>
  res?.getFilters?.filters?.vehicleTracker as Array<AcceptInputOption>;

const [runGetVehicleModels] = graphApi(GetVehicleModelsDocument, {
  onError: (res: GetVehicleModelsGraphApiResponse): void => handleError(res, { notification: { title: 'Get Vehicle Models' } }),
});
const [runGetVehicleTracker] = graphApi(GetVehicleTrackerDocument, {
  onError: (res: GetVehicleTrackerGraphApiResponse): void => handleError(res, { notification: { title: 'Get Vehicle Tracker' } }),
});

export const getVehicleModels = async (make: VehicleMakeEnum = null): Promise<Array<AcceptInputOption>> => {
  const input: VehicleModelInput = { make };
  const res = await runGetVehicleModels({ input });
  return getVehicleModelsResponseSelector(res);
};
export const getVehicleTracker = async (): Promise<Array<AcceptInputOption>> => {
  const res = await runGetVehicleTracker();
  return getVehicleTrackerResponseSelector(res);
};

export const useGetVehicleModels = createGraphApiHook(getVehicleModels);

import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import useConfirmation from '../../hooks/useConfirmation';
import ManifestOverridesModal from '../ManifestOverridesModal';
import ManifestUpload from '../ManifestUpload';

const modals = {
  'Manifest Upload': ManifestUpload,
  'Manifest Overrides': ManifestOverridesModal,
};

const initModalsPaneState = {
  show: undefined,
};
const ModalsPane = () => {
  const [state, setState] = useState(initModalsPaneState);
  const { show } = state;

  const onHide = () => setState((current) => ({ ...current, show: undefined }));
  //example of confirmation modal
  const getConfirmation = useConfirmation({
    Body: {
      title: 'Testing',
      message: 'Select yes or no.',
    },
  });
  //confirmation modal onClick example
  const testConfirmationModal = () => {
    getConfirmation()
      .then(() => window.alert('You chose "Yes"'))
      .catch(() => window.alert('You chose "No"'));
  };

  return (
    <ListGroup>
      <ListGroup.Item action onClick={testConfirmationModal}>
        Confirmation Modal
      </ListGroup.Item>
      {Object.entries(modals)
        .sort(([a], [b]) => (a < b ? -1 : a > b ? 1 : 0))
        .map(([key, Component], m) => (
          <React.Fragment key={m}>
            <ListGroup.Item action onClick={() => setState((current) => ({ ...current, show: key }))}>
              {key}
            </ListGroup.Item>
            <Component show={show === key} onHide={onHide} />
          </React.Fragment>
        ))}
    </ListGroup>
  );
};

export default ModalsPane;

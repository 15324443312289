import './styles.scss';

import Grid, { GridCell, GridCellProps, GridProps, GridRow, GridRowProps } from '@/components/Grid';
import { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { VirtualItem, VirtualizerOptions, elementScroll, useVirtualizer } from '@tanstack/react-virtual';

import { LoadingBlur } from '@/components/LoadingSpinner';
import { Middle } from '@/components/Align';
import React from 'react';
import { SortDirectionEnum } from '@/models/gen/graphql';
import TippyWhen from '@/components/TippyWhen';
import equal from 'fast-deep-equal/es6/react';
import useLocale from '@/hooks/useLocale';

export type SimpleTableOptions = {
  loading?: boolean;
  locale?: {
    'No Records': string;
  };
  rowHeight?: number;
  overscan?: number;
  title?: string;
  shortcuts?: (() => ReactNode) | ReactNode;
};
export type SimpleTableProps = {
  data: string[];
  columns?: Record<string, string>;
  header?: (props) => ReactNode;
  row: (props: SimpleTableRowRendererProps) => React.ReactNode;
  options: SimpleTableOptions;
} & React.HTMLAttributes<HTMLDivElement>;

export type SimpleTableRowRendererProps = { index: number; rowId: string; columns: Record<string, string> } & Omit<GridProps, 'columns'>;

// const easeInOutCubic = (t: number): number => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
const easeInOutSine = (t) => -(Math.cos(Math.PI * t) - 1) / 2;

const SimpleTable = ({
  data,
  columns,
  header: HeaderRenderer,
  row: RowRenderer,
  options: {
    loading = false,
    locale: customLocale,
    rowHeight = 45,
    overscan = 5,
    title = undefined,
    shortcuts: Shortcuts = undefined,
  } = {},
  ...divProps
}: SimpleTableProps): ReactNode => {
  const [maxHeight, setMaxHeight] = useState(300);
  const headerRef = useRef(null);
  const headerHeight = headerRef.current?.offsetHeight || 0;
  const realHeight = maxHeight - headerHeight;

  const tableRef = useRef(null);
  const scrollingRef = useRef<number>();
  const locale = useLocale(customLocale);

  const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback((offset, canSmooth, instance): void => {
    const duration = 200;
    const start = tableRef.current.scrollTop;
    const change = offset - start;
    const startTime = (scrollingRef.current = performance.now());

    const animateScroll: FrameRequestCallback = (now: number): void => {
      if (scrollingRef.current !== startTime) return;
      const timeElapsed = now - startTime;
      // Use cubic-bezier easing for smoother scroll transition
      const easing = easeInOutSine(Math.min(timeElapsed / duration, 1));
      const interpolated = start + change * easing;

      elementScroll(interpolated, canSmooth, instance);
      if (timeElapsed < duration) requestAnimationFrame(animateScroll);
    };

    requestAnimationFrame(animateScroll);
  }, []);

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: (): HTMLElement => tableRef.current,
    estimateSize: (): number => rowHeight,
    paddingStart: rowHeight,
    overscan,
    scrollToFn,
  });

  const renderRow = useCallback(
    (virtualRow: VirtualItem): ReactNode => {
      const idx = virtualRow.index;
      const rowId = data[idx];
      return (
        <RowRenderer
          index={idx}
          rowId={rowId}
          columns={columns}
          className="SimpleTable-Row"
          ref={rowVirtualizer.measureElement}
          key={virtualRow.key}
          style={{
            height: virtualRow.size,
            transform: `translateY(${virtualRow.start}px)`,
          }}
          data-index={idx}
        />
      );
    },
    [data]
  );

  // calculate max height on mount
  useLayoutEffect((): void => {
    console.log('running layout effect');
    const FULLSCREEN_PADDING = headerHeight;
    const node = document.querySelector('.RouteContent');
    const { y } = node.getBoundingClientRect();
    const result = window.innerHeight - y - FULLSCREEN_PADDING || 0;
    setMaxHeight(result);
  }, [headerHeight]);

  return (
    <div {...divProps} className={`SimpleTable ${divProps?.className}`} style={{ ...(divProps?.style || {}), height: maxHeight }}>
      <div className="SimpleTable-Header" ref={headerRef}>
        {title && <div className="SimpleTable-Title">{title}</div>}
        {Shortcuts && <div className="SimpleTable-Shortcuts">{Shortcuts instanceof Function ? <Shortcuts /> : Shortcuts}</div>}
      </div>
      <div className="SimpleTable-Wrapper" style={{ maxHeight: realHeight }}>
        <LoadingBlur loading={loading} passive={true} />
        <div className="SimpleTable-Container" ref={tableRef}>
          <div className="SimpleTable-Header">
            <Grid className="SimpleTable-Row" columns={Object.values(columns)}>
              <HeaderRenderer />
            </Grid>
          </div>
          <div className="SimpleTable-Body" style={{ height: rowVirtualizer.getTotalSize() - rowHeight - headerHeight }}>
            {data.length === 0 && (
              <Grid className="SimpleTable-Row SimpleTable-Empty">
                <GridRow className="text-center">
                  <GridCell className="SimpleTable-Cell SimpleTable-Empty">
                    <Middle.Center>
                      <i className="fa fa-inbox" />
                      <span>{locale('No Records')}</span>
                    </Middle.Center>
                  </GridCell>
                </GridRow>
              </Grid>
            )}
            {rowVirtualizer.getVirtualItems().map(renderRow)}
            {/* {data.map((rowId, idx): ReactNode => {
              return (
                <RowRenderer
                  index={idx}
                  rowId={rowId}
                  columns={columns}
                  className="SimpleTable-Row"
                  style={{
                    height: 45,
                    position: 'relative',
                  }}
                  data-index={idx}
                />
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export type SimpleTableHeaderProps = GridRowProps & {};
export const SimpleTableHeader = ({ className = '', ...complexTableHeaderProps }: SimpleTableHeaderProps): ReactNode => (
  <GridRow {...complexTableHeaderProps} className={`SimpleTable-Header ${className}`} />
);

export type SimpleTableRowProps = GridProps & {};
const SimpleTableRow = (
  { className = '', children, ...props }: SimpleTableRowProps,
  ref?: React.ForwardedRef<HTMLDivElement>
): ReactNode => (
  <Grid {...props} className={`SimpleTable-Row ${className}`} ref={ref}>
    <GridRow children={children} />
  </Grid>
);
export const SimpleTableRowWithRef = React.forwardRef(SimpleTableRow);

export type SimpleTableCellProps = GridCellProps & {};
export const SimpleTableCell = ({ className = '', ...complexTableCellProps }: SimpleTableCellProps): ReactNode => (
  <GridCell {...complexTableCellProps} className={`SimpleTable-Cell ${className}`} />
);

export type SimpleTableSortableCellProps = SimpleTableCellProps & {
  direction: SortDirectionEnum;
  onSort: () => void;
};
// TODO: parent will give this a call back so we can know the column and it's sort direction
export const SimpleTableSortableCell = ({
  index,
  children,
  direction,
  onSort,
  ...complexTableCellProps
}: SimpleTableSortableCellProps): React.JSX.Element => (
  <SimpleTableCell {...complexTableCellProps}>
    <button className="SimpleTable-Sortable" onClick={onSort}>
      <span className="flex-grow-1">{children}</span>
      <TippyWhen isTrue={!!direction} options={{ content: direction === SortDirectionEnum.Asc ? 'Ascending' : 'Descending', delay: 250 }}>
        <span>
          <i className={`fa ${!!direction ? `fa-sort-${direction.toLowerCase()}` : 'fa-sort opacity-25'} `} />
        </span>
      </TippyWhen>
    </button>
  </SimpleTableCell>
);

export default React.memo(SimpleTable, (prev, next) => equal(prev, next));

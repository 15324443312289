import { DeleteStopBulkInput, DeleteStopInput } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { customDeleteHandler } from 'utils';
import gql from 'graphql-tag';

const deleteStopBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (...ids: string[]): Promise<any> => {
      const input: DeleteStopBulkInput = { search: ids.map((id: string): DeleteStopInput => ({ id })) };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): number => data?.deleteStopBulk?.deleted || 0,
  handler: customDeleteHandler('Delete Stop', 'stop', 'deleteStopBulk'),
  query: gql`
    mutation DeleteStopBulk($input: DeleteStopBulkInput!) {
      deleteStopBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteStopBulk;

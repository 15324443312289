import { RunAssignDriverInput, RunAssignDriverResponse } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { RunAssignDriverDocument } from '../../queries';
import { Validation } from '../../../utils/validations';
import graphApi from '../..';

type RunAssignDriverGraphApiResponse = GraphApiResponse<typeof RunAssignDriverDocument>;

const runAssignDriverValidator = new Validation.Validator({
  'driverId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
  'tripId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  vehicleId: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val) ? true : val?.length ? false : undefined,
  }),
  scheduled: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  combines: (val: string): Validation.Validity => ({
    valid: !!val,
  }),
});

const runAssignDriverResponseSelector = (res: RunAssignDriverGraphApiResponse): RunAssignDriverResponse =>
  !res.errors ? ((res?.runAssignDriver || {}) as RunAssignDriverResponse) : undefined;

const [runRunAssignDriver] = graphApi(RunAssignDriverDocument);

const runAssignDriver = async (runAssignDriverInput: RunAssignDriverInput): Promise<RunAssignDriverResponse> => {
  const input: RunAssignDriverInput = runAssignDriverValidator.create(runAssignDriverInput);

  const res = await runRunAssignDriver({ input });

  return runAssignDriverResponseSelector(res);
};

export default runAssignDriver;

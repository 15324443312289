import { createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { RunArchiveInvoicesDocument } from '@/api/queries';
import { RunArchiveInvoicesInput } from '@/models/gen/graphql';
import { Toast } from '@/models';
import graphApi from '@/api';

type RunArchiveInvoicesGraphApiResponse = GraphApiResponse<typeof RunArchiveInvoicesDocument>;

const title = 'Archive Invoices';

const runArchiveInvoicesResponseSelector = (res: RunArchiveInvoicesGraphApiResponse): number =>
  !res.errors ? res?.runArchiveInvoices?.updated : undefined;

const [runRunArchiveInvoices] = graphApi(RunArchiveInvoicesDocument, {
  onError: (res: RunArchiveInvoicesGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const runArchiveInvoices = async (invoiceIds: string[]): Promise<number> => {
  const input: RunArchiveInvoicesInput = { invoiceIds: (invoiceIds || []).map((id) => parseInt(id)) };
  const res = await runRunArchiveInvoices({ input });

  const selected = runArchiveInvoicesResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default runArchiveInvoices;

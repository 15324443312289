import { CreateCommunicationBulkInput, CreateCommunicationInput, CreateCommunicationResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { CreateCommunicationBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { Validation } from '@/utils';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const createCommunicationValidator = new Validation.Validator({
  message: (val: any): Validation.Validity => ({
    valid: !!(val || '').trim().length && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  tripId: (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
});

type CreateCommunicationBulkGraphApiResponse = GraphApiResponse<typeof CreateCommunicationBulkDocument>;

const title = 'Create Communication';

const createCommunicationBulkResponseSelector = (res: CreateCommunicationBulkGraphApiResponse): CreateCommunicationResponse[] =>
  !res.errors ? ((res?.createCommunicationBulk?.output || []) as CreateCommunicationResponse[]) : undefined;

const [runCreateCommunicationBulk] = graphApi(CreateCommunicationBulkDocument, {
  onError: (res: CreateCommunicationBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createCommunicationBulk = async (communications: CreateCommunicationInput[]): Promise<CreateCommunicationResponse[]> => {
  const input: CreateCommunicationBulkInput = {
    values: communications.map(
      (communication: CreateCommunicationInput): CreateCommunicationInput => createCommunicationValidator.create(communication)
    ),
  };

  const res = await runCreateCommunicationBulk({ input });
  const selected = createCommunicationBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateCommunicationBulk = createGraphApiHook(createCommunicationBulk);

export default createCommunicationBulk;

import { CreateLocationBulkInput, CreateLocationInput, Location } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreateLocationBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { Validation } from '../../../utils/validations';
import graphApi from '../..';

type CreateLocationBulkGraphApiResponse = GraphApiResponse<typeof CreateLocationBulkDocument>;

const title = 'Create Locations';

export const createLocationValidator = new Validation.Validator({
  'name!': (val: string): Validation.Validity => ({
    valid: val?.length >= 2,
    message: val?.length >= 2 ? 'Location name must be at least 2 characters.' : undefined,
  }),
  'address!': (val: string): Validation.Validity => ({
    valid: val?.length >= 2,
    message: val?.length >= 2 ? 'Please enter a valid address.' : undefined,
  }),
  'type!': (val: string): Validation.Validity => ({
    valid: val?.length >= 2,
    message: val?.length >= 2 ? 'Please select a location type.' : undefined,
  }),
  'airports!': (val: string[]): Validation.Validity => ({
    valid: !!val?.length,
    message: val?.length ? 'Please select at least one market.' : undefined,
  }),
  coordinates: (val: { lat: string; lng: string }): Validation.Validity => ({
    valid:
      Validation.isNumber(parseInt(val?.lat)) && Validation.isNumber(parseInt(val?.lng))
        ? true
        : !!val?.lat || !!val?.lng
          ? false
          : undefined,
    defaultValue: { lat: 0.0, lng: 0.0 },
  }),
  cityName: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  stateCode: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  zipCode: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  active: (val: any): Validation.Validity => ({
    valid: Validation.isNumber(parseInt(val)) ? true : undefined,
    defaultValue: 1,
  }),
  phoneNumber: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  countryCode: (val: string): Validation.Validity => ({
    valid: !!val && new RegExp('^[A-Z]{3}').test(val) ? true : val?.length ? false : undefined,
    defaultValue: 'USA',
  }),
  traceId: (val: string): Validation.Validity => ({
    // BE handles traceId
    valid: undefined,
  }),
  aliases: (val: string[]): Validation.Validity => ({
    valid: Array.isArray(val) && (val || []).length > 0 ? true : undefined,
    defaultValue: [],
  }),
  radius: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isNumber(parseInt(val)),
    defaultValue: 100,
  }),
});
const createLocationBulkResponseSelector = (res: CreateLocationBulkGraphApiResponse): Location[] =>
  !res.errors ? ((res?.createLocationBulk?.output || []).map(({ node }) => node) as Location[]) : undefined;

export const [runCreateLocationBulk] = graphApi(CreateLocationBulkDocument, {
  onError: (res: CreateLocationBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createLocationBulk = async (locationObjs: CreateLocationInput[]): Promise<Location[]> => {
  const values: CreateLocationInput[] = locationObjs.map((location: CreateLocationInput): CreateLocationInput => {
    const { airports, ...rest } = createLocationValidator.create({ ...location, airports: location?.airportCodes });
    return {
      ...rest,
      airportCodes: airports,
    };
  });
  const input: CreateLocationBulkInput = {
    values,
  };
  const res = await runCreateLocationBulk({ input });
  const data = createLocationBulkResponseSelector(res);

  if (data) createNotification('Success', Toast.Type.SUCCESS, title);

  return data;
};

export default createLocationBulk;

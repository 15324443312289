import './styles.scss';

import React, { forwardRef, useEffect, useState } from 'react';

import { Collapse } from 'react-bootstrap';
import { Validation } from '../../utils/validations';
import useClassNames from '../../hooks/useClassNames';

type CollapsibleState = {
  open: boolean;
};
type CollapsibleProps = {
  title: string;
  show?: boolean;
  className?: string;
  disabled?: boolean;
  options?: any;
  children: any;
  full?: boolean;
};

const Collapsible = ({ title: header, show: initShow, className, disabled, options, children }: CollapsibleProps): JSX.Element => {
  // init state
  const initCollapsibleState: CollapsibleState = {
    open: !!initShow || false,
  };

  const [state, setState] = useState(initCollapsibleState);
  const { open } = state;
  // state handler
  const onToggleOpen = (): void =>
    disabled ? undefined : setState((current: CollapsibleState): CollapsibleState => ({ ...current, open: !current?.open }));

  useEffect((): void => {
    if (Validation.isNil(initShow)) return;
    setState((current: CollapsibleState): CollapsibleState => ({ ...current, open: initShow }));
  }, [initShow]);

  const classNames = useClassNames('Collapsible', className);

  return (
    <section className={classNames}>
      <div className="Collapsible-Header">
        <span className={`{${disabled ? '' : 'cursor:pointer;'}}`} onClick={onToggleOpen}>
          {/* Toggle arrow */}
          <i className={`sv sv-chevron-${open ? 'down' : 'right'}`} />
          <span>{header || 'Drawer'}</span>
        </span>
      </div>
      <Collapse in={open}>
        <div className={`Collapsible-Body ${options?.full ? 'full' : ''}`}>{children || <NoContent />}</div>
      </Collapse>
    </section>
  );
};

const NoContent = forwardRef(
  (props, ref: any): JSX.Element => (
    <div ref={ref} className="d-flex justify-content-center">
      No Options
    </div>
  )
);

export default React.memo(Collapsible);

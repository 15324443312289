import { CreateGroupHasUserBulkInput, CreateGroupHasUserInput } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';
import { validateObj } from '../../utils';

const validateGroupHasUser = validateObj({
  userId: (val: any): any => val || '',
  groupId: (val: any): any => val || '',
});

const createGroupHasUserBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...groupHasUser: CreateGroupHasUserInput[]): Promise<any> => {
      const input: CreateGroupHasUserBulkInput = {
        values: groupHasUser.map(validateGroupHasUser),
      };
      // Handle validation.
      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): any => {
    const { response = { success: null } } = data?.createGroupHasUserBulk || {};
    const success = response.success;
    return { success, errors: null };
  },
  query: gql`
    mutation CreateGroupHasUserBulk($input: CreateGroupHasUserBulkInput!) {
      createGroupHasUserBulk(input: $input) {
        output {
          error
          node {
            userId
            groupId
          }
        }
      }
    }
  `,
};

export default createGroupHasUserBulk;

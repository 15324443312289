import { CreateEmailBulkDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const createEmailBulk = createGraphApiMockResponse(CreateEmailBulkDocument, {
  createEmailBulk: {
    output: [
      {
        error: null,
        node: {
          id: '3b08fd1d-2124-4601-84c6-0e565afa00d7',
        },
      },
    ],
  },
});

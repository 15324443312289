import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '@/utils/custom';
import { AirportWithLocationSearch, Location, LocationConnection, LocationConnectionInput, LocationSearch } from '@/models/gen/graphql';
import { SearchLocationsDocument, SearchLocationsTableDocument, SelectLocationFilterDocument } from '@/api/queries';

import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type SearchLocationsGraphApiResponse = GraphApiResponse<typeof SearchLocationsDocument>;
type SearchLocationsTableGraphApiResponse = GraphApiResponse<typeof SearchLocationsTableDocument>;
type SelectLocationFilterGraphApiResponse = GraphApiResponse<typeof SelectLocationFilterDocument>;
type SearchLocationsOptions = { pageSize?: number; page?: number; merge?: boolean };

const SEARCH_LOCATIONS_PAGE_SIZE = 5000;
export const SEARCH_LOCATIONS_TABLE_PAGE_SIZE = 5000;
const title = 'Search Locations';

const searchLocationsResponseSelector = (res: SearchLocationsGraphApiResponse): ConnectionDetails<Location> =>
  convertConnectionToDetails(res?.searchLocations?.locationConnection as LocationConnection);
const searchLocationsTableResponseSelector = (res: SearchLocationsTableGraphApiResponse): ConnectionDetails<Location> =>
  convertConnectionToDetails(res?.searchLocations?.locationWithAirportConnection as LocationConnection);
const selectLocationFilterResponseSelector = (res: SelectLocationFilterGraphApiResponse): ConnectionDetails<Location> =>
  convertConnectionToDetails(res?.searchLocations?.locationWithAirportConnection as LocationConnection);

const [runSearchLocations, runRefetchSearchLocations] = graphApi(SearchLocationsDocument, {
  onError: (res: SearchLocationsGraphApiResponse): void => handleError(res, { notification: { title } }),
});
const [runSearchLocationsTable, runRefetchSearchLocationsTable] = graphApi(SearchLocationsTableDocument, {
  onError: (res: SearchLocationsGraphApiResponse): void => handleError(res, { notification: { title } }),
});
const [runSelectLocationFilter, runRefetchSelectLocationFilter] = graphApi(SelectLocationFilterDocument, {
  onError: (res: SelectLocationFilterGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const searchLocations = async (
  query: LocationSearch[],
  options: SearchLocationsOptions = {}
): Promise<ConnectionDetails<Location>> => {
  const { pageSize = SEARCH_LOCATIONS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: LocationConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchLocations.locationConnection' : undefined };

  const res = await runSearchLocations({ input }, graphApiOptions);

  return searchLocationsResponseSelector(res);
};

export const searchLocationsTable = async (
  query: LocationSearch[],
  options: SearchLocationsOptions = {}
): Promise<ConnectionDetails<Location>> => {
  const { pageSize = SEARCH_LOCATIONS_TABLE_PAGE_SIZE, page = 0, merge = false } = options;

  const input: LocationConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchLocations.locationWithAirportConnection' : undefined };

  const res = await runSearchLocationsTable({ input }, graphApiOptions);

  return searchLocationsTableResponseSelector(res);
};

export const refetchSearchLocations = async (
  query: LocationSearch[],
  options: SearchLocationsOptions = {}
): Promise<ConnectionDetails<Location>> => {
  const { pageSize = SEARCH_LOCATIONS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: LocationConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchLocations.locationConnection' : undefined };

  const res = await runRefetchSearchLocations({ input }, graphApiOptions);

  return searchLocationsResponseSelector(res);
};

export const refetchSearchLocationsTable = async (
  query: LocationSearch[],
  options: SearchLocationsOptions = {}
): Promise<ConnectionDetails<Location>> => {
  const { pageSize = SEARCH_LOCATIONS_TABLE_PAGE_SIZE, page = 0, merge = false } = options;

  const input: LocationConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchLocations.locationWithAirportConnection' : undefined };

  const res = await runRefetchSearchLocationsTable({ input }, graphApiOptions);

  return searchLocationsTableResponseSelector(res);
};

export const selectLocationFilter = async (
  query: AirportWithLocationSearch[],
  options: SearchLocationsOptions = {}
): Promise<ConnectionDetails<Location>> => {
  const { pageSize = SEARCH_LOCATIONS_TABLE_PAGE_SIZE, page = 0, merge = false } = options;
  const input: LocationConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchLocations.locationWithAirportConnection' : undefined };
  const res = await runSelectLocationFilter({ input }, graphApiOptions);
  return selectLocationFilterResponseSelector(res);
};

export const refetchSelectLocationFilter = async (
  query: LocationSearch[],
  options: SearchLocationsOptions = {}
): Promise<ConnectionDetails<Location>> => {
  const { pageSize = SEARCH_LOCATIONS_TABLE_PAGE_SIZE, page = 0, merge = false } = options;
  const input: LocationConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchLocations.locationWithAirportConnection' : undefined };
  const res = await runRefetchSelectLocationFilter({ input }, graphApiOptions);
  return selectLocationFilterResponseSelector(res);
};

export const useSearchLocations = createGraphApiHook(searchLocations, { refetch: refetchSearchLocations });
export const useSearchLocationsTable = createGraphApiHook(searchLocationsTable, {
  refetch: refetchSearchLocationsTable,
  merge: mergeConnectionDetails,
});
export const useSelectLocationFilter = createGraphApiHook(selectLocationFilter, {
  refetch: refetchSelectLocationFilter,
  merge: mergeConnectionDetails,
});

export default searchLocations;

import { CreateRateItemInput, RateCurrencyEnum, RateItem, RateTypeEnum } from '@/models/gen/graphql';
import { Datetime, Validation } from '@/utils';
import { HandleErrorInput, handleError } from '@/utils/custom';

import { CreateRateItemBulkDocument } from '@/api/queries';
import { GraphApiMethod } from '@/api/core';
import graphApi from '@/api';

// Global Service Variables

export const RATE_ITEM_VALIDATION_CRITERIA = {
  'currency!': (val: any): Validation.Validity => ({ valid: Object.values(RateCurrencyEnum).includes(val) }),
  'endDatetime!': (val: any): Validation.Validity => ({ valid: !!val }),
  'rate!': (val: any): Validation.Validity => ({ valid: !Validation.isNil(val) && !isNaN(val), defaultValue: 0 }),
  'startDatetime!': (val: any): Validation.Validity => ({ valid: !!val }),
  'type!': (val: any): Validation.Validity => ({ valid: Object.values(RateTypeEnum).includes(val) }),
  rateGroupId: (val: any): Validation.Validity => ({ valid: true }),
};
export const createRateItemValidator = new Validation.Validator(RATE_ITEM_VALIDATION_CRITERIA);

export const enforceRateDates = (rateItem: CreateRateItemInput): CreateRateItemInput => {
  const result: CreateRateItemInput = { ...rateItem };
  if (rateItem.startDatetime) result.startDatetime = new Datetime(rateItem.startDatetime).setTime('00:00:00').toString();
  if (rateItem.endDatetime) result.endDatetime = new Datetime(rateItem.endDatetime).setTime('23:59:59').toString();
  return result;
};

const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Create Rate Item' } });

type ServiceMethodPayload = { [key in keyof CreateRateItemInput]: any }[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<RateItem[]>;
}

// CreateRateItemBulk Service
export const { createRateItemBulk } = ((): {
  createRateItemBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(CreateRateItemBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof CreateRateItemBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<RateItem[]> => {
        const values = payload.map((rateItem: CreateRateItemInput): CreateRateItemInput => {
          const rateWithAdjustedDates = enforceRateDates(rateItem);
          return createRateItemValidator.create(rateWithAdjustedDates);
        });
        const res = await fn({ input: { values } });
        return res?.createRateItemBulk?.output?.map(({ node }: { node: RateItem }): RateItem => node) || [];
      }
  );

  return {
    createRateItemBulk: mutate,
  };
})();

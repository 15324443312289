import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import React from 'react';
import { routes } from '../../App';

const NavigationPane = () => (
  <ListGroup>
    {Object.keys(routes)
      .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
      .map((url, u) => (
        <Link to={`${url}${window.location.search}`} key={u}>
          <ListGroup.Item action>{url}</ListGroup.Item>
        </Link>
      ))}
  </ListGroup>
);

export default NavigationPane;

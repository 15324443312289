import { Datetime, uuid } from '@/utils';

import { DATETIME_RFC_FORMAT } from '@/constants';
import { LoginDocument } from '@/api/queries';
import { LoginInput } from '@/models/gen/graphql';
import { VariablesOf } from '@graphql-typed-document-node/core';
import { createGraphApiMockResponse } from '@/api/core';

export const login = createGraphApiMockResponse(
  LoginDocument,
  {
    login: {
      token: uuid(),
      user: {
        address: null,
        avatar: null,
        cityName: null,
        companyId: uuid(),
        createdAt: new Datetime().format(DATETIME_RFC_FORMAT),
        createdBy: null,
        dob: null,
        email: 'johndoe@skyhoptechnologies.com',
        employeeId: '',
        firstName: 'John',
        id: uuid(),
        lastName: 'Doe',
        notes: null,
        phone: null,
        role: {
          id: uuid(),
          name: 'test',
        },
        roleId: uuid(),
        startDate: null,
        stateCode: null,
        timezone: null,
        updatedAt: null,
        updatedBy: null,
        username: 'johndoe',
        zipCode: null,
      },
    },
  },
  {
    variables: (opts: VariablesOf<LoginInput>): boolean => !!opts,
  }
);

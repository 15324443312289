import React from 'react';
import { TemplatedCell } from '..';
import Tippy from '@tippyjs/react';

export const OverflowCell = (props: any): JSX.Element => (
  <Tippy content={<strong>{props?.value || props?.children}</strong>} delay={500}>
    <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      {...props}
    />
  </Tippy>
);

export default (key: string): TemplatedCell => {
  switch (key) {
    case 'pickup':
    case 'dropoff':
      return { cell: OverflowCell, props: { className: 'text-start', minWidth: '7rem' } };
    case 'mon':
    case 'tue':
    case 'wed':
    case 'thu':
    case 'fri':
    case 'sat':
    case 'sun':
    case 'averageDifference':
      return { props: { className: 'text-start', minWidth: '2rem', maxWidth: '4rem' } };
    case 'airlineClient':
    case 'weekdayAvgDriveMinutes':
    case 'weekendAvgDriveMinutes':
      return { props: { className: 'text-start', minWidth: '6rem', maxWidth: '8rem' } };
    case 'totalTrips':
    case 'holidayAvgDriveMinutes':
      return { props: { className: 'text-start', minWidth: '8rem', maxWidth: '10rem' } };
    default:
      return {};
  }
};

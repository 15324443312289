import { CreateGroupHasUserBulkInput, CreateGroupHasUserInput, CreateGroupHasUserResponse } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreateGroupHasUserBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validateGroupHasUser = validateObj({
  userId: (val: any): any => val || '',
  groupId: (val: any): any => val || '',
});

type CreateGroupHasUserBulkGraphApiResponse = GraphApiResponse<typeof CreateGroupHasUserBulkDocument>;

const title = 'Assign Roles to Users';

const createGroupHasUserBulkResponseSelector = (res: CreateGroupHasUserBulkGraphApiResponse): CreateGroupHasUserResponse[] =>
  !res.errors ? ((res?.createGroupHasUserBulk?.output || []) as CreateGroupHasUserResponse[]) : undefined;

const [runCreateGroupHasUserBulk] = graphApi(CreateGroupHasUserBulkDocument, {
  onError: (res: CreateGroupHasUserBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createGroupHasUserBulk = async (groupHasUser: CreateGroupHasUserInput[]): Promise<CreateGroupHasUserResponse[]> => {
  const input: CreateGroupHasUserBulkInput = {
    values: groupHasUser.map(validateGroupHasUser),
  };

  const res = await runCreateGroupHasUserBulk({ input });
  const selected = createGroupHasUserBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateGroupHasUserBulk = createGraphApiHook(createGroupHasUserBulk);

export default createGroupHasUserBulk;

import { DeleteEmailBulkInput, DeleteEmailBulkResponse, DeleteEmailInput } from '../../../models/gen/graphql';

import { DeleteEmailBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import graphApi from '../..';

type DeleteEmailBulkGraphApiResponse = GraphApiResponse<typeof DeleteEmailBulkDocument>;

const deleteEmailBulkResponseSelector = (res: DeleteEmailBulkGraphApiResponse): DeleteEmailBulkResponse =>
  !res.errors ? res?.deleteEmailBulk : undefined;

const [runDeleteEmailBulk] = graphApi(DeleteEmailBulkDocument);

const deleteEmailBulk = async (ids: string[]): Promise<DeleteEmailBulkResponse> => {
  const input: DeleteEmailBulkInput = { search: ids.map((id: string): DeleteEmailInput => ({ id })) };
  const res = await runDeleteEmailBulk({ input });

  return deleteEmailBulkResponseSelector(res);
};

export default deleteEmailBulk;

import { RunRollbackImportsInput, RunRollbackImportsResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { GraphApiResponse } from '../../core';
import { RunRollbackImportsDocument } from '../../queries';
import { Toast } from '../../../models';
import graphApi from '../..';

type RunRollbackImportsGraphApiResponse = GraphApiResponse<typeof RunRollbackImportsDocument>;

const title = 'Rollback Imports';

const runRollbackImportsResponseSelector = (res: RunRollbackImportsGraphApiResponse): RunRollbackImportsResponse =>
  !res?.errors ? res?.runRollbackImports : undefined;

const [runRunRollbackImports, _runRefetchRunRollbackImports] = graphApi(RunRollbackImportsDocument, {
  onError: (res: RunRollbackImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runRollbackImports = async (importIds: string[]): Promise<RunRollbackImportsResponse> => {
  const input: RunRollbackImportsInput = {
    importIds,
  };

  const res = await runRunRollbackImports({ input });
  const selected = runRollbackImportsResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default runRollbackImports;

import './styles.scss';

import { Activity } from 'models/gen/graphql';
import { Datetime } from '../../utils';
import React from 'react';

const TripActivity = ({ list }: { list: Activity[] }): JSX.Element => (
  <ul className="list-unstyled mt-2 mb-5" style={{ maxHeight: '400px' }}>
    {(list || [])
      .filter(({ message }: Activity): boolean => !!message)
      .map(
        ({ createdAt, message }: Activity, i: any): JSX.Element => (
          <li key={i}>{message.replace(/ ?(\(.+\))?$/, ` (${new Datetime(createdAt).asDayjs().utc(true).fromNow()})`)}</li>
        )
      )}
  </ul>
);

export default TripActivity;

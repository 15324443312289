import DynamicReport from '../../../pages/Reports/DynamicReport';
import FormField from '../../FormField';
import React from 'react';
import Select from '../../Select';
import { createWidget } from '../utils';
import { reportTypes } from '../../../pages/Reports';

export const Report = createWidget({
  key: 'report',
  label: 'Report',
  description: (
    <>
      <p>Display a full dynamic report in a single resizable widget.</p>
      <p>
        <small>
          <em>
            This report cannot display reports that are not dynamic. Currently this only refers to the Drive Times Report, and will be
            updated when that report is converted to be dynamic.
          </em>
        </small>
      </p>
    </>
  ),
  icon: <i className="sv sv-chart-growth" />,
  component: ({ options }) => {
    return (
      <div className="w-100 h-100 overflow-auto">
        <DynamicReport report={options?.type} />
      </div>
    );
  },
  options: {
    type: 'clientSummaryReport',
    settings: { minW: 6, minH: 3 },
  },
  config: ({ options, setOptions }) => {
    return (
      <>
        <FormField
          name="type"
          label="Type"
          value={options?.type}
          onChange={(event) => setOptions((current) => ({ ...current, [event.target.name]: event.target.value }))}
          options={{
            input: {
              as: (props) => (
                <Select
                  onChange={(event) => setOptions((current) => ({ ...current, type: event.target.value }))}
                  value={options?.type}
                  placeholder="Report Type"
                >
                  {Object.entries(reportTypes)
                    .filter(([key]: [string, [string]]): boolean => key !== 'driveTimesReport')
                    .sort((a: [string, any], b: [string, any]): number => a[0].localeCompare(b[0]))
                    .map(
                      ([key, [title]]: [string, [string]], r: number): JSX.Element => (
                        <option value={key} key={r}>
                          {title}
                        </option>
                      )
                    )}
                </Select>
              ),
            },
          }}
        />
      </>
    );
  },
});

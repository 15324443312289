import { GET_AIRPORT_GROUPS } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectAirportGroup = ({ ...props }) => (
  <SelectFromQuery
    cache="AirportGroups"
    placeholder="All Airports"
    query={GET_AIRPORT_GROUPS}
    selector={(data) =>
      (data?.getFilters?.filters?.airportGroups || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
        values: opt.values,
      }))
    }
    sticky
    {...props}
  />
);

export default SelectAirportGroup;

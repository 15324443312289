import ProtectedRoute from '../../../components/ProtectedRoute';
import React from 'react';
import ScrollableContainer from '../../../components/ScrollableContainer';
import { useParams } from 'react-router-dom';

const Snapshots = () => {
  const { id } = useParams();
  return (
    <ProtectedRoute title="Snapshots">
      <ScrollableContainer name="Snapshots" className="page-container">
        <div>{id}</div>
      </ScrollableContainer>
    </ProtectedRoute>
  );
};
export default Snapshots;

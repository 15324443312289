import './styles.scss';

import Field, { FieldProps } from '@/components/Field';
import React, { ChangeEvent, ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef } from 'react';

import { getClasses } from '@/utils';

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (value: string) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
  ref?: ForwardedRef<HTMLInputElement>;
};

const Input = ({ onChange, isValid, isInvalid, isDirty, ...inputProps }: InputProps, ref: ForwardedRef<HTMLInputElement>): ReactNode => (
  <input
    {...inputProps}
    className={getClasses(
      'Input',
      'form-control',
      isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined,
      isDirty ? 'is-dirty' : undefined,
      inputProps?.className
    )}
    onChange={(event: ChangeEvent<HTMLInputElement>): void => onChange?.(event.target.value)}
    {...(inputProps?.ref ? { ref } : {})}
  />
);

export type InputFieldProps = InputProps & Omit<FieldProps, 'children'>;
const InputField = (
  { label, feedback, valid, required, ...inputProps }: InputFieldProps,
  ref: ForwardedRef<HTMLInputElement>
): ReactNode => (
  <Field label={label} feedback={feedback} valid={valid} required={required}>
    <Input {...inputProps} {...(inputProps?.ref ? { ref } : {})} />
  </Field>
);
const InputFieldWithRef = forwardRef(InputField);

export default forwardRef(Input);
export { InputFieldWithRef as InputField };

import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import SelectImportProvider from '../../components/SelectImportProvider';
import { useCreateImports } from '../../api/services/imports/createImports';

const ManifestFileUpload = ({
  name: filename,
  md5 = undefined,
  provider,
  progress = undefined,
  onRemove = () => {},
  onReady = (...args) => {},
  disabled,
}: {
  name: string;
  md5?: string;
  provider?: string;
  progress?: number;
  onRemove?: () => void;
  onReady?: (id: string, url: string, contentType: string) => void;
  disabled?: boolean;
}): React.JSX.Element => {
  const [providerId, setProviderId] = useState('');
  const [{ data, loading }, { fetch: createImports }] = useCreateImports();
  const { url } = data?.[0] || {};

  useEffect(() => {
    if (!providerId) return;
    (async () => {
      const res = await createImports([{ filename, md5, providerId }]);
      const { id, url, contentType } = res?.[0] || {};
      onReady(id, url, contentType);
    })();
  }, [providerId]);

  return (
    <Row className="ms-1 py-2">
      <Col md={8} className="border rounded py-2">
        <Row className="h-100 position-relative align-items-center">
          <Col md={1} className="pe-0 pt-1">
            <i className="sv sv-file-image fs-3" />
          </Col>
          <Col md={11} className="pe-4">
            <Row>
              <Col md={10}>{filename || 'Untitled'}</Col>
              <Col md={2} className="text-end">
                <small
                  style={{
                    fontSize: '0.65rem',
                  }}
                >
                  {progress >= 0 ? progress : 0}%
                </small>
              </Col>
            </Row>
            <Row>
              <Col>
                <ProgressBar
                  now={progress || 0}
                  variant="secondary"
                  style={{
                    height: '0.25rem',
                  }}
                />
              </Col>
            </Row>
          </Col>
          {onRemove && (
            <Button
              className="visible-hover align-self-end rounded-pill"
              size="sm"
              style={{
                position: 'absolute',
                top: '-1.25rem',
                right: '-0.75rem',
                width: '1.5rem',
                height: '1.5rem',
                lineHeight: '1.5rem',
                padding: 0,
              }}
              onClick={onRemove}
            >
              <i className="fa fa-times" />
            </Button>
          )}
        </Row>
      </Col>
      <Col md={4} className="ps-5">
        <SelectImportProvider
          className={`mb-2${!providerId ? ' border-danger' : ''}`}
          name="providerId"
          onChange={(event) => setProviderId(event?.target?.value || '')}
          disabled={loading || disabled}
          placeholder="Select a Provider"
          value={providerId || provider}
          searchable
        />
      </Col>
    </Row>
  );
};

export default ManifestFileUpload;

import { OverrideImportInput, RunOverrideImportsInput, UpdateResponse } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { RunOverrideImportsDocument } from '../../queries';
import graphApi from '../..';
import { handleError } from '../../../utils';

type RunOverrideImportsGraphApiResponse = GraphApiResponse<typeof RunOverrideImportsDocument>;

const title = 'Override Imports';

const runOverrideImportsResponseSelector = (res: RunOverrideImportsGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.runOverrideImports?.imports : undefined;

const [runRunOverrideImports] = graphApi(RunOverrideImportsDocument, {
  onError: (res: RunOverrideImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runOverrideImports = async (overrides: OverrideImportInput[]): Promise<UpdateResponse[]> => {
  const input: RunOverrideImportsInput = {
    overrides,
  };
  const res = await runRunOverrideImports({ input });
  return runOverrideImportsResponseSelector(res);
};

export default runOverrideImports;

import { GraphApi, Toast } from 'models';
import { createNotification, getErrors, handleError } from 'utils';

import { ReportProblemInput } from 'models/gen/graphql';
import gql from 'graphql-tag';

const reportProblem: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (message: string, images: string[] = null): Promise<any> => {
      const input: ReportProblemInput = {
        message,
        images,
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data) => data?.reportProblem || {},
  handler: (response: any): void => {
    const title = 'Report Problem';
    const errors = getErrors(response);
    const hasError = !!errors?.length || !!response?.networkError;
    handleError({ ...response, errors }, { notification: { title } });
    if (hasError) return;
    createNotification('Success', Toast.Type.SUCCESS, title);
  },
  query: gql`
    mutation ReportProblem($input: ReportProblemInput!) {
      reportProblem(input: $input) {
        error {
          code
          debug
          message
        }
        message
      }
    }
  `,
};

export default reportProblem;

import React, { MouseEventHandler, useContext, useState } from 'react';
import { Validation, onEnter } from '@/utils';

import { Button } from 'react-bootstrap';
import FormField from '@/components/FormField';
import LoadingSpinner from '@/components/LoadingSpinner';
import Logger from '@/utils/logs';
import { Rate } from '@/models/gen/graphql';
import { RowContext } from '@/components/VirtualTable';
import TippyWhen from '@/components/TippyWhen';
import { getClasses } from '@/utils/strings';
import getCorrectRatesForTrips from '@/api/services/rates/getCorrectRatesForTrips';
import runRateAmountOnTrips from '@/api/services/trips/runRateAmountOnTrips';
import updateRateOnTrips from '@/api/services/rates/updateRateOnTrips';

const log = Logger.of('InlineRate');

type InlineRateOptions = {
  showRate?: boolean;
  showSuggestions?: boolean;
  formatRate?: (rate: number) => string | JSX.Element;
};
type Props = {
  selectedTrips?: string[];
  tripId: string;
  rate: Rate;
  onEdit?: (index: number, rate: number, rateGroupId?: string) => void;
  onChangeRate?: () => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
  onBlur?: MouseEventHandler<HTMLDivElement>;
  onEditRateReport?: () => void;
  className?: string;
  options?: InlineRateOptions;
  children?: any;
};
type InlineRateState = {
  assigningTo: string;
  newRateValue: string;
  suggestedRate: {
    rateAmount: number;
    message: string;
    loading: boolean;
    rateId: string;
    tripId: string;
    bulk: boolean;
  };
};
const initInlineRateState: InlineRateState = {
  assigningTo: undefined,
  newRateValue: undefined,
  suggestedRate: {
    rateAmount: undefined,
    message: undefined,
    loading: false,
    rateId: undefined,
    tripId: undefined,
    bulk: false,
  },
};
const initOptions: InlineRateOptions = {
  showRate: true,
  showSuggestions: true,
};
const TripsRate = ({
  selectedTrips,
  tripId,
  onEdit,
  rate,
  onClick,
  onDoubleClick,
  onBlur,
  onEditRateReport,
  className = '',
  options,
  children,
}: Props): JSX.Element => {
  const [rateState, setState] = useState<InlineRateState>(initInlineRateState);
  const { assigningTo, newRateValue, suggestedRate } = rateState;
  const { showSuggestions, showRate } = { ...initOptions, ...options };
  const { index } = useContext(RowContext);

  const resetRateState = (): void => {
    setState(initInlineRateState);
  };

  const handleGetCorrectRateForTrip = async (tripId: string): Promise<void> => {
    const correctRate = await getCorrectRatesForTrips([tripId]);
    if (!correctRate?.length)
      return setState(
        (current: InlineRateState): InlineRateState => ({
          ...current,
          suggestedRate: { ...current.suggestedRate, message: 'No rate suggestion.', loading: false },
        })
      );
    const { id: rateId, rate: rateAmount, type: rateType } = correctRate[0]?.newRate || undefined;
    setState(
      (current: InlineRateState): InlineRateState => ({
        ...current,
        suggestedRate: {
          ...current?.suggestedRate,
          rateId,
          tripId,
          rateAmount,
          message: `Suggested ${rateType} $${rateAmount}`,
          loading: false,
        },
      })
    );
  };

  const handleGetCorrectRateForTripBulk = (): void => {
    setState(
      (current: InlineRateState): InlineRateState => ({
        ...current,
        suggestedRate: { ...current?.suggestedRate, bulk: true },
      })
    );
  };

  const handleRunRateAmountOnTrips = async (event: any): Promise<void> => {
    try {
      const amount = parseFloat(`${event?.target?.value}`);
      if (!Validation.isNumber(amount)) throw new Error();
      const payload = (selectedTrips?.length ? selectedTrips : [tripId]).map((tripId: string): { tripId: string; amount: number } => ({
        tripId,
        amount,
      }));
      const [{ rateGroupId }] = await runRateAmountOnTrips(payload);
      resetRateState();
      if (onEdit) onEdit(index, amount, rateGroupId);
    } catch (err) {
      log.error('handleRunRateAmountOnTrips: Failed to change rate amount on Trip(s)', err);
    }
  };

  const handleUpdateRateOnTrip = async (rateHistoryId: string, tripId: string, suggestedRate: number): Promise<void> => {
    await updateRateOnTrips([{ rateHistoryId, tripId }]);
    if (onEdit) onEdit(index, suggestedRate);
    resetRateState();
  };

  if (!tripId) return children;
  return (
    <>
      <div className="d-flex justify-content-center align-items-center gap-1" onDoubleClick={onDoubleClick}>
        {showSuggestions && assigningTo !== tripId && (
          <TippyWhen
            isTrue={!!suggestedRate}
            options={{
              content: (
                <div className="d-flex gap-2">
                  {!suggestedRate?.loading && suggestedRate?.message && (
                    <span className="{white-space:nowrap;line-height:2rem;}">{suggestedRate?.message}</span>
                  )}
                  {!suggestedRate?.loading && suggestedRate?.rateId && (
                    <Button
                      size="sm"
                      onClick={(): Promise<void> => handleUpdateRateOnTrip(suggestedRate?.rateId, tripId, suggestedRate?.rateAmount)}
                    >
                      Apply
                    </Button>
                  )}
                  {suggestedRate?.bulk && (
                    <Button size="sm" onClick={(): void => onEditRateReport()} className="{white-space:nowrap}">
                      Rate Report
                    </Button>
                  )}

                  {suggestedRate?.loading && !suggestedRate?.bulk && <LoadingSpinner size="sm" />}
                </div>
              ),
              placement: 'left',
              delay: 0,
              animation: false,
              interactive: true,
              hideOnClick: false,
              onHide: (): void => setState((current: InlineRateState): InlineRateState => ({ ...current, suggestedRate: undefined })),
              appendTo: document.body,
            }}
          >
            <i
              className="fa fa-circle-info pointer {opacity:0.4;}"
              onMouseEnter={(): void => {
                setState((current: InlineRateState): InlineRateState => {
                  return { ...current, suggestedRate: { ...current?.suggestedRate, loading: true } };
                });
                if (selectedTrips?.length > 1 && selectedTrips?.includes(tripId)) {
                  return handleGetCorrectRateForTripBulk();
                }
                handleGetCorrectRateForTrip(tripId);
              }}
            ></i>
          </TippyWhen>
        )}

        {showRate && (
          <div className="justify-content-center">
            {assigningTo === tripId && (
              <FormField
                name="newRateValue"
                onBlur={(event) => {
                  const { value } = event?.target || undefined;
                  setState(
                    (current: InlineRateState): InlineRateState => ({
                      ...current,
                      assigningTo: undefined,
                      newRateValue: value,
                    })
                  );
                  if (onBlur) onBlur(event);
                }}
                value={newRateValue || ''}
                onChange={(event) => {
                  const { value } = event?.target || undefined;
                  setState((current: InlineRateState): InlineRateState => ({ ...current, newRateValue: value }));
                }}
                onKeyDown={onEnter(handleRunRateAmountOnTrips)}
                onFocus={({ target }: { target: HTMLInputElement }) => {
                  target.select();
                  target.scrollLeft = 0;
                }}
                options={{
                  label: {
                    className: 'p-2 fs-6',
                  },
                  input: {
                    className: 'px-2 py-1',
                  },
                }}
                inline
                autoFocus
                condensed
              />
            )}
            {assigningTo !== tripId && (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  role="button"
                  tabIndex={0}
                  onMouseDown={(event: any): void => {
                    event.preventDefault();
                    event.stopPropagation();
                    setState((current: InlineRateState): InlineRateState => {
                      return {
                        ...current,
                        assigningTo: tripId,
                        newRateValue: rate?.rate ? parseFloat(`${rate?.rate}`).toFixed(2) : '',
                      };
                    });
                    if (onClick) onClick(event);
                  }}
                  className={getClasses('pointer', className || '')}
                >
                  <DisplayRate rate={rate?.rate} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const DisplayRate = ({ rate, formatRate }: { rate: number; formatRate?: (rate: number) => string }) => {
  return (
    <>
      {formatRate ? (
        formatRate(rate)
      ) : rate >= 0 ? (
        rate % 1 !== 0 ? (
          <span>
            {`${rate}`.split('.')[0]}
            <sup>{parseFloat(`${rate}`).toFixed(2).split('.')[1]}</sup>
          </span>
        ) : (
          `${parseFloat(`${rate}`) || 0}`
        )
      ) : (
        '--'
      )}
    </>
  );
};

export default TripsRate;

import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError } from '@/utils/custom';

import { DeleteRateItemBulkDocument } from '@/api/queries';
import { DeleteRateItemInput } from '@/models/gen/graphql';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Delete Rate Item' } });

// Service Function Signature
type ServiceMethodPayload = string[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// DeleteRateItemBulk Service
export const { deleteRateItemBulk } = ((): {
  deleteRateItemBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(DeleteRateItemBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof DeleteRateItemBulkDocument>): ServiceMethod =>
      async (ids: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const search: DeleteRateItemInput[] = ids.map((id: string): DeleteRateItemInput => ({ id }));
        const res = await fn({ input: { search } });
        return res;
      }
  );

  return {
    deleteRateItemBulk: mutate,
  };
})();

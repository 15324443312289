import Tippy, { TippyProps } from '@tippyjs/react';

import React from 'react';

type Props = {
  isTrue?: boolean;
  isFalse?: boolean;
  children?: any;
  options?: TippyProps;
};
const TippyWhen = ({ isTrue, isFalse, children = [], options = {} }: Props): JSX.Element => {
  if (!isTrue || !!isFalse) return children;
  return (
    <Tippy {...options} appendTo={document.body}>
      {children}
    </Tippy>
  );
};

export default TippyWhen;

import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError } from '@/utils/custom';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { DeleteVariableTimeBulkDocument } from '@/api/queries';
import { DeleteVariableTimeInput } from '@/models/gen/graphql';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Delete Variable Time' } });

// Service Function Signature
type ServiceMethodPayload = string[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// DeleteVariableTimeBulk Service
export const { deleteVariableTimeBulk, useDeleteVariableTimeBulk } = ((): {
  deleteVariableTimeBulk: ServiceMethod;
  useDeleteVariableTimeBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(DeleteVariableTimeBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof DeleteVariableTimeBulkDocument>): ServiceMethod =>
      async (ids: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const search: DeleteVariableTimeInput[] = ids.map((id: string): DeleteVariableTimeInput => ({ id }));
        const res = await fn({ input: { search } });
        return res;
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    deleteVariableTimeBulk: mutate,
    useDeleteVariableTimeBulk: useService,
  };
})();

import './styles.scss';

import { Button, ButtonProps, Card } from 'react-bootstrap';

import AddressDisplay from '../AddressDisplay';
import { Location } from 'models/gen/graphql';
import React from 'react';

interface LocationCardProps {
  location: Location;
  title?: string;
  onClick: (data: Location) => void;
  index?: number;
  options?: {
    button?: {
      text: string;
      variant?: ButtonProps['variant'];
    };
  };
}
const LocationCard = ({ location, title, onClick, index = -1, options }: LocationCardProps): JSX.Element => {
  const handleClick = (event: any): void => onClick(location);
  return (
    <Card className="LocationCard primary shadow">
      <Card.Header>
        <Card.Title>{title || location?.name || ''}:</Card.Title>
      </Card.Header>
      <Card.Body>
        <AddressDisplay
          className="m-0"
          address={location?.address || ''}
          city={location?.cityName || ''}
          state={location?.stateCode || ''}
          zip={location?.zipCode || ''}
          lat={location?.coordinates?.lat || 0}
          lng={location?.coordinates?.lng || 0}
        />
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button name="SELECT_LOCATION" className="w-50" variant={options?.button?.variant || 'primary'} size="sm" onClick={handleClick}>
          {options?.button?.text || 'Select Location'}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default LocationCard;

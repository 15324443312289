import React, { ReactNode } from 'react';
import SelectFromQuery, { SelectFromQueryProps } from '@/components/SelectFromQuery';

import { FilterOption } from '@/models/gen/graphql';
import { GET_AIRPORTS } from '@/queries/Filter';
import { SelectorObject } from '@/components/Select';

export type SelectAirportProps = Partial<SelectFromQueryProps>;
const SelectAirport = (props: SelectAirportProps): ReactNode => (
  <SelectFromQuery
    placeholder="All Airports"
    query={GET_AIRPORTS}
    selector={(data: { [key: string]: any }): any =>
      (data?.getFilters?.filters?.airports || []).map(
        (opt: FilterOption): SelectorObject => ({
          label: opt.displayName,
          value: opt.id,
          values: undefined,
        })
      )
    }
    options={{
      cache: 'Airports',
    }}
    {...props}
  />
);

export default SelectAirport;

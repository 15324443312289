import { DATE_FE_FORMAT } from '../../constants';
import { DatePicker } from 'antd';
import { Datetime } from '../../utils';
import React from 'react';

const { WeekPicker } = DatePicker;

const WeekInput = ({ value, onChange, ...props }) => {
  const formatValue = (value) => {
    if (!value) return '';
    const startDate = new Datetime(value?.toString()).asDayjs().startOf('week').format(DATE_FE_FORMAT);
    const endDate = new Datetime(value?.toString()).asDayjs().endOf('week').format(DATE_FE_FORMAT);
    return `${startDate} - ${endDate}`;
  };

  return (
    <WeekPicker
      {...props}
      value={new Datetime(value).asDayjs()}
      onChange={onChange}
      placeholder="Select week"
      format={formatValue}
      allowClear={false}
      suffixIcon={<i className="fa fa-calendar" />}
    />
  );
};

export default WeekInput;

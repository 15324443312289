// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditableCell .EditableCell-Value {
  text-align: inherit !important;
}

.ActionsCellDropdown .ant-dropdown-menu-item {
  font-size: 1rem !important;
  color: var(--bs-primary) !important;
}
.ActionsCellDropdown .ant-dropdown-menu-item-active:hover {
  color: var(--bs-secondary) !important;
  background-color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/VirtualTable/components/styles.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;;AAGE;EACE,0BAAA;EACA,mCAAA;AAAJ;AAEI;EACE,qCAAA;EACA,iCAAA;AAAN","sourcesContent":[".EditableCell .EditableCell-Value {\n  text-align: inherit !important;\n}\n\n.ActionsCellDropdown .ant-dropdown-menu-item {\n  font-size: 1rem !important;\n  color: var(--bs-primary) !important;\n}\n.ActionsCellDropdown .ant-dropdown-menu-item-active:hover {\n  color: var(--bs-secondary) !important;\n  background-color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Dropdown, { DropdownItem, DropdownProps } from '@/components/Dropdown';
import { ReactNode, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export type NavigationDropdownProps = Omit<DropdownProps, 'onChange' | 'name' | 'value'> & {
  name?: string;
};

const NavigationDropdown = (props: NavigationDropdownProps): ReactNode => {
  const navigate = useNavigate();
  const onChange = (value: string): void => navigate(value);

  useEffect((): void => {
    if (window.location.pathname && props.items.map((item: DropdownItem): string => item.value).includes(window.location.pathname)) return;
    navigate(props.items[0].value, { replace: true });
  }, [navigate, props.items]);

  return (
    <Dropdown
      name="navigationDropdown"
      {...props}
      value={window.location.pathname}
      onChange={onChange}
      options={{ showClearButton: false, ...(props?.options || {}) }}
    />
  );
};

export default NavigationDropdown;

import { Dropdown, DropdownProps } from 'antd';
import React, { forwardRef } from 'react';

import { Layout } from 'react-grid-layout';
import Tippy from '@tippyjs/react';
import { getClasses } from '../../utils';
import { useDashboard } from '.';

export type WidgetProps = {
  children: React.ReactNode;
  className?: string;
  title?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  Layout;

const Widget = forwardRef(({ children, className, widget, onDelete, ...props }: any, ref: any): JSX.Element => {
  const [, setDashboard] = useDashboard();

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'settings':
        return setDashboard((current) => ({
          ...current,
          widgetDetails: {
            ...widget,
            settings: props?.['data-grid'],
            options: { ...(widget?.options || {}), ...props?.['data-grid']?.options },
          },
        }));
      case 'reallyDelete':
        return onDelete();
    }
  };
  const menuOptions: DropdownProps['menu'] = {
    items: [
      { key: 'settings', label: 'Settings' },
      { type: 'divider' },
      {
        key: 'delete',
        label: 'Delete',
        children: [{ key: 'confirmDelete', label: 'Are you Sure?', children: [{ key: 'reallyDelete', label: 'Yes' }] }],
      },
    ],
    onClick: handleMenuClick,
  };

  const classes = getClasses('Widget', className);

  return (
    <div className={classes} {...props} ref={ref}>
      <Tippy
        content={
          <>
            Drag to move.
            <br />
            Right-click to see options.
          </>
        }
      >
        <div className="Controls">
          <Dropdown menu={menuOptions} trigger={['contextMenu']}>
            <div className="Control DragHandle">
              <i className="sv sv-move" />
            </div>
          </Dropdown>
        </div>
      </Tippy>
      <div className="Widget-Content">{children}</div>
    </div>
  );
});

export default Widget;

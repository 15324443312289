import './styles.scss';

import { ConnectionDetails, QueryInputType, handleError, queryInput } from '@/utils/custom';
import { Import, ImportStatusEnum, ImportTypeEnum, ImportWithAirportSearch, SortDirectionEnum } from '@/models/gen/graphql';
import { OVERRIDE_STATUS, PENDING_STATUS, PREVIEW_STATUS } from '@/constants';
import React, { useState } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';

import { Badge } from 'react-bootstrap';
import ConfirmationButton from '@/components/ConfirmationButton';
import { Datetime } from '@/utils/dates';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import ManifestOverridesModal from '@/components/ManifestOverridesModal';
import ManifestUpload from '@/components/ManifestUpload';
import PageInfo from '@/components/PageInfo';
import PendingImportsTable from '@/pages/Manifests/PendingImportsTable';
import { SEARCH_IMPORTS_PAGE_SIZE } from '@/api/services/imports/searchImports';
import ScrollableContainer from '@/components/ScrollableContainer';
import SelectAirportGroup from '@/components/SelectAirportGroup';
import SelectImportProvider from '@/components/SelectImportProvider';
import SelectImportType from '@/components/SelectImportType';
import SelectPendingImportStatus from '@/components/SelectPendingImportStatus';
import SelectUser from '@/components/SelectUser';
import runArchiveImports from '@/api/services/imports/runArchiveImports';
import runDownloadImports from '@/api/services/imports/runDownloadImports';
import { saveFile } from '@/utils/promises';
import { stringify } from '@/utils/objects';
import updateImportsStatus from '@/api/services/imports/updateImportsStatus';
import useInterval from '@/hooks/useInterval';
import usePage from '@/hooks/usePage';
import { useSearchImportsWithAirport } from '@/api/services/imports/searchImportsWithAirport';
import { useVirtualTable } from '@/components/VirtualTable/utils';

const ADDONS_REFETCH_INTERVAL = 60000;

const initPendingImportsTableState = {
  selectedImport: undefined,
  selected: [],
  sorting: {
    column: undefined,
    direction: undefined,
  },
  importing: false,
};

const parseFilesAndUpdateStatus = async (_uploads, files) => {
  if (!files?.length) return;
  const ids = [];
  for (let u = 0; u < files.length; u++) {
    const { id } = files[u];
    if (!id) continue;
    ids.push(id);
  }
  await updateImportsStatus(ids, ImportStatusEnum.Uploaded);
};

const PendingImports = (_props: RouteProps): React.JSX.Element => {
  // init state
  const [state, setState] = useState(initPendingImportsTableState);
  const { selectedImport, selected, sorting, importing } = state;

  // init page state
  const [page, setPage] = usePage('manifests');

  // init query
  const [{ data, loading }, { fetch: getPendingImportsTable, refetch, fetchMore }] = useSearchImportsWithAirport();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows });

  const navigate = useNavigate();

  const archiveImports = async () => {
    try {
      await runArchiveImports(Object.values(selected));
      await refetch([page?.query || {}]);
      setState((current) => ({ ...current, selected: [] }));
    } catch (err) {
      console.error(err);
    }
  };

  const onSearch = async (filters): Promise<void> => {
    const { airportCode, providerId, from, to, createdBy, status, type } = filters;
    const query: ImportWithAirportSearch = {
      airportCode: airportCode?.length ? queryInput(airportCode) : null,
      createdAt: queryInput.date([new Datetime(from).toString(), new Datetime(to).toString()], true, SortDirectionEnum.Desc, 0),
      createdBy: createdBy ? queryInput(createdBy) : null,
      providerId: providerId ? queryInput(providerId) : null,
      status: queryInput(status || PENDING_STATUS),
      type: type ? queryInput(type) : null,
      archivedAt: queryInput([], QueryInputType.ISNULL),
    };
    const fn = stringify.compare(page?.query || {}, query) ? refetch : getPendingImportsTable;
    await fn([query]);
    setPage((current) => ({
      ...(current || {}),
      query,
    }));
  };

  const onDownload = async (id: string, name?: string): Promise<void> => {
    const downloadResult = await runDownloadImports(id);
    const downloadURL = downloadResult.length === 1 ? downloadResult[0].url : undefined;
    if (downloadURL) saveFile(downloadURL, name);
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Import>> => {
    if (loading || !hasNextPage) return;
    const result = await fetchMore([page?.query || {}], {
      page: Math.round(after / SEARCH_IMPORTS_PAGE_SIZE),
      merge: true,
    });
    return result;
  };

  const showOverrides = (row: Import): void => {
    if (PREVIEW_STATUS.includes(row.status)) {
      navigate(`/manifests/${row.id}`);
    } else if (OVERRIDE_STATUS.includes(row.status)) {
      setState((current) => ({ ...current, selectedImport: row }));
    }
  };

  const hideManifestOverrideModal = () => setState((current) => ({ ...current, selectedImport: undefined }));
  const handleSubmitManifestOverrideModal = (id) => navigate(`/manifests/${id}${window.location.search}`);
  const onManifestUploadSuccess = async (uploads, files) => {
    try {
      if (!files?.length) return;
      await parseFilesAndUpdateStatus(uploads, files);
      if (files.length !== 1) return;
      const { id, name } = files?.[0] || {};
      setState((current: any): any => ({ ...current, importing: false }));
      showOverrides({ id, name, status: ImportStatusEnum.Parsed } as Import);
    } catch (err) {
      handleError(err, { notification: { title: 'Manifest Upload' } });
    }
  };

  useInterval(() => {
    if (stringify((page?.query || {})?.type).includes(ImportTypeEnum.Addon)) refetch([page?.query || {}]);
  }, ADDONS_REFETCH_INTERVAL);

  return (
    <ScrollableContainer name="Manifests" className="page-container">
      <Filters
        name="pendingImportFilters"
        onSubmit={onSearch}
        primary={({ values, onChange }): JSX.Element => {
          const { airportCode, from, to, createdBy, providerId, status, type } = values;
          return (
            <>
              <FormField name="dateRange" type="daterange" value={[from, to]} onChange={onChange.dateRange('from', 'to')} condensed />
              <FormField
                placeholder="Imported By"
                name="createdBy"
                onChange={onChange}
                value={createdBy}
                condensed
                searchable
                options={{ input: { as: SelectUser } }}
              />
              <FormField
                placeholder="Airport"
                name="airportCode"
                onChange={onChange}
                value={airportCode}
                condensed
                searchable
                options={{ input: { as: SelectAirportGroup } }}
                multiple
              />
              <FormField
                placeholder="Airline"
                name="providerId"
                onChange={onChange}
                value={providerId}
                condensed
                searchable
                options={{ input: { as: SelectImportProvider } }}
              />
              <FormField
                name="status"
                onChange={onChange}
                value={status}
                placeholder="Status"
                condensed
                searchable
                options={{ input: { as: SelectPendingImportStatus } }}
              />
              <FormField
                name="type"
                onChange={onChange}
                value={type}
                placeholder="Type"
                condensed
                searchable
                options={{ input: { as: SelectImportType } }}
              />
            </>
          );
        }}
        alternate={(): JSX.Element => (
          <>
            <FormButton
              name="ARCHIVED_ADDONS"
              icon={<i className="sv sv-archive2 {font-size:1.5rem;}" />}
              variant="outline-gray"
              onClick={(): string => (window.location.href = '/imports/history?status=PARSED,NOT_PARSED&type=ADDON&archived=true')}
            >
              Archived Addons
            </FormButton>
            <FormButton
              name="IMPORT_SCHEDULES"
              icon={<i className="sv sv-download2 {font-size:1.5rem;}" />}
              variant="outline-gray"
              onClick={(): void => setState((current: any): any => ({ ...current, importing: true }))}
            >
              Import Schedules
            </FormButton>
            <ConfirmationButton
              icon={<i className="sv sv-box {font-size:1.5rem;}" />}
              variant="outline-gray"
              disabled={!selected.length}
              onConfirm={archiveImports}
              options={{
                confirmation: {
                  Body: {
                    message: `Archiving ${selected?.length} manifest(s). Is this ok?`,
                  },
                  Footer: {
                    resolve: 'Yes',
                    reject: 'Cancel',
                  },
                },
              }}
            >
              Archive
              {selected?.length > 1 && (
                <Badge className="ms-2" pill bg="success">
                  {selected?.length}
                </Badge>
              )}
            </ConfirmationButton>
          </>
        )}
        submitOnMount={true}
      />
      <PageInfo>
        {filteredRows.length} / {totalCount} Imports
      </PageInfo>
      <PendingImportsTable
        data={filteredRows}
        loading={loading}
        onSelect={onSelect}
        onDownload={onDownload}
        onSort={makeSortable}
        selected={selected}
        onLazyLoad={!!hasNextPage && rows.length < totalCount ? getMore : undefined}
        onDoubleClick={(row: Import): void => {
          setState((current: any): any => ({
            ...current,
            selected: Array.from(new Set([row?.id])),
          }));
          showOverrides(row);
        }}
      />
      <ManifestUpload
        show={importing}
        onHide={(): void => setState((current: any): any => ({ ...current, importing: false }))}
        onSuccess={onManifestUploadSuccess}
      />
      <ManifestOverridesModal
        show={selectedImport}
        onHide={hideManifestOverrideModal}
        importId={selectedImport?.id}
        name={selectedImport?.name}
        onSubmit={handleSubmitManifestOverrideModal}
      />
    </ScrollableContainer>
  );
};

export default PendingImports;

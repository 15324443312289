import React from 'react';
import Select from '../Select';
import { useEffect } from 'react';
import { useSearchPreviousImports } from '@/api/services/imports/searchPreviousImports';

const SelectImport = ({ importId, providerId, ...props }: { importId: string; providerId: string; [x: string]: unknown }) => {
  const [{ data, loading }, { fetch: searchPreviousImports }] = useSearchPreviousImports();
  const { rows: imports = [] } = data || {};

  const getPreviousImports = async () => {
    if (!providerId || !importId) return;
    const res = await searchPreviousImports({ importId, providerId });
    return res;
  };

  useEffect(() => {
    getPreviousImports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  return (
    <Select
      name="importId"
      placeholder="Select a previous import"
      {...props}
      loading={loading}
      disabled={!importId || !providerId || !imports?.length}
    >
      {imports.map((imp, i) => (
        <option value={imp?.id} key={i}>
          {imp?.name}
        </option>
      ))}
    </Select>
  );
};

export default SelectImport;

import { Email, Provider } from '@/models/gen/graphql';

import Logger from '@/utils/logs';
import Toast from '@/models/Toast';
import { Validation } from '@/utils/validations';
import createClientBulk from './createClientBulk';
import createEmailBulk from './createEmailBulk';
import { createNotification } from '@/utils/custom';
import deleteEmailBulk from './deleteEmailBulk';
import { getDiff } from '@/utils/objects';
import updateClientBulk from './updateClientBulk';
import updateEmailBulk from './updateEmailBulk';

const log = Logger.of('service/providers');

export const createClientWithEmail = async (create: Partial<Provider>): Promise<void> => {
  try {
    const { emails = [], ...data } = create || {};
    const res = await createClientBulk([data]);

    if (!res) throw new Error('Something went wrong');
    if (!emails?.length) return createNotification('Success', Toast.Type.SUCCESS, 'Create Client');
    const output = res?.output || [];

    //Emails Flow
    let createEmailsPayload: Email[] = [];

    for (let i = 0; i < output.length; i++) {
      const providerId = output[i]?.node?.id;
      if (!providerId) continue; // create provider should throw error if any item in the bulk create fails
      // Format Create Email Payload
      const temp: Email[] = (emails || []).map((node): Email => ({ ...node, providerId }));
      createEmailsPayload = [...createEmailsPayload, ...temp];
    }

    if (createEmailsPayload.length > 0) await createEmailBulk(createEmailsPayload);
    createNotification('Success', Toast.Type.SUCCESS, 'Create Client');
  } catch (err) {
    const message = err.message || err;
    log.error('createClientWithEmail', message).notify({ title: 'Create Client', message });
  }
};

// UPDATE
export const updateClientWithEmail = async (payload: Partial<Provider>, selected: Provider[]): Promise<void> => {
  try {
    if (!payload) throw new Error('contact support: no payload with updates');
    if (!selected?.length) throw new Error('contact support: no selected client to update');

    // extract emails from original and updated provider
    const { emails = [], ...update } = payload;
    const { emails: originalEmails = [], ...original } = selected.find((node: Provider): boolean => node?.id === update?.id);

    const [partial] = getDiff(original, update);
    const clientChanged = !!Object.values(partial).length;
    // update selected providers
    if (clientChanged) {
      const bundle = Object.values(selected).map(({ id }: Provider): Provider => ({ ...partial, id }));
      await updateClientBulk(bundle);
    }
    // We want don't want to modify emails in bulk scenarios
    if (selected.length !== 1) return createNotification('Success', Toast.Type.SUCCESS, 'Update Clients');

    const emailChanged = await updateEmailOnProvider(emails, originalEmails, original?.id);

    if (!clientChanged && !emailChanged) return createNotification('No changes made.', Toast.Type.WARNING, 'Update Clients');
    createNotification('Success', Toast.Type.SUCCESS, 'Update Clients');
  } catch (err) {
    const message = err.message || err;
    log.error('updateClientWithEmail', message).notify({ title: 'Update Clients', message });
  }
};

// helper for the update func to crud email on provider
export const updateEmailOnProvider = async (
  emails: Array<Partial<Email>>,
  originalEmails: Array<Email>,
  providerId: string
): Promise<boolean> => {
  if (!Validation.isValidUUID(providerId)) throw new Error('Failed to update email on provider: unable to identify provider');
  // Email Flow
  const createEmailsPayload = [];
  const updateEmailsPayload = [];

  for (let i = 0; i < (emails || []).length; i++) {
    const node = emails[i];
    const id = node?.id || '';

    if (!id) {
      createEmailsPayload.push({ ...node, providerId });
      continue;
    }

    const originalEmail = (originalEmails || []).find((el: Email): any => el?.id === id);
    if (!originalEmail) continue;

    const [partial] = getDiff(originalEmail, node);
    if (!Object.values(partial).length) continue;

    updateEmailsPayload.push({ ...partial, id });
  }

  const deleteEmailsPayload = (originalEmails || [])
    .filter((node: Email): boolean => !emails.filter((el: any): boolean => el?.id === node.id).length)
    .map((node: Email): string => node.id);

  // Create Email
  if (createEmailsPayload.length > 0) await createEmailBulk(createEmailsPayload);

  // Update Email
  if (updateEmailsPayload.length > 0) await updateEmailBulk(updateEmailsPayload);

  // Delete Email
  if (deleteEmailsPayload.length > 0) await deleteEmailBulk(deleteEmailsPayload);

  return createEmailsPayload.length > 0 || updateEmailsPayload.length > 0 || deleteEmailsPayload.length > 0; // email changed on provider
};

import { GetImportProviderDocument } from '../../api/queries';
import SelectFromQuery from '../SelectFromQuery';

const SelectImportProvider = (props) => (
  <SelectFromQuery
    placeholder="All Airlines"
    query={GetImportProviderDocument}
    selector={(data) =>
      (data?.getFilters?.filters?.importProvider || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }))
    }
    {...props}
    options={{ cache: 'ImportProviders', ...(props?.options || {}) }}
  />
);

export default SelectImportProvider;

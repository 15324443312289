import { AirportHasUser, User } from '../../models/gen/graphql';
import { Col, Row } from 'react-bootstrap';
import { Datetime, createNotification, uploadFile, uuid } from '../../utils';
import React, { useEffect, useState } from 'react';

import { File } from 'buffer';
import Logger from '../../utils/logs';
import { MAX_PROFILE_IMAGE_SIZE_MB } from '../../constants';
import { Toast } from '../../models';
import generateUploadProfileImageUrl from '@/api/services/users/generateUploadProfileImageUrl';
import updateUserBulk from '@/api/services/users/updateUserBulk';
import { useAppState } from '../../store/appReducer';

const log = Logger.of('ProfileHeader');

type ProfileHeaderProps = {
  data: User;
};
type ProfileHeaderState = {
  error: boolean;
  image: string;
  renders: number;
  uploadProgress?: number;
};
const initProfileState: ProfileHeaderState = {
  error: false,
  image: undefined,
  renders: 0,
  uploadProgress: undefined,
};
const ProfileHeader = ({ data }: ProfileHeaderProps): JSX.Element => {
  const [state, setState] = useState(initProfileState);
  const { uploadProgress, image, error } = state;

  const [{ config }] = useAppState();
  const driverRoleId = config?.driverRoleId || null;

  const onUploadImage = async (file: File): Promise<any> => {
    try {
      const megabytes = file?.size / (1024 * 1024);
      // Check the file size before proceeding
      if (megabytes > MAX_PROFILE_IMAGE_SIZE_MB) {
        log
          .warn(`File ${file?.name} exceeds the maximum file size \n (${megabytes.toFixed(2)} / ${MAX_PROFILE_IMAGE_SIZE_MB} MB).`)
          .notify({ title: 'Upload Profile Image' });
        return;
      }
      const { contentType, url } = await generateUploadProfileImageUrl(data?.id, file?.name);
      if (!contentType || !url) throw new Error('handleUploadFile: no content type or url');
      const bundle = { file, url, contentType };
      return await uploadFile(bundle, {
        onUploadProgress: (progressEvent: any): void => {
          const { loaded, total } = progressEvent;
          const percent = (loaded * 100) / total;
          setState((current: ProfileHeaderState): ProfileHeaderState => ({ ...current, uploadProgress: Math.round(percent) }));
        },
      });
    } catch (err) {
      log.error(err.message || err);
    }
  };

  const handleUploadFile = async (event: any): Promise<void> => {
    try {
      setState((current: ProfileHeaderState): ProfileHeaderState => ({ ...current, error: false }));
      if (!data?.id) createNotification('User must be created before uploading image.', Toast.Type.WARNING, 'Upload Profile Image');
      const { files } = event?.target || {};
      if (!files?.length) throw new Error('Unable to read file.');
      const file: File = files[0] || {};
      const uploadResponse = await onUploadImage(file);
      const uploadedFile = uploadResponse?.file || {};
      if (!uploadedFile?.url) throw new Error('Failed to upload image.');
      const random_uuid = uuid();
      const parsedUrl = uploadedFile?.url?.replace(/\?.+$/, `?key=${random_uuid}`);
      const updateUserResponse = await updateUserBulk({ id: data?.id, avatar: parsedUrl.split('?')[0] });
      const errors = updateUserResponse
        .filter((output: any): boolean => !!output?.error)
        .map((output: any): string => `${output?.error?.code} ${output?.error?.message}`)
        .join('\n');
      if (errors?.length) throw new Error(errors);
      setState((current: ProfileHeaderState): ProfileHeaderState => ({ ...current, image: parsedUrl }));
      createNotification('Image uploaded successfully!', Toast.Type.SUCCESS, 'Upload Profile Image');
    } catch (err) {
      log.error(err.message || err).notify({
        title: 'Upload Profile Image',
      });
      setState(
        (current: ProfileHeaderState): ProfileHeaderState => ({
          ...current,
          error: true,
        })
      );
    }
  };

  useEffect((): void => {
    if (image || !data?.avatar) return;
    const random_uuid = uuid();
    setState(
      (current: ProfileHeaderState): ProfileHeaderState => ({
        ...current,
        image: `${data?.avatar}?key=${random_uuid}`,
      })
    );
  }, [data]);

  return (
    //  Profile Display
    <Row className="ProfileHeader d-flex align-items-center mb-4">
      {/* Image Upload */}
      <Col className="border-end border-dark" xs={12} md={'auto'}>
        <div className="d-flex justify-content-center align-items-center {width:170px;height:170px;border:1px|solid|black;border-radius:50%;overflow:hidden;position:relative;} me-3">
          {!!uploadProgress && (
            <div
              className={`{position:absolute;width:170px;height:${uploadProgress}%;bottom:0;left:0;transition:1s|height|background;font-size:12px;} text-light ${
                uploadProgress === 100 && !error ? 'bg-success' : uploadProgress === 100 && error ? 'bg-danger' : 'bg-info'
              } text-center`}
            >
              {uploadProgress}%
            </div>
          )}
          <div
            className="d-flex justify-content-center align-items-center {width:140px;height:140px;border-radius:50%;position:relative;text-align:center;overflow:hidden;background-size:cover;background-position:center;}"
            style={{
              backgroundImage: image ? `url(${image})` : undefined,
            }}
          >
            {!image && <i className="sv sv-user sv-2x" />}
            {/* Image Overlay */}
            <div className="{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);border-radius:50%;width:100%;height:100%;}">
              <input name="avatar" type="file" className="d-none" onChange={handleUploadFile} />
              <span
                className="w-100 h-100 image-overlay opacity-hover {cursor:pointer;}"
                onClick={(e: any): void => {
                  const fileInput: HTMLInputElement = document.querySelector('input[type="file"]');
                  fileInput.click();
                }}
              >
                <i className="fa fa-camera text-white" />
              </span>
            </div>
          </div>
        </div>
      </Col>
      {/* Profile Details */}
      <Col className="" xs={12} md={6}>
        <Row>
          <div>
            <small className="fs-6">User Since</small>
          </div>

          <div>
            <strong>{new Datetime(data?.startDate || data?.createdAt).format('MMMM YYYY')}</strong>
          </div>
          {
            /* Display License No if role is driver and driver has a license */
            data?.roleId === driverRoleId && (data?.licenses || []).length > 0 && (
              <div>
                <strong>License No: </strong>
                {data?.licenses[0]?.number}
              </div>
            )
          }
          <div className="fs-6">
            <small>Airports: </small>
            {(data?.airportCodes || []).map((node: AirportHasUser): string => node?.airportCode).join(', ')}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(ProfileHeader);

import './styles.scss';

import { Card, Col, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';

import TippyWhen from '../TippyWhen';
import { getClasses } from '../../utils/strings';

const Details = ({
  data,
  inline,
  className,
  ...props
}: {
  data: any[];
  inline?: boolean;
  className?: string;
  [x: string]: any;
}): JSX.Element => {
  const Content = useMemo((): JSX.Element | JSX.Element[] => {
    const items = (data || [])
      .map((group: any, g: number): JSX.Element[] =>
        Object.entries(group).map(
          ([key, { name, description, value }]: [string, { name: string; description: string; value: string }], d: number): JSX.Element => (
            <Item
              className={getClasses(inline === undefined ? 'col' : undefined, !d ? 'group' : undefined)}
              name={key}
              value={value}
              title={name}
              description={description}
              key={`${g}-${d}`}
            />
          )
        )
      )
      .flat();
    return items;
  }, [data, inline]);

  return (
    <Card {...props} className={getClasses('Details', inline !== undefined ? 'inline' : undefined, className)}>
      <Card.Body>
        <Row>{Content}</Row>
      </Card.Body>
    </Card>
  );
};

const Item = ({
  name,
  value,
  title: _title,
  description,
  ...props
}: {
  name: string;
  value: string;
  title: string;
  description: string;
  [x: string]: any;
}): JSX.Element => (
  <Col>
    <TippyWhen
      options={{
        content: (
          <div className="text-center {max-width:15rem;}">
            {name && <strong className="d-block {font-size:1rem!;}">{name}</strong>}
            {description && <span>{description}</span>}
          </div>
        ),
      }}
      isTrue={!!name || !!description}
    >
      <div {...props}>
        <span>{name}</span>
        <span>{value}</span>
      </div>
    </TippyWhen>
  </Col>
);

export default Details;

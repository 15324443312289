import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useOnChange, { OnChange } from './useOnChange';

import { getResultFromState } from '../utils';
import { modalsSlice } from '../store/modalReducer';
import useEvent from './useEvent';

type UseModalState = {
  show: boolean;
  loading: boolean;
  [name: string]: any;
};
type UseModalMethods = {
  (data: any): void;
  onChange: OnChange;
  onSubmit: () => Promise<void>;
  onHide: () => Promise<void>;
  show: (data?: any) => void;
  local: (data: any) => void;
  reset: () => void;
};
type UseModalResponse = [UseModalState, UseModalMethods];
type UseModalOptions = {
  onSubmit?: (data: any) => Promise<void>;
  onHide?: () => Promise<void>;
};

const useModal = (name: string, options?: UseModalOptions): UseModalResponse => {
  const isModal = useRef(false);
  const { _persist = {}, ...rest } = useSelector((state: any): any => state?.modals || {});
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState({
    ...(rest?.[name] || {}),
  });
  const [loading, setLoading] = useState(false);
  const state = {
    ...(rest?.[name] || {}),
    ...localState,
    show: !!rest?.[name],
    loading,
  };
  const triggerModalEvent = useEvent(`${name}-modal`, async ({ type, payload }: { type: string; payload: any }): Promise<void> => {
    if ((!options?.onSubmit && !options?.onHide) || isModal.current) return;
    switch (type) {
      case 'submit': {
        return options?.onSubmit?.(payload);
      }
      case 'hide': {
        return options?.onHide?.();
      }
    }
  });
  const setState = (data: any): void => {
    const result = getResultFromState(data, { ...(rest?.[name] || {}), ...(localState || {}) });
    dispatch(modalsSlice.actions.setState({ [name]: result }));
  };
  const onSubmit = async (): Promise<any> => {
    try {
      setLoading(true);
      let result = {
        ...(rest?.[name] || {}),
        ...localState,
      };
      setState((current: any): any => {
        result = { ...current, ...localState };
        return result;
      });
      isModal.current = true;
      if (options?.onSubmit) await options.onSubmit(result);
      setLoading(false);
      triggerModalEvent({ type: 'submit', payload: result });
      return result;
    } catch (err) {
      console.error(err);
    }
  };
  const onHide = async (): Promise<void> => {
    try {
      isModal.current = true;
      if (options?.onHide) await options.onHide();
      setState(undefined);
      triggerModalEvent({ type: 'hide' });
    } catch (err) {
      console.error(err);
    }
  };
  const resetState = (): void => setLocalState(rest?.[name] || {});
  const onChange = useOnChange(setLocalState);
  setState.onChange = onChange;
  setState.onSubmit = onSubmit;
  setState.onHide = onHide;
  setState.show = (data: any = {}): void => setState(data);
  setState.local = setLocalState;
  setState.reset = resetState;

  useEffect((): void => {
    setLocalState({
      ...(rest?.[name] || {}),
    });
  }, [rest?.[name]]);

  return [state, setState];
};

export default useModal;

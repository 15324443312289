import { useCallback, useEffect, useRef } from 'react';

import { stringify } from '../utils/objects';
import useGlobalState from '@/hooks/useGlobalState';

const useHotkeys = (source: string, hotkeys: Record<string, any>, target: Document | HTMLElement = document): any => {
  const setGlobalState = useGlobalState(({ setState }) => setState);
  const lastPage = useRef(window.location.href);

  const keyDownHandler: EventListener = useCallback(
    (event: KeyboardEvent): void => {
      const isCtrl = event?.ctrlKey || event?.metaKey;
      const isShift = event?.shiftKey;
      const isAlt = event?.altKey;

      const checkKey = (keyCombination: string): boolean => {
        const keys = keyCombination.split('+').map((key) => key.trim().toLowerCase());
        const result = keys.every((key: string): boolean => {
          return (
            (key === 'ctrl' && isCtrl) || (key === 'shift' && isShift) || (key === 'alt' && isAlt) || event?.key?.toLowerCase() === key
          );
        });
        return result;
      };

      for (const keyCombination in hotkeys) {
        if (checkKey(keyCombination)) {
          event?.preventDefault();
          hotkeys[keyCombination].action(event);
          break;
        }
      }
    },
    [hotkeys]
  );

  useEffect(() => {
    const keys = {};
    Object.entries(hotkeys).forEach(([key, { name, description }]) => {
      keys[key] = { name, description, source };
    });
    target.addEventListener('keydown', keyDownHandler);
    if (lastPage.current === window.location.href) {
      setGlobalState((current: any) => {
        const update = { ...current, hotkeys: { ...current?.hotkeys, ...keys } };
        return stringify.compare(current, update) ? current : update;
      });
    }
    return () => {
      target.removeEventListener('keydown', keyDownHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (lastPage.current !== window.location.href) {
        setGlobalState((current: any) => ({ ...current, hotkeys: {} }));
      }
    };
  }, [hotkeys, keyDownHandler, setGlobalState, source, target]);
};

export default useHotkeys;

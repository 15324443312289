import { IMPORT_TYPES } from '../../constants';
import React from 'react';
import Select from '../Select';
import { properCase } from '../../utils';

const SelectImportType = ({ onChange, value, ...props }) => {
  return (
    <Select name="type" onChange={onChange} value={value} placeholder="Type" {...props}>
      {IMPORT_TYPES.sort().map((name, n) => (
        <option value={name} key={n}>
          {properCase(name)}
        </option>
      ))}
    </Select>
  );
};

export default SelectImportType;

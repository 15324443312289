import { Button, Modal, ModalFooterProps, ModalHeaderProps } from 'react-bootstrap';

import { ModalBodyProps } from 'react-bootstrap/esm/ModalBody';
import React from 'react';
import { create as createModal } from 'react-modal-promise';

/* useConfirmation
  This hook is designed to provide reusable logic for a promise-based
  confirmation modal that is customizable in any way.

  const getConfirmation = useConfirmation(options);

  The previous example would return a promise that, when fired, shows
  the configured modal to get a response through resolve/reject.

  How this modal is supposed to respond is entirely up to you and the
  options provided.

  The DebugTools modal pane currently has a simple example of this
  that waits for a response of either Yes or No, and will show
  a browser alert telling you which was selected.

  The example shown there can me modified to get other inputs as
  well, including input from actual fields, or other components.

  The options that can be provided to the hook are a little complex,
  but hopefully reading through the hook itself will help anyone
  understand the possibilities.
*/

// TODO: Clean this up. It's a little sloppy syntax-wise.
export namespace Confirmation {
  type Component = (props: { isOpen: boolean; onResolve: () => void; onReject: () => void; data?: Record<string, any> }) => React.ReactNode;
  export type Method<T = Record<string, any>> = (overrides: Options, data?: T) => Promise<any>;
  export type Options = {
    Modal?: {
      props?: {
        size?: 'sm' | 'lg' | 'xl';
        centered?: boolean;
      };
      as?: Component;
    };
    Header?: {
      title?: string;
      props?: ModalHeaderProps;
      as?: Component;
    };
    Body?: {
      title?: string;
      message?: React.ReactNode;
      props?: ModalBodyProps;
      as?: Component;
    };
    Footer?: {
      resolve?: string;
      reject?: string;
      props?: ModalFooterProps;
      as?: Component;
    };
  };
}

const defaultOptions: Confirmation.Options = {
  Modal: {
    props: {
      size: 'sm',
      centered: true,
    },
    as: undefined,
  },
  Header: {
    title: undefined,
    props: {},
    as: undefined,
  },
  Body: {
    title: undefined,
    message: 'Are you sure?',
    props: {},
    as: undefined,
  },
  Footer: {
    resolve: 'Yes',
    reject: 'No',
    props: {},
    as: undefined,
  },
};

const useConfirmation = (initOptions: Confirmation.Options = {}) => {
  return async (overrides: Confirmation.Options = {}, data?: Record<string, any>) => {
    const options = {
      ...defaultOptions,
      ...initOptions,
      ...overrides,
    };

    const {
      Modal: { props: modalProps = {}, as: ModalComponent = undefined } = {},
      Header: { props: headerProps = {}, title: headerTitle = undefined, as: HeaderComponent = undefined } = {},
      Body: { props: bodyProps = {}, title: bodyTitle = undefined, message = 'Are you sure?', as: BodyComponent = undefined } = {},
      Footer: { resolve = 'Yes', reject = 'No', as: FooterComponent = undefined } = {},
    } = options || {};

    return createModal(
      ModalComponent ||
        ((({ isOpen, onResolve, onReject }) => (
          <Modal onHide={onReject} {...modalProps} className="ConfirmationModal" show={isOpen}>
            {HeaderComponent ? (
              <HeaderComponent isOpen={isOpen} onResolve={onResolve} onReject={onReject} data={data} />
            ) : headerTitle ? (
              <Modal.Header {...headerProps}>
                <Modal.Title>{headerTitle}</Modal.Title>
              </Modal.Header>
            ) : null}
            {BodyComponent ? (
              <BodyComponent isOpen={isOpen} onResolve={onResolve} onReject={onReject} data={data} />
            ) : (
              <Modal.Body className="pt-4 pb-4 d-flex flex-column justify-content-center text-center" {...bodyProps}>
                {bodyTitle && <Modal.Title>{bodyTitle}</Modal.Title>}
                {message && <p>{message}</p>}
              </Modal.Body>
            )}
            {FooterComponent ? (
              <FooterComponent isOpen={isOpen} onResolve={onResolve} onReject={onReject} data={data} />
            ) : (
              <Modal.Footer className="d-flex">
                <Button name="REJECT" className="flex-grow-1" variant="secondary" onClick={onReject}>
                  {reject}
                </Button>
                <Button name="RESOLVE" className="flex-grow-1" variant="primary" onClick={onResolve}>
                  {resolve}
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        )) as any)
    )();
  };
};

export default useConfirmation;

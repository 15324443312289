import { GraphApi, Toast } from 'models';
import { RunResetUserPasswordBulkInput, RunResetUserPasswordInput } from 'models/gen/graphql';
import { createNotification, getErrors, handleError } from 'utils';

import gql from 'graphql-tag';

const runResetUserPassword: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (payload: RunResetUserPasswordInput[]): Promise<any> => {
      payload = payload.map((val: RunResetUserPasswordInput): RunResetUserPasswordInput => val);
      const input: RunResetUserPasswordBulkInput = { input: payload };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data) => data?.runResetUserPasswordBulk || {},
  handler: (response: any): void => {
    const title = 'Reset User Password';
    const errors = getErrors(response);
    const hasError = !!errors?.length || !!response?.networkError;
    handleError({ ...response, errors }, { notification: { title } });
    if (hasError) return;
    return createNotification('Success', Toast.Type.SUCCESS, title);
  },
  query: gql`
    mutation RunResetUserPasswordBulk($input: RunResetUserPasswordBulkInput!) {
      runResetUserPasswordBulk(input: $input) {
        output {
          updated
          error {
            code
            message
            debug
          }
        }
      }
    }
  `,
};

export default runResetUserPassword;

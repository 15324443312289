import { generateUpdateBulkPayload, validateObj } from '../../utils';

import gql from 'graphql-tag';

const validateUser = validateObj({
  username: (val: any) => val || null,
  avatar: (val: any) => val || null,
  roleId: (val: any) => val || null,
  firstName: (val: any) => val || null,
  lastName: (val: any) => val || null,
  email: (val: any) => val || null,
  phone: (val: any) => val || null,
  countryCode: (val: any) => val || null,
  address: (val: any) => val || null,
  timezone: (val: any) => val || null,
  employeeId: (val: any) => val || null,
  dob: (val: any) => val || null,
  active: (val: any) => val || null,
  companyId: (val: any) => val || null,
  providerId: (val: any) => val || null,
  cityName: (val: any) => val || null,
  stateCode: (val: any) => val || null,
  zipCode: (val: any) => val || null,
  startDate: (val: any) => val || null,
  notes: (val: any) => val || null,
  longTrip: (val: any) => val || null,
});

const updateUserBulk = {
  wrapper:
    (fireQuery) =>
    async (...users: any[]) => {
      const updates = generateUpdateBulkPayload(users);

      return fireQuery({
        variables: {
          input: { updates },
        },
      });
    },
  selector: (data: any) => data?.updateUserBulk?.response || [],
  query: gql`
    mutation UpdateUserBulk($input: UpdateUserBulkInput!) {
      updateUserBulk(input: $input) {
        response {
          error {
            code
            debug
            message
          }
          updated
        }
      }
    }
  `,
};

export default updateUserBulk;
export { validateUser };

import { DeleteFlagBulkInput, DeleteFlagHasTripInput } from '../../models/gen/graphql';
import { customDeleteHandler, validateObj } from '../../utils';

import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const validateFlagHasTripQuery = validateObj({
  tripId: (val: string): string => val || '',
  flagId: (val: string): string => val || '',
});

const deleteFlagHasTripBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): (() => Promise<any>) =>
    async (...queryObjs: DeleteFlagHasTripInput[]): Promise<any> => {
      const input: DeleteFlagBulkInput = {
        search: queryObjs.map(validateFlagHasTripQuery),
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): number => data?.deleteFlagHasTripBulk?.deleted || 0,
  handler: customDeleteHandler('Unassign Flag from Trip', 'flag', 'deleteFlagHasTripBulk'),
  query: gql`
    mutation DeleteFlagHasTripBulk($input: DeleteFlagHasTripBulkInput!) {
      deleteFlagHasTripBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteFlagHasTripBulk;
export { validateFlagHasTripQuery };

import { CreateUserBulkInput, CreateUserResponse, User } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreateUserBulkDocument } from '@/api/queries';
import { Datetime } from '@/utils';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validateUser = validateObj({
  active: (val: any): any => (val ? 1 : 0),
  address: (val: any): any => val || null,
  avatar: (val: any): any => val || null,
  cityName: (val: any): any => val || null,
  companyId: (val: any): any => val || '',
  countryCode: (val: any): any => parseInt(val) || 0,
  dob: (val: any): any => new Datetime(val).toString() || null,
  email: (val: any): any => val || null,
  employeeId: (val: any): any => val || null,
  firstName: (val: any): any => val || '',
  lastName: (val: any): any => val || '',
  longTrip: (val: any): any => (val ? 1 : 0),
  notes: (val: any): any => val || null,
  password: (val: any): any => val || '',
  phone: (val: any): any => val || null,
  providerId: (val: any): any => val || null,
  resetClientIp: (val: any): any => val || null,
  resetCode: (val: any): any => val || null,
  resetCreatedAtUtc: (val: any): any => val || null,
  roleId: (val: any): any => val || '',
  startDate: (val: any): any => new Datetime(val).toString() || null,
  stateCode: (val: any): any => val || null,
  timezone: (val: any): any => val || null,
  username: (val: any): any => val || null,
  vehicleId: (val: any): any => val || null,
  zipCode: (val: any): any => val || null,
});

type CreateUserBulkGraphApiResponse = GraphApiResponse<typeof CreateUserBulkDocument>;

const title = 'Create Users';

const createUserBulkResponseSelector = (res: CreateUserBulkGraphApiResponse): CreateUserResponse[] =>
  !res.errors ? ((res?.createUserBulk?.output || []) as CreateUserResponse[]) : undefined;

const [runCreateUserBulk] = graphApi(CreateUserBulkDocument, {
  onError: (res: CreateUserBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createUserBulk = async (users: User[]): Promise<CreateUserResponse[]> => {
  const input: CreateUserBulkInput = {
    values: users.map(validateUser),
  };

  const res = await runCreateUserBulk({ input });
  const selected = createUserBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateUserBulk = createGraphApiHook(createUserBulk);

export default createUserBulk;

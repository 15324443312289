import { Button, ButtonProps } from 'react-bootstrap';
import React, { ReactNode, useMemo } from 'react';

import { Link } from 'react-router-dom';
import { Middle } from '../Align';
import TippyWhen from '../TippyWhen';
import useMediaQuery from '../../hooks/useMediaQuery';

export type FormButtonProps = {
  icon?: JSX.Element;
  tooltip?: string | React.ReactNode;
  feedback?: string | React.ReactNode;
  maxWidth?: number;
  loading?: boolean;
  to?: string;
} & ButtonProps;

const FormButton = ({
  icon,
  tooltip,
  maxWidth = 1399,
  children,
  loading = false,
  disabled = false,
  feedback,
  to,
  ...props
}: FormButtonProps): JSX.Element => {
  const isWithinRange = useMediaQuery(maxWidth !== null ? `(max-width: ${maxWidth}px)` : undefined);
  const displayTooltipWithinRange = !!icon && isWithinRange && (!!tooltip || !!children);

  const content = useMemo(
    (): ReactNode => (
      <Button {...props} disabled={disabled || loading}>
        <Middle.Center className="d-flex gap-2">
          {icon && !loading && <span style={{ lineHeight: 1.5, height: '1.5rem' }}>{icon}</span>}
          {icon && loading && (
            <span style={{ lineHeight: 1.5, height: '1.5rem' }}>
              <i className="fa fa-spinner fa-pulse" />
            </span>
          )}
          {children && !displayTooltipWithinRange && <span>{children}</span>}
        </Middle.Center>
      </Button>
    ),
    [children, disabled, displayTooltipWithinRange, icon, loading, props]
  );

  return (
    <TippyWhen isTrue={!!feedback || displayTooltipWithinRange} options={{ content: feedback || tooltip || children }}>
      {to === undefined ? <span>{content}</span> : <Link to={to}>{content}</Link>}
    </TippyWhen>
  );
};

export default FormButton;

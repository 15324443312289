import { Button, Toast } from 'react-bootstrap';

import { Datetime } from '../../utils';
import Portal from '@/components/Portal';
import React from 'react';
import { Toast as ToastModel } from '../../models';

interface NotificationProps extends ToastModel.Notification {
  onClose?: (event?: React.MouseEvent) => void;
  onClick?: (event?: React.MouseEvent) => void;
  icon?: React.JSX.Element;
  shownAt: number;
}
const Notification = ({
  onClose,
  title,
  icon,
  type,
  content,
  info = '',
  options = {
    redirect: '',
    moreDetails: '',
    clearAll: false,
  },
  shownAt,
}: NotificationProps): React.JSX.Element => {
  const isDebug = window.location.search.includes('debug') || window.location.host === 'localhost:5000';

  const handleRedirect = (event?: React.MouseEvent): void => {
    if (event) event.preventDefault();
    window.location.href = `${options.redirect}${window.location.search}`;
    onClose();
  };

  return (
    <Portal.When container={type === ToastModel.Type.DANGER ? document.querySelector('.ErrorContainer') : undefined}>
      <Toast className="Notification" bg={type} show={true}>
        <Toast.Body>
          <div className="d-flex w-100">
            <div className="d-flex flex-column flex-grow-1 justify-content-center">
              <div className="Notification-Header d-flex justify-content-start">
                {icon && icon}
                {title && <strong>{title}</strong>}
              </div>
              <div className="Notification-Body d-flex justify-content-start">
                {typeof content === 'string' &&
                  content.split('\n').map(
                    (text: string, idx: number): React.JSX.Element => (
                      <React.Fragment key={idx}>
                        {text}
                        <br />
                      </React.Fragment>
                    )
                  )}
              </div>
              {((isDebug && !!options?.moreDetails) || !!info) && (
                <div className="Notification-Footer d-flex justify-content-start mt-2">
                  {isDebug && options?.moreDetails && (
                    <details>
                      <summary>
                        <small>More Details</small>
                      </summary>
                      {options?.moreDetails}
                    </details>
                  )}
                  {info && <small className="Notification-Info opacity-50">{info}</small>}
                </div>
              )}
            </div>
            <div className="d-flex gap-2">
              {shownAt && <small className="Notification-Timestamp">{new Datetime(shownAt).asDayjs().fromNow()}</small>}
              {options?.redirect && (
                <Button variant="link" onClick={handleRedirect}>
                  Click Here
                  <i className="fa fa-external-link ms-2" />
                </Button>
              )}
              <Button className="p-0" variant="icon" onClick={onClose}>
                <i className="sv sv-cross fs-2" />
              </Button>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </Portal.When>
  );
};

export default Notification;

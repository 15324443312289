import { UpdateVehicleBulkInput, Vehicle } from '../../models/gen/graphql';
import { Validation, customUpdateHandler, generateUpdateBulkPayload } from '../../utils';

import { GraphApi } from '../../models';
import { createVehicleValidator } from './createVehicleBulk';
import gql from 'graphql-tag';

const updateVehicleValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createVehicleValidator
);

const updateVehicleBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...vehicles: Vehicle[]): Promise<any> => {
      vehicles = vehicles.map((vehicle: Vehicle): Vehicle => updateVehicleValidator.partial(vehicle));
      const updates = generateUpdateBulkPayload(vehicles);
      const input: UpdateVehicleBulkInput = {
        updates,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.updateVehicleBulk?.response || [],
  handler: customUpdateHandler('Update Vehicle', 'updateVehicleBulk'),
  query: gql`
    mutation UpdateVehicleBulk($input: UpdateVehicleBulkInput!) {
      updateVehicleBulk(input: $input) {
        response {
          error {
            code
            debug
            message
          }
          updated
        }
      }
    }
  `,
};

export default updateVehicleBulk;
export { updateVehicleValidator };

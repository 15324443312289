import './styles.scss';

import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';

import { FileUploader } from 'react-drag-drop-files';
import { processFilesForUpload } from '../../utils';
import { stringify } from '../../utils/objects';

const initFileUploadState = {
  files: [],
  errorMessage: '',
};
const FileUpload = ({
  label,
  onChange,
  placeholder,
  submitButtonText,
  File,
  onReady: onChangeReady,
  progress,
  supportedExtensions = [],
}) => {
  const [state, setState] = useState(initFileUploadState);
  const { files, errorMessage } = state;

  const isFileSupported = (file) => {
    let fileExtensionRegex = /[0-9a-z]+$/i;
    return (supportedExtensions || []).includes(file.name.match(fileExtensionRegex)[0]);
  };

  const handleAdd = async (fileList) => {
    const fileArray = Object.values(fileList);
    if (fileArray.every(isFileSupported)) {
      const newFiles = await processFilesForUpload(fileList);
      setState((current) => ({
        ...current,
        files: [...current.files, ...newFiles],
        errorMessage: '',
      }));
    } else {
      setState((current) => ({
        ...current,
        errorMessage:
          'One or more file types were not supported. Please select only supported types: ' + (supportedExtensions || []).join(', '),
      }));
      return;
    }
  };
  const onRemove = (index) => () =>
    setState((current) => ({
      ...current,
      files: current.files.filter((file, f) => f !== index),
    }));
  const onReady = (index) => (id, url, contentType) =>
    setState((current) => ({
      ...current,
      files: current.files.map((file, f) => (f === index ? { ...file, id, url, contentType, progress: 0 } : file)),
    }));
  const ready = useMemo(() => (files || []).length && !files.find(({ url }) => url === undefined), [files]);

  useEffect(() => {
    onChange(files);
    onChangeReady(ready);
  }, [ready]);
  useEffect(() => {
    const currentProgress = {};
    (files || []).forEach((file) => (currentProgress[file.md5] = file.progress));
    if (stringify.compare(progress, currentProgress)) return;
    setState((current) => {
      const updatedFiles = current.files;
      Object.entries(progress).forEach(([fileMd5, fileProgress]) => {
        const fileIndex = updatedFiles.findIndex((file) => file.md5 === fileMd5);
        if (updatedFiles[fileIndex]) updatedFiles[fileIndex].progress = fileProgress;
      });
      return { ...current, files: updatedFiles };
    });
  }, [progress]);

  return (
    <div className="FileUpload">
      <Form.Group className="mb-3">
        <Form.Label id="file-upload-label">{!(files || []).length && <div>{label}</div>}</Form.Label>
        <FileUploader classes="drag-and-drop border rounded text-center" handleChange={handleAdd} multiple={true}>
          <div className="drag-area d-flex flex-column mb-1">
            <i className="upload-icon fa fa-2x fa-file-lines text-secondary my-2" />
            <div className="m-0">{placeholder}</div>
          </div>
          <Button className="mt-2 px-5" name="SUBMIT_UPLOAD">
            {submitButtonText || 'Upload'}
          </Button>
        </FileUploader>
      </Form.Group>
      {File &&
        (files || []).map(({ file: { name, size }, md5, providerId, progress }, f) => (
          <File
            name={name}
            size={size}
            md5={md5}
            provider={providerId}
            progress={progress}
            onRemove={onRemove(f)}
            onReady={onReady(f)}
            key={md5}
          />
        ))}
      <div className="text-danger pt-3">{errorMessage}</div>
    </div>
  );
};

export default FileUpload;

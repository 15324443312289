// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lottie-anim {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Lottie/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;AACF","sourcesContent":[".lottie-anim {\n  position: relative;\n  width: 100%;\n  height: auto;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import gql from 'graphql-tag';
import GraphApi from '../../models/GraphApi';
import { GetMetricInput, Metric, MetricMathEnum, MetricNumberFieldEnum } from '../../models/gen/graphql';

const createMetric = (
  key: string,
  math: MetricMathEnum = MetricMathEnum.Average,
  numberField: MetricNumberFieldEnum = MetricNumberFieldEnum.Rate
): Metric => ({ key, math, numberField });

const getMetric: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (input: GetMetricInput): Promise<any> => {
      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any => data?.getMetric || {},
  query: gql`
    query GetMetric($input: GetMetricInput!) {
      getMetric(input: $input) {
        history {
          buckets {
            count
            key
            name
            value
          }
          count
          date
          pipelines {
            bucketKey
            key
            value
          }
        }
        pipelines {
          bucketKey
          key
          value
        }
      }
    }
  `,
};

export default getMetric;
export { createMetric };

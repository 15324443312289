import {
  CreatePickupPointBulkInput,
  CreatePickupPointInput,
  CreatePickupPointResponse,
  PickupPoint,
  Point,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreatePickupPointBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { Validation } from '../../../utils/validations';
import { convertImagesToCreateImageInputs } from './createImageBulk';
import graphApi from '../..';

type CreatePickupPointGraphApiResponse = GraphApiResponse<typeof CreatePickupPointBulkDocument>;

const title = 'Create Pickup Point';

export const createPickupPointValidator = new Validation.Validator({
  'coordinates!': (val: Point): Validation.Validity => ({
    valid: !!val?.lat && !!val?.lng && Validation.isNumber(val?.lat) && Validation.isNumber(val?.lng) ? true : undefined,
  }),
  'endDatetime!': (val: string): Validation.Validity => ({
    valid:
      !!val && val?.split(':').length === 3 && val?.split(':').every((numStr: string): boolean => Validation.isNumber(parseInt(numStr)))
        ? true
        : val?.split(':').length
          ? false
          : undefined,
  }),
  'startDatetime!': (val: string): Validation.Validity => ({
    valid:
      !!val && val?.split(':').length === 3 && val?.split(':').every((numStr: string): boolean => Validation.isNumber(parseInt(numStr)))
        ? true
        : val?.split(':').length
          ? false
          : undefined,
  }),
  instructions: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
  keyword: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
  'radius!': (val: number): Validation.Validity => ({
    valid: !!val && Validation.isNumber(val) ? true : undefined,
    defaultValue: 100,
  }),
  'locationId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
  'providerId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    message: !Validation.isValidUUID(val) ? 'Please select provider.' : undefined,
    defaultValue: '',
  }),
});
const createPickupPointResponseSelector = (res: CreatePickupPointGraphApiResponse): PickupPoint[] =>
  !res.errors ? (res?.createPickupPointBulk?.output || []).map(({ node }: CreatePickupPointResponse): PickupPoint => node) : undefined;

export const [runCreatePickupPointBulk] = graphApi(CreatePickupPointBulkDocument, {
  onError: (res: CreatePickupPointGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createPickupPointBulk = async (pickupPoints: PickupPoint[]): Promise<PickupPoint[]> => {
  const values: CreatePickupPointInput[] = pickupPoints.map(({ images, ...point }: PickupPoint): CreatePickupPointInput => {
    const validPickupPoint = createPickupPointValidator.create(point);
    return {
      ...validPickupPoint,
      images: convertImagesToCreateImageInputs(images || []),
    };
  });
  const input: CreatePickupPointBulkInput = {
    values,
  };
  const res = await runCreatePickupPointBulk({ input });
  const data = createPickupPointResponseSelector(res);

  if (data) createNotification('Success', Toast.Type.SUCCESS, title);

  return data;
};

export default createPickupPointBulk;

import {
  CreatePotentialTripBulkInput,
  CreatePotentialTripBulkResponse,
  PotentialTrip,
  TripKindEnum,
  TripTypeEnum,
} from '../../../models/gen/graphql';
import { Datetime, Validation } from '../../../utils';
import { createNotification, handleError, validateObj } from '../../../utils/custom';

import { CreatePotentialTripBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export const validatePotentialTrip = validateObj({
  airportCode: (val: any): any => val,
  attendants: (val: any): number => parseInt(val || 0) || 0,
  bufferMinutes: (val: any): any => val || 0,
  doLocationId: (val: any): any => val,
  flightNumber: (val: any): number => parseInt(val || 0) || 0,
  fromManifest: (val: any): any => (val ? 1 : 0),
  importId: (val: any): any => val,
  kind: (val: any): any => val,
  loopName: (val: any): any => val,
  payerProviderId: (val: any): any => val,
  pilots: (val: any): number => parseInt(val || 0) || 0,
  puLocationId: (val: any): any => val,
  scheduled: (val: any): any => val,
  servicerIataAirlineCode: (val: any): any => val || '',
  split: (val: any): any => val || 0,
  tripId: (val: any): any => val,
});

const keys = [
  'airportCode',
  'payerProviderId',
  'providerId',
  'servicerIataAirlineCode',
  'puLocationId',
  'doLocationId',
  'flightNumber',
  'attendants',
  'pilots',
  'type',
  'kind',
  'status',
  'crewId',
  'firstName',
  'lastName',
];

export const createPotentialTripBulkValidator = (trip: any, upsProviderId: string): any => {
  const validateKey = (key: string, val: any): any => {
    let isValid = undefined;
    const message = '';
    switch (key) {
      case 'airportCode': {
        isValid = !!val;
        break;
      }
      case 'payerProviderId': {
        isValid = !!val;
        break;
      }
      case 'servicerIataAirlineCode': {
        isValid = !!val;
        break;
      }
      case 'puLocationId': {
        isValid = !!val;
        break;
      }
      case 'doLocationId': {
        isValid = !!val;
        break;
      }
      case 'scheduled': {
        isValid = !!trip?.scheduledDays?.length || !!new Datetime(val || '').asDayjs().isValid();
        break;
      }
      case 'flightNumber': {
        isValid = val !== '' && Number(val) >= 0 && Number(val) <= 9999;
        break;
      }
      case 'type': {
        isValid = Object.values(TripTypeEnum).includes(val);
        break;
      }
      case 'kind': {
        isValid = Object.values(TripKindEnum).includes(val);
        break;
      }
      case 'crewId': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : Validation.isNumber(parseInt(val));
        break;
      }
      case 'firstName': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : !!val && Validation.isAlphanumeric(val);
        break;
      }
      case 'lastName': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : !!val && Validation.isAlphanumeric(val);
        break;
      }
    }
    return { valid: isValid, message };
  };
  const result = {};
  keys.forEach((key: string): any => (result[key] = validateKey(key, trip?.[key])));
  return result;
};

type CreatePotentialTripBulkGraphApiResponse = GraphApiResponse<typeof CreatePotentialTripBulkDocument>;

const title = 'Create Potential Trip';

const createPotentialTripBulkResponseSelector = (res: CreatePotentialTripBulkGraphApiResponse): CreatePotentialTripBulkResponse =>
  !res.errors ? (res?.createPotentialTripBulk as CreatePotentialTripBulkResponse) : undefined;

const [runCreatePotentialTripBulk] = graphApi(CreatePotentialTripBulkDocument, {
  onError: (res: CreatePotentialTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createPotentialTripBulk = async (...trips: PotentialTrip[]): Promise<CreatePotentialTripBulkResponse> => {
  const input: CreatePotentialTripBulkInput = {
    values: trips.map(validatePotentialTrip),
  };

  const res = await runCreatePotentialTripBulk({ input });
  const selected = createPotentialTripBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreatePotentialTripBulk = createGraphApiHook(createPotentialTripBulk);

export default createPotentialTripBulk;

import { ResetPasswordLinkCodeInput, ResetPasswordLinkCodeResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { GraphApiResponse } from '@/api/core';
import { ResetPasswordLinkCodeDocument } from '@/api/queries';
import Toast from '@/models/Toast';
import { Validation } from '@/utils';
import graphApi from '@/api';

type ResetPasswordLinkCodeGraphApiResponse = GraphApiResponse<typeof ResetPasswordLinkCodeDocument>;

const title = 'Reset Password Link Code';

const resetPasswordLinkCodeResponseSelector = (res: ResetPasswordLinkCodeGraphApiResponse): ResetPasswordLinkCodeResponse =>
  res?.resetPasswordLinkCode;

const [runResetPasswordLinkCode] = graphApi(ResetPasswordLinkCodeDocument, {
  onError: (res: ResetPasswordLinkCodeGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const resetPasswordLinkCode = async (
  code: string,
  username: string,
  password: string,
  confirmPassword: string
): Promise<ResetPasswordLinkCodeResponse> => {
  const credential = Validation.isEmail(username) ? 'email' : 'username';
  const input: ResetPasswordLinkCodeInput = {
    code,
    [credential]: username,
    password,
    confirmPassword,
  };
  const res = await runResetPasswordLinkCode({ input });
  const data = resetPasswordLinkCodeResponseSelector(res);
  if (data) createNotification('Success', Toast.Type.SUCCESS, title);
  return data;
};

export default resetPasswordLinkCode;

import { FilterOption } from '@/models/gen/graphql';
import { GetImportContractProviderDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetImportContractProviderGraphApiResponse = GraphApiResponse<typeof GetImportContractProviderDocument>;

const getImportContractProviderResponseSelector = (res: GetImportContractProviderGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.importContractProvider as Array<FilterOption>;

const [runGetImportContractProvider] = graphApi(GetImportContractProviderDocument, {
  onError: (res: GetImportContractProviderGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Get Import Contract Provider' } }),
});

export const getImportContractProvider = async (): Promise<Array<FilterOption>> => {
  const res = await runGetImportContractProvider();
  return getImportContractProviderResponseSelector(res);
};

export const useGetImportContractProvider = createGraphApiHook(getImportContractProvider);

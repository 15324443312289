import { RateItemCurrencyEnum, RateItemTypeEnum } from '../../models/gen/graphql';

import { CreateRateGroupBulkDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const createRateGroupBulk = createGraphApiMockResponse(CreateRateGroupBulkDocument, {
  createRateGroupBulk: {
    output: [
      {
        error: null,
        node: {
          airportCode: 'ATL',
          companyId: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
          createdAt: null,
          createdBy: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
          duration: 0,
          id: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
          items: [
            {
              createdAt: null,
              createdBy: null,
              currency: RateItemCurrencyEnum.Usd,
              endDatetime: '2024-07-01T00:00:00',
              id: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
              rate: 5,
              rateGroupId: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
              startDatetime: '2024-07-01T00:00:00',
              type: RateItemTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'RateItem',
            },
          ],
          payerProviderId: null,
          thatLocationId: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
          thisLocationId: 'da1a85f8-79dc-4b6b-ba31-54e27e89b961',
          tripDistance: 0,
          updatedAt: null,
          updatedBy: null,
          __typename: 'RateGroup',
        },
        __typename: 'CreateRateGroupResponse',
      },
    ],
    __typename: 'CreateRateGroupBulkResponse',
  },
});

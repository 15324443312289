import { OverflowCell } from '@/components/VirtualTable/TemplateCell/tables/drivetimeReportTable';
import React from 'react';
import { TemplatedCell } from '..';
import { getClasses } from '@/utils';

const DiffCell = ({ value }: { value: string }): JSX.Element => {
  const classes = getClasses(value.startsWith('+') ? 'text-danger' : undefined, value.startsWith('-') ? 'text-success' : undefined);
  const [time, date] = value?.split?.(' - ') || [value];
  return (
    <>
      <span className={classes}>
        {time}
        {date ? ' - ' : ''}
      </span>
      {date && <span>{date}</span>}
    </>
  );
};

export default (key: string): TemplatedCell => {
  switch (key) {
    // Defaults
    case 'date':
    case 'expectedClockin':
    case 'actualClockin':
    case 'totalWorked':
      return { props: { className: 'text-start', maxWidth: '6rem' } };
    // Custom Cells
    case 'availabilityDiffIn':
    case 'diffIn':
    case 'diffOut':
      return { cell: DiffCell, props: { className: 'text-start', maxWidth: '6rem' } };
    case 'city':
      return { cell: OverflowCell, props: { className: 'text-start', maxWidth: '8rem' } };
    case 'name':
      return { cell: OverflowCell, props: { className: 'text-start', maxWidth: '8rem' } };
    case 'driver':
      return { cell: OverflowCell, props: { className: 'text-start', minWidth: '3rem', maxWidth: '6rem' } };
    case 'diffTotal':
      return { cell: DiffCell, props: { className: 'text-start', minWidth: '5rem', maxWidth: '6rem' } };
    case 'expectedClockout':
    case 'actualClockout':
      return { cell: DiffCell, props: { className: 'text-start', minWidth: '8rem', maxWidth: '10rem', style: { whiteSpace: 'nowrap' } } };
    case 'firstPickup':
    case 'lastDropoff':
      return { cell: OverflowCell, props: { className: 'text-start', minWidth: '8rem' } };
    default:
      return {};
  }
};

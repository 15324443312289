import './styles.scss';

import { DATE_FE_FORMAT, TODAY } from '../../constants';
import React, { MutableRefObject, useRef } from 'react';

import { DATE_INPUT_FORMAT } from '../../constants';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import useClassNames from '../../hooks/useClassNames';

type DateInputProps = {
  className?: string;
  name: string;
  value: string;
  onChange: any;
  onBlur?: any;
  isValid?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  picker?: 'week' | 'month';
  [x: string]: any;
};

const DateInput = ({
  className,
  name,
  value,
  onChange,
  isValid,
  isInvalid,
  readOnly,
  disabled,
  picker,
  ...props
}: DateInputProps): JSX.Element => {
  const ref: MutableRefObject<any> = useRef();
  const classes = useClassNames(
    className,
    'flex-grow-1 border',
    isValid !== undefined && !!isValid ? 'isValid border-success' : undefined,
    isInvalid !== undefined && !!isInvalid ? 'isInvalid border-danger' : undefined
  );
  const handleChange = (value: any): void => {
    if (value && typeof value !== 'string') {
      value = value.format(DATE_INPUT_FORMAT);
    }
    onChange({ target: { name, value } });
  };

  const onKeyDown = (event: any): void => {
    if (!ref?.current) return;
    const key = event.key;
    switch (key) {
      case 'Tab':
      case 'Enter': {
        event.preventDefault();
        event.stopPropagation();
        handleChange(event?.target?.value);
        if (key === 'Tab') ref.current.blur();
      }
      default: {
        break;
      }
    }
  };

  return (
    <DatePicker
      className={classes}
      name={name}
      value={dayjs(value || TODAY, DATE_INPUT_FORMAT)}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      size="small"
      suffixIcon={<i className="fa fa-regular fa-calendar" />}
      allowClear={false}
      inputReadOnly={!!readOnly}
      disabled={disabled}
      format={DATE_FE_FORMAT}
      picker={picker}
      {...props}
      placeholder={picker === 'week' ? '--/--/-- - --/--/--' : '--/--/--'}
      ref={ref}
    />
  );
};

export default DateInput;
export { DateInputProps };

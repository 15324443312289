import {
  ConnectionDetails,
  HandleErrorInput,
  convertConnectionToDetails,
  generateQuery,
  handleError,
  mergeConnectionDetails as merge,
} from '@/utils/custom';
import { ConnectionOptions, GraphApiMethod } from '@/api/core';
import { SearchCombinableTripsResponseCombinableTripsConnectionArgs, Trip, TripSearch } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { SearchCombinableTripsDocument } from '@/api/queries';
import { TypedDocumentNode } from '@apollo/client';
import { getProperty } from '@/utils';
import graphApi from '@/api';

const queryKeys = ['id'];
const SEARCH_COMBINABLE_TRIPS_PAGE_SIZE = 30;

// Global Service Variables
const mergeKey = 'searchCombinableTrips.combinableTripsConnection';
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Search Combinable Trips' } });

// Service Function Signature
interface ServiceMethod {
  (query?: TripSearch[], options?: ConnectionOptions): Promise<ConnectionDetails<Trip[]>>;
}

// SearchCombinableTrips Service
export const { searchCombinableTrips, refetchCombinableTrips, useSearchCombinableTrips } = ((): {
  searchCombinableTrips: ServiceMethod;
  refetchCombinableTrips: ServiceMethod;
  useSearchCombinableTrips: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [fetch, refetch] = graphApi(SearchCombinableTripsDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<TypedDocumentNode>): ServiceMethod =>
      async (queryObjects?: TripSearch[], options: ConnectionOptions = {}): Promise<ConnectionDetails<Trip[]>> => {
        const { pageSize = SEARCH_COMBINABLE_TRIPS_PAGE_SIZE, page = 0, merge = false } = options;

        const args: SearchCombinableTripsResponseCombinableTripsConnectionArgs = {
          input: {
            first: pageSize || null,
            after: pageSize ? (pageSize * page).toString() : null,
            query: generateQuery(queryKeys, ...queryObjects),
          },
        };
        const opts = { merge: merge ? mergeKey : undefined };
        const res = await fn(args, opts);
        return convertConnectionToDetails(getProperty(mergeKey, res));
      }
  );
  const useService = createGraphApiHook(fetch, { refetch, merge });
  return {
    searchCombinableTrips: fetch,
    refetchCombinableTrips: refetch,
    useSearchCombinableTrips: useService,
  };
})();

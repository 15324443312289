import './styles.scss';

import RangeSlider, { RangeSliderProps } from '@/components/RangeSlider';

import React from 'react';

const PunchPolicySlider = (props: RangeSliderProps): JSX.Element => {
  return (
    <div className="PunchPolicySlider">
      <RangeSlider stepSize={15} tickSize={15} handleLabel={(_val: number, index: number): number => index + 1} {...props} />
    </div>
  );
};

export default PunchPolicySlider;

import { Button, Col, Row } from 'react-bootstrap';

import { LoadingBlur } from '@/components/LoadingSpinner';
import { useSearchRateHistory } from '@/api/services/rates/searchRateHistory';
import { Datetime, queryInput, QueryInputType } from '@/utils';
import { useEffect, useState } from 'react';
import { RateHistory, SortDirectionEnum, TripTableFormatEnum } from '@/models/gen/graphql';
import Tippy from '@tippyjs/react';
import useHashNavigation from '@/hooks/useHashNavigation';
import { DATETIME_FE_FORMAT_FULL } from '@/constants';
export type RateHistoryProps = {
  rateId: string;
};

const RateHistory = ({ rateId }: RateHistoryProps) => {
  const [{ loading }, { refetch: searchRateHistory }] = useSearchRateHistory();
  const [rateHistory, setRateHistory] = useState<RateHistory[]>(undefined);
  const [, navigate] = useHashNavigation();

  useEffect(() => {
    if (!rateId) return;
    searchRateHistory([{ rateGroupId: queryInput(rateId), createdAt: queryInput([], QueryInputType.ISNOTNULL, SortDirectionEnum.Desc) }])
      .then((res) => setRateHistory(res?.rows))
      .catch(console.error);
  }, [rateId, searchRateHistory]);

  return (
    <div className="w-100 h-100">
      <LoadingBlur loading={loading} />
      {rateHistory?.length === 0 && (
        <Row className="text-primary text-center">
          <Col sm={12}>
            <em>No rate history found.</em>
          </Col>
        </Row>
      )}
      {rateHistory?.length > 0 &&
        rateHistory?.map?.(({ rate, message, createdAt }, index: number) => {
          const formatedDate = new Datetime().setAsUTC(createdAt).toLocaleDatetime().format(DATETIME_FE_FORMAT_FULL);
          return (
            <Row key={index}>
              <Col sm={1}>
                <div className="w-100 d-flex justify-content-end pointer align-items-center">
                  <Tippy content="View Trips" placement="right">
                    <Button
                      variant="icon"
                      onClick={() => navigate('/trips', { rateAmount: rate.toFixed(2), format: TripTableFormatEnum.All })}
                      className="p-0"
                    >
                      <i className="sv sv-earth sv-2x text-primary" />
                    </Button>
                  </Tippy>
                </div>
              </Col>
              <Col sm={11}>
                <div className="text-primary d-flex flex-column justify-content-start">
                  <strong>{`${formatedDate}`}</strong>
                  <p>
                    <span>{message}</span>
                  </p>
                </div>
              </Col>
            </Row>
          );
        })}
    </div>
  );
};

export default RateHistory;

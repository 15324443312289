import { Communication, CommunicationEdge, CreateCommunicationBulkInput, CreateCommunicationInput } from '../../models/gen/graphql';
import { Validation, customCreateHandler } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const createCommunicationValidator = new Validation.Validator({
  message: (val: any): Validation.Validity => ({
    valid: !!(val || '').trim().length && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  tripId: (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
});

const createCommunicationBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...communications: CreateCommunicationInput[]): Promise<any> => {
      const input: CreateCommunicationBulkInput = {
        values: communications.map(
          (communication: CreateCommunicationInput): CreateCommunicationInput => createCommunicationValidator.create(communication)
        ),
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): { rows: Communication[] } => ({
    rows: (data?.createCommunicationBulk?.output || []).map(({ node }: CommunicationEdge): Communication => node),
  }),
  handler: customCreateHandler('Create Communication for Trip', 'createCommunicationBulk'),
  query: gql`
    mutation CreateCommunicationBulk($input: CreateCommunicationBulkInput!) {
      createCommunicationBulk(input: $input) {
        output {
          error
          node {
            # createdAt
            # createdBy
            # creator {
            #   active
            #   address
            #   airports
            #   availability {
            #     approved
            #     createdAt
            #     createdBy
            #     dayOfWeek
            #     end
            #     id
            #     repeatUntil
            #     start
            #     type
            #     updatedAt
            #     updatedBy
            #     userId
            #   }
            #   avatar
            #   cityName
            #   companyId
            #   countryCode
            #   createdAt
            #   createdBy
            #   dob
            #   email
            #   employeeId
            #   firstName
            #   groups {
            #     active
            #     companyId
            #     createdAt
            #     createdBy
            #     id
            #     name
            #     permissions {
            #       createdAt
            #       createdBy
            #       effect
            #       entity
            #       id
            #       resource
            #       updatedAt
            #       updatedBy
            #       verb
            #     }
            #     updatedAt
            #     updatedBy
            #     users {
            #       active
            #       address
            #       airports
            #       avatar
            #       cityName
            #       companyId
            #       countryCode
            #       createdAt
            #       createdBy
            #       dob
            #       email
            #       employeeId
            #       firstName
            #       id
            #       lastName
            #       longTrip
            #       notes
            #       password
            #       phone
            #       providerId
            #       resetClientIp
            #       resetCode
            #       resetCreatedAtUtc
            #       roleId
            #       startDate
            #       stateCode
            #       timezone
            #       updatedAt
            #       updatedBy
            #       username
            #       vehicleId
            #       zipCode
            #     }
            #   }
            id
            #   lastLogin {
            #     datetime
            #   }
            #   lastName
            #   licenses {
            #     createdAt
            #     createdBy
            #     expirationDate
            #     id
            #     licenseTypeId
            #     number
            #     stateCode
            #     updatedAt
            #     updatedBy
            #     userId
            #   }
            #   longTrip
            #   notes
            #   password
            #   phone
            #   provider {
            #     billFrequency
            #     billingAddress
            #     billingCityCode
            #     billingStateCode
            #     billingZipCode
            #     contactPerson
            #     createdAt
            #     createdBy
            #     displayName
            #     emails {
            #       address
            #       createdAt
            #       createdBy
            #       id
            #       providerId
            #       type
            #       updatedAt
            #       updatedBy
            #     }
            #     faxNumber
            #     id
            #     importCode
            #     mobilePhoneNumber
            #     name
            #     occPhoneNumber
            #     primaryAddress
            #     primaryCityCode
            #     primaryEmail
            #     primaryLogoUrl
            #     primaryPhoneNumber
            #     primaryStateCode
            #     primaryZipCode
            #     referenceNumber
            #     type
            #     updatedAt
            #     updatedBy
            #     website
            #   }
            #   providerId
            #   ratingDetails {
            #     averageRating
            #     rating {
            #       comment
            #       compliments
            #       createdAt
            #       createdBy
            #       id
            #       rating
            #       ratingType {
            #         createdAt
            #         createdBy
            #         id
            #         name
            #         updatedAt
            #         updatedBy
            #       }
            #       ratingTypeId
            #       tripId
            #       updatedAt
            #       updatedBy
            #       userId
            #     }
            #   }
            #   resetClientIp
            #   resetCode
            #   resetCreatedAtUtc
            #   role {
            #     createdAt
            #     createdBy
            #     id
            #     name
            #     updatedAt
            #     updatedBy
            #   }
            #   roleId
            #   startDate
            #   stateCode
            #   timezone
            #   tripDetails {
            #     onTimePercentage
            #     totalAmountOfTrips
            #   }
            #   updatedAt
            #   updatedBy
            #   username
            #   vehicleId
            #   zipCode
            # }
            # id
            # message
            # tripId
            # updatedAt
            # updatedBy
          }
        }
      }
    }
  `,
};

export default createCommunicationBulk;
export { createCommunicationValidator };

import { CreateCombineTripInput, UpdateTripValuesInput } from '../../models/gen/graphql';
import { GraphApi, Toast } from '../../models';
import { createNotification, getErrors, handleError } from '../../utils';

import gql from 'graphql-tag';

const combineTrips: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (values: UpdateTripValuesInput = {}, ...tripIds: string[]): Promise<any> => {
      const input: CreateCombineTripInput = { tripIds, values };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any => data?.combineTrips || {},
  handler: (response: any): void => {
    const title = 'Combine Trips';
    const errors = getErrors(response);
    const hasError = !!errors?.length || !!response?.networkError;
    handleError({ ...response, errors }, { notification: { title } });
    if (hasError) return;

    if (response?.data?.combineTrips?.updated || 0) createNotification('Success', Toast.Type.SUCCESS, title);
    else createNotification(response?.data?.combineTrips?.error || 'Failed to combine trips', Toast.Type.DANGER, title);
  },
  query: gql`
    mutation CombineTrips($input: CreateCombineTripInput!) {
      combineTrips(input: $input) {
        updated
        error
      }
    }
  `,
};
export default combineTrips;

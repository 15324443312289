import FormField, { FormFieldModel } from '../FormField';

import { Datetime } from '../../utils/dates';
import React from 'react';

type TimeRange = { startDatetime: string; endDatetime: string }; // SHOULD BE ONLY TIME VALUE NOT DATETIME

type Props = {
  value: TimeRange;
  onChange: (event: { target: { name: string; value: TimeRange } }) => void;
  name: string;
  disabled?: boolean;
  className?: string;
  options?: {
    startDatetime?: FormFieldModel.Options;
    endDatetime?: FormFieldModel.Options;
  };
};

const TimeRangeInput = ({ value, onChange, name, className = '', disabled, options = {} as any }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name: inputName, value: inputValue } = event.target;
    const [hours = '00', minutes = '00', seconds = '00'] = inputValue.split(':');
    return onChange({
      target: { name, value: { ...value, [inputName]: `${hours}:${minutes}:${seconds}` } },
    });
  };
  return (
    <div className={`TimeRangeInput ${className}`}>
      <FormField
        name="startDatetime"
        label="Start Time:"
        type="time"
        value={value?.startDatetime ? new Datetime().setTime(value?.startDatetime).fullTime : undefined}
        onChange={handleChange}
        options={{ group: { className: '{max-width:100px;}' }, ...(options?.startDatetime || {}) }}
        condensed
        disabled={disabled}
      />
      <FormField
        name="endDatetime"
        label="End Time:"
        type="time"
        value={value?.endDatetime ? new Datetime().setTime(value?.endDatetime).fullTime : undefined}
        onChange={handleChange}
        options={{ group: { className: '{max-width:100px;}' }, ...(options?.endDatetime || {}) }}
        condensed
        disabled={disabled}
      />
    </div>
  );
};

export default TimeRangeInput;

import { InvoiceInput, InvoiceResponse, RunSaveAndSendInvoiceInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { RunSaveAndSendInvoiceDocument } from '../../queries';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type RunSaveAndSendInvoiceGraphApiResponse = GraphApiResponse<typeof RunSaveAndSendInvoiceDocument>;

const title = 'Save And Send Invoice';

const runSaveAndSendInvoiceResponseSelector = (res: RunSaveAndSendInvoiceGraphApiResponse): InvoiceResponse =>
  !res.errors ? (res?.runSaveAndSendInvoice?.output as InvoiceResponse) : undefined;

const [runRunSaveAndSendInvoice] = graphApi(RunSaveAndSendInvoiceDocument, {
  onError: (res: RunSaveAndSendInvoiceGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const runSaveAndSendInvoice = async (invoice: InvoiceInput): Promise<InvoiceResponse> => {
  const input: RunSaveAndSendInvoiceInput = { input: invoice };
  const res = await runRunSaveAndSendInvoice({ input });

  const selected = runSaveAndSendInvoiceResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useRunSaveAndSendInvoice = createGraphApiHook(runSaveAndSendInvoice);

export default runSaveAndSendInvoice;

import './styles.scss';

import { Col, Row, Toast, ToastBody } from 'react-bootstrap';
import React, { Dispatch, ReactNode, Ref, SetStateAction, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Validation, createNotification, getBulkValues, getClasses, stringify } from '@/utils';

import { EventObject } from '@/hooks/useOnChange';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { Toast as ToastType } from '@/models';
import useForm from '@/hooks/useForm';
import useValidation from '../../hooks/useValidation';

export type FormContainerProps = {
  name: string;
  initialValues: Record<string, unknown>;
  onSubmit: (form: Partial<Record<string, unknown>>) => Promise<void>;
  onError?: (error: string) => Promise<void>;
  onCancel?: (reason?: string) => void;
  formAs: (props: Record<string, unknown>) => React.ReactNode;
  options?: {
    loadingSpinner?: {
      loading?: boolean;
      label?: string;
      size?: 'sm' | 'md' | 'lg';
    };
    validator?: Validation.Validator;
    bulk?: Record<string, unknown>[];
  };
};
export type FormAsProps = {
  values: Record<string, unknown>;
  setValues: Dispatch<SetStateAction<Record<string, unknown>>>;
  onChange: (event: EventObject) => void;
  onSubmit: () => Promise<void>;
  onCancel: (reason?: string) => void;
  loading: boolean;
  error: string;
};

export type FormContainerRef = {
  isDirty: () => boolean;
};

const FormContainer = (props: FormContainerProps, ref: Ref<FormContainerRef>) => {
  const { name, initialValues, onSubmit, onError, formAs: FormAs, onCancel } = props;
  const [form, onChange, setForm] = useForm(initialValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(undefined);
  const formLoading: boolean = !!props?.options?.loadingSpinner?.loading;
  const disabled = useMemo(() => {
    const values = getBulkValues([initialValues, ...(props?.options?.bulk || [])]);
    return Object.entries(values).reduce((result, [key, value]: [string, string[]]) => {
      result[key] = value?.length > 1;
      return result;
    }, {});
  }, [initialValues, props?.options?.bulk]);
  const validation = useValidation<typeof initialValues>(props?.options?.validator, form);
  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      await onSubmit?.(form);
    } catch (err) {
      setError(err.message || err);
      handleError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const handleError = async (err: string): Promise<void> => {
    if (!onError) {
      createNotification(err, ToastType.Type.DANGER, 'Error');
    }
    onError?.(err);
  };

  const handleCancel = async (reason?: string): Promise<void> => {
    try {
      onCancel?.(reason);
    } catch (err) {
      handleError(err.message || err);
    } finally {
      setForm(initialValues);
    }
  };

  useEffect(() => {
    if (loading || formLoading) return;
    setForm(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, formLoading]);

  useImperativeHandle(ref, () => ({ isDirty: () => !stringify.compare(initialValues, form) }));

  return (
    <div className={getClasses('FormContainer', name)}>
      <LoadingBlur {...props.options?.loadingSpinner} loading={loading || formLoading} />
      {error && (
        <Row>
          <Col>
            <Toast className="Notification w-100" bg="danger" show={true} autohide={false}>
              <ToastBody>{error}</ToastBody>
            </Toast>
          </Col>
        </Row>
      )}
      <FormAs
        values={form}
        setValues={setForm}
        onChange={onChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        validation={validation}
        loading={loading || formLoading}
        disabled={disabled}
        error={error}
      />
    </div>
  );
};

const FormContainerGroup = ({ children }: { children: ReactNode }): ReactNode => (
  <div className="FormContainer-Group">
    <Row>{children}</Row>
  </div>
);

const FormContainerGroupContent = ({ children }: { children: ReactNode }): ReactNode => (
  <Col className="FormContainer-Content">
    <Row>{children}</Row>
  </Col>
);
const FormContainerGroupControl = ({ children }: { children: ReactNode }): ReactNode => (
  <Col className="FormContainer-Control">
    <Row>{children}</Row>
  </Col>
);
const FormContainerFooter = ({ children }: { children: ReactNode }): ReactNode => <div className="FormContainer-Footer">{children}</div>;

const FormContainerWithStaticProps = Object.assign(forwardRef(FormContainer), {
  Group: FormContainerGroup,
  GroupContent: FormContainerGroupContent,
  GroupControl: FormContainerGroupControl,
  Footer: FormContainerFooter,
});

export default FormContainerWithStaticProps;

import React, { useEffect, useMemo } from 'react';
import Select, { SelectProps } from '../Select';

import { FilterOption } from '../../models/gen/graphql';
import { GET_AVAILABILITY_TYPES } from '../../queries/Filter';
import useDb from '../../hooks/useDb';

type SelectAvailabilityTypeProps = {
  cache?: string;
} & SelectProps;

const SelectAvailabilityType = ({ cache, value, name, onChange, ...props }: SelectAvailabilityTypeProps): React.JSX.Element => {
  const { data, loading } = useDb('AvailabilityTypeFilters', GET_AVAILABILITY_TYPES, { ttl: 1, selector: availabilityTypeFilterSelector });

  const options = useMemo(() => (!!data && !loading ? availabilityTypeFilterSelector(data) : []), [data, loading]);

  // effect to default to first option
  useEffect((): void => {
    if (value || !options?.length) return;
    onChange({ target: { name, value: options[0]?.values || options[0]?.value } });
  }, [value, options]);

  return (
    <Select {...props} name={name} value={value} onChange={onChange} loading={loading}>
      {options.map(
        ({ label, ...opts }, o: number): React.JSX.Element => (
          <option {...opts} key={o}>
            {label}
          </option>
        )
      )}
    </Select>
  );
};

const availabilityTypeFilterSelector = (data) =>
  (data?.getFilters?.filters?.availabilityTypes || []).map((opt: FilterOption) => ({
    label: opt.displayName,
    value: opt.id,
    values: opt.values,
  }));

export default SelectAvailabilityType;

import { GetAvailableUserForTripResponse, GetAvailableUsersForTripInput } from '../../../models/gen/graphql';

import { GetAvailableUsersForTripDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Validation } from '../../../utils/validations';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';

type GetAvailableUsersForTripGraphApiResponse = GraphApiResponse<typeof GetAvailableUsersForTripDocument>;

const title = 'Get Available Users For Trip';

const getAvailableUsersForTripValidator = new Validation.Validator({
  'airportCode!': (val: string): Validation.Validity => ({
    valid: !!val,
    defaultValue: '',
  }),
  'scheduled!': (val: string): Validation.Validity => ({
    valid: !!val,
    defaultValue: '',
  }),
  actual: (val: string): Validation.Validity => ({
    valid: !!val,
  }),
});

const getAvailableUsersForTripResponseSelector = (res: GetAvailableUsersForTripGraphApiResponse): GetAvailableUserForTripResponse[] =>
  res?.getAvailableUsersForTrip?.output;

const [runGetAvailableUsersForTrip, runRefetchGetAvailableUsersForTrip] = graphApi(GetAvailableUsersForTripDocument, {
  onError: (res: GetAvailableUsersForTripGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const getAvailableUsersForTrip = async (query: GetAvailableUsersForTripInput): Promise<GetAvailableUserForTripResponse[]> => {
  const input: GetAvailableUsersForTripInput = getAvailableUsersForTripValidator.create(query);
  const res = await runRefetchGetAvailableUsersForTrip({ input });
  return getAvailableUsersForTripResponseSelector(res);
};

export const useGetAvailableUsersForTrip = createGraphApiHook(getAvailableUsersForTrip);
export default getAvailableUsersForTrip;

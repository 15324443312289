import { CreateEmailBulkInput, CreateEmailBulkResponse, CreateEmailInput, Email } from '../../../models/gen/graphql';

import { CreateEmailBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Validation } from '../../../utils';
import graphApi from '../..';

type CreateEmailBulkGraphApiResponse = GraphApiResponse<typeof CreateEmailBulkDocument>;

export const createEmailValidator = new Validation.Validator({
  type: (val: any): Validation.Validity => ({ valid: !!val }),
  address: (val: any): Validation.Validity => ({ valid: !!val }),
  providerId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
});

const createEmailBulkResponseSelector = (res: CreateEmailBulkGraphApiResponse): CreateEmailBulkResponse =>
  !res.errors ? (res?.createEmailBulk as CreateEmailBulkResponse) : undefined;

const [runCreateEmailBulk] = graphApi(CreateEmailBulkDocument);

const createEmailBulk = async (emails: Partial<Email>[]): Promise<CreateEmailBulkResponse> => {
  const values: CreateEmailInput[] = emails.map((email: Email): CreateEmailInput => createEmailValidator.create(email));
  const input: CreateEmailBulkInput = { values };

  const res = await runCreateEmailBulk({ input });
  return createEmailBulkResponseSelector(res);
};

export default createEmailBulk;

import { GetClientsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getClients = createGraphApiMockResponse(GetClientsDocument, {
  getFilters: {
    filters: {
      clients: [
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'AA Training',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'AC',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'AF',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Aloft',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'American Airlines',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'AS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'ASA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'AT',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'ATLAs',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'B6',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'BA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'CIRC',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'CP',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'CPLAX',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DALET',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DALFO',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DALNH',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DALRC',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DALUG',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DALWN',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Delta',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DFWA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DFWLA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DL-CQ-ATL',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DL-NYC-S',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DL-WINGS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DLINST',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DLTRA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DTAS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'DTHRP',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'EAIR',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'EI',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'ERJ',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'ESTBY',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'F9',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'FI',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'GOOL',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'HP',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'HP17',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'HYPDX',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'KIMP',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'KLM',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Lago Mar',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LANA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LANB',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LANC',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LANCO',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LANE',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LANP',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'LAT',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'MOA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'MP',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'MSPCC',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Nippon',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'OO',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'PO',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Prvt',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'QK',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'RBNA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'RPDX',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SH',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SHPHL',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SHS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SLAX',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SNO',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SON',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Southwest Airlines',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Spirit',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'SPT',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'TOAST',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'TPAH',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'Training R',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'TRS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'TS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'UA',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'UPS',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'US',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'wertwert',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'WJ',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'WOW',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'WSTG',
          __typename: 'FilterOption',
        },
        {
          id: 'cf5b7a32-1a80-4d05-8f4d-5c8d0b24836c',
          displayName: 'YVE',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

import { UserConnectionInput, UserSearch } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';
import { validateObj } from '../../utils';

const validateGetFilteredUsersQuery = validateObj({
  active: (val: any): any => val || null,
  airportCode: (val: any): any => val || null,
  availability: (val: any): any => val || null,
  availabilityType: (val: any): any => val || null,
  averageRating: (val: any): any => val || null,
  countryCode: (val: any): any => val || null,
  employeeId: (val: any): any => val || null,
  id: (val: any): any => val || null,
  roleId: (val: any): any => val || null,
});

const getFilteredUsers: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (first: number = PAGE_SIZE, after: string = null, query: UserSearch[], options: any): Promise<any> => {
      const input: UserConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchUsers.userConnection'),
  query: gql`
    query SearchUsers($input: UserConnectionInput) {
      searchUsers {
        userConnection(input: $input) {
          edges {
            cursor
            node {
              firstName
              lastName
              id
            }
          }
        }
      }
    }
  `,
};

export default getFilteredUsers;
export { validateGetFilteredUsersQuery };

import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Import, ImportConnection, ImportWithAirportConnectionInput, ImportWithAirportSearch } from '../../../models/gen/graphql';
import { SEARCH_IMPORTS_PAGE_SIZE, SearchImportOptions } from './searchImports';

import { GraphApiResponse } from '../../core';
import { SearchImportsWithAirportDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type SearchImportsWithAirportGraphApiResponse = GraphApiResponse<typeof SearchImportsWithAirportDocument>;

const title = 'Search Imports';

const searchImportsWithAirportResponseSelector = (res: SearchImportsWithAirportGraphApiResponse): ConnectionDetails<Import> =>
  convertConnectionToDetails(res?.searchImports?.importWithAirportConnection as ImportConnection);

const [runSearchImportsWithAirport, runRefetchSearchImportsWithAirport] = graphApi(SearchImportsWithAirportDocument, {
  onError: (res: SearchImportsWithAirportGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchImportsWithAirport = async (
  query: ImportWithAirportSearch[],
  options: SearchImportOptions = {}
): Promise<ConnectionDetails<Import>> => {
  const { pageSize = SEARCH_IMPORTS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: ImportWithAirportConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchImports.importWithAirportConnection' : undefined };

  const res = await runSearchImportsWithAirport({ input }, graphApiOptions);

  return searchImportsWithAirportResponseSelector(res);
};

const refetchSearchImportsWithAirport = async (
  query: ImportWithAirportSearch[],
  options: SearchImportOptions = {}
): Promise<ConnectionDetails<Import>> => {
  const { pageSize = SEARCH_IMPORTS_PAGE_SIZE, page = 0 } = options;

  const input: ImportWithAirportConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchImportsWithAirport({ input });

  return searchImportsWithAirportResponseSelector(res);
};

export const useSearchImportsWithAirport = createGraphApiHook(searchImportsWithAirport, {
  refetch: refetchSearchImportsWithAirport,
  merge: mergeConnectionDetails,
});

export default searchImportsWithAirport;

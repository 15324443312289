import { Validation } from '../utils';
import { useState } from 'react';

/* useLocalStorage
  Provides a hook-styled way to use the browser's LocalStorage API
  to store/retrieve data from the client's browser.

  It basically just provides a [state, setState] tuple in the same
  format as React's useState hook to allow for similar usage. All
  data stored/retrieved is automatically parsed and stored as a string
  that localStorage requires, and returned as a JSON Object.
*/

const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T | ((value: T) => T)) => T, () => void, () => T] => {
  const [storedValue, setStoredValue] = useState<T>((): T => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (Validation.isJson(item) ? JSON.parse(item) : item) : initialValue;
    } catch (error) {
      console.warn(error);
      return initialValue;
    }
  });
  const getValue = (): T => {
    try {
      const item = window.localStorage.getItem(key);
      setStoredValue(item ? JSON.parse(item) : initialValue);
      return item ? (Validation.isJson(item) ? JSON.parse(item) : item) : initialValue;
    } catch (error) {
      console.warn(error);
      setStoredValue(initialValue);
      return initialValue;
    }
  };
  const setValue = (value: T | ((value: T) => T)): T => {
    try {
      const currentValue = getValue();
      const valueToStore = value instanceof Function ? value(currentValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      return valueToStore;
    } catch (error) {
      console.error(error);
      return storedValue;
    }
  };
  const deleteValue = (): void => {
    setStoredValue(undefined);
    window.localStorage.removeItem(key);
  };

  return [storedValue, setValue, deleteValue, getValue];
};

const getLocalStorage = <T>(key: string, defaultValue: T): T => {
  try {
    const value = window.localStorage.getItem(key) as T;
    return value || defaultValue;
  } catch (error) {
    console.warn(error);
    return defaultValue;
  }
};

export default useLocalStorage;
export { getLocalStorage };

import { RateCurrencyEnum, RateTypeEnum, TripKindEnum, TripStatusEnum, TripTableConnectionInput, TripTypeEnum } from '@/models/gen/graphql';

import { SearchTripsTableDocument } from '@/api/queries';
import { VariablesOf } from '@graphql-typed-document-node/core';
import { createGraphApiMockResponse } from '@/api/core';

export const searchTripsTableConnection = createGraphApiMockResponse(
  SearchTripsTableDocument,
  {
    searchTrips: {
      tripTableConnection: {
        edges: [
          {
            cursor: '1',
            node: {
              id: '5827f19b-406b-4312-b08d-620992f663d3',
              offset: '-04:00',
              flags: null,
              communications: null,
              type: TripTypeEnum.Add,
              scheduled: '2024-05-17T04:40:00',
              scheduledUtc: '2024-05-17T11:40:00',
              trackFlight: null,
              rate: {
                rate: 50,
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Auto Generated 50.00',
                thisLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                thatLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                currency: RateCurrencyEnum.Usd,
                startDatetime: '2024-05-16T23:26:00',
                endDatetime: '2024-05-17T11:36:00',
                type: RateTypeEnum.Contract,
                providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              },
              airportCode: 'SFO',
              servicerIataAirlineCode: 'DL',
              flightNumber: 327,
              payerProvider: null,
              pilots: 2,
              attendants: 0,
              driverId: null,
              driver: null,
              vehicleId: null,
              vehicle: null,
              puLocation: {
                address: '1333 OLD BAYSHORE HWY',
                cityName: 'Burlingame',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Hyatt Regency San Francisco Airport',
                stateCode: 'CA',
                zipCode: '94010',
                aliases: [
                  {
                    name: 'HYATT SFO AIRPORT',
                  },
                  {
                    name: 'hyatt regency sfo airport',
                  },
                ],
              },
              doLocation: {
                address: 'SAN FRANCISCO',
                cityName: '',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'SFO',
                stateCode: 'CA',
                zipCode: '94128',
                aliases: null,
                airports: [
                  {
                    airportCode: 'SFO',
                  },
                ],
              },
              puActualDatetime: null,
              puCompletedDatetime: null,
              completionId: null,
              fcrs: null,
              combineId: null,
              deletedAt: null,
              doActualDatetime: null,
              doLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              isLate: true,
              kind: TripKindEnum.Dep,
              payerProviderId: '5827f19b-406b-4312-b08d-620992f663d3',
              provider: null,
              providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              puLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              rateId: '5827f19b-406b-4312-b08d-620992f663d3',
              status: TripStatusEnum.Active,
              state: null,
            },
          },
          {
            cursor: '2',
            node: {
              id: '5827f19b-406b-4312-b08d-620992f663d3',
              offset: '-04:00',
              flags: null,
              communications: null,
              type: TripTypeEnum.Add,
              scheduled: '2024-05-17T08:10:00',
              scheduledUtc: '2024-05-17T15:10:00',
              trackFlight: null,
              rate: {
                rate: 50,
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Auto Generated 50.00',
                thisLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                thatLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                currency: RateCurrencyEnum.Usd,
                startDatetime: '2024-05-16T23:26:00',
                endDatetime: '2024-05-17T11:36:00',
                type: RateTypeEnum.Contract,
                providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              },
              airportCode: 'SFO',
              servicerIataAirlineCode: 'DL',
              flightNumber: 779,
              payerProvider: null,
              pilots: 2,
              attendants: 0,
              driverId: null,
              driver: null,
              vehicleId: null,
              vehicle: null,
              puLocation: {
                address: '1333 OLD BAYSHORE HWY',
                cityName: 'Burlingame',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Hyatt Regency San Francisco Airport',
                stateCode: 'CA',
                zipCode: '94010',
                aliases: [
                  {
                    name: 'HYATT SFO AIRPORT',
                  },
                  {
                    name: 'hyatt regency sfo airport',
                  },
                ],
              },
              doLocation: {
                address: 'SAN FRANCISCO',
                cityName: '',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'SFO',
                stateCode: 'CA',
                zipCode: '94128',
                aliases: null,
                airports: [
                  {
                    airportCode: 'SFO',
                  },
                ],
              },
              puActualDatetime: null,
              puCompletedDatetime: null,
              completionId: null,
              fcrs: null,
              combineId: null,
              deletedAt: null,
              doActualDatetime: null,
              doLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              isLate: true,
              kind: TripKindEnum.Dep,
              payerProviderId: '5827f19b-406b-4312-b08d-620992f663d3',
              provider: null,
              providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              puLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              rateId: '5827f19b-406b-4312-b08d-620992f663d3',
              status: TripStatusEnum.Active,
              state: null,
            },
          },
          {
            cursor: '3',
            node: {
              id: '5827f19b-406b-4312-b08d-620992f663d3',
              offset: '-04:00',
              flags: null,
              communications: null,
              type: TripTypeEnum.Add,
              scheduled: '2024-05-17T10:10:00',
              scheduledUtc: '2024-05-17T17:10:00',
              trackFlight: null,
              rate: {
                rate: 50,
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Auto Generated 50.00',
                thisLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                thatLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                currency: RateCurrencyEnum.Usd,
                startDatetime: '2024-05-16T17:13:00',
                endDatetime: '2024-05-19T09:16:00',
                type: RateTypeEnum.Contract,
                providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              },
              airportCode: 'SFO',
              servicerIataAirlineCode: 'DL',
              flightNumber: 664,
              payerProvider: null,
              pilots: 0,
              attendants: 1,
              driverId: null,
              driver: null,
              vehicleId: null,
              vehicle: null,
              puLocation: {
                address: '1333 OLD BAYSHORE HWY',
                cityName: 'Burlingame',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Hyatt Regency San Francisco Airport',
                stateCode: 'CA',
                zipCode: '94010',
                aliases: [
                  {
                    name: 'HYATT SFO AIRPORT',
                  },
                  {
                    name: 'hyatt regency sfo airport',
                  },
                ],
              },
              doLocation: {
                address: 'SAN FRANCISCO',
                cityName: '',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'SFO',
                stateCode: 'CA',
                zipCode: '94128',
                aliases: null,
                airports: [
                  {
                    airportCode: 'SFO',
                  },
                ],
              },
              puActualDatetime: null,
              puCompletedDatetime: null,
              completionId: null,
              fcrs: null,
              combineId: null,
              deletedAt: null,
              doActualDatetime: null,
              doLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              isLate: true,
              kind: TripKindEnum.Dep,
              payerProviderId: '5827f19b-406b-4312-b08d-620992f663d3',
              provider: null,
              providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              puLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              rateId: '5827f19b-406b-4312-b08d-620992f663d3',
              status: TripStatusEnum.Active,
              state: null,
            },
          },
          {
            cursor: '4',
            node: {
              id: '5827f19b-406b-4312-b08d-620992f663d3',
              offset: '-04:00',
              flags: null,
              communications: null,
              type: TripTypeEnum.Add,
              scheduled: '2024-05-17T10:36:00',
              scheduledUtc: '2024-05-17T17:36:00',
              trackFlight: null,
              rate: {
                rate: 50,
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Auto Generated 50.00',
                thisLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                thatLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                currency: RateCurrencyEnum.Usd,
                startDatetime: '2024-05-16T23:26:00',
                endDatetime: '2024-05-17T11:36:00',
                type: RateTypeEnum.Contract,
                providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              },
              airportCode: 'SFO',
              servicerIataAirlineCode: 'DL',
              flightNumber: 916,
              payerProvider: null,
              pilots: 2,
              attendants: 0,
              driverId: null,
              driver: null,
              vehicleId: null,
              vehicle: null,
              puLocation: {
                address: '1333 OLD BAYSHORE HWY',
                cityName: 'Burlingame',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Hyatt Regency San Francisco Airport',
                stateCode: 'CA',
                zipCode: '94010',
                aliases: [
                  {
                    name: 'HYATT SFO AIRPORT',
                  },
                  {
                    name: 'hyatt regency sfo airport',
                  },
                ],
              },
              doLocation: {
                address: 'SAN FRANCISCO',
                cityName: '',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'SFO',
                stateCode: 'CA',
                zipCode: '94128',
                aliases: null,
                airports: [
                  {
                    airportCode: 'SFO',
                  },
                ],
              },
              puActualDatetime: null,
              puCompletedDatetime: null,
              completionId: null,
              fcrs: null,
              combineId: null,
              deletedAt: null,
              doActualDatetime: null,
              doLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              isLate: true,
              kind: TripKindEnum.Dep,
              payerProviderId: '5827f19b-406b-4312-b08d-620992f663d3',
              provider: null,
              providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              puLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              rateId: '5827f19b-406b-4312-b08d-620992f663d3',
              status: TripStatusEnum.Active,
              state: null,
            },
          },
          {
            cursor: '5',
            node: {
              id: '5827f19b-406b-4312-b08d-620992f663d3',
              offset: '-04:00',
              flags: null,
              communications: null,
              type: TripTypeEnum.Add,
              scheduled: '2024-05-17T14:50:00',
              scheduledUtc: '2024-05-17T21:50:00',
              trackFlight: null,
              rate: {
                rate: 50,
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Auto Generated 50.00',
                thisLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                thatLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
                currency: RateCurrencyEnum.Usd,
                startDatetime: '2024-05-16T17:13:00',
                endDatetime: '2024-05-19T09:16:00',
                type: RateTypeEnum.Contract,
                providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              },
              airportCode: 'SFO',
              servicerIataAirlineCode: 'DL',
              flightNumber: 525,
              payerProvider: null,
              pilots: 0,
              attendants: 4,
              driverId: null,
              driver: null,
              vehicleId: null,
              vehicle: null,
              puLocation: {
                address: '1333 OLD BAYSHORE HWY',
                cityName: 'Burlingame',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'Hyatt Regency San Francisco Airport',
                stateCode: 'CA',
                zipCode: '94010',
                aliases: [
                  {
                    name: 'HYATT SFO AIRPORT',
                  },
                  {
                    name: 'hyatt regency sfo airport',
                  },
                ],
              },
              doLocation: {
                address: 'SAN FRANCISCO',
                cityName: '',
                id: '5827f19b-406b-4312-b08d-620992f663d3',
                name: 'SFO',
                stateCode: 'CA',
                zipCode: '94128',
                aliases: null,
                airports: [
                  {
                    airportCode: 'SFO',
                  },
                ],
              },
              puActualDatetime: null,
              puCompletedDatetime: null,
              completionId: null,
              fcrs: null,
              combineId: null,
              deletedAt: null,
              doActualDatetime: null,
              doLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              isLate: false,
              kind: TripKindEnum.Dep,
              payerProviderId: '5827f19b-406b-4312-b08d-620992f663d3',
              provider: null,
              providerId: '5827f19b-406b-4312-b08d-620992f663d3',
              puLocationId: '5827f19b-406b-4312-b08d-620992f663d3',
              rateId: '5827f19b-406b-4312-b08d-620992f663d3',
              status: TripStatusEnum.Active,
              state: null,
            },
          },
        ],
        pageInfo: {
          endCursor: '5',
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: '0',
        },
        totalCount: 5,
      },
    },
  },
  {
    variables: (opts: VariablesOf<TripTableConnectionInput>): boolean => !!opts,
  }
);

import { InvoiceInput, InvoiceResponse, RunSaveInvoiceInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { RunSaveInvoiceDocument } from '../../queries';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type RunSaveInvoiceGraphApiResponse = GraphApiResponse<typeof RunSaveInvoiceDocument>;

const title = 'Save Invoice';

const runSaveInvoiceResponseSelector = (res: RunSaveInvoiceGraphApiResponse): InvoiceResponse =>
  !res.errors ? (res?.runSaveInvoice?.output as InvoiceResponse) : undefined;

const [runRunSaveInvoice] = graphApi(RunSaveInvoiceDocument, {
  onError: (res: any): void => handleError(res, { notification: { title } }),
});

export const runSaveInvoice = async (invoice: InvoiceInput): Promise<InvoiceResponse> => {
  const input: RunSaveInvoiceInput = { input: invoice };
  const res = await runRunSaveInvoice({ input });

  const selected = runSaveInvoiceResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useRunSaveInvoice = createGraphApiHook(runSaveInvoice);

export default runSaveInvoice;

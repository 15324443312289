import { Button } from 'react-bootstrap';
import Tippy from '@tippyjs/react';

const LocationCell = ({
  name,
  address,
  cityName,
  stateCode,
  zipCode,
  aliases,
}: {
  name: string;
  address: string;
  cityName: string;
  stateCode: string;
  zipCode: string;
  aliases: string[];
}): React.JSX.Element => {
  const street = `${address}, ${cityName}, ${stateCode} ${zipCode}`;
  return (
    <>
      <Tippy
        content={
          <div className="w-100 h-100 position-relative">
            <div className="text-center">{name}</div>
            <div className="text-center">{street}</div>
          </div>
        }
        placement="left"
      >
        <Tippy content={<small>Click to copy to clipboard.</small>} placement="bottom-start" delay={[500, 0]} arrow={false}>
          <Button
            variant="icon"
            className="p-0 overflow-hidden"
            onClick={(): Promise<void> => navigator.clipboard.writeText(`${name}\n${street}`)}
          >
            {name || '--'}
          </Button>
        </Tippy>
      </Tippy>
      {aliases?.length > 1 && (
        <Tippy
          content={
            <>
              <div className="d-flex">
                <strong className="flex-grow-1">Aliases:</strong>
                <strong>({aliases?.length})</strong>
              </div>
              <ul className="m-0">{aliases?.map?.((name: string, a: number): JSX.Element => <li key={a}>{name}</li>)}</ul>
            </>
          }
          placement="right"
        >
          <Tippy content={<small>Click to copy to clipboard.</small>} placement="bottom-end" delay={[500, 0]} arrow={false}>
            <Button
              variant="transparent"
              className="p-0"
              onClick={(): Promise<void> => navigator.clipboard.writeText(aliases?.join('\n') || '')}
            >
              <i className="fa fa-circle-info pointer {opacity:0.4;}" />
            </Button>
          </Tippy>
        </Tippy>
      )}
    </>
  );
};

export default LocationCell;

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const runArchiveInvoices: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (invoiceIds: number[]): Promise<any> => {
      return fireQuery({
        variables: {
          input: {
            invoiceIds,
          },
        },
      });
    },
  selector: (data: any): any => data?.runArchiveInvoices?.response || [],
  query: gql`
    mutation RunArchiveInvoices($input: RunArchiveInvoicesInput!) {
      runArchiveInvoices(input: $input) {
        updated
        error {
          code
          debug
          message
        }
      }
    }
  `,
};

export default runArchiveInvoices;

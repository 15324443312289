import { createPersistentComponentState } from '@/state';

const initGlobalState: {
  hotkeys: Record<string, { name: string; description: string; source: string }>;
} = {
  hotkeys: {},
};
const useGlobalState = createPersistentComponentState('globalState', initGlobalState);

export default useGlobalState;

import React, { forwardRef } from 'react';

import Select from '../Select';
import { TripStatusEnum } from '../../models/gen/graphql';

const SelectStatus = ({ value, ...props }: any, ref) => (
  <Select {...props} ref={ref} value={value}>
    {Object.entries(TripStatusEnum).map(
      ([key, val]: [string, string]): JSX.Element => (
        <option value={val} key={val}>
          {key}
        </option>
      )
    )}
  </Select>
);

export default forwardRef(SelectStatus);

import { SearchTypeEnum, StringSearch, UpdatePotentialTripBulkResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdatePotentialTripBulkDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { stringify } from '../../../utils/objects';

const generateUpdatePotentialTripBulk = (objs: any[]): any => {
  const result: any[] = [];
  objs.forEach(
    ({
      tripId,
      id,
      payerProvider: _payerProvider,
      ingestAction: _ingestAction,
      typeName: _typeName,
      doLocation: _doLocation,
      puLocation: _puLocation,
      statusName: _statusName,
      warnings: _warnings,
      ...obj
    }: any): void => {
      const newObj = { ...obj, fromManifest: obj.fromManifest ? 1 : 0 };
      const i = result.findIndex((res: any): boolean => stringify.compare(res.value, newObj));
      if (i >= 0) {
        result[i].query[0].tripId[0].values.push(tripId || id);
      } else {
        const temp: StringSearch = {
          type: SearchTypeEnum.Or,
          values: [tripId || id],
        };
        result.push({
          query: [{ tripId: [temp] }],
          value: newObj,
        });
      }
    }
  );
  return result;
};

type UpdatePotentialTripBulkGraphApiResponse = GraphApiResponse<typeof UpdatePotentialTripBulkDocument>;

const title = 'Update Potential Trip(s)';

const updatePotentialTripBulkSelector = (res: UpdatePotentialTripBulkGraphApiResponse): UpdatePotentialTripBulkResponse =>
  !res.errors ? res?.updatePotentialTripBulk || { response: [] } : undefined;

const [runUpdatePotentialTripBulk] = graphApi(UpdatePotentialTripBulkDocument, {
  onError: (res: UpdatePotentialTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

// TODO: input needs to be typed out
const updatePotentialTripBulk = async (...tripObjects: any[]): Promise<UpdatePotentialTripBulkResponse> => {
  const input = { updates: generateUpdatePotentialTripBulk(tripObjects) };
  const res = await runUpdatePotentialTripBulk({
    input,
  });

  const selected = updatePotentialTripBulkSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useUpdatePotentialTripBulk = createGraphApiHook(updatePotentialTripBulk);

export default updatePotentialTripBulk;

import {
  AddLocationAliasesInput,
  AddLocationAliasesResponse,
  AirportHasLocation,
  Alias,
  AliasInput,
  Location,
} from '../../../models/gen/graphql';
import { Validation, createNotification, handleError } from '../../../utils';

import { AddLocationAliasesDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import graphApi from '../..';

type AddLocationAliasesGraphApiResponse = GraphApiResponse<typeof AddLocationAliasesDocument>;

const title = 'Add Location Aliases';

const addLocationAliasesValidator = new Validation.Validator({
  'locationId!': (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val),
    defaultValue: '',
  }),
  'aliases!': (val: any[]): Validation.Validity => ({
    valid: Array.isArray(val) && !!val?.length,
    defaultValue: [],
  }),
  airportCodes: (val: any[]): Validation.Validity => ({
    valid: Array.isArray(val) && !!val?.length,
  }),
});

const addLocationAliasesSelector = (res: AddLocationAliasesGraphApiResponse): AddLocationAliasesResponse =>
  !res.errors ? res?.addLocationAliases || {} : undefined;

const [runAddLocationAliases] = graphApi(AddLocationAliasesDocument, {
  onError: (res: AddLocationAliasesGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const addLocationAliases = async (aliases: AliasInput[]): Promise<AddLocationAliasesResponse> => {
  const values = aliases.map((alias: AliasInput): AliasInput => addLocationAliasesValidator.create(alias));
  const input: AddLocationAliasesInput = {
    locations: values,
  };

  const res = await runAddLocationAliases({ input });
  const selected = addLocationAliasesSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const handleAddLocationAliases = async (
  { aliases, airports }: { aliases?: any[]; airports?: any[] },
  originalLocation: Location
): Promise<AddLocationAliasesResponse> => {
  if (!Validation.isValidUUID(originalLocation?.id)) {
    createNotification('No location to add aliases to', Toast.Type.WARNING, title);
    return;
  }

  const addLocationAliasesPayload = (aliases || [])
    .map((alias: any): string => alias?.name || alias)
    .filter(
      (alias: string): boolean =>
        !(originalLocation?.aliases || []).find((originalAlias: Alias): boolean => (originalAlias?.name || originalAlias) === alias)
    );

  const addLocationAirportsPayload = (airports || [])
    .map((airport: any): string => airport?.airportCode || airport)
    .filter(
      (airport: string): boolean =>
        !(originalLocation?.airports || []).find(
          (originalAirport: AirportHasLocation): boolean => (originalAirport?.airportCode || originalAirport) === airport
        )
    );

  if (addLocationAliasesPayload.length === 0 && addLocationAirportsPayload.length === 0) {
    createNotification('No aliases or airports to add', Toast.Type.WARNING, title);
    return;
  }

  return await addLocationAliases([
    {
      locationId: originalLocation?.id,
      aliases: addLocationAliasesPayload,
      airportCodes: addLocationAirportsPayload,
    },
  ]);
};

export default addLocationAliases;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessageAvatar {
  display: inline-block;
  background-color: #fff;
  border: 1px solid var(--bs-secondary-subtle);
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.ChatMessageIcon-sm,
.ChatMessageImage-sm {
  width: 35px;
  height: 35px;
}
.ChatMessageIcon-md,
.ChatMessageImage-md {
  width: 47px;
  height: 47px;
}
.ChatMessageIcon-lg,
.ChatMessageImage-lg {
  width: 63px;
  height: 63px;
}

.ChatMessageIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ChatMessageIcon::before {
  opacity: 0.75;
}
.ChatMessageIcon-sm {
  font-size: 1.5rem;
}
.ChatMessageIcon-md {
  font-size: 2rem;
}
.ChatMessageIcon-lg {
  font-size: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatMessage/ChatMessageAvatar/styles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,sBAAA;EACA,4CAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAIE;;EACE,WAAA;EACA,YAAA;AAAJ;AAEE;;EACE,WAAA;EACA,YAAA;AACJ;AACE;;EACE,WAAA;EACA,YAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAEE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;AAAJ","sourcesContent":[".ChatMessageAvatar {\n  display: inline-block;\n  background-color: #fff;\n  border: 1px solid var(--bs-secondary-subtle);\n  border-radius: 50%;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n\n.ChatMessageIcon-sm,\n.ChatMessageImage-sm {\n  width: 35px;\n  height: 35px;\n}\n.ChatMessageIcon-md,\n.ChatMessageImage-md {\n  width: 47px;\n  height: 47px;\n}\n.ChatMessageIcon-lg,\n.ChatMessageImage-lg {\n  width: 63px;\n  height: 63px;\n}\n\n.ChatMessageIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.ChatMessageIcon::before {\n  opacity: 0.75;\n}\n.ChatMessageIcon-sm {\n  font-size: 1.5rem;\n}\n.ChatMessageIcon-md {\n  font-size: 2rem;\n}\n.ChatMessageIcon-lg {\n  font-size: 3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

type Props = {
  lat?: number;
  lng?: number;
};
const CoordinateDisplay = ({ lat, lng }: Props): React.JSX.Element => (
  <span className="CoordinateDisplay">
    {lat ? <span>{lat}&deg;N</span> : ''}
    {lat && lng ? <span>, </span> : ''}
    {lng ? <span>{lng}&deg;E</span> : ''}
  </span>
);

export default CoordinateDisplay;

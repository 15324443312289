import {
  CreateUserAvailabilityBulkInput,
  CreateUserAvailabilityBulkResponse,
  CreateUserAvailabilityInput,
  UserAvailabilityTypeEnum,
} from '@/models/gen/graphql';
import { Datetime, Validation, titleCase } from '@/utils';
import { createNotification, handleError } from '@/utils/custom';

import { CreateUserAvailabilityBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import Logger from '@/utils/logs';
import { Toast } from '@/models';
import graphApi from '@/api';

const log = Logger.of('CreateUserAvailabilityBulk');

type CreateUserAvailabilityBulkGraphApiResponse = GraphApiResponse<typeof CreateUserAvailabilityBulkDocument>;

const title = 'Create User Availability';

export const createUserAvailabilityValidator = new Validation.Validator({
  'startDate!': (val: string): Validation.Validity => ({
    valid: Validation.isDate(val),
  }),
  'endDate!': (val: string): Validation.Validity => ({
    valid: Validation.isDate(val),
  }),
  'startTime!': (val: string): Validation.Validity => ({
    valid: !!val,
  }),
  'endTime!': (val: string): Validation.Validity => ({
    valid: !!val,
  }),
  'dayOfWeek!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
  }),
  availabilityType: (val: string): Validation.Validity => ({
    valid: val ? true : val?.length ? false : undefined,
  }),
  type: (val: UserAvailabilityTypeEnum): Validation.Validity => ({
    valid: UserAvailabilityTypeEnum[titleCase(val)] ? true : val?.length ? false : undefined,
  }),
  'repeatUntil!': (val: string): Validation.Validity => ({
    valid: Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  reason: (val: string): Validation.Validity => ({
    valid: val ? true : val?.length ? false : undefined,
  }),
  'userId!': (val: string): Validation.Validity => ({
    valid: !!Validation.isValidUUID(val),
  }),
});

const createUserAvailabilityBulkResponseSelector = (res: CreateUserAvailabilityBulkGraphApiResponse): CreateUserAvailabilityBulkResponse =>
  !res.errors ? (res?.createUserAvailabilityBulk as CreateUserAvailabilityBulkResponse) : undefined;

const [runCreateUserAvailabilityBulk] = graphApi(CreateUserAvailabilityBulkDocument, {
  onError: (res: CreateUserAvailabilityBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createUserAvailabilityBulk = async (availabilities: CreateUserAvailabilityInput[]): Promise<CreateUserAvailabilityBulkResponse> => {
  const values: CreateUserAvailabilityInput[] = availabilities.map(
    (availability: CreateUserAvailabilityInput): CreateUserAvailabilityInput => createUserAvailabilityValidator.create(availability)
  );
  const input: CreateUserAvailabilityBulkInput = { values };

  const res = await runCreateUserAvailabilityBulk({ input });
  const selected = createUserAvailabilityBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const handleCreateAvailability = async (data: CreateUserAvailabilityInput): Promise<CreateUserAvailabilityBulkResponse> => {
  try {
    // validate input
    if (!data?.startTime || !data?.endTime) throw new Error('Please select a start and end time.');
    if (!data?.endDate || !data?.startDate) throw new Error('Missing start and end date.');

    const startDateTime = new Datetime(data.startDate).setTime(data.startTime);
    let endDateTime = new Datetime(data.startDate).setTime(data.endTime);

    if (startDateTime.asDayjs().isSame(endDateTime.toString())) throw new Error('Availability cannot start and stop at the same time.');
    else if (startDateTime.asDayjs().isAfter(endDateTime.toString())) endDateTime = endDateTime.add(1, 'day').setTime(data.endTime);

    // format payload
    const formatData: CreateUserAvailabilityInput = {
      ...data,
      startDate: startDateTime.setTime('00:00:00').toString(),
      endDate: endDateTime.setTime('00:00:00').toString(),
      startTime: startDateTime.setTime(data.startTime).fullTime,
      endTime: endDateTime.setTime(data.endTime).fullTime,
      dayOfWeek: new Datetime(data.startDate).asDayjs().day(),
    };
    if (data?.repeatUntil) formatData.repeatUntil = new Datetime(data?.repeatUntil).setTime('23:59:59').toString();

    return await createUserAvailabilityBulk([formatData]);
  } catch (err) {
    const message = err.message || err;
    log.error('handleCreateAvailability', err.message).notify({ title, message });
  }
};

export default createUserAvailabilityBulk;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormContainer .FormField:not(.condensed) {
  margin: 0.5rem 0;
}
.FormContainer .FormContainer-Group {
  margin: 1rem -0.5rem;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
.FormContainer .FormContainer-Group .FormField .FormField-Label {
  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.5);
}
.FormContainer .FormContainer-Group .FormField button:hover {
  background: #fff;
}
.FormContainer .FormContainer-Group .FormContainer-Content {
  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.5);
  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
  flex-grow: 1;
}
.FormContainer .FormContainer-Group .FormContainer-Content + .FormContainer-Control {
  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.5);
  border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
  padding: 0.5rem;
  flex-grow: 0;
  border-left: 1px solid #fff;
}
.FormContainer .FormContainer-Group .FormContainer-Content + .FormContainer-Control > .row {
  height: 100%;
}
.FormContainer .FormContainer-Footer {
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FormContainer/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAEE;EACE,oBAAA;EACA,+BAAA;AAAJ;AAEM;EACE,2DAAA;AAAR;AAEM;EACE,gBAAA;AAAR;AAGI;EACE,2DAAA;EACA,kEAAA;EACA,YAAA;AADN;AAEM;EACE,2DAAA;EACA,kEAAA;EACA,eAAA;EACA,YAAA;EACA,2BAAA;AAAR;AAEQ;EACE,YAAA;AAAV;AAKE;EACE,gBAAA;AAHJ","sourcesContent":[".FormContainer .FormField:not(.condensed) {\n  margin: 0.5rem 0;\n}\n.FormContainer .FormContainer-Group {\n  margin: 1rem -0.5rem;\n  padding: 0 0.5rem 0.5rem 0.5rem;\n}\n.FormContainer .FormContainer-Group .FormField .FormField-Label {\n  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.5);\n}\n.FormContainer .FormContainer-Group .FormField button:hover {\n  background: #fff;\n}\n.FormContainer .FormContainer-Group .FormContainer-Content {\n  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.5);\n  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);\n  flex-grow: 1;\n}\n.FormContainer .FormContainer-Group .FormContainer-Content + .FormContainer-Control {\n  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.5);\n  border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;\n  padding: 0.5rem;\n  flex-grow: 0;\n  border-left: 1px solid #fff;\n}\n.FormContainer .FormContainer-Group .FormContainer-Content + .FormContainer-Control > .row {\n  height: 100%;\n}\n.FormContainer .FormContainer-Footer {\n  margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { UpdateVehicleBulkInput, UpdateVehicleBulkResponse, Vehicle } from '../../../models/gen/graphql';
import { Validation, createNotification, generateUpdateBulkPayload, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdateVehicleBulkDocument } from '../../queries';
import { createVehicleValidator } from './createVehicleBulk';
import graphApi from '../..';

type UpdateVehicleBulkGraphApiResponse = GraphApiResponse<typeof UpdateVehicleBulkDocument>;

const title = 'Update Vehicles';

const updateVehicleValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createVehicleValidator
);

const updateVehicleBulkResponseSelector = (res: UpdateVehicleBulkGraphApiResponse): UpdateVehicleBulkResponse =>
  !res.errors ? res?.updateVehicleBulk : undefined;

const [runUpdateVehicleBulk] = graphApi(UpdateVehicleBulkDocument, {
  onError: (res: UpdateVehicleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateVehicleBulk = async (vehicles: Partial<Vehicle>[]): Promise<UpdateVehicleBulkResponse> => {
  vehicles = vehicles.map((vehicle: Vehicle): Vehicle => updateVehicleValidator.partial(vehicle));
  const updates = generateUpdateBulkPayload(vehicles);
  const input: UpdateVehicleBulkInput = {
    updates,
  };
  const res = await runUpdateVehicleBulk({ input });

  const selected = updateVehicleBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateVehicleBulk;

import { RunUnassignDriverInput, RunUnassignDriverResponse } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { RunUnassignDriverDocument } from '../../queries';
import { Validation } from '../../../utils/validations';
import graphApi from '../..';

type RunUnassignDriverGraphApiResponse = GraphApiResponse<typeof RunUnassignDriverDocument>;

const runUnassignDriverValidator = new Validation.Validator({
  'tripId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
});

const runUnassignDriverResponseSelector = (res: RunUnassignDriverGraphApiResponse): RunUnassignDriverResponse =>
  !res.errors ? ((res?.runUnassignDriver || {}) as RunUnassignDriverResponse) : undefined;

const [runRunUnassignDriver] = graphApi(RunUnassignDriverDocument);

const runUnassignDriver = async (tripId: string): Promise<RunUnassignDriverResponse> => {
  const input: RunUnassignDriverInput = runUnassignDriverValidator.create({ tripId });

  const res = await runRunUnassignDriver({ input });

  return runUnassignDriverResponseSelector(res);
};

export default runUnassignDriver;

import {
  ConnectionDetails,
  HandleErrorInput,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails as merge,
} from '@/utils/custom';
import { ConnectionOptions, GraphApiMethod } from '@/api/core';
import { RateHistory, RateHistorySearch, SearchRateResponseRateHistoryConnectionArgs } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { SearchRateHistoryDocument } from '@/api/queries';
import { TypedDocumentNode } from '@apollo/client';
import { getProperty } from '@/utils';
import graphApi from '@/api';

// Global Service Variables
const mergeKey = 'searchRates.rateHistoryConnection';
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Search Rate History' } });

// Service Function Signature
type ServiceMethodPayload = {
  [key in keyof RateHistorySearch]?: any;
}[];
interface ServiceMethod {
  (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<RateHistory>>;
}

// SearchRateHistory Service
export const { searchRateHistory, refetchRateHistory, useSearchRateHistory } = ((): {
  searchRateHistory: ServiceMethod;
  refetchRateHistory: ServiceMethod;
  useSearchRateHistory: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [fetch, refetch] = graphApi(SearchRateHistoryDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<TypedDocumentNode>): ServiceMethod =>
      async (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<RateHistory>> => {
        const args: SearchRateResponseRateHistoryConnectionArgs = {
          input: {
            first: options?.pageSize || null,
            after: options?.pageSize > 0 ? (options?.pageSize * options?.page).toString() : null,
            query: payload,
          },
        };
        const opts = { merge: options?.merge ? mergeKey : undefined };
        const res = await fn(args, opts);
        const results = convertConnectionToDetails(getProperty(mergeKey, res));
        return { ...results, rows: results?.rows.filter((row) => !!row.message) };
      }
  );
  const useService = createGraphApiHook(fetch, { refetch, merge });
  return {
    searchRateHistory: fetch,
    refetchRateHistory: refetch,
    useSearchRateHistory: useService,
  };
})();

import { FilterOption } from '../../models/gen/graphql';
import { GetInvoiceTermsDocument } from '../../api/queries';
import SelectFromQuery from '../SelectFromQuery';

const SelectInvoiceTerms = (props) => (
  <SelectFromQuery
    placeholder="Select Invoice Terms"
    query={GetInvoiceTermsDocument}
    selector={(data) =>
      (data?.getFilters?.filters?.invoiceTerms || []).map((opt: FilterOption) => ({
        label: opt.displayName,
        value: opt.id,
      }))
    }
    {...props}
  />
);

export default SelectInvoiceTerms;

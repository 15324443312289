import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Role, RoleConnection, RoleConnectionInput, RoleSearch } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchRolesDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchRolesGraphApiResponse = GraphApiResponse<typeof SearchRolesDocument>;
export type SearchRolesOptions = { pageSize?: number; page?: number; merge?: true };

export const SEARCH_ROLES_PAGE_SIZE = 1000;
const title = 'Search Roles';

export const searchRolesResponseSelector = (res: SearchRolesGraphApiResponse): ConnectionDetails<Role> =>
  convertConnectionToDetails(res?.searchRoles?.roleConnection as RoleConnection);

const [runSearchRoles, runRefetchSearchRoles] = graphApi(SearchRolesDocument, {
  onError: (res: SearchRolesGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchRoles = async (query?: RoleSearch[], options: SearchRolesOptions = {}): Promise<ConnectionDetails<Role>> => {
  const { pageSize = SEARCH_ROLES_PAGE_SIZE, page = 0, merge = false } = options;

  const input: RoleConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchRoles.roleConnection' : undefined };

  const res = await runSearchRoles({ input }, graphApiOptions);

  return searchRolesResponseSelector(res);
};

const refetchSearchRoles = async (query?: RoleSearch[], options: SearchRolesOptions = {}): Promise<ConnectionDetails<Role>> => {
  const { pageSize = SEARCH_ROLES_PAGE_SIZE, page = 0 } = options;

  const input: RoleConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchRoles({ input });

  return searchRolesResponseSelector(res);
};

export const useSearchRoles = createGraphApiHook(searchRoles, {
  refetch: refetchSearchRoles,
  merge: mergeConnectionDetails,
});

export default searchRoles;

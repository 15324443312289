import './styles.scss';

import FormField, { FormFieldProps } from '../FormField';
import React, { useState } from 'react';

import Tippy from '@tippyjs/react';
import { getClasses } from '../../utils';

type Props = Omit<FormFieldProps, 'type'>;

const PasswordInput = ({ className, name, ...props }: Props): React.JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShow = (): void => setShowPassword((current: boolean): boolean => !current);
  return (
    <FormField
      name={name}
      placeholder="Password"
      {...props}
      type={showPassword ? undefined : 'password'}
      append={
        <Tippy content={showPassword ? 'Hide Password' : 'Show Password'}>
          <i className={`fa fa-eye${showPassword ? '' : '-slash'}`} onClick={toggleShow} />
        </Tippy>
      }
      options={{
        ...props?.options,
        group: {
          ...props?.options?.group,
          className: getClasses('PasswordInput', props?.options?.group?.className),
        },
      }}
    />
  );
};

export default PasswordInput;

import { BufferTime, BufferTimeSearch, CreateBufferTimeInput, SortDirectionEnum, UpdateBufferTimeValuesInput } from '@/models/gen/graphql';
import { ConnectionDetails, QueryInputType, onEnter, queryInput, stringify } from '@/utils';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import SettingsPageGroup, { SettingsPageGroupProps } from '@/features/settings/components/SettingsPageGroup';

import { AirportGroupDropdown } from '@/components/AirportDropdown';
import BufferTimesTable from '@/features/settings/components/pages/BufferTimes/BufferTimesTable';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import { ComplexTableRef } from '@/components/ComplexTable';
import FormField from '@/components/FormField';
import { GraphApiResponse } from '@/api/core';
import ImportContractProviderDropdown from '@/components/ImportContractProviderDropdown';
import { OnChange } from '@/hooks/useOnChange';
import PageInfo from '@/components/PageInfo';
import { useCreateBufferTimeBulk } from '@/features/settings/api/services/bufferTimes/createBufferTimeBulk';
import { useDeleteBufferTimeBulk } from '@/features/settings/api/services/bufferTimes/deleteBufferTimeBulk';
import { useSearchBufferTimes } from '@/features/settings/api/services/bufferTimes/searchBufferTimes';
import { useUpdateBufferTimeBulk } from '@/features/settings/api/services/bufferTimes/updateBufferTimeBulk';

const TITLE = 'Buffer Times';
const ICON = <i className="sv sv-earth" />;
const HEADER = { title: TITLE, icon: ICON };

type BufferTimesFilters = {
  contractId: string;
  airportCode: string[];
};
type BufferTimesState = {
  search: string;
  sorting: { column: string; direction: string };
  count: number;
  valid: boolean;
  dirty: boolean;
};
const initBufferTimesFilters: BufferTimesFilters = {
  contractId: undefined,
  airportCode: [],
};
const initBufferTimesState: BufferTimesState = {
  search: '',
  sorting: { column: undefined, direction: undefined },
  count: 0,
  valid: true,
  dirty: false,
};
const BufferTimes = (): ReactNode => {
  const [{ data: { rows: data = [] } = {}, loading: loadingBufferTimes }, { refetch: getBufferTimes }] = useSearchBufferTimes();
  const [{ loading: creatingBufferTimes }, { fetch: createBufferTimes }] = useCreateBufferTimeBulk();
  const [{ loading: updatingBufferTimes }, { fetch: updateBufferTimes }] = useUpdateBufferTimeBulk();
  const [{ loading: deletingBufferTimes }, { fetch: deleteBufferTimes }] = useDeleteBufferTimeBulk();

  const [state, setState] = useState<BufferTimesState>({ ...initBufferTimesState });
  const { count, search, valid, dirty } = state;

  const loading = useMemo(
    (): boolean => loadingBufferTimes || creatingBufferTimes || updatingBufferTimes || deletingBufferTimes,
    [loadingBufferTimes, creatingBufferTimes, updatingBufferTimes, deletingBufferTimes]
  );

  const complexTableRef = useRef<ComplexTableRef>(null);

  const onChange = useCallback(async (): Promise<void> => {
    if (complexTableRef?.current?.deletions?.length) {
      try {
        const deleteBufferTimeBulkPayload = complexTableRef?.current?.deletions?.flatMap(
          (item: ComplexFormItem<BufferTime>): string[] => item?.id
        );
        await deleteBufferTimes(deleteBufferTimeBulkPayload);
        complexTableRef?.current?.setDeletions([]);
        getBufferTimes();
      } catch (err) {
        console.error(err);
      }
    }
    setState(
      (current: BufferTimesState): BufferTimesState => ({
        ...current,
        count: complexTableRef?.current?.items.filter((item: ComplexFormItem<BufferTime>): boolean => !!item.data).length,
        valid: complexTableRef?.current?.isValid,
        dirty: complexTableRef?.current?.isDirty,
      })
    );
  }, [deleteBufferTimes, getBufferTimes]);

  const onSubmit = useCallback(
    async (filters?: BufferTimesFilters): Promise<ConnectionDetails<BufferTime>> => {
      let query: BufferTimeSearch = {
        createdAt: [queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0)],
      };
      if (filters?.contractId) query.contractId = queryInput(filters.contractId);
      if (filters?.airportCode?.length) query.airportCode = queryInput(filters.airportCode);

      return getBufferTimes([query]);
    },
    [getBufferTimes]
  );

  const onSave = useCallback(async (): Promise<void> => {
    const transaction = [];
    if (complexTableRef?.current?.additions?.length) {
      transaction.push(async (): Promise<BufferTime[]> => {
        const result = await createBufferTimes(
          complexTableRef?.current?.additions?.map((item: ComplexFormItem<BufferTime>): CreateBufferTimeInput => item?.data)
        );
        return result;
      });
    }
    if (complexTableRef?.current?.updates?.length) {
      transaction.push(async (): Promise<GraphApiResponse> => {
        const updateBufferTimeBulkPayload = complexTableRef?.current?.updates?.map(
          (item: ComplexFormItem<BufferTime>): UpdateBufferTimeValuesInput & { id: string } => ({
            id: item?.id,
            ...Object.entries(item?.data).reduce(
              (acc: Record<string, unknown[]>, [key, value]: [string, unknown]): Record<string, unknown[]> => {
                acc[key] = [value];
                return acc;
              },
              {}
            ),
          })
        );
        const result = await updateBufferTimes(updateBufferTimeBulkPayload);
        return result;
      });
    }

    if (transaction.length)
      try {
        await Promise.all(transaction.map((fn) => fn()));
        complexTableRef?.current?.onReset();
        getBufferTimes();
      } catch (err) {
        console.error(err.message || err);
      }
  }, [createBufferTimes, updateBufferTimes, getBufferTimes]);

  const footerProps = useMemo(
    (): { onAdd?: false | (() => void); onCancel?: false | (() => void); onSave?: false | (() => void) } => ({
      onAdd: !loading ? (): void => complexTableRef?.current?.onAdd() : false,
      onCancel: dirty ? (): void => complexTableRef?.current?.onReset() : false,
      onSave: dirty && valid ? onSave : false,
    }),
    [dirty, valid, onSave, loading]
  );

  const filterProps = useMemo(
    (): SettingsPageGroupProps['filters'] => ({
      name: 'bufferTimesFilters',
      value: initBufferTimesFilters,
      onSubmit,
      onReset: () => {
        setState((current: BufferTimesState): BufferTimesState => ({ ...current, search: '' }));
        return {};
      },
      submitOnMount: true,
      primary: ({ values, onChange }: { values: BufferTimesFilters; onChange: OnChange }): JSX.Element => {
        const { contractId, airportCode } = values;
        return (
          <>
            <ImportContractProviderDropdown
              value={contractId}
              onChange={(value: string): void => onChange({ target: { name: 'contractId', value } })}
            />
            <AirportGroupDropdown
              value={airportCode}
              onChange={(value: string[]): void => onChange({ target: { name: 'airportCode', value } })}
            />
          </>
        );
      },
      controls: ({ values, onChange }): JSX.Element => {
        const { search } = values;
        return (
          <>
            <FormField
              prepend={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={search || ''}
              onChange={onChange}
              onBlur={(): void => setState((current: BufferTimesState): BufferTimesState => ({ ...current, search }))}
              onKeyDown={onEnter((): void => {
                setState((current: BufferTimesState): BufferTimesState => ({ ...current, search }));
              })}
              placeholder="Search"
              condensed
            />
          </>
        );
      },
    }),
    [onSubmit]
  );

  const filter = useCallback(
    (item: ComplexFormItem<BufferTime>): boolean =>
      !search ||
      stringify([
        item.data?.provider?.name,
        item.data?.startLocation?.name,
        item.data?.endLocation?.name,
        item.data?.startTime,
        item.data?.endTime,
        item.data?.bufferMinutes,
      ])
        .toLowerCase()
        .includes(search?.toLowerCase()),
    [search]
  );

  return (
    <SettingsPageGroup header={HEADER} footer={footerProps} filters={filterProps}>
      <PageInfo>Buffer Times: {count}</PageInfo>
      <BufferTimesTable data={data} onChange={onChange} options={{ loading, filter }} ref={complexTableRef} />
    </SettingsPageGroup>
  );
};

export default BufferTimes;

import { openDB } from 'idb';

/* useIndexedDb
  This hook may seem complex, but it really just takes the IndexedDB
  API, and makes it usable in the same style as the standard useState
  hook.

  The only difference between the two is that since IndexedDB is used
  to store data on the client's hard drive, and therefore has no real
  expiry, or size restrictions.

  Technically speaking, since this hook allows for specifying a
  "database", as well as a "table", it should be discussed before using
  this as a current database/table combination may already be in use
  and the same one should be specified, unless reasoning makes sense to
  create anything new.

  The config argument is a custom object used by the hook to provide
  additional options for the idb library, as well as a few custom settings
  that the hook allows, such as secondaryKey. This object is only needed
  if any extra features are desired for your specific use.

  As for the version argument, this is part of the IndexedDB API that
  allows for checking of data that already exists on the client's machine.
  If the client has data with a version that differs from the version
  currently specified, then an upgrade method needs to be provided to
  handle the migration/upgrade of the client's data. This upgrade could
  be as simple is deleting the older data, and only accepting new data.
*/

const useIndexedDb = (database, table, config = {}, version = 1) => {
  const open = async (database, table, { upgrade, ...config } = {}, version) =>
    await openDB(database, version, {
      upgrade(db, oldVersion, newVersion, transaction) {
        const deleteOnUpgrade = () => {
          if (oldVersion !== newVersion) {
            try {
              db.deleteObjectStore(table);
            } catch (err) {}
          }
        };
        if (upgrade) {
          upgrade.apply(db, [db, table, deleteOnUpgrade, oldVersion, newVersion, transaction]);
        } else {
          deleteOnUpgrade();
          db.createObjectStore(table);
        }
      },
      blocked: () => {
        deleteOnUpgrade();
        db.createObjectStore(table);
      },
      ...config,
    });
  const getKey = (db) => async (key, value) => {
    let val;
    try {
      if (typeof key === 'object') {
        const [index, query] = Object.entries(key)[0];
        val = await db.getAllFromIndex(table, index, query);
        value = [];
      } else {
        val = await db[key === undefined ? 'getAll' : 'get'](table, key);
        if (val === undefined) {
          if (config.onNull) {
            const result = await config.onNull(db, table, key, value);
            return result;
          } else if (value !== undefined) {
            await setKey(db)(key, value);
          }
        }
      }
    } catch (err) {
      console.warn(database, table, err);
    }
    return val || value;
  };
  const setKey = (db) => async (key, value) => {
    if (!value && config.primaryKey && key[config.primaryKey]) {
      value = key;
      key = value[config.primaryKey];
      if (config.secondaryKey && value[config.secondaryKey]) key = `${key}.${config.secondaryKey}`;
    }
    await db.put(table, value, key);
  };
  const deleteKey = (db) => async (key) => await db.delete(table, key);
  const query = (type) => async (key, value) => {
    const db = await open(database, table, config, version);
    let result;
    switch (type) {
      case 'set': {
        result = await setKey(db)(key, value);
        break;
      }
      case 'delete': {
        result = await deleteKey(db)(key);
        break;
      }
      default: {
        result = await getKey(db)(key, value);
        break;
      }
    }
    db.close();
    return result;
  };
  query.get = query('get');
  query.set = query('set');
  query.delete = query('delete');

  return [query.get, query.set, query.delete];
};

export default useIndexedDb;

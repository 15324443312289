import { FilterOption, RateHistoryCurrencyEnum } from '@/models/gen/graphql';

import { GetRateValuesDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetRateValuesGraphApiResponse = GraphApiResponse<typeof GetRateValuesDocument>;

const getRateValuesResponseSelector = (res: GetRateValuesGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.rateValue?.rates as Array<FilterOption>;

const [runGetRateValues] = graphApi(GetRateValuesDocument, {
  onError: (res: GetRateValuesGraphApiResponse): void => handleError(res, { notification: { title: 'Get Rate Values' } }),
});

export const getRateValues = async ({ currency = null }: { currency: RateHistoryCurrencyEnum }): Promise<Array<FilterOption>> => {
  const res = await runGetRateValues({ input: { currency } });
  return getRateValuesResponseSelector(res);
};

export const useGetRateValues = createGraphApiHook(getRateValues);

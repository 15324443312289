import './styles.scss';

import { Button, Col, Container, FormCheck, InputGroup, Row } from 'react-bootstrap';
import {
  CreateRateItemAndAmountInput,
  CreateRateItemInput,
  RateGroup,
  RateItem,
  RateItemCurrencyEnum,
  RateItemTypeEnum,
  RunRateOverrideInput,
} from '@/models/gen/graphql';
import { DATE_FE_FORMAT_FULL, TODAY, TODAY_EOD } from '@/constants';
import {
  Datetime,
  Validation,
  createNotification,
  fieldValidator,
  findOverlappingDateRangeIndexes,
  getClasses,
  getDiff,
  handleError,
  queryInput,
  stringify,
} from '@/utils';
import DynamicContent, { DynamicContentProps } from '@/components/DynamicContent';
import { EventObject, OnChange } from '@/hooks/useOnChange';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { createRateGroupBulk, createRateGroupBulkValidator } from '@/api/services/rates/createRateGroupBulk';

import FormContainer from '@/components/FormContainer';
import FormField from '@/components/FormField';
import { LocationQueryDropdownField } from '@/components/LocationDropdown';
import { RATE_ITEM_VALIDATION_CRITERIA, createRateItemBulk } from '@/api/services/rates/createRateItemBulk';
import RateHistory from '@/pages/Rates/components/RateHistory';
import SelectCurrency from '@/components/SelectCurrency';
import { Toast } from '@/models';

import { RateItemUpdate, updateRateItemBulk } from '@/api/services/rates/updateRateItemBulk';
import { deleteRateItemBulk } from '@/api/services/rates/deleteRateItemBulk';
import { useSearchRateGroup } from '@/api/services/rates/searchRateGroup';
import OverrideModal from '@/components/EditRatesForm/OverrideModal';
import HasPermission from '@/components/HasPermission';
import { EnumDropdownField } from '@/components/EnumDropdown';
import useConfirmation from '@/hooks/useConfirmation';
import ClientDropdown from '@/components/ClientDropdown';
import Field from '@/components/Field';
import AirportDropdown from '@/components/AirportDropdown';

export type EditRatesFormProps = Omit<DynamicContentProps, 'show' | 'onHide'> & {
  value: string | Partial<RateGroup>;
  show?: boolean;
  onSubmit?: (form: RatesForm | boolean) => void;
  onHide?: (refetch?: boolean) => void;
  selected: Partial<RateGroup>[];
};

type RateGroupWithOverride = Partial<RateGroup> & { override: Partial<RateItem>[] };
export type EditRatesFormState = {
  data?: RateGroupWithOverride;
  overridePayload?: RunRateOverrideInput;
};
const initEditRatesFormState: EditRatesFormState = {
  data: undefined,
  overridePayload: undefined,
};
export type RatesForm = Omit<RateGroupWithOverride, 'items'> & {
  dropCity: string;
  applyToSimilarTrips: boolean;
  override: Array<Omit<CreateRateItemAndAmountInput, 'type' | 'endDatetime'> & { endDatetime: string }>;
  items: Array<CreateRateItemAndAmountInput & { id?: string }>;
};
const initForm: RatesForm = {
  airportCode: null,
  payerProviderId: null,
  thisLocationId: null,
  thatLocationId: null,
  tripDistance: null,
  duration: null,
  dropCity: undefined,
  applyToSimilarTrips: false,
  items: [
    {
      type: null,
      rate: null,
      currency: RateItemCurrencyEnum.Usd,
      startDatetime: new Datetime(TODAY).datetimeInput,
      endDatetime: new Datetime(TODAY_EOD).datetimeInput,
    },
  ],
  override: [
    {
      rate: null,
      currency: RateItemCurrencyEnum.Usd,
      startDatetime: new Datetime(TODAY).datetimeInput,
      endDatetime: new Datetime(TODAY_EOD).datetimeInput,
    },
  ],
};

const EditRatesForm = ({ value = {}, show, onSubmit, onHide, selected = [], ...dynamicContentProps }: EditRatesFormProps): ReactNode => {
  const [{ data, overridePayload }, setState] = useState<EditRatesFormState>(initEditRatesFormState);
  const isExistingRate = !!data?.id;
  const previousValue = useRef<string | Partial<RateGroupWithOverride>>();
  const [{ loading }, { refetch: searchRateGroup }] = useSearchRateGroup();
  const originalRateGroup = useRef<RateGroup>();
  const formContainerRef = useRef(null);
  const confirmHide = useConfirmation({
    Body: {
      message: (
        <>
          <span>Are you sure?</span>
          <br />
          <small>Any unsaved changes will be lost.</small>
        </>
      ),
    },
  });

  const handleFetchRate = async (value: string): Promise<void> => {
    try {
      const searchRatesResponse = await searchRateGroup([{ id: [queryInput(value)] }]);
      const rateGroup = searchRatesResponse?.rows?.[0];
      if (!rateGroup) return createNotification('Rate not found.', Toast.Type.WARNING, 'Search Rate Group');
      originalRateGroup.current = rateGroup;
      setState(
        (current: EditRatesFormState): EditRatesFormState => ({
          ...current,
          data: { ...rateGroup, override: stringify.parse(initForm.override) },
        })
      );
    } catch (err) {
      handleError(err);
    }
  };

  const handleSubmit = async (ratesForm: RatesForm): Promise<void> => {
    const { dropCity: _d, applyToSimilarTrips, ...formWithOverride } = ratesForm;
    const { override, ...formWithoutOverride } = formWithOverride;
    try {
      // if rate has no id, we are creating, otherwise we are editing
      if (!isExistingRate) {
        // CREATE
        await createRateGroupBulk(formWithoutOverride);
      } else {
        // Close modal if rate has not changed
        if (stringify.compare(data, formWithOverride)) return onHide();

        // DIFF & UPDATE
        const [partial] = getDiff(data, formWithOverride);

        const originalItemIds = data?.items?.map((item: Partial<RateItem>): string => item?.id);
        const currentItemIds = ratesForm?.items?.map((item: Partial<RateItem>): string => item?.id);

        const additions = formWithoutOverride?.items
          ?.filter((item: CreateRateItemAndAmountInput & { id?: string }): boolean => !item?.id)
          .map(
            (item: CreateRateItemAndAmountInput): CreateRateItemInput => ({
              ...item,
              rateGroupId: data?.id,
            })
          );
        if (additions?.length) {
          await createRateItemBulk(additions);
        }
        const deletions = originalItemIds?.filter((id: string): boolean => !currentItemIds?.includes(id));
        if (deletions?.length) {
          await deleteRateItemBulk(deletions);
        }
        const updates = Object.values(partial?.items || [])
          .map((item: RateItemUpdate, index: number): RateItemUpdate => ({ ...item, id: item?.id || ratesForm?.items?.[index]?.id }))
          .filter((item: RateItemUpdate): boolean => !!item?.id);
        if (updates?.length) {
          await updateRateItemBulk(updates);
        }

        // OVERRIDE
        if (applyToSimilarTrips) {
          const overridePayload: RunRateOverrideInput = {
            companyId: data?.companyId,
            payerProviderId: data?.payerProviderId,
            rateGroupId: data?.id,
            thatLocationId: data?.thatLocationId,
            thisLocationId: data?.thisLocationId,
            ...override[0],
            startDatetime: new Datetime(override?.[0]?.startDatetime).setTime('00:00:00').toString(),
            endDatetime: new Datetime(override?.[0]?.endDatetime).setTime('23:59:59').toString(),
          };
          setState((current: EditRatesFormState): EditRatesFormState => ({ ...current, overridePayload }));
          return;
        }
      }
      onHide();
      onSubmit?.(ratesForm);
    } catch (err) {
      handleError(err, { notification: { title: 'Rates Error' } });
    }
  };
  const handleHide = async (): Promise<void> => {
    try {
      if (formContainerRef?.current?.isDirty?.()) await confirmHide();
      onHide(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect((): void => {
    if (stringify.compare(previousValue.current, value)) return;
    previousValue.current = value;
    if (value && typeof value === 'string') handleFetchRate(value);
    else setState((current: EditRatesFormState): EditRatesFormState => ({ ...current, data: value as RateGroupWithOverride }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, value]);

  return (
    <>
      <DynamicContent {...dynamicContentProps} show={show} onHide={handleHide}>
        <FormContainer
          ref={formContainerRef}
          name="EditRatesForm"
          initialValues={isExistingRate ? data : initForm}
          options={{
            loadingSpinner: { loading: loading || !data },
            validator: createRateGroupBulkValidator,
            bulk: selected,
          }}
          onSubmit={handleSubmit}
          formAs={({
            values,
            validation: [isValid, validity],
            setValues,
            onChange,
            onSubmit,
            disabled,
          }: {
            values: RatesForm;
            validation: [boolean, Record<string, Validation.Validity>, boolean];
            setValues: Dispatch<SetStateAction<Record<string, unknown>>>;
            onChange: OnChange;
            onSubmit: () => Promise<void>;
            disabled: Record<string, boolean>;
          }): ReactNode => {
            const rateItemsValidity: Record<string, Validation.Validity>[] = (values?.items || []).map(
              (rateItem: CreateRateItemAndAmountInput): Record<string, Validation.Validity> =>
                fieldValidator(rateItem, selected, RATE_ITEM_VALIDATION_CRITERIA)
            );
            const overrideValidity: Record<string, Validation.Validity>[] = (values?.override || []).map(
              (override: Omit<CreateRateItemAndAmountInput, 'type'>): Record<string, Validation.Validity> => {
                const { 'type!': _type, ...OVERRIDE_VALIDATION_CRITERIA } = RATE_ITEM_VALIDATION_CRITERIA;
                return fieldValidator(override, selected, OVERRIDE_VALIDATION_CRITERIA);
              }
            );
            const handleRateItemChange =
              (field: 'items' | 'override', index: number): ((event: EventObject) => void) =>
              (event: EventObject): void => {
                const { name, value } = event.target;
                const rateItem = values?.[field]?.[index] || ({} as Partial<RateItem>);
                let update = {};
                if (name === 'type') {
                  update = {
                    ...rateItem,
                    type: value,
                    endDatetime: index === lastIndex && value === RateItemTypeEnum.NonContract ? null : rateItem?.endDatetime,
                  };
                } else if (name === 'dates') {
                  const [startDatetime, endDatetime] = value.split(' - ');
                  update = { ...rateItem, startDatetime, endDatetime: endDatetime === '' ? null : endDatetime };
                } else {
                  update = { ...rateItem, [name]: value };
                }
                const newRates = stringify.parse(values?.[field] || []);
                newRates.splice(index, 1, update);
                setValues((current: Record<string, unknown>): Record<string, unknown> => ({ ...current, [field]: newRates }));
              };
            const lastIndex = (values?.items || []).length - 1;
            const rateItemsDateValidation = rateItemsValidity.map(
              (validity: Record<string, Validation.Validity>, index: number): [Validation.ValidityType, string] => {
                const datesHaveOverlap = findOverlappingDateRangeIndexes(
                  values?.items?.map((rate: CreateRateItemAndAmountInput) => [rate?.startDatetime, rate?.endDatetime])
                ).some((overlappingIndex) => overlappingIndex === index);
                if (datesHaveOverlap) return [Validation.ValidityType.INVALID, 'Dates overlap with another rate'];

                const startDatetimeNotValid = validity?.startDatetime?.valid !== Validation.ValidityType.VALID;
                const endDatetimeNotValid = validity?.endDatetime?.valid !== Validation.ValidityType.VALID;
                const isNonContract = values?.items?.[index]?.type === RateItemTypeEnum.NonContract;
                const isFirstNonContract = isNonContract && index === 0;
                const isLastNonContract = isNonContract && index === lastIndex;
                const isNotFirstOrLastNonContract = isNonContract && index < lastIndex;

                if (isFirstNonContract) {
                  const isValidFirstNonContract = isLastNonContract && endDatetimeNotValid && !startDatetimeNotValid;
                  if (isValidFirstNonContract) return [Validation.ValidityType.VALID, ''];
                  if (startDatetimeNotValid) return [Validation.ValidityType.INVALID, 'Start date is required'];
                }

                if (isNotFirstOrLastNonContract && startDatetimeNotValid) return [Validation.ValidityType.INVALID, 'Start dates required'];

                if (isLastNonContract && startDatetimeNotValid) return [Validation.ValidityType.INVALID, 'Start date is required'];
                if (values?.items?.[index]?.type === RateItemTypeEnum.Contract && (startDatetimeNotValid || endDatetimeNotValid))
                  return [Validation.ValidityType.INVALID, 'Start/End dates required'];
                if (index === 0 && !isNonContract && endDatetimeNotValid) return [Validation.ValidityType.INVALID, 'End date is required'];
                if (index === lastIndex && startDatetimeNotValid) return [Validation.ValidityType.INVALID, 'Start date is required'];
                return [Validation.ValidityType.VALID, ''];
              }
            );
            const valid =
              isValid &&
              !rateItemsValidity.some((obj) =>
                Object.entries(obj)
                  .filter(([key, _val]) => !['startDatetime', 'endDatetime'].includes(key))
                  .some(([_key, val]) => val?.valid === 2)
              ) &&
              !rateItemsDateValidation.some(([val, _f]: [Validation.ValidityType, string]) => val === 2) &&
              (!values?.applyToSimilarTrips ||
                (values?.applyToSimilarTrips && !Object.values(overrideValidity[0] || {}).some((val) => val?.valid === 2)));

            return (
              <Container fluid>
                <DynamicContent.Title>{`${value && (loading || typeof value === 'string' || isExistingRate) ? 'EDIT' : 'ADD'} RATE`}</DynamicContent.Title>
                <Row>
                  <Col md={6}>
                    <Field label="Client:" valid={validity?.payerProviderId?.valid === true} feedback={validity?.payerProviderId?.message}>
                      <ClientDropdown
                        name="payerProviderId"
                        value={values?.payerProviderId || ''}
                        onChange={(val) => onChange({ target: { name: 'payerProviderId', value: val } })}
                        placeholder="Select Client"
                        disabled={isExistingRate || disabled?.payerProviderId}
                        isValid={validity?.payerProviderId?.valid === true}
                        isInvalid={validity?.payerProviderId?.valid === false}
                        required
                      />
                    </Field>
                  </Col>
                  <Col md={6}>
                    <Field label="Airport:" valid={validity?.airportCode?.valid === true} feedback={validity?.airportCode?.message}>
                      <AirportDropdown
                        name="airportCode"
                        value={values?.airportCode || ''}
                        onChange={(value): void => {
                          setValues((current: Record<string, unknown>): Record<string, unknown> => {
                            return {
                              ...current,
                              dropCity: undefined,
                              airportCode: value,
                              thisLocationId: '',
                              thatLocationId: '',
                            };
                          });
                        }}
                        placeholder="Airport"
                        disabled={isExistingRate || disabled?.airportCode}
                        isValid={validity?.airportCode?.valid === true}
                        isInvalid={validity?.airportCode?.valid === false}
                        required
                      />
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LocationQueryDropdownField
                      name="pickupLocation"
                      label="Pickup Location"
                      airports={!isExistingRate && values?.airportCode ? [values?.airportCode] : []}
                      value={values?.thatLocationId || ''}
                      isValid={validity?.thatLocationId?.valid === true}
                      isInvalid={validity?.thatLocationId?.valid === false}
                      options={{
                        showAirportPrefix: false,
                        showClearButton: false,
                        disabled: isExistingRate,
                        locale: { 'Select...': 'Pickup Location' },
                      }}
                      onChange={(value: string): void => onChange({ target: { value, name: 'thatLocationId' } })}
                      required
                    />
                  </Col>
                  {values?.dropCity !== undefined && (
                    <Col md={6}>
                      <Field label="Dropoff City:">
                        <AirportDropdown
                          name="dropCity"
                          value={values?.dropCity || ''}
                          onChange={(value): void => onChange({ target: { name: 'dropCity', value } })}
                          placeholder="Dropoff City"
                        />
                      </Field>
                    </Col>
                  )}
                  <Col md={6}>
                    <InputGroup className="d-flex align-items-center">
                      <LocationQueryDropdownField
                        name="dropoffLocation"
                        label="Dropoff Location"
                        airports={
                          !isExistingRate && (values?.dropCity || values?.airportCode) ? [values?.dropCity || values?.airportCode] : []
                        }
                        value={values?.thisLocationId || ''}
                        isValid={validity?.thisLocationId?.valid === true}
                        isInvalid={validity?.thisLocationId?.valid === false}
                        options={{
                          showAirportPrefix: false,
                          showClearButton: false,
                          disabled: isExistingRate,
                          locale: { 'Select...': 'Dropoff Location' },
                        }}
                        onChange={(value: string): void => onChange({ target: { value, name: 'thisLocationId' } })}
                        required
                      />
                      {!isExistingRate && (
                        <Button
                          name="DROP_CITY"
                          variant="icon"
                          onClick={(): void =>
                            setValues(
                              (current: Record<string, unknown>): Record<string, unknown> => ({
                                ...current,
                                dropCity: values?.dropCity === undefined ? '' : undefined,
                              })
                            )
                          }
                        >
                          <i
                            className={getClasses('sv sv-city fs-4', values?.dropCity !== undefined ? 'text-secondary' : 'text-primary')}
                          />
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
                {(values?.items || []).map(
                  (rateItem: CreateRateItemAndAmountInput, index: number): ReactNode => (
                    <RateItem
                      data={rateItem}
                      onChange={(event: EventObject): void => handleRateItemChange('items', index)(event)}
                      onRemove={
                        values?.items?.length !== 1
                          ? (): void => {
                              const newRates = stringify.parse(values?.items || []);
                              newRates.splice(index, 1);
                              setValues((current: Record<string, unknown>): Record<string, unknown> => ({ ...current, items: newRates }));
                            }
                          : undefined
                      }
                      validity={rateItemsValidity?.[index]}
                      dateValidity={rateItemsDateValidation?.[index]?.[0]}
                      dateFeedback={rateItemsDateValidation?.[index]?.[1]}
                      index={index}
                      lastIndex={(values?.items || []).length - 1}
                      key={index}
                    />
                  )
                )}
                <Row>
                  <Col>
                    <Button
                      className="w-100 add-rate-btn"
                      onClick={(): void => {
                        const rates = values?.items || [];
                        const nextDay = new Datetime(rates?.at(-1)?.endDatetime).add(1, 'day');
                        const totalItems = rates?.length;
                        const previousRateType = (rates?.at(-1) || {})?.type || null;
                        const newRates: CreateRateItemAndAmountInput[] = [
                          ...stringify.parse(rates),
                          {
                            ...initForm.items[0],
                            type: previousRateType,
                            startDatetime:
                              totalItems === 0 && previousRateType === RateItemTypeEnum.NonContract
                                ? null
                                : nextDay.setTime('00:00:00').datetimeInput,
                            endDatetime:
                              totalItems > 0 && previousRateType === RateItemTypeEnum.NonContract
                                ? null
                                : nextDay.setTime('23:59:59').datetimeInput,
                          },
                        ];
                        setValues((current: Record<string, unknown>): Record<string, unknown> => ({ ...current, items: newRates }));
                      }}
                    >
                      <i className="fa fa-plus" />
                      ADD RATE
                    </Button>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md={6}>
                    <FormField name="duration" label="Trip Duration:" value={values?.duration || ''} placeholder="Trip Duration" readOnly />
                  </Col>
                  <Col md={6}>
                    <FormField
                      name="tripDistance"
                      label="Trip Distance:"
                      value={values?.tripDistance || ''}
                      placeholder="Trip Distance"
                      readOnly
                    />
                  </Col>
                </Row> */}
                {isExistingRate && (
                  <HasPermission name="allowOverrideRate">
                    <>
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center mt-3 gap-2">
                            <FormCheck
                              id="applyToSimilarTrips"
                              name="applyToSimilarTrips"
                              className="mt-1 pointer"
                              type="checkbox"
                              checked={values?.applyToSimilarTrips}
                              onChange={(): void =>
                                setValues(
                                  (current: Record<string, unknown>): Record<string, unknown> => ({
                                    ...current,
                                    applyToSimilarTrips: !values?.applyToSimilarTrips,
                                  })
                                )
                              }
                            />
                            <label className="d-flex w-100 gap-2" htmlFor="applyToSimilarTrips">
                              <span>Apply new rate to trips</span>
                              <span>
                                <i className="sv sv-broom fs-5 opacity-50" />
                              </span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      {values?.applyToSimilarTrips && (
                        <RateItem
                          data={values?.override[0]}
                          onChange={(event: EventObject): void => handleRateItemChange('override', 0)(event)}
                          validity={overrideValidity[0]}
                        />
                      )}
                    </>
                  </HasPermission>
                )}
                <FormContainer.Footer>
                  <Row>
                    <Col>
                      <Button className="w-100" onClick={handleHide} variant="secondary" name="CANCEL">
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="w-100"
                        variant="primary"
                        onClick={onSubmit}
                        disabled={!valid || !formContainerRef?.current?.isDirty?.()}
                        name="RESOLVE"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                  {isExistingRate && (
                    <Row>
                      <Col className="py-3">
                        <DynamicContent.Title>Rate History</DynamicContent.Title>
                        <RateHistory rateId={data?.id} />
                      </Col>
                    </Row>
                  )}
                </FormContainer.Footer>
              </Container>
            );
          }}
        />
      </DynamicContent>
      {show && overridePayload && (
        <OverrideModal
          data={overridePayload}
          onHide={(): void => {
            setState((current: EditRatesFormState): EditRatesFormState => ({ ...current, overridePayload: undefined }));
            onHide();
          }}
        />
      )}
    </>
  );
};

type RateItemProps = {
  data: Partial<RateItem>;
  onChange: OnChange;
  validity: Record<string, Validation.Validity>;
  dateValidity?: Validation.ValidityType;
  dateFeedback?: string;
  index?: number;
  lastIndex?: number;
  dateOverlaps?: number[];
  onRemove?: () => void;
};
const RateItem = ({ data, onChange, validity, dateValidity, dateFeedback, index = 0, onRemove }: RateItemProps): ReactNode => {
  // TODO: Fix this logic. There needs to be a more specific way to specify if this is an override.
  const isOverride = !Object.keys(data || {}).includes('type');
  const [from, to] = useMemo((): [string, string] => {
    const start = data?.startDatetime ? new Datetime(data?.startDatetime).datetimeInput : null;
    const end = data?.endDatetime ? new Datetime(data?.endDatetime).datetimeInput : null;
    return [start, end];
  }, [data?.startDatetime, data?.endDatetime]);

  return (
    <FormContainer.Group>
      <FormContainer.GroupContent>
        {!isOverride && (
          <Col md={12}>
            <EnumDropdownField
              label="Type:"
              name="type"
              value={data?.type}
              onChange={(value: string): boolean => {
                onChange({ target: { name: 'type', value } });
                return false;
              }}
              valid={validity?.type?.valid === Validation.ValidityType.VALID}
              enum={RateItemTypeEnum}
              options={{ showClearButton: false, locale: { NonContract: 'Non-Contract' } }}
              required
            />
          </Col>
        )}
        <Col md={12}>
          <FormField
            type="number"
            name="rate"
            label={isOverride ? 'Override Rates:' : 'Rate:'}
            onChange={(event: EventObject): void => {
              event.stopPropagation();
              const { name, value } = event.target;
              onChange({ target: { name, value: value } });
            }}
            onBlur={(event: EventObject): void => {
              event.stopPropagation();
              const { name, value } = event.target;
              onChange({ target: { name, value: Math.min(Math.max(parseFloat(value), 0.01), 9999).toFixed(2) } });
            }}
            value={data?.rate === 0 ? 0 : data?.rate || ''}
            placeholder="Amount"
            valid={validity?.rate?.valid}
            condensed
            required
            append={
              <SelectCurrency
                name="currency"
                label="currency"
                onChange={(event: EventObject): void => {
                  const { value } = event.target;
                  if (value && value !== data?.currency) onChange(event);
                }}
                value={data?.currency || ''}
                placeholder="Currency"
                valid={validity?.currency?.valid}
              />
            }
            onWheel={(event: EventObject): void => event?.target?.blur()}
          />
        </Col>
        <Col md={12}>
          <FormField
            name="dates"
            label="Dates:"
            type="daterange"
            value={[from, to]}
            format={DATE_FE_FORMAT_FULL}
            onChange={onChange}
            valid={dateValidity}
            options={{
              feedback: { className: 'text-end pe-1' },
            }}
            feedback={dateFeedback}
            required
            allowClear
            placeholder="---"
          />
        </Col>
      </FormContainer.GroupContent>
      {!!onRemove && index > 0 && (
        <FormContainer.GroupControl>
          <Col>
            <Button name="DELETE_RATE_BLOCK" className="p-0" variant="icon" onClick={onRemove}>
              <i className="sv sv-trash2 fs-5 text-primary" />
            </Button>
          </Col>
        </FormContainer.GroupControl>
      )}
    </FormContainer.Group>
  );
};

export default EditRatesForm;

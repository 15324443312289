import { DeleteFcrBulkInput, DeleteFcrInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { DeleteFcrBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type DeleteFcrBulkGraphApiResponse = GraphApiResponse<typeof DeleteFcrBulkDocument>;

const title = 'Delete Complaints';

const deleteFcrBulkResponseSelector = (res: DeleteFcrBulkGraphApiResponse): number =>
  !res.errors ? res?.deleteFcrBulk?.deleted || 0 : undefined;

const [runDeleteFcrBulk] = graphApi(DeleteFcrBulkDocument, {
  onError: (res: DeleteFcrBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteFcrBulk = async (ids: string[]): Promise<number> => {
  const input: DeleteFcrBulkInput = { search: ids.map((id: string): DeleteFcrInput => ({ id })) };

  const res = await runDeleteFcrBulk({ input });
  const selected = deleteFcrBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteFcrBulk = createGraphApiHook(deleteFcrBulk);

export default deleteFcrBulk;

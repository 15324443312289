import { customUpdateHandler } from '../../utils';
import gql from 'graphql-tag';

const queryKeys = [
  'username',
  'avatar',
  'roleId',
  'firstName',
  'lastName',
  'email',
  'phone',
  'countryCode',
  'address',
  'timezone',
  'employeeId',
  'dob',
  'active',
  'companyId',
  'providerId',
  'cityName',
  'stateCode',
  'zipCode',
  'startDate',
  'notes',
  'longTrip',
];
const validateUser = (user) => {
  const errors = {};
  // TODO: Add User Validate logic
  return errors;
};

const updateUserProfileBulk = {
  query: gql`
    mutation RunUpdateUserProfileBulk($input: RunUpdateUserProfileBulkInput!) {
      runUpdateUserProfileBulk(input: $input) {
        response {
          error {
            code
            debug
            message
          }
          updated
        }
      }
    }
  `,
  wrapper:
    (fireQuery) =>
    async (inputs = []) => {
      const updates = inputs.length > 0 ? inputs : null;
      return fireQuery({
        variables: {
          input: { updates },
        },
      });
    },
  handler: customUpdateHandler('Update User Profile', 'runUpdateUserProfileBulk'),
  selector: undefined,
  validation: validateUser,
  queryKeys,
};

export default updateUserProfileBulk;

import { GraphApi } from '../../models';
import { ResetPasswordLinkCodeInput } from '../../models/gen/graphql';
import { Validation } from '../../utils';
import gql from 'graphql-tag';

const resetPasswordLinkCode: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (code: string, username: string, password: string, confirmPassword: string): Promise<any> => {
      const credential = Validation.isEmail(username) ? 'email' : 'username';
      const input: ResetPasswordLinkCodeInput = {
        code,
        [credential]: username,
        password,
        confirmPassword,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): boolean => !!data?.resetPasswordLinkCode?.success || false,
  query: gql`
    mutation ResetPasswordLinkCode($input: ResetPasswordLinkCodeInput!) {
      resetPasswordLinkCode(input: $input) {
        error {
          code
          debug
          message
        }
        success
      }
    }
  `,
};

export default resetPasswordLinkCode;

import { CreateStopBulkInput, CreateStopInput, CreateStopResponse } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreateStopBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validateStop = validateObj({
  tripId: (val: any): any => val || null,
  type: (val: any): any => val || null,
  locationId: (val: any): any => val || null,
  scheduled: (val: any): any => val || null,
  completed: (val: any): any => val || null,
  actual: (val: any): any => val || null,
});

type CreateStopBulkGraphApiResponse = GraphApiResponse<typeof CreateStopBulkDocument>;

const title = 'Create Stops';

const createStopBulkResponseSelector = (res: CreateStopBulkGraphApiResponse): CreateStopResponse[] =>
  !res.errors ? ((res?.createStopBulk?.output || []) as CreateStopResponse[]) : undefined;

const [runCreateStopBulk] = graphApi(CreateStopBulkDocument, {
  onError: (res: CreateStopBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createStopBulk = async (stops: CreateStopInput[]): Promise<CreateStopResponse[]> => {
  const input: CreateStopBulkInput = { values: stops.map((stop: CreateStopInput): CreateStopInput => validateStop(stop)) };

  const res = await runCreateStopBulk({ input });
  const selected = createStopBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateStopBulk = createGraphApiHook(createStopBulk);

export default createStopBulk;

import { Button } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { TripCombineTypeEnum } from '../../../../../models/gen/graphql';

const CombineCell = ({
  combineId,
  combineType,
  onClick,
}: {
  combineId: string;
  combineType: TripCombineTypeEnum;
  onClick: () => void;
}): React.JSX.Element => (
  <Tippy content="Combine">
    <Button variant="transparent" className="p-0" onClick={onClick}>
      <i className={`sv sv-combine-icon fs-5 ${combineId || !!combineType ? 'text-green' : 'text-secondary'}`} />
    </Button>
  </Tippy>
);
export default CombineCell;

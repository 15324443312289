import React from 'react';
import Tippy from '@tippyjs/react';
import useClassNames from '../../hooks/useClassNames';

type CopyButtonProps = {
  text: string;
  tooltip?: string;
  className?: string;
  children?: any;
  placement?: 'left' | 'right' | 'bottom' | 'top';
  [x: string]: any;
};

const CopyButton = ({ text, tooltip, className, children, placement, ...props }: CopyButtonProps): JSX.Element => {
  const classNames = useClassNames('opacity-hover', 'text-gray', '{cursor:pointer;}', className);
  return (
    <Tippy content={tooltip || 'Copy to Clipboard'} placement={placement}>
      <span className={classNames} onClick={(): Promise<void> => navigator.clipboard.writeText(text)} {...props}>
        {children || <i className="sv sv-copy" />}
      </span>
    </Tippy>
  );
};

export default CopyButton;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FcrReport .FcrHeader .col:not(:first-child) {
  position: relative;
}
.FcrReport .FcrHeader .col:not(:first-child)::before {
  content: "";
  height: 3rem;
  border-left: 1px solid var(--bs-primary);
  position: absolute;
  top: 0.5rem;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Fcrs/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;EACA,wCAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;AACN","sourcesContent":[".FcrReport .FcrHeader .col:not(:first-child) {\n  position: relative;\n}\n.FcrReport .FcrHeader .col:not(:first-child)::before {\n  content: \"\";\n  height: 3rem;\n  border-left: 1px solid var(--bs-primary);\n  position: absolute;\n  top: 0.5rem;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

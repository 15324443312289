import { CreateLicenseBulkInput, CreateLicenseResponse, License } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreateLicenseBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validateLicense = validateObj({
  expirationDate: (val: any): any => val || null,
  licenseTypeId: (val: any): any => val || '',
  number: (val: any): any => val || '',
  stateCode: (val: any): any => val || '',
  userId: (val: any): any => val || '',
});

type CreateLicenseBulkGraphApiResponse = GraphApiResponse<typeof CreateLicenseBulkDocument>;

const title = 'Create Licenses';

const createLicenseBulkResponseSelector = (res: CreateLicenseBulkGraphApiResponse): CreateLicenseResponse[] =>
  !res.errors ? ((res?.createLicenseBulk?.output || []) as CreateLicenseResponse[]) : undefined;

const [runCreateLicenseBulk] = graphApi(CreateLicenseBulkDocument, {
  onError: (res: CreateLicenseBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createLicenseBulk = async (licenses: License[]): Promise<CreateLicenseResponse[]> => {
  const input: CreateLicenseBulkInput = {
    values: licenses.map(validateLicense),
  };

  const res = await runCreateLicenseBulk({ input });
  const selected = createLicenseBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateLicenseBulk = createGraphApiHook(createLicenseBulk);

export default createLicenseBulk;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select,
.Select:active,
.Select:hover,
.Select:focus {
  position: relative;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.Select :after,
.Select:active :after,
.Select:hover :after,
.Select:focus :after {
  margin: auto 0;
}
.Select input,
.Select:active input,
.Select:hover input,
.Select:focus input {
  color: inherit;
  border: 0;
  outline: none;
  line-height: 1rem;
}
.Select input + i,
.Select:active input + i,
.Select:hover input + i,
.Select:focus input + i {
  position: absolute;
  top: calc(50% - 0.5em);
  right: 1.5rem;
}
.Select input.hasValue::placeholder,
.Select:active input.hasValue::placeholder,
.Select:hover input.hasValue::placeholder,
.Select:focus input.hasValue::placeholder {
  color: var(--bs-dark) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-success);
  z-index: 9999;
}

.dropdown-item.highlight {
  background-color: rgba(0, 0, 0, 0.12);
  color: #222;
  z-index: 9999;
}

.dropdown-menu {
  overflow-y: auto;
  z-index: 9999;
}

.dropdown-toggle {
  padding-right: 1.5rem;
}
.dropdown-toggle:not(.disabled) {
  background-color: #fff;
}
.dropdown-toggle::after {
  position: absolute;
  right: 0.75rem;
  top: calc(50% - 0.1em);
}`, "",{"version":3,"sources":["webpack://./src/components/Select/styles.scss"],"names":[],"mappings":"AAAA;;;;EAIE,kBAAA;EACA,4EAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAAE;;;;EACE,cAAA;AAKJ;AAHE;;;;EACE,cAAA;EACA,SAAA;EACA,aAAA;EACA,iBAAA;AAQJ;AAPI;;;;EACE,kBAAA;EACA,sBAAA;EACA,aAAA;AAYN;AAVI;;;;EACE,gCAAA;AAeN;;AAXA;;EAEE,mCAAA;EACA,aAAA;AAcF;;AAXA;EACE,qCAAA;EACA,WAAA;EACA,aAAA;AAcF;;AAXA;EACE,gBAAA;EACA,aAAA;AAcF;;AAXA;EACE,qBAAA;AAcF;AAZE;EACE,sBAAA;AAcJ;AAXE;EACE,kBAAA;EACA,cAAA;EACA,sBAAA;AAaJ","sourcesContent":[".Select,\n.Select:active,\n.Select:hover,\n.Select:focus {\n  position: relative;\n  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  text-align: left;\n}\n.Select :after,\n.Select:active :after,\n.Select:hover :after,\n.Select:focus :after {\n  margin: auto 0;\n}\n.Select input,\n.Select:active input,\n.Select:hover input,\n.Select:focus input {\n  color: inherit;\n  border: 0;\n  outline: none;\n  line-height: 1rem;\n}\n.Select input + i,\n.Select:active input + i,\n.Select:hover input + i,\n.Select:focus input + i {\n  position: absolute;\n  top: calc(50% - 0.5em);\n  right: 1.5rem;\n}\n.Select input.hasValue::placeholder,\n.Select:active input.hasValue::placeholder,\n.Select:hover input.hasValue::placeholder,\n.Select:focus input.hasValue::placeholder {\n  color: var(--bs-dark) !important;\n}\n\n.dropdown-item.active,\n.dropdown-item:active {\n  background-color: var(--bs-success);\n  z-index: 9999;\n}\n\n.dropdown-item.highlight {\n  background-color: rgba(0, 0, 0, 0.12);\n  color: #222;\n  z-index: 9999;\n}\n\n.dropdown-menu {\n  overflow-y: auto;\n  z-index: 9999;\n}\n\n.dropdown-toggle {\n  padding-right: 1.5rem;\n}\n.dropdown-toggle:not(.disabled) {\n  background-color: #fff;\n}\n.dropdown-toggle::after {\n  position: absolute;\n  right: 0.75rem;\n  top: calc(50% - 0.1em);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

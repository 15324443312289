import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

const childQuery = `
children {
  values {
    key
    value
  }
  details {
    id
    filters {
      csvColumns {
        key
      }
      groupBy
      startDatetime
      endDatetime
      airports
      clients
      currencies
      airlines
      reason
      rating
      drivers
      companies
      kinds
      doLocations
      puLocations
      types
      status
      buckets {
        name
        hour
        minute
      }
      complexFilters
      completionTypes
      series
      reportType
      thresholds
      rates
      rateTypes
    }
  }
`;
export default (reportType: string, depth: number = 3): DocumentNode => gql`
  query ReportQuery($input: ReportInput) {
    reportQuery {
      ${reportType}(input: $input) {
        error
        output {
          header {
            key
            value
            name
            description
          }
          filters {
            key
            value
            options
          }
          tables {
            metadata {
              header {
                key
                value
                name
                description
              }
              footer {
                key
                value
                name
                description
              }
              columns {
                key
                value
                name
                description
              }
            }
            details {
              filters {
                csvColumns {
                  key
                }
                groupBy
                startDatetime
                endDatetime
                airports
                clients
                currencies
                airlines
                reason
                rating
                drivers
                companies
                kinds
                doLocations
                puLocations
                types
                status
                buckets {
                  name
                  hour
                  minute
                }
                complexFilters
                completionTypes
                series
                reportType
                thresholds
                rates
                rateTypes
              }
            }
            children {
              details {
                id
                filters {
                  csvColumns {
                    key
                  }
                  groupBy
                  startDatetime
                  endDatetime
                  airports
                  clients
                  currencies
                  airlines
                  reason
                  rating
                  drivers
                  companies
                  kinds
                  doLocations
                  puLocations
                  types
                  status
                  buckets {
                    name
                    hour
                    minute
                  }
                  complexFilters
                  completionTypes
                  series
                  reportType
                  thresholds
                  rates
                  rateTypes
                }
              }
              values {
                key
                value
                name
                description
              }
              ${Array.from({ length: depth }, (): string => childQuery).join(' ')}
              ${Array.from({ length: depth }, (): string => '}').join(' ')}
            }
          }
        }
      }
    }
  }
`;

export const ReportQueryAsCsv = (reportType: string, _depth: number): DocumentNode => gql`
  query ReportQuery($input: ReportInput) {
    reportQuery {
      ${reportType}AsCsv(input: $input) {
        error
        output
      }
    }
  }
`;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PunchPolicy .VirtualList {
  margin-top: 2rem !important;
}
.PunchPolicy .VirtualList .AddedLocation {
  margin: 2rem 0;
  padding: 0 4rem 0 5.5rem;
}
.PunchPolicy .VirtualList .AddedLocation .col-md-10 {
  padding: 0;
}
.PunchPolicy .VirtualList .AddedLocation .col-md-10 .FormField-Label {
  font-size: 0.9rem;
  color: var(--bs-primary);
}

.punch-policy-start-date input {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/PunchPolicy/styles.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AACE;EACE,cAAA;EACA,wBAAA;AACJ;AACI;EACE,UAAA;AACN;AACM;EACE,iBAAA;EACA,wBAAA;AACR;;AAKA;EACE,kBAAA;AAFF","sourcesContent":[".PunchPolicy .VirtualList {\n  margin-top: 2rem !important;\n}\n.PunchPolicy .VirtualList .AddedLocation {\n  margin: 2rem 0;\n  padding: 0 4rem 0 5.5rem;\n}\n.PunchPolicy .VirtualList .AddedLocation .col-md-10 {\n  padding: 0;\n}\n.PunchPolicy .VirtualList .AddedLocation .col-md-10 .FormField-Label {\n  font-size: 0.9rem;\n  color: var(--bs-primary);\n}\n\n.punch-policy-start-date input {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import './styles.scss';

import { Button, Form, InputGroup } from 'react-bootstrap';
import React, { ReactNode, useRef, useState } from 'react';

import { DynamicCell } from '..';
import Tippy from '@tippyjs/react';
import useOnClickOutside from '@/hooks/useOnClickOutside';

// TODO: Better typing support - Dane E. Parchment Jr.
export const EditableCell = ({
  onEdit,
  options = {},
  disabled = false,
  display: Display = ({ children }: { children: ReactNode; onCancel?: () => void }) => children,
  format = undefined,
  ...props
}: any): JSX.Element => {
  const formRef = useRef(undefined);
  const [state, setState] = useState({ editing: false, savedValue: undefined });
  const { editing, savedValue } = state;

  const Render = ({ value }: { value: string }): string | JSX.Element => {
    const [localValue, setLocalValue] = useState(savedValue !== undefined ? savedValue : value);
    const onSubmit = (): void => {
      setState((current: any): any => ({ ...current, editing: false, savedValue: localValue }));
      onEdit(localValue);
    };
    const onSuggestion = (value): void => {
      setState((current: any): any => ({ ...current, editing: false, savedValue: value }));
      onEdit(value);
    };
    const onCancel = (): void => setState((current: any): any => ({ ...current, editing: false }));
    // TODO: Better typing support - Dane E Parchment Jr.
    const onChange = (event: any): void => {
      const value = options?.input?.onChange ? options.input.onChange(event) : event.target.value;
      setLocalValue(value);
    };
    useOnClickOutside(formRef, (e) => {
      const target = e.target as HTMLElement;
      if (target.className.includes('Suggested-Rates')) return;
      onCancel();
    });

    return (
      <div className="EditableCell">
        <Tippy
          content={
            editing ? (
              <InputGroup>
                <Display
                  onCancel={onCancel}
                  onValueChange={(val) => {
                    onSuggestion(val);
                  }}
                >
                  <div
                    className="d-flex"
                    ref={formRef}
                    onDoubleClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Form.Control
                      name="RATE_INPUT_FIELD"
                      className="border-primary {outline:none!;box-shadow:none!;}"
                      size="sm"
                      {...(options?.input || {})}
                      onChange={onChange}
                      onKeyDown={(event: any): void => {
                        if (event.key === 'Enter') onSubmit();
                        if (event.key === 'Escape') onCancel();
                      }}
                      value={localValue}
                      onFocus={(event: any): void => event.target.select()}
                      autoFocus={options?.input?.autoFocus || false}
                    />
                    <Button name="SUBMIT" className="{border-radius:0!;}" variant="outline-success" onClick={onSubmit}>
                      <i className="fa fa-save" />
                    </Button>
                    <Button
                      name="CANCEL"
                      className="{border-top-left-radius:0!;border-bottom-left-radius:0!;}"
                      variant="outline-danger"
                      onClick={onCancel}
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </div>
                </Display>
              </InputGroup>
            ) : null
          }
          visible={editing}
          interactive={true}
          appendTo={document.body}
        >
          <Button
            variant="icon"
            className="EditableCell-Value d-flex w-100 p-0 {position:relative;}"
            onClick={
              onEdit && !disabled
                ? (): void => {
                    setState((current: any): any => ({ ...current, editing: true }));
                    setLocalValue(savedValue !== undefined ? savedValue : value);
                  }
                : undefined
            }
          >
            <span className="flex-grow-1">{format ? format(savedValue || value) : savedValue || value}</span>
            {onEdit && !disabled && (
              <small className="cursor:pointer;">
                <i className="fa fa-pencil opacity-hover" />
              </small>
            )}
          </Button>
        </Tippy>
      </div>
    );
  };
  return <DynamicCell {...props} value={savedValue} render={Render} />;
};

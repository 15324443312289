import React, { ReactPortal, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import useClassNames from '../../hooks/useClassNames';

const PageInfo = ({ className = '', children = [], ...props }: { className?: string; children?: any }): ReactPortal => {
  const [mountPoint, setMountPoint] = useState(null);

  useEffect(() => {
    if (mountPoint !== null) return;
    const e = document.querySelector(`#PageInfo`);
    setMountPoint(e);
  }, [children]);
  const classes = useClassNames('PageInfo-Item', 'd-flex flex-column flex-grow-0 justify-content-center', className);

  if (!mountPoint) return null;
  return createPortal(
    <div {...props} className={classes}>
      {children}
    </div>,
    mountPoint
  );
};

export default PageInfo;

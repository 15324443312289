import React, { ReactPortal, useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import TippyWhen from '../TippyWhen';
import { createPortal } from 'react-dom';

enum SidebarHorizontalAlignment {
  LEFT = 'Sidebar-Left',
  RIGHT = 'Sidebar-Right',
}
enum SidebarVerticalAlignment {
  TOP = 'Sidebar-Top',
  BOTTOM = 'Sidebar-Bottom',
}
const SidebarButton = ({
  xAlign = SidebarHorizontalAlignment.RIGHT,
  yAlign = SidebarVerticalAlignment.TOP,
  tooltip,
  ...props
}: {
  xAlign?: SidebarHorizontalAlignment;
  yAlign?: SidebarVerticalAlignment;
  tooltip?: string;
  [x: string]: any;
}): ReactPortal => {
  const [mountPoint, setMountPoint] = useState(null);

  useEffect(() => {
    if (mountPoint !== null) return;
    const e = document.querySelector(`#${xAlign}>.${yAlign}`);
    if (!e) return;
    setMountPoint(e);
  }, [xAlign, yAlign]);

  if (!mountPoint) return null;
  return createPortal(
    <TippyWhen isTrue={!!tooltip} options={{ content: tooltip, placement: xAlign === SidebarHorizontalAlignment.RIGHT ? 'left' : 'right' }}>
      <Button variant="primary" {...props} />
    </TippyWhen>,
    mountPoint
  );
};

SidebarButton.xAlign = SidebarHorizontalAlignment;
SidebarButton.yAlign = SidebarVerticalAlignment;

export default SidebarButton;

import { Badge, Button } from 'react-bootstrap';
import { ConnectionDetails, QueryInputType, Validation, createNotification, onEnter, queryInput, titleCase } from '../../utils';
import React, { useRef, useState } from 'react';
import { SEARCH_USERS_TABLE_PAGE_SIZE, useSearchUserTable } from '@/api/services/users/searchUsers';
import { SortDirectionEnum, UpdateUserActivationInput, User, UserActivationActionEnum } from '../../models/gen/graphql';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '../../components/VirtualTable';

import ActiveSwitch from '../../components/ActiveSwitch';
import EditUserPasswordModal from '../../components/EditUsersModal/EditUserPasswordModal';
import EditUsersModal from '../../components/EditUsersModal';
import Filters from '../../components/Filters';
import FormButton from '../../components/FormButton';
import FormField from '../../components/FormField';
import Logger from '../../utils/logs';
import PageInfo from '../../components/PageInfo';
import SelectActiveStatus from '../../components/SelectActiveStatus';
import SelectAirportGroup from '../../components/SelectAirportGroup';
import SelectRole from '../../components/SelectRole';
import { Toast } from '../../models';
import { getClasses } from '../../utils/strings';
import updateUserActivationBulk from '../../api/services/users/updateUserActivationBulk';
import useUsers from '../../hooks/useUsers';

const log = Logger.of('UsersTable');

type UserTableState = {
  selected: string[];
  editing: boolean;
  creating: boolean;
  resetPassword: boolean;
  search: string;
  sorting: { column: string; direction: string };
  airports: string[];
};

const initUsersTableState: UserTableState = {
  selected: [],
  editing: false,
  creating: false,
  resetPassword: false,
  search: '',
  sorting: { column: undefined, direction: undefined },
  airports: [],
};

const UsersTable = (): JSX.Element => {
  // Init State
  const [state, setState] = useState(initUsersTableState);
  const { creating, editing, resetPassword, selected, search, sorting, airports } = state;

  // Init GraphApi useUsers hook
  const [{ data, loading }, { fetch, refetch, fetchMore }] = useSearchUserTable();
  const { rows, totalCount, hasNextPage } = data || {};
  const [, { handleDeleteUserBulk }] = useUsers();
  const appliedQuery = useRef(undefined);
  const { makeSortable, onSelect, filteredRows, selectedRows } = useVirtualTable(setState, { selected, sorting, rows, search });

  const onToggle =
    (key: string): (() => void) =>
    (): void =>
      setState((current: UserTableState): UserTableState => ({ ...current, [key]: !current[key] }));

  // Helper Funcs
  const getMore = async (after: number): Promise<ConnectionDetails<User>> => {
    if (filteredRows?.length < SEARCH_USERS_TABLE_PAGE_SIZE || loading || !hasNextPage) return;
    const result = await fetchMore(appliedQuery.current, {
      page: Math.round(after / SEARCH_USERS_TABLE_PAGE_SIZE),
      merge: true,
    });
    return result;
  };

  const onSubmit = async (filters) => {
    const query = {
      active: Validation.isNumber(filters?.active) ? queryInput([filters?.active]) : null,
      roleId: Validation.isValidUUID(filters?.roleId) ? queryInput(filters?.roleId) : null,
      createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc, 1),
    };
    const airports = filters?.airportCode?.length ? filters?.airportCode : [];
    appliedQuery.current = { query, airports };
    setState(
      (current: UserTableState): UserTableState => ({ ...current, airports: filters?.airportCode?.length ? filters?.airportCode : [] })
    );
    await fetch(appliedQuery.current);
  };
  const onResetPassword = async (user: User): Promise<void> => {
    setState(
      (current: UserTableState): UserTableState => ({
        ...current,
        selected: [user?.id],
        resetPassword: true,
        editing: false,
        creating: false,
      })
    );
  };
  const onDelete = async (): Promise<void> => {
    try {
      await handleDeleteUserBulk(selected);
      createNotification('User deleted.', Toast.Type.SUCCESS, 'User Deleted');
    } catch (err) {
      createNotification('Could not delete user.', Toast.Type.SUCCESS, 'Delete Failed');
    } finally {
      onHide();
    }
  };
  const onEdit = onToggle('editing');
  const onCreate = onToggle('creating');

  const onHide = async (): Promise<void> => {
    setState((current: UserTableState): UserTableState => ({ ...initUsersTableState, search: current?.search }));
    await refetch();
  };

  const handleTableSearch = ({ search }: any): void => setState((current: UserTableState): UserTableState => ({ ...current, search }));

  return (
    <>
      <Filters
        name="userFilters"
        onSubmit={onSubmit}
        onReset={(): void => setState((current: UserTableState): UserTableState => ({ ...current, search: '' }))}
        primary={({ values, onChange }): JSX.Element => {
          const { roleId, active, airportCode, search } = values;
          return (
            <>
              <FormField
                name="roleId"
                placeholder="Role"
                onChange={onChange}
                value={roleId}
                searchable
                options={{ input: { as: SelectRole } }}
                condensed
              />
              <FormField
                name="active"
                value={active}
                onChange={onChange}
                placeholder="Status"
                options={{ input: { as: SelectActiveStatus } }}
                condensed
              />
              <FormField
                name="airportCode"
                onChange={onChange}
                value={airportCode}
                placeholder="Airports"
                condensed
                searchable
                options={{ input: { as: SelectAirportGroup } }}
              />
              <FormField
                name="search"
                onChange={onChange}
                onBlur={(): void => handleTableSearch(values)}
                onKeyDown={onEnter((): void => handleTableSearch(values))}
                value={search || ''}
                placeholder="Search"
                condensed
                style={{ width: 300 }}
              />
            </>
          );
        }}
        alternate={(): JSX.Element => (
          <>
            {/* Create Button */}
            <FormButton
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="CREATE_USER"
              variant="outline-gray"
              onClick={onCreate}
            >
              Add User
            </FormButton>
            {/* Edit Bulk Button */}
            <FormButton
              icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
              name="EDIT_USER"
              variant="outline-gray"
              onClick={onEdit}
              disabled={!selected.length}
            >
              Edit User{selected.length > 1 ? 's' : ''}
              {selected.length > 1 && (
                <Badge className="ms-2" pill bg="success">
                  {selected.length}
                </Badge>
              )}
            </FormButton>
            {/* Delete Button */}
            <FormButton
              icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
              tooltip={
                <>
                  Delete User{selected.length > 1 ? 's' : ''}
                  {selected.length > 1 && (
                    <Badge className="ms-2" pill bg="success">
                      {selected.length}
                    </Badge>
                  )}
                </>
              }
              name="DELETE_USER"
              variant="outline-gray"
              onClick={onDelete}
              disabled={!selected.length}
            />
          </>
        )}
        submitOnMount
      />
      <PageInfo>
        {filteredRows?.length} / {totalCount} Users
      </PageInfo>
      <VirtualTable
        name="usersTable"
        data={filteredRows}
        loading={loading}
        selected={selected}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        header={{
          username: 'Username',
          fullName: 'Name',
          email: 'Email',
          role: { name: 'Role' },
          employeeId: 'Employee ID',
          active: 'Active',
          reset: 'Reset PW',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void =>
              setState(
                (current: UserTableState): UserTableState => ({
                  ...current,
                  selected: Array.from(new Set([data?.id])),
                  editing: true,
                })
              )
            }
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="username"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('username')}
            />
            <DynamicCell
              selector="fullName"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('fullName')}
            />
            <DynamicCell
              selector="email"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('email')}
            />
            <DynamicCell
              selector="role.name"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('role.name')}
              render={({ data, value }: { data: User; value: string }): string => titleCase(data?.role?.name) || ''}
            />
            <DynamicCell
              selector="employeeId"
              placeholder="--"
              className="text-center"
              width="calc(100% / 12)"
              sorting={makeSortable('employeeId')}
            />
            <DynamicCell
              selector="active"
              placeholder="--"
              className="text-center"
              width="calc(100% / 12)"
              sorting={makeSortable('active')}
              render={({ data, value }: { data: User; value: string }): JSX.Element | string => (
                <ActiveSwitch
                  title="Update User Activation"
                  value={!!data?.active}
                  onHide={(): void => {
                    refetch();
                  }}
                  onSubmit={async (checked: boolean): Promise<void> => {
                    const input: UpdateUserActivationInput = {
                      userId: data?.id,
                      action: checked ? UserActivationActionEnum.Activate : UserActivationActionEnum.Deactivate,
                    };
                    const res = await updateUserActivationBulk([input]);
                    if (!res?.usersUpdated) throw new Error('Something went wrong. Try again.');
                  }}
                />
              )}
            />
            <DynamicCell
              selector="reset"
              placeholder="--"
              className="text-center"
              width="calc(100% / 14)"
              render={({ data, value }: { data: User; value: string }): string | JSX.Element => (
                <Button onClick={(): Promise<void> => onResetPassword(data)} variant="transparent">
                  <i className="sv sv-rotation-lock fs-5 {cursor:pointer;}" />
                </Button>
              )}
            />
          </VirtualTableRow>
        )}
      />
      <EditUsersModal
        show={editing || creating}
        data={editing ? selectedRows[0] : undefined}
        onHide={onHide}
        options={{
          selected,
        }}
      />
      <EditUserPasswordModal show={resetPassword} data={resetPassword ? selectedRows[0] : undefined} onHide={onHide} />
    </>
  );
};

export default UsersTable;

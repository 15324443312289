import { FcrConnectionInput, FcrSearch } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchFcrs: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, query: FcrSearch[] = null, options: any = {}): Promise<any> => {
      const input: FcrConnectionInput = {
        first,
        after,
        query: query && !!Object.keys(query).length ? query : null,
      };

      return fireQuery({
        variables: {
          input,
        },
        ...options,
      });
    },
  selector: getConnectionDetails('searchFcrs.fcrConnection'),
  query: gql`
    query SearchFcrs($input: FcrConnectionInput) {
      searchFcrs {
        fcrConnection(input: $input) {
          edges {
            node {
              cause
              description
              fcrTypeId
              id
              tripId
              createdAt
            }
          }
        }
      }
    }
  `,
};

export default searchFcrs;

import { CreateGroupBulkInput, CreateGroupBulkResponse, CreateGroupInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreateGroupBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { Validation } from '../../../utils';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type CreateGroupBulkGraphApiResponse = GraphApiResponse<typeof CreateGroupBulkDocument>;

const title = 'Create Groups';

export const createGroupValidator = new Validation.Validator({
  'name!': (val: string): Validation.Validity => ({
    valid: val?.length >= 2 && Validation.isAlphanumeric(val),
  }),
  'companyId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  'active!': (val: string): Validation.Validity => ({
    valid: Validation.isNumber(parseInt(val)),
  }),
});

const createGroupBulkResponseSelector = (res: CreateGroupBulkGraphApiResponse): CreateGroupBulkResponse =>
  !res.errors ? (res?.createGroupBulk as CreateGroupBulkResponse) : undefined;

const [runCreateGroupBulk] = graphApi(CreateGroupBulkDocument, {
  onError: (res: CreateGroupBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createGroupBulk = async (groups: CreateGroupInput[]): Promise<CreateGroupBulkResponse> => {
  const values: CreateGroupInput[] = groups.map((group: CreateGroupInput): CreateGroupInput => createGroupValidator.create(group));
  const input: CreateGroupBulkInput = { values };

  const res = await runCreateGroupBulk({ input });
  const selected = createGroupBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};
export const useCreateGroupBulk = createGraphApiHook(createGroupBulk);

export default createGroupBulk;

import './styles.scss';

import { Button, Dropdown, DropdownToggleProps } from 'react-bootstrap';
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { fromArrayMaybe, onKeyAction } from '@/utils';

import Portal from '../Portal';
import { getClasses } from '@/utils/strings';
import useClassNames from '@/hooks/useClassNames';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import useUuid from '@/hooks/useUuid';

//TODO: update all options to be a custom option component so we can better type this out
export type SelectOption = { props: { value?: string; values?: string[]; children: string; [x: string]: any }; [x: string]: any };
export type SelectorObject = { label: string; value: string; values?: SelectOption[] };
export type SelectorReturn = SelectorObject[];
export type SelectOptions = {
  showSelectAll?: boolean;
};
export type SelectProps = {
  children?: Array<SelectOption>;
  name?: string;
  value?: string | string[];
  placeholder?: string;
  label?: string;
  multiple?: boolean;
  sticky?: boolean;
  onChange?: (event: { target: { name: string; value: string | string[] } }) => void;
  onFocus?: () => void;
  searchable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  className?: string;
  options?: SelectOptions;
} & Omit<DropdownToggleProps, 'children'>;

// TODO: Clean up a lot of the logic on this component. It all works, but is very messy to look at.

const FULLSCREEN_PADDING = 40; // px

type SelectState = {
  show: boolean;
  search: string;
  itemIndex: number;
};
const initSelectState: SelectState = {
  show: false,
  search: '',
  itemIndex: undefined,
};
const Select = (
  {
    children,
    name,
    value,
    placeholder,
    multiple,
    sticky,
    onChange,
    searchable,
    disabled,
    onFocus,
    loading,
    isValid,
    isInvalid,
    className,
    ...props
  }: SelectProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLInputElement>
): React.JSX.Element => {
  const [state, setState] = useState(initSelectState);
  const { show, search, itemIndex } = state;
  const justOpened = useRef(false);
  const uuid = useUuid();
  const classes = useClassNames(
    `Select${name ? ` Select-${name}` : ''} form-control`,
    className,
    !(children || []).length && !disabled ? 'pointer-events:all!' : undefined,
    disabled
      ? 'disabled {background-color:#e9ecef!;color:#212529!;pointer-events:none!;border:1px|solid|#ced4da!;opacity:1!;}'
      : isInvalid
        ? 'border-danger'
        : isValid
          ? 'border-success'
          : ''
  );
  // init Refs
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  // expose container ref to parent
  useImperativeHandle(ref, (): HTMLButtonElement | HTMLInputElement => searchInputRef.current || dropdownButtonRef.current);
  // calculates the max height of the dropdown menu based on the position of the input and the window height (minus a little padding)
  const MIN_HEIGHT = 25;
  const FULL_HEIGHT = window.innerHeight - dropdownButtonRef?.current?.getBoundingClientRect()?.y - FULLSCREEN_PADDING || 0;
  const isAbove = FULL_HEIGHT <= MIN_HEIGHT;
  const calculatedMaxHeight = isAbove ? window.innerHeight - FULLSCREEN_PADDING : Math.max(FULL_HEIGHT, MIN_HEIGHT);

  const handleFocus = useCallback(
    (nextShow: boolean): void => {
      setState((current: SelectState): SelectState => ({ ...current, show: !!nextShow }));
      if (nextShow) {
        justOpened.current = true;
        if (searchInputRef.current) searchInputRef.current.focus();
        else if (dropdownButtonRef.current) dropdownButtonRef.current.focus();
        (onFocus || ((): void => {}))();
      }
    },
    [onFocus, searchInputRef, dropdownButtonRef]
  );
  const onSearch = (event: any): void => {
    const { value } = event.target;
    setState((current: SelectState): SelectState => ({ ...current, search: value }));
  };
  const onClearSearch = (): void => {
    setState((current: SelectState): SelectState => ({ ...current, search: '' }));
  };
  const triggerChange = useCallback(
    (event: any): void => {
      if (searchable !== undefined) searchInputRef.current.focus();
      (onChange || ((): void => {}))(event);
    },
    [onChange, searchInputRef, searchable]
  );
  const handleChange = useCallback(
    (opt: any, index: number): ((event: any) => void) =>
      (event: any): void => {
        event.stopPropagation();
        (opt?.onClick || ((): void => {}))(opt, index, event);
        event.target.name = name;
        if (multiple !== undefined || sticky !== undefined) {
          const val = !!justOpened.current && sticky ? [] : fromArrayMaybe(value);
          justOpened.current = false;
          if (opt?.values) {
            const anySelected = opt?.values.map((key: string): boolean => val.includes(key));
            if (anySelected.includes(false)) {
              event.target.value = Array.from(new Set([...val, ...(opt?.values || [])]));
            } else {
              event.target.value = val.filter((key: string): boolean => !opt?.values.includes(key));
            }
          } else if (val.includes(opt?.value)) {
            event.target.value = val.filter((v: string): boolean => v !== opt?.value);
          } else {
            event.target.value = Array.from(new Set([...val, opt?.value]));
          }
          return triggerChange(event);
        }
        event.target.value = opt.values ? opt.values : opt?.value !== value ? opt?.value : '';
        handleFocus(false);
        return triggerChange(event);
      },
    [name, multiple, sticky, value, handleFocus, triggerChange]
  );

  const options = useMemo((): React.JSX.Element[] => {
    const opts = (Array.isArray(children) ? children : [children])
      .filter((child: SelectOption): boolean => {
        if (child?.props?.value === '+') return true;
        const hasChildren = !!child && child?.props?.children !== '';
        const containsSearch =
          !searchable ||
          !!search
            .toLowerCase()
            .trim()
            .split(' ')
            .every(
              (str: string): boolean =>
                !!(child?.props?.children || '')
                  .toLowerCase()
                  .split(/[ ]+?\|[ ]+?/)
                  .pop()
                  .includes(str.toLowerCase())
            );
        return hasChildren && containsSearch;
      })
      .sort((a: SelectOption, b: SelectOption): number => {
        const indexA = (a?.props?.children || '')
          .split(/[ ]+?\|[ ]+?/)
          .pop()
          .toLowerCase()
          .indexOf(search.toLowerCase());
        if (indexA === -1) return 0;
        const indexB = (b?.props?.children || '')
          .split(/[ ]+?\|[ ]+?/)
          .pop()
          .toLowerCase()
          .indexOf(search.toLowerCase());
        if (indexB === -1) return 0;
        return indexA < indexB ? -1 : 0;
      });
    if ((multiple !== undefined || sticky !== undefined) && opts.length > 0) {
      const selectAllOptions = {};
      opts.forEach((child: SelectOption): void => {
        const values = child?.props?.values;
        const value = child?.props?.value;
        if (values?.length) values.forEach((val: string): string => (selectAllOptions[val] = val));
        else selectAllOptions[value] = value;
      });
      if (props?.options?.showSelectAll !== false)
        opts.unshift(
          <option value="*" style={{ borderBottom: '1px solid #ccc' }} {...{ values: Object.keys(selectAllOptions) }}>
            Select All
          </option>
        );
    }
    const allOptions = opts.map((child: SelectOption, c: number): JSX.Element => {
      const active = Array.isArray(value)
        ? value.includes(child?.props?.value) ||
          (child?.props?.values && !child?.props?.values.map((key: string): boolean => value.includes(key)).includes(false))
        : value === child?.props?.value;
      return (
        <Dropdown.Item
          {...(child?.props || {})}
          className={`${child?.props?.className || ''} ${c === itemIndex ? 'highlight' : ''}`}
          active={active}
          onClick={handleChange(child?.props, c)}
          key={c}
        >
          {child?.props?.children}
          {active && <i className="fa fa-times ms-2" />}
        </Dropdown.Item>
      );
    });

    return allOptions;
  }, [children, multiple, sticky, searchable, search, value, itemIndex, handleChange]);

  const displayName = useMemo((): string => {
    if (!value) return undefined;
    if (Array.isArray(value)) {
      return value
        .map(
          (val: string): string =>
            (Array.isArray(children) ? children : [children])
              .find((opt: SelectOption): boolean => opt?.props?.value === val)
              ?.props?.children?.toString() || undefined
        )
        .join(', ');
    }
    return (
      (Array.isArray(children) ? children : [children]).find((opt: SelectOption): boolean => opt?.props?.value === value)?.props
        ?.children || undefined
    );
  }, [value, children]);
  const handleSelection = (index: number = 0): [number, any] => {
    index = Math.min(Math.max(index, 0), options.length - 1);
    const selected: any = document.querySelector('.dropdown-menu > a.highlight');
    const firstOption = document.querySelectorAll('.dropdown-menu > a:not([value="+"])')[index];
    const element: any = selected || firstOption;
    return [index, element];
  };

  useEffect((): void => {
    // Reset Search - This is for reset logic when value gets cleared, we want the search input to get cleared as well.
    if (!searchable || !value?.length) return;
    onClearSearch();
  }, [value, searchable]);

  useEffect((): void => {
    // reset itemIndex when show is false
    if (show) return;
    setState(
      (current: SelectState): SelectState => ({
        ...current,
        itemIndex: undefined,
      })
    );
  }, [show]);
  const handleKeyAction = (event: React.KeyboardEvent): void => {
    if (event.ctrlKey || event.altKey || event.shiftKey || event.key === 'Escape') return;
    event.stopPropagation();
    if (event.key === 'Enter') event.preventDefault();
    if (!show && event.key !== 'Tab' && (!!searchInputRef.current || ['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key))) {
      handleFocus(true);
    }
    return onKeyAction({
      ArrowUp: (): void => {
        setState((current: SelectState): SelectState => {
          if (!options.length) return current;
          const [newIndex, element] = handleSelection((current.itemIndex || options.length) - 1);
          element?.scrollIntoView?.(false);
          return { ...current, itemIndex: newIndex };
        });
      },
      ArrowDown: (): void => {
        setState((current: SelectState): SelectState => {
          if (!options.length) return current;
          const [newIndex, element] = handleSelection((current.itemIndex === undefined ? -1 : current.itemIndex) + 1);
          element?.scrollIntoView?.(false);
          return { ...current, itemIndex: newIndex };
        });
      },
      Enter: (): void => {
        const [, element] = handleSelection(itemIndex || 0);
        element?.click?.();
      },
      Tab: (): void => {
        if (show) {
          if (search) {
            const [, element] = handleSelection(itemIndex || 0);
            element?.click?.();
          }
          handleFocus(false);
        }
      },
    })(event);
  };

  const handleOutsideClick = (): void => {
    setState((current: SelectState): SelectState => {
      if (!!current?.show && !!current?.search) {
        const [, element] = handleSelection(current?.itemIndex || 0);
        element?.click?.();
      }
      return {
        ...current,
        show: show ? false : true,
      };
    });
  };

  useOnClickOutside(dropdownMenuRef, handleOutsideClick, 'mousedown');

  const ValueDisplay = (): JSX.Element => (
    <div className="{width:100%;overflow:hidden;}">
      <span className={getClasses(!displayName ? 'text-gray-subtle' : undefined)}>{displayName || placeholder || 'Select...'}</span>
    </div>
  );

  return (
    <Dropdown
      show={show}
      className={'w-100 {white-space:nowrap;}'}
      autoClose={multiple === undefined && sticky === undefined ? true : 'outside'}
      onToggle={(nextShow: boolean): void => {
        if (!!show && document?.activeElement?.['name'] === `${name}-search`) return;
        handleFocus(nextShow);
      }}
      onChange={(e: React.FormEvent<HTMLElement>): ((event: any) => void) => handleChange(e, undefined)}
      onKeyDown={handleKeyAction}
      tabIndex={searchable ? -1 : 0}
    >
      <Dropdown.Toggle
        variant=""
        {...props}
        ref={dropdownButtonRef}
        tabIndex={-1}
        className={classes}
        style={{ textAlign: 'left', ...(props.style || {}) }}
        id={uuid}
        value={multiple !== undefined ? fromArrayMaybe(value) : value}
      >
        {searchable === undefined && <ValueDisplay />}
        {searchable !== undefined && (
          <>
            <input
              name={`${name}-search`}
              className={`{width:100%;}${displayName ? ' hasValue' : ''}`}
              value={search}
              onChange={onSearch}
              placeholder={displayName || placeholder || 'Select...'}
              tabIndex={0}
              ref={searchInputRef}
              disabled={disabled}
              autoComplete="off"
              onKeyDown={handleKeyAction}
            />
            {search.length > 0 && (
              <Button className="p-0" variant="icon" onClick={onClearSearch}>
                <i className="sv sv-cross" />
              </Button>
            )}
          </>
        )}
      </Dropdown.Toggle>
      {show && (
        <Portal>
          <Dropdown.Menu
            ref={dropdownMenuRef}
            className={`max-height:${calculatedMaxHeight}px; ${multiple !== undefined || sticky !== undefined ? 'all-separator' : ''}`}
          >
            {loading ? (
              <Dropdown.Item className="text-center" disabled>
                <i className="fa fa-spinner fa-pulse" />
              </Dropdown.Item>
            ) : null}
            {options.length && !loading ? options : null}
            {(!options.length || (options.length === 1 && options[0].props.value === '+')) && !loading ? (
              <Dropdown.Item disabled>No Results</Dropdown.Item>
            ) : null}
          </Dropdown.Menu>
        </Portal>
      )}
    </Dropdown>
  );
};

export default forwardRef(Select);

import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import { createGroupValidator, useCreateGroupBulk } from '../../api/services/groups/createGroupBulk';

import EditModal from '../../components/EditModal/new';
import { Group } from '../../models/gen/graphql';
import { STATUSES } from '../../constants';
import SelectCompany from '../../components/SelectCompany';
import SelectInt from '../../components/SelectInt';
import useForm from '../../hooks/useForm';
import useValidation from '../../hooks/useValidation';

interface EditGroupsModalProps {
  show: boolean;
  title?: string;
  onHide?: Function;
  onSubmit?: Function;
  data?: Group;
}

const defaultFormData = {
  name: '',
  companyId: '',
  active: 1,
};

const EditGroupsModal = ({ show, onHide, onSubmit, data: initData, title }: EditGroupsModalProps): JSX.Element => {
  const [form, onChange, setForm] = useForm(defaultFormData);
  const [valid, validity] = useValidation(createGroupValidator, form);
  const [{ loading }, { fetch: createGroupBulk }] = useCreateGroupBulk();
  const mode = useRef<'create' | 'edit'>('create');

  const handleCleanup = (): void => {
    setForm(defaultFormData);
    mode.current = 'create';
  };

  const handleSubmit = async (): Promise<void> => {
    if (onSubmit) await onSubmit(form);
    else if (mode.current === 'create') await createGroupBulk([form]);
    onHide();
  };

  const onShow = (): void => {
    if (!show || !initData?.id) return;
    // TODO: search group data if initData.id is provided
    setForm(createGroupValidator.create(initData));
    mode.current = 'edit';
  };

  useEffect((): void => {
    if (!show) return handleCleanup();
    onShow();
  }, [show]);

  return (
    <EditModal
      show={show}
      title={title || 'Edit Group'}
      icon="fa fa-location-dot"
      size="sm"
      onHide={onHide}
      onSubmit={valid ? handleSubmit : false}
      options={undefined}
      loading={loading}
      name="editGroup"
    >
      <Row>
        <Col>
          <InputGroup className="mt-3">
            <InputGroup.Text>Name:</InputGroup.Text>
            <Form.Control
              name="name"
              maxLength={16}
              value={form?.name || ''}
              isInvalid={validity?.name?.valid === false}
              isValid={validity?.name?.valid === true}
              onChange={onChange}
            />
          </InputGroup>

          <InputGroup className="mt-3">
            <InputGroup.Text>Company:</InputGroup.Text>
            <SelectCompany
              name="companyId"
              placeholder="--"
              value={form?.companyId || ''}
              isInvalid={validity?.companyId?.valid === false}
              isValid={validity?.companyId?.valid === true}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup className="mt-3">
            <InputGroup.Text>Status:</InputGroup.Text>
            <SelectInt
              name="active"
              value={form?.active}
              onChange={onChange.int}
              isInvalid={validity?.active?.valid === false}
              isValid={validity?.active?.valid === true}
              placeholder="--"
            >
              {STATUSES.default.map(
                (node: any, n: number): JSX.Element => (
                  <option key={n} value={node.id}>
                    {node.displayName}
                  </option>
                )
              )}
            </SelectInt>
          </InputGroup>
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditGroupsModal;

import './styles.scss';

import * as Widgets from './widgets';

import { Button, ButtonGroup } from 'react-bootstrap';
import { Divider, Drawer, Menu } from 'antd';
import React, { createContext, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getClasses, getResultFromState, sleep, stringify, uuid } from '../../utils';

import FormButton from '../FormButton';
import GridLayout from 'react-grid-layout';
import { INDEXED_DB_VERSION } from '../../constants';
import { LoadingBlur } from '../LoadingSpinner';
import Portal from '@/components/Portal';
import Widget from './Widget';
import useIndexedDb from '../../hooks/useIndexedDb';
import useViewport from '../../hooks/useViewport';

const defaultLayout = [];
const Dashboard = ({ id }: { id: string }): JSX.Element => {
  const [state, setState] = useState<any>({ loading: true, showWidgetMenu: false, widgetDetails: undefined });
  const { loading, showWidgetMenu, widgetDetails } = state;
  const [getLayout, saveLayout, clearLayout] = useIndexedDb('dashboards', 'layouts', {}, INDEXED_DB_VERSION);
  const [layout, setLayout] = useState(defaultLayout);
  const [viewport] = useViewport();
  const lastLayoutSaved = useRef(defaultLayout);
  const dashboardContext = useMemo(() => [state, setState], [state]);
  const [renderTimes, setRenderTimes] = useState({});

  const onLayoutChange = async (updatedLayout = layout): Promise<void> => {
    setLayout((current) =>
      updatedLayout.map((u) => {
        const existing = current.find((l) => l.i === u.i);
        return existing ? { ...existing, ...u } : u;
      })
    );
    await sleep(1000);
    setState((current) => ({ ...current, loading: false }));
  };
  const showWidgetDetails = ({ key: type }) =>
    setState((current) => ({ ...current, widgetDetails: Object.values(Widgets).find((w: any): boolean => w?.key === type) }));
  const saveWidget = ({ key: type }) => {
    const selectedWidget = widgetDetails;
    if (!selectedWidget) return console.error(`Widget ${type} not found`);
    const { options, settings = {} } = selectedWidget;
    let result = {
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      x: 0,
      y: 0,
      ...settings,
      ...(options?.settings || {}),
      options,
      type,
      i: settings?.i || uuid(),
    };
    result = options?.onAdd?.(result) || result;
    setLayout((current) =>
      current.map((l) => (l.i === result.i ? result : l)).concat(result.i && !current.some((l) => l.i === result.i) ? [result] : [])
    );
    setState((current) => ({ ...current, widgetDetails: undefined, showWidgetMenu: false }));
    setRenderTimes((current) => ({ ...current, [result.i]: Date.now() }));
  };
  const deleteWidget = (id: string) => setLayout((current) => current.filter((l) => l.i !== id));

  const Content = useMemo(
    () =>
      (layout || []).map(({ type, ...props }) => {
        props.minW = props.minW || 1;
        props.minH = props.minH || 1;
        props.w = (props.w || 1) < props.minW ? props.minW : props.w;
        props.h = (props.h || 1) < props.minH ? props.minH : props.h;
        const { component, ...settings } = Object.values(Widgets).find((w: any): boolean => w?.key === type);
        const Content = component || (() => null);
        return (
          <Widget key={props.i} data-grid={props} widget={settings} onDelete={() => deleteWidget(props.i)}>
            <Content
              type={type}
              widget={settings}
              options={{ ...(settings?.options || {}), ...(props?.options || {}) }}
              key={renderTimes?.[props.i]}
            />
          </Widget>
        );
      }),
    [layout, state, !!widgetDetails]
  );
  const Config = widgetDetails?.config;
  const DetailsDrawer = useMemo(
    () => (
      <Drawer
        title={
          <div className="d-flex">
            <span className="flex-grow-1">{widgetDetails?.label}</span>
            <span>{widgetDetails?.icon || null}</span>
          </div>
        }
        open={!!widgetDetails}
        closeIcon={null}
        footer={
          widgetDetails ? (
            <div className="d-flex">
              <ButtonGroup className="w-100">
                <Button variant="success" onClick={() => saveWidget(widgetDetails)}>
                  {widgetDetails?.settings?.i ? 'Save' : 'Add'}
                </Button>
                <Button variant="outline-primary" onClick={() => setState((current) => ({ ...current, widgetDetails: undefined }))}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          ) : undefined
        }
      >
        {widgetDetails ? widgetDetails?.description || 'No description provided.' : <LoadingBlur loading={true} />}
        {Config && (
          <div className="WidgetSettings mt-2">
            <Divider />
            <Config
              options={widgetDetails?.options || {}}
              setOptions={(val) =>
                setState((current) => ({
                  ...current,
                  widgetDetails: {
                    ...current.widgetDetails,
                    options: getResultFromState(val, current?.widgetDetails?.options || {}),
                  },
                }))
              }
            />
          </div>
        )}
      </Drawer>
    ),
    [widgetDetails, state]
  );

  useLayoutEffect(() => {
    if (!id) return;
    setState((current) => ({ ...current, loading: true }));
    getLayout(id, [])
      .then((res) => {
        lastLayoutSaved.current = res;
        setLayout(res);
        if (!res?.length) setState((current) => ({ ...current, loading: false }));
      })
      .catch(() => setLayout(defaultLayout));
  }, [id]);
  useLayoutEffect(() => {
    const savingLayout = JSON.parse(JSON.stringify(layout));
    if (!id || stringify.compare(lastLayoutSaved.current, savingLayout)) return;
    lastLayoutSaved.current = savingLayout;
    saveLayout(id, savingLayout);
  }, [id, layout]);

  const classes = getClasses('Dashboard', loading ? 'loading' : undefined);

  return (
    <DashboardContext.Provider value={dashboardContext}>
      <div className={classes}>
        <LoadingBlur loading={loading} />
        <GridLayout
          className="DashboardContainer"
          layout={layout}
          compactType={null}
          onLayoutChange={onLayoutChange}
          draggableHandle=".DragHandle"
          containerPadding={[0, 0]}
          preventCollision={true}
          width={viewport.width}
          height={viewport.height}
          rowHeight={Math.ceil(viewport.height / 12)}
          resizeHandles={['s', 'e', 'w', 'n', 'se', 'sw', 'nw']}
        >
          {Content}
        </GridLayout>
        <Drawer
          title="Add Widget"
          open={showWidgetMenu}
          onClose={() => setState((current) => ({ ...current, showWidgetMenu: false }))}
          styles={{ body: { padding: 0 } }}
        >
          <Menu
            mode="inline"
            onClick={showWidgetDetails}
            selectable={false}
            items={Object.values(Widgets).map((w) => ({
              key: w?.key,
              label: w?.label,
              icon: w?.icon,
            }))}
          />
          {showWidgetMenu && DetailsDrawer}
        </Drawer>
        {!showWidgetMenu && DetailsDrawer}
        <Portal container={document.body}>
          <FormButton
            className="AddWidgetButton"
            variant="primary"
            tooltip="Add Widget"
            onClick={() => setState((current) => ({ ...current, showWidgetMenu: true }))}
            icon={<i className="fa fa-plus {font-size:1.5rem;}" />}
          />
        </Portal>
      </div>
    </DashboardContext.Provider>
  );
};

const DashboardContext = createContext({});
export const useDashboard = () => useContext<any>(DashboardContext);

export default Dashboard;

import React, { useEffect } from 'react';

import useModal from '../../hooks/useModal';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [, { show: showResetPasswordModal }] = useModal('ResetPasswordModal', {
    onHide: async () => navigate('/login', { replace: true }),
  });

  useEffect(() => {
    showResetPasswordModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="LoginPage vw-100 vh-100">
      <div className="Login p-3 vh-100 w-25 d-flex flex-column justify-content-center" style={{ margin: '0 auto', minWidth: 320 }}>
        <div
          className="skyvibe-logo"
          style={{ width: '100%', height: '550px', backgroundSize: '90%', backgroundPosition: 'top center' }}
        ></div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { ReactNode, useRef, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';
import { Trip, TripStatusEnum, TripTableFormatEnum } from '@/models/gen/graphql';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import { Badge } from 'react-bootstrap';
import ClientDropdown from '@/components/ClientDropdown';
import DeleteTripsModal from './components/DeleteTripsModal';
import Filters from '@/components/Filters';
import FlightNumberInput from './components/FlightNumber';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import LocationQueryDropdown from '@/components/LocationDropdown';
import { MenuProps } from 'antd';
import RateValuesDropdown from '@/components/RateValuesDropdown';
import { SortedColumn } from './TripsTable';
import { TripTypeDropdownMulti } from '@/components/TripTypeDropdown';
import TripsTableFormatDropdown from '@/components/TripsTableFormatDropdown';
import { Validation } from '@/utils/validations';
import { onEnter } from '@/utils';
import { useAppState } from '@/store/appReducer';
import { useDeleteTripBulk } from '@/api/services/trips/deleteTripBulk';
import useHotkeys from '@/hooks/useHotkeys';
import useModal from '@/hooks/useModal';
import { useNavigate } from 'react-router-dom';

interface TripsFiltersState {
  airportCode: string[];
  payerProviderId: string;
  doLocationId: string[];
  expanded: boolean;
  flightNumber: string;
  from: string;
  loading: boolean;
  puLocationId: string[];
  to: string;
  time: string;
  type: string[];
  servicerIataAirlineCode: string;
  search: string;
  rateAmount: string;
  records: number;
  total: number;
  selected: string[];
  selectedRows: Trip[];
  sortedColumn: SortedColumn;
  format: TripTableFormatEnum;
  onReset: () => void;
}
const initTripsFiltersState: TripsFiltersState = {
  servicerIataAirlineCode: '',
  airportCode: [],
  payerProviderId: '',
  doLocationId: [],
  expanded: false,
  flightNumber: '',
  from: TODAY,
  loading: false,
  puLocationId: [],
  time: undefined,
  to: TODAY_EOD,
  type: [],
  search: '',
  rateAmount: '',
  records: 0,
  total: 0,
  selected: [],
  selectedRows: [],
  sortedColumn: undefined,
  format: TripTableFormatEnum.Current,
  onReset: (): void => {},
};

const TripsFilters = ({
  data = [],
  selected,
  selectedRows,
  onSelected,
  onSubmit,
  onSearch,
  onReset,
  actions,
}: {
  data: Trip[];
  selected: string[];
  selectedRows: Trip[];
  onSelected: (...a: any) => void;
  onSubmit: (...a: any) => Promise<any>;
  onSearch: (val: string) => void;
  onReset: () => void;
  actions: {
    items: MenuProps['items'];
    onClick: (key: string) => void;
  };
}): ReactNode => {
  const [appState] = useAppState();
  const { config: { auth: { allowSearchTripsByRate = false } = {} } = {} } = appState || {};
  const [, { show: showEditTripsModal }] = useModal('EditTrips');
  const [{ loading: loadingDeleteTripBulk }, { fetch: deleteTripBulk }] = useDeleteTripBulk();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteModalValuesRef = useRef(null);

  // Hotkeys
  const flightNumberRef = useRef(null);
  const navigate = useNavigate();
  useHotkeys('trips_filters', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: () => document.getElementById('filters-searchbar') && document.getElementById('filters-searchbar').focus(),
    },
    'shift+ctrl+f': {
      name: 'Focus Flight Number',
      description: 'Focuses the Flight Number field',
      action: () => flightNumberRef.current.focus(),
    },
  });

  const handleDelete = async (values, comment) => {
    try {
      await deleteTripBulk(comment, selected);
      await onSubmit(values);
      onSelected([]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Filters
        name="tripFilters"
        onSubmit={onSubmit}
        onReset={onReset}
        primary={({ values, onChange }): JSX.Element => {
          const { from, to, airportCode, format, flightNumber } = values || initTripsFiltersState;
          return (
            <>
              <FormField
                name="dateRange"
                type="daterange"
                value={[from || initTripsFiltersState?.from, to || initTripsFiltersState?.to]}
                onChange={onChange.dateRange('from', 'to')}
                condensed
              />
              <AirportGroupDropdown
                name="airportCode"
                value={airportCode || initTripsFiltersState?.airportCode}
                onChange={(value: string[]) => {
                  onChange({ target: { name: 'airportCode', value } });
                  return false;
                }}
                options={{ locale: { 'Select...': 'Airport' } }}
              />

              <TripsTableFormatDropdown
                name="format"
                value={format || initTripsFiltersState?.format}
                onChange={(value: TripTableFormatEnum = TripTableFormatEnum.Current): void => {
                  onChange({ target: { name: 'format', value } });
                }}
                options={{
                  showClearButton: false,
                }}
              />
              <FormField
                name="flightNumber"
                placeholder="Flight"
                value={Validation.isNumber(flightNumber) ? flightNumber : initTripsFiltersState?.flightNumber}
                onChange={onChange.int}
                onKeyDown={onEnter((): Promise<void> => onSubmit(values || initTripsFiltersState))}
                options={{ input: { as: FlightNumberInput, withRef: flightNumberRef } }}
                maxLength={4}
                condensed
              />
            </>
          );
        }}
        controls={({ values: { search }, onChange }): JSX.Element => (
          <>
            <FormField
              prepend={<i className="sv sv-magnifier fs-4" />}
              id={'filters-searchbar'}
              name="search"
              value={search || ''}
              onChange={onChange}
              onBlur={(): void => onSearch(search)}
              onKeyDown={onEnter((): void => onSearch(search))}
              placeholder="Search"
              style={{ maxWidth: 300 }}
              condensed
              inline
            />
          </>
        )}
        alternate={({ values }): JSX.Element => (
          <>
            {/* <Link to="/trips/v2">
              <FormButton variant="outline-gray">Beta v2</FormButton>
            </Link> */}
            <FormButton
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="CREATE_TRIP"
              variant="outline-gray"
              onClick={(): void => showEditTripsModal({ creating: true, editing: false, trip: { status: TripStatusEnum.Active } })}
            >
              Create Trip
            </FormButton>
            <FormButton
              icon={<i className="sv sv-download2 {font-size:1.5rem;}" />}
              variant="outline-gray"
              onClick={(): void => navigate('/manifests?type=MANIFEST')}
            >
              Import
            </FormButton>
            <FormButton
              icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
              name="EDIT_TRIP"
              variant="outline-gray"
              onClick={(): void =>
                showEditTripsModal({
                  tripId: data.filter((trip: Trip): boolean => (selected || []).includes(trip.id))[0]?.id,
                  selected: data.filter((trip: Trip): boolean => (selected || []).includes(trip.id)),
                })
              }
              disabled={!Object.values(selected).length}
            >
              {Object.keys(selected).length > 1 ? 'Bulk Edit' : 'Edit'}
              {Object.keys(selected).length > 1 && (
                <Badge className="ms-2" pill bg="success">
                  {Object.keys(selected).length}
                </Badge>
              )}
            </FormButton>
            <FormButton
              name="DELETE_TRIP"
              disabled={
                loadingDeleteTripBulk ||
                !Object.values(selected).length ||
                Object.values(selected).filter((trip: any): boolean => !!trip?.deletedAt).length > 0
              }
              onClick={() => {
                deleteModalValuesRef.current = values;
                setShowDeleteModal(!showDeleteModal);
              }}
              variant="outline-danger"
              tooltip="Delete Trip(s)"
              icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
            ></FormButton>
          </>
        )}
        secondary={({
          values: { airportCode, type, puLocationId, doLocationId, rateAmount, payerProviderId, servicerIataAirlineCode } = {},
          onChange,
        }) => (
          <>
            <ClientDropdown
              name="payerProviderId"
              value={payerProviderId || initTripsFiltersState?.payerProviderId}
              onChange={(value: string) => onChange({ target: { name: 'payerProviderId', value } })}
              options={{ locale: { 'Select...': 'Clients' } }}
            />
            {allowSearchTripsByRate && (
              <RateValuesDropdown
                name="rateAmount"
                placeholder="Rate"
                value={rateAmount}
                onChange={(value: string) => onChange({ target: { name: 'rateAmount', value } })}
              />
            )}
            <AirlineIataDropdown
              name="servicerIataAirlineCode"
              value={servicerIataAirlineCode || initTripsFiltersState?.servicerIataAirlineCode}
              onChange={(value: string) => onChange({ target: { name: 'servicerIataAirlineCode', value } })}
            />
            <TripTypeDropdownMulti name="type" value={type} onChange={(value: string[]) => onChange({ target: { name: 'type', value } })} />
            <LocationQueryDropdown
              name="puLocationId"
              value={puLocationId}
              onChange={(value: string) => onChange({ target: { name: 'puLocationId', value } })}
              airports={airportCode?.length ? airportCode : null}
              options={{
                showAirportPrefix: false,
                locale: { 'Select...': 'Pickups' },
              }}
            />
            <LocationQueryDropdown
              name="doLocationId"
              value={doLocationId}
              onChange={(value: string) => onChange({ target: { name: 'doLocationId', value } })}
              airports={airportCode?.length ? airportCode : null}
              options={{
                showAirportPrefix: false,
                locale: { 'Select...': 'Dropoffs' },
              }}
            />
          </>
        )}
        actions={actions}
        submitOnMount={true}
      />
      <DeleteTripsModal
        onHide={() => setShowDeleteModal(false)}
        formValues={deleteModalValuesRef.current}
        onDelete={handleDelete}
        show={showDeleteModal}
        selected={selectedRows}
      />
    </>
  );
};

export default React.memo(TripsFilters);

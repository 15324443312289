import { ProviderTypeEnum } from '@/models/gen/graphql';
import { SearchClientsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const searchClients = createGraphApiMockResponse(SearchClientsDocument, {
  searchProviders: {
    clientConnection: {
      edges: [
        {
          cursor: '1',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'rewtr',
            displayName: 'wertwert',
            contactPerson: null,
            primaryEmail: null,
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '2',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'TOASTYs',
            displayName: 'TOAST',
            contactPerson: null,
            primaryEmail: null,
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '3',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Banana Leaf Suites',
            displayName: null,
            contactPerson: null,
            primaryEmail: null,
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '4',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Air Tobago',
            displayName: null,
            contactPerson: null,
            primaryEmail: null,
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '5',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Air Jamaica',
            displayName: null,
            contactPerson: null,
            primaryEmail: null,
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '6',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'RMOA-T',
            displayName: 'Training R',
            contactPerson: 'Caroline Naslund,',
            primaryEmail: 'Caroline.Naslund@RadissonBluAmericas.com',
            primaryPhoneNumber: '9528815258',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '7',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Circ Hotel_Sonder USA',
            displayName: 'CIRC',
            contactPerson: 'Heather Alexsa',
            primaryEmail: 'adrienne.hilde@sonder.com',
            primaryPhoneNumber: '5595999999',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '8',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DFW-Marriott',
            displayName: 'DFWA',
            contactPerson: 'Darryl Jett',
            primaryEmail: 'darryljett@remingtonhotels.com',
            primaryPhoneNumber: '9729296560',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '9',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DL Training',
            displayName: 'DLTRA',
            contactPerson: 'Claudia',
            primaryEmail: 'Claudia.AllenKidder@delta.com',
            primaryPhoneNumber: '8017444766',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '10',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'TPA- HILTON DOWNTOWN',
            displayName: 'TPAH',
            contactPerson: 'NA',
            primaryEmail: 'raul.aguilera@hilton.com',
            primaryPhoneNumber: '8132043000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '11',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Sonesta LAX Airport',
            displayName: 'SLAX',
            contactPerson: 'sedge',
            primaryEmail: 'dhfqh@gameil.vom',
            primaryPhoneNumber: '1111111111',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '12',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Crowne Plaza LAX AP',
            displayName: 'CPLAX',
            contactPerson: 'NA',
            primaryEmail: 'aa.hotel-transportation@aa.com',
            primaryPhoneNumber: '9999999999',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '13',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Air Transat',
            displayName: 'TS',
            contactPerson: 'Dominique Eckstein',
            primaryEmail: 'dominique.eckstein@apiglobalsolutions.com',
            primaryPhoneNumber: '5163073000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '14',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'ExpressJet',
            displayName: 'ERJ',
            contactPerson: 'NA',
            primaryEmail: 'reservation.center@apiglobalsolutions.com',
            primaryPhoneNumber: '5163073000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '15',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Air Tran',
            displayName: 'TRS',
            contactPerson: 'PHOUANGMANY THAMMIXAY',
            primaryEmail: 'hotel@airtransat.com',
            primaryPhoneNumber: '5149060330',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '16',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'WestJet Airlines',
            displayName: 'WJ',
            contactPerson: 'Brian Mullen',
            primaryEmail: 'bmullen@tvlinc.com',
            primaryPhoneNumber: '4035397860',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '17',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Endeavor Air',
            displayName: 'EAIR',
            contactPerson: 'weoifj',
            primaryEmail: 'oifj@gmail.com',
            primaryPhoneNumber: '4454666666',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '18',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Southwest Airlines Upgrades',
            displayName: 'DALUG',
            contactPerson: 'Melinda Allen',
            primaryEmail: 'swainvoicenonpo@wnco.com',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '19',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Southwest Airlines New Hires',
            displayName: 'DALNH',
            contactPerson: 'Melinda Allen',
            primaryEmail: 'swainvoicenonpo@wnco.com',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '20',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Southwest Airlines ETOPS',
            displayName: 'DALET',
            contactPerson: 'Melinda Allen',
            primaryEmail: 'swainvoicenonpo@wnco.com',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '21',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Southwest Airlines Recurring',
            displayName: 'DALRC',
            contactPerson: 'Melinda Allen',
            primaryEmail: 'swainvoiceonpo@wnco.com',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '22',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Southwest Airlines FO',
            displayName: 'DALFO',
            contactPerson: 'Melinda Allen',
            primaryEmail: 'swainvoicenonpo@wnco.com',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '23',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Residence Inn BNA',
            displayName: 'RBNA',
            contactPerson: 'Jack',
            primaryEmail: 'jack.smith@marriott.com',
            primaryPhoneNumber: '6158898600',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '24',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Gool Airlines',
            displayName: 'GOOL',
            contactPerson: 'Fabio Silva',
            primaryEmail: 'fspinto@voegol.com.br',
            primaryPhoneNumber: '5511509878',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '25',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Doubletree Arlington DFW South',
            displayName: 'DTAS',
            contactPerson: 'Pavel Filippov',
            primaryEmail: 'p.filippov@esperantodev.com',
            primaryPhoneNumber: '8176407712',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '26',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LATAM',
            displayName: 'LAT',
            contactPerson: 'Javiera Lopez',
            primaryEmail: 'javiera.lopezh@latam.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '27',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Kimpton Overland Atl Airport',
            displayName: 'KIMP',
            contactPerson: 'Kerry Ringham',
            primaryEmail: 'KRingham@kimptonoverlandhotel.com',
            primaryPhoneNumber: '4704663300',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '28',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'BEHIND THE WINGS',
            displayName: 'DL-WINGS',
            contactPerson: 'guillermo Coplan',
            primaryEmail: 'guillermo.coplan@delta.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '29',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DL NYC shuttle',
            displayName: 'DL-NYC-S',
            contactPerson: 'WES MASON',
            primaryEmail: 'wes.mason@delta.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '30',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DL-CQ TRaning-AIrport.',
            displayName: 'DL-CQ-ATL',
            contactPerson: 'Jason',
            primaryEmail: 'Jason.Ward@delta.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '31',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DELTA INSTRUCTORS',
            displayName: 'DLINST',
            contactPerson: 'Jason Ward',
            primaryEmail: 'Jason.Ward@delta.com',
            primaryPhoneNumber: '4049151218',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '32',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Aloft Dallas Airport',
            displayName: 'Aloft',
            contactPerson: 'Monte Greene',
            primaryEmail: 'monte.greene@aloftdallaslovefield.com',
            primaryPhoneNumber: '4696870612',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '33',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Polar',
            displayName: 'PO',
            contactPerson: 'Stephanie Grant',
            primaryEmail: 'Stephanie.Grant@Polaraircargo.com',
            primaryPhoneNumber: '9142700078',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '34',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Atlas Air',
            displayName: 'ATLAs',
            contactPerson: 'to be determind',
            primaryEmail: 'tobedetermind@idfjidfj.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '35',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Cathay Pacific',
            displayName: 'CP',
            contactPerson: 'Leo Chan',
            primaryEmail: 'Leo.Chan@apiglobalsolutions.com',
            primaryPhoneNumber: '5163073000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '36',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Hyatt Place PDX',
            displayName: 'HYPDX',
            contactPerson: 'Tyler Terrell',
            primaryEmail: 'tyler.terrell@aimhosp.com',
            primaryPhoneNumber: '5032882808',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '37',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Embassy Suites Tampa Westshore',
            displayName: 'ESTBY',
            contactPerson: 'Robin',
            primaryEmail: 'robinshows@hilton.com',
            primaryPhoneNumber: '8136769991',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '38',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DFW Marriott Westlake',
            displayName: 'DFWLA',
            contactPerson: 'Matthew McDaniel',
            primaryEmail: 'Matthew.Mcdaniel2@marriott.com',
            primaryPhoneNumber: '8174305087',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '39',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'MartinAir',
            displayName: 'MP',
            contactPerson: 'Mike',
            primaryEmail: 'skfhsfhj@martin.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '40',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'WestShore Grand',
            displayName: 'WSTG',
            contactPerson: 'David Rowland,',
            primaryEmail: 'david.rowland@westshoregrand.com',
            primaryPhoneNumber: '8132864039',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '41',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'PHL SHERATON SUITES',
            displayName: 'SHPHL',
            contactPerson: 'JACKIE KING',
            primaryEmail: 'jking@sheratonphiladelphiaairport.com',
            primaryPhoneNumber: '2153656600',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '42',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'DoubleTree Hilton Rocky Point.',
            displayName: 'DTHRP',
            contactPerson: 'Bryan Clancy',
            primaryEmail: 'bryan.clancy@hilton.com',
            primaryPhoneNumber: '8138888800',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '43',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Residence Inn Portland Airport a',
            displayName: 'RPDX',
            contactPerson: 'Mario A. Moreno',
            primaryEmail: 'marioa.moreno@aimhosp.com',
            primaryPhoneNumber: '5032841800',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '44',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Southwest Airlines DALWN',
            displayName: 'DALWN',
            contactPerson: 'aofhdo[ifH',
            primaryEmail: 'fiebf@dfh.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '45',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'US Airways',
            displayName: 'US',
            contactPerson: 'Denise Raddle',
            primaryEmail: 'Us.invoices@aa.com',
            primaryPhoneNumber: '8179676818',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '46',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Marriott City Center',
            displayName: 'MSPCC',
            contactPerson: 'Kurt Boerner',
            primaryEmail: 'James.mason@marriott.com',
            primaryPhoneNumber: '6123494053',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '47',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Icelandair',
            displayName: 'FI',
            contactPerson: 'ester Santana API',
            primaryEmail: 'ester@api.com',
            primaryPhoneNumber: '1233344444',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '48',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Hyatt Place',
            displayName: 'HP',
            contactPerson: 'Sig Brunnacker',
            primaryEmail: 'sig.brunnacker@hyatt.com',
            primaryPhoneNumber: '9549220271',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '49',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Alaska Airbus',
            displayName: 'ASA',
            contactPerson: 'juan',
            primaryEmail: 'sdjfohw@email.com',
            primaryPhoneNumber: '9549339299',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '50',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LAN Argentina',
            displayName: 'LANA',
            contactPerson: 'Paola Diaz Toro',
            primaryEmail: 'paola.diaz@latam.com',
            primaryPhoneNumber: '3033033030',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '51',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'YVE Hotel Miami',
            displayName: 'YVE',
            contactPerson: 'Robert Festinger',
            primaryEmail: 'rfestinger@destinationhotels.com',
            primaryPhoneNumber: '7864051674',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '52',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Air France',
            displayName: 'AF',
            contactPerson: 'Luz Ferrante',
            primaryEmail: 'AF.Passage.US@klm.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '53',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LAN CHILE',
            displayName: 'LANC',
            contactPerson: 'Paola',
            primaryEmail: 'paola.diaz@latam.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '54',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LAN Colombia',
            displayName: 'LANCO',
            contactPerson: 'Paola Diaz',
            primaryEmail: 'paola.diaz@latam.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '55',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LAN Ecuador',
            displayName: 'LANE',
            contactPerson: 'Paola Diaz',
            primaryEmail: 'paola.diaz@latam.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '56',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LAN Peru',
            displayName: 'LANP',
            contactPerson: 'HES #',
            primaryEmail: 'paola.diaz@latam.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '57',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Hyatt Place 17st',
            displayName: 'HP17',
            contactPerson: 'jhon doe',
            primaryEmail: 'sdagh@gamil.com',
            primaryPhoneNumber: '9547637876',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '58',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'WOW Airlines',
            displayName: 'WOW',
            contactPerson: 'juan',
            primaryEmail: 'sdfluhe@dfh.com',
            primaryPhoneNumber: '2105723572',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '59',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Aer Lingus',
            displayName: 'EI',
            contactPerson: 'Carlos',
            primaryEmail: 'asddeofh@edj.com',
            primaryPhoneNumber: '0099887755',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '60',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Sunrise Airways S.A.',
            displayName: 'SA',
            contactPerson: 'Sandy Laborde',
            primaryEmail: 'sandy.laborde@sunriseairways.net',
            primaryPhoneNumber: '4079537972',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '61',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Spring Hill Suites BNA Airport',
            displayName: 'SHS',
            contactPerson: 'Penny Burns',
            primaryEmail: 'Penny.Burns@marriott.com',
            primaryPhoneNumber: '6158846111',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '62',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'LAN Brasil',
            displayName: 'LANB',
            contactPerson: 'Paola Diaz',
            primaryEmail: 'paola.diaz@latam.com',
            primaryPhoneNumber: '0000000000',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '63',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'British Airways',
            displayName: 'BA',
            contactPerson: null,
            primaryEmail: null,
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '64',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'United Parcel Service',
            displayName: 'UPS',
            contactPerson: 'Karen Bush',
            primaryEmail: 'info@ups.com',
            primaryPhoneNumber: '0005551234',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '65',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Alaska Airlines',
            displayName: 'AS',
            contactPerson: null,
            primaryEmail: 'alaska@info.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '66',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Air Canada',
            displayName: 'AC',
            contactPerson: 'aubrey jones',
            primaryEmail: 'canada@info.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '67',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Frontier Airlines',
            displayName: 'F9',
            contactPerson: null,
            primaryEmail: 'frontier@info.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '68',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Jazz Airlines',
            displayName: 'QK',
            contactPerson: 'vlad',
            primaryEmail: 'jazz@info.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '69',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Lago Mar Hotel',
            displayName: 'Lago Mar',
            contactPerson: null,
            primaryEmail: 'info@test.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '70',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Skywest Airlines',
            displayName: 'OO',
            contactPerson: 'reshi yamamoto',
            primaryEmail: 'info@skw.com',
            primaryPhoneNumber: '9543216549',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '71',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'United Airlines',
            displayName: 'UA',
            contactPerson: 'john wyclef',
            primaryEmail: 'info@ual.com',
            primaryPhoneNumber: '9549876543',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '72',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Skyplan Training',
            displayName: 'SPT',
            contactPerson: '',
            primaryEmail: '',
            primaryPhoneNumber: '',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '73',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'American Airlines Group',
            displayName: 'American Airlines',
            contactPerson: 'lisa bell',
            primaryEmail: 'info@aa.com',
            primaryPhoneNumber: '3055555678',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '74',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Delta Airlines',
            displayName: 'Delta',
            contactPerson: 'Mary Huggins',
            primaryEmail: 'info@delta.com',
            primaryPhoneNumber: '6122667105',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '75',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'South West Airlines',
            displayName: 'Southwest Airlines',
            contactPerson: 'Melinda Allen',
            primaryEmail: 'swainvoicenonpo@wnco.com',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '76',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Unknown',
            displayName: null,
            contactPerson: '',
            primaryEmail: '',
            primaryPhoneNumber: '',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '77',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Skyplan',
            displayName: 'SH',
            contactPerson: '',
            primaryEmail: '',
            primaryPhoneNumber: '',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '78',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Spirit Airlines',
            displayName: 'Spirit',
            contactPerson: null,
            primaryEmail: 'spirit@info.com',
            primaryPhoneNumber: '',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '79',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'American Airlines Training',
            displayName: 'AA Training',
            contactPerson: null,
            primaryEmail: 'info@aa.com',
            primaryPhoneNumber: null,
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '80',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Private',
            displayName: 'Prvt',
            contactPerson: 'Melinda Allen',
            primaryEmail: '',
            primaryPhoneNumber: '4696030735',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '81',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Sonesta Select',
            displayName: 'SON',
            contactPerson: 'aubrey jones',
            primaryEmail: 'canada@info.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '82',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'SONESTA NASHVILLE AIRPORT',
            displayName: 'SNO',
            contactPerson: 'aubrey jones',
            primaryEmail: 'canada@info.com',
            primaryPhoneNumber: '9545555679',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '83',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'KLM Royal Dutch Airlines',
            displayName: 'KLM',
            contactPerson: 'josh smith',
            primaryEmail: 'info@delta.com',
            primaryPhoneNumber: '9545551234',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '84',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'All Nippon Airways',
            displayName: 'Nippon',
            contactPerson: 'josh smith',
            primaryEmail: 'info@delta.com',
            primaryPhoneNumber: '9545551234',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '85',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Alaska Training',
            displayName: 'AT',
            contactPerson: 'josh smith',
            primaryEmail: 'info@delta.com',
            primaryPhoneNumber: '9545551234',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '86',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'Radisson MOA',
            displayName: 'MOA',
            contactPerson: 'josh smith',
            primaryEmail: 'info@delta.com',
            primaryPhoneNumber: '9545551234',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
        {
          cursor: '87',
          node: {
            id: '25ec0365-c094-4869-af25-c075306b72ba',
            name: 'JetBlue Airways',
            displayName: 'B6',
            contactPerson: 'Jessica Lapadula',
            primaryEmail: '',
            primaryPhoneNumber: '5168091886',
            type: ProviderTypeEnum.Client,
            __typename: 'Provider',
          },
          __typename: 'ProviderEdge',
        },
      ],
      pageInfo: {
        endCursor: '87',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '0',
        __typename: 'PageInfo',
      },
      totalCount: 87,
      __typename: 'ProviderConnection',
    },
    __typename: 'SearchProviderResponse',
  },
});

import { Col, Modal, Row } from 'react-bootstrap';

import { LoadingBlur } from '../LoadingSpinner';
import { Location } from 'models/gen/graphql';
import LocationCard from '../LocationCard';
import React from 'react';

type ResolveDuplicateModalProps = {
  show: boolean;
  onHide: () => void;
  duplicates: Location[];
  loading: boolean;
  onSubmit: (data: Location) => void;
};

const ResolveDuplicateModal = ({
  show,
  onHide,
  duplicates = [],
  loading: forceLoading,
  onSubmit,
}: ResolveDuplicateModalProps): JSX.Element => {
  return (
    <Modal className={`ResolveDuplicatesModal${forceLoading ? ' loading' : ''}`} show={show} onHide={onHide} centered size="sm">
      <Modal.Body>
        <Modal.Title>Resolve Duplicates</Modal.Title>
        <p className="text-muted"> We found similar location(s) in the database.</p>
        <LoadingBlur loading={!!forceLoading} />
        {duplicates.map(
          (loc: Location, l: number): JSX.Element => (
            <Row className="mt-2" key={l}>
              <Col className="mb-4" key={l}>
                <LocationCard
                  location={loc}
                  onClick={onSubmit}
                  index={l}
                  options={{
                    button: {
                      text: 'Use This Location',
                      variant: 'primary',
                    },
                  }}
                />
              </Col>
            </Row>
          )
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ResolveDuplicateModal;

import {
  AirportWithVehicleConnectionInput,
  AirportWithVehicleSearch,
  SortDirectionEnum,
  Vehicle,
  VehicleConnection,
} from '../../../models/gen/graphql';
import {
  ConnectionDetails,
  QueryInputType,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails,
  queryInput,
} from '../../../utils/custom';
import { SearchVehicleWithAirportDocument, SearchVehiclesTableDocument } from '../../queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchVehicleGraphApiResponse = GraphApiResponse<typeof SearchVehicleWithAirportDocument>;
export type SearchVehicleOptions = { pageSize?: number; page?: number; merge?: true };

export const SEARCH_VEHICLE_WITH_AIRPORT_PAGE_SIZE = 1000;
const title = 'Search Vehicles';

export const searchVehicleResponseSelector = (res: SearchVehicleGraphApiResponse): ConnectionDetails<Vehicle> =>
  convertConnectionToDetails(res?.searchVehicles?.vehicleWithAirportConnection as VehicleConnection);
export const searchVehiclesTableResponseSelector = (res: SearchVehicleGraphApiResponse): ConnectionDetails<Vehicle> =>
  convertConnectionToDetails(res?.searchVehicles?.vehicleWithAirportConnection as VehicleConnection);

// services
const [runSearchVehicleWithAirport, runRefetchSearchVehicleWithAirport] = graphApi(SearchVehicleWithAirportDocument, {
  onError: (res: SearchVehicleGraphApiResponse): void => handleError(res, { notification: { title } }),
});
const [runSearchVehiclesTable, runRefetchSearchVehiclesTable] = graphApi(SearchVehiclesTableDocument, {
  onError: (res: SearchVehicleGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchVehicleWithAirport = async (
  query: AirportWithVehicleSearch[],
  options: SearchVehicleOptions = {}
): Promise<ConnectionDetails<Vehicle>> => {
  const { pageSize = SEARCH_VEHICLE_WITH_AIRPORT_PAGE_SIZE, page = 0, merge = false } = options;

  const input: AirportWithVehicleConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchVehicles.vehicleWithAirportConnection' : undefined };

  const res = await runRefetchSearchVehicleWithAirport({ input }, graphApiOptions);

  return searchVehicleResponseSelector(res);
};

const searchVehiclesTable = async (
  query: AirportWithVehicleSearch[],
  options: SearchVehicleOptions = {}
): Promise<ConnectionDetails<Vehicle>> => {
  const { pageSize = SEARCH_VEHICLE_WITH_AIRPORT_PAGE_SIZE, page = 0, merge = false } = options;

  const input: AirportWithVehicleConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchVehicles.vehicleWithAirportConnection' : undefined };

  const res = await runRefetchSearchVehiclesTable({ input }, graphApiOptions);

  return searchVehiclesTableResponseSelector(res);
};

export const getVehicleOptions = async (airportCode: string): Promise<ConnectionDetails<Vehicle>> => {
  if (!airportCode) throw new Error('Failed to Search Vehicles: Airport code is required.');
  const query: AirportWithVehicleSearch = {
    airportCode: queryInput(airportCode),
    active: queryInput(1),
    trackingId: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc),
  };
  return await searchVehicleWithAirport([query], { pageSize: null });
};

// hooks
export const useSearchVehiclesTable = createGraphApiHook(searchVehiclesTable, {
  merge: mergeConnectionDetails,
});
export const useGetVehicleOptions = createGraphApiHook(getVehicleOptions);

export default searchVehicleWithAirport;

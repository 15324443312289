import { Button, Col, Row } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';

import { ContractedRatesFooter } from '../../pages/ContractedRates';
import ContractedRatesTable from '../ContractedRatesTable';
import EditModal from '../../components/EditModal/new';
import FormField from '../FormField';
import { updateRateOnTrips } from '../../api/services/rates/updateRateOnTrips';
import { useGetCorrectRatesForTrips } from '../../api/services/rates/getCorrectRatesForTrips';
import useModal from '../../hooks/useModal';
import { useVirtualTable } from '../VirtualTable';

const RateReportModal = (): JSX.Element => {
  const [state, setState] = useModal('RateReport', {
    onSubmit: async (): Promise<void> => {
      const payload = selectedRows.map((row) => ({ tripId: row?.id, rateHistoryId: row?.newRate?.id }));
      if (!payload?.length) return;
      await updateRateOnTrips(payload);
      onHide();
    },
  });
  const { show = false, search = '', tripIds = [], selected = [] } = state;
  const { onChange, onHide, onSubmit } = setState;
  const [{ data: rows = [], loading }, { fetch: getCorrectRatesForTrips }] = useGetCorrectRatesForTrips();
  const lastTripIds = useRef(null);

  const { selectedRows, onSelect, filteredRows } = useVirtualTable(setState, {
    selected,
    rows,
    search,
  });

  useEffect((): void => {
    if (!show || !tripIds?.length || JSON.stringify(tripIds) === JSON.stringify(lastTripIds.current)) return;
    lastTripIds.current = tripIds;
    getCorrectRatesForTrips(tripIds);
  }, [getCorrectRatesForTrips, show, tripIds]);

  return (
    <EditModal
      name="rateReport"
      show={show}
      onSubmit={onSubmit}
      onHide={onHide}
      size="xl"
      title="Rate Report"
      loading={loading}
      options={{
        Footer: (
          <Row className="justify-content-end pe-3 pt-2">
            <Col sm={2}>
              <Button variant="secondary" className="w-100" onClick={onHide}>
                CANCEL
              </Button>
            </Col>
            <Col sm={2}>
              <Button name="SUBMIT" variant="primary" className="w-100" onClick={onSubmit} disabled={!selected?.length}>
                Done
              </Button>
            </Col>
          </Row>
        ),
      }}
    >
      <FormField name="search" label="Search:" value={search || ''} onChange={onChange} options={{ group: { className: 'mt-0' } }} />
      <ContractedRatesTable data={filteredRows} selected={selected} onSelect={onSelect} loading={false} style={{ height: '60vh' }} />
      <ContractedRatesFooter rows={filteredRows} selected={selected} />
    </EditModal>
  );
};

export default RateReportModal;

import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import { Datetime, Validation, diffKey, properCase } from '../../utils';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Stop, TripKindEnum, TripStatusEnum, TripTypeEnum } from '../../models/gen/graphql';
import { TripStop, TripStopTimeSchedule } from './TripStop';

import DateSelector from 'components/DateSelector';
import DragAndDrop from 'components/DragAndDrop';
import EditModal from '../../components/EditModal/new';
import EditTripsFooter from './EditTripsFooter';
import FormField from 'components/FormField';
import PreviewFile from '../../components/PreviewFile';
import SelectAirlineIata from '../SelectAirlineIata';
import SelectAirport from 'components/SelectAirport';
import SelectClient from 'components/SelectClient';
import SelectFlag from 'components/SelectFlag';
import SelectKind from 'components/SelectKind';
import SelectStatus from 'components/SelectStatus';
import SelectType from 'components/SelectType';
import { getTripForModal } from '@/api/services/trips/searchTrips';
import { useAppState } from 'store/appReducer';
import useModal from '../../hooks/useModal';
import { useNavigate } from 'react-router-dom';
import useTrips from '../../hooks/useTrips';
import useUuid from '../../hooks/useUuid';

// TODO: Move validation logic to createTrips query validateObj.test.
const keys = [
  'airportCode',
  'payerProviderId',
  'providerId',
  'servicerIataAirlineCode',
  'puLocationId',
  'doLocationId',
  'scheduled',
  'scheduledDays',
  'flightNumber',
  'attendants',
  'pilots',
  'type',
  'kind',
  'status',
  'crewId',
  'firstName',
  'lastName',
  'stops',
];
const getValidityType = (isValid: undefined | boolean, diff: boolean = false): Validation.ValidityType => {
  if (isValid === undefined) return Validation.ValidityType.INVARIANT;
  if (diff) return isValid ? Validation.ValidityType.VALID_WARNING : Validation.ValidityType.INVALID_WARNING;
  return isValid ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID;
};
const validator = (trip: any, upsProviderId: string, trips: any[] = []): any => {
  const validateKey = (key: string, val: any): any => {
    const isDiff = diffKey(key, [trip, ...Object.values(trips)]).length > 1;
    let isValid = undefined;
    const message = '';
    switch (key) {
      case 'airportCode': {
        isValid = !!val;
        break;
      }
      case 'payerProviderId': {
        isValid = !!val;
        break;
      }
      case 'providerId': {
        isValid = !!val;
        break;
      }
      case 'servicerIataAirlineCode': {
        isValid = !!val;
        break;
      }
      case 'puLocationId': {
        isValid = !!val;
        break;
      }
      case 'doLocationId': {
        isValid = !!val;
        break;
      }
      case 'scheduled': {
        isValid = !!trip?.scheduledDays?.length || !!new Datetime(val || '').asDayjs().isValid();
        break;
      }
      case 'scheduledDays': {
        isValid = Array.isArray(val) && val?.length > 0;
        break;
      }
      case 'flightNumber': {
        isValid = val !== '' && Number(val) >= 0 && Number(val) <= 9999;
        break;
      }
      case 'type': {
        isValid = Object.values(TripTypeEnum).includes(val);
        break;
      }
      case 'kind': {
        isValid = Object.values(TripKindEnum).includes(val);
        break;
      }
      case 'status': {
        isValid = Object.values(TripStatusEnum).includes(val);
        break;
      }
      case 'crewId': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : Validation.isNumber(parseInt(val));
        break;
      }
      case 'firstName': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : !!val && Validation.isAlphanumeric(val);
        break;
      }
      case 'lastName': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : !!val && Validation.isAlphanumeric(val);
        break;
      }
      case 'stops': {
        isValid =
          !val?.length || !val.map((stop: any): boolean => !!Validation.isValidUUID(stop?.locationId) && !!stop?.scheduled).includes(false);
        break;
      }
    }
    return { valid: getValidityType(isValid, isDiff), message };
  };
  const result = {};
  keys.forEach((key: string): any => (result[key] = validateKey(key, trip?.[key])));
  return result;
};

// TODO: Type this hook out properly.
const useEditTripsModal = (options?: any): any => useModal('EditTrips', options);

const EditTripsModal = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [{ config: { upsProviderId = '' } = {} } = {}] = useAppState();
  const [, { handleCreateTripBulk, handleUpdateTripBulk }] = useTrips();
  const [state, setState] = useEditTripsModal();
  const navigate = useNavigate();
  const { show = false, selected = [], preview = '', trip = {}, original = {}, tripId } = state;
  const { onChange, onSubmit, onHide } = setState;
  const handleSubmit = async (): Promise<void> => {
    const { persist = false } = state;
    const { airportFilter: _airportFilter, ...trip } = state?.trip || {};
    trip.pilots = trip?.pilots || 0;
    trip.attendants = trip?.attendants || 0;
    if (trip?.id) {
      await handleUpdateTripBulk(trip, selected?.length > 1 ? selected || [] : [original]);
    } else {
      await handleCreateTripBulk(trip);
    }
    onSubmit(state);
    if (!persist) onHide();
  };
  const onSaveAndCopy = async (): Promise<void> => {
    await handleCreateTripBulk(trip, selected?.length > 1 ? selected || [] : [original]);
    onSubmit(state);
  };

  const mode = !trip?.id ? 'create' : 'edit';
  const selectedCount = Object.values(selected).length || 0;
  const uuid = useUuid();

  const onAddStop = (): any => {
    const currentDatetime = new Datetime();
    setState((current: any): any => ({
      ...current,
      trip: {
        ...(current?.trip || {}),
        stops: [
          ...(current?.trip?.stops || []),
          {
            id: new Date().getTime(),
            airportFilter: current?.trip?.airportCode,
            scheduled: current?.trip?.scheduled
              ? new Datetime(current?.trip?.scheduled).setTime(currentDatetime.fullTime).toString()
              : currentDatetime.toString(),
          },
        ],
      },
    }));
  };
  const onBlurFlightNumber = (): void => {
    setState((current: any): any => ({
      ...current,
      trip: { ...(current?.trip || {}), flightNumber: `${current?.trip?.flightNumber || ''}`.replace(/^[0]+/, '').padStart(4, '0') },
    }));
  };

  const validity = useMemo(
    (): any => {
      if (mode === 'create' && !keys.includes('scheduledDays')) keys.push('scheduledDays');
      if (mode === 'edit' && keys.includes('scheduledDays')) keys.splice(keys.indexOf('scheduledDays'), 1);
      const result = (validator || ((): any => ({})))(trip, upsProviderId, selected);
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validator, trip, upsProviderId]
  );
  const isValid = Validation.isValid(validity);

  const TripStopComponent = (props: any): JSX.Element => (
    <TripStop
      onDelete={(): any =>
        setState((current: any): any => ({
          ...current,
          trip: {
            ...(current?.trip || {}),
            stops: (current?.trip?.stops || []).filter((_stop: any, i: number): boolean => i !== props?.index),
          },
        }))
      }
      onChange={(event: any): void => {
        const { name, value } = event?.target || {};
        switch (name.split('.').pop()) {
          case 'locationId':
            return value !== '' ? onChange(event) : undefined;
          case 'airportFilter':
            onChange({ target: { name: `trip.stops.${props?.index}.locationId`, value: '' } });
          default:
            return onChange(event);
        }
      }}
      valid={{
        locationId: Validation.isValidUUID(props?.data?.locationId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
      }}
      options={{
        filterLocationByAirport: true,
      }}
      {...props}
    />
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MemoizedTripStop = useMemo((): ((props: any) => JSX.Element) => TripStopComponent, [trip?.airportCode, trip?.stops]);
  const gotoManifestLink = useMemo((): ReactNode => {
    if (!trip?.manifestImportId) return undefined;
    return (
      <Button
        variant="icon"
        onClick={(): void => {
          onHide();
          navigate(`/manifests/${trip?.manifestImportId}`);
        }}
      >
        Goto Manifest
      </Button>
    );
  }, [onHide, trip?.manifestImportId]);

  const getTripData = async (): Promise<void> => {
    setLoading(true);
    try {
      const trip = await getTripForModal(tripId);
      setState((current: any): any => ({ ...current, trip, original: trip }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!show || !tripId) return;
    getTripData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, tripId]);

  return (
    <EditModal
      show={show}
      title={`${properCase(mode)} Trip`}
      subtitle={gotoManifestLink}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onSubmit={false}
      options={{
        Footer: (
          <>
            <EditTripsFooter
              onAddStop={onAddStop}
              data={trip}
              onSaveCopy={mode === 'create' ? onSaveAndCopy : undefined}
              onSubmit={handleSubmit}
              valid={isValid && (trip?.pilots > 0 || trip?.attendants > 0)}
              onHide={onHide}
              showActivity={selectedCount === 1}
            />
            {!!preview && <PreviewFile className="{height:300px;margin-bottom:1rem;}" src={preview} />}
          </>
        ),
      }}
      loading={loading}
      name="editTrips"
    >
      <Row>
        <Col xs={12} md={3}>
          <FormField
            label="Type:"
            placeholder="Select Type"
            name="trip.type"
            value={trip?.type || ''}
            onChange={onChange}
            valid={validity?.type?.valid}
            searchable
            options={{
              input: {
                as: SelectType,
              },
            }}
          />
          <FormField
            label="Client:"
            placeholder="Select Client"
            name="trip.payerProviderId"
            value={trip?.payerProviderId || ''}
            onChange={(event) => {
              const value = event?.target?.value;
              setState((current) => {
                return { ...(current || {}), trip: { ...(current?.trip || {}), payerProviderId: value, providerId: value } };
              });
            }}
            valid={validity?.payerProviderId?.valid}
            searchable
            options={{
              input: {
                as: SelectClient,
              },
            }}
          />
          <FormField
            label="Airline:"
            placeholder="Select Airline"
            name="trip.servicerIataAirlineCode"
            value={trip?.servicerIataAirlineCode || ''}
            onChange={onChange}
            valid={validity?.servicerIataAirlineCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirlineIata,
              },
            }}
          />
          <FormField
            label="Airport:"
            placeholder="Select Airport"
            name="trip.airportCode"
            value={trip?.airportCode || ''}
            onChange={(event: any): void => {
              const { value } = event?.target || {};
              if (!value) return;
              setState((current: any): any => ({
                ...(current || {}),
                trip: {
                  ...(current?.trip || {}),
                  airportCode: value,
                  puLocationId: '',
                  doLocationId: '',
                  doAirportFilter: value,
                  stops: (current?.trip?.stops || []).map((stop: any): any => ({
                    ...stop,
                    airportFilter: value,
                    locationId: '',
                  })),
                },
              }));
            }}
            valid={validity?.airportCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirport,
              },
            }}
          />
          <FormField
            label={'Arrival/\nDeparture:'}
            placeholder="Select Kind"
            name="trip.kind"
            value={trip?.kind || ''}
            onChange={(event): void => {
              const { value } = event?.target || {};
              setState((current: any): any => {
                const { puLocationId = '', doLocationId = '' } = current?.trip || {};
                return {
                  ...(current || {}),
                  trip: {
                    ...(current?.trip || {}),
                    kind: value,
                    puLocationId: doLocationId,
                    doLocationId: puLocationId,
                  },
                };
              });
            }}
            valid={validity?.kind?.valid}
            searchable
            options={{
              input: {
                as: SelectKind,
              },
            }}
          />
          <FormField label={'Loop Name:'} name="trip.loopName" value={trip?.loopName || ''} onChange={onChange} />
        </Col>
        <Col xs={12} md={4}>
          <FormField
            label="Flight Number:"
            name="trip.flightNumber"
            value={!Validation.isNil(trip?.flightNumber) ? `${trip?.flightNumber}`.padStart(4, '0') : ''}
            onChange={(event: any): void => {
              event.target.value = event.target.value.replace(/^[0]+/, '').padStart(4, '0').substring(0, 4) || '';
              onChange(event);
            }}
            onBlur={onBlurFlightNumber}
            valid={validity?.flightNumber?.valid}
          />
          <div className="d-flex gap-3 {margin:-1.2rem|0;}">
            <FormField
              label="PLT:"
              type="number"
              name="trip.pilots"
              value={trip?.pilots || 0}
              onChange={onChange}
              valid={trip?.pilots > 0 || trip?.attendants > 0}
              min={0}
              max={255}
            />
            <FormField
              label="F/A:"
              type="number"
              name="trip.attendants"
              value={trip?.attendants || 0}
              onChange={onChange}
              valid={trip?.attendants > 0 || trip?.pilots > 0}
              min={0}
              max={255}
            />
          </div>
          <FormField
            type="number"
            label="Crew ID:"
            name="trip.crewId"
            value={trip?.crewId || ''}
            onChange={onChange.int}
            valid={validity?.crewId?.valid}
            options={{
              input: {
                className: 'CrewId',
              },
            }}
          />
          <FormField
            label="First Name:"
            name="trip.firstName"
            value={trip?.firstName || ''}
            onChange={onChange}
            valid={validity?.firstName?.valid}
          />

          <FormField
            label="Last Name:"
            name="trip.lastName"
            value={trip?.lastName || ''}
            onChange={onChange}
            valid={validity?.lastName?.valid}
          />
          <div className="d-flex gap-3 {margin:-1.2rem|0;}">
            <FormField
              label="Include in\nFuture Manifests?"
              id={`future_${uuid}`}
              type="switch"
              name="trip.fromManifest"
              checked={trip?.fromManifest !== undefined ? !!trip?.fromManifest : true}
              onChange={onChange.toggleInt}
            />
            <FormField label="Split:" type="number" name="trip.split" value={trip?.split || ''} onChange={onChange} />
          </div>
        </Col>
        {!preview && (
          <Col xs={12} md={5}>
            <div className="d-flex gap-3 {margin:0|0|-1.2rem|0;}">
              <FormField
                label={'Flags:'}
                placeholder="No Flags"
                name="trip.flags"
                value={(trip?.flags || []).map((node) => node.id || node)}
                onChange={onChange}
                disabled={selectedCount > 1}
                searchable
                options={{
                  input: { as: SelectFlag, className: 'Flags' },
                }}
              />
              <FormField
                label={'Status:'}
                placeholder="Select Status"
                name="trip.status"
                value={trip?.status || ''}
                onChange={(event: any): void => (event?.target?.value ? onChange(event) : undefined)}
                valid={validity?.status?.valid}
                searchable
                options={{
                  input: { as: SelectStatus, className: 'Status' },
                }}
              />
            </div>
            <FormField
              type="textarea"
              label="Comments:"
              name="trip.comments"
              value={trip?.comments || ''}
              onChange={onChange}
              options={{ input: { className: 'Comments {h:11.6rem;resize:none;}' } }}
            />
            {mode === 'edit' && (
              <div className="d-flex gap-3 {margin:-1.2rem|0;}">
                <FormField
                  label="Increment?"
                  id={`increment_${uuid}`}
                  type="switch"
                  name="trip.increment"
                  checked={!!trip?.increment}
                  onChange={onChange.toggle}
                />
                <FormField
                  label="Days:"
                  type="number"
                  name="trip.days"
                  value={trip?.days || 0}
                  disabled={!trip?.increment}
                  onChange={onChange.int}
                />
              </div>
            )}
            {mode === 'edit' && (
              <FormField
                name="trip.scheduled"
                label="P/U Date:"
                type="date"
                value={trip?.scheduled || ''}
                onChange={(event: any): void => {
                  const { value } = event?.target || {};
                  setState((current: any): any => {
                    const currentScheduledTime = current?.trip?.scheduled?.split('T')?.[1];
                    const newScheduledTime = currentScheduledTime ? value.concat(' ', currentScheduledTime) : value;
                    const result = {
                      ...current,
                      trip: { ...(current?.trip || {}), scheduled: new Datetime(newScheduledTime).toString() },
                    };
                    if (current?.trip?.stops?.length) {
                      const originalStops = [...(current?.trip?.stops || [])];
                      const stops = originalStops.map(
                        (stop: Stop): Stop => ({
                          ...stop,
                          scheduled: value ? new Datetime(stop?.scheduled || undefined).setDate(value).toString() : null,
                        })
                      );
                      result.trip.stops = stops;
                    }
                    return result;
                  });
                }}
                valid={validity?.scheduled?.valid}
                disabled={!!trip?.increment}
                feedback={trip?.increment ? 'Disable increment to edit scheduled date.' : ''}
              />
            )}
            {mode === 'create' && (
              <DateSelector
                name="trip.scheduledDays"
                label="P/U Date:"
                value={trip?.scheduledDays || []}
                onChange={onChange}
                valid={validity?.scheduledDays?.valid}
                selector="day"
                range={false}
                multiple
              />
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Modal.Title className="border-0 d-flex mt-4">
            <Badge className="rounded bg-black bg-opacity-75">
              <i className={`fa fa-2x fa-location-dot {scale(0.75)}`} />
            </Badge>
            <span className="flex-grow-1 h-100 ms-3 mt-2 border-bottom border-gray">Trip Stops</span>
          </Modal.Title>
          <Row className="mt-3">
            <Col xs={12} sm={8}>
              <TripStop
                data={{
                  ...trip,
                  type: 'PU',
                  airportFilter: trip?.airportCode || '',
                  locationId: trip?.puLocationId,
                  scheduled: trip?.scheduled,
                  actual: trip?.puActualDatetime,
                  completed: trip?.puCompletedDatetime,
                  kind: trip?.kind,
                  doLocationId: trip?.doLocationId || '',
                  stops: trip?.stops || [],
                }}
                valid={{ locationId: validity?.puLocationId?.valid, scheduled: validity?.scheduled?.valid }}
                disabled
                index={-1}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'locationId':
                      return value !== '' ? onChange({ target: { name: 'trip.puLocationId', value } }) : undefined;
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                options={{
                  autoSelectLocation: trip?.kind === TripKindEnum.Arr,
                  location: {
                    props: {
                      query: {
                        airportCode: trip?.airportCode || null,
                      },
                    },
                  },
                }}
              />
              <DragAndDrop
                list={trip?.stops || []}
                id="id"
                name="trip.stops"
                replaceProperties={['id', 'type', 'locationId', 'airportFilter']}
                onChange={onChange}
                as={MemoizedTripStop}
              />
              <TripStop
                data={{
                  ...trip,
                  type: 'DO',
                  locationId: trip?.doLocationId,
                  scheduled: trip?.doScheduledDatetime,
                  actual: trip?.doActualDatetime,
                  completed: trip?.completion?.datetime,
                  airportFilter: trip?.doAirportFilter || trip?.doLocation?.airports?.[0]?.airportCode || trip?.airportCode || '',
                  kind: trip?.kind,
                  puLocationId: trip?.puLocationId || '',
                  stops: trip?.stops || [],
                }}
                valid={{ locationId: validity?.doLocationId?.valid }}
                disabled
                index={trip?.stops?.length || 0}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'locationId':
                      return value !== '' ? onChange({ target: { name: 'trip.doLocationId', value } }) : undefined;
                    case 'airportFilter':
                      return setState((current: any): any => ({
                        ...current,
                        trip: {
                          ...current?.trip,
                          doLocationId: '',
                          doAirportFilter: value,
                        },
                      }));
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                options={{
                  autoSelectLocation: trip?.kind === TripKindEnum.Dep,
                  filterLocationByAirport: true,
                }}
              />
            </Col>
            <Col>
              <TripStopTimeSchedule
                stop={{
                  type: 'PU',
                  locationId: trip?.puLocationId,
                  scheduled: trip?.scheduled,
                  actual: trip?.puActualDatetime,
                  completed: trip?.puCompletedDatetime,
                }}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'scheduled':
                      return onChange.time({ target: { name: 'trip.scheduled', value } });
                    case 'actual':
                      return onChange.time({ target: { name: 'trip.puActualDatetime', value } });
                    case 'completed':
                      return onChange.time({ target: { name: 'trip.puCompletedDatetime', value } });
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                index={-1}
                valid={{ scheduled: validity?.scheduled?.valid }}
              />
              {trip?.stops?.map((stop: any, s: number): JSX.Element => {
                return (
                  <TripStopTimeSchedule
                    disabled={selectedCount > 1}
                    stop={stop}
                    onChange={onChange}
                    index={s}
                    key={`stop.${s}.time`}
                    valid={{ scheduled: stop?.scheduled ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID }}
                  />
                );
              })}
              <TripStopTimeSchedule
                stop={{
                  type: 'DO',
                  locationId: trip?.doLocationId,
                  scheduled: trip?.doScheduledDatetime,
                  actual: trip?.doActualDatetime,
                  completed: trip?.completion?.datetime,
                }}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'scheduled':
                      return onChange.time({ target: { name: 'trip.doScheduledDatetime', value } });
                    case 'actual':
                      return onChange.time({ target: { name: 'trip.doActualDatetime', value } });
                    case 'completed':
                      return onChange.time({ target: { name: 'trip.completion.datetime', value } });
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                index={trip?.stops?.length || 0}
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditTripsModal;
export { useEditTripsModal };

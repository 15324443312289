import { CreateFlagHasTripBulkInput, CreateFlagHasTripInput, CreateFlagHasTripResponse, FlagHasTrip } from '../../models/gen/graphql';
import { customCreateHandler, validateObj } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateFlagHasTrip = validateObj({
  flagId: (val: string): string => val || '',
  tripId: (val: string): string => val || '',
});

const createFlagHasTripBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): (() => Promise<any>) =>
    async (...flagHasTrips: CreateFlagHasTripInput[]): Promise<any> => {
      const input: CreateFlagHasTripBulkInput = { values: flagHasTrips.map(validateFlagHasTrip) };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): { rows: FlagHasTrip[] | [] } => ({
    rows: (data?.createFlagHasTripBulk?.output || []).map((res: CreateFlagHasTripResponse): FlagHasTrip => res.node),
  }),
  handler: customCreateHandler('Assign Flag to Trip', 'createFlagHasTripBulk'),
  query: gql`
    mutation CreateFlagHasTripBulk($input: CreateFlagHasTripBulkInput!) {
      createFlagHasTripBulk(input: $input) {
        output {
          error
          node {
            createdAt
            # createdBy
            # flagId
            # tripId
            # updatedAt
            # updatedBy
          }
        }
      }
    }
  `,
};

export default createFlagHasTripBulk;
export { validateFlagHasTrip };

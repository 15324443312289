import React, { ReactNode, forwardRef } from 'react';

import { Virtuoso } from 'react-virtuoso';
import useViewport from '@/hooks/useViewport';

export type VirtualListProps = {
  data: any[];
  itemRenderer: (index: number, data: any) => React.ReactNode;
  height?: string | number;
};

// TODO: Proper documentation for easier usage
const VirtualList = ({ data, itemRenderer, height }: VirtualListProps): ReactNode => {
  // TODO: Make this an optional prop that defaults to the height in useViewport()
  const [{ content: { height: viewportHeight = 0 } = {} } = {}] = useViewport();
  return (
    <Virtuoso
      style={{ height: height || viewportHeight }}
      data={data}
      components={{
        // TODO: Make this more generic, classNames or entire object could be mapped in an options attribute
        List: forwardRef(ListComponent),
      }}
      itemContent={itemRenderer}
    />
  );
};

const ListComponent = (props, ref: React.Ref<HTMLDivElement>): ReactNode => (
  <div className={'VirtualList d-flex flex-column'} {...props} ref={ref} />
);

export default VirtualList;

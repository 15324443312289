import './styles.scss';

import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { NOTIFICATION_TIMEOUT } from '@/constants';
import Notification from '.';
import { Toast } from '@/models';
import useEvent from '@/hooks/useEvent';
import useInterval from '@/hooks/useInterval';

export type NotificationType = {
  title: string;
  content: string;
  type: Toast.Type;
  info?: string;
  options?: Toast.Options;
  shownAt?: number;
  [key: string]: unknown;
};
export type NotificationWrapperState = {
  notifications: NotificationType[];
};
const initNotificationWrapperState = {
  notifications: [],
};
const NotificationWrapper = (): ReactNode => {
  const [state, setState] = useState<NotificationWrapperState>(initNotificationWrapperState);
  const { notifications = [] } = state;

  const addNotification = useCallback(
    (notification: NotificationType): void => {
      const newId = JSON.stringify({ title: notification.title, content: notification.content });
      setState((current: NotificationWrapperState): NotificationWrapperState => {
        const exists = current.notifications.find((n: Record<string, unknown>): boolean => {
          const { title, content } = n;
          const currentId = JSON.stringify({ title, content });
          return currentId === newId;
        });
        return {
          ...current,
          notifications: exists
            ? current.notifications.map((curr: NotificationType): NotificationType => {
                const { title, content } = curr;
                const currentId = JSON.stringify({ title, content });
                if (currentId === newId) return notification;
                return curr;
              })
            : [...current.notifications, notification],
        };
      });
    },
    [setState]
  );

  useEvent('create-notification', (notification: NotificationType): void =>
    addNotification({ ...notification, shownAt: new Date().getTime() })
  );

  const onClose = useCallback(
    (key: string): (() => void) =>
      (): void => {
        setState((current: NotificationWrapperState): NotificationWrapperState => {
          const filteredNotifications = (current?.notifications || [])
            .filter((n: Record<string, unknown>): boolean => !!n.content)
            .filter((n: Record<string, unknown>): boolean => key !== JSON.stringify({ title: n.title, content: n.content }));
          return { ...current, notifications: filteredNotifications };
        });
      },
    [setState]
  );

  const lastCheck = useRef('');
  useInterval((): void => {
    const check = notifications
      .filter((n: NotificationType): boolean => n.shownAt + NOTIFICATION_TIMEOUT > new Date().getTime())
      .map((n: NotificationType): number => n.shownAt)
      .join();
    if (check === lastCheck.current) return;
    lastCheck.current = notifications.map((n: NotificationType): number => n.shownAt).join();
    setState(
      (current: NotificationWrapperState): NotificationWrapperState => ({
        ...current,
        notifications: current.notifications.filter(
          (n: NotificationType): boolean => n.shownAt + NOTIFICATION_TIMEOUT > new Date().getTime()
        ),
      })
    );
  }, 1000);

  const result = useMemo(
    (): ReactNode =>
      notifications
        .filter((notification: NotificationType): boolean => !!notification.content)
        .filter((notification: NotificationType): boolean => notification.shownAt + NOTIFICATION_TIMEOUT > new Date().getTime())
        .map((notification: NotificationType, n: number): ReactNode => {
          const id = JSON.stringify({ title: notification.title, content: notification.content });
          return (
            <Notification
              {...notification}
              options={{ ...(notification?.options || {}), clearAll: n === 0 }}
              onClose={onClose(id)}
              shownAt={notification.shownAt}
              key={n}
            />
          );
        }),
    [notifications, onClose]
  );

  return <div className="Notification-Wrapper">{result}</div>;
};

export default NotificationWrapper;

import { OperationVariables, QueryResult } from '@apollo/client';
import { RunRemoveCompletionOnTripInput, RunRemoveCompletionOnTripResponse } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

export type RunRemoveCompletionOnTripWrapper = (tripIds: string[]) => Promise<RunRemoveCompletionOnTripResponse>;

const RunRemoveCompletionOnTrip: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (tripIds: string[]): Promise<QueryResult<any, OperationVariables>> => {
      const input: RunRemoveCompletionOnTripInput = {
        tripIds: tripIds,
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): RunRemoveCompletionOnTripResponse => data?.runRemoveCompletionOnTrip || {},
  query: gql`
    mutation RunRemoveCompletionOnTrip($input: RunRemoveCompletionOnTripInput!) {
      runRemoveCompletionOnTrip(input: $input) {
        error {
          code
          debug
          message
        }
        updated
      }
    }
  `,
};

export default RunRemoveCompletionOnTrip;

import React, { forwardRef, useMemo } from 'react';
import { ResultOf, TypedDocumentNode, VariablesOf } from '@graphql-typed-document-node/core';
import Select, { SelectOption, SelectProps } from '@/components/Select';

import { camelCase } from '@/utils';
import useDb from '@/hooks/useDb';

export type SelectFromQueryProps<DocumentNode extends TypedDocumentNode = TypedDocumentNode> = {
  query: DocumentNode;
  selector: (data: ResultOf<DocumentNode>) => SelectOption[];
  /** @deprecated use 'options.cache' instead */
  cache?: string;
  /** @deprecated use 'options.variables' instead */
  params?: { variables?: VariablesOf<DocumentNode> }; //TODO: update useDb to use graphApi
  options?: {
    cache?: string;
    variables?: VariablesOf<DocumentNode>;
    ttl?: number;
  };
} & SelectProps;

const SelectFromQuery = <Props extends SelectFromQueryProps>(
  { query, selector = (a: any): SelectOption[] => a, options = {}, cache, params, ...props }: Props,
  ref: React.ForwardedRef<HTMLButtonElement | HTMLInputElement>
): JSX.Element => {
  options.cache = options?.cache || cache;
  options.variables = options?.variables || params?.variables;
  options.ttl = options?.ttl || 1;

  const { data, loading } = useDb(options?.cache || props?.name || camelCase(query?.definitions?.[0]?.['name']?.['value']), query, {
    selector,
    ...params,
    variables: options?.variables,
    ttl: options?.ttl,
  });

  const content = useMemo(
    (): JSX.Element[] =>
      (selector?.(data) || []).map(
        ({ label, ...optionProps }: SelectOption, o: number): JSX.Element => (
          <option {...optionProps} key={o}>
            {label}
          </option>
        )
      ),
    [data, selector]
  );

  return (
    <Select {...props} loading={!(content || []).length && !!loading} ref={ref}>
      {content}
    </Select>
  );
};
const SelectFromQueryWithRef = forwardRef(SelectFromQuery);

export default React.memo(SelectFromQueryWithRef);

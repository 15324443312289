namespace Styles {
  export type ColorVariant =
    | 'primary'
    | 'secondary'
    | 'alternate'
    | 'success'
    | 'green-subtle'
    | 'warning'
    | 'danger'
    | 'info'
    | 'red'
    | 'purple'
    | 'cyan'
    | 'green'
    | 'blue'
    | 'magenta'
    | 'dark';
  export const VARIANT_MAPPING: { [key in ColorVariant]: string } = {
    primary: '#1b3e6f',
    secondary: '#ff9e16',
    red: '#c00403',
    purple: '#9403fe',
    alternate: '',
    dark: '#000',
    success: '#067640',
    'green-subtle': '#93d500',
    magenta: '#d31566',
    cyan: '#40ade2',
    blue: '#4059ff',
    green: '#0f9d58',
    warning: '',
    danger: '',
    info: '',
  };
  export const MARKER_COLOR_LIST: Styles.ColorVariant[] = [
    'secondary',
    'primary',
    'red',
    'purple',
    'green-subtle',
    'cyan',
    'blue',
    'green',
  ];
  export const RANGE_HANDLE_COLOR_LIST: Styles.ColorVariant[] = ['primary', 'secondary', 'green-subtle', 'cyan', 'red'];
}

export default Styles;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueryFilters {
  --qf-control-height: 3rem;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  flex-wrap: nowrap;
}

#PageFilters .QueryFilters .QueryFilters-Control,
#PageFilters .QueryFilters .QueryFilters-Input {
  display: flex;
  gap: 0.5rem;
  max-height: var(--qf-control-height);
}
#PageFilters .QueryFilters .QueryFilters-Input {
  min-width: 75px;
  max-width: 140px;
}
#PageFilters .QueryFilters .Input,
#PageFilters .QueryFilters .Dropdown,
#PageFilters .QueryFilters .btn,
#PageFilters .QueryFilters .ant-picker {
  height: var(--qf-control-height);
  box-shadow: 4px 5px 16px 0px rgba(0, 0, 0, 0.35);
}
#PageFilters .QueryFilters input,
#PageFilters .QueryFilters .ant-picker {
  background-color: transparent;
  border-color: #fff;
  color: #fff !important;
}
#PageFilters .QueryFilters input.Dropdown-Input.is-selected::placeholder,
#PageFilters .QueryFilters .ant-picker.Dropdown-Input.is-selected::placeholder {
  color: #fff !important;
}
#PageFilters .QueryFilters input::placeholder,
#PageFilters .QueryFilters .ant-picker::placeholder {
  color: rgba(255, 255, 255, 0.65) !important;
}
#PageFilters .QueryFilters .ant-picker {
  height: var(--qf-control-height);
}
#PageFilters .QueryFilters .fa,
#PageFilters .QueryFilters .fa-regular {
  color: #fff;
}
#PageFilters .QueryFilters .ActionsDropdown .fa-ellipsis-v {
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/QueryFilters/styles.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EAEA,aAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AAFF;;AAOI;;EAEE,aAAA;EACA,WAAA;EACA,oCAAA;AAJN;AAMI;EACE,eAAA;EACA,gBAAA;AAJN;AAMI;;;;EAIE,gCAAA;EACA,gDAAA;AAJN;AAMI;;EAEE,6BAAA;EACA,kBAAA;EACA,sBAAA;AAJN;AAKM;;EACE,sBAAA;AAFR;AAIM;;EACE,2CAAA;AADR;AAII;EACE,gCAAA;AAFN;AAII;;EAEE,WAAA;AAFN;AAII;EACE,eAAA;AAFN","sourcesContent":[".QueryFilters {\n  --qf-control-height: 3rem;\n  display: flex;\n  gap: 0.5rem;\n  padding: 0.5rem;\n  flex-wrap: nowrap;\n}\n\n#PageFilters .QueryFilters .QueryFilters-Control,\n#PageFilters .QueryFilters .QueryFilters-Input {\n  display: flex;\n  gap: 0.5rem;\n  max-height: var(--qf-control-height);\n}\n#PageFilters .QueryFilters .QueryFilters-Input {\n  min-width: 75px;\n  max-width: 140px;\n}\n#PageFilters .QueryFilters .Input,\n#PageFilters .QueryFilters .Dropdown,\n#PageFilters .QueryFilters .btn,\n#PageFilters .QueryFilters .ant-picker {\n  height: var(--qf-control-height);\n  box-shadow: 4px 5px 16px 0px rgba(0, 0, 0, 0.35);\n}\n#PageFilters .QueryFilters input,\n#PageFilters .QueryFilters .ant-picker {\n  background-color: transparent;\n  border-color: #fff;\n  color: #fff !important;\n}\n#PageFilters .QueryFilters input.Dropdown-Input.is-selected::placeholder,\n#PageFilters .QueryFilters .ant-picker.Dropdown-Input.is-selected::placeholder {\n  color: #fff !important;\n}\n#PageFilters .QueryFilters input::placeholder,\n#PageFilters .QueryFilters .ant-picker::placeholder {\n  color: rgba(255, 255, 255, 0.65) !important;\n}\n#PageFilters .QueryFilters .ant-picker {\n  height: var(--qf-control-height);\n}\n#PageFilters .QueryFilters .fa,\n#PageFilters .QueryFilters .fa-regular {\n  color: #fff;\n}\n#PageFilters .QueryFilters .ActionsDropdown .fa-ellipsis-v {\n  font-size: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { ReactNode } from 'react';

import useGlobalState from '@/hooks/useGlobalState';

const GlobalStatePane = (): ReactNode => {
  const { state } = useGlobalState();

  return <pre>{JSON.stringify(state, null, 2)}</pre>;
};

export default GlobalStatePane;

import './styles.scss';

import { Badge, Button, Col, Row } from 'react-bootstrap';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { TimePicker, TimeRangePickerProps } from 'antd';
import { getClasses, stringify } from '@/utils';

import AdjustableNumberField from '../AdjustableNumberField';
import ConfirmationButton from '@/components/ConfirmationButton';
import { Dayjs } from 'dayjs';
import FormField from '@/components/FormField';
import { PunchPolicy } from '@/models/gen/graphql';
import dayjs from 'dayjs';

type PunchPolicyTimeControlsProps = PunchPolicy & {
  index: number;
  onChange?: (event) => void;
  onTimeInputChange?: (event: { target: { name: string; value: [string, string] } }) => void;
  onDelete?: (event) => void;
  handleColor?: (i: number) => string;
  nextStartTime?: string;
  disabled?: boolean;
  isSpecialEvent?: boolean;
};

const PunchPolicyTimeControls = ({
  index,
  eventStartDatetime,
  eventEndDatetime,
  startTime = '00:00:00',
  endTime = '00:00:00',
  minutesIn = 0,
  minutesOut = 0,
  onChange,
  onTimeInputChange,
  handleColor,
  disabled = false,
  isSpecialEvent = false,
  onDelete,
}: PunchPolicyTimeControlsProps): JSX.Element => (
  <Row className="PunchPolicyTimeControls mt-3">
    <Col className="flex-grow-0">
      <ConfirmationButton
        icon={<i className={getClasses('sv sv-trash2 fs-5 text-danger', !onDelete ? 'invisible' : undefined)} />}
        onConfirm={onDelete}
        variant="icon"
        disabled={!onDelete}
      />
    </Col>
    <Col className="flex-grow-1">
      <Row>
        {!!isSpecialEvent && (
          <Col>
            <FormField
              name="date"
              type="daterange"
              value={[eventStartDatetime || undefined, eventEndDatetime || undefined]}
              onChange={onChange}
              disabled={!!disabled}
              condensed
            />
          </Col>
        )}
        <Col>
          <AdjustableNumberField name="minutesIn" value={minutesIn} onChange={onChange} disabled={!!disabled} />
        </Col>
        <Col>
          <AdjustableNumberField name="minutesOut" value={minutesOut} onChange={onChange} disabled={!!disabled} />
        </Col>
        <Col className="d-flex gap-2">
          <div className="d-flex flex-column justify-content-center flex-grow-0">
            <Badge
              className="text-white rounded-circle"
              bg={handleColor(index)}
              style={{ width: '2rem', height: '2rem', lineHeight: '1.25rem' }}
            >
              {index + 1}
            </Badge>
          </div>
          <div className="flex-grow-1">
            <TimeRange name="time" value={[startTime, endTime]} onChange={onTimeInputChange} onBlur={onTimeInputChange} />
          </div>
        </Col>
      </Row>
    </Col>
    <Col className="flex-grow-0" />
  </Row>
);

type TimeRangeProps = Omit<TimeRangePickerProps, 'value' | 'onChange' | 'onBlur'> & {
  value?: [string, string];
  onChange?: (event: { target: { name: string; value: [string, string] } }) => void;
  onBlur?: (event: { target: { name: string; value: [string, string] } }) => void;
};
const TimeRange = ({ onChange, onBlur, value: rawValue, ...props }: TimeRangeProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef(null);
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const lastChange = useRef<[string, string]>([rawValue[0], rawValue[1]]);
  const value = useMemo(
    (): [Dayjs, Dayjs] => [rawValue[0] ? dayjs(rawValue[0], 'hh:mm') : undefined, rawValue[1] ? dayjs(rawValue[1], 'hh:mm') : undefined],
    [rawValue]
  );

  const getValue = (): [string, string] => {
    if (!startInputRef.current || !endInputRef.current) {
      const [startInput, endInput] = inputRef.current?.['nativeElement'].querySelectorAll('input[date-range]') as HTMLInputElement[];
      startInputRef.current = startInput;
      endInputRef.current = endInput;
    }
    const [start, end] = [startInputRef.current?.value, endInputRef.current?.value];
    return [start || rawValue[0], end || rawValue[1]];
  };
  const isStaleChange = (): boolean => {
    const newChange = getValue();
    const isDifferent = stringify.compare(lastChange.current, newChange);
    lastChange.current = newChange;
    return isDifferent;
  };
  const handleChange = (): void => {
    if (isStaleChange()) return;
    onChange?.({ target: { ...inputRef.current, value: getValue() } });
  };
  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open);
    if (open || isStaleChange()) return;
    onBlur?.({ target: { ...inputRef.current, value: getValue() } });
  };
  const handleBlur = (event): void => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      if (event.target === startInputRef.current) {
        endInputRef.current.focus();
      } else if (event.target === endInputRef.current) {
        handleChange();
      }
    }
  };

  return (
    <>
      <TimePicker.RangePicker
        open={isOpen}
        className={props.className || 'w-100 {text-align:center!;}>.ant-picker-input>input'}
        allowClear={false}
        format="HH:mm"
        suffixIcon={<i className="sv sv-time {font-size:1.5rem;} m-2" />}
        {...props}
        value={value as any}
        onChange={handleChange}
        onOpenChange={handleOpenChange}
        onBlur={handleBlur}
        ref={inputRef}
        needConfirm={false}
        renderExtraFooter={(): ReactNode => (
          <div className="w-100 d-flex justify-content-end py-2">
            <Button variant="primary" size="sm" onClick={(): void => handleOpenChange(false)}>
              OK
            </Button>
          </div>
        )}
        disabledTime={(now: Dayjs, range: 'start' | 'end') => ({
          disabledHours: (): number[] =>
            Array.from({ length: 24 }, (_: unknown, n: number): number => n).filter(
              (hour: number): boolean => range === 'end' && hour < value[0].hour()
            ),
          disabledMinutes: (selectedHour: number): number[] =>
            Array.from({ length: 60 }, (_: unknown, n: number): number => n).filter(
              (n: number): boolean =>
                range === 'end' &&
                now
                  .clone()
                  .set('hour', selectedHour)
                  .set('minute', n)
                  .isBefore(now.set('hour', value[0].hour()).set('minute', value[0].minute() + 1))
            ),
        })}
      />
    </>
  );
};

export default PunchPolicyTimeControls;

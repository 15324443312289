import { SelectUserFilterDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const selectUserFilter = createGraphApiMockResponse(SelectUserFilterDocument, {
  searchUsers: {
    userWithAirportConnection: {
      edges: [
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'john doe',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'richard pennington',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'manifest api',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'delta-crew ',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'american-crew ',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'karly castro',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'american-crew-2 ',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'ups ',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'trip api',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'cypress leader',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'leader2 ondemand',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'manifest worker',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'workaholic james',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Erickson Joseph',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Juan Velez',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Apple Store',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Johnathan Clark',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Kristine Scotto',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Daisy Mathis',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Noreen Young',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Mitchell Hayes',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Cotney Nelson',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Arnold Prosper',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Ralph Valdes',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Lukas Tobon',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Driver Developer',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Amber Stinson',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Antonio Orkmon',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Charles Stinson',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Diante McIntosh',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Ericka Logan',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Gary Snelson',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'Janice McWright',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'John Triplett',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
        {
          node: {
            id: 'fedbb950-4412-4638-845d-f77899985d72',
            fullName: 'cypress dispatcher',
            __typename: 'User',
          },
          __typename: 'UserEdge',
        },
      ],
      __typename: 'UserConnection',
    },
    __typename: 'SearchUserResponse',
  },
});

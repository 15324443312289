import {
  DeleteGroupHasPermissionBulkInput,
  DeleteGroupHasPermissionBulkResponse,
  DeleteGroupHasPermissionInput,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { DeleteGroupHasPermissionBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import graphApi from '../..';

type DeleteGroupHasPermissionBulkGraphApiResponse = GraphApiResponse<typeof DeleteGroupHasPermissionBulkDocument>;

const title = 'Remove Permissions from Group';

const deleteGroupHasPermissionBulkResponseSelector = (
  res: DeleteGroupHasPermissionBulkGraphApiResponse
): DeleteGroupHasPermissionBulkResponse => (!res.errors ? res?.deleteGroupHasPermissionBulk : undefined);

const [runDeleteGroupHasPermissionBulk] = graphApi(DeleteGroupHasPermissionBulkDocument, {
  onError: (res: DeleteGroupHasPermissionBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteGroupHasPermissionBulk = async (search: DeleteGroupHasPermissionInput[]): Promise<DeleteGroupHasPermissionBulkResponse> => {
  const input: DeleteGroupHasPermissionBulkInput = { search };
  const res = await runDeleteGroupHasPermissionBulk({ input });

  const selected = deleteGroupHasPermissionBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default deleteGroupHasPermissionBulk;

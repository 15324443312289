import { GetInvoiceInput, GetInvoiceResponse } from '../../../models/gen/graphql';

import { GetInvoiceDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils';

type GetInvoiceGraphApiResponse = GraphApiResponse<typeof GetInvoiceDocument>;

const title = 'Load Invoice';

const getInvoiceResponseSelector = (res: GetInvoiceGraphApiResponse): GetInvoiceResponse =>
  !res.errors ? (res?.getInvoice as GetInvoiceResponse) : undefined;

const [runGetInvoice, runRefetchGetInvoice] = graphApi(GetInvoiceDocument, {
  onError: (res: GetInvoiceGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const getInvoice = async (invoiceId: number): Promise<GetInvoiceResponse> => {
  const input: GetInvoiceInput = { invoiceId };
  const res = await runRefetchGetInvoice({ input });

  const selected = getInvoiceResponseSelector(res);
  return selected;
};

export const useGetInvoice = createGraphApiHook(getInvoice);

export default getInvoice;

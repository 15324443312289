import React, { ReactNode } from 'react';

import { createPortal } from 'react-dom';

type PortalProps = {
  children?: ReactNode;
  container?: Element;
};
const Portal = ({ children, container }: PortalProps): ReactNode => createPortal(children, container || document.body);
const PortalWhen = (props: PortalProps): ReactNode => (props?.container ? <Portal {...props} /> : props?.children);

Portal.When = PortalWhen;

export default Portal;
export { PortalWhen };

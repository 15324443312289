import { ForgotPasswordInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const forgotPassword: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (key: 'email' | 'username', value: string): Promise<any> => {
      const input: ForgotPasswordInput = {
        [key]: value,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any => data?.forgotPassword?.success || false,
  query: gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
      forgotPassword(input: $input) {
        success
      }
    }
  `,
};

export default forgotPassword;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput .fa.fa-eye::before, .PasswordInput .fa.fa-eye-slash::before {
  cursor: pointer;
  opacity: 0.5;
}
@media (max-width: 1399px) {
  .PasswordInput .prepend,
  .PasswordInput .append {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PasswordInput/styles.scss"],"names":[],"mappings":"AAEI;EAEE,eAAA;EACA,YAAA;AAFN;AAME;EACE;;IAEE,cAAA;EAJJ;AACF","sourcesContent":[".PasswordInput .fa.fa-eye::before, .PasswordInput .fa.fa-eye-slash::before {\n  cursor: pointer;\n  opacity: 0.5;\n}\n@media (max-width: 1399px) {\n  .PasswordInput .prepend,\n  .PasswordInput .append {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

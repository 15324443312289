import { GraphApi, Toast } from 'models';
import { createNotification, getErrors, handleError } from 'utils';

import { RunDownloadLatestInvoiceDocumentInput } from 'models/gen/graphql';
import gql from 'graphql-tag';

const runDownloadLatestInvoiceDocument: GraphApi.Query.Bundle = {
  wrapper: (fireQuery: GraphApi.Query.Execute) => async (invoiceId: number) => {
    const input: RunDownloadLatestInvoiceDocumentInput = { invoiceId };
    return fireQuery({
      variables: {
        input,
      },
    });
  },
  selector: (data: any): any => data?.runDownloadLatestInvoiceDocument?.output,
  handler: (response: any): void => {
    const title = 'Download Invoice';
    const errors = getErrors(response);
    const hasError = !!errors?.length || !!response?.networkError;
    handleError({ ...response, errors }, { notification: { title } });
    if (hasError) return;
    createNotification('Invoice successfully downloaded.', Toast.Type.SUCCESS, title);
  },
  query: gql`
    mutation RunDownloadLatestInvoiceDocument($input: RunDownloadLatestInvoiceDocumentInput!) {
      runDownloadLatestInvoiceDocument(input: $input) {
        output {
          id
          url
          error {
            code
            debug
            message
          }
        }
      }
    }
  `,
};

export default runDownloadLatestInvoiceDocument;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Field {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  padding: 0.6rem 0;
  flex-grow: 1;
}
.Field > .Field-Label,
.Field > .Field-Feedback {
  position: absolute;
  padding: 0 0.25rem;
  z-index: 5;
  line-height: 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
}
.Field > .Field-Label {
  display: flex;
  gap: 0.25rem;
  top: 0.1rem;
  left: 0.5rem;
  border-bottom: 1px solid #fff;
  color: var(--bs-gray);
  text-transform: uppercase;
}
.Field > .Field-Label > .Field-Required {
  position: absolute;
  right: -0.5rem;
  color: var(--bs-danger);
  font-weight: bold;
  font-size: 1rem;
}
.Field > .Field-Feedback {
  bottom: 0.1rem;
  right: 0.5rem;
  border-top: 1px solid #fff;
  color: var(--bs-gray);
}
.Field.is-valid > .Field-Feedback {
  color: var(--bs-success);
}
.Field.is-invalid > .Field-Feedback {
  color: var(--bs-danger);
}
.Field > .alert {
  margin: 0;
  padding: 0.375rem 0.75rem;
}

.input-group > .Field:not(:last-child) .form-control,
.input-group > .Field:not(:last-child) .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .Field:not(:last-child) + .Field .form-control,
.input-group > .Field:not(:last-child) + .Field .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Field/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACF;AAAE;;EAEE,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,qBAAA;EACA,yBAAA;AAEJ;AADI;EACE,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;AAGN;AAAE;EACE,cAAA;EACA,aAAA;EACA,0BAAA;EACA,qBAAA;AAEJ;AAII;EACE,wBAAA;AAFN;AASI;EACE,uBAAA;AAPN;AAUE;EACE,SAAA;EACA,yBAAA;AARJ;;AAaI;;EAEE,0BAAA;EACA,6BAAA;AAVN;AAaM;;EAEE,yBAAA;EACA,4BAAA;AAXR","sourcesContent":[".Field {\n  position: relative;\n  display: block;\n  margin: 0.5rem 0;\n  padding: 0.6rem 0;\n  flex-grow: 1;\n}\n.Field > .Field-Label,\n.Field > .Field-Feedback {\n  position: absolute;\n  padding: 0 0.25rem;\n  z-index: 5;\n  line-height: 0.5rem;\n  font-weight: 600;\n  font-size: 0.8rem;\n}\n.Field > .Field-Label {\n  display: flex;\n  gap: 0.25rem;\n  top: 0.1rem;\n  left: 0.5rem;\n  border-bottom: 1px solid #fff;\n  color: var(--bs-gray);\n  text-transform: uppercase;\n}\n.Field > .Field-Label > .Field-Required {\n  position: absolute;\n  right: -0.5rem;\n  color: var(--bs-danger);\n  font-weight: bold;\n  font-size: 1rem;\n}\n.Field > .Field-Feedback {\n  bottom: 0.1rem;\n  right: 0.5rem;\n  border-top: 1px solid #fff;\n  color: var(--bs-gray);\n}\n.Field.is-valid > .Field-Feedback {\n  color: var(--bs-success);\n}\n.Field.is-invalid > .Field-Feedback {\n  color: var(--bs-danger);\n}\n.Field > .alert {\n  margin: 0;\n  padding: 0.375rem 0.75rem;\n}\n\n.input-group > .Field:not(:last-child) .form-control,\n.input-group > .Field:not(:last-child) .input-group-text {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n.input-group > .Field:not(:last-child) + .Field .form-control,\n.input-group > .Field:not(:last-child) + .Field .input-group-text {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

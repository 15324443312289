import './styles.scss';

import { Form, InputGroup } from 'react-bootstrap';
import React, { useMemo, useRef } from 'react';

import DateInput from '@/components/DateInput';
import DateRangePicker from '@/components/DateRangePicker';
import DatetimeInput from '@/components/DatetimeInput';
import { FeedbackType } from 'react-bootstrap/esm/Feedback';
import TimeInput from '@/components/TimeInput';
import Tippy from '@tippyjs/react';
import { Validation } from 'utils';
import { followCursor } from 'tippy.js';
import { getClasses } from '@/utils/strings';
import { stringify } from '@/utils/objects';
import useClassNames from '@/hooks/useClassNames';

// TODO: Replace FormField's use of all date components to use DateInput.Range, DateInput.Datetime, and DateInput.Time when DateRangePicker is refactored.
type FormFieldTypes =
  | 'text'
  | 'number'
  | 'switch'
  | 'checkbox'
  | 'radio'
  | 'email'
  | 'password'
  | 'date'
  | 'datetime'
  | 'daterange'
  | 'time'
  | 'textarea';

export type FormFieldProps = {
  name: string;
  type?: FormFieldTypes;
  label?: number | string | JSX.Element;
  value?: string | boolean | number | string[] | number[];
  checked?: boolean;
  placeholder?: string;
  required?: boolean;
  // TODO: Once validation has been updated, remove "undefined | boolean" from this type.
  valid?: Validation.ValidityType | undefined | boolean;
  feedback?: string;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  onKeyDown?: any;
  options?: FormFieldModel.Options;
  inputOptions?: any;
  readOnly?: boolean;
  disabled?: boolean;
  inline?: boolean;
  size?: 'sm' | 'md' | 'lg';
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  id?: string | number;
  className?: undefined;
  isInvalid?: undefined;
  isValid?: undefined;
  autoComplete?: boolean;
  append?: string | JSX.Element;
  prepend?: string | JSX.Element;
  condensed?: boolean;
  [x: string]: any;
};

const FormField = ({
  id,
  name,
  type = 'text',
  label,
  value,
  checked,
  placeholder,
  required,
  valid,
  feedback,
  onChange,
  onBlur,
  onKeyDown,
  onFocus,
  options,
  readOnly,
  disabled,
  inline,
  condensed,
  size,
  min,
  max,
  maxLength,
  autoComplete,
  inputOptions,
  append,
  prepend,
  ...inputProps
}: FormFieldProps): JSX.Element => {
  const isInline = inline !== undefined && inline !== false;
  const isCondensed = condensed !== undefined && condensed !== false;
  const originalValue = useRef(value);
  const wasDirty = useRef(false);
  const isDirty = useMemo(
    (): boolean => (wasDirty.current = !!wasDirty.current || !stringify.compare(originalValue.current, value)),
    [value]
  );
  const groupClasses = useClassNames(
    'FormField',
    options?.group?.className,
    isCondensed ? 'condensed' : undefined,
    isInline ? 'inline' : '',
    ['daterange'].includes(type) ? 'DateRange' : '',
    isDirty ? 'is-dirty' : 'is-clean'
  );
  const labelClasses = useClassNames('FormField-Label', options?.label?.className);
  const inputClasses = useClassNames(
    'FormField-Input',
    !['checkbox', 'radio', 'switch'].includes(type) ? '' : undefined,
    (valid === Validation.ValidityType.WARNING && 'border-warning') || undefined,
    (valid === Validation.ValidityType.VALID_WARNING && 'border-warning') || undefined,
    (valid === Validation.ValidityType.INVALID_WARNING && 'border-warning') || undefined,
    options?.input?.className
  );
  const feedbackClasses = useClassNames('FormField-Feedback', 'd-block', options?.feedback?.className);

  const calculatedProps = {
    group: {
      className: groupClasses,
    },
    wrapper: {
      className: getClasses(
        'FormField-Wrapper d-flex flex-column flex-grow-1',
        ['checkbox', 'radio', 'switch'].includes(type) ? 'h-100' : undefined
      ),
    },
    label: {
      className: labelClasses,
      htmlFor: id ? id : undefined,
    },
    required: {
      content: options?.label?.requiredTooltip || 'Required',
    },
    input: {
      id: id || name,
      className: inputClasses,
      name: name,
      type: type !== 'password' || autoComplete !== undefined ? type : 'security',
      value: value as any,
      checked: checked !== undefined ? !!checked : undefined,
      placeholder: placeholder,
      isValid: valid === Validation.ValidityType.VALID || valid === Validation.ValidityType.VALID_WARNING || valid === true,
      isInvalid: valid === Validation.ValidityType.INVALID || valid === Validation.ValidityType.INVALID_WARNING || valid === false,
      onChange: onChange,
      onFocus: onFocus,
      onBlur: onBlur,
      onKeyDown: onKeyDown,
      readOnly: readOnly,
      disabled: disabled,
      size: size,
      min: min,
      max: max,
      maxLength: maxLength,
      as: type === 'textarea' ? 'textarea' : undefined,
      autoComplete: autoComplete !== undefined ? autoComplete : 'off',
      style: {
        ...(inputProps?.style || {}),
        ...(type !== 'password' || autoComplete !== undefined ? {} : { textSecurity: 'disc', WebkitTextSecurity: 'disc' }),
        ...(!['checkbox', 'radio', 'switch'].includes(type) ? {} : { margin: 'auto 0' }),
      },
      label: ['checkbox', 'radio', 'switch'].includes(type) ? (typeof label === 'string' ? label.replace(/\\n/g, ' ') : label) : undefined,
      options: inputOptions,
      ...inputProps,
    },
    feedback: {
      className: feedbackClasses,
      type: [Validation.ValidityType.VALID, Validation.ValidityType.VALID_WARNING, true].includes(valid)
        ? 'valid'
        : [Validation.ValidityType.INVALID, Validation.ValidityType.INVALID_WARNING, false].includes(valid)
          ? 'invalid'
          : ('default' as FeedbackType),
      as: options?.feedback?.as || 'em',
    },
  };

  const GroupComponent = useMemo(
    (): ((props: any) => JSX.Element) => (options?.group?.as || isInline ? InputGroup : Form.Group),
    [isInline, options?.group?.as]
  );
  const LabelComponent = useMemo(
    (): ((props: any) => JSX.Element) => (options?.label?.as || isInline ? InputGroup.Text : Form.Text),
    [isInline, options?.label?.as]
  );
  const InputComponent = useMemo((): ((props: any) => JSX.Element) => {
    let result = options?.input?.as || Form.Control;
    if (type === 'switch' || type === 'checkbox' || type === 'radio') result = Form.Check;
    if (type === 'date') result = DateInput;
    if (type === 'datetime') result = DatetimeInput;
    if (type === 'daterange') result = DateRangePicker;
    if (type === 'time') result = TimeInput;
    return result;
  }, [options?.input?.as, type]);

  const Group = useMemo((): ((props: any) => JSX.Element) => {
    const FormFieldGroup = (props: any): JSX.Element => <GroupComponent {...props}>{props?.children}</GroupComponent>;
    return FormFieldGroup;
  }, [GroupComponent]);
  const Label = useMemo((): ((props: any) => JSX.Element) => {
    const FormFieldLabel = (props: any): JSX.Element => (
      <LabelComponent {...props}>
        {typeof label === 'string'
          ? label.split(/\\n/g).map(
              (text: string, idx: number): JSX.Element => (
                <React.Fragment key={idx}>
                  {text}
                  <br />
                </React.Fragment>
              )
            )
          : label}
        {required !== undefined && required !== false && (
          <Tippy content="Required" {...props.required}>
            <sup className="ms-1">
              <i className="fa fa-asterisk text-danger" />
            </sup>
          </Tippy>
        )}
      </LabelComponent>
    );
    return FormFieldLabel;
  }, [LabelComponent, label, required]);
  const Input = useMemo((): ((props: any) => JSX.Element) => {
    const FormFieldInput = (props: any): JSX.Element => <InputComponent ref={options?.input?.withRef} {...props} />;
    return FormFieldInput;
  }, [InputComponent]);
  const Wrapper = useMemo((): ((props: any) => JSX.Element) => {
    const FormFieldWrapper = (props: any): JSX.Element =>
      options?.feedback?.asTooltip ? (
        <Tippy
          content={feedback}
          animation={false}
          trigger={feedback ? 'mouseenter' : 'manual'}
          followCursor
          plugins={[followCursor]}
          arrow={false}
          disabled={!feedback}
        >
          <div {...props} />
        </Tippy>
      ) : (
        <div {...props} />
      );
    return FormFieldWrapper;
  }, [feedback, options?.feedback?.asTooltip]);

  return (
    <Group {...calculatedProps.group}>
      {label && !['checkbox', 'radio', 'switch'].includes(type) && <Label {...calculatedProps.label} required={calculatedProps.required} />}
      <Wrapper {...calculatedProps.wrapper}>
        <div className={`d-flex flex-grow-1 rounded ${append || prepend ? 'grouped-input' : ''}`}>
          {prepend && <InputGroup.Text className="prepend">{prepend}</InputGroup.Text>}
          <Input {...calculatedProps.input} />
          {append && <InputGroup.Text className="append">{append}</InputGroup.Text>}
        </div>
        {!options?.feedback?.asTooltip && feedback && (
          <Form.Control.Feedback {...calculatedProps.feedback}>{feedback}</Form.Control.Feedback>
        )}
      </Wrapper>
    </Group>
  );
};

const FormFieldText = (props: any): JSX.Element => (
  <FormField {...props} className="{box-shadow:none!;outline:none!;border:none!;}" readOnly condensed />
);
FormField.Text = FormFieldText;

export namespace FormFieldModel {
  interface ElementOptions {
    className?: string;
    as?: any;
  }
  export interface GroupOptions extends ElementOptions {}
  export interface LabelOptions extends ElementOptions {
    requiredTooltip?: string;
  }
  export interface InputOptions extends ElementOptions {
    withRef?: any;
  }
  export interface FeedbackOptions extends ElementOptions {
    asTooltip?: boolean;
  }
  export type Options = {
    group?: GroupOptions;
    label?: LabelOptions;
    input?: InputOptions;
    feedback?: FeedbackOptions;
  };
}

export default FormField;

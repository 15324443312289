// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border: 1px solid var(--bs-light);
  border-radius: var(--bs-border-radius-sm);
  overflow: hidden;
}
.ImageDisplay > img {
  object-fit: cover;
  border-radius: inherit;
  width: inherit;
  height: inherit;
}
.ImageDisplay > i {
  font-size: 1.5rem;
}

.Thumbnail {
  position: relative;
}
.Thumbnail > .ImageDisplay {
  width: 8.3rem;
  height: 8.3rem;
}
.Thumbnail > button {
  position: absolute;
  top: -0.35rem;
  left: -0.35rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--bs-gray);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageDisplay/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,iCAAA;EACA,yCAAA;EACA,gBAAA;AACF;AACE;EACE,iBAAA;EACA,sBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;;AAGA;EACE,kBAAA;AAAF;AACE;EACE,aAAA;EACA,cAAA;AACJ;AACE;EACE,kBAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,UAAA;AACJ","sourcesContent":[".ImageDisplay {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 5rem;\n  height: 5rem;\n  border: 1px solid var(--bs-light);\n  border-radius: var(--bs-border-radius-sm);\n  overflow: hidden;\n}\n.ImageDisplay > img {\n  object-fit: cover;\n  border-radius: inherit;\n  width: inherit;\n  height: inherit;\n}\n.ImageDisplay > i {\n  font-size: 1.5rem;\n}\n\n.Thumbnail {\n  position: relative;\n}\n.Thumbnail > .ImageDisplay {\n  width: 8.3rem;\n  height: 8.3rem;\n}\n.Thumbnail > button {\n  position: absolute;\n  top: -0.35rem;\n  left: -0.35rem;\n  width: 1.6rem;\n  height: 1.6rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-color: var(--bs-gray);\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { createNotification, handleError } from '../../../utils/custom';

import { DeletePickupPointBulkDocument } from '../../queries';
import { DeletePickupPointBulkInput } from '../../../models/gen/graphql';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import graphApi from '../..';

type DeletePickupPointGraphApiResponse = GraphApiResponse<typeof DeletePickupPointBulkDocument>;

const title = 'Delete Pickup Point';

const DeletePickupPointBulkResponseSelector = (res: DeletePickupPointGraphApiResponse): number =>
  !res.errors ? res?.deletePickupPointBulk?.deleted || 0 : undefined;

export const [runDeletePickupPointBulk] = graphApi(DeletePickupPointBulkDocument, {
  onError: (res: DeletePickupPointGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deletePickupPointBulk = async (ids: string[]): Promise<number> => {
  const input: DeletePickupPointBulkInput = {
    search: ids.map((id: string): { id: string } => ({ id })),
  };
  const res = await runDeletePickupPointBulk({ input });
  const data = DeletePickupPointBulkResponseSelector(res);

  if (data) createNotification(`Successfully deleted ${data} pickup point(s).`, Toast.Type.SUCCESS, title);
  return data;
};

export default deletePickupPointBulk;

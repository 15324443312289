import './styles.scss';

import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '../../components/VirtualTable';

import { Datetime } from '../../utils';
import { LoadingBlur } from '../../components/LoadingSpinner';
import { RateModalType } from './useRatesModals';
import createRateForTrips from '../../api/services/rates/createRateForTrips';
import { getClasses } from '../../utils/strings';
import updateRateBulk from '../../api/services/rates/updateRateBulk';
import useEvent from '../../hooks/useEvent';
import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';

interface ConfirmTripRatesModalForm {
  name: string;
  search: string;
}
const initConfirmTripRatesModalForm: ConfirmTripRatesModalForm = {
  name: '',
  search: '',
};
const ConfirmTripRatesModal = () => {
  // Init State
  const openRatesModal = useEvent('useRatesModals:triggerModal');
  const [form, onChange, setForm] = useForm(initConfirmTripRatesModalForm);
  const { name, search } = form;

  const [state, setState] = useModal('ConfirmTripRates', {
    onSubmit: async (): Promise<void> => {
      const isCopy = !!selected.length;
      if (isCopy) {
        const tripIds = data?.filter((row) => !selected?.includes(row?.id)).map((row) => row?.id);
        await createRateForTrips({ ...rate, name }, tripIds);
        return onHide();
      }
      const res = await updateRateBulk(rateUpdate);
      onHide();
      if (!res?.[0]?.error) openRatesModal({ type: RateModalType.ApplySimilar, payload: { rateId: rate?.id } });
    },
  });
  const { onHide, onSubmit } = setState;
  const { show = false, data = [], selected = [], rate, rateUpdate, loading } = state;
  const totalCount = data?.length || 0;

  const { filteredRows, onSelect } = useVirtualTable(setState, {
    selected: selected,
    rows: data,
    search,
  });

  useEffect(() => {
    setForm(initConfirmTripRatesModalForm);
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="xl" centered={loading || !(filteredRows || []).length}>
      <LoadingBlur loading={loading} />
      <Modal.Body className="ConfirmTripRates {text-transform:uppercase!;font-size:0.8rem!;py:0!;text-align:right!;line-height:0.9rem;}_.input-group-text,label">
        <Modal.Title className="border:none!">
          <Badge className="position:absolute mt:-0.5rem scale(0.9) rounded bg-black bg-opacity-75">
            <i className="fa fa-2x fa-location-dot scale(0.75)" />
          </Badge>
          <div className="ml:3.5rem w:calc(100%-3.5rem) border-bottom:1px|solid|#888!">Trips</div>
        </Modal.Title>
        <Row className="pt-4 pb-3">
          <Col className="d-flex {margin-left:0.5rem;}>*+*">
            {totalCount > 1 && <Form.Control name="search" onChange={onChange} value={search || ''} placeholder="Search" autoFocus />}
          </Col>
          <Col className="d-flex justify-content-end {margin-left:0.5rem;}>*+*">
            {selected?.length > 0 && (
              <Button variant="gray" onClick={onSubmit}>
                Save as Copy
              </Button>
            )}
            <Button variant="success" onClick={onSubmit} disabled={selected?.length > 0}>
              <i className="fa fa-check" />
            </Button>
            <Button variant="danger" onClick={onHide}>
              <i className="fa fa-times" />
            </Button>
          </Col>
        </Row>
        <Row>
          <span className="my-3">{`${
            totalCount === 1 ? 'The below trip will be affected' : 'Optionally select trips below to exclude'
          }:`}</span>
          <Col>
            <VirtualTable
              className={'{max-height:50vh;}'}
              header={{
                flightNumber: 'FLT',
                scheduledDate: 'DATE',
                scheduledTime: 'TIME',
                pilots: 'PLT',
                attendants: 'FA',
                airportCode: 'CITY',
                servicerIataAirlineCode: 'AL',
                puLocation: { name: 'P/U' },
                doLocation: { name: 'D/O' },
              }}
              data={filteredRows}
              selected={selected}
              locale={{
                Select: 'Exclude',
                Deselect: 'Include',
                'Select All': 'Exclude All',
                'Deselect All': 'Include All',
              }}
              rowRenderer={({
                index,
                data: { _type, ...data } = {},
                context = {},
              }: {
                index: any;
                data: any;
                context: any;
              }): JSX.Element => (
                <VirtualTableRow
                  context={{
                    ...context,
                    rowType: _type,
                    data,
                    index,
                    selected: _type === 'header' ? selected.length === totalCount : selected.includes(data?.id),
                  }}
                  className={getClasses(selected.includes(data?.id) ? 'selected' : '')}
                >
                  {totalCount > 1 && <SelectCell onClick={onSelect} />}
                  <DynamicCell
                    selector="scheduledDate"
                    className="text-center"
                    width="calc(100% / 9)"
                    render={({ data }: { data: any }): string => (data?.scheduled ? new Datetime(data?.scheduled).frontendDate : '')}
                  />
                  <DynamicCell
                    selector="scheduledTime"
                    className="text-center"
                    width="calc(100% / 9)"
                    render={({ data }: { data: any }): string => (data?.scheduled ? new Datetime(data?.scheduled).time : '')}
                  />
                  <DynamicCell selector="airportCode" className="text-center" width="calc(50% / 9)" />
                  <DynamicCell selector="servicerIataAirlineCode" className="text-center" width="calc(50% / 9)" />
                  <DynamicCell selector="flightNumber" className="text-center" width="calc(100% / 9)" />
                  <DynamicCell selector="pilots" className="text-center" width="calc(50% / 9)" />
                  <DynamicCell selector="attendants" className="text-center" width="calc(50% / 9)" />
                  <DynamicCell selector="puLocation.name" className="text-center" width="calc(200% / 9)" />
                  <DynamicCell selector="doLocation.name" className="text-center" width="calc(200% / 9)" />
                </VirtualTableRow>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <span>
              {(totalCount || 0) - (selected?.length || 0) < 0 ? 0 : (totalCount || 0) - (selected?.length || 0)} of {totalCount || 0} Trips
              will be affected.
            </span>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmTripRatesModal;

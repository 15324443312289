import './styles.scss';

import { InfoWindow, Marker, MarkerProps } from '@react-google-maps/api';
import React, { useState } from 'react';

import Styles from '../../models/Styles';

const LOCATOR_PIN_PATH =
  'm0 0c-22 0-39 18-39 39 0 16 21 54 33 73 4 7 7 11 7 11 0 0 3-4 7-11 11-19 33-57 33-73 0-22-18-39-39-39zm0 57c-10 0-18-8-18-18s8-18 18-18 18 8 18 18-8 18-18 18z';

type MapMarkerProps = { color?: string } & MarkerProps;
const MapMarker = ({ icon, color = Styles.VARIANT_MAPPING['primary'], ...props }: MapMarkerProps): JSX.Element => {
  const displayIcon =
    typeof icon === 'string'
      ? { url: icon }
      : {
          path: LOCATOR_PIN_PATH,
          fillOpacity: 0.95,
          fillColor: color,
          strokeColor: color,
          scale: 0.265,
          anchor: { x: 2, y: 125 } as google.maps.Point,
          ...icon,
        };
  return <Marker {...props} icon={displayIcon} />;
};

const MapMarkerWithInfoWindow = ({ children, onClick, ...props }: MapMarkerProps): React.JSX.Element => {
  // Init State
  const [state, setState] = useState({ showInfoWindow: false });
  const { showInfoWindow } = state;

  // State Handlers
  const onOpenInfoWindow = (): void => setState({ showInfoWindow: true });
  const onCloseInfoWindow = (): void => setState({ showInfoWindow: false });

  //Event Handlers
  const handleOnClick = (e: google.maps.MapMouseEvent): void => {
    onOpenInfoWindow();
    if (onClick) onClick(e);
  };

  return (
    <MapMarker {...props} onClick={handleOnClick}>
      {showInfoWindow && (
        <InfoWindow onCloseClick={onCloseInfoWindow}>
          <div className="InfoWindow">{children}</div>
        </InfoWindow>
      )}
    </MapMarker>
  );
};
export default MapMarker;
export { MapMarkerWithInfoWindow };

import { properCase } from './strings';

const chartTypes = [
  'line',
  'bar',
  'pie',
  //   'scatter',
  //   'radar',
  //   'map',
  //   'tree',
  //   'treemap',
  //   'graph',
  //   'gauge',
  //   'funnel',
  //   'parallel',
  //   'sankey',
  //   'boxplot',
  //   'candlestick',
  //   'effectScatter',
  //   'lines',
  //   'heatmap',
  //   'pictorialBar',
  //   'themeRiver',
  //   'sunburst',
  //   'custom',
] as const;
export type ChartTypes = (typeof chartTypes)[number];

export const ChartTypesObject: { [key in ChartTypes]: { value: string } } = chartTypes.reduce(
  (acc, type) => {
    acc[type] = { value: properCase(type) };
    return acc;
  },
  {} as { [key in ChartTypes]: { value: string } }
);

export type DefaultChartProps = {
  xAxis: string;
  yAxis: string;
  data: any[];
  formatTooltip?: (value: any, row: any) => string;
  loading?: boolean;
};

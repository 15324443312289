import { FilterOption } from '@/models/gen/graphql';
import { GetClientsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetClientsGraphApiResponse = GraphApiResponse<typeof GetClientsDocument>;

const getClientsResponseSelector = (res: GetClientsGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.clients as Array<FilterOption>;

const [runGetClients] = graphApi(GetClientsDocument, {
  onError: (res: GetClientsGraphApiResponse): void => handleError(res, { notification: { title: 'Get Clients' } }),
});

export const getClients = async (): Promise<Array<FilterOption>> => {
  const res = await runGetClients();
  return getClientsResponseSelector(res);
};

export const useGetClients = createGraphApiHook(getClients);

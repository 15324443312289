import './styles.scss';

import Masonry from 'react-masonry-css';
import React from 'react';
import useClassNames from '../../hooks/useClassNames';

type VerticalProps = {
  cols?: number;
  className?: string;
  children: any;
  options?: {
    column?: {
      className?: string;
    };
  };
};

const Vertical = ({ cols, className, children, options }: VerticalProps): JSX.Element => {
  const classNames = useClassNames('my-masonry-grid', className);
  const columnClassNames = useClassNames('my-masonry-grid_column', options?.column?.className);
  return (
    <Masonry breakpointCols={cols || 1} className={classNames} columnClassName={columnClassNames}>
      {children || <p className="d-flex justify-content-center">No Content</p>}
    </Masonry>
  );
};

export default Vertical;

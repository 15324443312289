// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditBucketsModal {
  color: var(--bs-primary);
}
.EditBucketsModal .FormField .badge {
  --bs-badge-padding-x: 0.5rem;
  --bs-badge-padding-y: 0.5rem;
  --bs-badge-font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}
.EditBucketsModal .FormField .form-control {
  color: var(--bs-primary);
}
.EditBucketsModal .FormField .form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.25rem rgba(27, 62, 111, 0.25);
}
.EditBucketsModal .ant-picker.ant-picker-range {
  border-bottom: 1px solid var(--bs-gray-subtle) !important;
  border-radius: 0 !important;
}
.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input {
  margin-bottom: 0.25rem;
}
.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input > input {
  padding: 0.35rem;
  color: var(--bs-primary);
}
.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input.ant-picker-input-active {
  border: 1px solid var(--bs-gray-subtle);
}
.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input.ant-picker-input-active > input {
  color: var(--bs-secondary);
}
.EditBucketsModal .ant-picker.ant-picker-focused.ant-picker-outlined:focus-within {
  box-shadow: none;
}
.EditBucketsModal .ant-picker .ant-picker-active-bar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Reports/DriveTime/styles.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AACI;EACE,4BAAA;EACA,4BAAA;EACA,0BAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,cAAA;AAAN;AAEI;EACE,wBAAA;AAAN;AACM;EACE,+BAAA;EACA,iDAAA;AACR;AAKI;EACE,yDAAA;EACA,2BAAA;AAHN;AAKM;EACE,sBAAA;AAHR;AAIQ;EACE,gBAAA;EACA,wBAAA;AAFV;AAKQ;EACE,uCAAA;AAHV;AAIU;EACE,0BAAA;AAFZ;AASM;EACE,gBAAA;AAPR;AAWI;EACE,aAAA;AATN","sourcesContent":[".EditBucketsModal {\n  color: var(--bs-primary);\n}\n.EditBucketsModal .FormField .badge {\n  --bs-badge-padding-x: 0.5rem;\n  --bs-badge-padding-y: 0.5rem;\n  --bs-badge-font-size: 1rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 1.5rem;\n  height: 1.5rem;\n}\n.EditBucketsModal .FormField .form-control {\n  color: var(--bs-primary);\n}\n.EditBucketsModal .FormField .form-control:focus {\n  border-color: var(--bs-primary);\n  box-shadow: 0 0 0 0.25rem rgba(27, 62, 111, 0.25);\n}\n.EditBucketsModal .ant-picker.ant-picker-range {\n  border-bottom: 1px solid var(--bs-gray-subtle) !important;\n  border-radius: 0 !important;\n}\n.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input {\n  margin-bottom: 0.25rem;\n}\n.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input > input {\n  padding: 0.35rem;\n  color: var(--bs-primary);\n}\n.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input.ant-picker-input-active {\n  border: 1px solid var(--bs-gray-subtle);\n}\n.EditBucketsModal .ant-picker.ant-picker-range > .ant-picker-input.ant-picker-input-active > input {\n  color: var(--bs-secondary);\n}\n.EditBucketsModal .ant-picker.ant-picker-focused.ant-picker-outlined:focus-within {\n  box-shadow: none;\n}\n.EditBucketsModal .ant-picker .ant-picker-active-bar {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

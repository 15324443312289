// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hotkey-Table + .Hotkey-Table {
  margin-top: 3rem;
}
.Hotkey-Table .Hotkey-Table-Header {
  background-color: #d9d9d9;
  border-radius: 10px 10px 0 0;
}
.Hotkey-Table .Hotkey-Table-Body {
  -webkit-box-shadow: 0px 10px 15px 0px rgba(194, 194, 194, 0.5);
  -moz-box-shadow: 0px 10px 15px 0px rgba(194, 194, 194, 0.5);
  box-shadow: 0px 10px 15px 0px rgba(194, 194, 194, 0.5);
  border-radius: 0 0 5px 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/HotkeysModal/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,4BAAA;AAAJ;AAEE;EACE,8DAAA;EACA,2DAAA;EACA,sDAAA;EACA,0BAAA;AAAJ","sourcesContent":[".Hotkey-Table + .Hotkey-Table {\n  margin-top: 3rem;\n}\n.Hotkey-Table .Hotkey-Table-Header {\n  background-color: #d9d9d9;\n  border-radius: 10px 10px 0 0;\n}\n.Hotkey-Table .Hotkey-Table-Body {\n  -webkit-box-shadow: 0px 10px 15px 0px rgba(194, 194, 194, 0.5);\n  -moz-box-shadow: 0px 10px 15px 0px rgba(194, 194, 194, 0.5);\n  box-shadow: 0px 10px 15px 0px rgba(194, 194, 194, 0.5);\n  border-radius: 0 0 5px 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

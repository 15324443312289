import './styles.scss';

import { Button, Modal } from 'react-bootstrap';
import { ConnectionDetails, Datetime, getClasses, handleError, titleCase, zeroPadFlightNumber } from '@/utils';
import { DynamicCell, RowContext } from '@/components/VirtualTable';
import { ReactNode, useContext, useState } from 'react';
import {
  RunAssignDriverInput,
  RunAttemptAssignDriverAndCombineTripsInput,
  RunGetDriverEstimatedDriveTimeInput,
  Trip,
  TripAndCombineType,
  TripCombineTypeEnum,
} from '@/models/gen/graphql';

import { AssignDriverDropdown } from '@/components/DriverDropdown';
import HasPermission from '../../../../components/HasPermission';
import { ILLEGAL_COMBINES } from '../../../../constants';
import ImageDisplay from '@/components/ImageDisplay';
import Tippy from '@tippyjs/react';
import TippyWhen from '@/components/TippyWhen';
import { getDriverById } from '@/api/services/users/searchUsers';
import runAssignDriver from '@/api/services/trips/runAssignDriver';
import runAttemptAssignDriverAndCombineTrips from '@/api/services/trips/runAttemptAssignDriverAndCombineTrips';
import runGetDriverEstimatedDriveTime from '@/api/services/trips/runGetDriverEstimatedDriveTime';
import runUnassignDriver from '@/api/services/trips/runUnassignDriver';
import useConfirmation from '@/hooks/useConfirmation';

const AssignDriverDropdownCell = ({ refetch, ...props }: { refetch: () => Promise<any> } & Record<string, any>): React.JSX.Element => {
  const [value, data, handleChange, loading] = useAssignDriverLogic(refetch);
  const hasWarning = !!data?.driveTimeWarning || ILLEGAL_COMBINES.includes(data?.combineType);
  return (
    <DynamicCell
      {...props}
      className={getClasses('text-center', !data?.driver ? 'UNASSIGNED' : undefined)}
      render={({ data }: { data: Trip & { driveTimeWarning?: string } }): string | JSX.Element => (
        <TippyWhen
          isTrue={!!data?.driver}
          options={{
            content: (
              <div className="d-flex">
                <ImageDisplay className="{width:50px!;height:50px!;margin-right:.7rem;}" src={data?.driver?.avatar} />
                <div>
                  <div>{`${data?.driver?.firstName || '--'} ${data?.driver?.lastName || '--'}`}</div>
                  <div>{`${data?.driver?.employeeId || '--'}`}</div>
                  <div>{`${(data?.driver?.cityName || '--').toUpperCase()}`}</div>
                </div>
              </div>
            ),
          }}
        >
          <div>
            <AssignDriverDropdown
              name="driver"
              className="AssignDriverDropdownCell text-center"
              value={value}
              airportCode={data?.airportCode}
              scheduled={data?.scheduled}
              actual={data?.trackFlight?.actual}
              details={data?.driver}
              onChange={handleChange}
              options={{
                loading,
                disabled: loading,
                lazyLoadItems: true,
                showRefreshButton: false,
                showChevron: false,
                showClearButton: false,
                locale: { 'Select...': `${data?.driver?.firstName || ''} ${data?.driver?.lastName || ''}`.trim() || '--' },
                autoSelect: false,
                indicators: (
                  <>
                    {!loading && hasWarning ? (
                      <Tippy
                        content={
                          <div className="d-flex flex-column gap-1 p-2 text-center">
                            <div className="text-danger fs-6">WARNING:</div>
                            <div>{ILLEGAL_COMBINES.includes(data?.combineType) && 'This combine is against contract and policy!'}</div>
                            <div className="fw-bold">{!!data?.driveTimeWarning && titleCase(data?.driveTimeWarning)}</div>
                          </div>
                        }
                      >
                        <i className="fa fa-warning text-danger" />
                      </Tippy>
                    ) : undefined}
                  </>
                ),
              }}
            />
          </div>
        </TippyWhen>
      )}
    />
  );
};

const useAssignDriverLogic = (
  refetch: () => Promise<void>
): [string, Trip & { driveTimeWarning?: string }, (value: string) => Promise<void>, boolean] => {
  const { data, setRows, setPriorityRows, index } = useContext(RowContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<string>(data?.driverId);

  const confirmIllegalCombines = useConfirmation({
    Header: {
      as: () => (
        <div className="d-flex w-100 justify-content-start align-items-center gap-2 border-bottom border-1 pb-3 fs-3 mb-4">
          <i className="sv sv-warning text-danger" />
          <div>ILLEGAL COMBINE - {getTripTitle(data)}</div>
        </div>
      ),
    },
    Footer: {
      as: ({ onResolve, onReject }) => (
        <Modal.Footer className="d-flex justify-content-end mt-3">
          <div>
            <Button name="REJECT" className="flex-grow-1 px-4" variant="secondary" onClick={onReject}>
              CANCEL
            </Button>
          </div>
          <div>
            <Button name="RESOLVE" className="flex-grow-1 px-4" variant="danger" onClick={onResolve}>
              DO IT ANYWAY
            </Button>
          </div>
        </Modal.Footer>
      ),
    },
  });
  const updateRow =
    (update: Partial<Trip>): ((current: ConnectionDetails<Trip>) => ConnectionDetails<Trip>) =>
    (current: ConnectionDetails<Trip>): ConnectionDetails<Trip> => {
      const combinedTripIds: string[] =
        (update['lastKnownTripsIds']?.length ? update['lastKnownTripsIds'] : current['lastKnownTripsIds']) || [];

      const rows = [...(current?.rows || [])];
      const idsToUpdate = new Set([data.id, ...combinedTripIds]);

      let updated = 0;
      // Iterate over rows only once
      for (let i = 0; i < rows.length; i++) {
        if (idsToUpdate.has(rows[i].id)) {
          rows[i] = { ...rows[i], ...update };
          updated++;
        }
        // Break the loop if all necessary updates have been made
        if (updated === idsToUpdate?.size) {
          break;
        }
      }

      return { ...current, rows };
    };
  const setContextRows = (update: Partial<Trip> & { driveTimeWarning?: string }): void => {
    setRows(updateRow(update));
    if (setPriorityRows) setPriorityRows(updateRow(update));
  };

  const setIllegalCombineMessage = (illegalCombineTrips: Trip[]): ReactNode => {
    return (
      <>
        <div>This combine is against contract and policy! Driver already has the following pickups:</div>
        <div className="overflow-auto pt-2 {max-height:15rem}">
          {illegalCombineTrips.map(
            (trip: Trip, index: number): React.JSX.Element => (
              <div className="mb-2" key={index}>
                {trip?.puLocation?.name} - <strong className="fs-5">{`${getTripTitle(trip)}`}</strong>
              </div>
            )
          )}
        </div>
      </>
    );
  };

  const handleChange = async (value: string): Promise<void> => {
    try {
      const result: Pick<Trip, 'driverId' | 'driver' | 'vehicle' | 'vehicleId' | 'combineType'> = {
        driverId: value,
        driver: undefined,
        vehicle: undefined,
        vehicleId: undefined,
        combineType: data?.combineType ?? null,
      };
      setLoading(true);
      if (value !== undefined && value !== data?.driverId) {
        setLocalValue(value);

        if (HasPermission.check('allowAssignAndAutoCombine')) {
          const runAttemptAssignDriverAndCombineTripsInput: RunAttemptAssignDriverAndCombineTripsInput = {
            providerId: data?.providerId,
            tripId: data?.id,
            scheduled: data?.scheduled,
            driverId: value,
            pilots: data?.pilots,
            attendants: data?.attendants,
            puLocationId: data?.puLocationId,
            doLocationId: data?.doLocationId,
          };

          // assign driver with illegal combines flow
          try {
            const res = await runAttemptAssignDriverAndCombineTrips(runAttemptAssignDriverAndCombineTripsInput);
            result.vehicle = res?.vehicle || null;
            result.vehicleId = res?.vehicle?.id || null;
            // if there were any trips that were auto combined with no illegal combines error
            if (res?.output?.length) {
              const tripIds = res.output
                .flatMap((combine: TripAndCombineType): Trip[] => combine.trips || [])
                .map(({ id }: Trip): string => id);
              result['lastKnownTripsIds'] = tripIds;
              result.combineType = res?.appliedCombineType || null;
            }
          } catch (err) {
            // custom illegal combines error
            const illegalCombines: TripAndCombineType[] = err?.illegalCombines || [];
            const combineType: TripCombineTypeEnum = err?.combineType || null;

            if (!illegalCombines?.length) throw err; // this is a gql error
            const trips = illegalCombines?.flatMap((combine: TripAndCombineType): Trip[] => combine.trips || []);
            await confirmIllegalCombines({
              Body: {
                as: () => setIllegalCombineMessage(trips),
              },
            });
            const tripIds = trips.map(({ id }: Trip): string => id);
            const runAssignDriverWithCombinesInput: RunAssignDriverInput = {
              tripId: data?.id,
              scheduled: data?.scheduled,
              driverId: value,
              combines: [{ tripIds, combineType }],
            };
            const res = await runAssignDriver(runAssignDriverWithCombinesInput);
            result.vehicle = res?.vehicle || null;
            result.vehicleId = res?.vehicle?.id || null;
            result.combineType = combineType;
            result['lastKnownTripsIds'] = tripIds;
          }
        } else {
          // assign driver flow
          const runAssignDriverInput: RunAssignDriverInput = {
            tripId: data?.id,
            scheduled: data?.scheduled,
            driverId: value,
          };
          const res = await runAssignDriver(runAssignDriverInput);
          result.vehicle = res?.vehicle || null;
          result.vehicleId = res?.vehicle?.id || null;
        }

        const driver = await getDriverById(value);
        result.driver = driver;
        if (!driver) throw new Error('Failed to get driver details. Try again.');

        const allowGetDriverEstimatedDriveTime = HasPermission.check('allowGetDriverEstimatedDriveTime');
        setLoading(allowGetDriverEstimatedDriveTime);

        if (HasPermission.check('allowGetDriverEstimatedDriveTime')) {
          // bad scheduling flow
          const runGetDriverEstimatedDriveTimeInput: RunGetDriverEstimatedDriveTimeInput = {
            scheduled: data?.scheduled,
            toLocationId: data?.puLocationId,
            tripId: data?.id,
            driverId: value,
          };
          runGetDriverEstimatedDriveTime(runGetDriverEstimatedDriveTimeInput).then((res) => {
            if (!res?.warning) return res;
            setContextRows({ driveTimeWarning: res.warning });
          });
        }
        setContextRows({ ...result });
        setLoading(false);
      } else {
        runUnassignDriver(data?.id)
          .then((res) => {
            setContextRows({
              driverId: null,
              driver: null,
              vehicleId: null,
              vehicle: null,
              driveTimeWarning: null,
              combineType: null,
              combineId: null,
            });
            setLoading(false);
          })
          .catch((err): never => {
            throw err;
          });
      }
    } catch (err) {
      setLocalValue(data?.driverId);
      handleError(err, {
        notification: { title: value !== undefined && value !== data?.driverId ? 'Assign Driver' : 'Unassign Driver' },
      });
      setLoading(false);
    }
  };

  return [localValue, data, handleChange, loading];
};

export default AssignDriverDropdownCell;

const getTripTitle = (data: Trip): string =>
  `${(data?.servicerIataAirlineCode || '').toUpperCase()}${zeroPadFlightNumber(data?.flightNumber)} ${
    data?.scheduled ? new Datetime(data?.scheduled).frontendDatetimeShort : ''
  }`;

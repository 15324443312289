import React, { createContext, useContext, useEffect, useState } from 'react';

import { stringify } from '../../utils/objects';

const initUseDragAndDropState = {
  list: [],
  id: 'id',
  dragging: undefined,
  dropped: undefined,
  update: undefined,
};
const DragAndDropContext = createContext({ list: [] });
const useDragAndDropContext = () => useContext(DragAndDropContext);
const DragAndDrop = ({
  list: initialList,
  id: initialId,
  name,
  replaceProperties,
  onChange,
  as: RenderData = DefaultRenderComponent,
  ...props
}) => {
  const [state, setState] = useState({ ...initUseDragAndDropState, list: initialList, id: initialId });
  const { list, id, dragging, dropped, update } = state;

  const handleDrag = (index) => (event) => {
    event.preventDefault();
    setState((current) => ({ ...current, dragging: current.list[index] }));
  };
  const handleDrop = (index) => async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setState((current) => {
      const update = JSON.parse(JSON.stringify(current.list));
      const moving = current.dragging || {};
      const replacing = current.list[index] || {};
      let oldIndex = (current.list || []).filter((item) => !!item).findIndex((item) => item[id] === moving[id]);
      let newIndex = (current.list || []).filter((item) => !!item).findIndex((item) => item[id] === replacing[id]);

      if (replaceProperties) {
        const reverse = oldIndex > newIndex;
        if (reverse) {
          oldIndex = update.length - oldIndex - 1;
          newIndex = update.length - newIndex - 1;
          update.reverse();
        }
        let currClone = { ...update[newIndex] };
        for (let i = newIndex; i > oldIndex; i--) {
          const nextClone = { ...update[i - 1] };
          replaceProperties.forEach((key) => (update[i - 1][key] = currClone[key]));
          currClone = { ...nextClone };
          if (i === oldIndex + 1) {
            replaceProperties.forEach((key) => (update[newIndex][key] = moving[key]));
            break;
          }
        }
        if (reverse) update.reverse();
      } else {
        update.splice(newIndex, 0, update.splice(oldIndex, 1)[0]);
      }
      return { ...current, list: update, dragging: undefined, dropped: true, update };
    });
  };
  const onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (!stringify.compare(initialList, list)) setState((current) => ({ ...current, list: initialList }));
    if (!stringify.compare(initialId, id)) setState((current) => ({ ...current, id: initialId }));
  }, [initialList, initialId]);

  useEffect(() => {
    if (dropped) {
      onChange({ target: { name, value: update } });
      setState((current) => ({ ...current, dropped: false }));
    }
  }, [dropped]);

  return (
    <DragAndDropContext.Provider value={{ list: state?.list }}>
      <div className={`DragAndDropZone ${props.className}`} onDrop={handleDrop(0)} onDragOver={onDragOver}>
        {list.map((item, i) => (
          <div onDrag={handleDrag(i)} onDrop={handleDrop(i)} draggable={true} key={i}>
            <RenderData data={item} index={i} dragging={dragging === i} {...props} />
          </div>
        ))}
      </div>
    </DragAndDropContext.Provider>
  );
};

const DefaultRenderComponent = ({ data, ...props }) => <pre {...props}>{JSON.stringify(data)}</pre>;

export default React.memo(DragAndDrop);
export { useDragAndDropContext };

import React from 'react';
import { getClasses } from '../../utils/strings';

const combineAlignments =
  (Vertical: (a: any) => JSX.Element, Horizontal: (a: any) => JSX.Element): ((a: any) => JSX.Element) =>
  ({ children, ...props }: any): JSX.Element => (
    <Vertical {...props}>
      <Horizontal {...props}>{children}</Horizontal>
    </Vertical>
  );

const Top = (props: any): JSX.Element => (
  <div {...props} className={getClasses('h-100 d-flex flex-column justify-content-start flex-grow-1', props?.className)} />
);
const Middle = (props: any): JSX.Element => (
  <div {...props} className={getClasses('h-100 d-flex flex-column justify-content-center flex-grow-1', props?.className)} />
);
const Bottom = (props: any): JSX.Element => (
  <div {...props} className={getClasses('h-100 d-flex flex-column justify-content-end flex-grow-1', props?.className)} />
);
const Left = (props: any): JSX.Element => <div {...props} className={getClasses('w-100 d-flex justify-content-start', props?.className)} />;
const Center = (props: any): JSX.Element => (
  <div {...props} className={getClasses('w-100 d-flex justify-content-center', props?.className)} />
);
const Right = (props: any): JSX.Element => <div {...props} className={getClasses('w-100 d-flex justify-content-end', props?.className)} />;

Top.Left = combineAlignments(Top, Left);
Top.Center = combineAlignments(Top, Center);
Top.Right = combineAlignments(Top, Right);
Middle.Left = combineAlignments(Middle, Left);
Middle.Center = combineAlignments(Middle, Center);
Middle.Right = combineAlignments(Middle, Right);
Bottom.Left = combineAlignments(Bottom, Left);
Bottom.Center = combineAlignments(Bottom, Center);
Bottom.Right = combineAlignments(Bottom, Right);
Left.Top = combineAlignments(Left, Top);
Center.Top = combineAlignments(Center, Top);
Right.Top = combineAlignments(Right, Top);
Left.Middle = combineAlignments(Left, Middle);
Center.Middle = combineAlignments(Center, Middle);
Right.Middle = combineAlignments(Right, Middle);
Left.Bottom = combineAlignments(Left, Bottom);
Center.Bottom = combineAlignments(Center, Bottom);
Right.Bottom = combineAlignments(Right, Bottom);

export default { Top, Middle, Bottom, Left, Center, Right };
export { Top, Middle, Bottom, Left, Center, Right };

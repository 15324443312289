import gql from 'graphql-tag';

export const GET_AVAILABILITY_TYPES = gql`
  query GetAvailabilityTypes {
    getFilters {
      filters {
        availabilityTypes {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_AIRPORTS = gql`
  query GetAirports {
    getFilters {
      filters {
        airports {
          id
          displayName
          values
        }
      }
    }
  }
`;
export const GET_AIRPORT_GROUPS = gql`
  query GetAirportGroups {
    getFilters {
      filters {
        airportGroups {
          id
          displayName
          values
        }
      }
    }
  }
`;
export const GET_STATES = gql`
  query GetStates {
    getFilters {
      filters {
        states {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_FLAGS = gql`
  query GetFlags {
    getFilters {
      filters {
        flag {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_STATUSES = gql`
  query GetStatuses {
    getFilters {
      filters {
        status {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_TYPES = gql`
  query GetTypes {
    getFilters {
      filters {
        type {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_KINDS = gql`
  query GetKinds {
    getFilters {
      filters {
        kind {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_IMPORT_NAMES = gql`
  query GetImportNames {
    getFilters {
      filters {
        imports {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_LOCATIONS = gql`
  query GetLocations {
    getFilters {
      filters {
        locations {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_EXCHANGE_LOCATIONS = gql`
  query GetExchangeLocations {
    getFilters {
      filters {
        exchangeLocations {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_VEHICLE_TRACKER = gql`
  query GetVehicleTracker {
    getFilters {
      filters {
        vehicleTracker {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_COMPANIES = gql`
  query GetCompanies {
    getFilters {
      filters {
        companies {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_ROLES = gql`
  query GetRoles {
    getFilters {
      filters {
        roles {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_GROUPS = gql`
  query GetGroups {
    getFilters {
      filters {
        groups {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_LICENSE_TYPES = gql`
  query GetLicenseTypes {
    getFilters {
      filters {
        licenseType {
          id
          displayName
        }
      }
    }
  }
`;
export const GET_LINE_ITEM_TYPES = gql`
  query GetFilters {
    getFilters {
      filters {
        lineItemType {
          displayName
          id
        }
      }
    }
  }
`;
export const GET_RATES = gql`
  query SearchRates($input: RateConnectionInput) {
    searchRates {
      rateConnection(input: $input) {
        edges {
          cursor
          node {
            id
            name
            rate
          }
        }
      }
    }
  }
`;

import React from 'react';
import SelectFromEnum from '../SelectFromEnum';
import { TripTypeEnum } from '../../models/gen/graphql';

const SelectType = (props): React.JSX.Element => (
  <SelectFromEnum
    name="type"
    placeholder="All Types"
    source={TripTypeEnum}
    getLabel={(key: string, value: string): string => value}
    {...props}
  />
);

export default SelectType;

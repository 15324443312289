import './styles.scss';

import { Button, ButtonProps } from 'react-bootstrap';
import ImageDisplay, { ImageDisplayProps } from '.';

import React from 'react';

type ThumbnailProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonProps['variant'];
} & ImageDisplayProps;

const Thumbnail = ({ onClick, variant = 'light', ...props }: ThumbnailProps): React.JSX.Element => (
  <div className="Thumbnail">
    <ImageDisplay {...props} />
    {onClick && (
      <Button onClick={onClick} variant={variant} className="rounded-circle">
        <i className="sv sv-cross" />
      </Button>
    )}
  </div>
);

export default Thumbnail;

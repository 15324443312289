import GenericChart from '@/components/GenericChart';
import { remToPixels } from '@/utils';
import { DefaultChartProps } from '@/utils/charts';
import { SeriesOption } from 'echarts';
import React, { useEffect, useRef } from 'react';

type BarChartProps = DefaultChartProps;

const BarChart = ({ xAxis, yAxis, data, formatTooltip, loading, ...props }: BarChartProps) => {
  const chartRef = useRef(null);
  const yAxisType = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const instance = chartRef.current.getEchartsInstance();
      loading ? instance?.showLoading() : instance?.hideLoading();
    }
  }, [chartRef, loading]);

  const xAxisData = Object.keys(data);
  const seriesData: Record<string, number[]> = {};
  const originalData: Record<string, any[]> = {};

  xAxisData.forEach((parentGroup) => {
    originalData[parentGroup] = data[parentGroup];
    data[parentGroup].forEach((childValues, index) => {
      const seriesKey = `Child${index + 1}`;
      const value = parseFloat(childValues[yAxis]);
      if (!seriesData[seriesKey]) {
        seriesData[seriesKey] = [];
      }
      seriesData[seriesKey].push(value);
    });
  });

  const xAxisConfig = {
    type: 'category',
    data: xAxisData,
  };
  const series: SeriesOption[] = Object.keys(seriesData).map((key) => ({
    type: 'bar',
    name: key,
    data: seriesData[key],
    // TODO: Figure out a way to handle childData that has differing levels. I.E. the childData index is 2 - 1 = 1, but the total of children is 1 (index = 0)
    // so it is considered undefined...
    tooltip: {
      formatter: (params: any) => {
        const parentGroup = xAxisData[params.dataIndex];
        const childData = originalData[parentGroup][parseInt(key.replace('Child', '')) - 1];
        return formatTooltip && childData ? formatTooltip(params, childData) : `${params.name}: ${params.value}`;
      },
    },
  }));
  return (
    <GenericChart
      noData={data?.length === 0 || !yAxis || !xAxis}
      ref={chartRef}
      option={{
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        xAxis: xAxisConfig,
        yAxis: { type: yAxisType.current || 'value' },
        tooltip: {
          trigger: 'item',
          textStyle: { fontSize: remToPixels(0.75) },
        },
        series,
      }}
      {...props}
    />
  );
};

export default BarChart;

import { CreateAirportHasVehicleBulkInput, CreateAirportHasVehicleInput } from '../../models/gen/graphql';
import { customCreateHandler, validateObj } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateCreateAirportHasVehicle = validateObj({
  airportCode: (val: string): string => val || '',
  vehicleId: (val: string): string => val || '',
});

const createAirportHasVehicleBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...vehicles: CreateAirportHasVehicleInput[]): Promise<any> => {
      const input: CreateAirportHasVehicleBulkInput = {
        values: vehicles.map(validateCreateAirportHasVehicle),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.createAirportHasVehicleBulk?.output || [],
  handler: customCreateHandler('Assign Vehicle to Airport', 'createAirportHasVehicleBulk'),
  query: gql`
    mutation CreateAirportHasVehicleBulk($input: CreateAirportHasVehicleBulkInput!) {
      createAirportHasVehicleBulk(input: $input) {
        output {
          error
          node {
            airportCode
            vehicleId
          }
        }
      }
    }
  `,
};

export default createAirportHasVehicleBulk;
export { validateCreateAirportHasVehicle };

import { InvoiceInput, InvoiceResponse, RunSaveAsInvoiceInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { RunSaveAsInvoiceDocument } from '../../queries';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type RunSaveAsInvoiceGraphApiResponse = GraphApiResponse<typeof RunSaveAsInvoiceDocument>;

const title = 'Save New Invoice';

const runSaveAsInvoiceResponseSelector = (res: RunSaveAsInvoiceGraphApiResponse): InvoiceResponse =>
  !res.errors ? (res?.runSaveAsInvoice?.output as InvoiceResponse) : undefined;

const [runRunSaveAsInvoice] = graphApi(RunSaveAsInvoiceDocument, {
  onError: (res: RunSaveAsInvoiceGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const runSaveAsInvoice = async (invoice: InvoiceInput): Promise<InvoiceResponse> => {
  const input: RunSaveAsInvoiceInput = { input: invoice };
  const res = await runRunSaveAsInvoice({ input });

  const selected = runSaveAsInvoiceResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useRunSaveAsInvoice = createGraphApiHook(runSaveAsInvoice);

export default runSaveAsInvoice;

import { DATETIME_FE_FORMAT, DATE_FE_FORMAT, MONTH_END, MONTH_START } from '@/constants';
import { Datetime, onEnter, queryInput } from '@/utils';
import { Invoice, InvoiceSearch, InvoiceStatusEnum } from '@/models/gen/graphql';
import React, { useEffect, useRef, useState } from 'react';
import { SEARCH_INVOICE_PAGE_SIZE, useSearchInvoices } from '@/api/services/invoices/searchInvoices';
import VirtualTable, { DynamicCell, FormatCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import { ConnectionDetails } from '@/utils/custom';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import PageInfo from '@/components/PageInfo';
import SelectFromEnum from '@/components/SelectFromEnum';
import Tippy from '@tippyjs/react';
import { getClasses } from '@/utils/strings';
import runArchiveInvoices from '@/api/services/invoices/runArchiveInvoices';
import runDownloadLatestInvoiceDocument from '@/api/services/invoices/runDownloadLatestInvoiceDocument';
import { stringify } from '@/utils/objects';
import useConfirmation from '@/hooks/useConfirmation';
import useForm from '@/hooks/useForm';
import useHotkeys from '@/hooks/useHotkeys';
import { useNavigate } from 'react-router-dom';
import usePage from '@/hooks/usePage';

interface InvoiceHistoryFilters {
  from: typeof MONTH_START;
  to: typeof MONTH_END;
  search: string;
  status: string[];
}
interface InvoiceHistoryState {
  sorting: { column: string; direction: string };
  selected: string[];
  rows: Invoice[];
}

const initInvoiceHistoryFilters: InvoiceHistoryFilters = {
  from: MONTH_START,
  to: MONTH_END,
  search: '',
  status: [InvoiceStatusEnum.Active],
};
const initInvoiceHistoryState: InvoiceHistoryState = {
  sorting: { column: undefined, direction: undefined },
  selected: [],
  rows: [],
};
const InvoiceHistoryTable = () => {
  const [filters, , setFilters] = useForm(initInvoiceHistoryFilters);
  const { search } = filters;

  const [state, setState] = useState(initInvoiceHistoryState);
  const { sorting, selected } = state;

  // init page state
  const [page, setPage] = usePage('invoiceHistory');

  // init queries
  const [{ data, loading }, { fetch: searchInvoices, refetch, fetchMore }] = useSearchInvoices();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const navigate = useNavigate();
  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows, search });

  // Hotkeys
  const searchbarRef = useRef(null);
  useHotkeys('Invoice History', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: (): any => searchbarRef.current.focus(),
    },
    'shift+ctrl+c': {
      name: 'Create Invoice',
      description: 'Navigate to Create Invoice page',
      action: (): any => createInvoice(),
    },
    'shift+ctrl+r': {
      name: 'Refresh Table',
      description: 'Refreshes the Invoice History table',
      action: (): any => refetch([lastQuery.current]),
    },
  });

  const confirmArchiveInvoices = useConfirmation({
    Body: {
      message: `Archiving ${selected?.length} invoice(s). Is this ok?`,
    },
    Footer: {
      resolve: 'Yes',
      reject: 'Cancel',
    },
  });

  const handleDownloadInvoice = async (invoiceId: number): Promise<void> => {
    const { url, error } = await runDownloadLatestInvoiceDocument(invoiceId);
    if (error) return;
    window.open(url, '_blank', 'noreferrer');
  };

  const handleArchiveInvoices = async () => {
    if (selected?.length > 0) {
      try {
        await confirmArchiveInvoices();
        const response = await runArchiveInvoices(selected);
        await refetch([lastQuery.current]);
        setState((current: InvoiceHistoryState): InvoiceHistoryState => ({ ...current, selected: [] }));
        return response;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const openInvoice = (invoiceId: number = 0): void => navigate(`/invoices/${invoiceId}`);

  const createInvoice = () => {
    window.localStorage.removeItem('invoice-preview-filters');
    openInvoice(0);
  };
  const lastQuery = useRef<InvoiceSearch>(page?.query);
  const onSubmit = async (filters) => {
    const { from, to, status } = filters;
    const query: InvoiceSearch = {
      invoiced: queryInput.date([new Datetime(from).toString(), new Datetime(to).toString()]),
      status: status?.length ? queryInput(status || []) : null,
    };
    await (stringify.compare(lastQuery.current, query) ? refetch([query]) : searchInvoices([query]));
    lastQuery.current = query;
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Invoice>> => {
    if (loading || !hasNextPage) return;
    return await fetchMore([lastQuery.current], {
      page: Math.round(after / SEARCH_INVOICE_PAGE_SIZE),
      merge: true,
    });
  };

  const onLeavePage = (): void => {
    setPage((current) => ({
      ...(current || {}),
      query: lastQuery.current,
    }));
  };

  useEffect((): (() => void) => {
    return onLeavePage;
  }, []);

  return (
    <>
      <PageInfo>
        {(filteredRows || []).length} / {totalCount} Invoices
      </PageInfo>
      <Filters
        name="invoiceHistoryTable"
        value={filters}
        onSubmit={onSubmit}
        onReset={(): void => setFilters((current: InvoiceHistoryFilters): InvoiceHistoryFilters => ({ ...current, search: '' }))}
        submitOnMount
        primary={({ values, onChange }): JSX.Element => {
          const { from, to, status } = values;
          return (
            <>
              <FormField name="daterange" type="daterange" value={[from, to]} onChange={onChange.dateRange('from', 'to')} condensed />
              <FormField
                placeholder="Status"
                name="status"
                value={status}
                onChange={onChange}
                source={InvoiceStatusEnum}
                searchable
                multiple
                options={{
                  input: {
                    as: SelectFromEnum,
                    className: 'Status',
                  },
                }}
                condensed
              />
            </>
          );
        }}
        controls={({ values, onChange }): JSX.Element => {
          const { search } = values;
          return (
            <>
              <FormField
                prepend={<i className="sv sv-magnifier fs-4" />}
                id={'filters-searchbar'}
                name="search"
                value={search || ''}
                onChange={onChange}
                onBlur={(): void => setFilters((current: InvoiceHistoryFilters): InvoiceHistoryFilters => ({ ...current, search }))}
                onKeyDown={onEnter((): void => {
                  setFilters((current: InvoiceHistoryFilters): InvoiceHistoryFilters => ({ ...current, search }));
                })}
                placeholder="Search"
                condensed
                inline
              />
            </>
          );
        }}
        alternate={(): JSX.Element => {
          return (
            <>
              <FormButton
                icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
                name="create"
                variant="outline-gray"
                onClick={createInvoice}
              >
                Create Invoice
              </FormButton>
              <FormButton
                icon={<i className="sv sv-box {font-size:1.5rem;}" />}
                feedback={`Archive Invoice${selected?.length > 1 ? 's' : ''}`}
                variant="outline-gray"
                onClick={handleArchiveInvoices}
                disabled={selected?.length === 0}
                name="ARCHIVE_INVOICES"
              />
            </>
          );
        }}
      />
      <VirtualTable
        name="invoiceHistory"
        data={filteredRows}
        loading={loading}
        selected={selected}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        header={{
          id: '#',
          name: 'Name',
          invoiced: 'Inv Date',
          startDatetime: 'Start',
          endDatetime: 'End',
          provider: { displayName: 'Client' },
          airports: 'Airport',
          grandTotal: 'Amount',
          due: 'Due',
          creator: { fullName: 'Creator' },
          download: <i className="sv sv-download2 fs-4" />,
          updatedAt: 'Updated',
          documents: [{ sentAt: 'Send', sender: { fullName: 'Sent By' } }],
          createdAt: 'Created',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void => openInvoice(data?.id)}
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="id"
              placeholder="--"
              className="text-center alternate"
              width="calc(40% / 15)"
              sorting={makeSortable('id')}
            />
            <DynamicCell
              selector="name"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 10)"
              sorting={makeSortable('name')}
            />
            <FormatCell
              selector="invoiced"
              placeholder="--"
              className="text-center"
              width="calc(100% / 15)"
              sorting={makeSortable('invoiced')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <FormatCell
              selector="startDatetime"
              placeholder="--"
              className="text-center"
              width="calc(100% / 15)"
              sorting={makeSortable('startDatetime')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <FormatCell
              selector="endDatetime"
              placeholder="--"
              className="text-center"
              width="calc(100% / 15)"
              sorting={makeSortable('endDatetime')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <DynamicCell
              selector="provider.displayName"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 15)"
              sorting={makeSortable('provider.displayName')}
            />
            <DynamicCell
              selector="airports"
              placeholder="--"
              className="text-center alternate text-nowrap"
              width="calc(100% / 15)"
              sorting={makeSortable('airports')}
              render={({ value }: { value: string[] }): string => (Array.isArray(value) ? (value || []).join(', ') : value)}
            />

            <DynamicCell
              selector="grandTotal"
              placeholder="--"
              className="text-center"
              width="calc(100% / 15)"
              sorting={makeSortable('grandTotal')}
            />
            <FormatCell
              selector="due"
              placeholder="--"
              className="text-center"
              width="calc(100% / 15)"
              sorting={makeSortable('due')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <DynamicCell
              selector="creator.fullName"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 15)"
              sorting={makeSortable('creator')}
            />
            <DynamicCell
              selector="download"
              placeholder="--"
              className="text-center alternate"
              width="calc(30% / 15)"
              render={({ data }: { data: Invoice }): JSX.Element => (
                <Tippy content="Download Invoice">
                  <i className="sv sv-download2 fs-4 pointer" onClick={() => handleDownloadInvoice(data?.id)} onKeyDown={() => {}} />
                </Tippy>
              )}
            />
            <FormatCell
              selector="updatedAt"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 15)"
              sorting={makeSortable('updatedAt')}
              format={(value: string): string => new Datetime(value).format(DATETIME_FE_FORMAT)}
            />
            <DynamicCell
              selector="documents.0.sentAt"
              placeholder="--"
              className="text-center alternate"
              width="calc(50% / 15)"
              render={({ value }: { value: string }): JSX.Element => {
                // converts a datetime string that is in UTC to the local datetime string
                const sentAt = value ? new Datetime().setAsUTC(value).toLocaleDatetime().frontendDatetime : '';
                return (
                  <Tippy content={`${sentAt ? `Last sent at ${sentAt}` : 'Not Yet Sent'}`}>
                    <i className={`fa-regular fa-envelope pointer ${sentAt ? 'text-success' : 'text-gray'} {font-size:1.5rem}`}></i>
                  </Tippy>
                );
              }}
            />
            <DynamicCell
              selector="documents.0.sender.fullName"
              placeholder="--"
              className="text-center alternate"
              width="calc(50% / 12)"
              sorting={makeSortable('documents.0.sender.fullName')}
            />
            <FormatCell
              selector="createdAt"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 15)"
              sorting={makeSortable('createdAt')}
              format={(value: string): string => new Datetime(new Date(value.replace(/Z?$/, 'Z')).getTime()).format(DATETIME_FE_FORMAT)}
            />
          </VirtualTableRow>
        )}
      />
    </>
  );
};

export default InvoiceHistoryTable;

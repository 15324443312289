import React from 'react';
import './styles.scss';
import LottieReact from 'lottie-react';
import maintenance from './maintenance.json';
import not_found from './not_found.json';
import loading_spinner from './loading_spinner.json';

const anims = { maintenance, not_found, loading_spinner };

const Lottie = ({ anim }) =>
  anim && anims[anim] ? (
    <div className="lottie-anim">
      <LottieReact animationData={anims[anim]} loop={true} />
    </div>
  ) : null;

export default Lottie;

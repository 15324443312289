import { Form, FormLabel } from 'react-bootstrap';
import React, { forwardRef } from 'react';

import { FormCheckInputProps } from 'react-bootstrap/esm/FormCheckInput';
import TippyWhen from 'components/TippyWhen';

interface PermissionInputProps extends FormCheckInputProps {
  label: string;
  options?: any;
}

const PermissionInput = ({ id, label, options, value, ...props }: PermissionInputProps, ref: any): JSX.Element => {
  const { isTrue, ...tippyOptions } = options?.tippyWhen || {};

  return (
    <div className="d-flex align-items-center mx-3">
      <Form.Check {...{ ...props, id, value }} ref={ref} />
      <TippyWhen isTrue={isTrue} options={tippyOptions}>
        <FormLabel className="mb-0 fs-6 {cursor:pointer;}" htmlFor={id}>
          {label || 'unknown'}
        </FormLabel>
      </TippyWhen>
    </div>
  );
};

export default React.memo(forwardRef(PermissionInput));

import { GET_ROLES } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';
import { titleCase } from '../../utils';

const SelectRole = (props) => (
  <SelectFromQuery
    cache="Roles"
    placeholder="All Roles"
    query={GET_ROLES}
    selector={(data) => {
      return (data?.getFilters?.filters?.roles || []).map((opt) => ({
        label: titleCase(opt.displayName),
        value: opt.id,
      }));
    }}
    {...props}
  />
);

export default SelectRole;

import './styles.scss';

import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { EOD_MINUTES, TODAY, TODAY_EOD } from '@/constants';
import { Location, PunchPolicy } from '@/models/gen/graphql';
import { PunchPolicyState, usePunchPolicyContext } from '../..';
import React, { useState } from 'react';
import { createNotification, getClasses, getRangeHandleColor, getResultFromState, minutesToTime, stringify, timeToMinutes } from '@/utils';

import FormField from '@/components/FormField';
import PunchPolicySlider from '../PunchPolicySlider';
import PunchPolicyTimeControls from '../PunchPolicyTimeControls';
import Tippy from '@tippyjs/react';
import TippyWhen from '@/components/TippyWhen';
import { Toast } from '@/models';
import useLocale from '@/hooks/useLocale';

type PunchPolicyDetailsProps = {
  id: string;
  name: string;
  policies: PunchPolicy[];
  isSpecialEvent?: boolean;
  locale?: Record<string, string>;
};
type PunchPolicyDetailsState = { show: boolean; animating: boolean };

const PunchPolicyDetails = ({ id, name, policies, isSpecialEvent = false }: PunchPolicyDetailsProps): JSX.Element => {
  const [{ openLocations }, setPunchPolicyContext] = usePunchPolicyContext();
  const isOpen = openLocations?.includes(id);
  const setPolicies = (val: PunchPolicy[] | ((policies: PunchPolicy[]) => PunchPolicy[])): void =>
    setPunchPolicyContext(
      (current: PunchPolicyState): PunchPolicyState => ({
        ...current,
        locations: current?.locations?.map(
          (location: Location): Location =>
            location?.id === id ? { ...location, punchPolicies: getResultFromState(val, location?.punchPolicies || []) } : location
        ),
      })
    );
  const [state, setState] = useState<PunchPolicyDetailsState>({ show: isOpen, animating: false });
  const { show, animating } = state;

  const locale = useLocale();

  const onChange =
    (i: number): ((event: any) => void) =>
    ({ target: { name, value } }: { target: { name: string; value: string } }): void => {
      setPolicies((current: PunchPolicy[]): PunchPolicy[] => {
        const newPolicies = stringify.parse(current);
        if (name === 'date') {
          const [from, to] = (value || '').split(' - ');
          newPolicies[i].eventStartDatetime = from;
          newPolicies[i].eventEndDatetime = to;
        } else {
          newPolicies[i][name] = value;
        }
        return newPolicies.sort((a: PunchPolicy, b: PunchPolicy): number => timeToMinutes(a.startTime) - timeToMinutes(b.startTime));
      });
    };

  const onTimeInputChange =
    (index: number): ((event) => void) =>
    (event): void => {
      setPolicies((current: PunchPolicy[]): PunchPolicy[] => {
        const [startTime, endTime] = event?.target?.value || [];
        const buckets = stringify.parse(current);
        const thisPolicy = buckets?.[index] || {};
        const prevPolicy = buckets?.[index - 1];
        if (prevPolicy && thisPolicy.startTime !== startTime && timeToMinutes(prevPolicy.startTime) !== timeToMinutes(startTime) - 1) {
          prevPolicy.endTime = minutesToTime(timeToMinutes(startTime) - 1);
        }
        const nextPolicy = buckets?.[index + 1];
        if (nextPolicy && thisPolicy.endTime !== endTime && timeToMinutes(nextPolicy.startTime) !== timeToMinutes(endTime) + 1) {
          nextPolicy.startTime = minutesToTime(timeToMinutes(endTime) + 1);
        }
        thisPolicy.startTime = startTime;
        thisPolicy.endTime = endTime;
        return buckets.sort((a: PunchPolicy, b: PunchPolicy): number => timeToMinutes(a.startTime) - timeToMinutes(b.startTime));
      });
    };

  const onConfirmDelete =
    (i: number): (() => void) =>
    (): void => {
      if (policies?.length === 1) return;
      setPolicies((current: PunchPolicy[]): PunchPolicy[] => {
        const { startTime: newStartTime, endTime: newEndTime } = policies?.[i] || {};
        const newPolicies: PunchPolicy[] = stringify.parse(current);
        if (i === 0) {
          newPolicies[1].startTime = newStartTime;
        } else if (i === newPolicies.length - 1) {
          newPolicies[i - 1].endTime = newEndTime;
        } else {
          newPolicies[i - 1].endTime = newPolicies[i + 1].startTime;
        }
        return newPolicies.filter((_: PunchPolicy, index: number): boolean => index !== i);
      });
    };
  const onAddBucket = () =>
    setPolicies((current: PunchPolicy[] = []): PunchPolicy[] => {
      const newPolicy: PunchPolicy = {
        id: null,
        companyId: '',
        locationId: id,
        eventStartDatetime: isSpecialEvent ? TODAY : null,
        eventEndDatetime: isSpecialEvent ? TODAY_EOD : null,
        startTime: '00:00',
        endTime: '23:59',
        minutesIn: 0,
        minutesOut: 0,
      };
      if (!current.length) return [newPolicy];
      const [...existingPolicies] = current;

      const previousPolicy = existingPolicies.length ? existingPolicies[existingPolicies.length - 1] : null;
      if (previousPolicy) {
        const previousStartTime = timeToMinutes(previousPolicy.startTime);
        const newStartTime = previousStartTime + 15;
        const newEndTime = minutesToTime(newStartTime - 1);
        if (newStartTime >= EOD_MINUTES) {
          createNotification('Not enough time remaining to add another policy.', Toast.Type.WARNING, 'Add Bucket');
          return current;
        }
        existingPolicies[existingPolicies.length - 1] = { ...previousPolicy, endTime: newEndTime };
        newPolicy.startTime = minutesToTime(newStartTime);
        return [...existingPolicies, newPolicy];
      }
      [...existingPolicies, previousPolicy, newPolicy];
    });
  const onChangeBucket = ({ target: { value } }: { target: { value: number[] } }): void => {
    setPolicies((current: PunchPolicy[]): PunchPolicy[] => {
      const updatedStartTimes = current
        .map((policy: PunchPolicy, i: number): PunchPolicy => ({ ...policy, startTime: minutesToTime(value[i]) }))
        .sort((a: PunchPolicy, b: PunchPolicy): number => timeToMinutes(a.startTime) - timeToMinutes(b.startTime));
      return updatedStartTimes.map(
        (policy: PunchPolicy, i: number): PunchPolicy => ({
          ...policy,
          endTime: i < updatedStartTimes.length - 1 ? minutesToTime(timeToMinutes(updatedStartTimes[i + 1].startTime) - 1) : policy.endTime,
        })
      );
    });
  };
  const getLabel = (value: number): string => {
    if (value === EOD_MINUTES) return '23:59';
    if (value % 360 === 0) return minutesToTime(value);
    if (value % 180 === 0) return ' ';
    return '';
  };
  const onToggle =
    (id: string): (() => void) =>
    (): void => {
      // this code makes sure we do not set the context until the animation is complete
      if (animating) return;
      setState((current: PunchPolicyDetailsState): PunchPolicyDetailsState => ({ show: !current?.show, animating: true }));
      setTimeout(() => {
        setPunchPolicyContext(
          (current: PunchPolicyState): PunchPolicyState => ({
            ...current,
            openLocations: isOpen ? openLocations?.filter((i: string): boolean => i !== id) : [...openLocations, id],
          })
        );
      }, 250);
    };

  return (
    <div className="PunchPolicyDetails">
      <Row className="align-items-center justify-content-between">
        <Col className="d-flex">
          <Button variant="icon" className="pt-3" onClick={onToggle(id)}>
            <i className={getClasses('fa', show ? 'fa-chevron-up' : 'fa-chevron-down')} />
          </Button>
          <FormField
            name="location"
            label={<small>{'location name: '}</small>}
            value={name}
            valid={!!policies?.length}
            readOnly
            condensed
            onDoubleClick={onToggle(id)}
          />
          <TippyWhen
            isTrue={!policies?.length}
            options={{ content: 'This Location is missing policies.', className: 'punch-policy-tooltip' }}
          >
            <Button variant="icon" className="pt-3" onClick={onToggle(id)}>
              <i className={getClasses('sv sv-warning text-danger fs-5', !policies?.length ? 'opacity-100' : 'opacity-0')} />
            </Button>
          </TippyWhen>
        </Col>
      </Row>
      <Collapse in={show} unmountOnExit mountOnEnter>
        <div className="collapse-content">
          <Row>
            <Col className="flex-grow-0" />
            <Col className="flex-grow-1">
              <div className="primary-slider">
                <PunchPolicySlider
                  min={0}
                  max={EOD_MINUTES}
                  tickSize={180}
                  value={policies?.map((policy: PunchPolicy): number => timeToMinutes(policy.startTime))}
                  tickLabel={getLabel}
                  segmentTooltips={policies?.map((policy: PunchPolicy): string => `${policy.minutesIn} | ${policy.minutesOut}`)}
                  handleTooltip={(value: number): string => minutesToTime(value)}
                  onChange={onChangeBucket}
                  disabled={!policies?.length}
                />
              </div>
            </Col>
            <Col className="flex-grow-0" />
          </Row>
          <Row className="time-controls">
            <Col>
              <Row className="border-bottom">
                <Col className="flex-grow-0">
                  <Button className="invisible" variant="icon" disabled>
                    <i className="sv sv-trash" />
                  </Button>
                </Col>
                <Col className="flex-grow-1">
                  <Row>
                    {!!isSpecialEvent && (
                      <Col className="text-center">
                        <span>{locale('Date Range')}</span>
                      </Col>
                    )}
                    <Col className="text-center">
                      <span>
                        {locale('Inbound')}
                        <br />
                        {locale('(Mins)')}
                      </span>
                    </Col>
                    <Col className="text-center">
                      <span>
                        {locale('Outbound')}
                        <br />
                        {locale('(Mins)')}
                      </span>
                    </Col>
                    <Col className="text-center">
                      <Tippy className="punch-policy-tooltip" content="Padding in minutes from location.">
                        <span>{locale('Time Range')}</span>
                      </Tippy>
                    </Col>
                  </Row>
                </Col>
                <Col className="flex-grow-0" />
              </Row>
              {policies?.map(
                (policy: PunchPolicy, i: number): JSX.Element => (
                  <PunchPolicyTimeControls
                    {...policy}
                    key={`punch-policy-${i}`}
                    index={i}
                    onChange={onChange(i)}
                    onTimeInputChange={onTimeInputChange(i)}
                    nextStartTime={policies?.[(i + 1) % policies.length].startTime}
                    isSpecialEvent={!!isSpecialEvent}
                    handleColor={(_index: number): string => getRangeHandleColor(i)}
                    onDelete={policies?.length > 1 || !!isSpecialEvent ? onConfirmDelete(i) : undefined}
                  />
                )
              )}
              <Row className="mt-3">
                <Col className="d-flex justify-content-center">
                  <Button variant="outline-primary" className="add-punch-policy-button w-75" onClick={onAddBucket}>
                    <i className="fa fa-plus me-2 border border-primary" />
                    {locale('Add New Bucket')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default PunchPolicyDetails;

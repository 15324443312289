import { GetAirportGroupPermissionsDocument, GetAirportGroupsDocument } from '@/api/queries';

import { FilterOption } from '@/models/gen/graphql';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetAirportGroupsGraphApiResponse = GraphApiResponse<typeof GetAirportGroupsDocument>;
type GetAirportGroupPermissionsGraphApiResponse = GraphApiResponse<typeof GetAirportGroupPermissionsDocument>;

const getAirportGroupsResponseSelector = (res: GetAirportGroupsGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.airportGroups as Array<FilterOption>;
const getAirportGroupPermissionsResponseSelector = (res: GetAirportGroupPermissionsGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.airportGroupPermissions as Array<FilterOption>;

const [runGetAirportGroups] = graphApi(GetAirportGroupsDocument, {
  onError: (res: GetAirportGroupsGraphApiResponse): void => handleError(res, { notification: { title: 'Get Airport Groups' } }),
});
const [runAirportGroupPermissions] = graphApi(GetAirportGroupPermissionsDocument, {
  onError: (res: GetAirportGroupPermissionsGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Get Airport Group Permissions' } }),
});

export const getAirportGroups = async (): Promise<Array<FilterOption>> => {
  const res = await runGetAirportGroups();
  return getAirportGroupsResponseSelector(res);
};
export const getAirportGroupPermissions = async (): Promise<Array<FilterOption>> => {
  const res = await runAirportGroupPermissions();
  return getAirportGroupPermissionsResponseSelector(res);
};

export const useGetAirportGroups = createGraphApiHook(getAirportGroups);

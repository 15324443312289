import './styles.scss';

import React, { useEffect, useLayoutEffect } from 'react';
import { Validation, getClasses, getCookies, logout } from '../../utils';

import DebugTools from '../DebugTools';
import Header from '../Header';
import { LoadingBlur } from '../LoadingSpinner';
import ModalContainer from '../ModalContainer';
import NotificationWrapper from '@/components/Notification/NotificationWrapper';
import { clearNotifications } from '../../utils/custom';
import { useAppState } from '../../store/appReducer';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useSearchParams } from 'react-router-dom';
import useSocket from '@/hooks/useSocket';
import useVersionCheck from '@/hooks/useVersionCheck';

const ProtectedRoute = ({ title, children, className }: { title: string; children: any; className?: string }): JSX.Element => {
  const [{ config, session }] = useAppState();
  const { env } = config;
  const [query] = useSearchParams();
  const isLocal = env === 'local';
  const isDebug = query.has('debug');
  const socket = useSocket();

  useDocumentTitle(title || '');
  useEffect((): void => {
    const { token } = getCookies('token');
    if (!token || Validation.isNil(session)) logout();
  }, [session]);
  useLayoutEffect((): void => {
    clearNotifications();
    if (socket?.connected) return;
    socket?.connect();
  }, [socket]);
  useVersionCheck();

  if (!session) return <LoadingBlur loading={true} />;
  return (
    <>
      <Header />
      <NotificationWrapper />
      <div className={getClasses('RouteContent', className)}>
        {children}
        <div id="Sidebar-Left" className="d-flex flex-column py-2 ps-2 {font-size:1.5rem;}_.btn">
          <div className="Sidebar-Top d-flex flex-column justify-content-start flex-grow-1 gap-2" />
          <div className="Sidebar-Bottom d-flex flex-column justify-content-end flex-grow-1 gap-2" />
        </div>
        <div id="Sidebar-Right" className="d-flex flex-column py-2 ps-2 {font-size:1.5rem;}_.btn">
          <div className="Sidebar-Top d-flex flex-column justify-content-start flex-grow-1 gap-2" />
          <div className="Sidebar-Bottom d-flex flex-column justify-content-end flex-grow-1 gap-2" />
        </div>
      </div>
      <div id="RouteContent-Footer"></div>
      <ModalContainer />
      {isLocal && isDebug && <DebugTools />}
    </>
  );
};

export default ProtectedRoute;

import { Circle, GoogleMapProps } from '@react-google-maps/api';
import MapMarker, { MapMarkerWithInfoWindow } from './MapMarker';

import { Button } from 'react-bootstrap';
import MapDisplay from '.';
import { Point } from '../../models/gen/graphql';
import React from 'react';
import Styles from '../../models/Styles';
import { getVariantColor } from '../../utils/styles';
import { stringify } from '../../utils/objects';

type Coordinate = {
  coordinates: Point;
  radius?: number;
  color?: Styles.ColorVariant;
};
type MapCoordinatesInputOptions = { radiusControl?: boolean } & GoogleMapProps['options'];
interface MapCoordinatesInput extends GoogleMapProps {
  name: string;
  value: Coordinate[];
  className?: string;
  onChange: (event: { target: { name: string; value: Coordinate[] } }) => void;
  onAddPoint: (event: google.maps.MapMouseEvent, input: { target: { name: string; value: Coordinate } }) => void;
  options?: MapCoordinatesInputOptions;
}
type MapCoordinatesInputState = {
  map: google.maps.Map;
  circleRefs: google.maps.Circle[];
};
const initMapCoordinatesInputState: MapCoordinatesInputState = { circleRefs: [], map: null };
const MapCoordinatesInput = ({
  name,
  value: markers,
  onChange,
  onAddPoint,
  center,
  options,
  ...props
}: MapCoordinatesInput): JSX.Element => {
  // Init State
  const [state, setState] = React.useState(initMapCoordinatesInputState);
  const { circleRefs, map } = state;

  const { radiusControl = true, ...mapDisplayOptions } = options || {};

  // State Handlers
  const onCircleLoad =
    (idx: number): ((circleRef: google.maps.Circle) => void) =>
    (circleRef: google.maps.Circle): void =>
      setState((current: MapCoordinatesInputState): MapCoordinatesInputState => {
        const circleRefs = [...current.circleRefs];
        circleRefs[idx] = circleRef;
        return { ...current, circleRefs };
      });
  const onCircleUnmount =
    (idx: number): ((circleRef: google.maps.Circle) => void) =>
    (circleRef: google.maps.Circle): void =>
      setState((current: MapCoordinatesInputState): MapCoordinatesInputState => {
        const circleRefs = [...current.circleRefs];
        circleRefs.splice(idx, 1);
        return { ...current, circleRefs };
      });
  const onLoadMap = (map: google.maps.Map): void => setState((current: any): any => ({ ...current, map }));
  const onUnmountMap = (map: google.maps.Map): void => setState(initMapCoordinatesInputState);
  // Change Handlers
  const onMapClick = (e: google.maps.MapMouseEvent): void => {
    // logic to modify marker state goes here
    const result: Coordinate = { coordinates: { lat: e.latLng.lat(), lng: e.latLng.lng() } };
    if (radiusControl) result.radius = 600;
    const input = { target: { name, value: result } };
    onAddPoint(e, input);
  };
  const onRadiusChange =
    (idx: number): (() => void) =>
    (): void => {
      // this func fires on mount and on radius change
      const radius = circleRefs[idx]?.['radius'];
      if (!radius) return;
      const newMarkers = [...markers];
      newMarkers[idx] = { ...newMarkers[idx], radius: Math.round(parseInt(radius)) };
      const input = { target: { name, value: newMarkers } };
      if (stringify.compare(markers[idx], newMarkers[idx])) return;
      onChange(input);
    };
  const onCircleCenterChange =
    (idx: number): (() => void) =>
    (): void => {
      // if the user changed the circle center we want to sync the change with the marker
      // this func fires on mount and on center change
      const center = circleRefs[idx]?.['center'];
      if (!center) return;
      const coordinates = { lat: center.lat(), lng: center.lng() };
      const newMarkers = [...markers];
      newMarkers[idx] = { ...newMarkers[idx], coordinates };
      const input = { target: { name, value: newMarkers } };
      if (stringify.compare(markers[idx], newMarkers[idx])) return;
      onChange(input);
    };
  const onDragEndMarker =
    (idx: number): ((e: google.maps.MapMouseEvent) => void) =>
    (e: google.maps.MapMouseEvent): void => {
      // sync marker circle and marker position
      const coordinates = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      const newMarkers = [...markers];
      newMarkers[idx] = { ...newMarkers[idx], coordinates };
      const input = { target: { name, value: newMarkers } };
      onChange(input);
    };
  const onRemoveMarker =
    (idx: number): React.MouseEventHandler<HTMLButtonElement> =>
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      // sync marker circle and marker position
      const newMarkers = [...markers];
      newMarkers.splice(idx, 1);
      const input = { target: { name, value: newMarkers } };
      onChange(input);
    };

  // adjust zoom to fit all markers
  // React.useEffect((): void => {
  //   if (!map || (markers || []).length < 3) return;
  //   console.log('adjust zoom');
  //   const bounds = new window.google.maps.LatLngBounds();
  //   markers.map((marker: Coordinates): void => {
  //     bounds.extend({
  //       lat: marker.lat,
  //       lng: marker.lng,
  //     });
  //   });
  //   map.fitBounds(bounds);
  // }, [map, markers]);

  return (
    <MapDisplay
      {...props}
      center={center || markers[0]?.coordinates}
      onLoad={onLoadMap}
      onUnmount={onUnmountMap}
      options={mapDisplayOptions}
      onClick={onMapClick}
    >
      {markers.map(({ radius, color: variant, coordinates }: Coordinate, m: number): React.JSX.Element => {
        const color = getVariantColor(variant);
        const mapMarkerProps = {
          draggable: !radiusControl,
          onDragEnd: !radiusControl ? onDragEndMarker(m) : undefined,
          position: coordinates,
          color,
        };
        return (
          <React.Fragment key={m}>
            {radiusControl && (
              <Circle
                editable
                onRadiusChanged={onRadiusChange(m)}
                onCenterChanged={onCircleCenterChange(m)}
                onClick={onMapClick}
                center={coordinates}
                radius={radius}
                options={{
                  strokeColor: color,
                  fillColor: color,
                }}
                onLoad={onCircleLoad(m)}
                onUnmount={onCircleUnmount(m)}
              />
            )}
            {m === 0 && <MapMarker {...mapMarkerProps} />}
            {m !== 0 && (
              <MapMarkerWithInfoWindow {...mapMarkerProps}>
                <Button className="m-2" onClick={onRemoveMarker(m)} variant={variant}>
                  Remove Marker
                </Button>
              </MapMarkerWithInfoWindow>
            )}
          </React.Fragment>
        );
      })}
    </MapDisplay>
  );
};

export default React.memo(MapCoordinatesInput);
export { Coordinate as MapCoordinate };

import { RunResetUserPasswordBulkInput, RunResetUserPasswordBulkResponse, RunResetUserPasswordInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { GraphApiResponse } from '@/api/core';
import { RunResetUserPasswordBulkDocument } from '@/api/queries';
import Toast from '@/models/Toast';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type RunResetUserPasswordBulkGraphApiResponse = GraphApiResponse<typeof RunResetUserPasswordBulkDocument>;

const title = 'Reset User Password';

const runResetUserPasswordResponseSelector = (res: RunResetUserPasswordBulkGraphApiResponse): RunResetUserPasswordBulkResponse =>
  res?.runResetUserPasswordBulk;

const [runResetUserPasswordBulk] = graphApi(RunResetUserPasswordBulkDocument, {
  onError: (res: RunResetUserPasswordBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runResetUserPassword = async (payload: RunResetUserPasswordInput[]): Promise<RunResetUserPasswordBulkResponse> => {
  const input: RunResetUserPasswordBulkInput = { input: payload };

  const res = await runResetUserPasswordBulk({ input });
  const data = runResetUserPasswordResponseSelector(res);

  if (data) createNotification('Success', Toast.Type.SUCCESS, title);

  return data;
};

export const useRunResetUserPassword = createGraphApiHook(runResetUserPassword);

export default runResetUserPassword;

import { DownloadInvoiceResponse, InvoiceInput, RunDownloadInvoiceInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { RunDownloadInvoiceDocument } from '../../queries';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type RunDownloadInvoiceGraphApiResponse = GraphApiResponse<typeof RunDownloadInvoiceDocument>;

const title = 'Download Invoice';

const runDownloadInvoiceResponseSelector = (res: RunDownloadInvoiceGraphApiResponse): DownloadInvoiceResponse =>
  !res.errors ? res?.runDownloadInvoice?.output : undefined;

const [runRunDownloadInvoice] = graphApi(RunDownloadInvoiceDocument, {
  onError: (res: RunDownloadInvoiceGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const runDownloadInvoice = async (invoice: InvoiceInput): Promise<DownloadInvoiceResponse> => {
  const input: RunDownloadInvoiceInput = { input: invoice };
  const res = await runRunDownloadInvoice({ input });

  const selected = runDownloadInvoiceResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useRunDownloadInvoice = createGraphApiHook(runDownloadInvoice);

export default runDownloadInvoice;

import { useEffect, useState } from 'react';

import { OnChange } from './useOnChange';
import useLocalStorage from './useLocalStorage';
import useOnChange from './useOnChange';
import useQueryString from './useQueryString';

/* useForm
  Inspired by the Mantine library's useForm hook, this is really
  just a lite version of that for the time being. It's purpose
  is to centralize the onChange call that is actually duplicated
  all across the project, but also so that we can add global
  validation logic to one place in the future and keep it all
  simple with less code duplication.

  This also adds the functionality of the setProperty utility so
  that our form fields can have JSON Key styled names to work
  with nested form values as well.

  <input name="my.nested.key" onChange={onChange} value={state.my.nested.key} />

  This example would have the onChange method change the nested
  value based on the name it was given with no extra logic needed.
*/

const useForm = <T>(initFormState?: T): [T, OnChange, React.Dispatch<React.SetStateAction<T>>] => {
  const [form, setForm] = useState(initFormState);
  const onChange = useOnChange(setForm, initFormState);
  return [form, onChange, setForm];
};
const useFormPersist = (initFormState = {}, name: any): any[] => {
  const [savedForm, setSavedForm] = useLocalStorage(name, initFormState);
  const [form, onChange, setForm] = useForm(savedForm);

  useEffect((): void => {
    setSavedForm(form);
  }, [form]);

  return [form, onChange, setForm];
};
useForm.persist = useFormPersist;

const useFormQuery = (initFormState: any): any[] => {
  const [queryString, setQueryString] = useQueryString(initFormState);
  const [form, onChange, setForm] = useForm(queryString);

  useEffect((): void => {
    const cleanedForm = {};
    Object.entries(form).forEach(([key, val]: [string, any]): any => (cleanedForm[key] = val));
    setQueryString(cleanedForm);
  }, [form]);

  return [form, onChange, setForm];
};
useForm.query = useFormQuery;

export default useForm;

import { Button, Modal } from 'react-bootstrap';
import { logout, pascalCase, properCase } from '../../utils';

import Field from '@/components/Field';
import Input from '@/components/Input';
import { LoadingBlur } from '../LoadingSpinner';
import Logger from '../../utils/logs';
import React from 'react';
import { parseQueryString } from '../../utils/strings';
import resetPasswordLinkCode from '@/api/services/users/resetPasswordLinkCode';
import updatePasswords from '@/api/services/users/updatePasswords';
import useModal from '../../hooks/useModal';

const ChangePasswordModal = ({ type = 'change' }: { type: 'change' | 'reset' }): JSX.Element => {
  const { code: queryCode } = parseQueryString();
  const modalName = pascalCase(`${type}PasswordModal`);
  const log = Logger.of(modalName);
  const [state, setState] = useModal(modalName, {
    onSubmit: async ({
      oldPassword,
      newPassword,
      resetCode = queryCode,
      username,
      confirmPassword,
    }: {
      oldPassword: string;
      newPassword: string;
      resetCode: string;
      username: string;
      confirmPassword: string;
    }): Promise<void> => {
      try {
        if (type === 'change') {
          await updatePasswords(oldPassword, newPassword);
        } else {
          await resetPasswordLinkCode(resetCode, username, newPassword, confirmPassword);
        }
        logout();
      } catch (err) {
        log.error(err.message || err).notify({
          title: `${properCase(type)} Password`,
        });
      }
    },
  });
  const { onHide, onChange, onSubmit } = setState;
  const handleChange =
    (name: string): ((value: string) => void) =>
    (value: string): void =>
      onChange({ target: { name, value } });
  const {
    show = false,
    loading = false,
    resetCode = queryCode || '',
    username = '',
    oldPassword = '',
    newPassword = '',
    confirmPassword = '',
  } = state;
  const OldAndNewPasswordFields = (
    <>
      <Field
        label="New Password"
        valid={newPassword?.length > 3}
        feedback={newPassword?.length > 3 ? undefined : 'Password must be at least 4 characters.'}
      >
        <Input
          name="newPassword"
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(value: string): void => {
            value = value.replace(/\s{1,}/g, ' ');
            handleChange('newPassword')(value);
          }}
          isValid={newPassword?.length > 3}
          isInvalid={newPassword?.length <= 3}
        />
      </Field>
      <Field
        label="Confirm Password"
        valid={confirmPassword?.length > 3 && newPassword === confirmPassword}
        feedback={
          confirmPassword?.length > 3
            ? newPassword === confirmPassword
              ? undefined
              : 'Passwords do not match.'
            : 'Password must be at least 4 characters.'
        }
      >
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(value: string): void => {
            value = value.replace(/\s{1,}/g, ' ');
            handleChange('confirmPassword')(value);
          }}
          isValid={newPassword?.length > 3 && newPassword === confirmPassword}
          isInvalid={newPassword?.length <= 3 || newPassword !== confirmPassword}
        />
      </Field>
    </>
  );

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <LoadingBlur loading={loading} />
      {type === 'change' && (
        <Modal.Body>
          <Modal.Title>Change Password</Modal.Title>
          <p>Please enter your current password, as well as your new one.</p>
          <Field
            label="Old Password"
            valid={oldPassword?.length > 3}
            feedback={oldPassword?.length <= 3 ? 'You must enter a password.' : undefined}
          >
            <Input
              name="oldPassword"
              type="password"
              placeholder="Old Password"
              value={oldPassword}
              onChange={handleChange('oldPassword')}
              isValid={oldPassword?.length > 3}
              isInvalid={oldPassword?.length <= 3}
            />
          </Field>
          {OldAndNewPasswordFields}
        </Modal.Body>
      )}
      {type === 'reset' && (
        <Modal.Body>
          <Modal.Title>Reset Password</Modal.Title>
          <p>Please enter and confirm your new password.</p>
          <Field
            label="Reset Code"
            valid={resetCode?.length > 0}
            feedback={resetCode?.length === 0 ? 'Reset Code is required.' : undefined}
          >
            <Input
              name="resetCode"
              placeholder="Reset Code"
              value={resetCode}
              onChange={handleChange('resetCode')}
              isValid={resetCode?.length > 0}
              isInvalid={resetCode?.length === 0}
            />
          </Field>
          <Field label="Username" valid={username?.length > 0} feedback={username?.length > 0 ? undefined : 'Username is required.'}>
            <Input
              name="username"
              placeholder="Username"
              value={username}
              onChange={handleChange('username')}
              isValid={username?.length > 0}
              isInvalid={username?.length === 0}
            />
          </Field>
          {OldAndNewPasswordFields}
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button className="flex-grow-1" name="CANCEL" variant="outline-gray" onClick={onHide}>
          Cancel
        </Button>
        <Button
          className="flex-grow-1"
          name="SUBMIT"
          variant="success"
          onClick={onSubmit}
          disabled={newPassword?.length < 4 || newPassword !== confirmPassword}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;

import { ReactNode, useMemo } from 'react';

import BufferTimes from '@/features/settings/components/pages/BufferTimes';
import CombineRules from '@/features/settings/components/pages/CombineRules';
import VariableTimes from '@/features/settings/components/pages/VariableTimes';
import { useParams } from 'react-router-dom';

export type SettingsRouteProps = {
  route: string;
};
export type SettingsRoute = (props: SettingsRouteProps) => ReactNode;

const Settings = (): ReactNode => {
  const { ['sub-route']: subRoute } = useParams<Record<string, string>>();

  const Content = useMemo((): SettingsRoute => {
    switch (subRoute) {
      case 'variable-times':
        return VariableTimes;
      case 'combine-rules':
        return CombineRules;
      case 'buffer-times':
      default:
        return BufferTimes;
    }
  }, [subRoute]);

  return <Content route={subRoute} />;
};

export default Settings;

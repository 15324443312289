import { useEffect, useState } from 'react';

import { Rate } from '../../models/gen/graphql';
import { initEditRatesModalForm } from '../../components/EditRatesModal';
import runApplyRateToSimilarTrips from '../../api/services/rates/runApplyRateToSimilarTrips';
import updateRateBulk from '../../api/services/rates/updateRateBulk';
import useConfirmation from '../../hooks/useConfirmation';
import useEvent from '../../hooks/useEvent';
import useModal from '../../hooks/useModal';

export enum RateModalType {
  ApplySimilar = 'APPLY_SIMILAR',
  ConfirmTripRates = 'CONFIRM_TRIP_RATES',
  MassConfirm = 'MASS_CONFIRM',
}
type State = {
  totalCount: number;
  rateUpdate: Partial<Rate>[];
};
const initState: State = {
  totalCount: 0,
  rateUpdate: undefined,
};
const useRatesModals = (onSubmit?: (data?: any) => Promise<void>) => {
  const [state, setState] = useState<State>(initState);
  const { totalCount, rateUpdate } = state;
  const [, { show: showEditRatesModal }] = useModal('EditRates', { onSubmit });
  const [, { show: showConfirmTripRatesModal }] = useModal('ConfirmTripRates', { onSubmit });

  const confirmApplyRateToSimilarTrips = useConfirmation({
    Body: {
      title: 'Assign Rate to Trips',
      message: 'Apply rate to all similar trips?',
    },
  });
  const massConfirmation = useConfirmation({
    Body: {
      title: 'Confirm Mass Update',
      message: `There are ${totalCount || 0} trips using this rate. Continue?`,
    },
  });

  const handleMassConfirm = async (): Promise<void> => {
    try {
      await massConfirmation();
      await updateRateBulk(rateUpdate);
      triggerModal({ type: RateModalType.ApplySimilar, payload: { rateId: rateUpdate?.[0]?.id } });
      setState(initState);
      onSubmit();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (totalCount !== 0) {
      handleMassConfirm();
    }
  }, [totalCount]);

  const triggerModal = useEvent(`useRatesModals:triggerModal`, async ({ type, payload }: { type: string; payload: any }): Promise<void> => {
    switch (type) {
      case RateModalType.ApplySimilar:
        try {
          await confirmApplyRateToSimilarTrips();
          await runApplyRateToSimilarTrips([payload?.rateId]);
          onSubmit();
          setState(initState);
        } catch (err) {
          console.error(err);
        }
        return;
      case RateModalType.ConfirmTripRates:
        showConfirmTripRatesModal(payload);
        return setState(initState);
      case RateModalType.MassConfirm:
        return setState((current) => ({ ...current, totalCount: payload?.totalCount, rateUpdate: payload?.rateUpdate }));
    }
  });

  const triggerEditRatesModal = (payload: { selectedRows: any[] }): void => {
    setState((current) => ({ ...current, selectedRows: payload?.selectedRows }));
    showEditRatesModal({ ...payload, form: initEditRatesModalForm });
  };
  return triggerEditRatesModal;
};

export default useRatesModals;

import { Modal } from 'react-bootstrap';
import React from 'react';
import { createPortal } from 'react-dom';

const PageSettings = ({ group = 'general', title, children = [] }: { group?: string; title?: string; children: any }): JSX.Element => {
  const modal = document.getElementById('PageSettings');
  let mountPoint = modal?.getElementsByClassName?.(group)?.[0];
  let content = children;
  if (!mountPoint) {
    mountPoint = modal;
    content = (
      <Modal.Body className={group} title={title}>
        {children}
      </Modal.Body>
    );
  }
  if (!mountPoint) return null;
  return createPortal(content, mountPoint);
};

export default React.memo(PageSettings);

import './styles.scss';

import { Container } from 'react-bootstrap';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import ReportProblemForm from './ReportProblemForm';

const ReportProblem = (): JSX.Element => (
  <ProtectedRoute title="Report A Problem">
    <Container fluid>
      <ReportProblemForm />
    </Container>
  </ProtectedRoute>
);

export default ReportProblem;

import gql from 'graphql-tag';
import { PAGE_SIZE } from '../../constants';
import { generateQuery } from '../../utils';

const queryKeys = [
  'active',
  'airportCode',
  'availability',
  'availabilityType',
  'averageRating',
  'countryCode',
  'employeeId',
  'id',
  'roleId',
];

const getFilteredVehicles = {
  query: gql`
    query SearchVehicles($input: VehicleConnectionInput) {
      searchVehicles {
        vehicleConnection(input: $input) {
          edges {
            cursor
            node {
              trackingId
              id
            }
          }
        }
      }
    }
  `,
  wrapper:
    (fireQuery) =>
    async (first = PAGE_SIZE, after = null, ...querObjects) => {
      return fireQuery({
        variables: {
          input: {
            first,
            after,
            query: generateQuery(queryKeys, ...querObjects),
          },
        },
      });
    },
  selector: (data) => {
    const { edges, totalCount, pageInfo: { hasNextPage = false, endCursor = '0' } = {} } = data?.searchVehicles?.vehicleConnection || {};
    const rows = (edges || []).map(({ node }) => node || {});
    return { rows, hasNextPage, endCursor, totalCount };
  },
};

export default getFilteredVehicles;

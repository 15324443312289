import { RunGetDriverEstimatedDriveTimeInput, RunGetDriverEstimatedDriveTimeResponse, Trip } from '@/models/gen/graphql';

import { GraphApiResponse } from '@/api/core';
import { RunGetDriverEstimatedDriveTimeDocument } from '@/api/queries';
import graphApi from '@/api';
import { handleError } from '../../../utils';

type RunGetDriverEstimatedDriveTimeGraphApiResponse = GraphApiResponse<typeof RunGetDriverEstimatedDriveTimeDocument>;

const runGetDriverEstimatedDriveTimeResponseSelector = (
  res: RunGetDriverEstimatedDriveTimeGraphApiResponse
): RunGetDriverEstimatedDriveTimeResponse =>
  !res.errors ? ((res?.runGetDriverEstimatedDriveTime || {}) as RunGetDriverEstimatedDriveTimeResponse) : undefined;

const [runRunGetDriverEstimatedDriveTime] = graphApi(RunGetDriverEstimatedDriveTimeDocument);

const runGetDriverEstimatedDriveTime = async (
  input: RunGetDriverEstimatedDriveTimeInput
): Promise<RunGetDriverEstimatedDriveTimeResponse> => {
  try {
    const res = await runRunGetDriverEstimatedDriveTime({ input });

    return runGetDriverEstimatedDriveTimeResponseSelector(res);
  } catch (err) {
    handleError(err, { notification: { title: 'Get Driver Estimated Drive Time' } });
  }
};

export default runGetDriverEstimatedDriveTime;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy .modal-content {
  min-height: 90vh;
  padding: 10px !important;
}
.PrivacyPolicy .modal-body {
  height: 90%;
  padding: 10px;
}
.PrivacyPolicy h6 {
  margin-top: 3rem;
  padding-bottom: 5px;
}
.PrivacyPolicy h6:after {
  height: 2px;
  display: block;
  width: 100%;
  background: #b0b0b0;
  margin-top: 10px;
  content: "";
}
.PrivacyPolicy ::-webkit-scrollbar-track {
  background-color: transparent;
}
.PrivacyPolicy .overflow-y-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

@media screen and (min-width: 768px) {
  .PrivacyPolicy .modal-header,
  .PrivacyPolicy .modal-body {
    padding: 10px 30px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,wBAAA;AAAJ;AAGE;EACE,WAAA;EACA,aAAA;AADJ;AAIE;EACE,gBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,WAAA;EACA,cAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AAHJ;AAME;EACE,6BAAA;AAJJ;AAOE;EACE,kBAAA;EACA,kBAAA;AALJ;;AASA;EAEI;;IAEE,6BAAA;EAPJ;AACF","sourcesContent":[".PrivacyPolicy .modal-content {\n  min-height: 90vh;\n  padding: 10px !important;\n}\n.PrivacyPolicy .modal-body {\n  height: 90%;\n  padding: 10px;\n}\n.PrivacyPolicy h6 {\n  margin-top: 3rem;\n  padding-bottom: 5px;\n}\n.PrivacyPolicy h6:after {\n  height: 2px;\n  display: block;\n  width: 100%;\n  background: #b0b0b0;\n  margin-top: 10px;\n  content: \"\";\n}\n.PrivacyPolicy ::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n.PrivacyPolicy .overflow-y-scroll {\n  overflow-x: hidden;\n  overflow-y: scroll;\n}\n\n@media screen and (min-width: 768px) {\n  .PrivacyPolicy .modal-header,\n  .PrivacyPolicy .modal-body {\n    padding: 10px 30px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react-hooks/rules-of-hooks */
import { getProperty, stringify } from '@/utils/objects';
import { useMemo, useRef } from 'react';

/**
 * Generates a function comment for the given function body.
 *
 * @param {string} key - The key used for some operation.
 * @param {Function} setState - The state setter function.
 * @return {Function} A function that takes an `id` parameter and performs some operation.
 */
const useList =
  (key: string): ((setState: any) => (id: string | string[]) => void) =>
  (setState: any): ((id: string | string[]) => void) =>
  (id: string | string[]): string[] => {
    let result = [];
    setState((current: any): any => {
      result = !Array.isArray(id)
        ? (current?.[key] || []).includes(id)
          ? (current?.[key] || []).filter((i: string): boolean => i !== id)
          : [id, ...(current?.[key] || [])]
        : id.length !== (current?.[key] || []).length
          ? id
          : [];
      return {
        ...current,
        [key]: result,
      };
    });
    return result;
  };
export const useOnSelect = useList('selected');
export const useOnExpand = useList('expanded');

/**
 * Generates a function comment for the given function body.
 *
 * @param {any} setState - The setState function to update the state.
 * @param {object} sorting - An optional object containing sorting information.
 *   - {string} column: The column to sort by.
 *   - {string} direction: The direction of the sorting.
 *   - {(column: string, direction: string) => Promise<void>} onSort: An optional function to handle sorting.
 * @return {(column: string) => { onSort: (dir: string) => void; direction: string }} - Returns a function that takes a column parameter and returns an object with onSort and direction.
 */
export const useMakeSortable =
  (
    setState: any,
    sorting?: { column?: string; direction?: string; onSort?: (column: string, direction: string) => Promise<void> }
  ): ((column: string) => { onSort: (dir: string) => void; direction: string }) =>
  (column: string): any => {
    const handleSort = (dir: string): void => {
      setState((current) => ({
        ...current,
        sorting: { column: dir ? column : undefined, direction: dir },
      }));
      sorting?.onSort?.(column, dir);
    };
    const direction = sorting?.column === column ? sorting?.direction : undefined;
    return { onSort: handleSort, direction };
  };

const getSearchableValueFromColumn = (column: string, row: any): string => {
  switch (column) {
    case 'flightNumber':
      return String(getProperty(column, row)).padStart(4, '0');
    default:
      return getProperty(column, row);
  }
};

/**
 * Generates a virtual table hook.
 *
 * @param {any} setState - The state setter function.
 * @param {object} options - Optional configuration object.
 * @param {string[]} options.selected - An array of selected items.
 * @param {string[]} options.expanded - An array of expanded items.
 * @param {object} options.sorting - An object representing the sorting configuration.
 * @param {string} options.sorting.column - The column used for sorting.
 * @param {string} options.sorting.direction - The sort direction.
 * @return {object} An object containing the makeSortable, onSelect, and onExpand functions.
 */
export const useVirtualTable = (
  setState: any,
  options?: {
    selected?: string[];
    expanded?: string[];
    sorting?: { column?: string; direction?: string; onSort?: (column: string, direction: string) => Promise<void> };
    search?: string;
    rows?: any[];
    searchableColumns?: Array<string>;
  }
): {
  makeSortable: (column: string) => { onSort: (dir: string) => void; direction: string };
  onSelect: (id: string | string[]) => void;
  onExpand: (id: string | string[]) => void;
  filteredRows: any[];
  selectedRows: any[];
  expandedRows: any[];
} => {
  const {
    selected = [],
    expanded = [],
    sorting = { column: undefined, direction: undefined, onSort: undefined },
    search = '',
    rows = [],
    searchableColumns = [],
  } = options || {};
  const makeSortable = useMakeSortable(setState, sorting);
  const onSelect = useOnSelect(setState);
  const onExpand = useOnExpand(setState);
  const lastSort = useRef({ rows, search, sorting });
  const filteredRows = useMemo((): any[] => {
    const thisSort = { rows, search, sorting };
    if (sorting?.direction && !stringify.compare(lastSort.current, thisSort) && !!selected?.length)
      setState((current: any): any => ({ ...current, selected: [] }));
    lastSort.current = thisSort;
    const sortRows = (rows: any[]): any[] => {
      return rows
        ?.filter((row: any): any => {
          if (!search) return true;
          row = searchableColumns?.length ? searchableColumns.map((selector) => getSearchableValueFromColumn(selector, row)) : row;
          const str = stringify(row);
          try {
            const expression = new RegExp(search || '', 'gi');
            return !!str.match(expression);
          } catch (err) {
            return true;
          }
        })
        .sort((a: any, b: any): any => {
          if (sorting?.onSort) return 0;
          if (!sorting?.column || !sorting?.direction) return 0;
          let valueA = getProperty(`values.${sorting?.column}|${sorting?.column}`, a, '');
          if (!valueA.localeCompare) valueA = stringify(valueA);
          let valueB = getProperty(`values.${sorting?.column}|${sorting?.column}`, b, '');
          if (!valueB.localeCompare) valueB = stringify(valueB);
          if (!isNaN(valueA) && !isNaN(valueB)) return sorting?.direction === 'asc' ? valueA - valueB : valueB - valueA;
          return sorting?.direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        })
        .map((row: any): any => {
          if (row?.['children']?.length) row['children'] = sortRows(row['children']);
          return row;
        });
    };
    return sortRows(rows);
  }, [rows, search, sorting?.column, sorting?.direction]);
  const selectedRows = useMemo(
    (): any[] => filteredRows?.filter((row: any): any => !!row?.id && selected.includes(row.id)),
    [rows, selected]
  );
  const expandedRows = useMemo(
    (): any[] => filteredRows?.filter((row: any): any => !!row?.id && expanded.includes(row.id)),
    [rows, expanded]
  );
  return { makeSortable, onSelect, onExpand, filteredRows, selectedRows, expandedRows };
};

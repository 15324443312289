import './styles.scss';

import GroupFooter, { GroupFooterProps } from '@/features/settings/components/SettingsPageGroup/GroupFooter';
import GroupHeader, { GroupHeaderProps } from '@/features/settings/components/SettingsPageGroup/GroupHeader';
import React, { ReactNode } from 'react';

import DynamicContentPage from '@/components/DynamicContentPage';
import { FiltersProps } from '@/components/Filters';
import SettingsDropdown from '@/features/settings/components/SettingsDropdown';

export type SettingsPageGroupProps = {
  header?: GroupHeaderProps;
  footer?: GroupFooterProps;
  filters?: Omit<FiltersProps, 'alternate'>;
  children?: ReactNode;
};
const SettingsPageGroup = ({ header, footer, filters, children }: SettingsPageGroupProps): ReactNode => (
  <DynamicContentPage
    header={header ? <GroupHeader {...header} /> : undefined}
    filters={{
      ...filters,
      alternate: (): JSX.Element => {
        return <SettingsDropdown />;
      },
    }}
    footer={footer ? <GroupFooter {...footer} /> : undefined}
  >
    {children}
  </DynamicContentPage>
);

export default SettingsPageGroup;

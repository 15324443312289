// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUpload .drag-and-drop {
  width: 100%;
  padding: 1rem;
}
.FileUpload .drag-and-drop .drag-area {
  height: 8rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
}
.FileUpload.condensed .drag-and-drop {
  border: none !important;
  padding: 0;
}
.FileUpload.condensed .form-label {
  position: relative;
  top: 1.25rem;
  left: 2rem;
  background-color: #fff;
  padding: 0 0.5rem;
  z-index: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/FileUpload/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,aAAA;AAAJ;AAEI;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,sBAAA;EACA,eAAA;AAAN;AAKI;EACE,uBAAA;EACA,UAAA;AAHN;AAKI;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;AAHN","sourcesContent":[".FileUpload .drag-and-drop {\n  width: 100%;\n  padding: 1rem;\n}\n.FileUpload .drag-and-drop .drag-area {\n  height: 8rem;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  justify-content: center;\n  border: 2px dashed #ccc;\n  border-radius: 0.25rem;\n  cursor: pointer;\n}\n.FileUpload.condensed .drag-and-drop {\n  border: none !important;\n  padding: 0;\n}\n.FileUpload.condensed .form-label {\n  position: relative;\n  top: 1.25rem;\n  left: 2rem;\n  background-color: #fff;\n  padding: 0 0.5rem;\n  z-index: 500;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { useCallback, useRef } from 'react';

export function useThrottledCallbackWithClearTimeout<T extends (...args: any[]) => any>(callback: T, wait: number) {
  const timestampRef = useRef(0);

  const clearTimer = () => (timestampRef.current = 0);

  const throttledCallback = useCallback(
    (...args) => {
      const now = Date.now();
      if (now - timestampRef.current < wait) return;
      timestampRef.current = now;
      callback(...args);
    },
    [callback, wait]
  );

  return [throttledCallback, clearTimer];
}

export function useThrottledCallback<T extends (...args: any[]) => any>(callback: T, wait: number) {
  return useThrottledCallbackWithClearTimeout(callback, wait)[0];
}

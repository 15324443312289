import { Button, Col, Row } from 'react-bootstrap';
import { Datetime, queryInput } from '../../utils';
import { Flag, FlagWithTrip } from '../../models/gen/graphql';
import React, { MouseEvent, useEffect } from 'react';

import EditModal from '../../components/EditModal/new';
import { getClasses } from '../../utils/strings';
import { searchFlags } from '../../queries';
import useDb from '../../hooks/useDb';
import { useGetFlagsByTripId } from '@/api/services/trips/searchTrips';
import useModal from '../../hooks/useModal';
import useTrips from '../../hooks/useTrips';

const EditFlag = (): JSX.Element => {
  const [state, setState] = useModal('EditFlag', {
    onSubmit: async (data: any): Promise<void> => {
      const creates = [];
      const deletes = [];
      Object.entries(data?.flags || {}).forEach(([key, value]: [string, boolean]): void => {
        const foundFlag = (getFlagsByTripIdData || []).find((node: FlagWithTrip): boolean => node.id === key);
        if (foundFlag && !value) deletes.push(key);
        if (!foundFlag && value) creates.push(key);
      });
      await handleSubmitFlag({ updates: { creates, deletes }, tripId });
      onHide();
    },
  });
  const { show = false, tripId } = state;
  const { onHide, onChange, onSubmit } = setState;

  // Init Queries
  const [, { handleSubmitFlag }] = useTrips();
  const {
    data: searchFlagsResponse,
    loading: searchFlagsLoading,
    refetch: refetchSearchFlags,
  } = useDb('Flags', searchFlags.query, { ttl: 0 });
  const searchFlagsData = {
    rows: (searchFlagsResponse?.searchFlags?.flagConnection?.edges || []).map(({ node }: { node: any }): any => node),
  };
  const [{ data: getFlagsByTripIdData, loading: getFlagsByTripIdLoading }, { fetch: getFlagsByTripId }] = useGetFlagsByTripId();

  const getTripFlags = async (): Promise<void> => {
    const tripFlags = await getFlagsByTripId([{ id: queryInput(tripId) }]);
    const result = {};
    tripFlags.forEach((node: FlagWithTrip): void => {
      if ((tripFlags || []).filter((n: FlagWithTrip): boolean => n.id === node.id).length > 0) {
        result[node.id] = true;
      }
    });
    if (Object.values(result).length === 0) return;
    setState((current: any): any => ({ ...current, flags: result }));
  };

  useEffect((): void => {
    if (!tripId) return;
    refetchSearchFlags();
    getTripFlags();
  }, [tripId]);

  const title = `Trip Flags - ${(state?.servicerIataAirlineCode || '').toUpperCase()}${state?.flightNumber || ''} ${
    state?.scheduled ? new Datetime(state?.scheduled).frontendDatetimeShort : ''
  }`;

  return (
    <EditModal
      name="editFlag"
      icon="sv sv-flag1"
      loading={getFlagsByTripIdLoading || searchFlagsLoading}
      show={show}
      onHide={onHide}
      title={title}
      onSubmit={onSubmit}
      options={{
        footer: {
          submitButtonText: 'Confirm Changes',
        },
      }}
    >
      <Row className="mt-4 {min-height:100px;}" xs={1} sm={3}>
        {/* Flag Component */}
        {(searchFlagsData?.rows || []).map(
          (flag: Flag, f: number): JSX.Element => (
            <Col key={f}>
              <Button
                name={flag.name.replace(/ /g, '_').toUpperCase()}
                onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                  event.preventDefault();
                  event.stopPropagation();
                  event.target['name'] = `flags.${flag.id}`;
                  event.target['value'] = flag.id;
                  onChange.toggle(event);
                  event.target['blur']();
                }}
                variant="outline-gray"
                className={getClasses(
                  'w-100',
                  'd-flex justify-content-center gap-2',
                  'bg-light text-dark',
                  '{border-width:3px!;}',
                  `{border-color:${state?.flags?.[flag.id] ? flag.color : 'rgba(0,0,0,0.05)'}!;}`
                )}
              >
                <i className={`sv sv-flag-${state?.flags?.[flag.id] ? 'filled' : 'empty'} {font-size:1.5rem;color:${flag.color};}`} />
                {flag.name}
              </Button>
            </Col>
          )
        )}
        <h4 style={{ display: searchFlagsData?.rows?.length ? 'none' : 'block' }}>No Flags to Show</h4>
      </Row>
    </EditModal>
  );
};

export default EditFlag;

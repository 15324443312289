import { GetRateValuesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getRateValues = createGraphApiMockResponse(GetRateValuesDocument, {
  getFilters: {
    filters: {
      rateValue: {
        rates: [
          {
            displayName: '0.00',
            id: '0.00',
            values: ['0.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '1.00',
            id: '1.00',
            values: ['1.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '2.00',
            id: '2.00',
            values: ['2.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '5.00',
            id: '5.00',
            values: ['5.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '7.00',
            id: '7.00',
            values: ['7.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '8.00',
            id: '8.00',
            values: ['8.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '10.00',
            id: '10.00',
            values: ['10.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '13.00',
            id: '13.00',
            values: ['13.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '15.00',
            id: '15.00',
            values: ['15.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '18.00',
            id: '18.00',
            values: ['18.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '20.00',
            id: '20.00',
            values: ['20.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '21.00',
            id: '21.00',
            values: ['21.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '23.00',
            id: '23.00',
            values: ['23.00'],
            __typename: 'FilterOption',
          },
          {
            displayName: '24.00',
            id: '24.00',
            values: ['24.00'],
            __typename: 'FilterOption',
          },
        ],
        __typename: 'RateValueResponse',
      },
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

import '@/components/ChatWidget/ChatMessage/styles.scss';
import './styles.scss';

import { Col, Row } from 'react-bootstrap';
import React, { HTMLAttributes } from 'react';

import ChatMessageAvatar from '@/components/ChatWidget/ChatMessage/ChatMessageAvatar';
import ChatMessageBubble from '@/components/ChatWidget/ChatMessage/ChatMessageBubble';
import { getClasses } from '@/utils';

type ChatMessageProps = HTMLAttributes<HTMLDivElement> & {
  message: any;
};

const ChatMessage = ({ message, ...props }: ChatMessageProps) => {
  return (
    <div className={getClasses('ChatMessage', props?.className)}>
      <Row
        className={getClasses(
          message?.role_name === 'crew' ? 'flex-row' : '',
          ['dispatch', 'driver'].includes(message?.role_name) ? 'flex-row-reverse' : '',
          'flex-nowrap'
        )}
      >
        <Col className="flex-grow-0 pl-0">
          <ChatMessageAvatar url={message?.user_profile_image_url} />
        </Col>
        <Col sm="10">
          <ChatMessageBubble {...message} />
        </Col>
      </Row>
    </div>
  );
};

export default ChatMessage;

import {
  DATETIME_FORMAT,
  DATETIME_INPUT_FORMAT,
  DATETIME_RFC_FORMAT,
  DATE_FE_FORMAT,
  DATE_FE_FORMAT_SHORT,
  DATE_FORMAT,
  DATE_INPUT_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_FULL,
} from '../constants';

export enum TimestampFormat {
  DATE = DATE_FORMAT,
  DATE_INPUT = DATE_INPUT_FORMAT,
  DATE_FE = DATE_FE_FORMAT,
  DATE_FE_SHORT = DATE_FE_FORMAT_SHORT,
  TIME = TIME_FORMAT,
  TIME_FULL = TIME_FORMAT_FULL,
  DATETIME = DATETIME_FORMAT,
  DATETIME_INPUT = DATETIME_INPUT_FORMAT,
  DATETIME_RFC = DATETIME_RFC_FORMAT,
}
export enum LineItemTypeId {
  FUEL_SURCHARGE = 'ed4b6bdf-6db8-11ed-b87c-0242ac150003',
  TOLLS = 'ed4b6c3d-6db8-11ed-b87c-0242ac150003',
  COMBINE_DISCOUNT = 'ed4b6c9b-6db8-11ed-b87c-0242ac150003',
  GENERIC = '33b9a9d8-77e7-11ed-aade-0242ac140004',
  NET_15_DISCOUNT = '856f5db0-2d17-11ee-a51c-f15ef2b450c7',
}

export enum Orientation {
  Portrait = 'PORTRAIT',
  Landscape = 'LANDSCAPE',
}

export enum Position {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT',
}

import { PersistConfig, persistReducer } from 'redux-persist';
import { bindMethods, generateSlice, getLocalStorage, getResultFromState, parseStoredReduxState, setLocalStorage } from 'utils';
import { useDispatch, useSelector } from 'react-redux';

import useOnChange from 'hooks/useOnChange';

/* generatePageReducer
  This is only a concept. The idea behind it is to act similar to just
  using generateSlice, but bundling in a default state for our standard
  concept of a "page" on the site. Each page tends to have similar data
  with needs, so the state for what is usually needed is added as a
  default, while the function still allows for custom initial state to
  be supplied so that pages with other requirements can still be made
  using this to keep things standardized.

  Running this will return the following as a tupple:
  - The Redux Slice that created the reducer and its actions.
  - A basic usePage-style hook for react components.
  - the functional page-state method to manage the state functionally.

  Usage of this is purely meant to standardize how our pages can use
  the new global state/reducer setup.

  Example:
    src/store/tripsReducer.ts
      import generatePageReducer from './pageReducer';

      const [tripsSlice, useTripsState, tripsState] = generatePageReducer('trips', {
        initialState: {
          customKey: ''
        },
        customActions: [customAction, customAction2],
        persistConfig: {
          // ...
        }
      });

      export { useTripsState, tripsState };
      export default tripsSlice.reducer;

    src/store/index.ts
      import tripsReducer from './tripsReducer';

      // ...

      trips: tripsReducer,

      // ...
*/

type ReducerOptions = {
  initialState?: any;
  customActions?: Function[];
  persistConfig?: PersistConfig<any>;
};
const generatePageReducer = (name: string, options?: ReducerOptions): any => {
  const { initialState = {}, customActions = [], persistConfig } = options || {};
  const pageSlice = generateSlice(name, {
    // records: [],
    // selected: [],
    filters: {},
    ...(initialState || {}),
  });

  const usePage = (selector: any = (fullState: any): any => fullState): any[] => {
    const { _persist = {}, ...page } = useSelector((state: any): any => state?.[name] || {});
    const state = selector(page);
    const dispatch = useDispatch();

    const actualSetState = (payload: any): any => dispatch(pageSlice.actions.setState({ ...getResultFromState(payload, state), _persist }));
    const onChange = useOnChange(actualSetState, initialState?.filters || {});
    const setState = bindMethods(actualSetState, dispatch, onChange, ...customActions);

    return [state, setState];
  };
  // This is a basic "hook" format for use in functions, outside of React Components.
  let pageState;
  if (persistConfig) {
    pageSlice.reducer = persistReducer(persistConfig, pageSlice.reducer);
    pageState = (selector: any = (fullState: any): any => fullState): any => {
      const stored = getLocalStorage(`persist:${name}`, initialState);
      const { _persist = {}, ...page } = parseStoredReduxState(stored);
      const state = selector(page || {});
      const setState = (payload: any): void => setLocalStorage(`persist:${name}`, { ...getResultFromState(payload, state), _persist });
      return [state, setState];
    };
  }

  return [pageSlice, usePage, pageState, persistConfig];
};

export default generatePageReducer;

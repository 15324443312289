import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist';

import TripsReducer from './TripsReducer';
import appReducer from './appReducer';
import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modalReducer';

const store = configureStore({
  reducer: {
    app: appReducer,
    trips: TripsReducer,
    modals: modalReducer,
  },
  middleware: (getDefaultMiddleware: any): any =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }),
});
const persistor = persistStore(store);

export default store;
export { persistor };

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRangePicker {
  display: flex;
  min-width: 20rem;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
}
.DateRangePicker .ant-picker-input input {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.DateRangePicker .ant-picker-input .ant-picker-suffix {
  margin-left: -0.25rem;
}
.DateRangePicker .ant-picker-input .ant-picker-clear {
  margin-right: 0.75rem;
}
.DateRangePicker .fa-arrow-right {
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/DateRangePicker/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AACN;AACI;EACE,qBAAA;AACN;AACI;EACE,qBAAA;AACN;AAEE;EACE,iBAAA;AAAJ","sourcesContent":[".DateRangePicker {\n  display: flex;\n  min-width: 20rem;\n  width: 100%;\n  flex-wrap: nowrap;\n  align-items: center;\n}\n.DateRangePicker .ant-picker-input input {\n  padding-left: 0;\n  padding-right: 0;\n  text-align: center;\n}\n.DateRangePicker .ant-picker-input .ant-picker-suffix {\n  margin-left: -0.25rem;\n}\n.DateRangePicker .ant-picker-input .ant-picker-clear {\n  margin-right: 0.75rem;\n}\n.DateRangePicker .fa-arrow-right {\n  font-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PunchPolicyDetails {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 4rem;
}
.PunchPolicyDetails *:not(.sv.sv-clock) {
  color: var(--bs-primary);
  font-size: 0.9rem;
}
.PunchPolicyDetails > .row > .col-md-10 {
  padding: 0;
}
.PunchPolicyDetails .primary-slider .PunchPolicySlider .RangeSlider {
  margin: 3rem 0.5rem 0.5rem 0.5rem;
}
.PunchPolicyDetails .time-controls.row > .col > .row:first-child {
  margin-top: 2rem;
}
.PunchPolicyDetails .time-controls.row .ConfirmationButton {
  border: none;
  width: 2.5rem;
  height: 2.5rem;
}
.PunchPolicyDetails .time-controls.row .add-punch-policy-button:hover {
  color: #fff;
}
.PunchPolicyDetails .time-controls.row .add-punch-policy-button:hover i {
  color: #fff;
  border: 1px solid #fff !important;
}

.punch-policy-tooltip {
  border: 1px solid var(--bs-primary);
}
.punch-policy-tooltip .tippy-arrow::after {
  content: "";
  position: absolute;
  top: 0.95rem;
  left: 0.25rem;
  width: 0.85rem;
  height: 0.85rem;
  transform: rotate(-45deg);
  background-color: transparent;
  border-left: 1px solid var(--bs-primary);
  border-bottom: 1px solid var(--bs-primary);
}`, "",{"version":3,"sources":["webpack://./src/pages/PunchPolicy/components/PunchPolicyDetails/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EACE,wBAAA;EACA,iBAAA;AAEJ;AACE;EACE,UAAA;AACJ;AAEE;EACE,iCAAA;AAAJ;AAII;EACE,gBAAA;AAFN;AAKI;EACE,YAAA;EACA,aAAA;EACA,cAAA;AAHN;AAMM;EACE,WAAA;AAJR;AAKQ;EACE,WAAA;EACA,iCAAA;AAHV;;AAUA;EACE,mCAAA;AAPF;AASI;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;EACA,6BAAA;EACA,wCAAA;EACA,0CAAA;AAPN","sourcesContent":[".PunchPolicyDetails {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n  padding: 0 4rem;\n}\n.PunchPolicyDetails *:not(.sv.sv-clock) {\n  color: var(--bs-primary);\n  font-size: 0.9rem;\n}\n.PunchPolicyDetails > .row > .col-md-10 {\n  padding: 0;\n}\n.PunchPolicyDetails .primary-slider .PunchPolicySlider .RangeSlider {\n  margin: 3rem 0.5rem 0.5rem 0.5rem;\n}\n.PunchPolicyDetails .time-controls.row > .col > .row:first-child {\n  margin-top: 2rem;\n}\n.PunchPolicyDetails .time-controls.row .ConfirmationButton {\n  border: none;\n  width: 2.5rem;\n  height: 2.5rem;\n}\n.PunchPolicyDetails .time-controls.row .add-punch-policy-button:hover {\n  color: #fff;\n}\n.PunchPolicyDetails .time-controls.row .add-punch-policy-button:hover i {\n  color: #fff;\n  border: 1px solid #fff !important;\n}\n\n.punch-policy-tooltip {\n  border: 1px solid var(--bs-primary);\n}\n.punch-policy-tooltip .tippy-arrow::after {\n  content: \"\";\n  position: absolute;\n  top: 0.95rem;\n  left: 0.25rem;\n  width: 0.85rem;\n  height: 0.85rem;\n  transform: rotate(-45deg);\n  background-color: transparent;\n  border-left: 1px solid var(--bs-primary);\n  border-bottom: 1px solid var(--bs-primary);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { GraphApiResponse } from '../../core';
import { PreviewDetails } from '../../../models/gen/graphql';
import { RunPreviewImportsDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils';

type RunPreviewImportsGraphApiResponse = GraphApiResponse<typeof RunPreviewImportsDocument>;

const title = 'Run Preview Imports';

const runPreviewImportsResponseSelector = (res: RunPreviewImportsGraphApiResponse): Array<PreviewDetails> =>
  !res.errors ? ((res?.runPreviewImports?.previews || []) as Array<PreviewDetails>) : undefined;

const [runRunPreviewImports] = graphApi(RunPreviewImportsDocument, {
  onError: (res: RunPreviewImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runPreviewImports = async (importIds: string | string[]): Promise<Array<PreviewDetails>> => {
  const input = { importIds: Array.isArray(importIds) ? importIds : [importIds] };
  const res = await runRunPreviewImports({ input });
  const selected = runPreviewImportsResponseSelector(res);

  return selected;
};

export const useRunPreviewImports = createGraphApiHook(runPreviewImports);

export default runPreviewImports;

import { PickupPoint, UpdatePickupPointBulkInput, UpdateResponse } from '../../../models/gen/graphql';
import { createNotification, generateUpdateBulkPayload, handleError } from '../../../utils/custom';

import { GraphApiResponse } from '../../core';
import Toast from '../../../models/Toast';
import { UpdatePickupPointBulkDocument } from '../../queries';
import { Validation } from '../../../utils/validations';
import { createPickupPointValidator } from './createPickupPointBulk';
import graphApi from '../..';

type UpdatePickupPointGraphApiResponse = GraphApiResponse<typeof UpdatePickupPointBulkDocument>;

const title = 'Update Pickup Point';

export const updatePickupPointValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createPickupPointValidator
);
const UpdatePickupPointBulkResponseSelector = (res: UpdatePickupPointGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.updatePickupPointBulk?.response : undefined;

export const [runUpdatePickupPointBulk] = graphApi(UpdatePickupPointBulkDocument, {
  onError: (res: UpdatePickupPointGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const updatePickupPointBulk = async (pickupPoints: PickupPoint[]): Promise<UpdateResponse[]> => {
  pickupPoints = pickupPoints.map((pickupPoint: PickupPoint): PickupPoint => updatePickupPointValidator.partial(pickupPoint));
  const updates = generateUpdateBulkPayload(pickupPoints);
  const input: UpdatePickupPointBulkInput = {
    updates,
  };
  const res = await runUpdatePickupPointBulk({ input });
  const data = UpdatePickupPointBulkResponseSelector(res);

  if (!data) return;
  createNotification('Success', Toast.Type.SUCCESS, title);
  return data;
};

export default updatePickupPointBulk;

import { useMemo } from 'react';

/* useSwitch
  A simple hook to memoize switch/case behavior.

  Example:
    const title = useSwitch(path, {
      '/login': 'Login',
      '/profile': 'Profile',
    });

  The previous example would result in title being `Profile` if the variable `path`
  was equal to `/profile`, and this would be memoized to only change if `path`
  changes.

  There are 2 ways to supply default values to this hook as well. The first being
  a third parameter being used, and the second way is to provide a key/value case
  for '*' - basically a wildcard.

  The result of this hook can be anything.
*/

type Conditional = (a: any) => boolean;

const useSwitch = (basis: any, cases: any, defaultCase?: any): any => {
  const result = useMemo((): any => {
    if (typeof cases === 'function') return cases(basis);
    let result = cases[basis] || cases['*'] || defaultCase;
    if (typeof result === 'function') result = result();
    return result;
  }, [cases, basis]);

  return result;
};

export default useSwitch;

import { AcceptInputOption, ReportFilterEnum, ReportInput, ReportSeriesEnum, TripTableFormatEnum } from '@/models/gen/graphql';
import React, { useCallback } from 'react';
import { handleError, printScreen } from '@/utils/custom';

import ActionsDropdown from '@/components/ActionsDropdown';
import { Datetime } from '@/utils/dates';
import { GetCompletionReasonsDocument } from '@/api/queries';
import { reportQueryAsCsv } from '@/api/services/reports/reportQuery';
import { saveFile } from '@/utils/promises';
import useDb from '@/hooks/useDb';
import { useMemo } from 'react';

type TripActionsDropdownProps = {
  format: TripTableFormatEnum;
  from: string;
  to: string;
  airportCode: string[];
  payerProviderId: string;
  puLocationId: string[];
  doLocationId: string[];
  rateAmount: string;
  servicerIataAirlineCode: string;
  type: string[];
};

const ACTION_ITEMS = [
  { key: 'exportCsv', label: 'Export As CSV' },
  { key: 'print', label: 'Print' },
];

const TripActionsDropdown = ({
  format,
  from,
  to,
  airportCode,
  payerProviderId,
  puLocationId,
  doLocationId,
  rateAmount,
  servicerIataAirlineCode,
  type,
}: TripActionsDropdownProps): JSX.Element => {
  const { data: getCompletionReasonsResponse } = useDb('CompletionReasons', GetCompletionReasonsDocument, {
    lazy: true,
    ttl: 360, // cache for 1 day
  });
  const completionReasons = useMemo(
    () => getCompletionReasonsResponse?.getFilters?.filters?.completionReasons || [],
    [getCompletionReasonsResponse]
  );

  const getCompletionTypes = useCallback((): string[] => {
    const noShow = completionReasons.find((reason: AcceptInputOption): boolean => reason?.displayName === 'No Show')?.id;
    const taxiCalled = completionReasons
      ?.filter((reason: AcceptInputOption): boolean => ['Taxi SHG Paid', 'Taxi Crew Paid', 'Lyft'].includes(reason?.displayName))
      ?.map((reason: AcceptInputOption): string => reason?.id);
    switch (format) {
      case TripTableFormatEnum.NoShow:
        return noShow ? [noShow] : null;
      case TripTableFormatEnum.TaxiCalled:
        return taxiCalled;
      default:
        return null;
    }
  }, [format, completionReasons]);

  const formatReportInput = (): ReportInput => {
    const payload: ReportInput = { series: ReportSeriesEnum.SeriesDefault, startDatetime: from, endDatetime: to };
    const completionTypes = getCompletionTypes();
    const complexFilters = convertTripTableFormatEnumToComplexFilters(format);
    // conditionally apply fields from props
    if (servicerIataAirlineCode) payload.airlines = [servicerIataAirlineCode];
    if (airportCode) payload.airports = airportCode;
    if (payerProviderId) payload.clients = [payerProviderId];
    if (completionTypes) payload.completionTypes = completionTypes;
    if (complexFilters) payload.complexFilters = complexFilters;
    if (doLocationId) payload.doLocations = doLocationId;
    if (puLocationId) payload.puLocations = puLocationId;
    if (rateAmount) payload.rates = parseFloat(rateAmount) === 0 ? [ReportFilterEnum.ZeroRate.toString()] : [rateAmount];
    if (type) payload.types = type;
    return payload;
  };

  const getCsv = async (): Promise<void> => {
    try {
      const payload = formatReportInput();
      const response = await reportQueryAsCsv('detailedReport')(payload);
      if (!response) return;
      const blob = new Blob([response], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      saveFile(url, `Trips-${new Datetime().dateInput}.csv`);
    } catch (err) {
      handleError(err, { notification: { title: 'Export To CSV' } });
    }
  };

  const handleClick = (key: string): void => {
    switch (key) {
      case 'exportCsv':
        getCsv();
        break;
      case 'print':
        printScreen();
        break;
    }
  };

  return <ActionsDropdown items={ACTION_ITEMS} onClick={handleClick} />;
};

const convertTripTableFormatEnumToComplexFilters = (format: TripTableFormatEnum): ReportFilterEnum[] => {
  switch (format) {
    case TripTableFormatEnum.Completed:
      return [ReportFilterEnum.HasCompletion];
    case TripTableFormatEnum.Unassigned:
      return [ReportFilterEnum.HasNoDriver];
    default:
      return null;
  }
};

export default React.memo(TripActionsDropdown);

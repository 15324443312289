import { createNotification, handleError } from '../../../utils/custom';

import { DeleteImageBulkDocument } from '../../queries';
import { DeleteImageBulkInput } from '../../../models/gen/graphql';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import graphApi from '../..';

type DeleteImageGraphApiResponse = GraphApiResponse<typeof DeleteImageBulkDocument>;

const title = 'Delete Pickup Point Image(s)';

const DeleteImageBulkResponseSelector = (res: DeleteImageGraphApiResponse): number =>
  !res.errors ? res?.deleteImageBulk?.deleted || 0 : undefined;

export const [runDeleteImageBulk] = graphApi(DeleteImageBulkDocument, {
  onError: (res: DeleteImageGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteImageBulk = async (ids: string[]): Promise<number> => {
  const input: DeleteImageBulkInput = {
    search: ids.map((id: string): { id: string } => ({ id })),
  };
  const res = await runDeleteImageBulk({ input });
  const data = DeleteImageBulkResponseSelector(res);

  if (data) createNotification(`Successfully deleted ${data} pickup point Image(s).`, Toast.Type.SUCCESS, title);
  return data;
};

export default deleteImageBulk;

import { LocationTypeEnum } from '../../models/gen/graphql';
import { SelectLocationFilterDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const selectLocationFilter = createGraphApiMockResponse(SelectLocationFilterDocument, {
  searchLocations: {
    locationWithAirportConnection: {
      edges: [
        {
          cursor: '1',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: '\tSOFITEL NEW YORK HOTEL',
            displayName: 'EWR | \tSOFITEL NEW YORK HOTEL',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '2',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' ALOFT TPA DOWNTOWN ',
            displayName: 'TPA |  ALOFT TPA DOWNTOWN ',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '3',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' Andaz Maui Wailea Resort',
            displayName: 'OGG |  Andaz Maui Wailea Resort',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '4',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' BEST WESTERN PLUS LANDMARK INN      ',
            displayName: 'BNA |  BEST WESTERN PLUS LANDMARK INN      ',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '5',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' Courtyard South Beach',
            displayName: 'MIA |  Courtyard South Beach',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '6',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' DFW Charter Pad',
            displayName: 'DFW |  DFW Charter Pad',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '7',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' Fire Station',
            displayName: 'DFW |  Fire Station',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '8',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' HILTON GARDEN INN Henderson',
            displayName: 'LAS |  HILTON GARDEN INN Henderson',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '9',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' HYATT PLACE PROVO',
            displayName: 'SLC |  HYATT PLACE PROVO',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '10',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' INTERCONTINENTAL BUCKHEAD HOTEL',
            displayName: 'ATL |  INTERCONTINENTAL BUCKHEAD HOTEL',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '11',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' LA Memorial Coliseum',
            displayName: 'LAX |  LA Memorial Coliseum',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '12',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' Marriott SEA',
            displayName: 'SEA |  Marriott SEA',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '13',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' MLB Tropicana Field',
            displayName: 'TPA |  MLB Tropicana Field',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '14',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' SLS HOTESLS HOTEL',
            displayName: 'LAX |  SLS HOTESLS HOTEL',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '15',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: ' W Hotel',
            displayName: 'BNA |  W Hotel',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '16',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: '.COURTYARD DFW south',
            displayName: 'DFW | .COURTYARD DFW south',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '17',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: '1440 W Colonial Drive Winter Garden',
            displayName: 'MCO | 1440 W Colonial Drive Winter Garden',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '18',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: '1515 W. Riviera Drive',
            displayName: 'MCO | 1515 W. Riviera Drive',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '19',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: '1812 Bayshore Rd Nokomis, FL 34275',
            displayName: 'RSW | 1812 Bayshore Rd Nokomis, FL 34275',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
        {
          cursor: '20',
          node: {
            id: '61916138-454a-491e-b9e3-020ecf26579d',
            name: '2052 NE 120th Road ',
            displayName: 'MIA | 2052 NE 120th Road ',
            type: LocationTypeEnum.Hotel,
            __typename: 'Location',
          },
          __typename: 'LocationEdge',
        },
      ],
      __typename: 'LocationConnection',
    },
    __typename: 'SearchLocationsResponse',
  },
});

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { SearchCombinableTripConnectionInput } from '../../models/gen/graphql';
import { generateQuery } from '../../utils';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const queryKeys = ['id'];

const searchCombinableTrips: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, ...queryObjects: any[]): Promise<any> => {
      const input: SearchCombinableTripConnectionInput = {
        first,
        after,
        query: generateQuery(queryKeys, ...queryObjects),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: getConnectionDetails('searchCombinableTrips.combinableTripsConnection'),
  query: gql`
    query SearchCombinableTrips($input: SearchCombinableTripConnectionInput) {
      searchCombinableTrips {
        combinableTripsConnection(input: $input) {
          edges {
            cursor
            node {
              id
              kind
              combineId
              scheduled
              pilots
              attendants
              flightNumber
              trackFlight {
                id
                scheduled
                actual
                arrivalGate
                departureGate
                servicerIataAirlineCode
                number
                status
                label
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default searchCombinableTrips;

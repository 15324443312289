import { CreateRateForTripsInput, CreateRateForTripsResponse, CreateRateInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { CreateRateForTripsDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { createRateValidator } from './createRateBulk';
import graphApi from '../..';

type CreateRateForTripsGraphApiResponse = GraphApiResponse<typeof CreateRateForTripsDocument>;

const title = 'Create New Rate and Assign Trips';

const createRateForTripsResponseSelector = (res: CreateRateForTripsGraphApiResponse): CreateRateForTripsResponse =>
  !res.errors ? (res?.createRateForTrips as CreateRateForTripsResponse) : undefined;

const [runCreateRateForTrips] = graphApi(CreateRateForTripsDocument, {
  onError: (res: CreateRateForTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const createRateForTrips = async (rate: CreateRateInput, tripIds: string[]): Promise<CreateRateForTripsResponse> => {
  const createRateRequest = createRateValidator.create(rate);
  const input: CreateRateForTripsInput = { createRateRequest, tripIds };
  const res = await runCreateRateForTrips({ input });

  const selected = createRateForTripsResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default createRateForTrips;

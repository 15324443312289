import { FlagConnectionInput, FlagSearch } from '../../models/gen/graphql';

import GraphApi from '../../models/GraphApi';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchFlags: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (first: number = PAGE_SIZE, after: string = null, query: FlagSearch[] = null, options: any = {}): Promise<any> => {
      const input: FlagConnectionInput = {
        first,
        after,
        query: query && !!Object.keys(query).length ? query : null,
      };

      return fireQuery({
        variables: {
          input,
        },
        ...options,
        fetchPolicy: 'no-cache',
      });
    },
  selector: getConnectionDetails('searchFlags.flagConnection'),
  query: gql`
    query SearchFlags($input: FlagConnectionInput) {
      searchFlags {
        flagConnection(input: $input) {
          edges {
            cursor
            node {
              # active
              color
              # companyId
              # createdAt
              # createdBy
              # global
              id
              name
              # updatedAt
              # updatedBy
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default searchFlags;

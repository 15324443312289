import {
  CombineRule,
  CombineRuleWithContractSearch,
  CreateCombineRuleInput,
  SortDirectionEnum,
  UpdateCombineRuleValuesInput,
} from '@/models/gen/graphql';
import { QueryInputType, onEnter, queryInput, stringify } from '@/utils';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import SettingsPageGroup, { SettingsPageGroupProps } from '@/features/settings/components/SettingsPageGroup';

import CombineRulesTable from '@/features/settings/components/pages/CombineRules/CombineRulesTable';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import { ComplexTableRef } from '@/components/ComplexTable';
import FormField from '@/components/FormField';
import { GraphApiResponse } from '@/api/core';
import ImportContractProviderDropdown from '@/components/ImportContractProviderDropdown';
import { OnChange } from '@/hooks/useOnChange';
import PageInfo from '@/components/PageInfo';
import { useCreateCombineRuleBulk } from '@/features/settings/api/services/combineRule/createCombineRuleBulk';
import { useDeleteCombineRuleBulk } from '@/features/settings/api/services/combineRule/deleteCombineRuleBulk';
import { useSearchCombineRules } from '@/features/settings/api/services/combineRule/searchCombineRule';
import { useUpdateCombineRuleBulk } from '@/features/settings/api/services/combineRule/updateCombineRuleBulk';

const TITLE = 'Combine Rules';
const ICON = <i className="sv sv-earth" />;
const HEADER = { title: TITLE, icon: ICON };

type CombineRulesFilters = {
  contractId: string;
};
type CombineRulesState = {
  search: string;
  sorting: { column: string; direction: string };
  count: number;
  valid: boolean;
  dirty: boolean;
  loading: boolean;
};
const initCombineRulesFilters: CombineRulesFilters = {
  contractId: undefined,
};
const initCombineRulesState: CombineRulesState = {
  search: '',
  sorting: { column: undefined, direction: undefined },
  count: 0,
  valid: true,
  dirty: false,
  loading: false,
};
const CombineRules = (): ReactNode => {
  const [{ data: { rows: data = [] } = {}, loading: loadingCombineRules }, { refetch: getCombineRules }] = useSearchCombineRules();
  const [{ loading: creatingCombineRules }, { fetch: createCombineRules }] = useCreateCombineRuleBulk();
  const [{ loading: updatingCombineRules }, { fetch: updateCombineRules }] = useUpdateCombineRuleBulk();
  const [{ loading: deletingCombineRules }, { fetch: deleteCombineRules }] = useDeleteCombineRuleBulk();
  const [state, setState] = useState<CombineRulesState>({ ...initCombineRulesState, count: data.length });
  const { search, count, valid, dirty } = state;

  const loading = useMemo(
    (): boolean => loadingCombineRules || creatingCombineRules || updatingCombineRules || deletingCombineRules,
    [loadingCombineRules, creatingCombineRules, updatingCombineRules, deletingCombineRules]
  );
  const complexTableRef = useRef<ComplexTableRef>(null);

  const onChange = useCallback(async (): Promise<void> => {
    if (complexTableRef?.current?.deletions?.length) {
      try {
        const deleteCombineRuleBulkPayload = complexTableRef?.current?.deletions?.flatMap(
          (item: ComplexFormItem<CombineRule>): string[] => item?.id
        );
        await deleteCombineRules(deleteCombineRuleBulkPayload);
        complexTableRef?.current?.setDeletions([]);
        getCombineRules();
      } catch (err) {
        console.error(err);
      }
    }
    setState(
      (current: CombineRulesState): CombineRulesState => ({
        ...current,
        count: complexTableRef?.current?.items.filter((item: ComplexFormItem<CombineRule>): boolean => !!item.data).length,
        valid: complexTableRef?.current?.isValid,
        dirty: complexTableRef?.current?.isDirty,
      })
    );
  }, [deleteCombineRules, getCombineRules]);

  const handleSubmit = useCallback(
    async (filters?: CombineRulesFilters): Promise<void> => {
      const query: CombineRuleWithContractSearch = {
        createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0), // default sort by created time
      };
      if (filters?.contractId) query.contractId = queryInput(filters.contractId); // filter by contract
      getCombineRules([query]);
    },
    [getCombineRules]
  );

  const onSave = useCallback(async (): Promise<void> => {
    const transaction = [];
    if (complexTableRef?.current?.additions?.length) {
      transaction.push(async (): Promise<CombineRule[]> => {
        const result = await createCombineRules(
          complexTableRef?.current?.additions?.map((item: ComplexFormItem<CombineRule>): CreateCombineRuleInput => item?.data)
        );
        return result;
      });
    }
    if (complexTableRef?.current?.updates?.length) {
      transaction.push(async (): Promise<GraphApiResponse> => {
        const updateCombineRuleBulkPayload = complexTableRef?.current?.updates?.map(
          (item: ComplexFormItem<CombineRule>): UpdateCombineRuleValuesInput & { id: string } => ({
            id: item?.id,
            ...Object.entries(item?.data).reduce(
              (acc: Record<string, unknown[]>, [key, value]: [string, unknown]): Record<string, unknown[]> => {
                acc[key] = [value];
                return acc;
              },
              {}
            ),
          })
        );
        const result = await updateCombineRules(updateCombineRuleBulkPayload);
        return result;
      });
    }

    if (transaction.length)
      try {
        await Promise.all(transaction.map((fn) => fn()));
        complexTableRef?.current?.onReset();
        getCombineRules();
      } catch (err) {
        console.error(err.message || err);
      }
  }, [createCombineRules, updateCombineRules, getCombineRules]);

  const footerProps = useMemo((): { onAdd?: false | (() => void); onCancel?: false | (() => void); onSave?: false | (() => void) } => {
    return {
      onAdd: (): void => complexTableRef?.current?.onAdd?.(),
      onCancel: dirty ? (): void => complexTableRef?.current?.onReset?.() : false,
      onSave: dirty && valid ? (): Promise<void> => onSave?.() : false,
    };
  }, [dirty, valid, onSave]);

  const filterProps = useMemo(
    (): SettingsPageGroupProps['filters'] => ({
      name: 'combineRulesFilters',
      value: initCombineRulesFilters,
      onSubmit: handleSubmit,
      onReset: () => {
        setState((current: CombineRulesState): CombineRulesState => ({ ...current, search: '' }));
        return {};
      },
      submitOnMount: true,
      primary: ({ values, onChange }: { values: CombineRulesFilters; onChange: OnChange }): JSX.Element => {
        const { contractId } = values;
        return (
          <>
            <ImportContractProviderDropdown
              value={contractId}
              onChange={(value: string): void => onChange({ target: { name: 'contractId', value } })}
            />
          </>
        );
      },
      controls: ({ values, onChange }): JSX.Element => {
        const { search } = values;
        return (
          <>
            <FormField
              prepend={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={search || ''}
              onChange={onChange}
              onBlur={(): void => setState((current: CombineRulesState): CombineRulesState => ({ ...current, search }))}
              onKeyDown={onEnter((): void => {
                setState((current: CombineRulesState): CombineRulesState => ({ ...current, search }));
              })}
              placeholder="Search"
              condensed
            />
          </>
        );
      },
    }),
    [handleSubmit]
  );

  const filter = useCallback(
    (item: ComplexFormItem<CombineRule>): boolean =>
      !search ||
      stringify([
        item.data?.provider?.name,
        item.data?.contractId,
        item.data?.pickupWindow,
        item.data?.turnaroundThreshold,
        item.data?.discount,
        item.data?.createdAt,
        item.data?.createdBy,
      ])
        .toLowerCase()
        .includes(search.toLowerCase()),
    [search]
  );

  return (
    <SettingsPageGroup header={HEADER} footer={footerProps} filters={filterProps}>
      <PageInfo>Variable Times: {count}</PageInfo>
      <CombineRulesTable data={data} onChange={onChange} options={{ loading, filter }} ref={complexTableRef} />
    </SettingsPageGroup>
  );
};

export default CombineRules;

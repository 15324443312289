import React, { ErrorInfo, ReactNode } from 'react';

import DefaultError from './DefaultError';

export type CatchErrorProps = {
  fallback?: () => ReactNode | JSX.Element;
  onError?: (err: Error, info: ErrorInfo) => Promise<void>;
  children?: ReactNode;
};
export type CatchErrorState = { hasError: boolean };

class CatchError extends React.Component<CatchErrorProps, CatchErrorState> {
  public state: CatchErrorState = { hasError: false };
  public static getDerivedStateFromError(): CatchErrorState {
    return { hasError: true };
  }
  public componentDidCatch(error: Error, info: ErrorInfo): void {
    this?.props?.onError?.(error, info);
  }
  public render(): any {
    //console.log('hasError', this?.state?.hasError);
    if (this?.state?.hasError) {
      const Fallback = this?.props?.fallback || DefaultError;
      return <Fallback />;
    }

    return this?.props?.children;
  }
}

export default CatchError;

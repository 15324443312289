import '@/components/ChatWidget/ChatList/styles.scss';

import { Message, RoomsState, useChatState } from '@/hooks/useSocket/chat/useChatRooms';
import React, { HTMLAttributes, useState } from 'react';
// import { Chat } from '@/components/ChatWidget/datamock';
import { getClasses, getResultFromState } from '@/utils';

import { Accordion } from 'react-bootstrap';
import ChatListContent from '@/components/ChatWidget/ChatList/ChatListContent';
import ChatListHeader from '@/components/ChatWidget/ChatList/ChatListHeader';

type ChatListProps = HTMLAttributes<HTMLDivElement> & {
  rooms: RoomsState;
};

const ChatList = ({ rooms, ...props }: ChatListProps) => {
  const [activeRoom, setActiveRoom] = useState(undefined);

  return (
    <div {...props} className={getClasses('ChatList', props?.className)}>
      <Accordion onSelect={setActiveRoom}>
        {Object.entries(rooms)?.map(([roomId, room]) => {
          return (
            <Accordion.Item eventKey={roomId} key={roomId}>
              <ChatRoom
                active={activeRoom === roomId}
                name={room?.displayName}
                avatar={room?.displayImageUrl}
                iata={room?.trip?.servicerIataAirlineCode}
                scheduled={room?.trip?.tripScheduled}
                latest={room?.latestMessage}
                roomId={roomId}
                eventKey={roomId}
              />
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export const useChatRoom = (
  roomId
): [RoomsState[string], (room: RoomsState[string] | ((room: RoomsState[string]) => RoomsState[string])) => void] => {
  const [room, setChatState] = useChatState(({ state, setState }) => [state.rooms[roomId], setState]);
  const setRoom = (room: RoomsState[string] | ((room: RoomsState[string]) => RoomsState[string])) =>
    setChatState((current) => ({ ...current, rooms: { ...current.rooms, [roomId]: getResultFromState(room, current.rooms[roomId]) } }));
  return [room, setRoom];
};

const ChatRoom = ({
  name,
  avatar,
  iata,
  scheduled,
  latest,
  roomId,
  eventKey,
  active,
}: {
  name: string;
  avatar: string;
  iata: string;
  scheduled: string;
  latest: Message;
  roomId: string;
  eventKey: string;
  active: boolean;
}) => {
  const [room] = useChatRoom(roomId);
  return (
    <>
      <ChatListHeader
        name={name}
        displayImageUrl={avatar}
        servicerIataAirlineCode={iata}
        scheduledTime={scheduled}
        latestMessage={latest}
        roomId={roomId}
        eventKey={eventKey}
        unread={room.unreadCount || 0}
      />
      {active && <ChatListContent roomId={roomId} />}
    </>
  );
};

export default ChatList;

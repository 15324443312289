import { Button, Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';

import DynamicContent from '@/components/DynamicContent';

const MiscSandbox = (): JSX.Element => {
  const [state, setState] = useState({ show: false });
  const { show } = state;

  return (
    <>
      <Row>
        <Col>
          <Button onClick={() => setState((current) => ({ ...current, show: !current.show }))} disabled={show}>
            Show
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <DynamicContent
            show={show}
            placement="left"
            onHide={() => setState((current) => ({ ...current, show: false }))}
            as="modal"
            modal
            inline={false}
            drawer
          >
            <h1>Testing</h1>
          </DynamicContent>
        </Col>
      </Row>
    </>
  );
};

export default MiscSandbox;

import { Datetime, Validation, createNotification, generateUpdateBulkPayload, getDiff, handleError } from '@/utils';
import { UpdateUserAvailabilityBulkDocument, UpdateUserAvailabilitySeriesBulkDocument } from '@/api/queries';
import { UpdateUserAvailabilityBulkInput, UpdateUserAvailabilityBulkResponse, UserAvailability } from '@/models/gen/graphql';

import { GraphApiResponse } from '@/api/core';
import Logger from '@/utils/logs';
import { Toast } from '@/models';
import { createUserAvailabilityValidator } from './createUserAvailabilityBulk';
import graphApi from '../..';

type UpdateUserAvailabilityBulkGraphApiResponse = GraphApiResponse<typeof UpdateUserAvailabilityBulkDocument>;
type UpdateUserAvailabilitySeriesBulkGraphApiResponse = GraphApiResponse<typeof UpdateUserAvailabilitySeriesBulkDocument>;

const log = Logger.of('UpdateUserAvailability');

const updateUserAvailabilityValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createUserAvailabilityValidator
);
const getPartialUserAvailability = (data: Partial<UserAvailability>, original: UserAvailability): Partial<UserAvailability> => {
  // check date and time inputs have a value
  if (!data?.startTime || !data?.endTime) throw new Error('Please select a start and end time.');
  if (!data?.endDate || !data?.startDate) throw new Error('Missing start and end date.');

  // format Input
  const startDateTime = new Datetime(data.startDate).setTime(data.startTime);
  let endDateTime = new Datetime(data.startDate).setTime(data.endTime);

  if (startDateTime.asDayjs().isSame(endDateTime.toString())) throw new Error('Availability cannot start and stop at the same time.');
  if (startDateTime.asDayjs().isAfter(endDateTime.toString())) endDateTime = endDateTime.add(1, 'day').setTime(data.endTime);

  const formatData: Partial<UserAvailability> = {
    ...data,
    startDate: startDateTime.setTime('00:00:00').toString(),
    endDate: endDateTime.setTime('00:00:00').toString(),
    startTime: startDateTime.setTime(data?.startTime).fullTime,
    endTime: endDateTime.setTime(data?.endTime).fullTime,
    dayOfWeek: new Datetime(data?.startDate).asDayjs().day(),
  };

  // get partial
  const [partial] = getDiff(original, formatData);

  // check if any changes were made
  if (!Object.values(partial).length) throw new Error('No changes were made to the availability.');
  if (partial?.repeatUntil) throw new Error('Unable to update user availability series');

  //update
  // after checking there are user input values to update; set the non user mutated fields
  return partial;
};

const updateUserAvailabilityBulkResponseSelector = (res: UpdateUserAvailabilityBulkGraphApiResponse): UpdateUserAvailabilityBulkResponse =>
  !res.errors ? res?.updateUserAvailabilityBulk : undefined;
const updateUserAvailabilitySeriesBulkResponseSelector = (
  res: UpdateUserAvailabilitySeriesBulkGraphApiResponse
): UpdateUserAvailabilityBulkResponse => (!res.errors ? res?.updateUserAvailabilitySeriesBulk : undefined);

const [runUpdateUserAvailabilityBulk] = graphApi(UpdateUserAvailabilityBulkDocument, {
  onError: (res: UpdateUserAvailabilityBulkGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Update User Availability' } }),
});
const [runUpdateUserAvailabilitySeriesBulk] = graphApi(UpdateUserAvailabilitySeriesBulkDocument, {
  onError: (res: UpdateUserAvailabilitySeriesBulkGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Update User Availability Series' } }),
});

const updateUserAvailabilityBulk = async (
  userAvailabilities: Array<Partial<UserAvailability>>
): Promise<UpdateUserAvailabilityBulkResponse> => {
  userAvailabilities = userAvailabilities.map(
    (userAvailability: UserAvailability): UserAvailability => updateUserAvailabilityValidator.partial(userAvailability)
  );
  const updates = generateUpdateBulkPayload(userAvailabilities);
  const input: UpdateUserAvailabilityBulkInput = {
    updates,
  };
  const res = await runUpdateUserAvailabilityBulk({ input });

  const selected = updateUserAvailabilityBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Update User Availability');
  return selected;
};

const updateUserAvailabilitySeriesBulk = async (
  userAvailabilities: Array<Partial<UserAvailability>>
): Promise<UpdateUserAvailabilityBulkResponse> => {
  userAvailabilities = userAvailabilities.map(
    (userAvailability: UserAvailability): UserAvailability => updateUserAvailabilityValidator.partial(userAvailability)
  );
  const updates = generateUpdateBulkPayload(userAvailabilities);
  const input: UpdateUserAvailabilityBulkInput = {
    updates,
  };
  const res = await runUpdateUserAvailabilitySeriesBulk({ input });

  const selected = updateUserAvailabilitySeriesBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Update User Availability Series');
  return selected;
};

export const handleUpdateAvailability = async (
  data: Partial<UserAvailability>,
  original: UserAvailability
): Promise<UpdateUserAvailabilityBulkResponse> => {
  try {
    // Check If We are updating an availability & verify original has an id
    if (!Object.values(original).length || !original?.id) throw new Error('No user availability to update');

    const partial = getPartialUserAvailability(data, original);
    partial.id = original.id;

    return await updateUserAvailabilityBulk([partial]);
  } catch (err) {
    log.error('handleUpdateAvailabilityBulk', err?.message).notify({ title: 'Update User Availability' });
  }
};
export const handleUpdateAvailabilitySeries = async (
  data: Partial<UserAvailability>,
  original: UserAvailability
): Promise<UpdateUserAvailabilityBulkResponse> => {
  try {
    // Check If We are updating an availability & verify original has an id
    if (!Object.values(original).length || !original?.id) throw new Error('No user availability to update');

    const partial = getPartialUserAvailability(data, original);
    partial.id = original.id;

    return await updateUserAvailabilitySeriesBulk([partial]);
  } catch (err) {
    log.error('handleUpdateAvailabilitySeriesBulk', err?.message).notify({ title: 'Update User Availability Series' });
  }
};

export default updateUserAvailabilityBulk;

import { useAppState } from '../store/appReducer';

//TODO: Update app state selector logic to setAppState the same way setPage does but can handle deeply nested state using the selector
const usePage = (page: string): [Record<string, any>, (fn: (current: Record<string, any>) => void | Record<string, any>) => void] => {
  const [state, setState] = useAppState();
  const pageState = state?.page?.[page] || {};

  const setPage = (fn: (current: Record<string, any>) => Record<string, any> | Record<string, any>): void => {
    setState((current) => ({
      ...current,
      page: {
        ...(current?.page || {}),
        [page]: typeof fn === 'function' ? fn(current?.page?.[page]) : fn,
      },
    }));
  };

  return [pageState, setPage];
};

export default usePage;

import gql from 'graphql-tag';
import { validateObj } from '../../utils';

const validateFlag = validateObj({
  active: (val) => parseInt(val) || 0,
  color: (val) => val || '',
  companyId: (val) => val,
  global: (val) => parseInt(val) || 0,
  name: (val) => val || '',
});

const createFlagBulk = {
  query: gql`
    mutation CreateFlagBulk($input: CreateFlagBulkInput!) {
      createFlagBulk(input: $input) {
        output {
          error
          node {
            # active
            # color
            # companyId
            # createdAt
            # createdBy
            # global
            id
            # name
            # updatedAt
            # updatedBy
          }
        }
      }
    }
  `,
  wrapper:
    (fireQuery) =>
    async (...flags) => {
      const values = flags.map(validateFlag);

      return fireQuery({
        variables: {
          input: { values },
        },
      });
    },
  selector: (data) => data?.createFlagBulk?.output || [],
};

export default createFlagBulk;
export { validateFlag };

import { DeleteAirportHasVehicleBulkInput, DeleteAirportHasVehicleBulkResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { DeleteAirportHasVehicleBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { validateCreateAirportHasVehicle } from './createAirportHasVehicleBulk';

type DeleteAirportHasVehicleBulkGraphApiResponse = GraphApiResponse<typeof DeleteAirportHasVehicleBulkDocument>;

const title = 'Delete Airport Has Vehicle';

const deleteAirportHasVehicleBulkResponseSelector = (
  res: DeleteAirportHasVehicleBulkGraphApiResponse
): DeleteAirportHasVehicleBulkResponse => (!res.errors ? res?.deleteAirportHasVehicleBulk : undefined);

const [runDeleteAirportHasVehicleBulk] = graphApi(DeleteAirportHasVehicleBulkDocument, {
  onError: (res: DeleteAirportHasVehicleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const deleteAirportHasVehicleBulk = async (ids: string[]): Promise<DeleteAirportHasVehicleBulkResponse> => {
  const input: DeleteAirportHasVehicleBulkInput = {
    search: ids.map(validateCreateAirportHasVehicle),
  };
  const res = await runDeleteAirportHasVehicleBulk({ input });

  const selected = deleteAirportHasVehicleBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useDeleteAirportHasVehicleBulk = createGraphApiHook(deleteAirportHasVehicleBulk);

export default deleteAirportHasVehicleBulk;

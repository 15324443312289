// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PermissionsCard {
  height: 18rem;
  position: relative;
  overflow: hidden;
}
.PermissionsCard .card-header {
  display: flex;
  padding: 1rem 1.5rem;
}
.PermissionsCard .card-body {
  height: 100%;
  overflow: hidden;
}
.PermissionsCard .card-body ul {
  height: 100%;
  overflow: auto;
  margin: 0;
}
.PermissionsCard .card-body ul li {
  padding: 0.2rem 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/PermissionsCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,aAAA;EACA,oBAAA;AAEJ;AAAE;EACE,YAAA;EACA,gBAAA;AAEJ;AADI;EACE,YAAA;EACA,cAAA;EACA,SAAA;AAGN;AAFM;EACE,sBAAA;AAIR","sourcesContent":[".PermissionsCard {\n  height: 18rem;\n  position: relative;\n  overflow: hidden;\n}\n.PermissionsCard .card-header {\n  display: flex;\n  padding: 1rem 1.5rem;\n}\n.PermissionsCard .card-body {\n  height: 100%;\n  overflow: hidden;\n}\n.PermissionsCard .card-body ul {\n  height: 100%;\n  overflow: auto;\n  margin: 0;\n}\n.PermissionsCard .card-body ul li {\n  padding: 0.2rem 0.1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import FormField from '../../FormField';
import React from 'react';
import { createWidget } from '../utils';

export const Title = createWidget({
  key: 'title',
  label: 'Title',
  description: (
    <>
      <p>Display a title widget on your Dashboard that can be customized to display text in a large font.</p>
    </>
  ),
  icon: <i className="fa fa-heading" />,
  component: ({ options }) => {
    return (
      <div className="w-100 h-100 overflow-auto d-flex flex-column justify-content-center">
        <h1 className="Widget-Title text-center">{options?.title}</h1>
      </div>
    );
  },
  options: {
    title: 'Title',
  },
  config: ({ options, setOptions }) => {
    return (
      <>
        <FormField
          name="title"
          label="Title"
          value={options?.title}
          onChange={(event) => setOptions((current) => ({ ...current, [event.target.name]: event.target.value }))}
        />
      </>
    );
  },
});

import { useEffect, useLayoutEffect, useRef } from 'react';

/* useEventListener
  A hook to set, and cleanup any event listeners
  that are needed. Using this hook guarantees that
  cleanup is taken care of, and memory leaks are
  avoided.
*/

const useEventListener = (eventName: string, handler: any, element: any = window): void => {
  const savedHandler = useRef(handler);

  useEffect((): void => {
    savedHandler.current = handler;
  }, [handler]);
  useLayoutEffect((): (() => void) => {
    if (!savedHandler.current) return;
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: any): any => {
      try {
        savedHandler.current(event);
      } catch (err) {
        console.error(err.message || err);
      }
    };
    element.addEventListener(eventName, eventListener);
    return (): void => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export default useEventListener;

import './styles.scss';

import React from 'react';

interface Props {
  size?: string;
  label?: string;
  passive?: boolean;
}
interface BlurProps extends Props {
  loading: boolean;
}
const LoadingSpinner = ({ size = 'lg', label }: Props): JSX.Element => (
  <div className="w-100 h-100 d-flex flex-column justify-content-center text-center opacity-50">
    <div>
      {size === 'sm' && <i className="fa fa-spinner fa-pulse" />}
      {size === 'md' && <i className="fa fa-spinner fa-2x fa-pulse opacity-50" />}
      {size === 'lg' && <i className="fa fa-spinner fa-3x fa-pulse opacity-50" />}
    </div>
    {label && (
      <div className="mt-3">
        <small>{label}</small>
      </div>
    )}
  </div>
);
const LoadingBlur = ({ size = 'lg', label, loading = false, passive = false }: BlurProps): JSX.Element => {
  if (!loading) return null;
  return (
    <div
      className={`LoadingBlur${loading ? ' active' : ''}${passive ? ' passive' : ''} d-flex flex-column justify-content-center text-center`}
    >
      <LoadingSpinner size={size} label={label} />
    </div>
  );
};

export default LoadingSpinner;
export { LoadingBlur };

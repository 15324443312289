export const randomItem = (arr: any[]): any => arr.at(Math.floor(Math.random() * arr.length));
export const dedupe = (arr: any[], key: string): any[] =>
  arr.reduce(
    (unique: any[], item: any): any[] =>
      unique.find((obj: any): boolean => obj[key] === item[key] || obj === item) ? unique : [...unique, item],
    []
  );
export const fromArrayMaybe = (value: string | string[] = []): string[] => [
  ...(!!value && !Array.isArray(value) ? value?.split?.(',') || [] : value || []),
];
/**
 * Sorts an array of records and updates specified properties from the original array based on their indices.
 * This function is designed to operate without modifying the original array, returning a new array
 * with sorted records and selectively sorting specified properties.
 *
 * @template T Extends array of records (Array<Record<string, any>>).
 * @param {T} arr - The original array of records to sort.
 * @param {Array<keyof T[number]>} properties - List of properties to sort.
 * @param {(a: T[number], b: T[number]) => number} compareFn - Comparison function used for sorting the array.
 * @returns {T} A new sorted array with persisted properties from the original array.
 *
 * @example
 * // Define an array of user objects
 * const users = [
 *   { id: 2, name: "Alice", age: 30 },
 *   { id: 1, name: "Bob", age: 25 }
 * ];
 *
 * // Define a compare function for sorting by id
 * function compareById(userA, userB) {
 *   return userA.id - userB.id;
 * }
 *
 * // Sort Properties, sorting by id and persisting the 'name' property
 * const sortedUsers = sortProperties(users, ['id', 'age'], compareById);
 * console.log(sortedUsers); // Outputs: [{ id: 1, name: "Alice", age: 25 }, { id: 2, name: "Bob", age: 30 }]
 */
export const sortProperties = <T extends Array<Record<string, any>>>(
  arr: T,
  properties: Array<keyof T[number]>,
  compareFn: (a: T[number], b: T[number]) => number
): T => {
  const temp = [...arr].sort(compareFn);

  const output = temp.map((item: Record<string, any>, idx: number): Record<string, any> => {
    const result = { ...item };

    const original = arr?.[idx];
    if (!original || !properties?.length) return result;

    Object.keys(original).forEach((key: string): void => {
      if (!properties.includes(key)) result[key] = original?.[key];
    });

    return result;
  });

  return output as T;
};

/** -- getNonTouchingIndex --
 * Calculates a derived index based on the input index and the array's length, then returns the element at that index.
 * This function ensures that the derived index is always within the bounds of the array by using modulo operations.
 * The derived index is calculated by adding the modulo of the index and the integer division of the index by the array's length,
 * and then taking modulo of this sum with the array's length again to ensure it's a valid index.
 *
 * use case:
 * assume you have an array that has a list of 4 colors: ['red', 'green', 'blue', 'purple']
 * assume you are painting large stripes of color on a train with two cabins, and you want to paint 5 stripes on each cabin
 * if you were to iterate through the colors in order to paint the stripes per cabin, you would get: ['red', 'green', 'blue', 'purple', 'red'] on each
 * because of this result the first cabin would end with red, and the second would start with red, which wouldnt look very good
 * to fix this we use this function to always get a different value based on the array and input index
 *
 * @param {any[]} arr - The array from which to retrieve an element.
 * @param {number} [index=0] - The base index used to calculate the derived index. Defaults to 0 if not provided.
 * @returns {any} - Returns the element at the calculated index of the array.
 */
export const getNonTouchingIndex = (arr: any[] = [], index: number = 0): any =>
  arr.at((((index % arr.length) + Math.floor(index / arr.length)) % arr.length) % arr.length);

// get first index where there is a difference in value
export const getDiffIndex = <T>(arr1: T[], arr2: T[]): number => {
  if (arr1.length !== arr2.length) {
    return -1; // Arrays have different lengths
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return i;
    }
  }
  return -1; // No differences found
};

export const range = (size: number, startAt: number = 0): ReadonlyArray<number> => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

import { AirportHasVehicleConnectionInput, AirportHasVehicleSearch } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

// TODO: add to query vehicle assigned airport and exchangePlace name from backend
const searchAirportHasVehicles: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after = null, query: AirportHasVehicleSearch[] = null, options: any = {}): Promise<any> => {
      const input: AirportHasVehicleConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
      };
      return fireQuery({
        variables: {
          input,
        },
        ...options,
      });
    },
  selector: getConnectionDetails('searchAirportHasVehicle.airportHasVehicleConnection'),
  query: gql`
    query SearchAirportHasVehicle($input: AirportHasVehicleConnectionInput) {
      searchAirportHasVehicle {
        airportHasVehicleConnection(input: $input) {
          totalCount
          edges {
            node {
              vehicleId
              vehicle {
                id
                trackingId
              }
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  `,
};

export default searchAirportHasVehicles;

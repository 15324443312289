import { DropdownItem, DropdownOptions } from '@/components/Dropdown';
import Field, { FieldProps } from '@/components/Field';
import QueryDropdown, { QueryDropdownProps } from '../QueryDropdown';
import { QueryInput, queryInput } from '@/utils';
import React, { ReactNode, useCallback } from 'react';

import { Location } from '@/models/gen/graphql';
import { selectLocationFilter } from '@/api/services/locations/searchLocations';

export type LocationQueryDropdownProps = Omit<QueryDropdownProps, 'query'> & {
  airports?: string[];
  query?: Record<string, QueryInput>[];
  options?: LocationQueryDropdownOptions;
};

export type LocationQueryDropdownOptions = DropdownOptions & {
  showAirportPrefix?: boolean;
};

const LocationQueryDropdown = ({
  name = 'location',
  airports = [],
  query,
  options,
  ...queryDropdownProps
}: LocationQueryDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await selectLocationFilter(
      query || airports?.length > 0
        ? [
            {
              airportCode: queryInput(airports),
            },
          ]
        : undefined
    );
    return (res?.rows || []).map(
      ({ id, name, displayName }: Location): DropdownItem => ({
        label: options?.showAirportPrefix !== false ? displayName : name,
        keywords: name.split(' '),
        value: id,
      })
    );
  }, [airports, options?.showAirportPrefix, query]);

  return <QueryDropdown {...queryDropdownProps} options={options} name={name} query={getItems} />;
};

export type LocationQueryDropdownField = Omit<FieldProps, 'children'> & LocationQueryDropdownProps;

export const LocationQueryDropdownField = ({
  label,
  feedback,
  valid,
  required,
  ...locationQueryDropdownProps
}: LocationQueryDropdownField): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required}>
      <LocationQueryDropdown {...locationQueryDropdownProps} />
    </Field>
  );
};

export default LocationQueryDropdown;

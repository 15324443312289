import Add from './Add.svg';
import AddSchedule from './AddSchedule.svg';
import Availability from './Availability.svg';
import Bulk from './Bulk.svg';
import CST from './CST.svg';
import Calendar from './Calendar.svg';
import Call from './Call.svg';
import Cancel from './Cancel.svg';
import Checkmark from './Checkmark.svg';
import Clients from './Clients.svg';
import Combine from './Combine_trips.svg';
import Comment from './Comment.svg';
import Communication from './Communication.svg';
import CommunicationFilled from './CommunicationFilled.svg';
import Complete from './Complete_Trip.svg';
import CreateInvoice from './CreateInvoice.svg';
import Delete from './Trash.svg';
import Dot from './Dot.svg';
import Dropdown from './Dropdown.svg';
import EST from './EST.svg';
import Edit from './Pencil.svg';
import ExportExcel from './ExportExcel.svg';
import Fcr from './FCR.svg';
import FileUpload from './FileUpload.svg';
import Flag from './Flag.svg';
import FlagFilled from './FlagFilled.svg';
import FlagOpen from './FlagOpen.svg';
import FullScreen from './FullScreen.svg';
import FullScreenNeumorphic from './FullScreenNeumorphic.svg';
import Group from './Group.svg';
import Help from './Help.svg';
import Image from './Image.svg';
import Image2 from './Image2.svg';
import Import from './Import.svg';
import InvoiceHistory from './InvoiceHistory.svg';
import LinkAirportLocations from './LinkAirportLocations.svg';
import LinkTrips from './LinkTrips.svg';
import Locations from './Locations.svg';
import LocationsMap from './LocationsMap.svg';
import MST from './MST.svg';
import ManifestFile from './ManifestFile.svg';
import ManifestHistory from './ManifestHistory.svg';
import ManualCompletion from './ManualCompletion.svg';
import Message2 from './Message2.svg';
import MessageInbox from './MessageInbox.svg';
import Minimize from './Minimize.svg';
import PST from './PST.svg';
import Password from './Password.svg';
import Permission from './Permission.svg';
import Plane from './Plane.svg';
import Plus from './Plus.svg';
import Print from './Print.svg';
import Profile from './Profile.svg';
import Rates from './Rates.svg';
import Read from './Read.svg';
import Reload from './Reload.svg';
import ReloadNeumorphic from './ReloadNeumorphic.svg';
import Remarks from './Remarks.svg';
import Reports from './Reports.svg';
import ScrollToTop from './ScrollToTop.svg';
import Search from './Search.svg';
import SendMessage from './SendMessage.svg';
import Sent from './Sent.svg';
import Setting from './Setting.svg';
import Star from './Star.svg';
import Support from './Support.svg';
import Time from './Time.svg';
import User from './User.svg';
import Users from './Users.svg';
import Van from './Van.svg';
import Vehicles from './Vehicles.svg';
import View from './View.svg';
import Warning from './Warning.svg';
import WarningOutline from './WarningOutline.svg';
import Wheel from './Wheel.svg';
import X from './X.svg';

export {
  Add,
  AddSchedule,
  Availability,
  Bulk,
  Calendar,
  Call,
  Cancel,
  Checkmark,
  Clients,
  Combine,
  Comment,
  Communication,
  CommunicationFilled,
  Complete,
  CreateInvoice,
  CST,
  Delete,
  Dot,
  Dropdown,
  Edit,
  EST,
  ExportExcel,
  Fcr,
  FileUpload,
  Flag,
  FlagFilled,
  FlagOpen,
  FullScreen,
  FullScreenNeumorphic,
  Group,
  Help,
  Image,
  Image2,
  Import,
  InvoiceHistory,
  LinkAirportLocations,
  LinkTrips,
  Locations,
  LocationsMap,
  ManifestFile,
  ManifestHistory,
  ManualCompletion,
  Message2,
  MessageInbox,
  Minimize,
  MST,
  Password,
  Permission,
  Plane,
  Plus,
  Print,
  Profile,
  PST,
  Rates,
  Read,
  Reload,
  ReloadNeumorphic,
  Remarks,
  Reports,
  ScrollToTop,
  Search,
  SendMessage,
  Sent,
  Setting,
  Star,
  Support,
  Time,
  User,
  Users,
  Van,
  Vehicles,
  View,
  Warning,
  WarningOutline,
  Wheel,
  X,
};

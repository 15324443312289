import { GetInvoiceTermsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getInvoiceTerms = createGraphApiMockResponse(GetInvoiceTermsDocument, {
  getFilters: {
    filters: {
      invoiceTerms: [
        {
          displayName: 'Due on receipt',
          id: '306cfcb5-702f-4a0c-9890-95d96b1e6624',
          __typename: 'FilterOption',
        },
        {
          displayName: 'Net 30',
          id: '306cfcb5-702f-4a0c-9890-95d96b1e6624',
          __typename: 'FilterOption',
        },
        {
          displayName: 'Net 20',
          id: '306cfcb5-702f-4a0c-9890-95d96b1e6624',
          __typename: 'FilterOption',
        },
        {
          displayName: 'Net 15',
          id: '306cfcb5-702f-4a0c-9890-95d96b1e6624',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

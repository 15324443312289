import './styles.scss';

import React, { ComponentProps, ReactNode } from 'react';

import { getClasses } from '@/utils';

export type ImageDisplayProps = ComponentProps<'img'> & {
  icon?: string;
  circle?: boolean;
};

const ImageDisplay = ({ icon, circle, ...imgProps }: ImageDisplayProps): ReactNode => (
  <div className={getClasses('ImageDisplay', circle ? 'rounded-circle' : '', imgProps?.className)}>
    {imgProps?.src && <img alt="Avatar" src={imgProps?.src} {...imgProps} />}
    {!imgProps?.src && <i className={icon || 'fa fa-user fs-1 text-gray'} />}
  </div>
);

export default ImageDisplay;

import { RunUpdateUserProfileInput, UpdateResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { RunUpdateUserProfileBulkDocument } from '@/api/queries';
import { Toast } from '@/models';
import graphApi from '@/api';

type UpdateUserProfileBulkGraphApiResponse = GraphApiResponse<typeof RunUpdateUserProfileBulkDocument>;

const title = 'Update User Profiles';

const updateUserProfileBulkResponseSelector = (res: UpdateUserProfileBulkGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.runUpdateUserProfileBulk?.response || [] : undefined;

const [runUpdateUserProfileBulk] = graphApi(RunUpdateUserProfileBulkDocument, {
  onError: (res: UpdateUserProfileBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateUserProfileBulk = async (inputs: RunUpdateUserProfileInput[] = []): Promise<UpdateResponse[]> => {
  const updates: RunUpdateUserProfileInput[] = inputs.length > 0 ? inputs : null;
  const res = await runUpdateUserProfileBulk({ input: { updates } });

  const selected = updateUserProfileBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateUserProfileBulk;

import { GetSummaryReportInput } from '../../models/gen/graphql';
import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const getSummaryReport: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (input: GetSummaryReportInput): Promise<any> => {
      return fireQuery({
        variables: { input },
        fetchPolicy: 'no-cache',
      });
    },
  selector: (data: any): any => data?.getSummaryReport || {},
  query: gql`
    query GetSummaryReport($input: GetSummaryReportInput!) {
      getSummaryReport(input: $input) {
        error {
          code
          debug
          message
        }
        tables {
          name
          count
          total
          average
          percentage
          children {
            name
            count
            total
            average
            percentage
            children {
              name
              count
              total
              average
              percentage
              children {
                name
                count
                total
                average
                percentage
              }
            }
          }
          values {
            amount
            count
            total
          }
        }
      }
    }
  `,
};

export default getSummaryReport;

import { GetVehicleModelsDocument } from '@/api/queries';
import { VehicleModelInput } from '@/models/gen/graphql';
import { createGraphApiMockResponse } from '@/api/core';

export const getVehicleModels = createGraphApiMockResponse(
  GetVehicleModelsDocument,
  {
    getFilters: {
      filters: {
        vehicleModel: {
          models: [
            {
              id: 'NV3500',
              displayName: 'NV3500',
              __typename: 'AcceptInputOption',
            },
            {
              id: 'NV3500 SV',
              displayName: 'NV3500 SV',
              __typename: 'AcceptInputOption',
            },
            {
              id: 'NV3500 SL',
              displayName: 'NV3500 SL',
              __typename: 'AcceptInputOption',
            },
            {
              id: 'Transit',
              displayName: 'Transit',
              __typename: 'AcceptInputOption',
            },
            {
              id: 'Transit Dully',
              displayName: 'Transit 350 HD Dually',
              __typename: 'AcceptInputOption',
            },
            {
              id: 'Sprinter',
              displayName: 'Sprinter',
              __typename: 'AcceptInputOption',
            },
          ],
          __typename: 'VehicleModelResponse',
        },
        __typename: 'Filters',
      },
      __typename: 'GetFiltersResponse',
    },
  },
  {
    variables: ({ input }: { input: VehicleModelInput }): boolean => {
      console.log(input?.make);
      return true;
    },
  }
);

import { RunRateAmountOnTripInput, RunRateAmountOnTripsInput } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const runRateAmountOnTrips: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (payload: RunRateAmountOnTripInput[]): Promise<any> => {
      const input: RunRateAmountOnTripsInput = {
        input: payload,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.runRateAmountOnTrips?.output || [],
  query: gql`
    mutation RunRateAmountOnTrips($input: RunRateAmountOnTripsInput!) {
      runRateAmountOnTrips(input: $input) {
        output {
          tripId
          rateId
          rateGroupId
          created
          updated
          error {
            code
            debug
            message
          }
        }
      }
    }
  `,
};

export default runRateAmountOnTrips;

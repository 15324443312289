import VirtualTable, { DynamicCell, SelectCell, VirtualTableProps, VirtualTableRow } from '../VirtualTable';

import { Datetime } from '../../utils';
import React from 'react';
import { TripWithRateChangeRow } from '../../api/services/rates/getCorrectRatesForTrips';
import { getClasses } from '../../utils/strings';

type Props = {
  data: TripWithRateChangeRow[];
  selected?: string[];
  onSelect?: (id: string | string[]) => void;
  loading?: boolean;
} & VirtualTableProps;

const header = {
  trip: {
    type: 'TYPE',
    scheduledDate: 'DATE',
    scheduledTime: 'SCH',
    airportCode: 'CITY',
    servicerIataAirlineCode: 'AL',
    flightNumber: 'FLT',
    pilots: 'PLT',
    attendants: 'FA',
    puLocation: { name: 'P/U' },
    doLocation: { name: 'D/O' },
    payerProvider: { displayName: 'CLT' },
  },
  oldRate: { rate: 'RATE' },
  newRate: { rate: 'CONTRACT' },
};

const ContractedRatesTable = ({ data, selected, loading, onSelect, ...props }: Props): JSX.Element => (
  <VirtualTable
    header={header}
    data={data}
    loading={loading}
    selected={selected}
    {...props}
    rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
      <VirtualTableRow
        context={{
          ...context,
          rowType: _type,
          data,
          index,
          selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
        }}
        className={getClasses(selected.includes(index) ? 'selected' : '')}
      >
        <SelectCell onClick={onSelect} />
        <DynamicCell selector="trip.type" className="text-center alternate" width="calc(100% / 13 * 0.5)" />
        <DynamicCell
          selector="trip.scheduledDate"
          className="text-center"
          width="calc(100% / 13)"
          render={({ data }: { data: TripWithRateChangeRow }): string =>
            data?.trip?.scheduled ? new Datetime(data?.trip?.scheduled).frontendDate : ''
          }
        />
        <DynamicCell
          selector="trip.scheduledTime"
          className="text-center alternate"
          width="calc(100% / 13)"
          render={({ data }: { data: TripWithRateChangeRow }): string =>
            data?.trip?.scheduled ? new Datetime(data?.trip?.scheduled).time : ''
          }
        />
        <DynamicCell selector="trip.airportCode" className="text-center" width="calc(100% / 13 * 0.5)" />
        <DynamicCell selector="trip.servicerIataAirlineCode" className="text-center alternate" width="calc(100% / 13 * 0.5)" />
        <DynamicCell selector="trip.flightNumber" className="text-center" width="calc(100% / 13 * 0.5)" />
        <DynamicCell selector="trip.pilots" className="text-center alternate" width="calc(100% / 13 * 0.5)" />
        <DynamicCell selector="trip.attendants" className="text-center alternate" width="calc(100% / 13 * 0.5)" />
        <DynamicCell selector="trip.puLocation.name" className="text-center" width="calc(100% / 13 * 2)" />
        <DynamicCell selector="trip.doLocation.name" className="text-center" width="calc(100% / 13 * 2)" />
        <DynamicCell selector="trip.payerProvider.displayName" className="text-center alternate" width="calc(100% / 13 * 1.5)" />
        <DynamicCell
          selector="oldRate.rate"
          className="text-center"
          width="calc(100% / 13)"
          render={({ value }: { value: number }): string =>
            !isNaN(value) ? `$${parseFloat(`${value || 0}`)?.toFixed?.(2) || '0.00'}` : '--'
          }
        />
        <DynamicCell
          selector="newRate.rate"
          className="text-center alternate"
          width="calc(100% / 13)"
          render={({ value }: { value: number }): string =>
            !isNaN(value) ? `$${parseFloat(`${value || 0}`)?.toFixed?.(2) || '0.00'}` : '--'
          }
        />
      </VirtualTableRow>
    )}
  />
);

export default ContractedRatesTable;

import './styles.scss';

import { GoogleMap, GoogleMapProps, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect } from 'react';

import { Middle } from '../Align';
import { Validation } from '../../utils/validations';
import { getClasses } from '../../utils/strings';
import { getMapConfig } from '../../api/services/config/getPortalConfig';

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

// FLL Airport Coordinates
const defaultCenter = {
  lat: 26.0742,
  lng: -80.1506,
};

type MapDisplayProps = {
  className?: string;
} & GoogleMapProps;
const MapDisplay = ({ center = defaultCenter, ...props }: MapDisplayProps): JSX.Element => {
  const [state, setState] = React.useState({ data: undefined, loading: false });
  const { data: googleMapsApiKey, loading } = state;

  const handleGetMapConfig = async (): Promise<void> => {
    setState((current) => ({ ...current, loading: true }));
    const res = await getMapConfig();
    setState((current) => ({ ...current, data: res, loading: false }));
  };

  useEffect((): void => {
    handleGetMapConfig();
  }, []);

  return (
    <div className={getClasses('MapDisplay', props?.className)}>
      {googleMapsApiKey && <Map mapContainerStyle={containerStyle} center={center} zoom={13} {...props} token={googleMapsApiKey} />}
      {!googleMapsApiKey && (
        <Middle.Center className="bg-dark bg-opacity-50 position-relative">
          {loading && <i className="fa fa-spinner fa-pulse {font-size:3rem}" />}
          {!loading && (
            <h2 className="fs-1">
              Unauthorized to view map
              <i className="sv sv-sad position-absolute {font-size:16rem;opacity:0.2;top:50%;left:50%;transform:translate(-8rem,-8rem)}" />
              <i className="sv sv-map2 ms-2" />
            </h2>
          )}
        </Middle.Center>
      )}
    </div>
  );
};

type MapProps = {
  token: string;
} & GoogleMapProps;
const Map = ({ center = defaultCenter, token, ...props }: MapProps): JSX.Element => {
  // loadError is always undefined
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: token,
  });

  const parsedCenterLat = typeof center?.lat === 'function' ? center?.lat() : center?.lat;
  const parsedCenterLng = typeof center?.lng === 'function' ? center?.lng() : center?.lng;

  const isValidCenter = Validation.isNumber(parsedCenterLat) && Validation.isNumber(parsedCenterLng);

  /*
    *** Example setup of using MapDisplay component with GoogleMap ***

    const [map, setMap] = useState(null)

    // onLoad and onUnmount callBack would be passed to the GoogleMap component
    const onLoad = useCallback((map)=> {
      // This is and getting and using the map instance
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);

      setMap(map)
    }, [])

    const onUnmount = useCallback((map) =>{
      setMap(null)
    }, [])
  */

  return (
    <>
      {isLoaded && (
        <GoogleMap mapContainerStyle={containerStyle} center={isValidCenter ? center : defaultCenter} zoom={13} {...props}>
          {/* Child react google maps components, such as markers, info windows, etc. */}
          {props.children}
        </GoogleMap>
      )}
    </>
  );
};

export default MapDisplay;

import QueryDropdown, { QueryDropdownProps } from '../QueryDropdown';

import { DropdownItem } from '../Dropdown';
import { Vehicle } from '../../models/gen/graphql';
import { getVehicleOptions } from '../../api/services/vehicles/searchVehicles';
import { handleError } from '../../utils/custom';
import md5 from 'md5';
import { useCallback } from 'react';

export type AssignVehicleDropdownProps = Omit<QueryDropdownProps, 'query'> & {
  airportCode: string;
  scheduled: string;
  actual: string;
  details: Pick<Vehicle, 'id' | 'trackingId'>;
};

export const AssignVehicleDropdown = ({
  name = 'vehicle',
  airportCode,
  scheduled,
  actual,
  details,
  ...props
}: AssignVehicleDropdownProps): React.JSX.Element => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    let result = [];
    try {
      const res = await getVehicleOptions(airportCode);
      result =
        res.rows?.map?.(
          (vehicle: Vehicle): DropdownItem => ({
            label: vehicle.trackingId,
            value: vehicle.id,
            keywords: [vehicle.trackingId],
          })
        ) || [];
    } catch (err) {
      handleError(err, { notification: { title: 'Assign Vehicle To Trip' } });
    }

    if (!details?.id) return result;
    return [
      {
        label: details.trackingId,
        value: details.id,
        keywords: [details.trackingId],
      },
      ...result.filter((item: DropdownItem): boolean => item.value !== details.id),
    ];
  }, [airportCode, details]);

  return (
    <QueryDropdown
      {...props}
      name={`${name}_${md5(airportCode + scheduled + actual)}`}
      query={getItems}
      options={{ ...(props.options || {}), locale: { 'No Options': 'No Available Vehicles', ...(props.options?.locale || {}) } }}
    />
  );
};

import { ReportProblemInput, ReportProblemResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { GraphApiResponse } from '@/api/core';
import { ReportProblemDocument } from '@/api/queries';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type ReportProblemGraphApiResponse = GraphApiResponse<typeof ReportProblemDocument>;

const title = 'Report A Problem';

const reportProblemResponseSelector = (res: ReportProblemGraphApiResponse): ReportProblemResponse =>
  !res.errors ? ((res?.reportProblem || {}) as ReportProblemResponse) : undefined;

const [runReportProblem] = graphApi(ReportProblemDocument, {
  onError: (res: ReportProblemGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const reportProblem = async (message: string, images: string[] = null): Promise<ReportProblemResponse> => {
  const input: ReportProblemInput = {
    message,
    images,
  };

  const res = await runReportProblem({ input });
  const selected = reportProblemResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useReportProblem = createGraphApiHook(reportProblem);

export default reportProblem;

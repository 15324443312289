import { Button, ButtonGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';

export type SuggestedRateProps = {
  onCancel: () => void;
  suggestion: {
    getSuggestion: () => Promise<number>;
    applySuggestion: (suggestion: number) => Promise<void>;
    messages?: {
      notFound: string;
    };
  };
};

const SuggestedRate = ({ suggestion, onCancel }: SuggestedRateProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [suggestedValue, setSuggestedValue] = useState<number>(undefined);
  const getSuggestion = async () => {
    try {
      setLoading(true);
      const suggestionResult = await suggestion?.getSuggestion?.();
      setSuggestedValue(suggestionResult);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const applySuggestion = async (value: number) => {
    try {
      await suggestion?.applySuggestion?.(value);
      onCancel?.();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSuggestion();
  }, []);
  return (
    <div className="d-flex gap-2 align-items-center Suggested-Rates ">
      {loading && <LoadingSpinner size="sm" />}
      {!loading && suggestedValue === undefined && <span>{suggestion?.messages?.notFound}</span>}
      {!loading && suggestedValue && (
        <>
          <span className="flex-grow-1 Suggested-Rates-Value">{`Suggested: ${suggestedValue}`}</span>
          <ButtonGroup>
            <Button variant="outline-success Suggested-Rates-Apply" onClick={() => applySuggestion(suggestedValue)}>
              <i className="fa fa-check Suggested-Rates-Icon" />
            </Button>
            <Button variant="outline-danger Suggested-Rates-Cancel" onClick={() => onCancel()}>
              <i className="fa fa-times Suggested-Rates-Icon" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
};

export default SuggestedRate;

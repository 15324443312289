import MaskedInput from '.';
import React from 'react';

type Props = {
  name: string;
  value: string;
  onChange: any;
  disabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
};
const mask = '99999-9999';
const placeholder = '_____-____';
const ZipCodeInput: (props: Props) => JSX.Element = ({ name, value, onChange, disabled, isValid, isInvalid }: Props): JSX.Element => (
  <MaskedInput
    className="ZipCodeInput"
    name={name}
    value={value}
    mask={mask}
    placeholder={placeholder}
    onChange={onChange}
    disabled={disabled}
    isValid={isValid}
    isInvalid={isInvalid}
  />
);

export default ZipCodeInput;

import { useCallback, useEffect, useRef } from 'react';

// TODO: Add support for returnable functions
export const useAbortable = (func: (...args) => void, timeoutDelay: number = 250) => {
  const timeoutRef = useRef(null);
  const abortControllerRef = useRef(new AbortController());

  const abortableFunc = useCallback(
    (...args) => {
      if (abortControllerRef.current.signal.aborted) {
        abortControllerRef.current = new AbortController();
      }

      clearTimeout(timeoutRef.current);

      const handleAbort = () => {
        clearTimeout(timeoutRef.current);
      };

      abortControllerRef.current.signal.addEventListener('abort', handleAbort);

      timeoutRef.current = setTimeout(() => {
        abortControllerRef.current.signal.removeEventListener('abort', handleAbort);
        try {
          func?.(...args);
        } catch (err) {
          console.error(err);
        }
      }, timeoutDelay);

      return () => {
        abortControllerRef.current.abort();
      };
    },
    [func, timeoutDelay]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      abortControllerRef.current.abort();
    };
  }, []);

  return abortableFunc;
};

import { BufferTime, CreateBufferTimeInput } from '@/models/gen/graphql';
import { HandleErrorInput, handleError } from '@/utils/custom';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { CreateBufferTimeBulkDocument } from '@/api/queries';
import { GraphApiMethod } from '@/api/core';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Create Buffer Time Setting' } });

type ServiceMethodPayload = { [key in keyof CreateBufferTimeInput]: any }[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<BufferTime[]>;
}

// CreateBufferTimeBulk Service
export const { createBufferTimeBulk, useCreateBufferTimeBulk } = ((): {
  createBufferTimeBulk: ServiceMethod;
  useCreateBufferTimeBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(CreateBufferTimeBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof CreateBufferTimeBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<BufferTime[]> => {
        const values: CreateBufferTimeInput[] = payload.map(
          (bufferTime: CreateBufferTimeInput): CreateBufferTimeInput => ({ active: 1, ...bufferTime })
        );
        const res = await fn({ input: { values } });
        return res?.createBufferTimeBulk?.output?.map(({ node }: { node: BufferTime }): BufferTime => node) || [];
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    createBufferTimeBulk: mutate,
    useCreateBufferTimeBulk: useService,
  };
})();

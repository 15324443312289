import './styles.scss';

import { Badge, Button } from 'react-bootstrap';
import { DATE_FE_FORMAT_SHORT, TIME_FORMAT } from '@/constants';
import { Datetime, Validation, formatDollars, getClasses, parsePhoneNumber, stringify, titleCase } from '@/utils';
import { Location, Rate, SortDirectionEnum, Trip, TripKindEnum, TripTableFormatEnum } from '@/models/gen/graphql';
import React, { useEffect, useState } from 'react';
import VirtualTable, { DynamicCell, FormatCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import AssignDriverDropdownCell from '@/pages/Trips/components/AssignDriverDropdownCell';
import { ConnectionDetails } from '@/utils/custom';
import EditRatesForm from '@/components/EditRatesForm';
import FlagsButton from '@/components/FlagsButton';
import HasPermission from '@/components/HasPermission';
import SelectVehicle from '@/components/SelectVehicle';
import Tippy from '@tippyjs/react';
import TippyWhen from '@/components/TippyWhen';
import TripsRate from '@/pages/Trips/components/TripsRate';
import useHotkeys from '@/hooks/useHotkeys';
import useModal from '@/hooks/useModal';

type SortedColumn = {
  sortBy: string;
  sortDirection: SortDirectionEnum;
};
type TripsTableState = {
  rows: Trip[];
  selected: string[];
  tripIds: string[];
  newRateValue: string;
  sortedColumn: SortedColumn;
  ratesForm: {
    visible: boolean;
    rateGroupId?: string | undefined;
  };
  filters: any;
  search: string;
  sorting: {
    column: string;
    direction: string;
  };
};
const initTripsTableState: TripsTableState = {
  rows: [],
  selected: [],
  tripIds: [],
  newRateValue: undefined,
  sortedColumn: undefined,
  ratesForm: {
    visible: false,
    rateGroupId: undefined,
  },
  filters: {},
  search: '',
  sorting: {
    column: undefined,
    direction: undefined,
  },
};
const TripsTable = ({
  data,
  setRows,
  setPriorityRows,
  refetch,
  fetchMore,
  selected,
  onSelected,
  search = '',
  onSort,
  sorting,
  label = undefined,
  loading = false,
  selectedRows,
  name,
  format,
}: {
  data: Trip[];
  setRows: React.Dispatch<React.SetStateAction<ConnectionDetails<Trip>>>;
  setPriorityRows: React.Dispatch<React.SetStateAction<ConnectionDetails<Trip>>>;
  refetch: () => Promise<any>;
  fetchMore?: (after: number) => Promise<any>;
  selected: string[];
  onSelected: (...a: any) => void;
  search: string;
  onSort: (column: string, direction: string) => Promise<void>;
  sorting: {
    column: string;
    direction: string;
  };
  label?: string;
  loading: boolean;
  selectedRows: Trip[];
  name: string;
  format?: TripTableFormatEnum;
}): JSX.Element => {
  // Init State
  const [state, setState] = useState(initTripsTableState);
  const { tripIds, rows, ratesForm } = state;

  const { makeSortable, filteredRows } = useVirtualTable(setState, {
    rows,
    search,
    sorting: {
      ...sorting,
      onSort,
    },
    searchableColumns: [
      'type',
      'scheduled',
      'trackFlight',
      'kind',
      'airportCode',
      'servicerIataAirlineCode',
      'flightNumber',
      'pilots',
      'attendants',
      'driver.employeeId',
      'driver.fullName',
      'puLocation.name',
      'doLocation.name',
      'rate.rate',
      'vehicle.trackingId',
      'payerProvider.displayName',
    ],
  });
  const onSubmit = async (): Promise<void> => refetch();
  const [, { show: showEditTripsModal }] = useModal('EditTrips', { onSubmit });
  const [, { show: showEditCompletionModal }] = useModal('EditCompletion', { onSubmit });
  const [, { show: showEditFcrModal }] = useModal('EditFcr', { onSubmit });
  const [, { show: showEditCombineModal }] = useModal('EditCombine', { onSubmit });
  const [, { show: showEditFlagModal }] = useModal('EditFlag', { onSubmit });
  const [, { show: showEditCommunicationModal }] = useModal('EditCommunication', { onSubmit });
  const [, { show: showRateReportModal }] = useModal('RateReport', { onSubmit });

  // Hotkeys
  useHotkeys('trips', {
    'shift+ctrl+c': {
      name: 'Toggle Create Trip',
      description: 'Opens and closes the Create Trip modal',
      action: (): void => showEditTripsModal({ creating: true, editing: false }),
    },
    'shift+ctrl+e': {
      name: 'Toggle Edit Trip(s)',
      description: 'Opens and closes the Edit Trip modal',
      action: (): void => onEditTrip(),
    },
  });

  const onEditFlags = (trip: Trip): void =>
    showEditFlagModal({
      tripId: trip.id,
      servicerIataAirlineCode: trip.servicerIataAirlineCode,
      flightNumber: trip.flightNumber.toString().padStart(4, '0'),
      scheduled: trip.scheduled,
    });
  const onEditCommunication = (trip: Trip): void =>
    showEditCommunicationModal({
      tripId: trip.id,
      offset: trip?.offset,
      servicerIataAirlineCode: trip.servicerIataAirlineCode,
      flightNumber: trip.flightNumber.toString().padStart(4, '0'),
      scheduled: trip.scheduled,
    });
  const onEditCompleted = (trip: Trip): void =>
    showEditCompletionModal({
      tripId: trip.id,
      completion: trip?.state?.completion,
      completionId: trip.completionId,
      scheduled: trip.scheduled,
      servicerIataAirlineCode: trip.servicerIataAirlineCode,
      flightNumber: trip.flightNumber.toString().padStart(4, '0'),
    });
  const onEditFcr = (trip: Trip): void =>
    showEditFcrModal({
      tripId: trip.id,
      servicerIataAirlineCode: trip.servicerIataAirlineCode,
      flightNumber: trip.flightNumber.toString().padStart(4, '0'),
      scheduled: trip.scheduled,
    });
  const onEditCombine = (trip: Trip): void => showEditCombineModal({ ...trip, tripId: trip.id });

  const onEditTrip = (trip?: Trip, selectedTrips?: any): void => {
    showEditTripsModal({
      tripId: selectedTrips ? selectedTrips?.[0]?.id : trip?.id ? trip.id : undefined,
      selected: selectedTrips ? selectedTrips : trip?.id ? [trip] : selected || [],
    });
    onSelected([]);
    setState(
      (current: TripsTableState): TripsTableState => ({
        ...current,
        tripIds: trip ? [trip.id] : selected ? selectedTrips.map((trip: Trip): string => trip.id) : tripIds || [],
      })
    );
  };

  const onEditRateReport = (): void =>
    showRateReportModal({
      tripIds: selectedRows?.map((trip: Trip): string => trip?.id),
    });

  const isUpcomingTrip = (scheduledUtc: string): boolean => {
    if (!scheduledUtc) return;
    const utcDatetime = new Datetime(scheduledUtc);
    return utcDatetime.isSameUtc(undefined, 'day') && utcDatetime.diffUtc(undefined, 'hour') < 1;
  };

  const handleHideRates = (fetchTable: boolean = true): void => {
    setState((current: TripsTableState): TripsTableState => ({ ...current, ratesForm: { ...initTripsTableState.ratesForm } }));
    if (fetchTable) refetch();
  };

  useEffect(() => {
    if (!data) return;
    setState((current: TripsTableState): TripsTableState => ({ ...current, rows: data }));
  }, [data]);

  return (
    <>
      <div className="TripsTable">
        {label && <p className="TripsTable-Label {border-top-right-radius:.83rem!;border-top-left-radius:.83rem!;}">{label}</p>}
        <VirtualTable
          name={name}
          data={filteredRows}
          onLazyLoad={fetchMore}
          dynamic
          dynamicRowHeight
          loading={loading}
          header={{
            flagCom: (
              <div className="d-flex justify-content-between align-items-center">
                <FlagsButton />
                <i className="sv sv-message2 fs-5 ms-2" />
              </div>
            ),
            type: 'Type',
            scheduledDate: 'Date',
            scheduledTime: 'Sch',
            actual: 'Act',
            airportCode: 'City',
            servicerIataAirlineCode: 'Al',
            flightNumber: 'Flt',
            pilots: 'Plt',
            attendants: 'FA',
            driverId: 'Drv',
            vehicleId: 'Van',
            puLocation: 'P/U',
            doLocation: 'D/O',
            payerProvider: { displayName: 'Clt' },
            completionButton: <i className="sv sv-completion fs-5" />,
            fcrButton: <i className="sv sv-fcr fs-5" />,
            combineButton: <i className="sv sv-combine-icon fs-5 text-secondary" />,
            rate: { rate: 'Rate' },
          }}
          rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
            <VirtualTableRow
              context={{
                ...context,
                rowType: _type,
                data,
                setRows,
                setPriorityRows,
                index,
                selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
              }}
              className={getClasses(
                selected.includes(index) ? 'selected' : '',
                data?.kind,
                data?.type,
                data?.status,
                data?.isLate ? 'LATE' : '',
                isUpcomingTrip(data?.scheduledUtc) ? 'UPCOMING' : '',
                data?.state?.completion ? 'COMPLETED' : '',
                data?.deletedAt ? 'DELETED' : '',
                data?.curbsideAt ? 'CURBSIDE' : '',
                format ? format : '',
                _type === 'header' ? 'HEADER' : ''
              )}
              onDoubleClick={(): void => onEditTrip(data)}
            >
              <SelectCell onClick={onSelected} />
              <DynamicCell
                selector="flagCom"
                placeholder="--"
                className="text-center"
                width="4rem"
                render={({ data }: { data: Trip }): JSX.Element => (
                  <div className="d-flex justify-content-between align-items-center">
                    <Tippy content="Flags">
                      <span>
                        <FlagsButton flags={data.flags} onClick={(): any => onEditFlags(data)} />
                      </span>
                    </Tippy>
                    <Tippy content="Communications">
                      <Button
                        variant="transparent"
                        className="position-relative p-0"
                        name="COMMUNICATIONS_BUTTON"
                        onClick={(): any => onEditCommunication(data)}
                      >
                        <i className={`sv sv-message2 fs-5 ${data?.communications?.length ? 'text-info' : ''}`} />
                      </Button>
                    </Tippy>
                  </div>
                )}
              />
              <DynamicCell selector="type" placeholder="--" className="text-center" width="4rem" sorting={makeSortable('type')} />
              <FormatCell
                selector="scheduledDate"
                placeholder="--"
                className="text-center"
                width="5rem"
                sorting={makeSortable('scheduledDate')}
                format={(_value: string, data: Trip): string => new Datetime(data?.scheduled).format(DATE_FE_FORMAT_SHORT)}
              />
              <FormatCell
                selector="scheduledTime"
                placeholder="--"
                className="text-center"
                width="5rem"
                sorting={makeSortable('scheduledTime')}
                format={(_value: string, data: Trip): string => new Datetime(data?.scheduled).format(TIME_FORMAT)}
              />
              <DynamicCell
                selector="actual"
                placeholder="--"
                className="text-center"
                width="calc(100% / 24)"
                sorting={makeSortable('actual')}
                render={({ data }: { data: Trip }): string | JSX.Element => <ActualColumn rowData={data} />}
              />
              <DynamicCell
                selector="airportCode"
                placeholder="--"
                className="text-center"
                width="4rem"
                sorting={makeSortable('airportCode')}
              />
              <DynamicCell
                selector="servicerIataAirlineCode"
                placeholder="--"
                className="text-center"
                width="4rem"
                sorting={makeSortable('servicerIataAirlineCode')}
              />
              <DynamicCell
                selector="flightNumber"
                placeholder="--"
                className={getClasses(
                  'text-center',
                  data?.trackFlight?.type === 'INTERNATIONAL' ? 'bg-purple bg-opacity-15 text-black' : undefined
                )}
                width="4rem"
                sorting={makeSortable('flightNumber')}
                render={({ value }: { value: string }): string => (value ? `${value}`.padStart(4, '0') : '--')}
              />
              <DynamicCell selector="pilots" placeholder="--" className="text-center" width="4rem" sorting={makeSortable('pilots')} />
              <DynamicCell
                selector="attendants"
                placeholder="--"
                className="text-center"
                width="4rem"
                sorting={makeSortable('attendants')}
              />
              <AssignDriverDropdownCell
                selector="driverId"
                placeholder="--"
                width="calc(100% / 10)"
                maxWidth="95px"
                sorting={makeSortable('driverId')}
                refetch={refetch}
              />
              <DynamicCell
                selector="vehicleId"
                placeholder="--"
                className="text-center"
                minWidth="5rem"
                width="calc(100% / 24)"
                maxWidth="95px"
                sorting={makeSortable('vehicleId')}
                render={({ data }: { data: Trip }): string | JSX.Element => (
                  <TippyWhen
                    isTrue={!!data?.vehicle}
                    options={{
                      content: (
                        <>
                          <div className="text-center">{`Vehicle #${data?.vehicle?.trackingId || '--'}`}</div>
                          <div>{`Phone: ${parsePhoneNumber(data?.vehicle?.phoneNumber || '', ' ')}`}</div>
                          <div className="text-center">{`Capacity: ${data?.vehicle?.capacity || '--'}`}</div>
                          <div className="text-center">{titleCase(`${data?.vehicle?.make || ''} ${data?.vehicle?.model || ''}`)}</div>
                        </>
                      ),
                    }}
                  >
                    <SelectVehicle
                      name="vehicle"
                      className="text-center"
                      data={data}
                      refetchParent={(): Promise<any> => refetch()}
                      placeholder={data?.vehicle?.trackingId || '--'}
                      disabled={!data.driverId}
                    />
                  </TippyWhen>
                )}
              />
              <LocationCell
                selector="puLocation"
                placeholder="--"
                className="text-center clamp"
                width="calc(100% / 8)"
                sorting={makeSortable('puLocationId')}
              />
              <LocationCell
                selector="doLocation"
                placeholder="--"
                className="text-center"
                width="calc(100% / 8)"
                sorting={makeSortable('doLocationId')}
              />
              <DynamicCell
                selector="payerProvider.displayName"
                placeholder="--"
                className="text-center"
                width="8rem"
                sorting={makeSortable('payerProvider.displayName')}
              />
              <DynamicCell
                selector="completionButton"
                placeholder="--"
                className="text-center"
                width="3rem"
                render={({ data }: { data: Trip }): string | JSX.Element => (
                  <Tippy
                    content={
                      !Validation.isNil(data?.state)
                        ? data?.state
                          ? data?.state?.datetimeOfChange
                            ? `${data?.state?.displayName} at ${new Datetime(data?.state?.datetimeOfChange).format('HH:mm')}`
                            : `${data?.state?.displayName}`
                          : ''
                        : 'No Completion'
                    }
                  >
                    <Button variant="transparent" className="p-0" onClick={(): void => onEditCompleted(data)}>
                      {data?.state && <small className="completion-reason">{data?.state ? data?.state?.displayNameShort : ''}</small>}
                      {data?.state ? (
                        <small className="completion-time text-success d-block">
                          {new Datetime(data?.state?.datetimeOfChange).format('HH:mm')}
                        </small>
                      ) : (
                        <i className="sv sv-time fs-5" />
                      )}
                    </Button>
                  </Tippy>
                )}
              />
              <DynamicCell
                selector="fcrButton"
                placeholder="--"
                className="text-center"
                width="3rem"
                render={({ data }: { data: Trip }): string | JSX.Element => (
                  <Tippy content="Complaints">
                    <Button variant="transparent" className="p-0" onClick={(): void => onEditFcr(data)}>
                      <i className={`sv sv-fcr fs-5 ${data?.fcrs?.length ? 'text-purple' : 'text-primary'}`} />
                    </Button>
                  </Tippy>
                )}
              />
              <DynamicCell
                selector="combineButton"
                placeholder="--"
                className="text-center"
                width="3rem"
                render={({ data }: { data: Trip }): string | JSX.Element => (
                  <Tippy content="Combine">
                    <Button variant="transparent" className="p-0" onClick={(): void => onEditCombine(data)}>
                      <i
                        className={getClasses(
                          'sv sv-combine-icon fs-5',
                          data?.combineId || !!data?.combineType ? 'text-green' : 'text-secondary'
                        )}
                      />
                    </Button>
                  </Tippy>
                )}
              />
              {HasPermission.check('allowSearchTripsByRate') && (
                <DynamicCell
                  selector="rate.rate"
                  placeholder="--"
                  className="text-center"
                  minWidth="6rem"
                  width="calc(100% / 24)"
                  sorting={makeSortable('rate.rate')}
                  render={({ data }: { data: Trip; value: string | number }): string | JSX.Element => (
                    <TripsRate
                      tripId={data?.id}
                      selectedTrips={selected}
                      rate={{ rate: data?.rate?.rate, id: data?.rate?.id } as Rate}
                      onEditRateReport={onEditRateReport}
                      onEdit={(index, rate, rateGroupId) => {
                        setState((current: TripsTableState) => {
                          const newRows = stringify.parse(current.rows);
                          newRows[index] = { ...newRows[index], rate: { ...newRows[index].rate, rate, rateGroupId: rateGroupId } };
                          return {
                            ...current,
                            rows: newRows,
                            ratesForm: { ...current.ratesForm, rateGroupId: rateGroupId ? rateGroupId : data?.rate?.rateGroupId },
                          };
                        });
                      }}
                      onDoubleClick={(event): void => {
                        if (!data?.rate) return;
                        event.stopPropagation();
                        setState((current: any): any => ({
                          ...current,
                          ratesForm: { ...current.ratesForm, rateGroupId: data?.rate?.rateGroupId, visible: true },
                        }));
                      }}
                    >
                      <strong>{formatDollars(`${data?.rate?.rate}`)}</strong>
                    </TripsRate>
                  )}
                />
              )}
            </VirtualTableRow>
          )}
        />
      </div>
      <EditRatesForm
        show={ratesForm.visible}
        value={ratesForm.rateGroupId}
        selected={ratesForm.rateGroupId ? [{ id: ratesForm.rateGroupId }] : undefined}
        onSubmit={handleHideRates}
        onHide={handleHideRates}
        modal
        drawer
        as="drawer"
      />
    </>
  );
};

const getActualTimeVariant = ({ trackFlight, scheduled }: Trip): string => {
  if (!trackFlight?.actual || !scheduled) return;
  const actualDatetime = new Datetime(trackFlight?.actual).asDayjs();
  if (actualDatetime.isBefore(scheduled)) return 'success';
  const isLate = actualDatetime.diff(scheduled, 'minutes') >= 1;
  const isVeryLate = actualDatetime.diff(scheduled, 'hours') >= 5;
  if (isLate && !isVeryLate) return 'warning-dark';
  if (isVeryLate) return 'danger';
};

const ActualColumn = ({ rowData }: { rowData: Trip }) => {
  const { trackFlight, kind } = rowData || {};
  const color = getActualTimeVariant(rowData);

  if (!trackFlight?.actual) return '--';
  return (
    <div className="d-flex align-items-center gap-1 justify-content-center flex-wrap">
      <TippyWhen
        isTrue={!!trackFlight?.label}
        options={{
          content: (
            <div className="d-flex flex-column align-items-center justify-content-center gap-2">
              <div>{`Status: ${titleCase(trackFlight?.label?.replace('_', ' ') || '')}`}</div>
              {trackFlight?.label === 'EN_ROUTE' && <div>{`ETA: ${new Datetime(trackFlight?.actual).time || 'Unknown'}`}</div>}
              {/* We only track arrivals */}
              {kind === TripKindEnum.Arr && (
                <>
                  {trackFlight?.arrivalGate && <div>Gate: {trackFlight?.arrivalGate}</div>}
                  {trackFlight?.arrivalTerminal && <div>Terminal: {trackFlight?.arrivalTerminal}</div>}
                </>
              )}
            </div>
          ),
        }}
      >
        <Badge className="d-flex align-items-center justify-content-center fs-6" bg={color}>
          {(trackFlight?.label || '')[0]}
        </Badge>
      </TippyWhen>
      <span className={`text-${color}`}>{new Datetime(trackFlight?.actual).time}</span>
    </div>
  );
};

const LocationCell = ({ ...props }: any): JSX.Element => (
  <DynamicCell
    {...props}
    render={({ value }: { value: Location }): any => (
      <div className="d-flex justify-content-center">
        <span className="flex-grow-1">
          <Tippy
            content={
              <div className="w-100 h-100 position-relative">
                <div className="text-center">{value?.name}</div>
                <div className="text-center">
                  {value?.address}, {value?.cityName}, {value?.stateCode} {value?.zipCode}
                </div>
              </div>
            }
            placement="left"
          >
            <Tippy content={<small>Click to copy to clipboard.</small>} placement="bottom-start" delay={[500, 0]} arrow={false}>
              <Button
                variant="transparent"
                className="p-0"
                onClick={(): Promise<void> =>
                  navigator.clipboard.writeText(
                    `${value?.name}\n${value?.address}, ${value?.cityName}, ${value?.stateCode} ${value?.zipCode}`
                  )
                }
              >
                {value?.name || '--'}
              </Button>
            </Tippy>
          </Tippy>
        </span>
        {value?.aliases?.length > 1 && (
          <span>
            <Tippy
              content={
                <>
                  <div className="d-flex">
                    <strong className="flex-grow-1">Aliases:</strong>
                    <strong>({value?.aliases?.length})</strong>
                  </div>
                  <ul className="m-0">
                    {value?.aliases?.map?.(({ name }: { name: string }, a: number): JSX.Element => <li key={a}>{name}</li>)}
                  </ul>
                </>
              }
              placement="right"
            >
              <Tippy content={<small>Click to copy to clipboard.</small>} placement="bottom-end" delay={[500, 0]} arrow={false}>
                <Button
                  variant="transparent"
                  className="p-0"
                  onClick={(): Promise<void> =>
                    navigator.clipboard.writeText(value?.aliases?.map?.(({ name }: { name: string }) => name)?.join('\n') || '')
                  }
                >
                  <i className="fa fa-circle-info pointer {opacity:0.4;}" />
                </Button>
              </Tippy>
            </Tippy>
          </span>
        )}
      </div>
    )}
  />
);

export default TripsTable;
export { SortedColumn };

import { DeleteVehicleBulkInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import { customDeleteHandler } from '../../utils';
import gql from 'graphql-tag';

const deleteVehicleBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...ids: string[]): Promise<any> => {
      const input: DeleteVehicleBulkInput = {
        search: ids.map((id: string): { id: string } => ({ id })),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): number => data?.deleteVehicleBulk?.deleted || 0,
  handler: customDeleteHandler('Delete Vehicle', 'vehicle', 'deleteVehicleBulk'),
  query: gql`
    mutation DeleteVehicleBulk($input: DeleteVehicleBulkInput!) {
      deleteVehicleBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteVehicleBulk;

import { CreateFcrBulkInput, CreateFcrInput, Fcr, FcrEdge } from '../../models/gen/graphql';
import { Validation, customCreateHandler } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const createFcrValidator = new Validation.Validator({
  description: (val: any): Validation.Validity => ({
    valid: !!(val || '').trim().length && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  cause: (val: any): Validation.Validity => ({
    valid: !!(val || '').trim().length && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  fcrTypeId: (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  tripId: (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
});

const createFcrBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...fcrs: CreateFcrInput[]): Promise<any> => {
      const values = fcrs.map((fcr: CreateFcrInput): CreateFcrInput => createFcrValidator.create(fcr));
      const input: CreateFcrBulkInput = { values };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): { rows: Fcr[] } => ({ rows: (data?.createFcrBulk?.output || []).map(({ node }: FcrEdge): Fcr => node) }),
  handler: customCreateHandler('Create Fcr for Trip', 'createFcrBulk'),
  query: gql`
    mutation CreateFcrBulk($input: CreateFcrBulkInput!) {
      createFcrBulk(input: $input) {
        output {
          error
          node {
            # cause
            # createdAt
            # createdBy
            description
            # fcrType {
            #   createdAt
            #   createdBy
            #   id
            #   name
            #   updatedAt
            #   updatedBy
            # }
            fcrTypeId
            id
            tripId
            # updatedAt
            # updatedBy
          }
        }
      }
    }
  `,
};

export default createFcrBulk;
export { createFcrValidator };

import { customCreateHandler, validateObj } from '../../utils';

import { CreateLicenseBulkInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateLicense = validateObj({
  expirationDate: (val: any): any => val || null,
  licenseTypeId: (val: any): any => val || '',
  number: (val: any): any => val || '',
  stateCode: (val: any): any => val || '',
  userId: (val: any): any => val || '',
});

const createLicenseBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (...licenses: any[]): Promise<any> => {
      const input: CreateLicenseBulkInput = {
        values: licenses.map(validateLicense),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): string[] => data?.createLicenseBulk?.output || [],
  handler: customCreateHandler('Create User License', 'createLicenseBulk'),
  query: gql`
    mutation CreateLicenseBulk($input: CreateLicenseBulkInput!) {
      createLicenseBulk(input: $input) {
        output {
          error
          node {
            # createdAt
            # createdBy
            # expirationDate
            id
            # licenseTypeId
            # number
            # stateCode
            # updatedAt
            # updatedBy
            # userId
          }
        }
      }
    }
  `,
};

export default createLicenseBulk;
export { validateLicense };

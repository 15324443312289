import gql from 'graphql-tag';

export const GetAirportGroups = gql`
  query GetAirportGroups {
    getFilters {
      filters {
        airportGroups {
          id
          displayName
          values
        }
      }
    }
  }
`;

export const GetAirportGroupPermissions = gql`
  query GetAirportGroupPermissions {
    getFilters {
      filters {
        airportGroupPermissions {
          id
          displayName
          values
        }
      }
    }
  }
`;

export const GetVehicleModels = gql`
  query GetVehicleModels($input: VehicleModelInput) {
    getFilters {
      filters {
        vehicleModel(input: $input) {
          models {
            id
            displayName
          }
        }
      }
    }
  }
`;

export const GetStates = gql`
  query GetStates {
    getFilters {
      filters {
        states {
          id
          displayName
        }
      }
    }
  }
`;

export const GetInvoiceTerms = gql`
  query GetInvoiceTerms {
    getFilters {
      filters {
        invoiceTerms {
          displayName
          id
        }
      }
    }
  }
`;

export const GetServicerIataAirlineCodes = gql`
  query GetServicerIataAirlineCodes {
    getFilters {
      filters {
        servicerIataAirlineCode {
          id
          displayName
          values
        }
      }
    }
  }
`;

export const GetImportProvider = gql`
  query GetImportProvider {
    getFilters {
      filters {
        importProvider {
          id
          displayName
        }
      }
    }
  }
`;

export const GetClients = gql`
  query GetClients {
    getFilters {
      filters {
        clients {
          id
          displayName
        }
      }
    }
  }
`;

export const GetAirports = gql`
  query GetAirports {
    getFilters {
      filters {
        airports {
          id
          displayName
          values
        }
      }
    }
  }
`;

export const GetImportContractProvider = gql`
  query GetImportContractProvider {
    getFilters {
      filters {
        importContractProvider {
          displayName
          id
          values
        }
      }
    }
  }
`;

export const GetAvailabilityTypes = gql`
  query GetAvailabilityTypes {
    getFilters {
      filters {
        availabilityTypes {
          id
          displayName
        }
      }
    }
  }
`;

export const GetCompanies = gql`
  query GetCompanies {
    getFilters {
      filters {
        companies {
          id
          displayName
        }
      }
    }
  }
`;

export const GetCompletionReasons = gql`
  query GetCompletionReasons {
    getFilters {
      filters {
        completionReasons {
          id
          displayName
          acceptInput
        }
      }
    }
  }
`;

export const GetFlags = gql`
  query GetFlags {
    getFilters {
      filters {
        flag {
          id
          displayName
        }
      }
    }
  }
`;

export const GetGroups = gql`
  query GetGroups {
    getFilters {
      filters {
        groups {
          id
          displayName
        }
      }
    }
  }
`;

export const GetKinds = gql`
  query GetKinds {
    getFilters {
      filters {
        kind {
          id
          displayName
        }
      }
    }
  }
`;

export const GetLicenseTypes = gql`
  query GetLicenseTypes {
    getFilters {
      filters {
        licenseType {
          id
          displayName
        }
      }
    }
  }
`;

export const GetRatevalues = gql`
  query GetRateValues($input: RateValueInput!) {
    getFilters {
      filters {
        rateValue(input: $input) {
          rates {
            displayName
            id
            values
          }
        }
      }
    }
  }
`;

export const GetRoles = gql`
  query GetRoles {
    getFilters {
      filters {
        roles {
          id
          displayName
        }
      }
    }
  }
`;

export const GetVehicleTracker = gql`
  query GetVehicleTracker {
    getFilters {
      filters {
        vehicleTracker {
          id
          displayName
        }
      }
    }
  }
`;

export const GetLineItemTypes = gql`
  query GetLineItemTypes {
    getFilters {
      filters {
        lineItemType {
          displayName
          id
        }
      }
    }
  }
`;

import { CreateLocationInput, Image, Location, PickupPoint } from '../../../models/gen/graphql';
import { Validation, createNotification, getDiff } from '../../../utils';
import createImageBulk, { uploadImageBulk } from './createImageBulk';

import Logger from '../../../utils/logs';
import { Toast } from '../../../models';
import createLocationBulk from './createLocationBulk';
import createPickupPointBulk from './createPickupPointBulk';
import deleteImageBulk from './deleteImageBulk';
import deletePickupPointBulk from './deletePickupPointBulk';
import updateLocationById from './updateLocationById';
import updatePickupPointBulk from './updatePickupPointBulk';

const log = Logger.of('EditLocationModal');

export const createLocationWithPickupPoint = async (
  { aliases, airports, pickupPoints, ...location }: Location,
  _?,
  md5ToFileMap?: Record<string, File>
): Promise<Location> => {
  try {
    const createLocationPayload: CreateLocationInput = {
      ...location,
      aliases: (aliases || []).map((val: any): string => val?.name || val),
      airportCodes: (airports || []).map((val: any): string => val?.airportCode || val),
    };
    const res = await createLocationBulk([createLocationPayload]);
    if (!res) return;
    const createdLocation = res?.[0];
    if (!(pickupPoints || []).length) return createdLocation;
    if (!Validation.isValidUUID(createdLocation?.id)) {
      createNotification('Invalid location id.', Toast.Type.DANGER, 'Create Locations');
      return;
    }
    const createPickupPointBulkPayload = pickupPoints.map(
      (point: PickupPoint): PickupPoint => ({ ...point, locationId: createdLocation?.id })
    );
    const response = await createPickupPointBulk(createPickupPointBulkPayload);
    await uploadImageBulk(response.map(({ images }: PickupPoint): Image[] => images).flat(), md5ToFileMap);

    return createdLocation;
  } catch (err) {
    log.error('createLocationWithPickupPoint', err?.message || err);
  }
};

export const updateLocationWithPickupPoint = async (
  {
    aliases: updateAliases,
    airports: updateAirports,
    newAlias,
    coordinates: updateCoordinates,
    pickupPoints: updatePickupPoints,
    ...update
  }: any,
  {
    aliases: originalAliases,
    airports: originalAirports,
    coordinates: { __typename, ...originalCoordinates } = {} as any,
    pickupPoints: originalPickupPoints,
    ...original
  }: Location,
  md5ToFileMap: Record<string, File>
): Promise<void> => {
  try {
    if (!Validation.isValidUUID(original?.id)) return createNotification('Invalid location id.', Toast.Type.DANGER, 'Edit Locations');
    // Update Location(s)
    const [partialLocation] = getDiff(original, update);
    const [partialCoordinates] = getDiff(originalCoordinates, updateCoordinates);

    const aliases = (updateAliases || []).map((val: any): string => val?.name || val);
    const airports = (updateAirports || []).map((val: any): string => val?.airportCode || val);

    const aliasesHasDiff =
      !!(aliases || []).filter((val: any): boolean => !(originalAliases || []).filter((org: any): boolean => org?.name === val).length)
        .length || (originalAliases || []).length !== aliases?.length;

    const airportsHasDiff =
      !!(airports || []).filter(
        (val: any): boolean => !(originalAirports || []).filter((org: any): boolean => org?.airportCode === val).length
      ).length || (originalAirports || []).length !== airports?.length;

    const locationChanged = !!Object.values(partialLocation).length;
    const locationCoordinatesChanged = !!Object.values(partialCoordinates).length;
    const changesMade = locationChanged || locationCoordinatesChanged || aliasesHasDiff || airportsHasDiff;
    if (changesMade) {
      let update: Partial<Location> = {
        id: original?.id,
        aliases,
        airports,
      };
      if (locationChanged) update = { ...update, ...partialLocation };
      if (locationCoordinatesChanged) update = { ...update, coordinates: { ...originalCoordinates, ...partialCoordinates } };
      await updateLocationById(update);
    }

    // Pickup Points
    const updateLocationPickPoints = await handleUpdatePickupPointOnLocation(
      updatePickupPoints,
      originalPickupPoints,
      original.id,
      md5ToFileMap
    );
    if (!updateLocationPickPoints && !changesMade) {
      return createNotification('No changes made to location.', Toast.Type.WARNING, 'Edit Locations');
    }
  } catch (err) {
    log.error('updateLocationWithPickupPoint', err?.message || err);
  }
};

const handleUpdatePickupPointOnLocation = async (
  updatePickupPoints: PickupPoint[],
  originalPickupPoints: PickupPoint[],
  locationId: string,
  md5ToFileMap: Record<string, File>
): Promise<boolean> => {
  try {
    if (!updatePickupPoints.length && !originalPickupPoints?.length) return;

    const createPickupPointPayload = [];
    const updatePickupPointPayload = [];

    let createImagesPayload = [];
    let deleteImagesPayload = [];

    (updatePickupPoints || []).forEach((point: any): void => {
      // HAndle Formatting Create Payload
      if (!Validation.isValidUUID(point?.id)) createPickupPointPayload.push({ ...point, locationId });
      else {
        // Handle Formatting Update Payload
        const {
          coordinates: originalCoordinates,
          images: originalImages,
          ...originalPickupPoint
        } = originalPickupPoints?.find((val: PickupPoint): boolean => val.id === point.id);
        const { coordinates: updateCoordinates, images: updateImages, ...updatePickupPoint } = point;

        // get all created images
        createImagesPayload = [
          ...createImagesPayload,
          ...(updateImages || [])
            .filter((node: Image): boolean => !Validation.isValidUUID(node?.id))
            .map((node: Image): Image => ({ ...node, path: '' })),
        ];
        // get all deleted images
        deleteImagesPayload = [
          ...deleteImagesPayload,
          ...(originalImages || [])
            .filter((image: Image): boolean => !updateImages.find((node: Image): boolean => node?.id === image.id))
            .map((image: Image): string => image.id),
        ];

        const [partialCoordinates] = getDiff(originalCoordinates, updateCoordinates);
        const [partialPickupPoint] = getDiff(originalPickupPoint, updatePickupPoint);

        const pickupPointChanged = Object.values(partialPickupPoint).length;
        const coordinatesChanged = Object.values(partialCoordinates).length;

        if (pickupPointChanged || coordinatesChanged) {
          let updates: Partial<PickupPoint> = { id: point.id };
          if (pickupPointChanged) updates = { ...updates, ...partialPickupPoint };
          if (coordinatesChanged) updates = { ...updates, coordinates: { ...originalCoordinates, ...partialCoordinates } };
          updatePickupPointPayload.push(updates);
        }
      }
    });
    // Handle Formatting Delete Payload
    const deletePickupPointPayload = (originalPickupPoints || [])
      .filter((originalPoint: PickupPoint): boolean => {
        const updatePickupPoint = (updatePickupPoints || []).find((updatePoint: any): boolean => updatePoint?.id === originalPoint.id);
        return !updatePickupPoint;
      })
      .map((originalPoint: PickupPoint): string => originalPoint.id);

    let uploadImagesPayload = [];

    if (createImagesPayload.length) {
      const res = await createImageBulk(createImagesPayload);
      if (res) uploadImagesPayload = [...uploadImagesPayload, ...(res || [])];
    }
    if (createPickupPointPayload.length) {
      const res = await createPickupPointBulk(createPickupPointPayload);
      if (res) {
        uploadImagesPayload = [...uploadImagesPayload, ...(res || [])?.map(({ images }: PickupPoint): Image[] => images || []).flat()];
      }
    }

    if (uploadImagesPayload.length) await uploadImageBulk(uploadImagesPayload, md5ToFileMap);
    if (deleteImagesPayload.length) await deleteImageBulk(deleteImagesPayload);

    if (updatePickupPointPayload.length) await updatePickupPointBulk(updatePickupPointPayload);
    if (deletePickupPointPayload.length) await deletePickupPointBulk(deletePickupPointPayload);

    if (
      createPickupPointPayload.length ||
      updatePickupPointPayload.length ||
      deletePickupPointPayload.length ||
      uploadImagesPayload.length ||
      deleteImagesPayload.length
    ) {
      return true;
    }
    return false;
  } catch (err) {
    log.error('handleUpdatePickupPointOnLocation', err?.message || err);
  }
};

import { Exact, RunRateOverrideInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { RunRateOverrideDocument, RunRateOverrideMutation } from '@/api/queries';
import { Toast } from '@/models';
import graphApi from '@/api';
import { TypedDocumentNode } from '@apollo/client';

type RunRateOverrideGraphApiResponse = GraphApiResponse<TypedDocumentNode<RunRateOverrideMutation, Exact<{ input: RunRateOverrideInput }>>>;

const title = 'Apply Rate Override';

const [overrideRate] = graphApi(RunRateOverrideDocument, {
  onError: (res: RunRateOverrideGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const applyRateOverride = async (input: RunRateOverrideInput): Promise<RunRateOverrideGraphApiResponse> => {
  try {
    const res = await overrideRate({ input });
    if (res) createNotification('Success', Toast.Type.SUCCESS, title);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export default applyRateOverride;

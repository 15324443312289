import { ResultOf, TypedDocumentNode, VariablesOf } from '@graphql-typed-document-node/core';

import { stringify } from '../utils/objects';

export interface GraphApiProvider {
  query: <T extends TypedDocumentNode = TypedDocumentNode>(
    gqlDoc: T,
    payload: VariablesOf<T>,
    options: GraphApiMethodOptions
  ) => Promise<any>;
  mutate?: <T extends TypedDocumentNode = TypedDocumentNode>(
    gqlDoc: T,
    payload: VariablesOf<T>,
    options: GraphApiMethodOptions
  ) => Promise<any>;
  refetch?: <T extends TypedDocumentNode = TypedDocumentNode>(
    gqlDoc: T,
    payload: VariablesOf<T>,
    options: GraphApiMethodOptions
  ) => Promise<any>;
  response: (res: any) => Partial<GraphApiResponse>;
  options?: (opt: any) => any;
}
type GraphApiBuilderOptions<T extends TypedDocumentNode = TypedDocumentNode> = {
  onError?: (res: GraphApiResponse<T>) => void;
};
export type ConnectionOptions = { pageSize?: number; page?: number; merge?: boolean };
export interface GraphApiMethod<T extends TypedDocumentNode> {
  (variables?: VariablesOf<T>, options?: GraphApiMethodOptions): Promise<GraphApiResponse<T>>;
}
export type GraphApiMethodOptions = {
  merge?: string; // must follow selector syntax e.g. 'searchTrips.tripConnection'
  fetchPolicy?: 'cache-first' | 'cache-and-network' | 'network-only' | 'no-cache' | 'standby' | 'cache-only' | 'no-store';
  nextFetchPolicy?: 'cache-first' | 'cache-and-network' | 'network-only' | 'no-cache' | 'standby' | 'cache-only' | 'no-store';
};
export enum GraphApiErrorLevelEnum {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEBUG = 'debug',
}
export type GraphApiError = {
  message: string;
  path?: string[];
  extensions?: {
    code: number;
    debug: string;
    level: GraphApiErrorLevelEnum;
  };
};
export type GraphApiResponse<T extends TypedDocumentNode = TypedDocumentNode> = {
  errors: GraphApiError[];
  operationName: string;
  variables: VariablesOf<T>;
} & ResultOf<T>;

const graphApiCore =
  (provider: GraphApiProvider) =>
  <Query extends TypedDocumentNode>(
    query: Query,
    queryOptions?: GraphApiBuilderOptions<Query>
  ): [GraphApiMethod<Query>, GraphApiMethod<Query>] => {
    const operation = query?.definitions?.[0]?.['operation'] || 'query';
    const method = operation === 'mutation' ? provider?.mutate || provider.query : provider.query;

    const execute =
      (fetch: GraphApiProvider['query'] | GraphApiProvider['mutate'] | GraphApiProvider['refetch'] = method): GraphApiMethod<Query> =>
      async (variables?: VariablesOf<Query>, options?: GraphApiMethodOptions): Promise<GraphApiResponse<Query>> => {
        const res = await fetch(query, variables, options);

        const parsedResponse: GraphApiResponse<Query> = stringify.parse({
          ...(provider?.response?.(res) || res),
          variables,
          operationName: query?.definitions?.[0]?.['name']?.['value'] || 'GraphQLRequest',
        });

        await handleGraphApiErrors(parsedResponse, queryOptions?.onError);

        return parsedResponse;
      };

    return [execute(), execute(provider?.refetch)];
  };

const handleGraphApiErrors = async <Response extends GraphApiResponse>(
  response: Response,
  onError?: GraphApiBuilderOptions['onError']
): Promise<void> => {
  if (!response?.errors) return;
  if (onError) onError(response);
  else throw new Error(response?.errors?.map?.((err: GraphApiError): string => err?.message || 'Something went wrong.').join('\n'));
};

export type GraphApiMockResponse<T extends TypedDocumentNode> = {
  request: {
    query: string;
    variables?: (opts: VariablesOf<T>) => boolean;
  };
  result: { data: ResultOf<T> };
};

export const createGraphApiMockResponse = <T extends TypedDocumentNode>(
  document: T,
  data: ResultOf<T>,
  options?: { variables?: (opts: VariablesOf<T>) => boolean }
): GraphApiMockResponse<T> => ({
  request: {
    query: document?.definitions?.[0]?.['name']?.['value'],
    variables: options?.variables,
  },
  result: {
    data,
  },
});

export default graphApiCore;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatStatus {
  font-size: 1rem;
  color: var(--bs-gray-subtle);
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatStatus/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,4BAAA;AACF","sourcesContent":[".ChatStatus {\n  font-size: 1rem;\n  color: var(--bs-gray-subtle);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { Email, UpdateEmailBulkInput, UpdateEmailBulkResponse, UpdateEmailInput } from '../../../models/gen/graphql';
import { Validation, generateUpdateBulkPayload } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { UpdateEmailBulkDocument } from '../../queries';
import { createEmailValidator } from './createEmailBulk';
import graphApi from '../..';

type UpdateEmailBulkGraphApiResponse = GraphApiResponse<typeof UpdateEmailBulkDocument>;

const updateEmailValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createEmailValidator
);

const updateEmailBulkResponseSelector = (res: UpdateEmailBulkGraphApiResponse): UpdateEmailBulkResponse =>
  !res.errors ? res?.updateEmailBulk : undefined;

const [runUpdateEmailBulk] = graphApi(UpdateEmailBulkDocument);

const updateEmailBulk = async (emails: Partial<Email>[]): Promise<UpdateEmailBulkResponse> => {
  emails = emails.map((email: Email): Email => updateEmailValidator.partial(email));
  const updates: UpdateEmailInput[] = generateUpdateBulkPayload(emails);
  const input: UpdateEmailBulkInput = {
    updates,
  };
  const res = await runUpdateEmailBulk({ input });

  return updateEmailBulkResponseSelector(res);
};

export default updateEmailBulk;

import { CreateFcrBulkInput, CreateFcrInput, CreateFcrResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { CreateFcrBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { Validation } from '@/utils';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const createFcrValidator = new Validation.Validator({
  description: (val: any): Validation.Validity => ({
    valid: !!(val || '').trim().length && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  cause: (val: any): Validation.Validity => ({
    valid: !!(val || '').trim().length && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  fcrTypeId: (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
  tripId: (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
});

type CreateFcrBulkGraphApiResponse = GraphApiResponse<typeof CreateFcrBulkDocument>;

const title = 'Create Complaint';

const createFcrBulkResponseSelector = (res: CreateFcrBulkGraphApiResponse): CreateFcrResponse[] =>
  !res.errors ? ((res?.createFcrBulk?.output || []) as CreateFcrResponse[]) : undefined;

const [runCreateFcrBulk] = graphApi(CreateFcrBulkDocument, {
  onError: (res: CreateFcrBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createFcrBulk = async (fcrs: CreateFcrInput[]): Promise<CreateFcrResponse[]> => {
  const values = fcrs.map((fcr: CreateFcrInput): CreateFcrInput => createFcrValidator.create(fcr));
  const input: CreateFcrBulkInput = { values };

  const res = await runCreateFcrBulk({ input });
  const selected = createFcrBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateFcrBulk = createGraphApiHook(createFcrBulk);

export default createFcrBulk;

import { ConnectionDetails, QueryInputType, onEnter, queryInput, stringify } from '@/utils';
import {
  CreateVariableTimeInput,
  SortDirectionEnum,
  UpdateVariableTimeValuesInput,
  VariableTime,
  VariableTimeSearch,
} from '@/models/gen/graphql';
import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import SettingsPageGroup, { SettingsPageGroupProps } from '@/features/settings/components/SettingsPageGroup';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import { ComplexTableRef } from '@/components/ComplexTable';
import FormField from '@/components/FormField';
import { GraphApiResponse } from '@/api/core';
import { OnChange } from '@/hooks/useOnChange';
import PageInfo from '@/components/PageInfo';
import VariableTimesTable from '@/features/settings/components/pages/VariableTimes/VariableTimesTable';
import { useCreateVariableTimeBulk } from '@/features/settings/api/services/variableTimes/createVariableTimeBulk';
import { useDeleteVariableTimeBulk } from '@/features/settings/api/services/variableTimes/deleteVariableTimeBulk';
import { useSearchVariableTimes } from '@/features/settings/api/services/variableTimes/searchVariableTimes';
import { useUpdateVariableTimeBulk } from '@/features/settings/api/services/variableTimes/updateVariableTimeBulk';

const TITLE = 'Variable Times';
const ICON = <i className="sv sv-earth" />;
const HEADER = { title: TITLE, icon: ICON };

type VariableTimesFilters = {
  servicerIataAirlineCode: string;
  airportCode: string[];
};
type VariableTimesState = {
  search: string;
  sorting: { column: string; direction: string };
  count: number;
  valid: boolean;
  dirty: boolean;
};
const initVariableTimesFilters: VariableTimesFilters = {
  servicerIataAirlineCode: undefined,
  airportCode: [],
};
const initVariableTimesState: VariableTimesState = {
  search: '',
  sorting: { column: undefined, direction: undefined },
  count: 0,
  valid: true,
  dirty: false,
};
const VariableTimes = (): ReactNode => {
  const [{ data: { rows: data = [] } = {}, loading: loadingVariableTimes }, { refetch: getVariableTimes }] = useSearchVariableTimes();
  const [{ loading: creatingVariableTimes }, { fetch: createVariableTimes }] = useCreateVariableTimeBulk();
  const [{ loading: updatingVariableTimes }, { fetch: updateVariableTimes }] = useUpdateVariableTimeBulk();
  const [{ loading: deletingVariableTimes }, { fetch: deleteVariableTimes }] = useDeleteVariableTimeBulk();

  const [state, setState] = React.useState<VariableTimesState>({ ...initVariableTimesState, count: data.length });
  const { search, count, valid, dirty } = state;

  const loading = useMemo(
    (): boolean => loadingVariableTimes || creatingVariableTimes || updatingVariableTimes || deletingVariableTimes,
    [loadingVariableTimes, creatingVariableTimes, updatingVariableTimes, deletingVariableTimes]
  );

  const complexTableRef = useRef<ComplexTableRef>(null);

  const onChange = useCallback(async (): Promise<void> => {
    if (complexTableRef?.current?.deletions?.length) {
      try {
        const deleteVariableTimeBulkPayload = complexTableRef?.current?.deletions?.flatMap(
          (item: ComplexFormItem<VariableTime>): string[] => item?.id
        );
        await deleteVariableTimes(deleteVariableTimeBulkPayload);
        complexTableRef?.current?.setDeletions([]);
        getVariableTimes();
      } catch (err) {
        console.error(err);
      }
    }
    setState(
      (current: VariableTimesState): VariableTimesState => ({
        ...current,
        count: complexTableRef?.current?.items.filter((item: ComplexFormItem<VariableTime>): boolean => !!item.data).length,
        valid: complexTableRef?.current?.isValid,
        dirty: complexTableRef?.current?.isDirty,
      })
    );
  }, [deleteVariableTimes, getVariableTimes]);

  const onSubmit = useCallback(
    async (filters?: VariableTimesFilters): Promise<ConnectionDetails<VariableTime>> => {
      let query: VariableTimeSearch = {
        createdAt: [queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0)], // default sort by created at
      };
      if (filters?.servicerIataAirlineCode) query.servicerIataAirlineCode = queryInput(filters.servicerIataAirlineCode);
      if (filters?.airportCode?.length) query.airportCode = queryInput(filters.airportCode);

      return getVariableTimes([query]);
    },
    [getVariableTimes]
  );

  const onSave = useCallback(async (): Promise<void> => {
    const transaction = [];
    if (complexTableRef?.current?.additions?.length) {
      transaction.push(async (): Promise<VariableTime[]> => {
        const result = await createVariableTimes(
          complexTableRef?.current?.additions?.map(
            (item: ComplexFormItem<VariableTime>): CreateVariableTimeInput => ({ companyId: '', ...item?.data })
          )
        );
        return result;
      });
    }
    if (complexTableRef?.current?.updates?.length) {
      transaction.push(async (): Promise<GraphApiResponse> => {
        const updateVariableTimeBulkPayload = complexTableRef?.current?.updates?.map(
          (item: ComplexFormItem<VariableTime>): UpdateVariableTimeValuesInput & { id: string } => ({
            id: item?.id,
            ...Object.entries(item?.data).reduce(
              (acc: Record<string, unknown[]>, [key, value]: [string, unknown]): Record<string, unknown[]> => {
                acc[key] = [value];
                return acc;
              },
              {}
            ),
          })
        );
        const result = await updateVariableTimes(updateVariableTimeBulkPayload);
        return result;
      });
    }

    if (transaction.length)
      try {
        await Promise.all(transaction.map((fn) => fn()));
        complexTableRef?.current?.onReset();
        getVariableTimes();
      } catch (err) {
        console.error(err.message || err);
      }
  }, [createVariableTimes, updateVariableTimes, getVariableTimes]);

  const footerProps = useMemo((): { onAdd?: false | (() => void); onCancel?: false | (() => void); onSave?: false | (() => void) } => {
    return {
      onAdd: (): void => complexTableRef?.current?.onAdd?.(),
      onCancel: dirty ? (): void => complexTableRef?.current?.onReset?.() : false,
      onSave: dirty && valid ? (): Promise<void> => onSave?.() : false,
    };
  }, [dirty, valid, onSave]);

  const filterProps = useMemo(
    (): SettingsPageGroupProps['filters'] => ({
      name: 'variableTimesFilters',
      value: initVariableTimesFilters,
      onSubmit,
      onReset: () => {
        setState((current: VariableTimesState): VariableTimesState => ({ ...current, search: '' }));
        return {};
      },
      submitOnMount: true,
      primary: ({ values, onChange }: { values: VariableTimesFilters; onChange: OnChange }): JSX.Element => {
        const { servicerIataAirlineCode, airportCode } = values;
        return (
          <>
            <AirlineIataDropdown
              value={servicerIataAirlineCode}
              onChange={(value: string): void => onChange({ target: { name: 'servicerIataAirlineCode', value } })}
            />
            <AirportGroupDropdown
              value={airportCode}
              onChange={(value: string[]): void => onChange({ target: { name: 'airportCode', value } })}
            />
          </>
        );
      },
      controls: ({ values, onChange }): JSX.Element => {
        const { search } = values;
        return (
          <>
            <FormField
              prepend={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={search || ''}
              onChange={onChange}
              onBlur={(): void => setState((current: VariableTimesState): VariableTimesState => ({ ...current, search }))}
              onKeyDown={onEnter((): void => {
                setState((current: VariableTimesState): VariableTimesState => ({ ...current, search }));
              })}
              placeholder="Search"
              condensed
            />
          </>
        );
      },
    }),
    [onSubmit]
  );

  const filter = useCallback(
    (item: ComplexFormItem<VariableTime>): boolean =>
      !search ||
      stringify([
        item.data?.servicerIataAirlineCode,
        item.data?.airportCode,
        item.data?.inboundDomestic,
        item.data?.inboundInternational,
        item.data?.outbound,
      ])
        .toLowerCase()
        .includes(search.toLowerCase()),
    [search]
  );

  return (
    <SettingsPageGroup header={HEADER} footer={footerProps} filters={filterProps}>
      <PageInfo>Variable Times: {count}</PageInfo>
      <VariableTimesTable data={data} onChange={onChange} options={{ loading, filter }} ref={complexTableRef} />
    </SettingsPageGroup>
  );
};

export default VariableTimes;

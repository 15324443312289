import { GetRolesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getRoles = createGraphApiMockResponse(GetRolesDocument, {
  getFilters: {
    filters: {
      roles: [
        {
          id: 'fd0937d6-bd4d-4ad8-a366-c400be772f71',
          displayName: 'dispatch',
          __typename: 'FilterOption',
        },
        {
          id: 'fd0937d6-bd4d-4ad8-a366-c400be772f71',
          displayName: 'crew',
          __typename: 'FilterOption',
        },
        {
          id: 'fd0937d6-bd4d-4ad8-a366-c400be772f71',
          displayName: 'driver',
          __typename: 'FilterOption',
        },
        {
          id: 'fd0937d6-bd4d-4ad8-a366-c400be772f71',
          displayName: 'system',
          __typename: 'FilterOption',
        },
        {
          id: 'fd0937d6-bd4d-4ad8-a366-c400be772f71',
          displayName: 'leader',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

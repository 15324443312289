import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const OptionsPane = ({ debugState: [state, setState] }) => {
  const { orientation } = state;

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((current) => ({ ...current, [name]: value }));
  };

  return (
    <InputGroup>
      <InputGroup.Text>Orientation</InputGroup.Text>
      <Form.Control as="select" onChange={onChange} name="orientation" value={orientation}>
        <option value="start">Left</option>
        <option value="end">Right</option>
        <option value="top">Top</option>
        <option value="bottom">Bottom</option>
        <option value="full">Full Screen</option>
      </Form.Control>
    </InputGroup>
  );
};

export default OptionsPane;

import { UserSearch, UserWithAirportConnectionInput } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchUserTable: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (
      first: number = PAGE_SIZE,
      after: string = null,
      query: UserSearch[] = null,
      airports: string[] = null,
      options: any = {}
    ): Promise<any> => {
      const input: UserWithAirportConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
        airports,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchUsers.userWithAirportConnection'),
  query: gql`
    query SearchUserTable($input: UserWithAirportConnectionInput) {
      searchUsers {
        userWithAirportConnection(input: $input) {
          totalCount
          edges {
            node {
              id
              username
              firstName
              lastName
              fullName
              active
              role {
                id
                name
              }
              email
              employeeId
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  `,
};

export default searchUserTable;

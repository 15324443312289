import { InvoiceFormatEnum, InvoiceTypeTypeEnum } from '../../models/gen/graphql';

import Filters from '../../components/Filters';
import FormButton from '../../components/FormButton';
import FormField from '../../components/FormField';
import { ParsedInvoiceInput } from '../../api/services/invoices';
import React from 'react';
import Select from '../../components/Select';
import SelectAirlineIata from '../../components/SelectAirlineIata';
import SelectAirport from '../../components/SelectAirport';
import SelectClient from '../../components/SelectClient';
import SelectFromEnum from '../../components/SelectFromEnum';
import SelectStatus from '../../components/SelectStatus';
import { useAppState } from '../../store/appReducer';
import { useNavigate } from 'react-router-dom';

type FiltersProps = {
  value: Partial<ParsedInvoiceInput>;
  onSubmit: (values: any) => Promise<any>;
};
type FilterSegmentProps = {
  values: {
    payerProviderId: string;
    iataAirlineCodes: string[];
    airports: string[];
    types: string[];
    startDatetime: string;
    endDatetime: string;
    format: string;
    tripStatus: string[];
  };
  onChange: any;
};
type FiltersValidityCheck = {
  airports: string[];
  payerProviderId: string;
  types: string[];
};
const InvoicePreviewFilters = ({ value = {}, onSubmit }: FiltersProps): JSX.Element => {
  const [{ config }] = useAppState();
  const navigate = useNavigate();
  return (
    <Filters
      name="invoicePreviewFilters"
      onSubmit={onSubmit}
      value={value}
      isValid={({ airports, payerProviderId, types }: FiltersValidityCheck): boolean =>
        !!airports?.length && !!types?.length && !!payerProviderId
      }
      primary={({
        values: { payerProviderId, iataAirlineCodes, airports, types, startDatetime, endDatetime },
        onChange,
      }: FilterSegmentProps): JSX.Element => (
        <>
          <FormField
            name="dates"
            type="daterange"
            value={[startDatetime, endDatetime]}
            onChange={onChange.dateRangeWithThreshold('startDatetime', 'endDatetime', 30, { showNotification: true })}
            condensed
          />
          <FormField
            name="payerProviderId"
            placeholder="Client"
            value={payerProviderId}
            valid={payerProviderId ? undefined : false}
            onChange={onChange}
            searchable
            condensed
            options={{ input: { as: SelectClient } }}
          />
          <FormField
            name="iataAirlineCodes"
            placeholder="Airline"
            value={iataAirlineCodes}
            onChange={onChange}
            searchable
            multiple
            condensed
            options={{ input: { as: SelectAirlineIata } }}
          />
          <FormField
            name="airports"
            placeholder="Airport"
            value={airports}
            valid={(airports || []).length ? undefined : false}
            onChange={onChange}
            multiple
            searchable
            condensed
            options={{ input: { as: SelectAirport } }}
          />
          <FormField
            name="types"
            placeholder="Type"
            value={types}
            valid={(types || []).length ? undefined : false}
            onChange={onChange}
            multiple
            searchable
            condensed
            options={{
              input: {
                as: (props: any): JSX.Element => (
                  <SelectFromEnum {...props} source={InvoiceTypeTypeEnum} getLabel={(key: string): string => key?.toUpperCase?.() || ''} />
                ),
              },
            }}
          />
        </>
      )}
      alternate={(): JSX.Element => (
        <>
          <FormButton
            name="INVOICE_HISTORY"
            icon={<i className="sv sv-invoice-history {font-size:1.5rem;}" />}
            className="{white-space:nowrap!;}"
            variant="outline-gray"
            to="/invoices"
          >
            Invoice History
          </FormButton>
        </>
      )}
      secondary={({ values: { format, tripStatus }, onChange }: FilterSegmentProps): JSX.Element => (
        <>
          <FormField
            name="format"
            placeholder="Standard"
            value={format}
            onChange={onChange}
            condensed
            options={{
              input: {
                as: (props: any): JSX.Element => (
                  <Select {...props}>
                    <option value={InvoiceFormatEnum.Crew}>Crew Info</option>
                  </Select>
                ),
              },
            }}
          />
          {config?.auth?.allowTripStatusInvoiceFilter && (
            <FormField
              name="tripStatus"
              placeholder="Status"
              value={tripStatus}
              onChange={onChange}
              searchable
              multiple
              options={{
                input: { as: (props: any): JSX.Element => <SelectStatus {...props} className="Status" /> },
              }}
              condensed
            />
          )}
        </>
      )}
    />
  );
};

export default InvoicePreviewFilters;

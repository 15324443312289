// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueryDropdown .fa-refresh {
  opacity: 0.5;
  transition: opacity 0.5s;
}
.QueryDropdown .fa-refresh:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/QueryDropdown/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,wBAAA;AAAJ;AACI;EACE,UAAA;AACN","sourcesContent":[".QueryDropdown .fa-refresh {\n  opacity: 0.5;\n  transition: opacity 0.5s;\n}\n.QueryDropdown .fa-refresh:hover {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails, queryInput } from '../../../utils/custom';
import {
  FlagWithTrip,
  Trip,
  TripConnection,
  TripConnectionInput,
  TripSearch,
  TripTableConnectionInput,
  TripTableSearch,
} from '../../../models/gen/graphql';
import {
  GetFlagsByTripIdDocument,
  GetTripDocument,
  SearchTripsDocument,
  SearchTripsForRatesDocument,
  SearchTripsTableDocument,
} from '../../queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchTripsGraphApiResponse = GraphApiResponse<typeof SearchTripsDocument>;
export type SearchTripsTableGraphApiResponse = GraphApiResponse<typeof SearchTripsTableDocument>;
export type SearchTripsForRatesGraphApiResponse = GraphApiResponse<typeof SearchTripsForRatesDocument>;
export type GetFlagsByTripIdGraphApiResponse = GraphApiResponse<typeof GetFlagsByTripIdDocument>;
export type GetTripForModalGraphApiResponse = GraphApiResponse<typeof GetTripDocument>;
export type SearchTripsOptions = { pageSize?: number; page?: number; merge?: true };

export const SEARCH_TRIPS_PAGE_SIZE = 1000;
export const SEARCH_TRIPS_TABLE_PAGE_SIZE = 1000;

const title = 'Search Trips Table';
const TripTableSearchKeys = [
  'actual',
  'airportCode',
  'attendants',
  'doLocationId',
  'driverId',
  'flightNumber',
  'kind',
  'latestScheduled',
  'latestScheduledUtc',
  'loopName',
  'payerProviderId',
  'pilots',
  'providerId',
  'puLocationId',
  'rateAmount',
  'scheduled',
  'scheduledUtc',
  'servicerIataAirlineCode',
  'status',
  'type',
  'vehicleId',
];

const validatePayload = (payload: TripTableSearch[]): TripTableSearch[] => {
  if (!payload) return [];
  if (!Array.isArray(payload)) payload = [payload];
  return payload.map(
    (p: TripTableSearch): TripTableSearch =>
      Object.keys(p).reduce((acc: TripTableSearch, curr): TripTableSearch => {
        if (TripTableSearchKeys.includes(curr)) {
          acc[curr] = p[curr];
        } else {
          console.log(`An invalid field was removed from TripTableSearch query: ${curr}`);
        }
        return acc;
      }, {})
  );
};

export const searchTripsResponseSelector = (res: SearchTripsGraphApiResponse): ConnectionDetails<Trip> =>
  convertConnectionToDetails(res?.searchTrips?.tripConnection as TripConnection);
export const searchTripsTableResponseSelector = (res: SearchTripsTableGraphApiResponse): ConnectionDetails<Trip> =>
  convertConnectionToDetails(res?.searchTrips?.tripTableConnection as TripConnection);
export const searchTripsForRatesResponseSelector = (res: SearchTripsForRatesGraphApiResponse): ConnectionDetails<Trip> =>
  convertConnectionToDetails(res?.searchTrips?.tripConnection as TripConnection);
export const getFlagsByTripIdResponseSelector = (res: GetFlagsByTripIdGraphApiResponse): FlagWithTrip[] =>
  res ? (res?.searchTrips?.tripConnection?.edges?.[0]?.node?.flags as FlagWithTrip[]) : [];
export const getTripForModalResponseSelector = (res: GetTripForModalGraphApiResponse): Trip =>
  res ? (res?.searchTrips?.tripConnection?.edges?.[0]?.node as Trip) : undefined;

const [runSearchTrips, runRefetchSearchTrips] = graphApi(SearchTripsDocument, {
  onError: (res: SearchTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});
const [runSearchTripsTable, runRefetchSearchTripsTable] = graphApi(SearchTripsTableDocument, {
  onError: (res: SearchTripsTableGraphApiResponse): void => handleError(res, { notification: { title } }),
});
const [, runRefetchSearchTripsForRates] = graphApi(SearchTripsForRatesDocument, {
  onError: (res: SearchTripsForRatesGraphApiResponse): void => handleError(res, { notification: { title } }),
});
const [, runRefetchGetFlagsByTripId] = graphApi(GetFlagsByTripIdDocument, {
  onError: (res: SearchTripsForRatesGraphApiResponse): void => handleError(res, { notification: { title: 'Get Trip Flags' } }),
});
const [, runRefetchGetTripForModal] = graphApi(GetTripDocument, {
  onError: (res: GetTripForModalGraphApiResponse): void => handleError(res, { notification: { title: 'Get Trip Data' } }),
});

const searchTrips = async (query: TripSearch[], options: SearchTripsOptions = {}): Promise<ConnectionDetails<Trip>> => {
  const { pageSize = SEARCH_TRIPS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: TripConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchTrips.tripConnection' : undefined };

  const res = await runSearchTrips({ input }, graphApiOptions);

  return searchTripsResponseSelector(res);
};

const refetchSearchTrips = async (query: TripSearch[], options: SearchTripsOptions = {}): Promise<ConnectionDetails<Trip>> => {
  const { pageSize = SEARCH_TRIPS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: TripConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchTrips.tripConnection' : undefined };

  const res = await runRefetchSearchTrips({ input }, graphApiOptions);

  return searchTripsResponseSelector(res);
};
const searchTripsTable = async (
  search: TripTableConnectionInput = { format: null },
  options: SearchTripsOptions = {}
): Promise<ConnectionDetails<Trip>> => {
  const { format, query } = search;
  const { pageSize = SEARCH_TRIPS_TABLE_PAGE_SIZE, page = 0, merge = false } = options;
  const input: TripTableConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query: validatePayload(query),
    format,
  };
  const graphApiOptions = { merge: merge ? 'searchTrips.tripTableConnection' : undefined };

  const res = await runSearchTripsTable({ input }, graphApiOptions);

  return searchTripsTableResponseSelector(res);
};
const refetchSearchTripsTable = async (
  search: TripTableConnectionInput = { format: null },
  options: SearchTripsOptions = {}
): Promise<ConnectionDetails<Trip>> => {
  const { format, query } = search;
  const { pageSize = SEARCH_TRIPS_TABLE_PAGE_SIZE, page = 0, merge = false } = options;

  const input: TripTableConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query: validatePayload(query),
    format,
  };
  const graphApiOptions = { merge: merge ? 'searchTrips.tripTableConnection' : undefined };

  const res = await runRefetchSearchTripsTable({ input }, graphApiOptions);

  return searchTripsTableResponseSelector(res);
};

export const searchTripsForRates = async (query: TripSearch[], options: SearchTripsOptions = {}): Promise<ConnectionDetails<Trip>> => {
  const { pageSize = SEARCH_TRIPS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: TripConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchTrips.tripConnection' : undefined };

  const res = await runRefetchSearchTripsForRates({ input }, graphApiOptions);

  return searchTripsForRatesResponseSelector(res);
};

export const getFlagsByTripId = async (query: TripSearch[], options: SearchTripsOptions = {}): Promise<FlagWithTrip[]> => {
  const { pageSize = SEARCH_TRIPS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: TripConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchTrips.tripConnection' : undefined };

  const res = await runRefetchGetFlagsByTripId({ input }, graphApiOptions);

  return getFlagsByTripIdResponseSelector(res);
};

export const getTripForModal = async (tripId: string): Promise<Trip> => {
  const input: TripConnectionInput = {
    first: 1,
    after: null,
    query: [{ id: queryInput(tripId) }],
  };

  const res = await runRefetchGetTripForModal({ input });

  return getTripForModalResponseSelector(res);
};

export const useSearchTrips = createGraphApiHook(searchTrips, {
  refetch: refetchSearchTrips,
  merge: mergeConnectionDetails,
});
export const useSearchTripsTable = createGraphApiHook(searchTripsTable, {
  refetch: refetchSearchTripsTable,
  merge: mergeConnectionDetails,
});
export const useSearchTripsForRates = createGraphApiHook(searchTripsForRates, {
  merge: mergeConnectionDetails,
});
export const useGetFlagsByTripId = createGraphApiHook(getFlagsByTripId);
export const useGetTripForModal = createGraphApiHook(getTripForModal);

export default searchTrips;

import { CombineRule, CreateCombineRuleInput } from '@/models/gen/graphql';
import { HandleErrorInput, handleError } from '@/utils/custom';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { CreateCombineRuleBulkDocument } from '@/api/queries';
import { GraphApiMethod } from '@/api/core';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Create Combine Rule Setting' } });

type ServiceMethodPayload = { [key in keyof CreateCombineRuleInput]: any }[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<CombineRule[]>;
}

// CreateCombineRuleBulk Service
export const { createCombineRuleBulk, useCreateCombineRuleBulk } = ((): {
  createCombineRuleBulk: ServiceMethod;
  useCreateCombineRuleBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(CreateCombineRuleBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof CreateCombineRuleBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<CombineRule[]> => {
        const values: CreateCombineRuleInput[] = payload.map(
          (combineRule: CreateCombineRuleInput): CreateCombineRuleInput => ({ ...combineRule }) // Combine Rule does not have active, so can't set = 1
        );
        const res = await fn({ input: { values } });
        return res?.createCombineRuleBulk?.output?.map(({ node }: { node: CombineRule }): CombineRule => node) || [];
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    createCombineRuleBulk: mutate,
    useCreateCombineRuleBulk: useService,
  };
})();

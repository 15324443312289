import './styles.scss';

import { LatestRate, LatestRateTypeEnum } from '@/models/gen/graphql';

import { TODAY } from '@/constants';
import { Datetime, isDateBetween } from '@/utils/dates';

type RateTypeCellProps = {
  data: LatestRate;
  value: LatestRateTypeEnum;
  displayValue: string;
};

const RateTypeCell = ({ data, value, displayValue }: RateTypeCellProps) => {
  const isBetween = isDateBetween(TODAY, data?.startDatetime, data?.endDatetime);
  const isAfter = new Datetime(TODAY).asDayjs().isAfter(data?.endDatetime);
  const isContractType = value === LatestRateTypeEnum.Contract;
  return (
    <div className="RateTypeCell d-flex flex-column">
      <span className="RateTypeCell-Type">{displayValue}</span>
      {isContractType && isBetween && <small className="RateTypeCell-Contract text-green">In-Contract</small>}
      {isContractType && isAfter && <small className="RateTypeCell-Contract text-danger">Expired</small>}
    </div>
  );
};

export default RateTypeCell;

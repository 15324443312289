import { Provider, UpdateProviderBulkInput, UpdateProviderBulkResponse, UpdateProviderInput } from '@/models/gen/graphql';
import { Validation, generateUpdateBulkPayload } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { UpdateAirlineBulkDocument } from '@/api/queries';
import { createAirlineValidator } from './createAirlineBulk';
import graphApi from '@/api';

type UpdateAirlineBulkGraphApiResponse = GraphApiResponse<typeof UpdateAirlineBulkDocument>;

const updateAirlineValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createAirlineValidator
);

const updateAirlineBulkResponseSelector = (res: UpdateAirlineBulkGraphApiResponse): UpdateProviderBulkResponse =>
  !res.errors ? res?.updateAirlineBulk : undefined;

const [runUpdateAirlineBulk] = graphApi(UpdateAirlineBulkDocument);

const updateAirlineBulk = async (airlines: Partial<Provider>[]): Promise<UpdateProviderBulkResponse> => {
  airlines = airlines.map((airline: Provider): Provider => updateAirlineValidator.partial(airline));
  const updates: UpdateProviderInput[] = generateUpdateBulkPayload(airlines);
  const input: UpdateProviderBulkInput = {
    updates,
  };
  const res = await runUpdateAirlineBulk({ input });

  return updateAirlineBulkResponseSelector(res);
};

export const updateAirlineActivationBulk = async (input: Array<Pick<Provider, 'id' | 'active'>>): Promise<UpdateProviderBulkResponse> => {
  return await updateAirlineBulk(input);
};

export default updateAirlineBulk;

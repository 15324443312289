import EnumDropdown, { EnumDropdownMulti, EnumDropdownMultiProps, EnumDropdownProps } from '@/components/EnumDropdown';

import { ReactNode } from 'react';
import { TripTypeEnum } from '@/models/gen/graphql';

type TripTypeDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  name?: string;
};
type TriptTypeDropdownMultiProps = Omit<EnumDropdownMultiProps, 'enum' | 'name'> & {
  name?: string;
};
const defaultLocale = { 'Select...': 'Type' };
const Enum = Object.values(TripTypeEnum).reduce((acc, val) => {
  // ensures keys and values are capitalized
  acc[val.toUpperCase()] = val.toUpperCase();
  return acc;
}, {});

const TripTypeDropdown = ({ name = 'type', ...props }: TripTypeDropdownProps): ReactNode => {
  return <EnumDropdown {...props} enum={Enum} name={name} options={{ locale: props?.options?.locale || defaultLocale }} />;
};

export const TripTypeDropdownMulti = ({ name = 'type', ...props }: TriptTypeDropdownMultiProps): ReactNode => {
  return <EnumDropdownMulti {...props} enum={Enum} name={name} options={{ locale: props?.options?.locale || defaultLocale }} />;
};

export default TripTypeDropdown;

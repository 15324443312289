// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-overlay {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  transition: background 0.2s;
}

.image-overlay:hover {
  background: rgba(0, 0, 0, 0.25);
}

.Users .VirtualTable-Container .VirtualTable-Row .VirtualTable-Cell[selector=active] > span {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/pages/Users/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AACA;EACE,+BAAA;AAEF;;AAMU;EACE,iBAAA;AAHZ","sourcesContent":[".image-overlay {\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: rgba(0, 0, 0, 0);\n  transition: background 0.2s;\n}\n\n.image-overlay:hover {\n  background: rgba(0, 0, 0, 0.25);\n}\n\n.Users .VirtualTable-Container .VirtualTable-Row .VirtualTable-Cell[selector=active] > span {\n  overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { Trip, UpdateResponse, UpdateTripBulkInput } from '@/models/gen/graphql';
import { Validation, createNotification, generateUpdateBulkPayload, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { UpdateTripBulkDocument } from '@/api/queries';
import { createTripsValidator } from '@/api/services/trips/createTrips';
import graphApi from '@/api';

const updateTripValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
    combineId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createTripsValidator
);

type UpdateTripBulkGraphApiResponse = GraphApiResponse<typeof UpdateTripBulkDocument>;

const title = 'Update Trips';

const updateTripBulkResponseSelector = (res: UpdateTripBulkGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.updateTripBulk?.response || [] : undefined;

const [runUpdateTripBulk] = graphApi(UpdateTripBulkDocument, {
  onError: (res: UpdateTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateTripBulk = async (trips: Partial<Trip>[]): Promise<UpdateResponse[]> => {
  trips = trips.map((trip: Trip): Trip => updateTripValidator.partial(trip));
  const updates = generateUpdateBulkPayload(trips);
  const input: UpdateTripBulkInput = {
    updates,
  };
  const res = await runUpdateTripBulk({ input });

  const selected = updateTripBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateTripBulk;

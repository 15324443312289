import './styles.scss';

import { Modal, Table } from 'react-bootstrap';
import React, { useMemo } from 'react';

import { titleCase } from 'utils';
import useGlobalState from '@/hooks/useGlobalState';

interface HotkeysModalProps {
  show: boolean;
  onHide: () => void;
}

const metaKey = navigator.userAgent.indexOf('Mac OS X') >= 0 ? 'cmd' : 'ctrl';
const parseCombination = (keyCombination: string): string =>
  titleCase(keyCombination.replace(/\s{0,}\+\s{0,}/g, ' + ').replace(/(ctrl)/g, metaKey));

const HotkeysModal = ({ show, onHide }: HotkeysModalProps): JSX.Element => {
  const hotkeys = useGlobalState(({ state: { hotkeys } }) => hotkeys || {});

  const content = useMemo(
    (): JSX.Element[] =>
      Array.from(
        new Set(
          Object.values(hotkeys)
            .map((value: any): any => value.source)
            .sort()
        )
      ).map(
        (source: any, s: number): JSX.Element => (
          <div className="Hotkey-Table" key={s}>
            <h6 className="Hotkey-Table-Header text-center pt-2 pb-2 text-gray m-0">{source.replace(/_/g, ' ').toUpperCase()}</h6>
            <Table className="Hotkey-Table-Body">
              <tbody>
                {Object.entries(hotkeys)
                  .filter(([, group]: [string, any]): boolean => group.source === source)
                  .map(
                    ([keyCombination, { name, description }]: [string, { name: string; description: string }], k: number): JSX.Element => (
                      <tr className="d-flex" key={k}>
                        <td className="w-50 border border-top-0 border-right-1 d-flex align-items-center justify-content-center">
                          <h5>{parseCombination(keyCombination)}</h5>
                        </td>
                        <td className="w-50 text-start d-flex align-items-center">
                          <h6 className="w-50">{name}</h6>
                          <p className="w-50">{description}</p>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          </div>
        )
      ),
    [hotkeys]
  );

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <h4 className="border-bottom border-2 p-2">Hotkeys</h4>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
};

export default HotkeysModal;

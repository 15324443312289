import { DynamicCell, RowContext } from '..';
import React, { useContext, useMemo } from 'react';

import { Row } from '@/models/gen/graphql';
import detailedReportTable from './tables/detailedReportTable';
import drivetimeReportTable from './tables/drivetimeReportTable';
import punchReportTable from './tables/punchReportTable';
import punchReportTableDetails from '@/components/VirtualTable/TemplateCell/tables/punchReportTableDetails';

export type TemplatedCell = {
  cell?: TemplatedCellComponent;
  props?: any;
};
export type TemplatedCellComponent = (props: TemplatedCellComponentProps) => JSX.Element;
export type TemplatedCellComponentProps = { row?: Row; cell?: { [key: string]: any }; value?: string; children?: string };

const dynamicTables: { [key: string]: (key: string) => TemplatedCell } = {
  punchReportTable,
  drivetimeReportTable,
  detailedReportTable,
  punchReportTableDetails,
};

const TemplateCell = ({ ...props }: any): JSX.Element => {
  const tableContext = useContext(RowContext);
  const tableSettings =
    dynamicTables?.[props?.table] || (props?.table?.endsWith?.('Details') ? dynamicTables.detailedReportTable : undefined);
  const { cell: Cell, props: getCellProps } = tableSettings?.(props?.name) || {};
  const cellProps = useMemo(
    (): Record<string, unknown> => (typeof getCellProps === 'function' ? getCellProps?.(tableContext) : getCellProps) || {},
    [getCellProps, tableContext]
  );
  const renderTemplate = ({ value, data }: { value: string; data: Row }): JSX.Element => {
    if (!Cell) return <>{value}</>;
    return (
      <Cell row={data} cell={props} value={value}>
        {value}
      </Cell>
    );
  };
  return <DynamicCell {...props} {...cellProps} render={renderTemplate} />;
};

export default React.memo(TemplateCell);

import './styles.scss';

import React, { useMemo } from 'react';

import { Container } from 'react-bootstrap';
import DynamicReport from '@/pages/Reports/DynamicReport';
import ProtectedRoute from '../../components/ProtectedRoute';
import PunchPolicyUpload from '../PunchPolicy/components/PunchPolicyUpload/PunchPolicyUpload';
import { useParams } from 'react-router-dom';
import useSwitch from '../../hooks/useSwitch';

export const reportTypes = {
  clientSummaryReport: ['Client Summary Report'],
  completionReport: ['Completion Report'],
  drivetimeReport: ['Drive Time Report'],
  complaintReport: ['Complaint Report'],
  kpiReport: ['KPI Report'],
  onTimePerformanceReport: ['On Time Performance'],
  ratingsReport: ['Ratings Report'],
  vehicleReport: ['Vehicle Report'],
  punchReport: ['Punch Policy Report'],
  detailedReport: ['Detailed Report'],
};

const Reports = (): JSX.Element => {
  const { type } = useParams();
  const reportType = Object.keys(reportTypes).includes(type) ? type : Object.keys(reportTypes)[0];

  const EmptyElement = useMemo(() => {
    switch (reportType) {
      case 'punchReport':
        return PunchPolicyUpload;
      default:
        return undefined;
    }
  }, [reportType]);

  const [title, Content = <DynamicReport onEmpty={EmptyElement} />] = useSwitch(
    reportType,
    reportTypes,
    reportTypes?.[Object.keys(reportTypes)?.[0]] || null
  );

  return (
    <ProtectedRoute title={title} key={title}>
      <Container className={`page-container${!type || type.endsWith('Report') ? ' full-width bg-white' : ''} w-100`} fluid>
        {Content}
      </Container>
    </ProtectedRoute>
  );
};

export default Reports;

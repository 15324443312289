import { CreatePermissionHasUserBulkInput, CreatePermissionHasUserInput, CreatePermissionHasUserResponse } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreatePermissionHasUserBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validatePermissionHasUser = validateObj({
  userId: (val: any): any => val || '',
  permissionId: (val: any): any => val || '',
});

type CreatePermissionHasUserBulkGraphApiResponse = GraphApiResponse<typeof CreatePermissionHasUserBulkDocument>;

const title = 'Assign Airports to Users';

const createPermissionHasUserBulkResponseSelector = (
  res: CreatePermissionHasUserBulkGraphApiResponse
): CreatePermissionHasUserResponse[] =>
  !res.errors ? ((res?.createPermissionHasUserBulk?.output || []) as CreatePermissionHasUserResponse[]) : undefined;

const [runCreatePermissionHasUserBulk] = graphApi(CreatePermissionHasUserBulkDocument, {
  onError: (res: CreatePermissionHasUserBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createPermissionHasUserBulk = async (
  permissionHasUser: CreatePermissionHasUserInput[]
): Promise<CreatePermissionHasUserResponse[]> => {
  const input: CreatePermissionHasUserBulkInput = {
    values: permissionHasUser.map(validatePermissionHasUser),
  };

  const res = await runCreatePermissionHasUserBulk({ input });
  const selected = createPermissionHasUserBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreatePermissionHasUserBulk = createGraphApiHook(createPermissionHasUserBulk);

export default createPermissionHasUserBulk;

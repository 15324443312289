import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { RunAssignVehicleToDriverInput, Trip, Vehicle } from '../../models/gen/graphql';

import Logger from '../../utils/logs';
import Select from '../Select';
import assignVehicleToDriverBulk from '../../api/services/vehicles/assignVehicleToDriver';
import useClassNames from '../../hooks/useClassNames';
import { useGetVehicleOptions } from '../../api/services/vehicles/searchVehicles';

const log = Logger.of('SelectVehicle');

type SelectVehicleState = {
  selected: string;
  loading: boolean;
};
const initStateValue: SelectVehicleState = {
  selected: '',
  loading: false,
};
type Props = {
  placeholder?: string;
  data: Trip;
  refetchParent?: () => void;
  className?: string;
  [x: string]: any;
};
const SelectVehicle = (
  { placeholder = 'Select...', data: trip = undefined, refetchParent = (): void => {}, ...props }: Props,
  ref: ForwardedRef<HTMLButtonElement | HTMLInputElement>
): React.JSX.Element => {
  // Init State
  const [state, setState] = useState(initStateValue);
  const { selected, loading } = state;
  const classes = useClassNames('SelectVehicle', '{color:#000!;}>span', props?.className);

  // Init Queries
  const [{ data }, { fetch: getVehicleOptions }] = useGetVehicleOptions();
  const vehicles: Vehicle[] = data?.rows || [];

  //When the user clicks on the cell we want to fire the query to get the vehicles available at this moment
  const onSearch = async (): Promise<void> => {
    try {
      setState((current: SelectVehicleState): SelectVehicleState => ({ ...current, loading: true }));
      if (!trip?.airportCode) throw new Error('No airportCode on trip');
      await getVehicleOptions(trip?.airportCode);
    } catch (err) {
      log.error('onSearch', err.message || err).notify({ title: 'Assign Vehicle To Trip' });
    } finally {
      setState((current: SelectVehicleState): SelectVehicleState => ({ ...current, loading: false }));
    }
  };

  //This will handle changing state to the selected value
  //and calling the endpoint to update both user and trip vehicleId
  const handleChange = async (event): Promise<void> => {
    try {
      const { value } = event.target;
      if (!value) return;
      setState((current: SelectVehicleState): SelectVehicleState => ({ ...current, loading: true }));
      if (!trip) throw new Error('No trip found.');
      const input: RunAssignVehicleToDriverInput = {
        userId: trip?.driverId || '',
        vehicleId: value,
        tripId: trip?.id || null,
        scheduled: trip?.scheduled || null,
      };
      await assignVehicleToDriverBulk(input);
      await refetchParent();
    } catch (err) {
      log.error('handleChange', err.message || err).notify({ title: 'Assign Vehicle To Trip' });
    } finally {
      setState((current: SelectVehicleState): SelectVehicleState => ({ ...current, loading: false }));
    }
  };

  useEffect((): void => {
    if (!trip?.vehicleId) return;
    setState((current: SelectVehicleState): SelectVehicleState => ({ ...current, selected: trip.vehicleId }));
  }, [trip]);

  return (
    <Select
      {...props}
      value={vehicles.length ? selected : ''}
      onChange={handleChange}
      placeholder={placeholder}
      onFocus={onSearch}
      className={classes}
      loading={loading}
      ref={ref}
    >
      {vehicles.map(
        (vehicle: Vehicle, v: number): React.JSX.Element => (
          <option value={vehicle.id} key={v}>
            {vehicle.trackingId}
          </option>
        )
      )}
    </Select>
  );
};

export default forwardRef(SelectVehicle);

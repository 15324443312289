import './styles.scss';

import { Button, Form } from 'react-bootstrap';
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Virtuoso, VirtuosoProps } from 'react-virtuoso';
import { getClasses, getProperty, properCase, remToPixels, titleCase, unCamelCase } from '@/utils';

import FormField from '@/components/FormField';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { Middle } from '@/components/Align';
import PageSettings from '@/components/PageSettings';
import Tippy from '@tippyjs/react';
import TippyWhen from '@/components/TippyWhen';
import useEventListener from '@/hooks/useEventListener';
import useLocale from '@/hooks/useLocale';
import useSettings from '@/state/settings';
import { useVirtualTable } from './utils';

type VirtualTableOptions = {
  ignoreColumnSettings: boolean;
};
export type VirtualTableProps<Data = any> = {
  data?: Data[];
  style?: React.CSSProperties;
  rowRenderer?: (index: any, row: any) => JSX.Element;
  components?: any;
  options?: VirtualTableOptions;
  name?: string;
  children?: any;
  header?: any;
  expanded?: any;
  loading?: boolean;
  locale?: any;
  onLazyLoad?: any;
  dynamic?: boolean;
  dynamicRowHeight?: boolean;
  selected?: Array<string>;
  onSelect?: (id: string | string[]) => void;
} & Omit<VirtuosoProps<Data, any>, 'selected' | 'onDoubleClick' | 'onSelect'>;

const VirtualTable = (props: VirtualTableProps, ref: React.ForwardedRef<HTMLElement>): JSX.Element => {
  const {
    name,
    data = [],
    style,
    components = {},
    rowRenderer: RenderRow = DynamicRow,
    header,
    expanded = [],
    loading = false,
    locale: localeOverrides,
    onLazyLoad,
    dynamic,
    dynamicRowHeight,
    options,
    ...virtuosoProps
  } = props;
  const containerRef = useRef(null);
  const tableRef = useRef(null);
  useImperativeHandle(ref, (): HTMLElement => tableRef.current);
  const tableContentRef = useRef(null);
  const routeContainer = useRef(null);
  const [tableHeight, setTableHeight] = useState(style?.height || 300);
  const [fullHeight, setFullHeight] = useState(style?.height || 300);
  const [printing, setPrinting] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const locale = useLocale(localeOverrides);
  const itemContent = (index: any, data: any, { lineage = [], ...context }: any, forceExpand: boolean = false): JSX.Element => {
    index = !!header && typeof index === 'number' ? index - 1 : index;
    return (
      <React.Fragment key={index}>
        <RenderRow data={data} index={index} context={context} lineage={lineage} />
        {(!!forceExpand || expanded.includes(data?.id || index)) && (
          <div className="VirtualTable-Expanded">
            {(data?.children || []).map(
              (child: any, c: number): JSX.Element =>
                itemContent(`${data?.id || index}_${c}`, child, { ...context, lineage: [...lineage, data] })
            )}
          </div>
        )}
      </React.Fragment>
    );
  };
  const handleResize = useCallback((): void => {
    if (document.hidden) return;
    if (!routeContainer.current) {
      const container = document.querySelector('.RouteContent');
      routeContainer.current = container;
    }
    const realHeight =
      dynamic !== undefined || !!dynamic
        ? tableContentRef.current?.querySelector('.VirtualTable-Header + div > .VirtualTable-List')?.getClientRects?.()?.[0]?.height ||
          remToPixels(10)
        : routeContainer.current?.clientHeight;
    const calculatedHeight =
      style?.height ||
      (!!realHeight && Math.min(realHeight + 2, routeContainer.current?.clientHeight)) ||
      routeContainer.current?.clientHeight;
    if (fullHeight !== (tableContentRef.current?.scrollHeight || 0)) setFullHeight(tableContentRef.current?.scrollHeight || 0);
    if (tableHeight !== calculatedHeight) setTableHeight(calculatedHeight);
  }, [dynamic, style?.height, fullHeight, tableHeight]);
  const totalCount = useMemo((): number => {
    const getCount = (rows: any[]): number => {
      let count = 0;
      rows.forEach((row: any): void => {
        if (row?.children?.length) {
          count += getCount(row.children);
        } else {
          count++;
        }
      });
      return count;
    };
    return getCount(data);
  }, [data]);
  const [{ rememberColumns, savedColumns }, setSettings] = useSettings(
    ({
      state: {
        columns: { [name]: { remember: rememberColumns = false, saved: savedColumns = {} } = {} },
      },
      setState,
    }) => [{ rememberColumns, savedColumns }, setState]
  );
  const onChangeVisibility =
    (selector: string): (() => void) =>
    (): void =>
      setSettings((current) => ({
        ...current,
        columns: {
          ...(current?.columns || {}),
          [name]: {
            remember: current?.columns?.[name]?.remember,
            saved: {
              ...current?.columns?.[name]?.saved,
              [selector]: !(current?.columns?.[name]?.saved?.[selector] ?? true),
            },
          },
        },
      }));
  const onChangeRememberColumns = () =>
    setSettings((current) => ({
      ...current,
      columns: {
        ...(current?.columns || {}),
        [name]: {
          remember: !current?.columns?.[name]?.remember,
          saved: !current?.columns?.[name]?.remember === false ? {} : current?.columns?.[name]?.saved || {},
        },
      },
    }));

  const timerRef = useRef(null);
  useEffect((): (() => void) => {
    timerRef.current = setTimeout((): void => {
      handleResize();
      timerRef.current = setTimeout((): void => {
        handleResize();
      }, 500);
    }, 500);
    return (): void => clearTimeout(timerRef.current);
  }, [loading, data, printing, handleResize]);
  useEffect((): void => {
    if (!rememberColumns)
      setSettings((current) => ({
        ...current,
        columns: {
          ...(current?.columns || {}),
          [name]: {
            remember: false,
            saved: {},
          },
        },
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberColumns]);

  useEffect((): void => {
    if (typeof tableHeight === 'number' && typeof fullHeight === 'number') setShowControls(tableHeight < fullHeight);
  }, [fullHeight, tableHeight]);

  useEventListener('visibilitychange', handleResize);
  useEventListener('resize', handleResize);
  useEventListener('beforeprint', (): void => setPrinting(true));
  useEventListener('afterprint', (): void => setPrinting(false));

  /* VirtualTable-Full
    This is the table that renders during printing in order to display every row, instead of virtualizing them.
    Any CSS changes needed to be made to this specifically can be made using the wrapping class `VirtualTable-Full`.
  */
  if (printing)
    return (
      <div className="VirtualTable-Container VirtualTable-Full d-flex flex-column">
        <div className="VirtualTable d-flex flex-column">
          {header && (
            <div className="VirtualTable-Header d-flex flex-column">
              <div className="VirtualTable-List d-flex flex-column">
                <div className="VirtualTable-Item">
                  <div className="VirtualTable-Row">
                    {itemContent(0, header, { rows: data, header, locale, table: name || 'unknownTable' })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-column">
            <div className="VirtualTable-List d-flex flex-column">
              {data.map(
                (row: any, i: number): JSX.Element => (
                  <div className="VirtualTable-Item" key={i}>
                    <div className="VirtualTable-Row">
                      {itemContent(i, row, { rows: data, header, locale, table: name || 'unknownTable', expanded: true }, true)}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div
      className={getClasses(
        'VirtualTable-Container',
        dynamicRowHeight !== undefined ? 'DynamicRows' : '',
        printing ? `{height:${fullHeight}px!;}` : undefined
      )}
      ref={containerRef}
    >
      <LoadingBlur loading={!!loading} label={locale('Loading')} />
      <LoadingBlur loading={!!printing} label={locale('Printing')} />
      {options?.ignoreColumnSettings !== true && (
        <PageSettings group="columns">
          <div className="w-100 d-flex">
            <span className="flex-grow-1">{titleCase(unCamelCase(name))}</span>
            <span>
              <FormField
                name="persistColumns"
                onChange={onChangeRememberColumns}
                checked={!!rememberColumns}
                type="checkbox"
                label="Remember"
                condensed
              />
            </span>
          </div>
          <div className="my-2 p-2 {border:2px|solid|var(--bs-primary);border-radius:var(--bs-border-radius);}">
            {Object.entries(header || {}).map(
              ([key, label]: any): JSX.Element => (
                <FormField
                  name={`${name}.${key || 'unknownColumn'}`}
                  label={typeof label === 'string' ? label : properCase(unCamelCase(key))}
                  type="switch"
                  checked={(savedColumns[key || 'unknownColumn'] ?? true) === true}
                  onChange={onChangeVisibility(key || 'unknownColumn')}
                  inline
                  condensed
                  options={{
                    group: { className: '{display:inline-block!;width:33%!;}' },
                  }}
                  key={`${name}.${key}`}
                />
              )
            )}
          </div>
        </PageSettings>
      )}
      <Virtuoso
        context={{ rows: data, header, locale, table: name || 'unknownTable' }}
        className={getClasses('VirtualTable', virtuosoProps?.className, printing ? `{height:${fullHeight}px!;}` : undefined)}
        data={
          header && data?.length > 0
            ? [{ ...header, _type: 'header' }, ...data.map((row: any): any => ({ ...row, _type: 'row' }))]
            : data.map((row: any): any => ({ ...row, _type: 'row' }))
        }
        topItemCount={header ? 1 : 0}
        style={{
          ...style,
          height: printing ? fullHeight : tableHeight,
        }}
        increaseViewportBy={parseInt(`${printing ? fullHeight : tableHeight}`)}
        totalCount={totalCount}
        itemContent={itemContent}
        endReached={(endIndex: number): void => {
          const record = data[endIndex - 1];
          if (!record || !onLazyLoad || loading) return;
          onLazyLoad(endIndex, record);
        }}
        components={{
          List: forwardRef(RenderListComponent),
          Item: (props: any): JSX.Element => <div id={props?.item?.id} className={getClasses('VirtualTable-Item')} {...props} />,
          TopItemList: (props: any): JSX.Element => <div className={getClasses('VirtualTable-Header')} {...props} />,
          EmptyPlaceholder: (): JSX.Element =>
            loading ? null : (
              <>
                <Middle.Center>
                  <h3 className="text-gray text-center">
                    <i className={`sv ${locale('sv-inbox')} fa-2x`} />
                    <br />
                    {locale('No Records')}
                  </h3>
                </Middle.Center>
              </>
            ),
          ...components,
        }}
        ref={tableRef}
        scrollerRef={(ref: HTMLElement | Window): any => {
          tableContentRef.current = ref;
          if (!!ref && !!totalCount) handleResize();
        }}
      />
      <div className="VirtualTable-Controls">
        {!!data?.length && showControls && (
          <>
            <Tippy content={locale('Scroll to Bottom')}>
              <div>
                <Button
                  className="border-gray"
                  variant="secondary-subtle"
                  onClick={(): void => tableRef.current.scrollToIndex(data?.length || 0)}
                  disabled={!!loading}
                >
                  <i className="fa fa-chevron-down" />
                </Button>
              </div>
            </Tippy>
            <Tippy content={locale('Scroll to Top')}>
              <div>
                <Button
                  className="border-gray"
                  variant="secondary-subtle"
                  onClick={(): void => tableRef.current.scrollToIndex(0)}
                  disabled={!!loading}
                >
                  <i className="fa fa-chevron-up" />
                </Button>
              </div>
            </Tippy>
          </>
        )}
      </div>
    </div>
  );
};
export const DynamicRow = ({ index, data: { _type, ...data } }: { index: any; data: any }): JSX.Element => (
  <VirtualTableRow
    context={{
      rowType: _type,
      data,
      index,
    }}
  >
    {Object.entries(data)
      .filter(([, value]: [string, string | number]): boolean => ['number', 'string'].includes(typeof value))
      .map(
        ([key]: [string, string | number], d: number): JSX.Element => (
          <DynamicCell
            selector={key}
            placeholder="--"
            width={`calc(100% / ${
              Object.entries(data).filter(([, value]: [string, string | number]): boolean => ['number', 'string'].includes(typeof value))
                .length
            })`}
            key={d}
          />
        )
      )}
  </VirtualTableRow>
);
export type RowContextType = {
  rows: any[];
  header: any;
  rowType: 'header' | 'row';
  data: any;
  setRows: React.Dispatch<React.SetStateAction<any>>;
  setPriorityRows: React.Dispatch<React.SetStateAction<any>> /** TODO: Refactor - Dane E. Parchment Jr. */;
  setData: React.Dispatch<React.SetStateAction<any>>;
  index: number;
  expanded: boolean;
  selected: boolean;
  locale: (key: string) => any;
  table: string;
};
export const RowContext: React.Context<RowContextType> = React.createContext({
  rows: [],
  header: undefined,
  rowType: 'row',
  data: undefined,
  setRows: (): void => console.warn('RowContext: setRows not defined'),
  setPriorityRows: (): void => console.warn('RowContext: setRows not defined') /** TODO: Refactor - Dane E. Parchment Jr. */,
  setData: (): void => console.warn('RowContext: setData not defined'),
  index: undefined,
  expanded: false,
  selected: false,
  locale: (key: string): any => key,
  table: 'unknownTable',
});
export const VirtualTableRow = ({ context = {}, ...props }: any): JSX.Element => {
  const [data, setData] = useState(context?.data || {});
  return (
    <RowContext.Provider value={{ ...context, data, setData }}>
      <div
        {...props}
        onDoubleClick={context.rowType !== 'header' ? props?.onDoubleClick : undefined}
        className={getClasses('VirtualTable-Row', 'd-flex', props?.className)}
      ></div>
    </RowContext.Provider>
  );
};

export type DynamicCellProps = {
  render?: (props: { value: unknown; data: unknown }) => unknown;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  value?: unknown;
  sorting?: {
    onSort?: (key: string) => void;
    direction?: 'asc' | 'desc';
  };
  loading?: boolean;
  placeholder?: string;
  selector?: string;
  name?: string;
  className?: string;
  options?: {
    showTooltip?: boolean;
  };
  [x: string]: unknown;
};
export const DynamicCell = ({
  render: RenderCell = ({ value }: { value: any }): any => value,
  width,
  minWidth,
  maxWidth,
  value: initialValue = null,
  sorting: { onSort = undefined, direction: sortDirection = undefined } = {},
  options = {
    showTooltip: true,
  },
  ...props
}: any): JSX.Element => {
  const { rowType, data, index, locale, table } = useContext(RowContext);
  const placeholder = props?.placeholder || '--';
  const selected = initialValue || getProperty(props?.selector || 'unknownColumn', data || {}, placeholder);
  const value = (typeof selected === 'string' || typeof selected === 'number' ? `${selected}`.trim() : selected) || placeholder;
  const { savedColumns } = useSettings(
    ({
      state: {
        columns: { [table]: { saved: savedColumns = {} } = {} },
      },
    }) => ({ savedColumns })
  );
  const loading = props?.loading ?? false;
  const visible = savedColumns[props?.name || (props?.selector || 'unknownColumn').split('.')[0]] ?? true;
  const innerCellRef = useRef();
  const [needsTooltip, setNeedsTooltip] = useState(false);

  // sorting vars
  const direction = sortDirection?.toLowerCase?.();

  const handleClick = (): (() => void | undefined) => {
    return rowType === 'header' && !!onSort
      ? (): void => onSort(direction === undefined ? 'asc' : direction === 'asc' ? 'desc' : undefined)
      : undefined;
  };

  useEffect(() => {
    if (!innerCellRef.current) return;
    setNeedsTooltip(
      options?.showTooltip !== false &&
        innerCellRef.current &&
        innerCellRef.current?.['scrollWidth'] > innerCellRef.current?.['offsetWidth']
    );
  }, [value, data, options?.showTooltip]);

  if (!visible) return null;
  return (
    <div
      {...props}
      className={getClasses('VirtualTable-Cell', rowType === 'header' ? 'VirtualTable-HeaderCell' : undefined, props?.className)}
      style={{
        ...(props?.style || {}),
        minWidth: minWidth || 'auto',
        maxWidth: maxWidth || 'auto',
        width: width || 'auto',
      }}
    >
      {rowType !== 'header' && <LoadingBlur size="sm" loading={loading} />}
      <TippyWhen
        isTrue={(rowType === 'header' && !!onSort && direction !== undefined) || needsTooltip}
        options={{
          content:
            rowType === 'header' ? (
              direction === 'asc' ? (
                locale('Ascending')
              ) : (
                locale('Descending')
              )
            ) : (
              <RenderCell value={value} data={data} index={index} />
            ),
          delay: needsTooltip ? 250 : 1000,
          interactive: needsTooltip,
        }}
      >
        {!loading && (
          <div
            style={{
              cursor: rowType === 'header' && !!onSort ? 'pointer' : undefined,
              whiteSpace: rowType === 'header' ? 'nowrap' : undefined,
              fontStyle: rowType === 'header' && direction !== undefined ? 'italic' : undefined,
            }}
            onClick={handleClick()}
            onKeyDown={handleClick()}
            ref={innerCellRef}
            role="button"
            tabIndex={0}
          >
            {rowType === 'header' ? value : <RenderCell value={value} data={data} index={index} />}
            {rowType === 'header' && !!onSort && (
              <i className={`ms-2 fa fa-sort${direction === 'asc' ? '-up' : direction === 'desc' ? '-down' : ' opacity-25'}`} />
            )}
          </div>
        )}
      </TippyWhen>
    </div>
  );
};
export const FormatCell = ({ format = (value: string): string => value, ...props }: any): JSX.Element => (
  <DynamicCell
    {...props}
    render={({ value, data }: { value: any; data: any }): any => `${format(value, data)}`.trim() || props?.placeholder || '--'}
  />
);
export const ExpandCell = (props: any): JSX.Element => {
  const { index, data, expanded, locale, rowType, rows } = useContext(RowContext);
  const tooltip = `${!expanded ? locale('Expand') : locale('Collapse')}${rowType === 'header' ? ' All' : ''}`;
  return (
    <Tippy content={tooltip} delay={[1000, 500]} placement="right">
      <div className={getClasses('VirtualTable-Expand', props?.className)}>
        <div className={getClasses('text-center')}>
          {!!(data?.children || []).length && rowType !== 'header' ? (
            <Form.Check.Input checked={expanded} onChange={(): void => props?.onClick(data?.id || index)} name="expandRow" />
          ) : rowType === 'header' ? (
            <Form.Check.Input
              checked={expanded}
              onChange={(): void => props?.onClick((rows || []).map((row: any, r: number): string => row?.id || r))}
              name="expandRow"
            />
          ) : null}
        </div>
      </div>
    </Tippy>
  );
};
export const SelectCell = (props: any): JSX.Element => {
  const { rowType, data, selected, rows, locale } = useContext(RowContext);
  return (
    <Tippy
      content={`${!selected ? locale('Select') : locale('Deselect')}${rowType === 'header' ? ' All' : ''}`}
      delay={[1000, 500]}
      placement="right"
    >
      <div className={getClasses('VirtualTable-Select', props?.className)}>
        <div className={getClasses('text-center')}>
          {rowType === 'header' ? (
            <Form.Check.Input
              disabled={!!props?.disabled}
              checked={selected}
              onChange={(): void =>
                props?.onClick(
                  (rows || []).filter((row: any): boolean => row?.id !== undefined).map((row: any): string => row?.id),
                  rows
                )
              }
              name="selectRow"
            />
          ) : data?.id !== undefined ? (
            <Form.Check.Input
              disabled={!!props?.disabled}
              checked={selected}
              onChange={(): void => props?.onClick(data?.id, data)}
              name="selectRow"
            />
          ) : null}
        </div>
      </div>
    </Tippy>
  );
};

const RenderListComponent = (props: any, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => (
  <div className={getClasses('VirtualTable-List', 'd-flex flex-column')} {...props} ref={ref} />
);

export default forwardRef(VirtualTable);
export { useVirtualTable };

import { GetAirportGroupPermissionsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getAirportGroupPermissions = createGraphApiMockResponse(GetAirportGroupPermissionsDocument, {
  getFilters: {
    filters: {
      airportGroupPermissions: [
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'Dallas',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac', '82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'Los Angeles',
          values: [
            '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
            '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
            '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
            '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
            '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          ],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'New York',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac', '82f546a4-f649-4b3f-9f6e-5e1cb78560ac', '82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'Seattle',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SFO Area',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac', '82f546a4-f649-4b3f-9f6e-5e1cb78560ac', '82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'South Florida',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac', '82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'ATL',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'AUS',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'BNA',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'BUR',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'CLT',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'DAL',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'DFW',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'EWR',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'FLL',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'IAH',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'IND',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'JAX',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'JFK',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'LAS',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'LAX',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'LGA',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'LGB',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'MCO',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'MIA',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'MSP',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'OAK',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'ONT',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'PBI',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'PDX',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'PHL',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'RSW',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SAN',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SEA',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SFO',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SJC',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SLC',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'SNA',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
        {
          id: '82f546a4-f649-4b3f-9f6e-5e1cb78560ac',
          displayName: 'TPA',
          values: ['82f546a4-f649-4b3f-9f6e-5e1cb78560ac'],
          __typename: 'FilterOption',
        },
      ],
    },
  },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage {
  background-image: url("../../../public/assets/images/LoginPage_Globe.jpg");
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.LoginPage .Login {
  max-width: 500px;
  position: relative;
  top: 23%;
  left: 15%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/styles.scss"],"names":[],"mappings":"AAAA;EACE,0EAAA;EACA,2BAAA;EACA,sBAAA;EACA,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AACJ","sourcesContent":[".LoginPage {\n  background-image: url(\"../../../public/assets/images/LoginPage_Globe.jpg\");\n  background-position: center;\n  background-size: cover;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n.LoginPage .Login {\n  max-width: 500px;\n  position: relative;\n  top: 23%;\n  left: 15%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

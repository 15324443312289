import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError } from '@/utils/custom';

import { DeletePunchPolicyBulkDocument } from '@/api/queries';
import { DeletePunchPolicyInput } from '@/models/gen/graphql';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Delete Punch Policy' } });

// Service Function Signature
type ServiceMethodPayload = string[];
interface ServiceMethod {
  (...payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// DeletePunchPolicyBulk Service
// carmstrong @ 5/1/2024
export const { deletePunchPolicyBulk } = ((): {
  deletePunchPolicyBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(DeletePunchPolicyBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof DeletePunchPolicyBulkDocument>): ServiceMethod =>
      async (...ids: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const search: DeletePunchPolicyInput[] = ids.map((id: string): DeletePunchPolicyInput => ({ id }));
        const res = await fn({ input: { search } });
        return res;
      }
  );

  return {
    deletePunchPolicyBulk: mutate,
  };
})();

import { DeleteVehicleBulkInput, DeleteVehicleBulkResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { DeleteVehicleBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type DeleteVehicleBulkGraphApiResponse = GraphApiResponse<typeof DeleteVehicleBulkDocument>;

const title = 'Delete Vehicles';

const deleteVehicleBulkResponseSelector = (res: DeleteVehicleBulkGraphApiResponse): DeleteVehicleBulkResponse =>
  !res.errors ? res?.deleteVehicleBulk : undefined;

const [runDeleteVehicleBulk] = graphApi(DeleteVehicleBulkDocument, {
  onError: (res: DeleteVehicleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const deleteVehicleBulk = async (ids: string[]): Promise<DeleteVehicleBulkResponse> => {
  const input: DeleteVehicleBulkInput = {
    search: ids.map((id: string): { id: string } => ({ id })),
  };
  const res = await runDeleteVehicleBulk({ input });

  const selected = deleteVehicleBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useDeleteVehicleBulk = createGraphApiHook(deleteVehicleBulk);

export default deleteVehicleBulk;

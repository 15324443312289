import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import React, { ReactNode } from 'react';

import { createComponentQueryState } from '@/state';

const useFilterState = createComponentQueryState({ test: '123', test2: '456' });

const FiltersSandbox = (): ReactNode => {
  const [state, setState, saveState] = useFilterState(({ state, setState, saveState }) => [state, setState, saveState]);
  const { test = '', test2 = '' } = state;

  const onChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <Container className="p-2" fluid>
      <Row>
        <Col>
          <InputGroup>
            <Form.Control onChange={onChange('test')} value={test} />
            <Form.Control onChange={onChange('test2')} value={test2} />
            <Button variant="primary" onClick={saveState}>
              Set
            </Button>
          </InputGroup>
        </Col>
        <Col>
          <pre>{JSON.stringify(state, null, 2)}</pre>
        </Col>
      </Row>
    </Container>
  );
};
export default FiltersSandbox;

import { UpdateUserBulkResponse, UserInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';
import { generateUpdateBulkPayload, validateObj } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import Toast from '@/models/Toast';
import { UpdateUserBulkDocument } from '@/api/queries';
import graphApi from '@/api';

type UpdateUserBulkGraphApiResponse = GraphApiResponse<typeof UpdateUserBulkDocument>;

const title = 'Update User Bulk';

const validateUser = validateObj({
  username: (val: any) => val || null,
  avatar: (val: any) => val || null,
  roleId: (val: any) => val || null,
  firstName: (val: any) => val || null,
  lastName: (val: any) => val || null,
  email: (val: any) => val || null,
  phone: (val: any) => val || null,
  countryCode: (val: any) => val || null,
  address: (val: any) => val || null,
  timezone: (val: any) => val || null,
  employeeId: (val: any) => val || null,
  dob: (val: any) => val || null,
  active: (val: any) => val || null,
  companyId: (val: any) => val || null,
  providerId: (val: any) => val || null,
  cityName: (val: any) => val || null,
  stateCode: (val: any) => val || null,
  zipCode: (val: any) => val || null,
  startDate: (val: any) => val || null,
  notes: (val: any) => val || null,
  longTrip: (val: any) => val || null,
});

const updateUserBulkResponseSelector = (res: UpdateUserBulkGraphApiResponse): UpdateUserBulkResponse['response'] =>
  res?.updateUserBulk?.response;

const [runUpdateUserBulk] = graphApi(UpdateUserBulkDocument, {
  onError: (res: UpdateUserBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const updateUserBulk = async (...users: Partial<UserInput>[]): Promise<UpdateUserBulkResponse['response']> => {
  const updates = generateUpdateBulkPayload(users);

  const res = await runUpdateUserBulk({ input: { updates } });
  const data = updateUserBulkResponseSelector(res);

  if (data) createNotification('Success', Toast.Type.SUCCESS, title);

  return data;
};

export default updateUserBulk;
export { validateUser };

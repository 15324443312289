import FormField, { FormFieldProps } from 'components/FormField';

import React from 'react';
import SelectFromEnum from 'components/SelectFromEnum';
import { properCase } from 'utils';

export enum PolicyTypeEnum {
  punchPolicies = 'PUNCH_POLICIES',
  specialEvents = 'SPECIAL_EVENTS',
}
const SelectPolicyType = (props: FormFieldProps): JSX.Element => {
  return (
    <FormField
      name="policyType"
      placeholder="Policy Type"
      condensed
      {...props}
      options={{
        input: {
          as: (props: any): JSX.Element => (
            <SelectFromEnum
              {...props}
              value={props.value || PolicyTypeEnum.punchPolicies}
              getLabel={(_key: string, value: string): string => properCase(value.replace(/_/g, ' '))}
              source={PolicyTypeEnum}
            />
          ),
        },
      }}
    />
  );
};

export default SelectPolicyType;

import { DeleteCommunicationBulkInput, DeleteCommunicationInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { DeleteCommunicationBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type DeleteCommunicationBulkGraphApiResponse = GraphApiResponse<typeof DeleteCommunicationBulkDocument>;

const title = 'Remove Communications';

const deleteCommunicationBulkResponseSelector = (res: DeleteCommunicationBulkGraphApiResponse): Number =>
  !res.errors ? res?.deleteCommunicationBulk?.deleted || 0 : undefined;

const [runDeleteCommunicationBulk] = graphApi(DeleteCommunicationBulkDocument, {
  onError: (res: DeleteCommunicationBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteCommunicationBulk = async (ids: string[]): Promise<Number> => {
  const input: DeleteCommunicationBulkInput = {
    search: ids.map((id: string): DeleteCommunicationInput => ({ id })),
  };
  const res = await runDeleteCommunicationBulk({ input });
  const selected = deleteCommunicationBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteCommunicationBulk = createGraphApiHook(deleteCommunicationBulk);

export default deleteCommunicationBulk;

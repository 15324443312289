import React, { ReactNode } from 'react';

export type GroupHeaderProps = { icon?: ReactNode; title: string; controls?: ReactNode };
const GroupHeader = ({ icon, title, controls }: GroupHeaderProps) => {
  return (
    <div className="SettingsPageGroup-Header">
      <div className="SettingsPageGroup-Header-Title">
        {icon}
        {title}
      </div>
      {controls && <div className="SettingsPageGroup-Header-Controls">{controls}</div>}
    </div>
  );
};
export default GroupHeader;

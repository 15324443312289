import { GetFlagsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getFlags = createGraphApiMockResponse(GetFlagsDocument, {
  getFilters: {
    filters: {
      flag: [
        {
          id: '35c9cec4-e9d2-4c4b-be44-178fb0ee1059',
          displayName: 'VIP',
          __typename: 'FilterOption',
        },
        {
          id: '35c9cec4-e9d2-4c4b-be44-178fb0ee1059',
          displayName: 'Express',
          __typename: 'FilterOption',
        },
        {
          id: '35c9cec4-e9d2-4c4b-be44-178fb0ee1059',
          displayName: 'Recovery',
          __typename: 'FilterOption',
        },
        {
          id: '35c9cec4-e9d2-4c4b-be44-178fb0ee1059',
          displayName: 'Training Trip',
          __typename: 'FilterOption',
        },
        {
          id: '35c9cec4-e9d2-4c4b-be44-178fb0ee1059',
          displayName: 'Planned Taxi',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

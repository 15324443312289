import CoordinateDisplay from '../CoordinateDisplay';
import React from 'react';
import useClassNames from '../../hooks/useClassNames';

type Props = {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  lat?: number;
  lng?: number;
  className?: string;
};
const AddressDisplay = ({ address, city, state, zip, lat, lng, ...props }: Props): React.JSX.Element => {
  const classes = useClassNames('AddressDisplay', props?.className);
  return (
    <address
      {...props}
      className={classes}
      style={{
        minHeight: '4.5rem',
      }}
    >
      <p className="m-0">{address}</p>
      <p className="m-0 fw-bold">
        {city && <span>{city}, </span>}
        {state} {zip}
      </p>
      <p className="m-0">
        <CoordinateDisplay lat={lat} lng={lng} />
      </p>
    </address>
  );
};

export default AddressDisplay;

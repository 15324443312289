import { Link, useLocation } from 'react-router-dom';

import { Badge } from 'react-bootstrap';
import React from 'react';
import { Route } from '../MainMenu';
import Tippy from '@tippyjs/react';
import { getClasses } from '../../utils/strings';
import { useAppState } from '../../store/appReducer';
import { useGetAvailabilityRequestCount } from '../../api/services/users/searchUsers';
import useInterval from '../../hooks/useInterval';

type ShortcutProps = {
  className?: string;
  children?: React.ReactNode;
} & Route;

const Shortcut = ({ url, search, label, icon, className, children }: ShortcutProps): JSX.Element => {
  const location = useLocation();

  return (
    <Tippy content={label}>
      <span className={getClasses('Shortcut', className)}>
        <Link
          tabIndex={-1}
          to={`${url}${search || ''}`}
          reloadDocument={!!url?.startsWith?.(window.location.pathname)}
          className={`${location.pathname.includes(url) ? 'active' : ''}`}
        >
          {icon}
          {children}
        </Link>
      </span>
    </Tippy>
  );
};
const DEFAULT_REQUEST_AVAILABILITY_INTERVAL_SECONDS = 300; // 5 minutes
Shortcut.Availability = (props: Route): React.JSX.Element => {
  const [{ config = {} }] = useAppState();
  const { requestAvailabilityIntervalSeconds } = config;
  const [{ data }, { fetch: getAvailabilityRequestCount }] = useGetAvailabilityRequestCount();

  const delay = convertSecondsToMilliseconds(requestAvailabilityIntervalSeconds || DEFAULT_REQUEST_AVAILABILITY_INTERVAL_SECONDS);
  useInterval(getAvailabilityRequestCount, delay, true);

  return <Shortcut {...props}>{!!data && <Badge bg="purple">{data}</Badge>}</Shortcut>;
};

const convertSecondsToMilliseconds = (seconds: number): number => (seconds || 0) * 1000;

export default Shortcut;

import { GetAirportGroupsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getAirportGroups = createGraphApiMockResponse(GetAirportGroupsDocument, {
  getFilters: {
    filters: {
      airportGroups: [
        {
          id: '45e92708-b4f0-4464-9bff-e0e14efeeec1',
          displayName: 'New York',
          values: ['EWR', 'JFK', 'LGA'],
          __typename: 'FilterOption',
        },
        {
          id: '45e92708-b4f0-4464-9bff-e0e14efeeec1',
          displayName: 'South Florida',
          values: ['FLL', 'MIA'],
          __typename: 'FilterOption',
        },
        {
          id: '45e92708-b4f0-4464-9bff-e0e14efeeec1',
          displayName: 'Dallas',
          values: ['DAL', 'DFW'],
          __typename: 'FilterOption',
        },
        {
          id: '45e92708-b4f0-4464-9bff-e0e14efeeec1',
          displayName: 'Los Angeles',
          values: ['BUR', 'LAX', 'LGB', 'ONT', 'SNA'],
          __typename: 'FilterOption',
        },
        {
          id: '45e92708-b4f0-4464-9bff-e0e14efeeec1',
          displayName: 'Seattle',
          values: ['BFI', 'SEA'],
          __typename: 'FilterOption',
        },
        {
          id: '45e92708-b4f0-4464-9bff-e0e14efeeec1',
          displayName: 'SFO Area',
          values: ['OAK', 'SFO', 'SJC'],
          __typename: 'FilterOption',
        },
        {
          id: 'ATL',
          displayName: 'ATL',
          values: ['ATL'],
          __typename: 'FilterOption',
        },
        {
          id: 'AUS',
          displayName: 'AUS',
          values: ['AUS'],
          __typename: 'FilterOption',
        },
        {
          id: 'BFI',
          displayName: 'BFI',
          values: ['BFI'],
          __typename: 'FilterOption',
        },
        {
          id: 'BNA',
          displayName: 'BNA',
          values: ['BNA'],
          __typename: 'FilterOption',
        },
        {
          id: 'BUR',
          displayName: 'BUR',
          values: ['BUR'],
          __typename: 'FilterOption',
        },
        {
          id: 'CLT',
          displayName: 'CLT',
          values: ['CLT'],
          __typename: 'FilterOption',
        },
        {
          id: 'DAL',
          displayName: 'DAL',
          values: ['DAL'],
          __typename: 'FilterOption',
        },
        {
          id: 'DFW',
          displayName: 'DFW',
          values: ['DFW'],
          __typename: 'FilterOption',
        },
        {
          id: 'EWR',
          displayName: 'EWR',
          values: ['EWR'],
          __typename: 'FilterOption',
        },
        {
          id: 'FLL',
          displayName: 'FLL',
          values: ['FLL'],
          __typename: 'FilterOption',
        },
        {
          id: 'IAH',
          displayName: 'IAH',
          values: ['IAH'],
          __typename: 'FilterOption',
        },
        {
          id: 'IND',
          displayName: 'IND',
          values: ['IND'],
          __typename: 'FilterOption',
        },
        {
          id: 'JAX',
          displayName: 'JAX',
          values: ['JAX'],
          __typename: 'FilterOption',
        },
        {
          id: 'JFK',
          displayName: 'JFK',
          values: ['JFK'],
          __typename: 'FilterOption',
        },
        {
          id: 'LAS',
          displayName: 'LAS',
          values: ['LAS'],
          __typename: 'FilterOption',
        },
        {
          id: 'LAX',
          displayName: 'LAX',
          values: ['LAX'],
          __typename: 'FilterOption',
        },
        {
          id: 'LGA',
          displayName: 'LGA',
          values: ['LGA'],
          __typename: 'FilterOption',
        },
        {
          id: 'LGB',
          displayName: 'LGB',
          values: ['LGB'],
          __typename: 'FilterOption',
        },
        {
          id: 'MCO',
          displayName: 'MCO',
          values: ['MCO'],
          __typename: 'FilterOption',
        },
        {
          id: 'MIA',
          displayName: 'MIA',
          values: ['MIA'],
          __typename: 'FilterOption',
        },
        {
          id: 'MSP',
          displayName: 'MSP',
          values: ['MSP'],
          __typename: 'FilterOption',
        },
        {
          id: 'OAK',
          displayName: 'OAK',
          values: ['OAK'],
          __typename: 'FilterOption',
        },
        {
          id: 'ONT',
          displayName: 'ONT',
          values: ['ONT'],
          __typename: 'FilterOption',
        },
        {
          id: 'PBI',
          displayName: 'PBI',
          values: ['PBI'],
          __typename: 'FilterOption',
        },
        {
          id: 'PDX',
          displayName: 'PDX',
          values: ['PDX'],
          __typename: 'FilterOption',
        },
        {
          id: 'PHL',
          displayName: 'PHL',
          values: ['PHL'],
          __typename: 'FilterOption',
        },
        {
          id: 'RSW',
          displayName: 'RSW',
          values: ['RSW'],
          __typename: 'FilterOption',
        },
        {
          id: 'SAN',
          displayName: 'SAN',
          values: ['SAN'],
          __typename: 'FilterOption',
        },
        {
          id: 'SEA',
          displayName: 'SEA',
          values: ['SEA'],
          __typename: 'FilterOption',
        },
        {
          id: 'SFO',
          displayName: 'SFO',
          values: ['SFO'],
          __typename: 'FilterOption',
        },
        {
          id: 'SJC',
          displayName: 'SJC',
          values: ['SJC'],
          __typename: 'FilterOption',
        },
        {
          id: 'SLC',
          displayName: 'SLC',
          values: ['SLC'],
          __typename: 'FilterOption',
        },
        {
          id: 'SNA',
          displayName: 'SNA',
          values: ['SNA'],
          __typename: 'FilterOption',
        },
        {
          id: 'TPA',
          displayName: 'TPA',
          values: ['TPA'],
          __typename: 'FilterOption',
        },
      ],
    },
  },
});

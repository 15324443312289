import { OverflowCell } from '@/components/VirtualTable/TemplateCell/tables/drivetimeReportTable';
import React from 'react';
import { TemplatedCell } from '..';

export const CompletedCell = ({ value, row }: { value: string; row: any }): JSX.Element => {
  return (
    <>
      <div className="d-flex flex-column">
        <strong>{value}</strong>
        <small className="text-muted">{row.values?.completion}</small>
      </div>
    </>
  );
};

export default (key: string): TemplatedCell => {
  switch (key) {
    // Default Cells
    case 'group':
    case 'type':
    case 'act':
    case 'scheduled':
    case 'rfp':
    case 'puActual':
    case 'city':
    case 'airline':
    case 'vehicleId':
    case 'flightNumber':
      return { props: { className: 'border-0 border-bottom text-start', maxWidth: '10rem' } };
    // Large Cell
    case 'puLocation':
    case 'doLocation':
      return { cell: OverflowCell, props: { className: 'border-0 border-bottom text-start', minWidth: '10rem' } };
    // Hidden Cells
    case 'completion':
      return { props: { className: 'd-none' } };
    // Custom Cells
    case 'completed':
      return { cell: CompletedCell, props: { className: 'border-0 border-bottom text-start', maxWidth: '15rem' } };
    default:
      return {};
  }
};

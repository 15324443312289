import { Container } from 'react-bootstrap';
import DynamicReport from '../Reports/DynamicReport';
import React from 'react';
import { RouteProps } from 'react-router-dom';

const Ratings = (_props: RouteProps) => {
  return (
    <Container className="page-container w-100" fluid>
      <DynamicReport report="ratingsTable" />
    </Container>
  );
};
export default Ratings;

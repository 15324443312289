import { useEffect } from 'react';
import useEventListener from './useEventListener';

/* useEvent
  Designed to allow communication between components without prop-drilling callbacks.
  This hook uses the CustomEvents API to fire/listen to custom events that are emitted
  as functions which accept any payload, and automatically provide that payload to
  any other components that are listening to the specific event type.

  Callbacks used can be async, or just pure functions. It really depends on the use-case.

  The event names are automatically prepended with "event::" to avoid conflicts with
  existing built-in events.

  By only providing an event name to this hook, you are able to fire the event, without
  listening to it. Again, this use depends on the situation.

  Examples:
    // Listen to the event, and react to it:
    useEvent('test', (res: any): void => setState(res));

    // Listen AND be able to fire the event:
    const fireTest = useEvent('test', (res: any): void => setState(res));

    // Only be able to fire the event:
    const fireTest = useEvent('test');
*/
type UseEventOptions = {
  target?: any;
  prefix?: string;
};

const useEvent = (type: string, callback?: any, options: UseEventOptions = {}): any => {
  // if (callback?.constructor?.name === (async (): Promise<void> => {}).constructor.name) return useEventPromise(type, callback, options);
  const fireEvent = (detail: any, eventOptions: any = {}): boolean => triggerEvent(type, detail, eventOptions);
  const onEvent = callback
    ? (event: any): void => {
        try {
          callback(event?.detail, event);
        } catch (err) {
          console.error(err?.message || err);
        }
      }
    : undefined;
  useEventListener(`${options?.prefix || 'event'}::${type}`, onEvent, options?.target);
  return fireEvent;
};

/* triggerEvent
  This is just being exported separately so that we can trigger events from outside of
  components as well.
*/
const triggerEvent = (type: string, detail?: any, options: UseEventOptions = {}): boolean =>
  (options?.target || window).dispatchEvent(new CustomEvent(`${options?.prefix || 'event'}::${type}`, { ...options, detail }));

const useEventPromise = (type: string, callback?: any, options: UseEventOptions = {}): any => {
  const fireEvent = (detail: any, eventOptions: any = {}): Promise<void> => {
    return new Promise((resolve: any, reject: any): void => {
      const handleResponse =
        (handler: any): any =>
        (event: any): any => {
          handler(event?.detail);
          (options?.target || window).removeEventListener(`${options?.prefix || 'event'}::${type}-response`, handleResponse(resolve));
          (options?.target || window).removeEventListener(`${options?.prefix || 'event'}::${type}-error`, handleResponse(reject));
        };
      (options?.target || window).addEventListener(`${options?.prefix || 'event'}::${type}-response`, handleResponse(resolve));
      (options?.target || window).addEventListener(`${options?.prefix || 'event'}::${type}-error`, handleResponse(reject));
      triggerEvent(type, detail, eventOptions);
    });
  };

  useEffect((): any => {
    if (!callback) return;
    const handleEvent = async (event: any): Promise<void> => {
      try {
        const result = await callback(event?.detail, event);
        triggerEvent(`${options?.prefix || 'event'}::${type}-response`, result);
      } catch (err) {
        triggerEvent(`${options?.prefix || 'event'}::${type}-error`, err);
      }
    };
    (options?.target || window).addEventListener(`${options?.prefix || 'event'}::${type}`, handleEvent);
    return (): void => {
      (options?.target || window).removeEventListener(`${options?.prefix || 'event'}::${type}`, handleEvent);
      (options?.target || window).removeEventListener(`${options?.prefix || 'event'}::${type}-response`, handleEvent);
      (options?.target || window).removeEventListener(`${options?.prefix || 'event'}::${type}-error`, handleEvent);
    };
  }, []);

  return fireEvent;
};

const useEventReducer = (name: string): any => {
  const reducer = useEvent(name);
  return (type: string, payload?: any): void => reducer({ type, payload });
};

export default useEvent;
export { triggerEvent, useEventPromise, useEventReducer };

export const onKey =
  (key: string): ((handler: (...args: unknown[]) => void) => (event: React.KeyboardEvent) => void) =>
  (handler: (...args: unknown[]) => void): ((event: React.KeyboardEvent) => void) =>
  (event: React.KeyboardEvent): void => {
    if (event.key === key) handler(event);
  };
export const onEnter = onKey('Enter');
export const onTab = onKey('Tab');
export const onKeyAction =
  (actions: { [eventKey: React.KeyboardEvent['key']]: (event: React.KeyboardEvent) => void }): ((event: React.KeyboardEvent) => void) =>
  (event: React.KeyboardEvent): void => {
    if (typeof actions[event.key] === 'function') actions[event.key](event);
  };

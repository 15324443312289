import {
  ImportStatusEnum,
  UpdateImportStatusInput,
  UpdateImportsStatusInput,
  UpdateImportsStatusResponse,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdateImportsStatusDocument } from '../../queries';
import graphApi from '../..';

type UpdateImportsStatusGraphApiResponse = GraphApiResponse<typeof UpdateImportsStatusDocument>;

const title = 'Update Imports Status';

const updateImportsStatusResponseSelector = (res: UpdateImportsStatusGraphApiResponse): UpdateImportsStatusResponse =>
  !res.errors ? res?.updateImportsStatus : undefined;

export const [runUpdateImportsStatus] = graphApi(UpdateImportsStatusDocument, {
  onError: (res: UpdateImportsStatusGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const updateImportsStatus = async (importIds: string[], status: ImportStatusEnum): Promise<UpdateImportsStatusResponse> => {
  const imports: UpdateImportStatusInput[] = importIds.map((id: string): UpdateImportStatusInput => ({ id, status }));
  const input: UpdateImportsStatusInput = {
    imports,
  };

  const res = await runUpdateImportsStatus({ input });
  const selected = updateImportsStatusResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateImportsStatus;

import './styles.scss';

import { DATETIME_FE_FORMAT, MONTH_END, MONTH_START } from '@/constants';
import { Datetime, Validation, onEnter, printScreen, queryInput } from '@/utils';
import React, { useMemo, useRef, useState } from 'react';
import VirtualTable, { DynamicCell, ExpandCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import Filters from '@/components/Filters';
import FormField from '@/components/FormField';
import { RouteProps } from 'react-router-dom';
import SelectAirlineIata from '@/components/SelectAirlineIata';
import SelectAirportGroup from '@/components/SelectAirportGroup';
import SelectClient from '@/components/SelectClient';
import SelectFcrReason from '@/components/SelectFcrReason';
import SidebarButton from '@/components/SidebarButton';
import { TripWithFcrInnerJoinSearch } from '@/models/gen/graphql';
import { getClasses } from '@/utils/strings';
import { stringify } from '@/utils/objects';
import { useSearchTripsWithFcr } from '@/api/services/fcrs/searchTripsWithFcr';

type FcrFilters = {
  from: string;
  to: string;
  providerId: string[];
  airport: string[];
  airline: string[];
  reason: string[];
  flightNumber: string;
};
const initFcrFilters: FcrFilters = {
  from: MONTH_START,
  to: MONTH_END,
  providerId: [],
  airport: [],
  airline: [],
  reason: [],
  flightNumber: '',
};
const Fcrs = (_props: RouteProps) => {
  const [{ data, loading }, { fetch: searchTripsWithFcr, refetch }] = useSearchTripsWithFcr();
  const { rows = [] } = data || {};

  const [state, setState] = useState({
    expanded: [],
  });
  const { expanded } = state;

  const { onExpand, filteredRows } = useVirtualTable(setState, {
    expanded,
    rows,
  });

  const lastQuery = useRef(null);

  const onSubmit = async (filters: FcrFilters): Promise<void> => {
    const { from, to, providerId, airport, airline, reason, flightNumber } = filters || {};
    const query: TripWithFcrInnerJoinSearch = {
      scheduledUtc: queryInput.date([from, to]),
      providerId: providerId?.length ? queryInput(providerId) : null,
      airportCode: airport?.length ? queryInput(airport) : null,
      servicerIataAirlineCode: airline?.length ? queryInput(airline) : null,
      fcrReason: reason?.length ? queryInput(reason) : null,
      flightNumber: flightNumber ? queryInput(flightNumber) : null,
    };
    const fn = stringify.compare(query, lastQuery.current) ? refetch : searchTripsWithFcr;
    await fn([query]);
    lastQuery.current = query;
  };

  const mappedRows = useMemo(() => {
    return (filteredRows || []).map((row) => ({
      ...row,
      children: [
        { child: true, scheduled: 'COMPLAINT DESCRIPTION', airportCode: row?.fcrDescription },
        { child: true, scheduled: 'ROOT CAUSE', airportCode: row?.fcrCause },
      ],
    }));
  }, [filteredRows]);

  return (
    <div className="Fcrs page-container">
      <Filters
        name="fcrs"
        value={initFcrFilters}
        onSubmit={onSubmit}
        primary={({ values, onChange }) => {
          const { from, to, providerId, airport, airline, reason, flightNumber } = values;
          return (
            <>
              <FormField name="dates" type="daterange" value={[from, to]} onChange={onChange.dateRange('from', 'to')} condensed />
              <FormField
                name="providerId"
                onChange={onChange}
                value={providerId}
                placeholder="Client"
                searchable
                options={{ input: { as: SelectClient } }}
                condensed
              />
              <FormField
                name="airport"
                onChange={onChange}
                value={airport}
                placeholder="Airport"
                multiple
                searchable
                options={{ input: { as: SelectAirportGroup } }}
                condensed
              />
              <FormField
                name="airline"
                onChange={onChange}
                value={airline}
                placeholder="Airline"
                multiple
                searchable
                options={{ input: { as: SelectAirlineIata } }}
                condensed
              />
              <FormField
                name="reason"
                onChange={onChange}
                value={reason}
                placeholder="Reason"
                multiple
                searchable
                options={{ input: { as: SelectFcrReason } }}
                condensed
              />
              <FormField
                name="flightNumber"
                placeholder="Flight"
                value={Validation.isNumber(flightNumber) ? flightNumber : initFcrFilters?.flightNumber}
                onChange={onChange.int}
                onKeyDown={onEnter((): Promise<void> => onSubmit(values || initFcrFilters))}
                maxLength={4}
                condensed
              />
            </>
          );
        }}
        submitOnMount
      />
      <VirtualTable
        name="FcrTable"
        className="condensed"
        loading={loading}
        data={mappedRows}
        expanded={expanded}
        header={{
          scheduled: 'Trip Date',
          airportCode: 'Airport',
          provider: { displayName: 'Client' },
          flightNumber: 'Flight Number',
          fcrReason: 'Reason',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              expanded: expanded.includes(data?.id || index),
            }}
            className={getClasses(
              expanded.includes(data?.id || index) ? 'expanded' : '',
              `${data?.id || index}`.includes('_') ? 'nested' : '',
              !data?.children?.length ? 'no-children' : ''
            )}
            onDoubleClick={(data?.children || []).length ? (): void => onExpand(data?.id || index) : undefined}
          >
            <ExpandCell className="alternate" onClick={onExpand} />
            <DynamicCell
              selector="scheduled"
              placeholder="--"
              className="text-center"
              width="calc(100% / 5)"
              render={({ value, data }: { value: string; data: any }): string =>
                data?.child ? value : new Datetime(value).format(DATETIME_FE_FORMAT)
              }
            />
            <DynamicCell
              selector="airportCode"
              placeholder={data?.child ? ' ' : '--'}
              className={`${data?.child ? 'text-start' : 'text-center'} alternate`}
              width={data?.child ? `calc(400% / 5)` : `calc(100% / 5)`}
            />
            {!data?.child && (
              <>
                <DynamicCell selector="provider.displayName" placeholder="--" className="text-center" width="calc(100% / 5)" />
                <DynamicCell selector="flightNumber" placeholder="--" className="text-center alternate" width="calc(100% / 5)" />
                <DynamicCell selector="fcrReason" placeholder="--" className="text-center" width="calc(100% / 5)" />
              </>
            )}
          </VirtualTableRow>
        )}
      />
      <SidebarButton xAlign={SidebarButton.xAlign.RIGHT} yAlign={SidebarButton.yAlign.TOP} onClick={() => {}} tooltip="Export to CSV">
        <i className="sv sv-excel" />
      </SidebarButton>
      <SidebarButton xAlign={SidebarButton.xAlign.RIGHT} yAlign={SidebarButton.yAlign.TOP} onClick={printScreen} tooltip="Print">
        <i className="sv sv-print" />
      </SidebarButton>
    </div>
  );
};
export default Fcrs;

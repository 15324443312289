import './styles.scss';

import React, { useState } from 'react';

import ActionsDropdown from '@/components/ActionsDropdown';
import { Button } from 'react-bootstrap';
import { Position } from '../../models/constants';
import { useAppState } from '../../store/appReducer';

type PreviewFileProps = {
  src: string;
  className?: string;
  globalSettingsPath?: string;
  defaultPosition?: Position;
};
type PreviewFileState = {
  fullscreen: boolean;
};
const initPreviewFileState: PreviewFileState = {
  fullscreen: false,
};
const PreviewFile = ({ src, className = '', globalSettingsPath, defaultPosition = Position.Top }: PreviewFileProps) => {
  const [state, setState] = useState(initPreviewFileState);
  const [{ settings }, setAppState] = useAppState();
  const { fullscreen } = state;
  const previewPosition = settings?.[globalSettingsPath]?.previewPosition || defaultPosition;

  const onTogglePosition = (Position: Position) => {
    if (Position === previewPosition) return;
    setAppState((current: any) => ({
      ...current,
      settings: {
        ...current.settings,
        [globalSettingsPath]: { ...(current.settings?.[globalSettingsPath] || {}), previewPosition: Position },
      },
    }));
  };

  return (
    <div
      className={`PreviewFile ${fullscreen ? 'PreviewFile-full' : 'PreviewFile-mini rounded border'}${className ? ` ${className}` : ''}`}
    >
      <iframe title={'Import Preview'} src={src} />
      <div className="PreviewFile-controls">
        <Button
          variant="icon"
          className="sv sv-expand fs-4 rounded bg-light"
          onClick={() =>
            setState((current) => ({
              ...current,
              fullscreen: !current.fullscreen,
            }))
          }
        />
        {!fullscreen && !!globalSettingsPath && (
          <ActionsDropdown
            icon={<i className="fa fa-ellipsis-h fs-5" />}
            items={[
              { key: Position.Top, label: Position.Top },
              { key: Position.Left, label: Position.Left },
              { key: Position.Bottom, label: Position.Bottom },
            ]}
            onClick={onTogglePosition}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewFile;

import { InitComponentStateType, createComponentState } from '@/state';
import { SortDirectionEnum, Trip } from '@/models/gen/graphql';

import { Datetime } from '@/utils/dates';

export enum TripRowKeyEnum {
  LAST_COMBINE_IDS = 'LAST_COMBINE_IDS', // persist state
  SYNC_ROW_IDS = 'SYNC_ROW_IDS', // update rows but don't persist state
}

// this enum maps the sortable trips headers to the sortable fields in the TripTableSearchObject
export enum TripSortColumnEnum {
  type = 'type',
  latestScheduled = 'latestScheduled',
  latestScheduledUtc = 'latestScheduledUtc',
  actual = 'actual',
  airportCode = 'airportCode',
  servicerIataAirlineCode = 'servicerIataAirlineCode',
  flightNumber = 'flightNumber',
  pilots = 'pilots',
  attendants = 'attendants',
  driverId = 'driverId',
  vehicleId = 'vehicleId',
  puLocationId = 'puLocationId',
  doLocationId = 'doLocationId',
  payerProvider = 'payerProvider',
  rateAmount = 'rateAmount',
}

export type TripTableState = {
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>;
  onSortOnly: (column: TripSortColumnEnum) => void;
  onSort: (column: TripSortColumnEnum) => void;
  trips: Map<string, Trip>;
  onSetTrips: (rows: Trip[]) => void;
  refetch: () => void;
  selected: Map<string, Trip>;
  onSelect: (rowId: string) => void;
  onSelectAll: () => void;
  onSetRow: (update: Partial<Trip>, tripIds: string[], combineKey?: TripRowKeyEnum) => void;
  onDeleteRow: (tripIds: Set<string>, softDelete?: boolean) => void;
  getSelectedTrips: () => Trip[];
};

export const DEFAULT_TRIP_SORTING = [{ column: TripSortColumnEnum.latestScheduledUtc, direction: SortDirectionEnum.Asc }];
const getNextSortDirection = (current: SortDirectionEnum): SortDirectionEnum => {
  switch (current) {
    case null:
      return SortDirectionEnum.Asc;
    case SortDirectionEnum.Asc:
      return SortDirectionEnum.Desc;
    case SortDirectionEnum.Desc:
    default:
      return null;
  }
};
const initTripTableState: InitComponentStateType<TripTableState> = (set, get): TripTableState => {
  return {
    trips: new Map(),
    sorting: DEFAULT_TRIP_SORTING,
    onSortOnly: (column: TripSortColumnEnum): void => {
      set((current: TripTableState): TripTableState => {
        const sorting = [current.sorting.find((c) => c.column === column)];
        // sort single column
        const found = sorting?.[0];
        if (found?.column === column) {
          const next = getNextSortDirection(found.direction);
          sorting[0].direction = next;
        } else {
          sorting[0] = { column, direction: SortDirectionEnum.Asc };
        }
        return { ...current, sorting };
      });
    },
    onSort: (column: TripSortColumnEnum): void => {
      set((current: TripTableState): TripTableState => {
        const sorting = [...current.sorting];
        // sort multiple columns
        const index = sorting.findIndex((s) => s.column === column);
        if (index > -1) {
          const next = getNextSortDirection(sorting[index].direction);
          sorting[index].direction = next;
        } else {
          sorting.push({ column, direction: SortDirectionEnum.Asc });
        }
        return { ...current, sorting };
      });
    },
    refetch: () => {
      console.log('default refetch called');
      return null;
    },
    selected: new Map(),
    onSelect: (rowId: string): void => {
      // optimized for performance
      set((current: TripTableState): TripTableState => {
        const selected = new Map(current.selected);
        const found = selected.get(rowId);

        // add selection
        if (!found) selected.set(rowId, current.trips.get(rowId));
        else selected.delete(rowId); // remove selection

        // update state
        return { ...current, selected };
      });
    },
    onSelectAll: (): void => {
      set((current) => {
        const selected = new Map();
        if (current.selected.size > 0 && current.selected.size === current.trips.size) return { ...current, selected };
        for (let trip of current.trips.entries()) {
          selected.set(trip[0], trip[1]);
        }
        return { ...current, selected };
      });
    },
    onSetRow: (update: Partial<Trip>, tripIds: string[], combineKey: TripRowKeyEnum = TripRowKeyEnum.LAST_COMBINE_IDS): void => {
      set((current: TripTableState): TripTableState => {
        const combinedTripIds: string[] = (tripIds !== undefined ? tripIds : current.trips[update.id][combineKey]) || [];

        const trips = new Map(current?.trips);
        const idsToUpdate = new Set([update.id, ...combinedTripIds]);

        let updated = 0;

        // LAST_COMBINE_IDS add combine key/property to update to persist through component lifecycle
        if (combineKey === TripRowKeyEnum.LAST_COMBINE_IDS && tripIds !== undefined) update[combineKey] = Array.from(idsToUpdate);
        //TODO: add un-combine blacklist keys

        // Iterate over rows only once
        for (const rowId in trips.keys()) {
          // Skip rows that are not in idsToUpdate
          if (!idsToUpdate.has(rowId)) continue;
          // Update the row
          const row = trips.get(rowId);
          trips.set(rowId, { ...row, ...update, id: rowId });
          updated++;
          // Break the loop if all necessary updates have been made
          if (updated === idsToUpdate?.size) break;
        }

        return { ...current, trips };
      });
    },
    onDeleteRow: (tripIds: Set<string>, softDelete?: boolean): void => {
      set((current: TripTableState): TripTableState => {
        const trips = new Map(current.trips);
        let updated = 0;
        const deletedAt = softDelete ? new Datetime().toString() : null;
        for (const rowId in trips.keys()) {
          if (!tripIds.has(rowId)) continue;
          // if the trip is in all or deleted format, update the deletedAt property to now
          const row = trips.get(rowId);
          if (softDelete) trips.set(rowId, { ...row, deletedAt });
          else trips.delete(rowId); // otherwise remove the trip from state
          updated++;
          if (updated === tripIds.size) break;
        }
        return { ...current, selected: new Map(), trips };
      });
    },
    onSetTrips: (rows: Trip[]): void => {
      set((current) => {
        // clone trips
        const trips = new Map(current.trips);
        // Directly update the existing Map with new rows
        if (!rows?.length) trips.clear();
        rows.forEach((curr): void => {
          trips.set(curr.id, curr);
        });

        return {
          ...current,
          trips,
        };
      });
    },
    getSelectedTrips: (): Trip[] => {
      const { state } = get();
      return Array.from(state.selected.values());
    },
  };
};
const useTripTableState = createComponentState(initTripTableState);

export default useTripTableState;

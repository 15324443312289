import './styles.scss';

import { Button, Col, Container, Row } from 'react-bootstrap';
import DynamicContent, { DynamicContentProps } from '../DynamicContent';
import { Email, EmailTypeEnum } from '../../models/gen/graphql';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { Validation, sleep, stringify } from '../../utils';

import { EventObject } from '../../hooks/useOnChange';
import FormContainer from '../FormContainer';
import FormField from '../FormField';
import GenericFileUpload from '../FileUpload/new';
import SelectFromEnum from '../SelectFromEnum';

export type EditAirlineFormProps = {
  value: string | Partial<Airline>;
  show?: boolean;
  onCancel?: () => void;
  onError?: (err: string) => Promise<void>;
  onSubmit?: (form: Airline) => Promise<void>;
} & DynamicContentProps;

export type EditAirlineFormState = {
  data: Partial<Airline>;
  loading: boolean;
  error: string;
};
const initEditAirlineFormState: EditAirlineFormState = {
  data: null,
  loading: false,
  error: undefined,
};

//TODO: Add validation
const EditAirlineForm = ({ value = {}, show, onCancel, onError, onSubmit, ...props }: EditAirlineFormProps): ReactNode => {
  const [state, setState] = useState<EditAirlineFormState>(initEditAirlineFormState);
  const { data, loading } = state;
  const previousValue = useRef<string | Partial<Airline>>();

  const handleSubmit = async (form: Airline): Promise<void> => {
    onSubmit?.(form);
  };
  const handleCancel = async (): Promise<void> => {
    onCancel?.();
  };
  const handleError = async (err: string): Promise<void> => {
    onError?.(err);
  };
  const handleFetchAirline = async (value: string): Promise<void> => {
    try {
      setState((current: EditAirlineFormState): EditAirlineFormState => ({ ...current, loading: true, error: undefined }));
      // TODO: Replace mock logic with actual fetch.
      // BEGIN MOCK LOGIC
      console.log('handleFetchAirline', value);
      await sleep(3000);
      // END MOCK LOGIC
      setState((current: EditAirlineFormState): EditAirlineFormState => ({ ...current, data: mockAirline }));
    } catch (err) {
      setState((current: EditAirlineFormState): EditAirlineFormState => ({ ...current, error: err.message || err }));
    } finally {
      setState((current: EditAirlineFormState): EditAirlineFormState => ({ ...current, loading: false }));
    }
  };

  useEffect((): void => {
    if ((show !== undefined && !show) || stringify.compare(previousValue.current, value)) return;
    previousValue.current = value;
    if (value && typeof value === 'string') handleFetchAirline(value);
    else setState((current: EditAirlineFormState): EditAirlineFormState => ({ ...current, data: value as Airline }));
  }, [show, value]);

  return (
    <DynamicContent {...props} show={show}>
      <FormContainer
        name="EditAirlineForm"
        initialValues={data || {}}
        options={{ loadingSpinner: { loading } }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onError={handleError}
        formAs={({
          values,
          setValues,
          onChange,
          onSubmit,
          onCancel,
        }: {
          values: Record<string, any>;
          setValues: Dispatch<SetStateAction<Record<string, unknown>>>;
          onChange: (event: EventObject) => void;
          onSubmit: () => Promise<void>;
          onCancel: () => void;
        }): ReactNode => (
          <Container fluid>
            <DynamicContent.Title>{`${value && (loading || typeof value === 'string' || data?.id) ? 'EDIT' : 'CREATE'} AIRLINE`}</DynamicContent.Title>
            <Row>
              <Col xs={12}>
                <FormField name="iataCode" label="IATA code" value={values?.iataCode} onChange={onChange} condensed />
              </Col>
              <Col xs={12}>
                <FormField name="contactPerson" label="Contact Person" value={values?.contactPerson} onChange={onChange} condensed />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormField name="legalName" label="Legal Name" value={values?.legalName} onChange={onChange} condensed />
              </Col>
              <Col xs={12}>
                <FormField name="refId" label="Reference ID" value={values?.refId} onChange={onChange} condensed />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormField name="mobileNumber" label="Mobile Number" value={values?.mobileNumber} onChange={onChange} condensed />
              </Col>
              <Col xs={12}>
                <FormField name="shortName" label="Short Name" value={values?.shortName} onChange={onChange} condensed />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormField name="phone" label="Phone" value={values?.phone} onChange={onChange} condensed />
              </Col>
              <Col xs={12}>
                <FormField name="occPhone" label="occ Phone" value={values?.occPhone} onChange={onChange} condensed />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormField name="website" label="Website" value={values?.website} onChange={onChange} condensed />
              </Col>
              <Col xs={12}>
                <FormField name="email" label="Email" value={values?.email} onChange={onChange} condensed />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormField name="faxNumber" label="Fax Number" value={values?.faxNumber} onChange={onChange} condensed />
              </Col>
              <Col xs={6}>
                <GenericFileUpload
                  options={{ supportedExtensions: ['jpg', 'jpeg', 'png'], multiple: false, label: 'MAIN LOGO' }}
                  endpoint={null}
                  condensed
                />
              </Col>
              <Col xs={6}>
                <GenericFileUpload
                  options={{ supportedExtensions: ['jpg', 'jpeg', 'png'], multiple: false, label: 'ICON LOGO' }}
                  endpoint={null}
                  condensed
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="section-label">PRIMARY ADDRESS</div>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <FormField
                      name="primaryAddress.address"
                      label="Address"
                      value={values?.primaryAddress?.address}
                      onChange={onChange}
                      condensed
                    />
                  </Col>
                  <Col xs={12}>
                    <FormField name="primaryAddress.city" label="City" value={values?.primaryAddress?.city} onChange={onChange} condensed />
                  </Col>
                  <Col xs={7}>
                    <FormField
                      name="primaryAddress.state"
                      label="State"
                      value={values?.primaryAddress?.state}
                      onChange={onChange}
                      condensed
                    />
                  </Col>
                  <Col xs={5}>
                    <FormField
                      name="primaryAddress.zipCode"
                      label="Zip Code"
                      value={values?.primaryAddress?.zipCode}
                      onChange={onChange}
                      condensed
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="section-label">BILLING ADDRESS</div>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <FormField
                      name="billingAddress.address"
                      label="Address"
                      value={values?.billingAddress?.address}
                      onChange={onChange}
                      condensed
                    />
                  </Col>
                  <Col xs={12}>
                    <FormField name="billingAddress.city" label="City" value={values?.billingAddress?.city} onChange={onChange} condensed />
                  </Col>
                  <Col xs={7}>
                    <FormField
                      name="billingAddress.state"
                      label="State"
                      value={values?.billingAddress?.state}
                      onChange={onChange}
                      condensed
                    />
                  </Col>
                  <Col xs={5}>
                    <FormField
                      name="billingAddress.zipCode"
                      label="Zip Code"
                      value={values?.billingAddress?.zipCode}
                      onChange={onChange}
                      condensed
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="section-label">EMAILS</div>
                <div className="FormContainer-Group">
                  {(values?.emails || []).map(
                    (email: Email, e: number): JSX.Element => (
                      <EditAirlineEmail
                        email={email}
                        index={e}
                        onChange={onChange}
                        onDelete={(): void => {
                          onChange({
                            target: {
                              name: 'emails',
                              value: values?.emails.filter((_: Email, i: number): boolean => i !== e),
                            },
                          });
                        }}
                        key={e}
                      />
                    )
                  )}
                  <EditAirlineEmail
                    onAdd={(address: string, type: EmailTypeEnum): void =>
                      setValues(
                        (current: Partial<Airline>): Partial<Airline> => ({
                          ...current,
                          emails: [...(current?.emails || []), { address, type }],
                        })
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="EditAirlineForm-Footer">
              <Col sm={6}>
                <Button variant="primary" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col sm={6}>
                <Button variant="secondary" onClick={onSubmit}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      />
    </DynamicContent>
  );
};

const EditAirlineEmail = ({
  email,
  index,
  onChange,
  onAdd,
  onDelete,
}: {
  email?: Email;
  index?: number;
  onChange?: (e: EventObject) => void;
  onAdd?: (address: string, type: EmailTypeEnum) => void;
  onDelete?: () => void;
}): ReactNode => {
  const [address, setAddress] = useState<string>(email?.address);
  const [emailType, setEmailType] = useState<EmailTypeEnum>(email?.type);

  const handleChange = (e: EventObject): void => {
    if (onChange) return onChange(e);
    switch (e.target.name.split('.').pop()) {
      case 'type':
        return setEmailType(e.target.value);
      case 'address':
      default:
        return setAddress(e.target.value);
    }
  };

  return (
    <Row className="d-flex">
      <Col className="FormContainer-Content">
        <Row>
          <Col xs={6}>
            <FormField
              label="Email:"
              name={`emails.${index}.address`}
              value={email?.address || address}
              valid={Validation.isEmail(email?.address || address)}
              placeholder="Email"
              onChange={handleChange}
              condensed
            />
          </Col>
          <Col xs={6}>
            <FormField
              name={`emails.${index}.type`}
              label="Role:"
              value={email?.type || emailType}
              onChange={handleChange}
              source={EmailTypeEnum}
              placeholder="Role"
              valid={!!email?.type || !!emailType}
              condensed
              options={{
                feedback: { asTooltip: true },
                input: {
                  as: SelectFromEnum,
                },
              }}
            />
          </Col>
        </Row>
      </Col>
      {(onDelete || onAdd) && (
        <Col className="FormContainer-Control d-flex flex-column justify-content-center">
          {onDelete && (
            <Button name="REMOVE_EMAIL" className="p-0" variant="icon" onClick={onDelete}>
              <i className="fa fa-trash" />
            </Button>
          )}
          {onAdd && (
            <Button
              name="ADD_EMAIL"
              className="p-0"
              variant="icon"
              onClick={(): void => {
                onAdd(address, emailType);
                setAddress('');
                setEmailType(null);
              }}
            >
              <i className="fa fa-plus" />
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
};

// MOCK TYPES/DATA
export type Airline = {
  __typename: 'Airline';
  iataCode: string;
  contactPerson: string;
  legalName: string;
  id: string;
  mobileNumber: string;
  shortName: string;
  phone: string;
  website: string;
  faxNumber: string;
  primaryAddress: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
  billingAddress: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
  emails: Partial<Email>[];
};
const mockAirline = {
  iataCode: 'AA',
  contactPerson: 'John Doe',
  legalName: 'Air Asia',
  id: '123456789',
  mobileNumber: '1234567890',
  shortName: 'Air Asia',
  phone: '1234567890',
  website: 'airasia.com',
  faxNumber: '1234567890',
  primaryAddress: {
    address: '123 Main St',
    city: 'New York',
    state: 'NY',
    zipCode: '10001',
  },
  billingAddress: {
    address: '123 Main St',
    city: 'New York',
    state: 'NY',
    zipCode: '10001',
  },
  emails: [
    { address: 'a@a.com', type: EmailTypeEnum.Admin },
    { address: 'b@b.com', type: EmailTypeEnum.Admin },
  ],
};

export default EditAirlineForm;

import { Badge, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

import CopyButton from '../CopyButton';
import { Logger } from 'react-logger-lib'; // TODO: replace this with our internal Logger
import { onEnter } from '../../utils';
import useForm from '../../hooks/useForm';
import useLocalStorage from '../../hooks/useLocalStorage';

const initLoggingPaneState = {
  'log/Apollo': 'ERROR',
  'log/GraphQL': 'ERROR',
  useActions: 'OFF',
};
const initLoggingPaneForm = { key: '', level: 'OFF' };
const LoggingPane = () => {
  const [logging, setLogging] = useLocalStorage('logging', initLoggingPaneState);
  const [form, onChange, setForm] = useForm(initLoggingPaneForm);
  const { key, level } = form;

  const enableLogLevel = (logKey, logLevel) => {
    setLogging((current) => ({ ...current, [logKey]: logLevel }));
    setForm((current) => ({ ...current, key: '' }));
  };
  const disableLogLevel = (logKey) =>
    setLogging((current) => {
      const result = { ...initLoggingPaneState };
      Object.entries(current)
        .filter(([k]) => k !== logKey)
        .forEach(([k, v]) => (result[k] = v));
      window.localStorage.removeItem(logKey);
      return result;
    });
  const changeLogLevel = (logKey) => (event) => {
    const { target: { value: logLevel } = {} } = event;
    enableLogLevel(logKey, logLevel);
  };
  const onKeyboardAdd = onEnter(() => {
    if (key.length >= 4) enableLogLevel(key, level);
  });

  useEffect(() => {
    Object.entries(logging).forEach(([logKey, logLevel]) => {
      window.localStorage.setItem(logKey, logLevel);
    });
  }, [logging]);

  return (
    <>
      <Row>
        <Col className="text-center">
          <Badge pill bg="primary">
            Info: {Logger.calls.info}
          </Badge>
        </Col>
        <Col className="text-center">
          <Badge pill bg="danger">
            Error: {Logger.calls.error}
          </Badge>
        </Col>
        <Col className="text-center">
          <Badge pill bg="warning">
            Warn: {Logger.calls.warn}
          </Badge>
        </Col>
        <Col className="text-center">
          <Badge pill bg="success">
            Trace: {Logger.calls.trace}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="mt-3 mb-2" size="sm">
            <Form.Control
              className={`w-50 ${borderType(level)}`}
              onChange={onChange}
              name="key"
              value={key}
              placeholder="Key"
              title={key}
              onKeyDown={onKeyboardAdd}
            />
            <Form.Select className={`w-auto ${borderType(level)}`} onChange={onChange} name="level" value={level}>
              {loggingLevels.map((name, n) => (
                <option value={name} key={n}>
                  {n}. {name}
                </option>
              ))}
            </Form.Select>
            <Button variant="outline-success" onClick={() => enableLogLevel(key, level)} disabled={key.length < 3}>
              <i className="fa fa-plus" />
            </Button>
          </InputGroup>
          {Object.entries(logging).map(([k, l], n) => (
            <InputGroup className="mb-1" size="sm" key={n}>
              {!Object.keys(initLoggingPaneState).includes(k) && (
                <Button className="border-0" variant="outline-danger" onClick={() => disableLogLevel(k)}>
                  <i className="sv sv-tash2 {font-size:1.5rem;}" />
                </Button>
              )}
              <div
                className={`form-control w-50 d-flex ${borderType(l)}`}
                style={{
                  borderTopLeftRadius: '0.25rem',
                  borderBottomLeftRadius: '0.25rem',
                }}
              >
                <span className="flex-grow-1">{k}</span>
                <CopyButton text={k} tooltip={k} />
              </div>
              <Form.Select className={`w-auto ${borderType(l)}`} value={l} onChange={changeLogLevel(k)}>
                {loggingLevels.map((name, n) => (
                  <option value={name} key={n}>
                    {n}. {name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          ))}
        </Col>
      </Row>
    </>
  );
};
const loggingLevels = ['OFF', 'ERROR', 'WARN', 'INFO', 'TRACE'];
const borderType = (level) => {
  switch (loggingLevels.indexOf(level)) {
    case 1:
      return 'border-danger';
    case 2:
      return 'border-warning';
    case 3:
      return 'border-primary';
    case 4:
      return 'border-success';
    case 0:
    default:
      return 'border-default';
  }
};

export default LoggingPane;

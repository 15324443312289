import { TripConnectionInput } from '../../models/gen/graphql';

import GraphApi from '../../models/GraphApi';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchTrips: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, query: any[] = null, options: any = {}): Promise<any> => {
      const input: TripConnectionInput = {
        first,
        after,
        query: query && !!Object.keys(query).length ? query : null,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchTrips.tripConnection'),
  query: gql`
    query SearchTrips($input: TripConnectionInput) {
      searchTrips {
        tripConnection(input: $input) {
          edges {
            cursor
            node {
              airportCode
              attendants
              combineId
              communications {
                # createdAt
                # createdBy
                # creator {
                #   active
                #   address
                #   airports
                #   avatar
                #   cityName
                #   companyId
                #   countryCode
                #   createdAt
                #   createdBy
                #   dob
                #   email
                #   employeeId
                #   firstName
                #   id
                #   lastName
                #   longTrip
                #   notes
                #   password
                #   phone
                #   providerId
                #   resetClientIp
                #   resetCode
                #   resetCreatedAtUtc
                #   roleId
                #   startDate
                #   stateCode
                #   timezone
                #   updatedAt
                #   updatedBy
                #   username
                #   vehicleId
                #   zipCode
                # }
                id
                message
                tripId
                # updatedAt
                # updatedBy
              }
              companyId
              completion {
                comment
                completionTypeId
                # createdAt
                # createdBy
                datetime
                id
                # updatedAt
                # updatedBy
              }
              completionId
              createdAt
              createdBy
              crewId
              firstName
              lastName
              deletedAt
              deletedBy
              doActualDatetime
              doLocation {
                # active
                address
                # aliases {
                #   createdAt
                #   createdBy
                #   id
                #   locationId
                #   name
                #   updatedAt
                #   updatedBy
                # }
                cityName
                # coordinates {
                #   lat
                #   lng
                # }
                # countryCode
                # createdAt
                # createdBy
                # deletedAt
                # deletedBy
                id
                name
                # phoneNumber
                # pickupPoints {
                #   createdAt
                #   createdBy
                #   endDatetime
                #   id
                #   images {
                #     createdAt
                #     createdBy
                #     id
                #     path
                #     pickupPointId
                #     updatedAt
                #     updatedBy
                #   }
                #   instructions
                #   keyword
                #   locationId
                #   providerId
                #   startDatetime
                #   updatedAt
                #   updatedBy
                # }
                stateCode
                # type
                # updatedAt
                # updatedBy
                zipCode
              }
              doLocationId
              driver {
                # active
                # address
                # airports
                # availability {
                #   approved
                #   createdAt
                #   createdBy
                #   dayOfWeek
                #   end
                #   id
                #   repeatUntil
                #   start
                #   type
                #   updatedAt
                #   updatedBy
                #   userId
                # }
                avatar
                cityName
                # companyId
                # countryCode
                # createdAt
                # createdBy
                # dob
                # email
                employeeId
                firstName
                # groups {
                #   active
                #   companyId
                #   createdAt
                #   createdBy
                #   id
                #   name
                #   permissions {
                #     createdAt
                #     createdBy
                #     effect
                #     entity
                #     id
                #     resource
                #     updatedAt
                #     updatedBy
                #     verb
                #   }
                #   updatedAt
                #   updatedBy
                #   users {
                #     active
                #     address
                #     airports
                #     avatar
                #     cityName
                #     companyId
                #     countryCode
                #     createdAt
                #     createdBy
                #     dob
                #     email
                #     employeeId
                #     firstName
                #     id
                #     lastName
                #     longTrip
                #     notes
                #     password
                #     phone
                #     providerId
                #     resetClientIp
                #     resetCode
                #     resetCreatedAtUtc
                #     roleId
                #     startDate
                #     stateCode
                #     timezone
                #     updatedAt
                #     updatedBy
                #     username
                #     vehicleId
                #     zipCode
                #   }
                # }
                id
                # lastLogin {
                #   datetime
                # }
                lastName
                # licenses {
                #   createdAt
                #   createdBy
                #   expirationDate
                #   id
                #   licenseTypeId
                #   number
                #   stateCode
                #   updatedAt
                #   updatedBy
                #   userId
                # }
                # longTrip
                # notes
                # password
                phone
                # providerId
                # ratingDetails {
                #   averageRating
                #   rating {
                #     comment
                #     compliments
                #     createdAt
                #     createdBy
                #     id
                #     rating
                #     ratingTypeId
                #     tripId
                #     updatedAt
                #     updatedBy
                #     userId
                #   }
                # }
                # resetClientIp
                # resetCode
                # resetCreatedAtUtc
                # role {
                #   createdAt
                #   createdBy
                #   id
                #   name
                #   updatedAt
                #   updatedBy
                # }
                roleId
                # startDate
                # stateCode
                # timezone
                # tripDetails {
                #   onTimePercentage
                #   totalAmountOfTrips
                # }
                # updatedAt
                # updatedBy
                # username
                # vehicleId
                # zipCode
              }
              driverId
              fcrs {
                # cause
                # createdAt
                # createdBy
                # description
                # fcrType {
                #   createdAt
                #   createdBy
                #   id
                #   name
                #   updatedAt
                #   updatedBy
                # }
                # fcrTypeId
                id
                # tripId
                # updatedAt
                # updatedBy
              }
              flags {
                # active
                # color
                # companyId
                # createdAt
                # createdBy
                # global
                id
                # name
                # updatedAt
                # updatedBy
              }
              flightNumber
              fromManifest
              id
              #isLate
              issues {
                # createdAt
                # createdBy
                driverId
                id
                message
                tripId
                # updatedAt
                # updatedBy
              }
              kind
              loopName
              manifestImportId
              payerProviderId
              payerProvider {
                displayName
                name
                id
              }
              pilots
              provider {
                iataAirlineCode
                # billFrequency
                # billingAddress
                # billingCityCode
                # billingStateCode
                # billingZipCode
                # contactPerson
                # createdAt
                # createdBy
                displayName
                # emails {
                #   address
                #   createdAt
                #   createdBy
                #   id
                #   providerId
                #   type
                #   updatedAt
                #   updatedBy
                # }
                # faxNumber
                id
                # importCode
                # mobilePhoneNumber
                name
                # occPhoneNumber
                # primaryAddress
                # primaryCityCode
                # primaryEmail
                # primaryLogoUrl
                # primaryPhoneNumber
                # primaryStateCode
                # primaryZipCode
                # referenceNumber
                # type
                # updatedAt
                # updatedBy
                # website
              }
              providerId
              puActualDatetime
              puCompletedDatetime
              puLocation {
                # active
                address
                cityName
                # countryCode
                # createdAt
                # createdBy
                # deletedAt
                # deletedBy
                id
                name
                # phoneNumber
                stateCode
                # type
                # updatedAt
                # updatedBy
                zipCode
              }
              puLocationId
              rate {
                #   companyId
                #   contractId
                #   createdAt
                #   createdBy
                currency
                #   duration
                #   endDatetime
                #   id
                name
                #   notes
                #   providerId
                #   radius
                rate
                #   startDatetime
                #   thatLocation {
                #     active
                #     address
                #     cityName
                #     countryCode
                #     createdAt
                #     createdBy
                #     deletedAt
                #     deletedBy
                #     id
                #     name
                #     phoneNumber
                #     stateCode
                #     type
                #     updatedAt
                #     updatedBy
                #     zipCode
                #   }
                #   thatLocationId
                #   thisLocation {
                #     active
                #     address
                #     cityName
                #     countryCode
                #     createdAt
                #     createdBy
                #     deletedAt
                #     deletedBy
                #     id
                #     name
                #     phoneNumber
                #     stateCode
                #     type
                #     updatedAt
                #     updatedBy
                #     zipCode
                #   }
                #   thisLocationId
                #   tripDistance
                #   type
                #   updatedAt
                #   updatedBy
              }
              rateId
              ratings {
                comment
                # compliments
                # createdAt
                # createdBy
                id
                rating
                ratingType {
                  # createdAt
                  # createdBy
                  id
                  name
                  # updatedAt
                  # updatedBy
                }
                ratingTypeId
                tripId
                # updatedAt
                # updatedBy
                userId
              }
              remarks {
                createdAt
                createdAtInLocation
                updatedAtInLocation
                # createdBy
                id
                message
                tripId
                updatedAt
                # updatedBy
                subject {
                  firstName
                }
                object {
                  id
                  name
                  url
                  type
                }
              }
              scheduled
              scheduledUtc
              servicerIataAirlineCode
              split
              status
              stops {
                actual
                completed
                createdAt
                createdBy
                id
                location {
                  active
                  address
                  cityName
                  countryCode
                  createdAt
                  createdBy
                  # deletedAt
                  # deletedBy
                  id
                  name
                  phoneNumber
                  stateCode
                  type
                  updatedAt
                  updatedBy
                  zipCode
                }
                locationId
                scheduled
                # trip {
                #   airportCode
                #   attendants
                #   combineId
                #   companyId
                #   completionId
                #   createdAt
                #   createdBy
                #   crewId
                #   deletedAt
                #   deletedBy
                #   doActualDatetime
                #   doLocationId
                #   driverId
                #   flightNumber
                #   fromManifest
                #   id
                #   isLate
                #   kind
                #   loopName
                #   manifestImportId
                #   payerProviderId
                #   pilots
                #   providerId
                #   puActualDatetime
                #   puCompletedDatetime
                #   puLocationId
                #   rateId
                #   scheduled
                #   scheduledUtc
                #   servicerIataAirlineCode
                #   split
                #   status
                #   traceId
                #   trackFlightId
                #   type
                #   updatedAt
                #   updatedBy
                #   vehicleId
                # }
                tripId
                type
                updatedAt
                updatedBy
              }
              traceId
              trackFlight {
                actual
                # createdAt
                # createdBy
                arrivalGate
                departureGate
                id
                label
                # lastPingTime
                # nextPingTime
                number
                # providerIataAirlineCode
                scheduled
                servicerIataAirlineCode
                status
                # trackerProvider
                # type
                # updatedAt
                # updatedBy
              }
              trackFlightId
              type
              updatedAt
              updatedBy
              vehicle {
                # active
                # airportPermitExpirationDate
                capacity
                # createdAt
                # createdBy
                # emissionPermitExpirationDate
                id
                licensePlate
                # locationId
                make
                model
                phoneNumber
                # registrationExpirationDate
                # stateRegistered
                tollTag
                tracker
                trackingId
                # updatedAt
                # updatedBy
                vin
                year
              }
              vehicleId
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default searchTrips;

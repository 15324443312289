import { DeleteStopInput, DeleteUserBulkInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { DeleteUserBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type DeleteUserBulkGraphApiResponse = GraphApiResponse<typeof DeleteUserBulkDocument>;

const title = 'Delete Users';

const deleteUserBulkResponseSelector = (res: DeleteUserBulkGraphApiResponse): number =>
  !res.errors ? res?.deleteUserBulk?.deleted || 0 : undefined;

const [runDeleteUserBulk] = graphApi(DeleteUserBulkDocument, {
  onError: (res: DeleteUserBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteUserBulk = async (ids: string[]): Promise<number> => {
  const input: DeleteUserBulkInput = { search: ids.map((id: string): DeleteStopInput => ({ id })) };

  const res = await runDeleteUserBulk({ input });
  const selected = deleteUserBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteUserBulk = createGraphApiHook(deleteUserBulk);

export default deleteUserBulk;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Locations .LocationsTable .Filters {
  position: relative;
  z-index: 100;
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Locations/styles.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;EACA,YAAA;EACA,0BAAA;EACA,mBAAA;AADN","sourcesContent":[".Locations .LocationsTable .Filters {\n  position: relative;\n  z-index: 100;\n  border-radius: 8px 8px 0 0;\n  border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

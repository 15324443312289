// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FlagsButton {
  position: relative;
  width: 2.5rem;
  height: 1.25rem;
}
.FlagsButton > i {
  font-size: 1.25rem;
}
.FlagsButton > i.sv-flag-filled {
  position: absolute;
  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.5);
}
.FlagsButton > .badge {
  position: absolute;
  bottom: -0.583rem;
  transform: scale(0.7);
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/FlagsButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,eAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EACE,kBAAA;EACA,yCAAA;AACN;AAEE;EACE,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".FlagsButton {\n  position: relative;\n  width: 2.5rem;\n  height: 1.25rem;\n}\n.FlagsButton > i {\n  font-size: 1.25rem;\n}\n.FlagsButton > i.sv-flag-filled {\n  position: absolute;\n  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.5);\n}\n.FlagsButton > .badge {\n  position: absolute;\n  bottom: -0.583rem;\n  transform: scale(0.7);\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

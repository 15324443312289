// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreviewFile {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.PreviewFile iframe {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
  position: relative;
}
.PreviewFile iframe + .PreviewFile-controls {
  position: absolute;
  top: 0.25rem;
  right: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PreviewFile iframe + .PreviewFile-controls i {
  color: var(--bs-gray-500);
  background-color: #fff;
  cursor: pointer;
}
.PreviewFile iframe + .PreviewFile-controls i:hover {
  color: var(--bs-primary);
}
.PreviewFile iframe + .PreviewFile-controls i:first-of-type {
  border: 1px solid var(--bs-gray-200);
  padding: 0.2rem;
}
.PreviewFile iframe + .PreviewFile-controls i:nth-of-type(2) {
  margin: 0.25rem 0;
}
.PreviewFile iframe + .PreviewFile-controls i:nth-of-type(3) {
  transform: rotate(-90deg);
}
.PreviewFile.PreviewFile-full {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 5rem);
  height: calc(100vh - 2rem);
  z-index: 999;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/PreviewFile/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,sBAAA;AACF;AACE;EACE,sBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACN;AACM;EACE,yBAAA;EACA,sBAAA;EACA,eAAA;AACR;AAAQ;EACE,wBAAA;AAEV;AACQ;EACE,oCAAA;EACA,eAAA;AACV;AAEQ;EACE,iBAAA;AAAV;AAGQ;EACE,yBAAA;AADV;AAOE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,yBAAA;EACA,0BAAA;EACA,YAAA;EACA,gBAAA;AALJ","sourcesContent":[".PreviewFile {\n  position: relative;\n  overflow: hidden;\n  background-color: #fff;\n}\n.PreviewFile iframe {\n  background-color: #fff;\n  width: 100%;\n  height: 100%;\n  border: 0;\n  box-shadow: none;\n  position: relative;\n}\n.PreviewFile iframe + .PreviewFile-controls {\n  position: absolute;\n  top: 0.25rem;\n  right: 1.25rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.PreviewFile iframe + .PreviewFile-controls i {\n  color: var(--bs-gray-500);\n  background-color: #fff;\n  cursor: pointer;\n}\n.PreviewFile iframe + .PreviewFile-controls i:hover {\n  color: var(--bs-primary);\n}\n.PreviewFile iframe + .PreviewFile-controls i:first-of-type {\n  border: 1px solid var(--bs-gray-200);\n  padding: 0.2rem;\n}\n.PreviewFile iframe + .PreviewFile-controls i:nth-of-type(2) {\n  margin: 0.25rem 0;\n}\n.PreviewFile iframe + .PreviewFile-controls i:nth-of-type(3) {\n  transform: rotate(-90deg);\n}\n.PreviewFile.PreviewFile-full {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: calc(100vw - 5rem);\n  height: calc(100vh - 2rem);\n  z-index: 999;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import Select from '../Select';

const SelectStopType = (props) => (
  <Select {...props}>
    <option value="DO">D/O</option>
    <option value="PU">P/U</option>
  </Select>
);

export default SelectStopType;

import { VehicleConnectionInput, VehicleSearch } from '../../models/gen/graphql';

import GraphApi from '../../models/GraphApi';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchVehicles: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, query: VehicleSearch[] = null, options: any = {}): Promise<any> => {
      const input: VehicleConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchVehicles.vehicleConnection'),
  query: gql`
    query SearchVehicles($input: VehicleConnectionInput) {
      searchVehicles {
        vehicleConnection(input: $input) {
          edges {
            cursor
            node {
              active
              airportCodes {
                airportCode
              }
              airportPermitExpirationDate
              capacity
              # createdAt
              # createdBy
              emissionPermitExpirationDate
              id
              licensePlate
              location {
                # active
                # address
                # aliases {
                #   createdAt
                #   createdBy
                #   id
                #   locationId
                #   name
                #   updatedAt
                #   updatedBy
                # }
                # cityName
                # coordinates {
                #   lat
                #   lng
                # }
                # countryCode
                # createdAt
                # createdBy
                # deletedAt
                # deletedBy
                id
                name
                # phoneNumber
                # pickupPoints {
                #   createdAt
                #   createdBy
                #   endDatetime
                #   id
                #   images {
                #     createdAt
                #     createdBy
                #     id
                #     path
                #     pickupPointId
                #     updatedAt
                #     updatedBy
                #   }
                #   instructions
                #   keyword
                #   locationId
                #   providerId
                #   startDatetime
                #   updatedAt
                #   updatedBy
                # }
                # stateCode
                # traceId
                # type
                # updatedAt
                # updatedBy
                # zipCode
              }
              locationId
              make
              model
              phoneNumber
              registrationExpirationDate
              stateRegistered
              tollTag
              tracker
              trackingId
              # updatedAt
              # updatedBy
              vin
              year
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default searchVehicles;

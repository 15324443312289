import './styles.scss';

import ImportHistoryTable from './ImportHistoryTable';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import ScrollableContainer from '../../components/ScrollableContainer';

const ImportHistory = (): JSX.Element => (
  <ProtectedRoute title="Import History">
    <ScrollableContainer name="ImportHistory" className="page-container">
      <ImportHistoryTable />
    </ScrollableContainer>
  </ProtectedRoute>
);

export default ImportHistory;

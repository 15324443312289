import './styles.scss';

import React, { useMemo } from 'react';

import { Card } from 'react-bootstrap';
import ConfirmationButton from '../ConfirmationButton';
import { Middle } from '../Align';
import { Permission } from 'models/gen/graphql';
import Tippy from '@tippyjs/react';
import { getClasses } from '@/utils';

interface PermissionsCardProps {
  name: string;
  title?: string;
  permissions: Permission[];
  onSetting: () => void;
  onDelete?: () => void;
}

const PermissionsCard = ({ name, title, permissions, onSetting, onDelete }: PermissionsCardProps): JSX.Element => {
  const parsedPermissions = useMemo((): Permission[] => {
    if (!(permissions || []).length) return [];
    const foundStarPermission = {};
    // find star permissions
    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i];
      if (permission.verb !== '*') continue;
      foundStarPermission[permission.displayGroup] = true;
    }
    // return filtered permissions
    return permissions.filter(
      (node: Permission): boolean =>
        (!!foundStarPermission[node.displayGroup] && node.verb === '*') || !foundStarPermission[node.displayGroup]
    );
  }, [permissions]);

  return (
    <Card className={getClasses('PermissionsCard', 'primary', name)}>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>{name || '-'}</Card.Title>
        <span className="d-flex align-items-center gap-2">
          <Tippy content={'Edit Permissions'}>
            <i className="sv sv-cog text-dark fs-5 {cursor:pointer;}" onClick={onSetting} />
          </Tippy>
          {onDelete && (
            <ConfirmationButton
              feedback={`Delete ${title}`}
              className="p-0"
              onConfirm={onDelete}
              variant="transparent"
              icon={<i className="sv sv-trash2 text-danger fs-5" />}
              options={{ confirmation: { Body: { message: `Are you sure you want to delete "${name}" ${title}?` } } }}
            />
          )}
        </span>
      </Card.Header>
      <Card.Body className="p-2">
        {!!parsedPermissions?.length && (
          <ul>
            {(parsedPermissions || []).map(
              (permission: Permission, p: number): JSX.Element => (
                <li key={p}>{permission?.displayName}</li>
              )
            )}
          </ul>
        )}
        {!permissions?.length && (
          <Middle.Center>
            Click "<i className="sv sv-cog fs-4" />" to modify permissions
          </Middle.Center>
        )}
      </Card.Body>
    </Card>
  );
};

export default PermissionsCard;

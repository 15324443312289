import './styles.scss';

import ProtectedRoute from '../../components/ProtectedRoute';
import RatesTable from './RatesTable';
import React from 'react';
import ScrollableContainer from '../../components/ScrollableContainer';

const Rates = () => (
  <ProtectedRoute title="Rates">
    <ScrollableContainer name="Rates" className="page-container">
      <RatesTable />
    </ScrollableContainer>
  </ProtectedRoute>
);

export default Rates;

import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError, queryInput } from '@/utils/custom';
import { UpdateRateItemInput, UpdateRateItemValuesInput } from '@/models/gen/graphql';

import { UpdateRateItemBulkDocument } from '@/api/queries';
import graphApi from '@/api';

export type RateItemUpdate = Partial<Omit<UpdateRateItemValuesInput, 'rateGroupId'> & { id: string }>;
// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Update Rate Items' } });

// Service Function Signature
type ServiceMethodPayload = RateItemUpdate[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// UpdateRateItemBulk Service
export const { updateRateItemBulk } = ((): {
  updateRateItemBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(UpdateRateItemBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof UpdateRateItemBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const updates: UpdateRateItemInput[] = payload.map(
          ({ id, ...update }): UpdateRateItemInput => ({
            query: [{ id: queryInput(id) }],
            value: { ...update },
          })
        );
        const res = await fn({ input: { updates } });
        return res;
      }
  );

  return {
    updateRateItemBulk: mutate,
  };
})();

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { Validation, createNotification, onEnter, titleCase } from '../../utils';

import { Toast } from 'models';
import forgotPassword from '@/api/services/users/forgotPassword';
import useForm from '../../hooks/useForm';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordModalProps {
  show: boolean;
  onHide: () => void;
}

type ForgotPasswordModalState = {
  value: string;
  forgotUsername: boolean;
  passwordResetRequested: boolean;
};

const initForgotPasswordModalState: ForgotPasswordModalState = {
  value: '',
  forgotUsername: false,
  passwordResetRequested: false,
};

const ForgotPasswordModal = ({ show, onHide }: ForgotPasswordModalProps): JSX.Element => {
  // Init State
  const [state, onChange, setState] = useForm(initForgotPasswordModalState);
  const { value, forgotUsername, passwordResetRequested } = state;

  const navigate = useNavigate();

  const credentialType = useMemo((): 'email' | 'username' => (forgotUsername ? 'email' : 'username'), [forgotUsername]);

  // state Handler
  const onToggle =
    (name: string): (() => any) =>
    (): any =>
      setState((current: ForgotPasswordModalState): ForgotPasswordModalState => ({ ...current, [name]: !current[name] }));

  const onHandleHide = (): void => {
    onHide();
    setState(initForgotPasswordModalState);
  };

  // Handle Submit
  const handleForgotPassword = async (): Promise<void> => {
    const success = await forgotPassword(credentialType, value);

    const notify = {
      message: 'Please make sure the email address or username you entered is correct and try again.',
      type: Toast.Type.DANGER,
    };

    if (success) {
      notify.message = 'Please follow the instructions in the email associated with your account to reset your password.';
      notify.type = Toast.Type.SUCCESS;
    }

    createNotification(notify.message, notify.type, 'Forgot Password');

    onToggle('passwordResetRequested')();
  };

  return (
    <Modal show={show} onHide={onHandleHide} size="sm" centered>
      {!passwordResetRequested ? (
        <>
          <Modal.Title className="">Forgot Password?</Modal.Title>
          <Modal.Body className="pb-0 px-0">
            <p className="">
              Enter your <i>{credentialType}</i> to reset you password.
              <br />
              You may need to check your spam folder
            </p>
            <Form.Group>
              <Form.Control
                className="text-center"
                placeholder={titleCase(`enter ${credentialType}`)}
                name="value"
                value={value}
                onChange={onChange}
                onKeyDown={onEnter(handleForgotPassword)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-around pb-0 px-0">
            <Form.Group className="w-100 h-auto m-0">
              <Button variant="" onClick={onToggle('forgotUsername')} style={{ whiteSpace: 'nowrap' }}>
                <small>{`Enter ${forgotUsername ? 'Username' : 'Email'} Instead`}</small>
              </Button>
            </Form.Group>
            <Row className="w-100 d-flex justify-content-between" xs={1} sm={2}>
              <Col className="d-flex justify-content-center">
                <Button className="mt-sm-3 w-100" variant="light" onClick={onHandleHide}>
                  Go to Login
                </Button>
              </Col>
              <Col className="d-flex justify-content-center">
                <Button
                  className="mt-3 w-100"
                  variant="success"
                  onClick={handleForgotPassword}
                  disabled={forgotUsername ? !Validation.isEmail(value) : value.length < 3}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Title className="">Password Reset Requested</Modal.Title>
          <Modal.Body className="pb-0 px-0">
            <p className="">
              If the username or email address you entered exists, a link will be sent to the email address associated with your account.
              <br />
              <br />
              Please follow the instructions in the email to reset your password. Otherwise, please make sure the email address or username
              you entered is correct and try again.
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-around pb-0 px-0">
            <Row className="w-100 d-flex justify-content-between g-2" xs={1} sm={2}>
              <Col>
                <Button className="mt-3 w-100" variant="outline-gray" onClick={onHandleHide}>
                  Back To Login
                </Button>
              </Col>
              <Col>
                <Button
                  className="mt-3 w-100"
                  variant="success"
                  onClick={(): void => {
                    navigate('/reset-password', { replace: true });
                    onHandleHide();
                  }}
                >
                  Enter Reset Code
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ForgotPasswordModal;

import { GetKindsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getKinds = createGraphApiMockResponse(GetKindsDocument, {
  getFilters: {
    filters: {
      kind: [
        {
          id: 'ARR',
          displayName: 'ARR',
          __typename: 'FilterOption',
        },
        {
          id: 'DEP',
          displayName: 'DEP',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

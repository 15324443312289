import { CreateFlagHasTripBulkInput, CreateFlagHasTripInput, CreateFlagHasTripResponse } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreateFlagHasTripBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validateFlagHasTrip = validateObj({
  flagId: (val: string): string => val || '',
  tripId: (val: string): string => val || '',
});

type CreateFlagHasTripBulkGraphApiResponse = GraphApiResponse<typeof CreateFlagHasTripBulkDocument>;

const title = 'Add Flags';

const createFlagHasTripBulkResponseSelector = (res: CreateFlagHasTripBulkGraphApiResponse): CreateFlagHasTripResponse[] =>
  !res.errors ? ((res?.createFlagHasTripBulk?.output || []) as CreateFlagHasTripResponse[]) : undefined;

const [runCreateFlagHasTripBulk] = graphApi(CreateFlagHasTripBulkDocument, {
  onError: (res: CreateFlagHasTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createFlagHasTripBulk = async (flagHasTrips: CreateFlagHasTripInput[]): Promise<CreateFlagHasTripResponse[]> => {
  const input: CreateFlagHasTripBulkInput = { values: flagHasTrips.map(validateFlagHasTrip) };

  const res = await runCreateFlagHasTripBulk({ input });
  const selected = createFlagHasTripBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateFlagHasTripBulk = createGraphApiHook(createFlagHasTripBulk);

export default createFlagHasTripBulk;

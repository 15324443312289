import { DeleteAirportHasVehicleBulkInput, DeleteAirportHasVehicleInput } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { customDeleteHandler } from '../../utils';
import gql from 'graphql-tag';
import { validateCreateAirportHasVehicle } from './createAirportHasVehicleBulk';

const deleteAirportHasVehicleBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...ids: DeleteAirportHasVehicleInput[]): Promise<any> => {
      const input: DeleteAirportHasVehicleBulkInput = {
        search: ids.map(validateCreateAirportHasVehicle),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): number => data?.deleteAirportHasVehicleBulk?.deleted || 0,
  handler: customDeleteHandler('Unassign Vehicle from Airport', 'airportHasVehicle', 'deleteAirportHasVehicleBulk'),
  query: gql`
    mutation DeleteAirportHasVehicleBulk($input: DeleteAirportHasVehicleBulkInput!) {
      deleteAirportHasVehicleBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteAirportHasVehicleBulk;

import { FilterOption } from '../../models/gen/graphql';
import { GetServicerIataAirlineCodes } from '../../api/queries/filters';
import SelectFromQuery from '../SelectFromQuery';

const SelectAirlineIata = (props) => {
  return (
    <SelectFromQuery
      placeholder="All Airline"
      query={GetServicerIataAirlineCodes}
      selector={(data): Array<{ label: string; value: string }> => {
        return (data?.getFilters?.filters?.servicerIataAirlineCode || []).map(
          ({ displayName, id, values }: FilterOption): { label: string; value: string } => ({
            label: displayName,
            value: values[0],
          })
        );
      }}
      {...props}
      // the cache name matches EditTripsModal custom servicerAirlineIataCode custom select dropdown
      options={{ cache: 'AirlineIata', ...(props?.options || {}) }}
    />
  );
};
export default SelectAirlineIata;

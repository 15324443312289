import { GET_FLAGS } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectFlag = (props) => (
  <SelectFromQuery
    cache="Flags"
    placeholder="All Flags"
    query={GET_FLAGS}
    selector={(data) =>
      (data?.getFilters?.filters?.flag || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }))
    }
    multiple
    {...props}
  />
);

export default SelectFlag;

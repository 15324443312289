import { Flag, FlagEdge, TripSearch } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import gql from 'graphql-tag';

const getFlagsByTripId: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (first: number = PAGE_SIZE, after: string = null, query: TripSearch[] = null, options: any = {}): Promise<any> => {
      query = query && !!Object.keys(query).length ? query : null;

      return fireQuery({
        variables: {
          input: { first, after, query },
        },
        ...options,
      });
    },
  selector: (data: any): any => {
    if (data == undefined) return {};
    const { edges, totalCount, pageInfo: { hasNextPage = false, endCursor = '0' } = {} } = data.searchTrips?.tripConnection || {};
    if (!edges?.length) return {};
    const node = (edges || []).map((edge: FlagEdge): Flag => edge.node);
    const rows = node[0].flags || [];
    return { rows, hasNextPage, endCursor, totalCount };
  },
  query: gql`
    query SearchTrips($input: TripConnectionInput) {
      searchTrips {
        tripConnection(input: $input) {
          totalCount
          edges {
            cursor
            node {
              flags {
                id
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  `,
};

export default getFlagsByTripId;

import { Button } from 'react-bootstrap';
import { FlagWithTrip } from '../../../../../models/gen/graphql';
import FlagsButton from '../../../../../components/FlagsButton';
import Tippy from '@tippyjs/react';

const FlagAndCommunicationCell = ({
  flags,
  hasCommunication,
  onFlagClick,
  onCommunicationClick,
}: {
  flags: FlagWithTrip[];
  hasCommunication: boolean;
  onFlagClick: () => void;
  onCommunicationClick: () => void;
}): React.JSX.Element => (
  <div className="w-100 d-flex justify-content-around">
    <Tippy content="Flags">
      <div className="{padding-top:0.5rem;}">
        <FlagsButton flags={flags} onClick={onFlagClick} />
      </div>
    </Tippy>
    <Tippy content="Communications">
      <Button variant="icon" className="p-0" name="COMMUNICATIONS_BUTTON" onClick={onCommunicationClick}>
        <i className={`sv sv-message2 fs-5 ${hasCommunication ? 'text-info' : ''}`} />
      </Button>
    </Tippy>
  </div>
);

export default FlagAndCommunicationCell;

import './styles.scss';

import React, { useEffect, useState } from 'react';
import { getClasses, getRangeHandleColor } from '@/utils';

import { Badge } from 'react-bootstrap';
import TippyWhen from '../TippyWhen';
import { useRanger } from 'react-ranger';

export type RangeSliderProps = {
  min?: number;
  max?: number;
  value?: number[];
  stepSize?: number;
  tickSize?: number;
  disabled?: boolean;
  multiple?: boolean;
  segmentTooltips?: (string | number)[];
  tickLabel?: (value?: number, index?: number) => string | number;
  handleLabel?: (value?: number, index?: number) => string | number;
  handleTooltip?: (value?: number, index?: number) => string | number;
  onChange?: ({ target: { value } }: { target: { value: number[] } }) => any;
  handleColor?: (i: number) => string;
  segmentColor?: (i: number) => string;
};
const RangeSlider = ({
  min = 0,
  max = 100,
  value = [],
  stepSize = 1,
  tickSize = 1,
  disabled = false,
  segmentTooltips = [],
  tickLabel = (val: number): string | number => val,
  handleLabel = (val: number): string | number => '',
  handleTooltip = (val: number): string | number => val,
  handleColor = getRangeHandleColor,
  segmentColor,
  onChange,
}: RangeSliderProps): JSX.Element => {
  const [values, setValues] = useState(value);
  const { getTrackProps, ticks, segments, handles } = useRanger({
    min,
    max,
    stepSize,
    values,
    onChange: (vals: any): void => {
      setValues(vals);
      onChange({ target: { value: vals } });
    },
    tickSize,
    disabled,
  });

  useEffect((): void => {
    setValues(value);
  }, [value]);

  return (
    <div className={`RangeSlider${disabled ? ' disabled' : ''}`}>
      <div className="RangeSlider-Track" {...getTrackProps()}>
        {ticks.map(({ value, getTickProps }: { value: number; getTickProps: any }, index: number): JSX.Element => {
          const label = tickLabel(value, index);
          if (label === '') return null;
          return (
            <div
              className={`RangeSlider-Tick${index === 0 ? ' first' : index === ticks.length - 1 ? ' last' : ''}`}
              {...getTickProps()}
              key={index}
            >
              <div className="RangeSlider-TickLabel">{label}</div>
            </div>
          );
        })}
        {segments.map(({ getSegmentProps }: { getSegmentProps: any }, index: number): JSX.Element => {
          return (
            <div
              className={getClasses(
                'RangeSlider-Segment',
                handles?.length && segmentColor ? `bg-${segmentColor(index)}` : undefined,
                handles?.length && !segmentColor && index === 0 ? `bg-${getRangeHandleColor(handles.length - 1)}` : undefined,
                handles?.length && !segmentColor && index !== 0 ? `bg-${getRangeHandleColor(index - 1)}` : undefined,
                !handles?.length ? 'bg-gray-subtle' : undefined
              )}
              {...getSegmentProps()}
              key={index}
            >
              {index !== 0 && !!segmentTooltips[index - 1] && (
                <div className={`RangeSlider-Segment-Tooltip${disabled ? ' disabled' : ''}`} key={index}>
                  {segmentTooltips[index - 1]}
                </div>
              )}
            </div>
          );
        })}
        {handles.map(
          ({ value, active, getHandleProps }: { value: number; active: boolean; getHandleProps: any }, index: number): JSX.Element => {
            const label = handleLabel(value, index);
            const tooltip = handleTooltip(value, index);
            return (
              <button
                {...getHandleProps({
                  style: {
                    appearance: 'none',
                    border: 'none',
                    background: 'transparent',
                    outline: 'none',
                  },
                })}
                key={index}
              >
                <TippyWhen isTrue={label !== ''} options={{ content: tooltip }}>
                  <Badge className={`rounded-circle RangeSlider-Handle${active ? ' active' : ''}`} bg={handleColor(index)}>
                    {label}
                  </Badge>
                </TippyWhen>
              </button>
            );
          }
        )}
      </div>
    </div>
  );
};

export default RangeSlider;

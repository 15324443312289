import { useMemo } from 'react';

/* useClassNames
  Combines multiple strings of "classNames" into one string with
  no duplicates, and sorted alphabetically.

  const classes = useClassNames('my-static classes', props.className, 'some-other user-classes', 'with-no duplicate classes');

  The above example would return a single string:

  "classes duplicate my-static some-other user-classes with-no"

  This would also have included any classes passed in through
  props.className.

  The real use-case for this hook is when you require a component
  to have specific classes set, but also would like the user to
  be able to provide their own className prop and add the classes
  without having to concatenate strings and variables together.
*/

const useClassNames = (...classes: string[]): string => {
  const result = useMemo((): string => {
    const strings = classes
      .join(' ')
      .split(' ')
      .filter((str: string): boolean => str && typeof str === 'string');

    return Array.from(new Set(strings)).sort().join(' ');
  }, [classes]);

  return result;
};

export default useClassNames;

import {
  ConnectionDetails,
  HandleErrorInput,
  QueryInputType,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails as merge,
  queryInput,
} from '@/utils/custom';
import { ConnectionOptions, GraphApiMethod } from '@/api/core';
import { Datetime, getProperty } from '@/utils';
import {
  Location,
  LocationWithPunchPolicySearch,
  SearchLocationsResponseLocationWithPunchPolicyConnectionArgs,
  SortDirectionEnum,
} from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { DATETIME_RFC_FORMAT } from '../../../../constants';
import { PolicyTypeEnum } from '../../components/SelectPolicyType';
import { SearchLocationsWithPunchPolicyDocument } from '@/api/queries';
import { TypedDocumentNode } from '@apollo/client';
import graphApi from '@/api';

// Global Service Variables
const mergeKey = 'searchLocations.locationWithPunchPolicyConnection';
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Search Locations With Punch Policy' } });

// Service Function Signature
type ServiceMethodPayload = {
  [key in keyof LocationWithPunchPolicySearch]?: any;
};
interface ServiceMethod {
  (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<Location>>;
}

// SearchLocationsWithPunchPolicy Service
// carmstrong @ 4/30/2024
export const { locationWithPunchPolicySearch, refetchLocationWithPunchPolicySearch, useLocationWithPunchPolicySearch } = ((): {
  locationWithPunchPolicySearch: ServiceMethod;
  refetchLocationWithPunchPolicySearch: ServiceMethod;
  useLocationWithPunchPolicySearch: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [fetch, refetch] = graphApi(SearchLocationsWithPunchPolicyDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<TypedDocumentNode>): ServiceMethod =>
      async (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<Location>> => {
        const eventEndDatetime = new Datetime(payload?.eventStartDatetime).asDayjs().add(1, 'year').format(DATETIME_RFC_FORMAT);
        const dateRange = [payload?.eventStartDatetime, eventEndDatetime];
        const dateQuery =
          payload?.type === PolicyTypeEnum.specialEvents
            ? queryInput(dateRange, QueryInputType.RANGE, SortDirectionEnum.Asc, 2)
            : queryInput([], QueryInputType.ISNULL);
        const search: LocationWithPunchPolicySearch = Object.entries(payload || {})
          .filter(([, value]: [string, any]): boolean => value !== undefined)
          .reduce(
            (acc: LocationWithPunchPolicySearch, [_key, _value]: [string, any]): LocationWithPunchPolicySearch => {
              // Custom validation/overrides go here.
              return acc;
            },
            {
              startTime: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0),
              name: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 1),
              airportCode: payload?.airportCode?.length ? queryInput(payload?.airportCode, QueryInputType.OR) : null,
              eventStartDatetime: dateQuery,
            }
          );
        const search2: LocationWithPunchPolicySearch = Object.entries(payload || {})
          .filter(([, value]: [string, any]): boolean => value !== undefined)
          .reduce(
            (acc: LocationWithPunchPolicySearch, [_key, _value]: [string, any]): LocationWithPunchPolicySearch => {
              // Custom validation/overrides go here.
              return acc;
            },
            {
              startTime: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0),
              name: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 1),
              airportCode: payload?.airportCode?.length ? queryInput(payload?.airportCode, QueryInputType.OR) : null,
              eventEndDatetime: dateQuery,
            }
          );
        const query = [search, search2];
        const args: SearchLocationsResponseLocationWithPunchPolicyConnectionArgs = {
          input: {
            first: options?.pageSize || null,
            after: options?.pageSize > 0 ? (options?.pageSize * options?.page).toString() : null,
            query: !!payload && !!Object.keys(search).length ? query : null,
          },
        };
        const opts = { merge: options?.merge ? mergeKey : undefined };
        const res = await fn(args, opts);
        return convertConnectionToDetails(getProperty(mergeKey, res));
      }
  );
  const useService = createGraphApiHook(fetch, { refetch, merge });
  return {
    locationWithPunchPolicySearch: fetch,
    refetchLocationWithPunchPolicySearch: refetch,
    useLocationWithPunchPolicySearch: useService,
  };
})();

import { SearchFcrTypesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const searchFcrTypes = createGraphApiMockResponse(SearchFcrTypesDocument, {
  searchFcrTypes: {
    fcrTypeConnection: {
      edges: [
        {
          node: {
            id: '87da67b5-4972-46cf-a689-1a42fa100214',
            name: 'Airline',
            __typename: 'FcrType',
          },
          __typename: 'FcrTypeEdge',
        },
        {
          node: {
            id: '87da67b5-4972-46cf-a689-1a42fa100214',
            name: 'Dispatch',
            __typename: 'FcrType',
          },
          __typename: 'FcrTypeEdge',
        },
        {
          node: {
            id: '87da67b5-4972-46cf-a689-1a42fa100214',
            name: 'Equipment',
            __typename: 'FcrType',
          },
          __typename: 'FcrTypeEdge',
        },
        {
          node: {
            id: '87da67b5-4972-46cf-a689-1a42fa100214',
            name: 'Positive',
            __typename: 'FcrType',
          },
          __typename: 'FcrTypeEdge',
        },
        {
          node: {
            id: '87da67b5-4972-46cf-a689-1a42fa100214',
            name: 'Traffic',
            __typename: 'FcrType',
          },
          __typename: 'FcrTypeEdge',
        },
        {
          node: {
            id: '87da67b5-4972-46cf-a689-1a42fa100214',
            name: 'Crew',
            __typename: 'FcrType',
          },
          __typename: 'FcrTypeEdge',
        },
      ],
      pageInfo: {
        startCursor: '0',
        endCursor: '6',
        hasPreviousPage: false,
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      totalCount: 6,
      __typename: 'FcrTypeConnection',
    },
    __typename: 'SearchFcrTypeResponse',
  },
});

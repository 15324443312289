import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const getDriveTimeReport: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (input: any = {}): Promise<any> => {
      const variables = {
        input: {
          after: null,
          airports: null,
          buckets: [
            {
              hour: null,
              minute: null,
              name: null,
            },
          ],
          dropoffLocationIds: null,
          endDatetime: null,
          first: null,
          iataAirlineCodes: null,
          payerProviderIds: null,
          pickupLocationIds: null,
          startDatetime: null,
          lowerThreshold: null,
          upperThreshold: null,
          ...input,
          format: input?.format || 'CLIENT',
        },
      };
      return fireQuery({
        variables,
        fetchPolicy: 'no-cache',
      });
    },
  selector: (data: any): any => data?.getDriveTimeReport?.output || {},
  query: gql`
    query GetDriveTimeReport($input: GetDriveTimeReportInput!) {
      getDriveTimeReport(input: $input) {
        output {
          tables {
            name
            totalTrips
            overallDriveMinutes
            weekdayTrips
            weekdayAvgDriveMinutes
            weekendTrips
            weekendAvgDriveMinutes
            holidayTrips
            holidayAvgDriveMinutes
            averageDifference
            rows {
              pickup
              dropoff
              name
              mon
              tue
              wed
              thr
              fri
              sat
              sun
              weekdayAvgDriveMinutes
              weekendAvgDriveMinutes
              holidayAvgDriveMinutes
              averageDifference
              totalTrips
              children {
                name
                totalTrips
                mon
                tue
                wed
                thr
                fri
                sat
                sun
                weekdayAvgDriveMinutes
                weekendAvgDriveMinutes
                holidayAvgDriveMinutes
                averageDifference
              }
            }
          }
        }
      }
    }
  `,
};

export default getDriveTimeReport;

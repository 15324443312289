import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError } from '@/utils/custom';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { DeleteBufferTimeBulkDocument } from '@/api/queries';
import { DeleteBufferTimeInput } from '@/models/gen/graphql';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Delete Buffer Time Setting' } });

// Service Function Signature
type ServiceMethodPayload = string[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// DeleteBufferTimeBulk Service
export const { deleteBufferTimeBulk, useDeleteBufferTimeBulk } = ((): {
  deleteBufferTimeBulk: ServiceMethod;
  useDeleteBufferTimeBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(DeleteBufferTimeBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof DeleteBufferTimeBulkDocument>): ServiceMethod =>
      async (ids: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const search: DeleteBufferTimeInput[] = ids.map((id: string): DeleteBufferTimeInput => ({ id }));
        const res = await fn({ input: { search } });
        return res;
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    deleteBufferTimeBulk: mutate,
    useDeleteBufferTimeBulk: useService,
  };
})();

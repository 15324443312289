import { Button, Form } from 'react-bootstrap';
import React, { useEffect } from 'react';

import Collapsible from '../../components/Collapsible';
import EditModal from '../../components/EditModal/new';
import Logger from '../../utils/logs';
import { Permission } from '../../models/gen/graphql';
import PermissionInput from './PermissionInput';
import Vertical from '../../components/Vertical';
import { getPermissions } from '../../api/services/permissions/searchPermissions';
import { parseTemplateString } from '../../utils';
import useForm from '../../hooks/useForm';
import useUuid from '../../hooks/useUuid';

const log = Logger.of('EditPermissionsModal');

type EditPermissionModel = { node: Permission; value: boolean };
type EditPermissionState = {
  permissionState: Record<string, Record<string, EditPermissionModel>>;
  allPermission: EditPermissionModel;
  loading: boolean;
  expandAll: boolean;
  search: string;
};
const initEditPermissionState: EditPermissionState = {
  permissionState: undefined,
  allPermission: { node: undefined, value: false },
  loading: false,
  expandAll: true,
  search: '',
};
interface EditPermissionsModalProps {
  show: boolean;
  title?: string;
  onHide?: Function;
  onSubmit?: (data: { creates: string[]; deletes: string[] }) => void;
  onExited?: Function;
  onCancel?: Function;
  data?: {
    [key: string]: any;
    permissions?: Permission[];
  };
  options?: any;
  loading?: boolean;
}

const EditPermissionsModal = ({ show, onHide, onSubmit, onExited, onCancel, data, ...props }: EditPermissionsModalProps): JSX.Element => {
  // Init State
  const [state, onChange, setState] = useForm(initEditPermissionState);
  const { permissionState, allPermission, expandAll, search, loading } = state;

  const uuid = useUuid();

  const onFilterPermissions = (event: any): void =>
    onChange({ target: { name: event.target.name, value: (event.target.value || '').trim().toLowerCase() } });

  const handleSubmit = (event: any): void => {
    toggleLoading();

    const creates = [];
    const deletes = [];

    Object.keys(permissionState).forEach((header: string): void =>
      Object.entries(permissionState?.[header]).forEach(([permissionId, permission]: [string, EditPermissionModel]): void => {
        const value = permission.value;
        const foundPermission = !!(data?.permissions || []).filter((node: Permission): boolean => node?.id === permissionId).length;
        if (foundPermission && !value) {
          deletes.push(permissionId);
        }
        if (!foundPermission && value) {
          creates.push(permissionId);
        }
      })
    );

    const foundAllPermission = !!(data?.permissions || []).filter((node: Permission): boolean => node?.id === allPermission?.node?.id)
      .length;
    if (foundAllPermission && !allPermission?.value) {
      deletes.push(allPermission?.node?.id);
    }
    if (!foundAllPermission && allPermission?.value) {
      creates.push(allPermission?.node?.id);
    }

    onSubmit({ creates, deletes });
    toggleLoading();
  };

  const toggleLoading = (): void =>
    setState((current: EditPermissionState): EditPermissionState => ({ ...current, loading: !current.loading }));

  const sendRequest = async (): Promise<void> => {
    try {
      toggleLoading();
      const response = await getPermissions();
      const records = response?.rows || [];
      if (!records?.length) return;

      const initPermissionState: Record<string, Record<string, EditPermissionModel>> = {};
      let initAllPermission: { node: Permission; value: boolean } = initEditPermissionState?.allPermission;
      for (let i = 0; i < records.length; i++) {
        const node = records[i];
        const hasPermission = !!(data?.permissions || []).filter((permission: Permission): boolean => permission?.id === node?.id).length;

        if (node?.entity === '*' && node?.verb === '*') {
          initAllPermission = { node, value: !!hasPermission };
          continue;
        }
        const current = initPermissionState[node?.displayGroup] || {};
        const temp = { [node?.id]: { node, value: !!hasPermission } };

        initPermissionState[node?.displayGroup] = { ...current, ...temp };
      }

      setState(
        (current: EditPermissionState): EditPermissionState => ({
          ...current,
          permissionState: initPermissionState,
          allPermission: initAllPermission,
        })
      );
    } catch (err) {
      log.error('sendRequest', err?.message || err);
    } finally {
      toggleLoading();
    }
  };

  useEffect((): void => {
    if (!show) return;
    sendRequest();
  }, [show]);

  const displayPermissions = (heading: string): JSX.Element[] => {
    if (!permissionState) return null;
    const currentState: { [permissionId: string]: EditPermissionModel } = (permissionState || {})?.[heading];
    const entityPermissions = Object.values(currentState);
    const starPermission = getStarPermission(entityPermissions);

    return entityPermissions.map(({ node: permission, value }: EditPermissionModel, p: number): JSX.Element => {
      const containsSearch = JSON.stringify(permission).toLowerCase().includes(search);
      if (!containsSearch) return null;
      return (
        <PermissionInput
          id={permission?.id}
          name={`permissionState.${heading}.${permission?.id}.value`}
          className="d-inline me-2"
          checked={!!allPermission?.value || !!starPermission?.value || value}
          disabled={!!allPermission?.value || (!!starPermission?.value && permission?.id !== starPermission?.node?.id)}
          onChange={onChange.toggle}
          label={permission?.displayName}
          options={{
            tippyWhen: {
              isTrue: !!permission?.description,
              content: (
                <p className="p-3 m-0 {max-width:300px;}">
                  <h5 className="mb-2">{permission?.displayName}</h5>
                  {permission?.description}
                </p>
              ),
            },
          }}
          key={p}
        />
      );
    });
  };

  return (
    <EditModal
      name="editPermissions"
      show={show}
      title={parseTemplateString('Edit Permissions - {{firstName|name|displayName|(n/a)}} {{lastName}}', data).trim()}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onCancel={onCancel}
      onExited={onExited}
      onSubmit={handleSubmit}
      options={undefined}
      loading={loading}
    >
      <section className="w-100">
        <div className="d-flex justify-content-between align-items-center px-3 mb-3">
          <Button name="expandAll" variant="gray" className="py-1" onClick={onChange.toggle}>
            {expandAll ? 'Collapse All' : 'Expand All'}
          </Button>
          <Form.Control className="w-50" name="search" onChange={onFilterPermissions} value={search} placeholder="Search" />
          <Form.Switch
            id={`-1_${uuid}`}
            // className="{h:2.25rem;py:0.5rem;m:0|1.5rem|0|0.8rem;} {font:bold}>label {scale:1.5;translateX(0.5rem)}>input"
            name="allPermission.value"
            checked={!!allPermission?.value}
            onChange={onChange.toggle}
            label={`${allPermission?.value ? 'Disable' : 'Enable'} Super Admin`}
            disabled={!allPermission?.node}
          />
        </div>
        <Vertical cols={4}>
          {Object.keys(permissionState || {}).map(
            (heading: string, h: number): JSX.Element => (
              <Collapsible className="mb-3" title={heading.toUpperCase()} options={{ full: true }} show={expandAll} key={h}>
                {displayPermissions(heading)}
              </Collapsible>
            )
          )}
        </Vertical>
      </section>
    </EditModal>
  );
};

const getStarPermission = (values: EditPermissionModel[]): EditPermissionModel =>
  values.find(({ node: permission }: EditPermissionModel): boolean => permission?.verb === '*');

export default EditPermissionsModal;

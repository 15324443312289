import { CompletionCurrencyEnum, InvoiceTripCurrencyEnum, RateCurrencyEnum } from 'models/gen/graphql';

import { OnChange } from 'hooks/useOnChange';
import React from 'react';
import SelectFromEnum from '../SelectFromEnum';

type SelectCurrencyProps = {
  onChange: OnChange;
  value: string;
  currencyEnumType?: 'completion' | 'invoice' | 'rate';
  [x: string]: any;
};

const SelectCurrency = ({ onChange, value, currencyEnumType, ...props }: SelectCurrencyProps): JSX.Element => {
  let currencyType: any = RateCurrencyEnum;
  switch (currencyEnumType) {
    case 'completion':
      currencyType = CompletionCurrencyEnum;
      break;
    case 'invoice':
      currencyType = InvoiceTripCurrencyEnum;
      break;
  }

  return (
    <SelectFromEnum
      name="currency"
      onChange={onChange}
      value={value}
      placeholder="Currency"
      source={currencyType}
      getLabel={(key: string, value: string): string => value}
      {...props}
    />
  );
};

export default SelectCurrency;

import { CreateRateGroupInput, RateGroup } from '@/models/gen/graphql';
import { HandleErrorInput, handleError } from '@/utils/custom';

import { CreateRateGroupBulkDocument } from '@/api/queries';
import { GraphApiMethod } from '@/api/core';
import { Validation } from '@/utils';
import { enforceRateDates } from './createRateItemBulk';
import graphApi from '@/api';

// Global Service Variables
export const RATE_GROUP_VALIDATION_CRITERIA = {
  airportCode: (val: any): Validation.Validity => ({ valid: !!val }),
  'companyId!': (_val: any): Validation.Validity => ({
    valid: undefined,
    defaultValue: '',
  }),
  duration: (_val: any): Validation.Validity => ({ valid: undefined, defaultValue: 0 }),
  payerProviderId: (val: any): Validation.Validity => ({
    valid: !!Validation.isValidUUID(val),
    defaultValue: null,
  }),
  'thatLocationId!': (val: any): Validation.Validity => ({ valid: !!Validation.isValidUUID(val) }),
  'thisLocationId!': (val: any): Validation.Validity => ({ valid: !!Validation.isValidUUID(val) }),
  tripDistance: (_val: any): Validation.Validity => ({ valid: undefined, defaultValue: 0 }),
};
export const createRateGroupBulkValidator = new Validation.Validator(RATE_GROUP_VALIDATION_CRITERIA);

const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Create Rate Group' } });

// Service Function Signature
type ServiceMethodPayload = Partial<CreateRateGroupInput>[];
interface ServiceMethod {
  (...payload: ServiceMethodPayload): Promise<RateGroup[]>;
}

// CreateRateGroupBulk Service
export const { createRateGroupBulk } = ((): {
  createRateGroupBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(CreateRateGroupBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof CreateRateGroupBulkDocument>): ServiceMethod =>
      async (...payload: ServiceMethodPayload): Promise<RateGroup[]> => {
        const values: CreateRateGroupInput[] = payload.map((rateGroup: CreateRateGroupInput): CreateRateGroupInput => {
          const { items, ...rest } = rateGroup;
          const rateWithAdjustedDates = { ...rest, items: items.map(enforceRateDates) };
          return { ...createRateGroupBulkValidator.create(rateWithAdjustedDates), items };
        });
        const res = await fn({ input: { values } });
        return res?.createRateGroupBulk?.output?.map(({ node }: { node: RateGroup }): RateGroup => node) || [];
      }
  );

  return {
    createRateGroupBulk: mutate,
  };
})();

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimePicker {
  display: flex;
  flex-grow: 1;
}
.TimePicker .ant-picker-input input {
  text-align: center;
}
.TimePicker .ant-picker-clear {
  padding-right: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/TimePicker/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,sBAAA;AAEJ","sourcesContent":[".TimePicker {\n  display: flex;\n  flex-grow: 1;\n}\n.TimePicker .ant-picker-input input {\n  text-align: center;\n}\n.TimePicker .ant-picker-clear {\n  padding-right: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

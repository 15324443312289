import React, { useEffect, useRef } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow } from '../../components/VirtualTable';

import { Datetime } from '../../utils';
import EditModal from '../../components/EditModal/new';
import { Trip } from '../../models/gen/graphql';
import { getClasses } from '../../utils/strings';
import useModal from '../../hooks/useModal';
import useTrips from '../../hooks/useTrips';

type State = {
  selected: string[];
  initSelected: string[];
  unCombined: string[];
  loading: boolean;
};

// TODO: Find/use the type generated in GraphQL.
type UncombineTripsPayload = { id: string; combineId: [null]; driverId: [null]; vehicleId: [null] };

const EditCombine = (): JSX.Element => {
  const [state, setState] = useModal('EditCombine', {
    onSubmit: async (data: any): Promise<void> => {
      const combineTripIds = data?.selected?.filter?.((id: string): boolean => !initSelected.includes(id)) || [];
      const unCombinePayload =
        data?.unCombined?.map?.(
          (id: string): UncombineTripsPayload => ({
            id,
            combineId: [null],
            driverId: [null],
            vehicleId: [null],
          })
        ) || [];
      await handleSubmitCombine({ updates: { combineTripIds, unCombinePayload }, ...data });
      onHide();
    },
  });
  const { show = false, tripId = '', combineId = '', selected = [], initSelected = [], unCombined = [] } = state;
  const { onHide, onSubmit } = setState;
  const [tripState, { searchCombinableTrips, handleSubmitCombine, refetchCombinableTrips }, { loading }] = useTrips();
  const { combineResults: { rows = [], totalCount = 0 } = {} } = tripState;
  const lastTripId = useRef();

  const onSetCombine = (clickedId: string | string[]): void => {
    return setState((current: State): State => {
      let newSelected = [...selected];
      let newUncombined = [...unCombined];

      // Check if 'clickedId' is an array and matches the length of 'selected' for 'select all' or 'deselect all'
      const isSelectAll = Array.isArray(clickedId) && clickedId.length !== selected?.length;
      const isDeselectAll = Array.isArray(clickedId) && clickedId.length === selected?.length;

      if (isSelectAll) {
        // Select all items: Replace 'newSelected' with all ids, and clear 'newUncombined'
        newSelected = clickedId;
        newUncombined = initSelected.filter((id) => !clickedId.includes(id));
      } else if (isDeselectAll) {
        // Deselect all items: Clear 'newSelected', and reset 'newUncombined' to 'initSelected'
        newSelected = [];
        newUncombined = [...initSelected];
      } else if (typeof clickedId === 'string') {
        if (newSelected.includes(clickedId)) {
          // remove this item from the list of selected items
          newSelected = newSelected.filter((id: string): boolean => id !== clickedId);
          // only add item to uncombined list if the item was one of the original items
          if (initSelected.includes(clickedId) && !newUncombined.includes(clickedId)) newUncombined.push(clickedId);
          // if a non-selected item is clicked
        } else {
          // add the item to the list of selected items
          newSelected.push(clickedId);
          // only remove this item from uncombined list if it was one of the original items
          if (initSelected.includes(clickedId)) newUncombined = newUncombined.filter((id: string): boolean => id !== clickedId);
        }
      }

      return {
        ...current,
        selected: [...newSelected],
        unCombined: [...newUncombined],
      };
    });
  };

  const getCombinableTrips = async (): Promise<void> => {
    const fn = lastTripId.current === tripId ? refetchCombinableTrips : searchCombinableTrips;
    lastTripId.current = tripId;
    const response = await fn([{ id: tripId }]);
    const combinedRows = (response?.rows || [])?.filter((node: Trip): boolean => (node.combineId || '') === combineId) || [];
    if (combinedRows.length) {
      setState(
        (current: State): State => ({
          ...current,
          selected: [...(current?.selected || []), ...(combinedRows?.map?.((node: Trip): string => node.id) || [])],
          initSelected: [...(current?.selected || []), ...(combinedRows?.map?.((node: Trip): string => node.id) || [])],
        })
      );
    }
  };

  useEffect((): void => {
    if (!tripId) return;
    getCombinableTrips();
  }, [tripId]);

  const scheduled = state?.scheduled ? new Datetime(state?.scheduled).frontendDatetimeShort : '';
  const airlineCode = (state?.servicerIataAirlineCode || '').toUpperCase();
  const flightNumber = state?.flightNumber ? state?.flightNumber.toString().padStart(4, '0') : '';
  const title = `Combine Schedule - ${airlineCode}${flightNumber} ${scheduled}`;
  const icon = 'sv sv-combine';
  const options = {
    footer: {
      submitButtonText: 'Confirm',
    },
  };

  return (
    <EditModal className="editCombine" show={!!show} title={title} icon={icon} options={options} onHide={onHide} onSubmit={onSubmit}>
      <VirtualTable
        header={{
          flightNumber: 'FLT',
          scheduled: 'TIME',
          pilots: 'PLT',
          attendants: 'FA',
        }}
        data={rows}
        selected={selected}
        loading={loading}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
          >
            <SelectCell onClick={onSetCombine} />
            <DynamicCell selector="flightNumber" className="text-center" width="calc(100% / 4)" />
            <DynamicCell
              selector="scheduled"
              className="text-center"
              width="calc(100% / 4)"
              render={({ value }: { value: string }): string => new Datetime(value).time}
            />
            <DynamicCell selector="pilots" className="text-center" width="calc(100% / 4)" />
            <DynamicCell selector="attendants" className="text-center" width="calc(100% / 4)" />
          </VirtualTableRow>
        )}
        dynamic
      />
      <div className="mt-3">
        <span>
          {selected.length || 0} of {totalCount || 0} trips selected
        </span>
      </div>
    </EditModal>
  );
};

export default EditCombine;

import { FilterOption } from '@/models/gen/graphql';
import { GetAirportsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetAirportsGraphApiResponse = GraphApiResponse<typeof GetAirportsDocument>;

const getAirportsResponseSelector = (res: GetAirportsGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.airports as Array<FilterOption>;

const [runGetAirports] = graphApi(GetAirportsDocument, {
  onError: (res: GetAirportsGraphApiResponse): void => handleError(res, { notification: { title: 'Get Airports' } }),
});

export const getAirports = async (): Promise<Array<FilterOption>> => {
  const res = await runGetAirports();
  return getAirportsResponseSelector(res);
};

export const useGetAirports = createGraphApiHook(getAirports);

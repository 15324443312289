import { CreateVehicleBulkInput, CreateVehicleInput, Vehicle } from '../../models/gen/graphql';
import { Validation, customCreateHandler } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const createVehicleValidator = new Validation.Validator({
  year: (val: number): Validation.Validity => ({
    valid: !!val && Validation.isNumber(val) ? true : undefined,
  }),
  tollTag: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  stateRegistered: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  registrationExpirationDate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  phoneNumber: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isNumeric(val) ? true : val?.length ? false : undefined,
  }),
  model: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  make: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  licensePlate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  emissionPermitExpirationDate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  airportPermitExpirationDate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  'locationId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    message: !Validation.isValidUUID(val) ? 'Please select location.' : undefined,
  }),
  'active!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'capacity!': (val: string): Validation.Validity => ({
    valid: Validation.isNumber(parseInt(val)) && parseInt(val) > 0,
  }),
  'vin!': (val: string): Validation.Validity => ({
    valid: val?.length === 17,
    message: val?.length !== 17 ? 'Please enter a valid vin number.' : undefined,
  }),
  'trackingId!': (val: number): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val),
  }),
  'tracker!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val),
  }),
  'airportCodes!': (val: string[]): Validation.Validity => ({
    valid: val?.length > 0,
  }),
});

const createVehicleBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...vehicles: Vehicle[]): Promise<any> => {
      const values: CreateVehicleInput[] = vehicles.map((vehicle: Vehicle): CreateVehicleInput => createVehicleValidator.create(vehicle));
      const input: CreateVehicleBulkInput = {
        values,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.createVehicleBulk?.output || [],
  handler: customCreateHandler('Create Vehicle', 'createVehicleBulk'),
  query: gql`
    mutation CreateVehicleBulk($input: CreateVehicleBulkInput!) {
      createVehicleBulk(input: $input) {
        output {
          error
          node {
            # active
            # airportPermitExpirationDate
            # capacity
            # createdAt
            # createdBy
            # emissionPermitExpirationDate
            id
            # licensePlate
            # location {
            #   active
            #   address
            #   aliases {
            #     createdAt
            #     createdBy
            #     id
            #     locationId
            #     name
            #     updatedAt
            #     updatedBy
            #   }
            #   cityName
            #   coordinates {
            #     lat
            #     lng
            #   }
            #   countryCode
            #   createdAt
            #   createdBy
            #   deletedAt
            #   deletedBy
            #   id
            #   name
            #   phoneNumber
            #   pickupPoints {
            #     createdAt
            #     createdBy
            #     endDatetime
            #     id
            #     images {
            #       createdAt
            #       createdBy
            #       id
            #       path
            #       pickupPointId
            #       updatedAt
            #       updatedBy
            #     }
            #     instructions
            #     keyword
            #     locationId
            #     providerId
            #     startDatetime
            #     updatedAt
            #     updatedBy
            #   }
            #   stateCode
            #   traceId
            #   type
            #   updatedAt
            #   updatedBy
            #   zipCode
            # }
            # locationId
            # make
            # model
            # phoneNumber
            # registrationExpirationDate
            # stateRegistered
            # tollTag
            # tracker
            # trackingId
            # updatedAt
            # updatedBy
            # vin
            # year
          }
        }
      }
    }
  `,
};

export default createVehicleBulk;
export { createVehicleValidator };

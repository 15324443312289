import React, { useMemo } from 'react';

import Select from '../Select';
import { Validation } from '../../utils';

interface SelectIntProps {
  value: string | number | boolean;
  onChange: Function;
  children: any;
  [x: string]: any;
}

const SelectInt = ({ value, onChange, children, multiple, ...props }: SelectIntProps): JSX.Element => {
  const handleChange = (event: any): void => {
    const { name, value } = event.target || {};
    const parsedValue = parseInt(value);
    const formatted = Validation.isNumber(parsedValue) ? parsedValue : '';
    onChange({ target: { name, value: formatted } });
  };

  const parsedValue = useMemo((): string => convertToString(value), [value]);

  return (
    <Select {...props} value={parsedValue} onChange={handleChange}>
      {children.map(
        (node: any, n: number): JSX.Element => (
          <option key={n} {...node.props} value={node.props.value.toString()} />
        )
      )}
    </Select>
  );
};

// TODO: handle parsing bulk values
const convertToString = (val: string | number | boolean): string => {
  switch (typeof val) {
    case 'boolean':
      return val ? '1' : '0';
    case 'number':
      return Validation.isNumber(val) ? `${val}` : '';
    default:
      return val || '';
  }
};

export default SelectInt;

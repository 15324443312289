export const randomInt = (max: number, min: number = 0): number => Math.floor(Math.random() * (max - min + 1) + min);
export const formatNumber = ((): any => {
  const fn = (amount: number = 0, options: any = {}): string => {
    const { locale = 'en-US', ...optionsObj } = options;
    return new Intl.NumberFormat(locale, optionsObj).format(amount);
  };
  fn.currency = (amount: number, options: any = {}): string => formatNumber(amount, { currency: 'USD', ...options, style: 'currency' });
  return fn;
})();
export const getHoursAndMinutesFromMinutes = (minutes: number): [number, number] => [Math.floor(minutes / 60), minutes % 60];
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const formatDollars = (input: string): string => USDollar.format(parseFloat(input)).replace(/\$/g, '');
export const minMax = (value: number, min: number, max: number): number => {
  const result = Math.max(Math.min(value, max), min);
  if (isNaN(result)) return null;
  return result;
};
export const zeroPadFlightNumber = (input: number): string => `${input}`.padStart(4, '0');

import { useRef } from 'react';
import { uuid } from '../utils';

/* useUuid
  A simple ref containing a unique ID generated on
  mount. This is designed to be used whenever a
  component requires a unique key, or identifier.
*/

const useUuid = () => {
  const uniqueId = useRef(uuid());
  return uniqueId.current;
};

export default useUuid;

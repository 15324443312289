import React from 'react';
import { STATUSES } from '../../constants';
import SelectInt from 'components/SelectInt';

type Props = {
  statusType?: 'default' | 'repeat';
  value: string | number | boolean;
  onChange: Function;
  [x: string]: any;
};

const SelectActiveStatus = ({ statusType = 'default', value, onChange, ...props }: Props): JSX.Element => {
  return (
    <SelectInt value={value} onChange={onChange} {...props}>
      {(STATUSES?.[statusType] || []).map(
        (node: { id: number; displayName: string }, n: number): JSX.Element => (
          <option key={n} value={node.id}>
            {node.displayName}
          </option>
        )
      )}
    </SelectInt>
  );
};

export default SelectActiveStatus;

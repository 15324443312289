import { DownloadInvoiceResponse, RunDownloadLatestInvoiceDocumentInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { RunDownloadLatestInvoiceDocumentDocument } from '@/api/queries';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type RunDownloadLatestInvoiceDocumentGraphApiResponse = GraphApiResponse<typeof RunDownloadLatestInvoiceDocumentDocument>;

const title = 'Download Invoice';

const runDownloadLatestInvoiceDocumentResponseSelector = (
  res: RunDownloadLatestInvoiceDocumentGraphApiResponse
): DownloadInvoiceResponse => (!res.errors ? res?.runDownloadLatestInvoiceDocument?.output : undefined);

const [runRunDownloadLatestInvoiceDocument] = graphApi(RunDownloadLatestInvoiceDocumentDocument, {
  onError: (res: RunDownloadLatestInvoiceDocumentGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const runDownloadLatestInvoiceDocument = async (invoiceId: number): Promise<DownloadInvoiceResponse> => {
  const input: RunDownloadLatestInvoiceDocumentInput = { invoiceId };
  const res = await runRunDownloadLatestInvoiceDocument({ input });

  const selected = runDownloadLatestInvoiceDocumentResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useRunDownloadLatestInvoiceDocument = createGraphApiHook(runDownloadLatestInvoiceDocument);

export default runDownloadLatestInvoiceDocument;

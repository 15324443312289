import FormButton, { FormButtonProps } from '../FormButton';

import { Confirmation } from '../../hooks/useConfirmation';
import React from 'react';
import useClassNames from '../../hooks/useClassNames';
import useConfirmation from '../../hooks/useConfirmation';

export type ConfirmationButtonProps = {
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  options?: {
    confirmation?: Confirmation.Options;
  };
} & FormButtonProps;

const initOptions: {
  confirmation?: Confirmation.Options;
} = {
  confirmation: {
    Body: {
      message: 'Are you sure?',
    },
  },
};

const ConfirmationButton = ({
  onConfirm,
  onCancel = (): void => {},
  options = initOptions,
  name,
  ...props
}: ConfirmationButtonProps): JSX.Element => {
  const confirmClick = useConfirmation(options?.confirmation);
  const classNames = useClassNames('ConfirmationButton', `${props?.disabled ? 'disabled' : ''}`, props?.className);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    confirmClick()
      .then((): void => onConfirm(event))
      .catch((): void => onCancel(event));
  };

  return <FormButton {...props} name={name || 'CONFIRMATION'} className={classNames} onClick={props?.disabled ? undefined : handleClick} />;
};

export default ConfirmationButton;

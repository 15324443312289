import { Email, Provider } from '@/models/gen/graphql';

import Logger from '@/utils/logs';
import Toast from '@/models/Toast';
import createAirlineBulk from './createAirlineBulk';
import createEmailBulk from '@/api/services/providers/createEmailBulk';
import { createNotification } from '@/utils/custom';
import { getDiff } from '@/utils/objects';
import updateAirlineBulk from './updateAirlineBulk';
import { updateEmailOnProvider } from '@/api/services/providers';

const log = Logger.of('service/airlines');

// Create
export const createAirlineWithEmail = async (create: Partial<Provider>): Promise<void> => {
  try {
    const { emails = [], ...data } = create;
    const res = await createAirlineBulk([data]);

    if (!res) throw new Error('Something went wrong');
    if (!emails?.length) return createNotification('Success', Toast.Type.SUCCESS, 'Create Airline');
    const output = res?.output || [];

    //Emails Flow
    let createEmailsPayload: Email[] = [];

    for (let i = 0; i < output.length; i++) {
      const providerId = output[i]?.node?.id;
      if (!providerId) continue; // create provider should throw error if any item in the bulk create fails
      // Format Create Email Payload
      const temp: Email[] = (emails || []).map((node): Email => ({ ...node, providerId }));
      createEmailsPayload = [...createEmailsPayload, ...temp];
    }

    if (createEmailsPayload.length > 0) await createEmailBulk(createEmailsPayload);
    createNotification('Success', Toast.Type.SUCCESS, 'Create Airline');
  } catch (err) {
    const message = err.message || err;
    log.error('createAirlineWithEmail', message).notify({ title: 'Create Airline', message });
  }
};

// Update
export const updateAirlineWithEmail = async (payload: Partial<Provider>, selected: Provider[]): Promise<void> => {
  try {
    if (!payload) throw new Error('contact support: no payload with updates');
    if (!selected?.length) throw new Error('contact support: no selected airline to update');

    // extract emails from original and updated provider
    const { emails = [], ...update } = payload;
    const { emails: originalEmails = [], ...original } = selected.find((node: Provider): boolean => node?.id === update?.id);

    const [partial] = getDiff(original, update);
    const airlineChanged = !!Object.values(partial).length;
    // update selected providers
    if (airlineChanged) {
      const bundle = Object.values(selected).map(({ id }: Provider): Provider => ({ ...partial, id }));
      await updateAirlineBulk(bundle);
    }
    // We want don't want to modify emails in bulk scenarios
    if (selected.length !== 1) return createNotification('Success', Toast.Type.SUCCESS, 'Update Airline');

    const emailChanged = await updateEmailOnProvider(emails, originalEmails, original?.id);

    if (!airlineChanged && !emailChanged) return createNotification('No changes made.', Toast.Type.WARNING, 'Update Airline');
    createNotification('Success', Toast.Type.SUCCESS, 'Update Airline');
  } catch (err) {
    const message = err.message || err;
    log.error('updateAirlineWithEmail', message).notify({ title: 'Update Airline', message });
  }
};

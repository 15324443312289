import './styles.scss';

import Input, { InputProps } from '@/components/Input';
import React, { ReactNode, useMemo } from 'react';

import { InputGroup } from 'react-bootstrap';

export type NumberInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  value: number;
  onChange: (value: number) => void;
  //TODO: change styles to work without needing the append prop
  append?: React.ReactNode;
};
const NumberInput = ({ value, onChange, append, ...props }: NumberInputProps): ReactNode => {
  // TODO: Add logic to handle negative numbers.
  const parsedValue = useMemo((): number => (!isNaN(parseFloat(`${value}`)) ? parseFloat(`${value}`) : undefined), [value]);
  const handleChange = (val: string): void => {
    val.replace(/[^0-9.-]/g, '').replace(/([0-9.-]{1,})-/g, '$1');
    const result = val === '' ? undefined : !isNaN(parseFloat(val)) ? parseFloat(val) : parsedValue;
    onChange(result);
  };

  return (
    <InputGroup className="NumberInput">
      <Input {...props} value={parsedValue ?? ''} onChange={handleChange} />
      <div className="NumberInput-Controls">
        <button onClick={(): void => onChange((parsedValue || 0) + 1)} tabIndex={-1}>
          <i className="fa fa-angle-up" />
        </button>
        <button onClick={(): void => onChange((parsedValue || 0) - 1)} tabIndex={-1}>
          <i className="fa fa-angle-down" />
        </button>
      </div>
      {append && <div className="NumberInput-Append">{append}</div>}
    </InputGroup>
  );
};

// TODO: Add a NumberInputField named export that wraps the NumberInput in a Field component.

export default NumberInput;

import { DeleteGroupBulkInput, DeleteGroupBulkResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { DeleteGroupBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type DeleteGroupBulkGraphApiResponse = GraphApiResponse<typeof DeleteGroupBulkDocument>;

const title = 'Delete Groups';

const deleteGroupBulkResponseSelector = (res: DeleteGroupBulkGraphApiResponse): DeleteGroupBulkResponse =>
  !res.errors ? res?.deleteGroupBulk : undefined;

const [runDeleteGroupBulk] = graphApi(DeleteGroupBulkDocument, {
  onError: (res: DeleteGroupBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const deleteGroupBulk = async (ids: string[]): Promise<DeleteGroupBulkResponse> => {
  const input: DeleteGroupBulkInput = {
    search: ids.map((id: string): { id: string } => ({ id })),
  };
  const res = await runDeleteGroupBulk({ input });

  const selected = deleteGroupBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useDeleteGroupBulk = createGraphApiHook(deleteGroupBulk);

export default deleteGroupBulk;

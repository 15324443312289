// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExtraPanel {
  border-radius: 0px 0px 24px 10px;
  background: #e9e9e9;
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.25);
  height: 90%;
}
.ExtraPanel ul {
  padding: 0;
  overflow: auto;
  border: 1px solid #ccc;
  padding: 0.25rem;
  border-radius: 4px;
  height: 100%;
}
.ExtraPanel ul > li {
  border: 1px solid #ccc;
  min-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  background-color: #fff;
}
.ExtraPanel ul > li + li {
  border-top: 0;
}

.rdt_ExpanderRow {
  max-height: 50vh;
  max-width: calc(100vw - 3rem);
  overflow: auto;
  padding: 0 5rem 1rem;
}
.rdt_ExpanderRow > div {
  max-height: 90%;
}

.ConfirmTripRates .VirtualTable .VirtualTable-Select input[type=checkbox]:checked:before {
  content: "\\e92a";
  color: #fff;
  background: #dc3545;
  padding: 0.25rem;
  font-size: 0.5rem;
  border-radius: 2px;
}
.ConfirmTripRates .VirtualTable .VirtualTable-Row.selected {
  background-color: var(--vt-row-color-late);
}

.RatesTable > .VirtualTable-Header .VirtualTable-Select {
  background-color: var(--bs-secondary-subtle) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Rates/styles.scss","webpack://./src/globals.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,mBAAA;EACA,kDAAA;EACA,WAAA;AADF;AAGE;EACE,UAAA;EACA,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AADJ;AAIE;EACE,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;AAFJ;AAII;EACE,aAAA;AAFN;;AAOA;EACE,gBAAA;EACA,6BAAA;EACA,cAAA;EACA,oBAAA;AAJF;AAME;EACE,eAAA;AAJJ;;AAWM;EACE,gBAAA;EACA,WAAA;EACA,mBCnBO;EDoBP,gBAAA;EACA,iBAAA;EACA,kBAAA;AARR;AAYI;EACE,0CAAA;AAVN;;AAgBE;EACE,uDAAA;AAbJ","sourcesContent":[".ExtraPanel {\n  border-radius: 0px 0px 24px 10px;\n  background: #e9e9e9;\n  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.25);\n  height: 90%;\n}\n.ExtraPanel ul {\n  padding: 0;\n  overflow: auto;\n  border: 1px solid #ccc;\n  padding: 0.25rem;\n  border-radius: 4px;\n  height: 100%;\n}\n.ExtraPanel ul > li {\n  border: 1px solid #ccc;\n  min-height: 1.25rem;\n  padding: 0.25rem 0.5rem;\n  border-radius: 6px;\n  background-color: #fff;\n}\n.ExtraPanel ul > li + li {\n  border-top: 0;\n}\n\n.rdt_ExpanderRow {\n  max-height: 50vh;\n  max-width: calc(100vw - 3rem);\n  overflow: auto;\n  padding: 0 5rem 1rem;\n}\n.rdt_ExpanderRow > div {\n  max-height: 90%;\n}\n\n.ConfirmTripRates .VirtualTable .VirtualTable-Select input[type=checkbox]:checked:before {\n  content: \"\\e92a\";\n  color: #fff;\n  background: #dc3545;\n  padding: 0.25rem;\n  font-size: 0.5rem;\n  border-radius: 2px;\n}\n.ConfirmTripRates .VirtualTable .VirtualTable-Row.selected {\n  background-color: var(--vt-row-color-late);\n}\n\n.RatesTable > .VirtualTable-Header .VirtualTable-Select {\n  background-color: var(--bs-secondary-subtle) !important;\n}",null],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

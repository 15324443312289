import { EventObject, OnChange } from '../../hooks/useOnChange';

import { Button } from 'react-bootstrap';
import CoordinatesInput from '../CoordinatesInput';
import FormField from '../FormField';
import { PickupPoint } from '../../models/gen/graphql';
import React from 'react';
import SelectImportProvider from '../SelectImportProvider';
import Styles from '../../models/Styles';
import TimeRangeInput from '../TimeInput/TimeRangeInput';
import TippyWhen from '../TippyWhen';
import { getClasses } from '../../utils/strings';

type PickupPointProps = {
  name: string;
  value: Partial<PickupPoint>;
  variant?: Styles.ColorVariant;
  className?: string;
  disabled?: {
    providerId?: boolean;
    point?: boolean;
    dateRange?: boolean;
    imageUpload?: boolean;
  };
  onChange: OnChange;
  onUploadClick?: () => void;
  onMarkerClick?: () => void;
  provider?: boolean;
  point?: boolean;
  dateRange?: boolean;
  imageUpload?: boolean;
};

const PickupPointInput = ({
  name,
  value,
  onChange,
  onUploadClick,
  onMarkerClick,
  variant = 'dark',
  className,
  disabled,
  provider,
  point,
  dateRange,
  imageUpload,
}: PickupPointProps): React.JSX.Element => {
  const { coordinates } = value;
  const handleChange = (event: EventObject): void => {
    const { name: inputName, value: inputValue } = event.target;
    if (inputName === 'coordinates') {
      const { radius, ...coordinates } = inputValue;
      return onChange({ target: { name, value: { ...value, coordinates, radius } } });
    }
    if (inputName === 'dateRange') {
      const { startDatetime, endDatetime } = inputValue;
      return onChange({
        target: { name, value: { ...value, startDatetime, endDatetime } },
      });
    }
    onChange({ target: { name, value: { ...value, [inputName]: inputValue } } });
  };

  return (
    <div className={getClasses('PickupPoint d-flex align-items-end justify-content-center {margin:0|0.6rem;}>*', className)}>
      <TippyWhen isTrue={!!onMarkerClick} options={{ content: 'Click to focus.' }}>
        <i
          className={`fa-solid fa-location-dot text-${variant} {font-size:2rem;transform:translateY(-0.2rem);${
            onMarkerClick ? 'cursor:pointer;' : ''
          }}`}
          onClick={onMarkerClick}
        />
      </TippyWhen>
      {(provider !== undefined || !!provider) && (
        <FormField
          name="providerId"
          label="Airline:"
          value={value?.providerId}
          onChange={handleChange}
          placeholder="Airline"
          options={{
            group: { className: '{max-width:150px;}' },
            input: { as: (props) => <SelectImportProvider {...props} searchable /> },
          }}
          condensed
          disabled={disabled?.providerId}
        />
      )}
      {(point !== undefined || !!point) && (
        <CoordinatesInput
          value={{ lat: coordinates?.lat, lng: coordinates?.lng, radius: value?.radius }}
          onChange={handleChange}
          name={'coordinates'}
          radius
          disabled={disabled?.point}
        />
      )}
      {(dateRange !== undefined || !!dateRange) && (
        <TimeRangeInput
          name="dateRange"
          onChange={handleChange}
          value={{ startDatetime: value?.startDatetime, endDatetime: value?.endDatetime }}
          className="d-flex gap-3 {min-width:15rem}"
          disabled={disabled?.dateRange}
        />
      )}
      {(imageUpload !== undefined || !!imageUpload) && (
        <Button
          variant="outline-primary"
          className="text-nowrap"
          name="UPLOAD"
          onClick={onUploadClick}
          disabled={disabled?.imageUpload || !onUploadClick}
        >
          Upload <i className="sv sv-picture" />
        </Button>
      )}
    </div>
  );
};

export default React.memo(PickupPointInput);

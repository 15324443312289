import { Card } from 'react-bootstrap';
import React from 'react';

const GlassContainer = (props: any): JSX.Element => (
  <Card
    {...props}
    className={`
      bg-glass
      rounded-sm
      shadow
      p-4
      {border-radius:2.5rem!;}
      ${props.className || ''}
    `}
  />
);

export default GlassContainer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar {
  min-width: 350px;
  padding: 0.7rem;
  background: white;
  box-shadow: 4px 5px 10px 5px rgba(75, 75, 75, 0.181);
}
.Calendar .Calendar-Header {
  padding: 0.2rem 1rem;
}
.Calendar .Calendar-Header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Calendar .Calendar-Body {
  padding: 0.5rem 0rem;
}
.Calendar .Calendar-Body .Calendar-Date {
  margin: 0.5rem;
  cursor: pointer;
}
.Calendar .Calendar-Body .Calendar-Date .day {
  margin-top: 0.2rem;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Calendar .Calendar-Body .Calendar-Date .before {
  background: #f5f6f8;
  color: #b7b7b7;
}
.Calendar .Calendar-Body .Calendar-Date .today {
  border: 1px solid rgba(0, 0, 0, 0.236);
}
.Calendar .Calendar-Body .Calendar-Date .selected {
  background: #1677ff;
  color: white;
}
.Calendar .Calendar-Body .Calendar-Date .range-start {
  background: green;
  color: white;
}
.Calendar .Calendar-Body .Calendar-Date .range-end {
  background: green;
  color: white;
}
.Calendar .Calendar-Body .Calendar-Date .range {
  background: rgba(0, 0, 0, 0.407);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Calendar/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,oDAAA;AACF;AAAE;EACE,oBAAA;AAEJ;AADI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAGN;AAAE;EACE,oBAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;AAGN;AADM;EACE,kBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAGR;AADM;EACE,mBAAA;EACA,cAAA;AAGR;AADM;EACE,sCAAA;AAGR;AADM;EACE,mBAAA;EACA,YAAA;AAGR;AADM;EACE,iBAAA;EACA,YAAA;AAGR;AADM;EACE,iBAAA;EACA,YAAA;AAGR;AADM;EACE,gCAAA;EACA,YAAA;AAGR","sourcesContent":[".Calendar {\n  min-width: 350px;\n  padding: 0.7rem;\n  background: white;\n  box-shadow: 4px 5px 10px 5px rgba(75, 75, 75, 0.181);\n}\n.Calendar .Calendar-Header {\n  padding: 0.2rem 1rem;\n}\n.Calendar .Calendar-Header div {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.Calendar .Calendar-Body {\n  padding: 0.5rem 0rem;\n}\n.Calendar .Calendar-Body .Calendar-Date {\n  margin: 0.5rem;\n  cursor: pointer;\n}\n.Calendar .Calendar-Body .Calendar-Date .day {\n  margin-top: 0.2rem;\n  height: 1.7rem;\n  width: 1.7rem;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.Calendar .Calendar-Body .Calendar-Date .before {\n  background: #f5f6f8;\n  color: #b7b7b7;\n}\n.Calendar .Calendar-Body .Calendar-Date .today {\n  border: 1px solid rgba(0, 0, 0, 0.236);\n}\n.Calendar .Calendar-Body .Calendar-Date .selected {\n  background: #1677ff;\n  color: white;\n}\n.Calendar .Calendar-Body .Calendar-Date .range-start {\n  background: green;\n  color: white;\n}\n.Calendar .Calendar-Body .Calendar-Date .range-end {\n  background: green;\n  color: white;\n}\n.Calendar .Calendar-Body .Calendar-Date .range {\n  background: rgba(0, 0, 0, 0.407);\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatList {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden auto;
  /* Hide Chevron Indicators On Bootstrap*/
}
.ChatList .accordion-button::after {
  background-image: initial;
}
.ChatList .accordion-button:not(.collapsed)::after {
  background-image: initial;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatList/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;EACA,wCAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".ChatList {\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n  overflow: hidden auto;\n  /* Hide Chevron Indicators On Bootstrap*/\n}\n.ChatList .accordion-button::after {\n  background-image: initial;\n}\n.ChatList .accordion-button:not(.collapsed)::after {\n  background-image: initial;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { TripTableFormatEnum } from 'models/gen/graphql';
import generatePageReducer from './pageReducer';
import storage from 'redux-persist/lib/storage';

const [tripsSlice, useTripsState, tripsState] = generatePageReducer('trips', {
  initialState: {
    filters: {
      airportCode: [],
      payerProviderId: '',
      doLocationId: [],
      expanded: false,
      flightNumber: '',
      from: '',
      loading: false,
      puLocationId: [],
      format: TripTableFormatEnum.Current,
      time: undefined,
      to: '',
      type: [],
    },
  },
  customActions: [],
  persistConfig: {
    key: 'trips',
    version: 1,
    storage,
    blacklist: [],
  },
});

export { useTripsState, tripsState };
export default tripsSlice.reducer;

import './styles.scss';

import {
  Children,
  Dispatch,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getClasses } from '@/utils';

export type GridProps = React.ComponentPropsWithRef<'div'> & { columns?: string[] };
const initGridState = {
  rows: 0,
  columns: 0,
};
export const GridContext = createContext([initGridState, (): void => {}] as [GridState, Dispatch<SetStateAction<GridState>>]);
export type GridState = typeof initGridState & {};

const Grid = ({ columns = [], children, ...divProps }: GridProps, ref: React.ForwardedRef<HTMLDivElement>): ReactNode => {
  const [state, setState] = useState<GridState>(initGridState);

  const gridContext = useMemo((): [GridState, Dispatch<SetStateAction<GridState>>] => [state, setState], [state, setState]);
  const gridChildren = useMemo((): ReactNode => {
    setState((current: GridState): GridState => ({ ...current, rows: (Array.isArray(children) ? children : []).length }));
    return children;
  }, [children]);
  const gridTemplateColumns = useMemo(
    (): string =>
      Array(Math.max(columns.length, state.columns))
        .fill('1fr')
        .map((fr: string, index: number): string => columns[index] || fr)
        .join(' '),
    [columns, state.columns]
  );

  return (
    <GridContext.Provider value={gridContext}>
      <div {...divProps} className={getClasses('Grid', divProps?.className)} style={{ ...divProps?.style, gridTemplateColumns }} ref={ref}>
        {gridChildren}
      </div>
    </GridContext.Provider>
  );
};

export type GridRowProps = { className?: string; style?: React.CSSProperties; children: ReactNode };
export const GridRow = ({ children }: GridRowProps): ReactNode => {
  const [state, setState] = useContext(GridContext);
  const columnCount = useMemo(
    (): number =>
      (Array.isArray(children) ? children : [children]).reduce(
        (acc: number, child: ReactElement): number => acc + (child.props?.span || 1),
        0
      ),
    [children]
  );

  const gridRowChildren = useMemo((): ReactNode => {
    const missingColumns = state.columns - columnCount;
    const emptyDivs = Array.from({ length: missingColumns }, (_: unknown, index: number): ReactNode => <GridCell key={index} />);
    return [...Children.toArray(children), ...emptyDivs];
  }, [children, columnCount, state.columns]);

  useEffect((): void => {
    if (columnCount <= state.columns) return;
    setState((current: GridState): GridState => ({ ...current, columns: Math.max(current.columns, columnCount) }));
  }, [columnCount, setState, state.columns]);

  return gridRowChildren;
};

const getGridColumnFromSpan = (span: number): string =>
  [span !== undefined ? `span ${span}` : undefined].filter((x: string): boolean => !!x).join(' / ') || undefined;
export type GridCellProps = HTMLAttributes<HTMLDivElement> & { span?: number; index?: number };
export const GridCell = ({ span, className = '', ...divProps }: GridCellProps): ReactNode => {
  const gridColumn = useMemo((): string => getGridColumnFromSpan(span), [span]);

  return <div {...divProps} className={`Grid-Cell ${className}`} style={{ ...(divProps?.style || {}), gridColumn }} />;
};

export default forwardRef(Grid);

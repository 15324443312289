import { FilterOption } from '@/models/gen/graphql';
import { GetLineItemTypesDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetLineItemTypesGraphApiResponse = GraphApiResponse<typeof GetLineItemTypesDocument>;

const getLineItemTypesResponseSelector = (res: GetLineItemTypesGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.lineItemType as Array<FilterOption>;

const [runGetLineItemTypes] = graphApi(GetLineItemTypesDocument, {
  onError: (res: GetLineItemTypesGraphApiResponse): void => handleError(res, { notification: { title: 'Get Line Item Types' } }),
});

export const getLineItemTypes = async (): Promise<Array<FilterOption>> => {
  const res = await runGetLineItemTypes();
  return getLineItemTypesResponseSelector(res);
};

export const useGetLineItemTypes = createGraphApiHook(getLineItemTypes);

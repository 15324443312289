import { CreateImportInput, CreateImportsInput, Import, ImportResponse } from '../../../models/gen/graphql';

import { CreateImportsDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';

type CreateImportsGraphApiResponse = GraphApiResponse<typeof CreateImportsDocument>;

const title = 'Create Imports';

const createImportsResponseSelector = (res: CreateImportsGraphApiResponse): Import[] =>
  !res.errors ? res?.createImports?.imports.map((node: ImportResponse): Import => node?.import) : undefined;

const [runCreateImports] = graphApi(CreateImportsDocument, {
  onError: (res: CreateImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createImports = async (imports: CreateImportInput[]): Promise<Import[]> => {
  const input: CreateImportsInput = {
    imports,
  };

  const res = await runCreateImports({ input });
  const selected = createImportsResponseSelector(res);

  return selected;
};

export const useCreateImports = createGraphApiHook(createImports);

export default createImports;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Collapsible .Collapsible-Header {
  font-weight: 600;
  font-size: 14px;
}
.Collapsible .Collapsible-Body {
  max-height: 155px;
  overflow: auto;
}
.Collapsible .full {
  max-height: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Collapsible/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,eAAA;AAAJ;AAEE;EACE,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".Collapsible .Collapsible-Header {\n  font-weight: 600;\n  font-size: 14px;\n}\n.Collapsible .Collapsible-Body {\n  max-height: 155px;\n  overflow: auto;\n}\n.Collapsible .full {\n  max-height: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

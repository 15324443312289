import './styles.scss';

import LocationsTable from './LocationsTable';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import ScrollableContainer from '../../components/ScrollableContainer';

const Locations = () => (
  <ProtectedRoute title="Locations">
    <ScrollableContainer name="Locations" className="page-container">
      <LocationsTable />
    </ScrollableContainer>
  </ProtectedRoute>
);

export default Locations;

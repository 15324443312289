import {
  CreateGroupHasPermissionInput,
  CreatePermissionHasRoleInput,
  DeleteGroupHasPermissionInput,
  DeletePermissionHasRoleInput,
} from '../../../models/gen/graphql';

import Logger from '../../../utils/logs';
import { Validation } from '../../../utils/validations';
import createGroupHasPermissionBulk from './createGroupHasPermission';
import createPermissionHasRoleBulk from './createPermissionHasRole';
import deleteGroupHasPermissionBulk from './deleteGroupHasPermissionBulk';
import deletePermissionHasRoleBulk from './deletePermissionHasRoleBulk';

const log = Logger.of('permissions/service');

export const updateRolePermissions = async (data: { creates: string[]; deletes: string[] }, roleId: string): Promise<void> => {
  try {
    const { creates = [], deletes = [] } = data || {};

    if (!Validation.isValidUUID(roleId)) throw new Error('contact support: no role to apply permissions selected');

    if (creates.length > 0) {
      const createPayload = creates.map((permissionId: string): CreatePermissionHasRoleInput => ({ permissionId, roleId }));
      await createPermissionHasRoleBulk(createPayload);
    }
    if (deletes.length > 0) {
      const deletePayload = deletes.map((permissionId: string): DeletePermissionHasRoleInput => ({ permissionId, roleId }));
      await deletePermissionHasRoleBulk(deletePayload);
    }
  } catch (err) {
    const message = err?.message || err;
    log.error('handleSubmitRolePermissions', message).notify({ title: 'Update Permissions on Role', message });
  }
};

export const updateGroupPermissions = async (data: { creates: string[]; deletes: string[] }, groupId: string): Promise<void> => {
  try {
    const { creates = [], deletes = [] } = data || {};

    if (!Validation.isValidUUID(groupId)) throw new Error('contact support: no group to apply permissions selected');

    if (creates.length > 0) {
      const createPayload = creates.map((permissionId: string): CreateGroupHasPermissionInput => ({ permissionId, groupId }));
      await createGroupHasPermissionBulk(createPayload);
    }
    if (deletes.length > 0) {
      const deletePayload = deletes.map((permissionId: string): DeleteGroupHasPermissionInput => ({ permissionId, groupId }));
      await deleteGroupHasPermissionBulk(deletePayload);
    }
  } catch (err) {
    const message = err?.message || err;
    log.error('handleSubmitGroupPermissions', message).notify({ title: 'Update Permissions on Group', message });
  }
};

import { DeleteFcrBulkInput, DeleteFcrInput } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { customDeleteHandler } from '../../utils';
import gql from 'graphql-tag';

const deleteFcrBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...ids: string[]): Promise<any> => {
      const input: DeleteFcrBulkInput = {
        search: ids.map((id: string): DeleteFcrInput => ({ id })),
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): number => data?.deleteFcrBulk?.deleted || 0,
  handler: customDeleteHandler('Delete Fcr on Trip', 'FCR', 'deleteFcrBulk'),
  query: gql`
    mutation DeleteFcrBulk($input: DeleteFcrBulkInput!) {
      deleteFcrBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteFcrBulk;

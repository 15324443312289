import { Toast } from '../models';
import config from '../config.json';
import { createNotification } from './custom';

type LoggerType = 'log' | 'info' | 'debug' | 'trace' | 'table' | 'warn' | 'error';
type LoggerMethods = {
  notify: (options: NotificationMiddlewareOptions) => void;
};
type LoggerExecute = (...message: any[]) => LoggerMethods;
export type LoggerFunctions = {
  [K in LoggerType]?: LoggerExecute;
};
type ToastOptions = {
  [K in LoggerType]: Toast.Type;
};

interface NotificationMiddlewareOptions extends Toast.Options {
  title?: string;
  info?: string;
  message?: string;
}

/**
 * Refactored notificationMiddleware function
 *
 * @param {string} type - Optional parameter for notification type
 * @param {string} title - Optional parameter for notification title
 * @param {string[]} message - Array parameter for notification message
 * @param {object} options - Optional parameter for additional options
 * @returns {void}
 */
const notificationMiddleware = (
  type?: LoggerType,
  title?: string,
  message: any[] = [],
  options: NotificationMiddlewareOptions = {}
): void => {
  // making this var strongly typed so we're reminded to update it
  // if we add a new loggerType in the future
  const logToToastTypeMapping: ToastOptions = {
    log: Toast.Type.DEFAULT,
    info: Toast.Type.INFO,
    debug: Toast.Type.INFO,
    trace: Toast.Type.INFO,
    table: Toast.Type.INFO,
    warn: Toast.Type.WARNING,
    error: Toast.Type.DANGER,
  };
  const toastType: Toast.Type = logToToastTypeMapping[type || 'log'];
  const { message: optionalMessage, info, title: optionalTitle, ...otherOptions } = options;

  createNotification(optionalMessage || message.join('\n'), toastType, optionalTitle || title, info, otherOptions);
};

// get env value in a more reliable way
let env = 'local';
Object.entries(config.env || {}).forEach(
  ([name = '', domains = []]: [string, any]): string => (env = domains.includes(window.location.host) ? name : env)
);

/**
 * Define a loggerMiddleware function that takes in a type and an optional title as parameters
 * @param type - The type of the logger
 * @param title - The title of the logger (optional)
 * @returns A function that takes in a variable number of messages and returns an object with a notify method
 */
const loggerMiddleware = (type: LoggerType, title?: string): LoggerExecute => {
  return (...message: string[]): LoggerMethods => {
    // TODO: handle enable/disable log logic
    // temporary solution: check env to determine what is logged
    switch (env) {
      case 'local':
      case 'dev':
      case 'stage': {
        const whitelist = ['error', 'warn', 'log', 'debug'];
        if (whitelist.includes(type)) console[type](title || type.toUpperCase(), '|', ...message);
        break;
      }
      case 'prod': {
        const whitelist = ['error', 'warn'];
        if (whitelist.includes(type)) console[type](title || type.toUpperCase(), '|', ...message);
        break;
      }
    }
    return {
      notify: (options: NotificationMiddlewareOptions): void => {
        const notifyTitle = options?.title || title || type.toUpperCase();
        return notificationMiddleware(type, notifyTitle, message, options);
      },
    };
  };
};

const loggerTypes: LoggerType[] = ['info', 'debug', 'warn', 'error', 'trace', 'table'];
/**
 * Creates logger functions for logging different types of messages.
 *
 * @param title - The title of the logger.
 * @returns An object containing logger functions for different types of messages.
 */
const createLoggers = (title?: string): LoggerFunctions => {
  const result: LoggerFunctions = {
    log: loggerMiddleware('log', title),
  };
  for (const type of loggerTypes) {
    result[type] = loggerMiddleware(type, title);
  }
  return result;
};

const Logger = Object.freeze({
  ...createLoggers(),
  of: (title: string): LoggerFunctions => createLoggers(title),
});

export default Logger;

import {
  RunAssignVehicleToDriverBulkInput,
  RunAssignVehicleToDriverBulkResponse,
  RunAssignVehicleToDriverInput,
  RunAssignVehicleToDriverResponse,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { AssignVehicleToDriverDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import Toast from '../../../models/Toast';
import { Validation } from '../../../utils/validations';
import graphApi from '../..';

type RunAssignVehicleToDriverGraphApiResponse = GraphApiResponse<typeof AssignVehicleToDriverDocument>;

const title = 'Assign Vehicle To Driver';

export const assignVehicleToDriverValidator = new Validation.Validator({
  'scheduled!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val),
    defaultValue: '',
  }),
  'vehicleId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
  'tripId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
  'userId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
});

const runAssignVehicleToDriverResponseSelector = (res: RunAssignVehicleToDriverGraphApiResponse): RunAssignVehicleToDriverBulkResponse =>
  !res.errors ? ((res?.runAssignVehicleToDriverBulk || {}) as RunAssignVehicleToDriverBulkResponse) : undefined;

export const [runAssignVehicleToDriverBulk] = graphApi(AssignVehicleToDriverDocument, {
  onError: (res: any): void => handleError(res, { notification: { title } }),
});

const assignVehicleToDriverBulk = async (
  ...assignVehicleToDriverInputs: RunAssignVehicleToDriverInput[]
): Promise<RunAssignVehicleToDriverBulkResponse> => {
  const values: RunAssignVehicleToDriverInput[] = assignVehicleToDriverInputs.map(
    (assignVehicleToDriverInput: RunAssignVehicleToDriverInput): RunAssignVehicleToDriverInput =>
      assignVehicleToDriverValidator.create(assignVehicleToDriverInput)
  );
  const input: RunAssignVehicleToDriverBulkInput = {
    input: values,
  };
  const res = await runAssignVehicleToDriverBulk({ input });

  const selected = runAssignVehicleToDriverResponseSelector(res);
  const { output = [] } = selected || {};
  if (output.every(({ updated = 0 }: RunAssignVehicleToDriverResponse): boolean => updated > 0)) {
    createNotification('Assigned vehicle successfully.', Toast.Type.SUCCESS, title);
  } else {
    createNotification('Cound not assign vehicle.', Toast.Type.DANGER, title);
  }

  return selected;
};

export default assignVehicleToDriverBulk;

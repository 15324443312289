import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import React from 'react';
import TippyWhen from '../TippyWhen';
import TripActivity from 'pages/Trips/TripActivity';

interface EditTripsFooterProps {
  data: any;
  onAddStop: any;
  onSaveCopy: any;
  onHide: any;
  onSubmit: any;
  valid: boolean;
  showActivity: boolean;
}

const EditTripsFooter = ({ data, onAddStop, onSaveCopy, onSubmit, onHide, showActivity, valid }: EditTripsFooterProps): JSX.Element => {
  return (
    <Modal.Footer className="d-block {text-transform:uppercase!;font-size:0.8rem!;py:0!;text-align:right!;line-height:0.9rem;}_.input-group-text,label">
      <Row>
        <Col xs={12} sm={2}></Col>
        <Col xs={12} sm={5} className="text-center">
          <InputGroup>
            <InputGroup.Text className="invisible">{(data?.stops || []).length + 1}.</InputGroup.Text>
            <Button name="ADD_STOP" className="flex-grow-1" variant="outline-gray" onClick={onAddStop}>
              <i className="fa fa-plus-circle" /> Add Stop
            </Button>
          </InputGroup>
        </Col>
        <Col xs={12} sm={5} className="text-end">
          {onSaveCopy && (
            <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
              <span>
                <Button className="ms-2" variant="gray" onClick={onSaveCopy} disabled={!valid}>
                  Save Copy
                </Button>
              </span>
            </TippyWhen>
          )}
          <Button name="CANCEL" className="ms-2" variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
            <span>
              <Button name="SUBMIT" className="ms-2" variant="primary" onClick={onSubmit} disabled={!valid}>
                Save
              </Button>
            </span>
          </TippyWhen>
        </Col>
      </Row>
      {showActivity && (
        <Row className="px-3">
          <small className="mt-4">ACTIVITY:</small>
          <div className="bg-white rounded border height:200px">
            <div className="bg-white overflow-scroll p-2 mt-2 height:190px">
              <TripActivity list={data?.remarks} />
            </div>
          </div>
        </Row>
      )}
    </Modal.Footer>
  );
};

export default EditTripsFooter;

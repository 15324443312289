import { AcceptInputOption, VehicleMakeEnum } from '../../models/gen/graphql';
import React, { useEffect } from 'react';
import Select, { SelectProps } from '../Select';

import { useGetVehicleModels } from '../../api/services/filters/vehicles';

type Props = { make: VehicleMakeEnum } & SelectProps;
const SelectVehicleModel = ({ make, value, ...props }: Props): React.JSX.Element => {
  const [{ data, loading }, { fetch: getVehicleModels }] = useGetVehicleModels();

  const onSearch = async (): Promise<void> => {
    await getVehicleModels(make);
  };

  useEffect((): void => {
    if (!value?.length) return;
    onSearch();
  }, [make]);

  return (
    <Select placeholder="All Models" value={value} loading={loading} onFocus={onSearch} {...props}>
      {data?.map(
        ({ id, displayName }: AcceptInputOption, o: number): React.JSX.Element => (
          <option value={id} key={o}>
            {displayName}
          </option>
        )
      )}
    </Select>
  );
};

export default SelectVehicleModel;

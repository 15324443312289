import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Group, GroupConnection, GroupConnectionInput, GroupSearch } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchGroupsDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchGroupsGraphApiResponse = GraphApiResponse<typeof SearchGroupsDocument>;
export type SearchGroupsOptions = { pageSize?: number; page?: number; merge?: true };

export const SEARCH_GROUPS_PAGE_SIZE = 1000;
const title = 'Search Groups';

export const searchGroupsResponseSelector = (res: SearchGroupsGraphApiResponse): ConnectionDetails<Group> =>
  convertConnectionToDetails(res?.searchGroups?.groupConnection as GroupConnection);

const [runSearchGroups, runRefetchSearchGroups] = graphApi(SearchGroupsDocument, {
  onError: (res: SearchGroupsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchGroups = async (query?: GroupSearch[], options: SearchGroupsOptions = {}): Promise<ConnectionDetails<Group>> => {
  const { pageSize = SEARCH_GROUPS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: GroupConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchGroups.groupConnection' : undefined };

  const res = await runSearchGroups({ input }, graphApiOptions);

  return searchGroupsResponseSelector(res);
};

const refetchSearchGroups = async (query?: GroupSearch[], options: SearchGroupsOptions = {}): Promise<ConnectionDetails<Group>> => {
  const { pageSize = SEARCH_GROUPS_PAGE_SIZE, page = 0 } = options;

  const input: GroupConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchGroups({ input });

  return searchGroupsResponseSelector(res);
};

export const useSearchGroups = createGraphApiHook(searchGroups, {
  refetch: refetchSearchGroups,
  merge: mergeConnectionDetails,
});

export default searchGroups;

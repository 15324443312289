import './styles.scss';

import React, { ReactNode } from 'react';
import TimePicker, { FullTimeString, TimePickerProps } from '@/components/TimePicker';

import { getClasses } from '@/utils';

export type TimePickerRangeProps = Omit<TimePickerProps, 'value' | 'onChange'> & {
  value?: FullTimeString[];
  onChange?: (value: FullTimeString[]) => void;
};
const TimePickerRange = ({ value, onChange, isValid, isInvalid, isDirty, ref: _ref, ...props }: TimePickerRangeProps): ReactNode => {
  const [startTime, endTime] = value || [];
  const handleOnChange =
    (type: 'start' | 'end'): ((fullTimeString: FullTimeString) => void) =>
    (fullTimeString: FullTimeString): void =>
      onChange?.(type === 'start' ? [fullTimeString, endTime] : [startTime, fullTimeString]);

  return (
    <div
      className={getClasses(
        'TimePickerRange',
        isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined,
        isDirty ? 'is-dirty' : undefined,
        props?.className
      )}
    >
      <TimePicker {...props} suffixIcon={null} placeholder="Start" value={startTime} onChange={handleOnChange('start')} />
      <div className="TimePickerRange-Separator">
        <i className="fa fa-arrow-right-long" />
      </div>
      <TimePicker {...props} placeholder="End" value={endTime} onChange={handleOnChange('end')} />
    </div>
  );
};

export default TimePickerRange;

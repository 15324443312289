import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Provider, ProviderConnection, ProviderConnectionInput, ProviderSearch } from '../../../models/gen/graphql';
import {
  SearchAirlinesDocument,
  SearchAirlinesTableDocument,
  SearchClientsDocument,
  SearchClientsTableDocument,
  SearchProvidersDocument,
  SearchProvidersTableDocument,
} from '../../queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type SearchProvidersGraphApiResponse = GraphApiResponse<typeof SearchProvidersDocument>;
type SearchClientsGraphApiResponse = GraphApiResponse<typeof SearchClientsDocument>;
type SearchAirlinesGraphApiResponse = GraphApiResponse<typeof SearchAirlinesDocument>;
type SearchProvidersOptions = {
  pageSize?: number;
  page?: number;
  merge?: boolean;
};

export const SEARCH_PROVIDERS_PAGE_SIZE = 1000;

// Providers
const searchProvidersResponseSelector = (res: SearchProvidersGraphApiResponse): ConnectionDetails<Provider> =>
  convertConnectionToDetails(res?.searchProviders?.providerConnection as ProviderConnection);

const [runSearchProvidersTable, runRefetchSearchProvidersTable] = graphApi(SearchProvidersTableDocument, {
  onError: (res: SearchProvidersGraphApiResponse): void => handleError(res, { notification: { title: 'Search Providers' } }),
});
const [runSearchProviders, runRefetchSearchProviders] = graphApi(SearchProvidersDocument, {
  onError: (res: SearchProvidersGraphApiResponse): void => handleError(res, { notification: { title: 'Search Providers' } }),
});

const searchProviders = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.providerConnection' : undefined };
  const res = await runSearchProviders({ input }, graphApiOptions);
  return searchProvidersResponseSelector(res);
};

const refetchSearchProviders = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.providerConnection' : undefined };
  const res = await runRefetchSearchProviders({ input }, graphApiOptions);
  return searchProvidersResponseSelector(res);
};

const searchProvidersTable = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.providerConnection' : undefined };
  const res = await runSearchProvidersTable({ input }, graphApiOptions);
  return searchProvidersResponseSelector(res);
};

const refetchSearchProvidersTable = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.providerConnection' : undefined };
  const res = await runRefetchSearchProvidersTable({ input }, graphApiOptions);
  return searchProvidersResponseSelector(res);
};

export const useSearchProviders = createGraphApiHook(searchProviders, {
  refetch: refetchSearchProviders,
  merge: mergeConnectionDetails,
});

export const useSearchProvidersTable = createGraphApiHook(searchProvidersTable, {
  refetch: refetchSearchProvidersTable,
  merge: mergeConnectionDetails,
});

// clients
const searchClientsResponseSelector = (res: SearchClientsGraphApiResponse): ConnectionDetails<Provider> =>
  convertConnectionToDetails(res?.searchProviders?.clientConnection as ProviderConnection);

const [runSearchClientsTable, runRefetchSearchClientsTable] = graphApi(SearchClientsTableDocument, {
  onError: (res: SearchClientsGraphApiResponse): void => handleError(res, { notification: { title: 'Search Clients' } }),
});
const [runSearchClients, runRefetchSearchClients] = graphApi(SearchClientsDocument, {
  onError: (res: SearchClientsGraphApiResponse): void => handleError(res, { notification: { title: 'Search Clients' } }),
});

const searchClients = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.clientConnection' : undefined };
  const res = await runSearchClients({ input }, graphApiOptions);
  return searchClientsResponseSelector(res);
};

const refetchSearchClients = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.clientConnection' : undefined };
  const res = await runRefetchSearchClients({ input }, graphApiOptions);
  return searchClientsResponseSelector(res);
};

const searchClientsTable = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.clientConnection' : undefined };
  const res = await runSearchClientsTable({ input }, graphApiOptions);
  return searchClientsResponseSelector(res);
};

const refetchSearchClientsTable = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.clientConnection' : undefined };
  const res = await runRefetchSearchClientsTable({ input }, graphApiOptions);
  return searchClientsResponseSelector(res);
};

export const useSearchClients = createGraphApiHook(searchClients, {
  refetch: refetchSearchClients,
  merge: mergeConnectionDetails,
});

export const useSearchClientsTable = createGraphApiHook(searchClientsTable, {
  refetch: refetchSearchClientsTable,
  merge: mergeConnectionDetails,
});

// airlines
const searchAirlinesResponseSelector = (res: SearchAirlinesGraphApiResponse): ConnectionDetails<Provider> =>
  convertConnectionToDetails(res?.searchProviders?.airlineConnection as ProviderConnection);

const [runSearchAirlinesTable, runRefetchSearchAirlinesTable] = graphApi(SearchAirlinesTableDocument, {
  onError: (res: SearchAirlinesGraphApiResponse): void => handleError(res, { notification: { title: 'Search Airlines' } }),
});
const [runSearchAirlines, runRefetchSearchAirlines] = graphApi(SearchAirlinesDocument, {
  onError: (res: SearchAirlinesGraphApiResponse): void => handleError(res, { notification: { title: 'Search Airlines' } }),
});

const searchAirlines = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.airlineConnection' : undefined };
  const res = await runSearchAirlines({ input }, graphApiOptions);
  return searchAirlinesResponseSelector(res);
};

const refetchSearchAirlines = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.airlineConnection' : undefined };
  const res = await runRefetchSearchAirlines({ input }, graphApiOptions);
  return searchAirlinesResponseSelector(res);
};

const searchAirlinesTable = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.airlineConnection' : undefined };
  const res = await runSearchAirlinesTable({ input }, graphApiOptions);
  return searchAirlinesResponseSelector(res);
};

const refetchSearchAirlinesTable = async (
  query: ProviderSearch[] = null,
  options: SearchProvidersOptions = {}
): Promise<ConnectionDetails<Provider>> => {
  const { pageSize = SEARCH_PROVIDERS_PAGE_SIZE, page = 0, merge = false } = options;
  const input: ProviderConnectionInput = {
    first: pageSize || null,
    after: (pageSize * page).toString() || null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchProviders.airlineConnection' : undefined };
  const res = await runRefetchSearchAirlinesTable({ input }, graphApiOptions);
  return searchAirlinesResponseSelector(res);
};

export const useSearchAirlines = createGraphApiHook(searchAirlines, {
  refetch: refetchSearchAirlines,
  merge: mergeConnectionDetails,
});

export const useSearchAirlinesTable = createGraphApiHook(searchAirlinesTable, {
  refetch: refetchSearchAirlinesTable,
  merge: mergeConnectionDetails,
});

export default searchProviders;

import './styles.scss';

import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import ScrollableContainer from '../../components/ScrollableContainer';
import VehiclesTable from './VehiclesTable';

const Vehicles = () => (
  <ProtectedRoute title="Vehicles">
    <ScrollableContainer name="Vehicles" className="page-container">
      <VehiclesTable />
    </ScrollableContainer>
  </ProtectedRoute>
);

export default Vehicles;

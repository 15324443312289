import NavigationDropdown, { NavigationDropdownProps } from '@/components/NavigationDropdown';

import { DropdownProps } from '@/components/Dropdown';
import { ReactNode } from 'react';

export type SettingsDropdownProps = Omit<NavigationDropdownProps, 'items'>;

const settingsDropdownItems: DropdownProps['items'] = [
  { label: 'Buffer Times', value: '/settings/buffer-times' },
  { label: 'Combine Rules', value: '/settings/combine-rules' },
  { label: 'Variable Times', value: '/settings/variable-times' },
];

const SettingsDropdown = (props: SettingsDropdownProps): ReactNode => (
  <NavigationDropdown name="settingsNavigationDropdown" {...props} items={settingsDropdownItems} />
);

export default SettingsDropdown;

import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError, queryInput } from '@/utils/custom';
import { UpdateVariableTimeInput, UpdateVariableTimeValuesInput } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { UpdateVariableTimeBulkDocument } from '@/api/queries';
import graphApi from '@/api';

export type VariableTimeUpdate = Partial<UpdateVariableTimeValuesInput> & { id: string };
// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Update Variable Time' } });

// Service Function Signature
type ServiceMethodPayload = VariableTimeUpdate[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// UpdateVariableTimeBulk Service
export const { updateVariableTimeBulk, useUpdateVariableTimeBulk } = ((): {
  updateVariableTimeBulk: ServiceMethod;
  useUpdateVariableTimeBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(UpdateVariableTimeBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof UpdateVariableTimeBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const updates: UpdateVariableTimeInput[] = payload.map(
          ({ id, ...update }): UpdateVariableTimeInput => ({
            query: [{ id: queryInput(id) }],
            value: { ...update },
          })
        );
        const res = await fn({ input: { updates } });
        return res;
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    updateVariableTimeBulk: mutate,
    useUpdateVariableTimeBulk: useService,
  };
})();

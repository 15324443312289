import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import MessageCenterFilters, { useMessageCenterFilters } from './components/MessageCenterFilters';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';
import useChatRooms, { ChatRoomType, useChatState, useSelectedRoomState } from '@/hooks/useSocket/chat/useChatRooms';

import MessageCenterChatList from './components/MessageCenterChatList';
import MessageCenterContent from './components/MessageCenterContent';
import MessageCenterNoContent from './components/MessageCenterNoContent';
import ProtectedRoute from '@/components/ProtectedRoute';
import useInterval from '@/hooks/useInterval';
import useViewport from '@/hooks/useViewport';

type MessageCenterFilters = {
  startDatetime?: string;
  endDatetime?: string;
  userProviderId?: string[];
};
type MessageCenterProps = {};
const MessageCenter = ({ ..._props }: MessageCenterProps) => {
  const [filters] = useMessageCenterFilters();
  const [activeTab, setActiveTab] = useState<ChatRoomType>();
  const [, setUsersTyping] = useChatState(({ state: { totalUnread }, setState }) => [totalUnread, setState]);
  const selectedRoom = useSelectedRoomState(({ state: { room } }) => room);
  const [, , { getRooms }] = useChatRooms(activeTab || ChatRoomType.ALL_CHAT);
  const defaultChatRef = useRef<HTMLButtonElement>(null);
  const buttonTabsRef = useRef<HTMLDivElement>(null);
  const [{ content: { height: viewportHeight = 0 } = {} } = {}] = useViewport();

  useInterval(() => {
    setUsersTyping((current) => {
      const now = Date.now();
      return {
        ...current,
        usersTyping: current.usersTyping.filter((user: any) => now - user.timestamp < 3000),
      };
    });
  }, 3000);

  const getList = useCallback(
    (vals = filters) => {
      getRooms({
        format: activeTab || ChatRoomType.ALL_CHAT,
        start_datetime: vals?.startDatetime || TODAY,
        end_datetime: vals?.endDatetime || TODAY_EOD,
        user_provider_id: vals?.userProviderId || null,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab, getRooms]
  );

  useEffect(() => {
    if (!activeTab) return;
    getList();
  }, [activeTab, getList]);

  return (
    <ProtectedRoute title="Message Center" className="MessageCenter">
      {/* TODO: Implement actual filtering */}
      <MessageCenterFilters onSubmit={getList} />
      {/* <PageInfo>Unread Messages: {totalUnread || 0}</PageInfo> */}
      <Row className="MessageCenter-Container m-0">
        <Col sm="3">
          <Row ref={buttonTabsRef}>
            <Col className="g-0">
              <Button
                className="MessageCenter-Button"
                variant={!activeTab || activeTab === ChatRoomType.ALL_CHAT ? 'secondary' : 'primary'}
                onClick={() => setActiveTab(ChatRoomType.ALL_CHAT)}
                size="lg"
              >
                All Chats
              </Button>
            </Col>
            <Col className="g-0">
              <Button
                className="MessageCenter-Button"
                ref={defaultChatRef}
                variant={activeTab === ChatRoomType.MY_CHAT ? 'secondary' : 'primary'}
                onClick={() => setActiveTab(ChatRoomType.MY_CHAT)}
                size="lg"
              >
                My Chats
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={`{max-height:${viewportHeight - (buttonTabsRef.current?.offsetHeight || 0)}px;overflow:auto;} p-0`}>
              <MessageCenterChatList />
            </Col>
          </Row>
        </Col>
        <Col sm="9" className="{max-height:100%;} d-flex p-0">
          {selectedRoom ? <MessageCenterContent room={selectedRoom} /> : <MessageCenterNoContent />}
        </Col>
      </Row>
    </ProtectedRoute>
  );
};

export default MessageCenter;

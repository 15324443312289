import { UpdateRateOnTripInput, UpdateRateOnTripResponse, UpdateRateOnTripsInput } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { UpdateRateOnTripsDocument } from '../../queries';
import graphApi from '../..';
import { handleError } from '../../../utils';

type UpdateRateOnTripsGraphApiResponse = GraphApiResponse<typeof UpdateRateOnTripsDocument>;

const updateRatesOnTripsResponseSelector = (res: UpdateRateOnTripsGraphApiResponse): UpdateRateOnTripResponse[] =>
  !res.errors ? (res?.updateRateOnTrips?.output as UpdateRateOnTripResponse[]) : undefined;

const [runUpdateRateOnTrips] = graphApi(UpdateRateOnTripsDocument, {
  onError: (res: UpdateRateOnTripsGraphApiResponse): void => handleError(res, { notification: { title: 'Update Rate On Trip(s)' } }),
});

export const updateRateOnTrips = async (updates: UpdateRateOnTripInput[]): Promise<UpdateRateOnTripResponse[]> => {
  const input: UpdateRateOnTripsInput = {
    input: updates,
  };
  const res = await runUpdateRateOnTrips({
    input,
  });

  return updateRatesOnTripsResponseSelector(res);
};

export default updateRateOnTrips;

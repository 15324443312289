import { DeleteFlagHasTripBulkInput, DeleteFlagHasTripInput } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { DeleteFlagHasTripBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const validateFlagHasTripQuery = validateObj({
  tripId: (val: string): string => val || '',
  flagId: (val: string): string => val || '',
});

type DeleteFlagHasTripBulkGraphApiResponse = GraphApiResponse<typeof DeleteFlagHasTripBulkDocument>;

const title = 'Remove Flags';

const deleteFlagHasTripBulkResponseSelector = (res: DeleteFlagHasTripBulkGraphApiResponse): Number =>
  !res.errors ? res?.deleteFlagHasTripBulk?.deleted || 0 : undefined;

const [runDeleteFlagHasTripBulk] = graphApi(DeleteFlagHasTripBulkDocument, {
  onError: (res: DeleteFlagHasTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteFlagHasTripBulk = async (queryObjs: DeleteFlagHasTripInput[]): Promise<Number> => {
  const input: DeleteFlagHasTripBulkInput = {
    search: queryObjs.map(validateFlagHasTripQuery),
  };
  const res = await runDeleteFlagHasTripBulk({ input });
  const selected = deleteFlagHasTripBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteFlagHasTripBulk = createGraphApiHook(deleteFlagHasTripBulk);

export default deleteFlagHasTripBulk;

import { LoginInput, LoginResponse } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { LoginDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type LoginGraphApiResponse = GraphApiResponse<typeof LoginDocument>;

const loginResponseSelector = (res: LoginGraphApiResponse): LoginResponse => res?.login as LoginResponse;

export const [runLogin] = graphApi(LoginDocument);

export const login = async (username: string, password: string): Promise<LoginResponse> => {
  const input: LoginInput = { username, password };
  const res = await runLogin({ input });

  return loginResponseSelector(res);
};

export const useLogin = createGraphApiHook(login);

export default login;

import { FilterOption } from '../../models/gen/graphql';
import { GetClientsDocument } from '../../api/queries';
import SelectFromQuery from '../SelectFromQuery';

const SelectClient = (props) => (
  <SelectFromQuery
    placeholder="All Clients"
    query={GetClientsDocument}
    selector={(data) =>
      (data?.getFilters?.filters?.clients || []).map(({ id, displayName }: FilterOption) => ({
        label: displayName,
        value: id,
      }))
    }
    {...props}
    options={{ cache: 'Clients', ...(props?.options || {}) }}
  />
);

export default SelectClient;

import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Invoice, InvoiceConnection, InvoiceConnectionInput, InvoiceSearch } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchInvoicesDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type SearchInvoiceGraphApiResponse = GraphApiResponse<typeof SearchInvoicesDocument>;
type SearchInvoiceOptions = { pageSize?: number; page?: number; merge?: boolean };

export const SEARCH_INVOICE_PAGE_SIZE = 1000;
const title = 'Search Invoices';

const searchInvoiceResponseSelector = (res: SearchInvoiceGraphApiResponse): ConnectionDetails<Invoice> =>
  convertConnectionToDetails(res?.searchInvoices?.invoiceConnection as InvoiceConnection);

const [runSearchInvoices, runRefetchSearchInvoices] = graphApi(SearchInvoicesDocument, {
  onError: (res: SearchInvoiceGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const searchInvoices = async (query: InvoiceSearch[], options: SearchInvoiceOptions = {}): Promise<ConnectionDetails<Invoice>> => {
  const { pageSize = SEARCH_INVOICE_PAGE_SIZE, page = 0, merge = false } = options;

  const input: InvoiceConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchInvoices.invoiceConnection' : undefined };

  const res = await runSearchInvoices({ input }, graphApiOptions);

  return searchInvoiceResponseSelector(res);
};

export const refetchSearchInvoices = async (
  query: InvoiceSearch[],
  options: SearchInvoiceOptions = {}
): Promise<ConnectionDetails<Invoice>> => {
  const { pageSize = SEARCH_INVOICE_PAGE_SIZE, page = 0 } = options;

  const input: InvoiceConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchInvoices({ input });

  return searchInvoiceResponseSelector(res);
};

export const useSearchInvoices = createGraphApiHook(searchInvoices, { refetch: refetchSearchInvoices, merge: mergeConnectionDetails });

export default searchInvoices;

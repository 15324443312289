// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationCard {
  border-width: 2px;
  border-radius: var(--bs-border-radius-xl);
  overflow: hidden;
  color: var(--bs-primary);
}
.LocationCard.card.primary .card-header {
  margin: 0;
  padding: 1rem 2.25rem;
  border-width: 2px;
}
.LocationCard.card.primary .card-footer {
  padding: 1rem 2.25rem;
  border-width: 2px;
}
.LocationCard.card.primary .card-footer button {
  padding: 0.7rem;
  text-transform: uppercase;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/LocationCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yCAAA;EACA,gBAAA;EACA,wBAAA;AACF;AACI;EACE,SAAA;EACA,qBAAA;EACA,iBAAA;AACN;AAEI;EACE,qBAAA;EACA,iBAAA;AAAN;AACM;EACE,eAAA;EACA,yBAAA;EACA,gBAAA;AACR","sourcesContent":[".LocationCard {\n  border-width: 2px;\n  border-radius: var(--bs-border-radius-xl);\n  overflow: hidden;\n  color: var(--bs-primary);\n}\n.LocationCard.card.primary .card-header {\n  margin: 0;\n  padding: 1rem 2.25rem;\n  border-width: 2px;\n}\n.LocationCard.card.primary .card-footer {\n  padding: 1rem 2.25rem;\n  border-width: 2px;\n}\n.LocationCard.card.primary .card-footer button {\n  padding: 0.7rem;\n  text-transform: uppercase;\n  font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

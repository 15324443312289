import { createNotification, handleError } from '@/utils/custom';

import { DeleteTripBulkDocument } from '@/api/queries';
import { DeleteTripBulkInput } from '@/models/gen/graphql';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type DeleteTripBulkGraphApiResponse = GraphApiResponse<typeof DeleteTripBulkDocument>;

const title = 'Delete Trips';

const deleteTripBulkResponseSelector = (res: DeleteTripBulkGraphApiResponse): number =>
  !res.errors ? res?.deleteTripBulk?.deleted || 0 : undefined;

const [runDeleteTripBulk] = graphApi(DeleteTripBulkDocument, {
  onError: (res: DeleteTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteTripBulk = async (comment: string, ids: string[]): Promise<number> => {
  const input: DeleteTripBulkInput = {
    search: ids.map((id: string): { id: string } => ({ id })),
    comment: comment ? comment : null,
  };

  const res = await runDeleteTripBulk({ input });
  const selected = deleteTripBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteTripBulk = createGraphApiHook(deleteTripBulk);

export default deleteTripBulk;

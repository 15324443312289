import './styles.scss';

import { Container } from 'react-bootstrap';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import RolesTable from './RoleTable';

const Permissions = () => (
  <ProtectedRoute title="Permissions">
    <Container className="Permissions page-container" fluid>
      <RolesTable />
    </Container>
  </ProtectedRoute>
);

export default Permissions;

import { Button, Form, FormControlProps, InputGroup } from 'react-bootstrap';
import FormField, { FormFieldProps } from '@/components/FormField';
import React, { KeyboardEvent, useRef } from 'react';

import { onEnter } from '@/utils';

type AdjustableNumberFieldProps = {
  name: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: ({ target: { name, value } }: { target: { name: string; value: number } }) => void;
};
const AdjustableNumber = ({
  name,
  value,
  min = 0,
  max = Infinity,
  step = 1,
  onChange,
  ...props
}: AdjustableNumberFieldProps & FormControlProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  // Get the current value, and clamp it between min and max while modifying it as requested.
  const getValue = (modifier: number): number => Math.min(Math.max((parseInt(inputRef.current.value) || 0) + modifier, min), max);
  // Add or subtract the step from the current value.
  const onAdd = (): void => onChange({ target: { name, value: getValue(Math.abs(step || 1)) } });
  const onSubtract = (): void => onChange({ target: { name, value: getValue(Math.abs(step || 1) * -1) } });
  // Update the value on blur.
  const handleBlur = (): void => onChange({ target: { name, value: getValue(0) } });

  return (
    <InputGroup>
      <Button variant="secondary-subtle" className="number-field-modifier" onClick={onSubtract}>
        <i className="fa fa-minus" />
      </Button>
      <Form.Control
        name={name}
        defaultValue={value || '0'}
        {...props}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
          if (!event.ctrlKey && !event.metaKey && event.key.length === 1 && event.key.match(/[^0-9]/)) event.preventDefault();
          onEnter(handleBlur)(event);
        }}
        onBlur={handleBlur}
        onFocus={(): void => inputRef.current.select()}
        ref={inputRef}
      />
      <Button variant="secondary-subtle" className="number-field-modifier" onClick={onAdd}>
        <i className="fa fa-plus" />
      </Button>
    </InputGroup>
  );
};

const AdjustableNumberField = (props: AdjustableNumberFieldProps & FormFieldProps): JSX.Element => (
  <FormField {...props} options={{ input: { as: AdjustableNumber } }} condensed />
);

export default AdjustableNumberField;
export { AdjustableNumber };

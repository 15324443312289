import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Rate, RateConnection, RateConnectionInput, RateSearch } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchRatesDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchRatesGraphApiResponse = GraphApiResponse<typeof SearchRatesDocument>;
export type SearchRatesOptions = { pageSize?: number; page?: number; merge?: true };

export const SEARCH_RATES_PAGE_SIZE = 1000;
const title = 'Search Rates';

export const searchRatesResponseSelector = (res: SearchRatesGraphApiResponse): ConnectionDetails<Rate> =>
  convertConnectionToDetails(res?.searchRates?.rateConnection as RateConnection);

const [runSearchRates, runRefetchSearchRates] = graphApi(SearchRatesDocument, {
  onError: (res: SearchRatesGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchRates = async (query?: RateSearch[], options: SearchRatesOptions = {}): Promise<ConnectionDetails<Rate>> => {
  const { pageSize = SEARCH_RATES_PAGE_SIZE, page = 0, merge = false } = options;

  const input: RateConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchRates.rateConnection' : undefined };

  const res = await runSearchRates({ input }, graphApiOptions);

  return searchRatesResponseSelector(res);
};

const refetchSearchRates = async (query?: RateSearch[], options: SearchRatesOptions = {}): Promise<ConnectionDetails<Rate>> => {
  const { pageSize = SEARCH_RATES_PAGE_SIZE, page = 0 } = options;

  const input: RateConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchRates({ input });

  return searchRatesResponseSelector(res);
};

export const useSearchRates = createGraphApiHook(searchRates, {
  refetch: refetchSearchRates,
  merge: mergeConnectionDetails,
});

export default searchRates;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DynamicContentPage .VirtualTable-Item:last-of-type {
  border-bottom: 1px solid var(--bs-gray-300);
}
.DynamicContentPage .VirtualTable-Item .VirtualTable-Cell:not(:first-of-type) {
  border-left: none;
}
.DynamicContentPage .VirtualTable-Item .VirtualTable-Cell:last-of-type {
  border-right: 1px solid var(--bs-gray-300);
}`, "",{"version":3,"sources":["webpack://./src/components/DynamicContentPage/styles.scss"],"names":[],"mappings":"AAEI;EACE,2CAAA;AADN;AAIM;EACE,iBAAA;AAFR;AAIM;EACE,0CAAA;AAFR","sourcesContent":[".DynamicContentPage .VirtualTable-Item:last-of-type {\n  border-bottom: 1px solid var(--bs-gray-300);\n}\n.DynamicContentPage .VirtualTable-Item .VirtualTable-Cell:not(:first-of-type) {\n  border-left: none;\n}\n.DynamicContentPage .VirtualTable-Item .VirtualTable-Cell:last-of-type {\n  border-right: 1px solid var(--bs-gray-300);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
  CreateGroupHasPermissionBulkInput,
  CreateGroupHasPermissionBulkResponse,
  CreateGroupHasPermissionInput,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreateGroupHasPermissionBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { Validation } from '../../../utils';
import graphApi from '../..';

type CreateGroupHasPermissionBulkGraphApiResponse = GraphApiResponse<typeof CreateGroupHasPermissionBulkDocument>;

const title = 'Assign Permission to Groups';

const createGroupHasPermissionValidator = new Validation.Validator({
  'groupId!': (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val),
  }),
  'permissionId!': (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val),
  }),
});

const createGroupHasPermissionBulkResponseSelector = (
  res: CreateGroupHasPermissionBulkGraphApiResponse
): CreateGroupHasPermissionBulkResponse =>
  !res.errors ? (res?.createGroupHasPermissionBulk as CreateGroupHasPermissionBulkResponse) : undefined;

const [runCreateGroupHasPermissionBulk] = graphApi(CreateGroupHasPermissionBulkDocument, {
  onError: (res: CreateGroupHasPermissionBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createGroupHasPermissionBulk = async (
  groupHasPermissions: CreateGroupHasPermissionInput[]
): Promise<CreateGroupHasPermissionBulkResponse> => {
  const values: CreateGroupHasPermissionInput[] = groupHasPermissions.map(
    (groupHasPermission: CreateGroupHasPermissionInput): CreateGroupHasPermissionInput =>
      createGroupHasPermissionValidator.create(groupHasPermission)
  );
  const input: CreateGroupHasPermissionBulkInput = { values };

  const res = await runCreateGroupHasPermissionBulk({ input });
  const selected = createGroupHasPermissionBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export default createGroupHasPermissionBulk;

import {
  CreatePermissionHasRoleBulkInput,
  CreatePermissionHasRoleBulkResponse,
  CreatePermissionHasRoleInput,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreatePermissionHasRoleBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { Validation } from '../../../utils';
import graphApi from '../..';

type CreatePermissionHasRoleBulkGraphApiResponse = GraphApiResponse<typeof CreatePermissionHasRoleBulkDocument>;

const title = 'Assign Permission to Roles';

const createPermissionHasRoleValidator = new Validation.Validator({
  'roleId!': (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val),
  }),
  'permissionId!': (val: string): Validation.Validity => ({
    valid: Validation.isValidUUID(val),
  }),
});

const createPermissionHasRoleBulkResponseSelector = (
  res: CreatePermissionHasRoleBulkGraphApiResponse
): CreatePermissionHasRoleBulkResponse =>
  !res.errors ? (res?.createPermissionHasRoleBulk as CreatePermissionHasRoleBulkResponse) : undefined;

const [runCreatePermissionHasRoleBulk] = graphApi(CreatePermissionHasRoleBulkDocument, {
  onError: (res: CreatePermissionHasRoleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createPermissionHasRoleBulk = async (
  permissionHasRoles: CreatePermissionHasRoleInput[]
): Promise<CreatePermissionHasRoleBulkResponse> => {
  const values: CreatePermissionHasRoleInput[] = permissionHasRoles.map(
    (permissionHasRole: CreatePermissionHasRoleInput): CreatePermissionHasRoleInput =>
      createPermissionHasRoleValidator.create(permissionHasRole)
  );
  const input: CreatePermissionHasRoleBulkInput = { values };

  const res = await runCreatePermissionHasRoleBulk({ input });
  const selected = createPermissionHasRoleBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export default createPermissionHasRoleBulk;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NumberInput .NumberInput-Controls {
  display: flex;
  flex-direction: column;
}
.NumberInput .NumberInput-Controls > button {
  display: none;
  flex-grow: 1;
  border: 1px solid var(--bs-border-color);
  border-left: transparent;
  background-color: #fff;
  padding: 0;
  line-height: 0;
  width: 2rem;
}
.NumberInput .NumberInput-Controls > button i {
  color: var(--bs-border-color);
}
.NumberInput .NumberInput-Controls > button + button {
  border-top: none;
}
.NumberInput:hover .NumberInput-Controls > button, .NumberInput:focus-within .NumberInput-Controls > button {
  display: inline;
}
.NumberInput .NumberInput-Append {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--bs-border-color);
  border-left: none;
  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.35);
  cursor: default;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}`, "",{"version":3,"sources":["webpack://./src/components/NumberInput/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,sBAAA;AAAJ;AAEI;EACE,aAAA;EACA,YAAA;EACA,wCAAA;EACA,wBAAA;EACA,sBAAA;EACA,UAAA;EACA,cAAA;EACA,WAAA;AAAN;AAEM;EACE,6BAAA;AAAR;AAGM;EACE,gBAAA;AADR;AAQI;EACE,eAAA;AANN;AAUE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,wBAAA;EACA,wCAAA;EACA,iBAAA;EACA,4DAAA;EACA,eAAA;EACA,gDAAA;EACA,mDAAA;AARJ","sourcesContent":[".NumberInput .NumberInput-Controls {\n  display: flex;\n  flex-direction: column;\n}\n.NumberInput .NumberInput-Controls > button {\n  display: none;\n  flex-grow: 1;\n  border: 1px solid var(--bs-border-color);\n  border-left: transparent;\n  background-color: #fff;\n  padding: 0;\n  line-height: 0;\n  width: 2rem;\n}\n.NumberInput .NumberInput-Controls > button i {\n  color: var(--bs-border-color);\n}\n.NumberInput .NumberInput-Controls > button + button {\n  border-top: none;\n}\n.NumberInput:hover .NumberInput-Controls > button, .NumberInput:focus-within .NumberInput-Controls > button {\n  display: inline;\n}\n.NumberInput .NumberInput-Append {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.25rem 0.75rem;\n  border: 1px solid var(--bs-border-color);\n  border-left: none;\n  background-color: rgba(var(--bs-secondary-subtle-rgb), 0.35);\n  cursor: default;\n  border-top-right-radius: var(--bs-border-radius);\n  border-bottom-right-radius: var(--bs-border-radius);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { AirportHasVehicle, CreateAirportHasVehicleInput, Vehicle } from '../../../models/gen/graphql';
import { createNotification, getDiff } from '../../../utils';

import Logger from '../../../utils/logs';
import { Toast } from '../../../models';
import createAirportHasVehicleBulk from './createAirportHasVehicleBulk';
import createVehicleBulk from './createVehicleBulk';
import deleteAirportHasVehicleBulk from './deleteAirportHasVehicleBulk';
import updateVehicleBulk from './updateVehicleBulk';

const log = Logger.of('VehiclesTable');

export const handleCreateVehicleBulk = async (create: any): Promise<void> => {
  const { airportCodes = [], ...rest } = create;
  if (Object.values(rest).length === 0) return;
  const createVehicleBulkResponse = await createVehicleBulk([rest]);
  if ((airportCodes || []).length === 0) return;
  // AirportHasVehicle Flow
  const createAirportHasVehiclePayload = [];
  const edges = createVehicleBulkResponse?.output || [];
  for (let i = 0; i < edges.length; i++) {
    const edge = edges[i];
    const vehicleId = edge?.node?.id || '';
    if (!vehicleId) continue;
    (airportCodes || []).forEach((airportCode: string): void => {
      const temp: CreateAirportHasVehicleInput = {
        airportCode,
        vehicleId,
      };
      createAirportHasVehiclePayload.push(temp);
    });
  }
  if (createAirportHasVehiclePayload.length > 0) await createAirportHasVehicleBulk(createAirportHasVehiclePayload);
};

export const handleUpdateVehicleBulk = async ({ airportCodes = [], ...update }: any, selected: Record<string, Vehicle>): Promise<void> => {
  if (!Object.values(selected || {}).length) {
    log.error('handleUpdateVehicleBulk: 0 rows selected');
    return;
  }

  let hasChanges = false;
  const { airportCodes: originalAirportCodes = [], ...original } = Object.values(selected)?.[0] || {};
  const [partial] = getDiff(original, update);
  if (Object.values(partial).length > 0) {
    const bundle = Object.entries(selected).map(([vehicleId]: [string, Vehicle]): Vehicle => ({ ...partial, id: vehicleId }));
    await updateVehicleBulk(bundle);
    hasChanges = true;
  }

  // AirportHasVehicle Flow
  // init vars
  const createAirportHasVehicle = (airportCodes || []).filter(
    (code: any): boolean =>
      !(originalAirportCodes || []).filter((node: AirportHasVehicle): boolean => !!(node?.airportCode === (code?.airportCode || code)))
        .length
  );
  const deleteAirportHasVehicle = (originalAirportCodes || []).filter(
    (node: AirportHasVehicle): boolean =>
      !(airportCodes || []).filter((code: any): boolean => !!(node?.airportCode === (code?.airportCode || code))).length
  );
  // format payload
  let createAirportHasVehiclePayload = [];
  let deleteAirportHasVehiclePayload = [];
  Object.values(selected || {}).forEach((vehicle: any): void => {
    const vehicleId = vehicle?.id || '';
    const createTemp = createAirportHasVehicle.map((node: any): any => ({
      vehicleId,
      airportCode: node,
    }));
    const deleteTemp = deleteAirportHasVehicle.map((node: any): any => ({
      vehicleId,
      airportCode: node?.airportCode || node,
    }));
    if (createTemp.length > 0) createAirportHasVehiclePayload = [...createAirportHasVehiclePayload, ...createTemp];
    if (deleteTemp.length > 0) deleteAirportHasVehiclePayload = [...deleteAirportHasVehiclePayload, ...deleteTemp];
  });
  // create airport has vehicles
  if (createAirportHasVehiclePayload.length > 0) {
    await createAirportHasVehicleBulk(createAirportHasVehiclePayload);
    hasChanges = true;
  }
  // delete airport has vehicles
  if (deleteAirportHasVehiclePayload.length > 0) {
    await deleteAirportHasVehicleBulk(deleteAirportHasVehiclePayload);
    hasChanges = true;
  }

  if (!hasChanges) return createNotification('No changes made', Toast.Type.WARNING, 'Update Vehicle');
};

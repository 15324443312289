import { GraphApi } from '../../models';
import { UpdateLicenseBulkInput } from '../../models/gen/graphql';
import { generateUpdateBulkPayload } from '../../utils';
import gql from 'graphql-tag';
import { validateLicense } from './createLicenseBulk';

const updateLicenseBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (...licenses: any[]): Promise<any> => {
      const input: UpdateLicenseBulkInput = {
        updates: generateUpdateBulkPayload(licenses, validateLicense),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.updateLicenseBulk?.response || [],
  query: gql`
    mutation UpdateLicenseBulk($input: UpdateLicenseBulkInput!) {
      updateLicenseBulk(input: $input) {
        response {
          error {
            code
            debug
            message
          }
          updated
        }
      }
    }
  `,
};

export default updateLicenseBulk;

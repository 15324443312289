import './styles.scss';

import {
  AcceptInputOption,
  ReportFilterEnum,
  ReportInput,
  ReportSeriesEnum,
  SortDirectionEnum,
  Trip,
  TripStatusEnum,
  TripTableFormatEnum,
} from '@/models/gen/graphql';
import {
  ConnectionDetails,
  Datetime,
  QueryInputType,
  Validation,
  dedupe,
  getClasses,
  printScreen,
  queryInput,
  saveFile,
  stringify,
} from '@/utils';
import { DATE_INPUT_FORMAT, TODAY, TODAY_EOD } from '@/constants';
import React, { useRef, useState } from 'react';
import { SEARCH_TRIPS_TABLE_PAGE_SIZE, useSearchTripsTable } from '@/api/services/trips/searchTrips';

import ChatWidget from '@/components/ChatWidget';
import { Container } from 'react-bootstrap';
import { GetCompletionReasons } from '@/api/queries/filters';
import HasPermission from '@/components/HasPermission';
import PageInfo from '@/components/PageInfo';
import { RouteProps } from 'react-router-dom';
import TripsFilters from './TripsFilters';
import TripsTable from './TripsTable';
import { reportQueryAsCsv } from '@/api/services/reports/reportQuery';
import useDb from '@/hooks/useDb';
import { useOnSelect } from '@/components/VirtualTable/utils';
import useViewport from '@/hooks/useViewport';

const initTripsState = {
  search: '',
  selected: [],
  format: TripTableFormatEnum.Current,
  sorting: {
    column: undefined,
    direction: undefined,
  },
  priorityTableSorting: {
    column: undefined,
    direction: undefined,
  },
  hasLoaded: false,
};
type TripsState = typeof initTripsState;

const Trips = (_props: RouteProps): JSX.Element => {
  const [state, setState] = useState(initTripsState);
  const onSelect = useOnSelect(setState);
  const { search = '', selected = [], format = TripTableFormatEnum.Current, sorting, priorityTableSorting } = state;
  const [{ data, loading }, { fetch: searchTripsTable, refetch, fetchMore, setData }] = useSearchTripsTable();
  const { data: getCompletionReasonsResponse } = useDb('CompletionReasons', GetCompletionReasons);
  const completionReasons = getCompletionReasonsResponse?.getFilters?.filters?.completionReasons || [];
  const [
    { data: priorityTableData, loading: priorityTableLoading },
    { fetch: searchPriorityTripsTable, refetch: refetchPriority, setData: setPriorityData },
  ] = useSearchTripsTable();
  const { rows = [], hasNextPage = false, totalCount = 0, endCursor = '0' } = data || {};
  const { rows: priorityRows = [] } = priorityTableData || {};
  const [
    {
      content: { height: viewportHeight },
    },
  ] = useViewport();

  const refetchBoth = (): Promise<ConnectionDetails<Trip>> => {
    if (lastQuery.current?.format === TripTableFormatEnum.Current) refetchPriority();
    return refetch();
  };

  const lastQuery = useRef({ query: null, format: TripTableFormatEnum.Current }); // Tracks query after being parsed to queryInput
  const lastFilters = useRef(undefined); // Tracks raw filter values before being parsed to queryInput

  const fetchTripsTable = async (query: any = lastQuery.current.query, queryFormat: TripTableFormatEnum): Promise<any> => {
    const search = { query: query || lastQuery.current.query, format: queryFormat || lastQuery.current.format };
    const refetching = stringify.compare(lastQuery.current, search);
    if (queryFormat === TripTableFormatEnum.Current)
      refetching ? refetchPriority() : searchPriorityTripsTable({ ...search, format: TripTableFormatEnum.Priority });
    const res = await (refetching ? refetch() : searchTripsTable(search));
    lastQuery.current = { query: query || lastQuery.current.query, format: queryFormat || lastQuery.current.format };
    return res;
  };

  const getCsv = async (): Promise<void> => {
    const format: string = lastQuery.current?.format;

    const getValue = (key: string): string[] => {
      return lastQuery.current?.query?.[key]?.values || [];
    };
    const getComplexFilters = (): ReportFilterEnum[] => {
      switch (format) {
        case TripTableFormatEnum.Completed:
          return [ReportFilterEnum.HasCompletion];
        case TripTableFormatEnum.Unassigned:
          return [ReportFilterEnum.HasNoDriver];
        default:
          return null;
      }
    };
    const getCompletionTypes = (): string[] => {
      const noShow = completionReasons.find((reason: AcceptInputOption) => reason?.displayName === 'No Show')?.id;
      const taxiCalled = completionReasons
        ?.filter((reason: AcceptInputOption) => ['Taxi SHG Paid', 'Taxi Crew Paid', 'Lyft'].includes(reason?.displayName))
        ?.map((reason: AcceptInputOption) => reason?.id);
      switch (format) {
        case TripTableFormatEnum.NoShow:
          return noShow ? [noShow] : null;
        case TripTableFormatEnum.TaxiCalled:
          return taxiCalled;
        default:
          return null;
      }
    };
    const payload: Partial<ReportInput> = {
      series: ReportSeriesEnum.SeriesDefault,
      startDatetime: getValue('latestScheduled')[0],
      endDatetime: getValue('latestScheduled')[1],
      airlines: getValue('servicerIataAirlineCode'),
      airports: getValue('airportCode'),
      clients: getValue('payerProviderId'),
      completionTypes: getCompletionTypes(),
      complexFilters: getComplexFilters(),
      doLocations: getValue('doLocationId'),
      puLocations: getValue('puLocationId'),
      rates: parseInt(getValue('rateAmount')[0]) === 0 ? [ReportFilterEnum.ZeroRate] : getValue('rateAmount'),
      status: format === TripStatusEnum.Cancelled ? [TripStatusEnum.Cancelled] : null,
      types: getValue('type'),
    };
    const response = await reportQueryAsCsv('detailedReport')(payload);
    if (!response) return;
    const blob = new Blob([response], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    saveFile(url, `Trips-${new Datetime().format(DATE_INPUT_FORMAT)}.csv`);
  };

  const fetchMoreTripsTable = (): Promise<any> =>
    fetchMore(lastQuery.current, {
      page: Math.round((parseInt(endCursor) || 0) / SEARCH_TRIPS_TABLE_PAGE_SIZE),
      merge: true,
    });

  const onSelected = (ids: string[]): void => setState((current: TripsState): TripsState => ({ ...current, selected: ids }));

  const onSort = async (column: string, direction: string): Promise<void> =>
    setState((current: TripsState): TripsState => {
      const result = { ...current, sorting: { column, direction: direction?.toUpperCase?.() } };
      onSubmit({ ...lastFilters.current, sorting: result.sorting });
      return result;
    });

  const onPriorityTableSort = async (column: string, direction: string): Promise<void> =>
    setState((current: TripsState): TripsState => {
      const result = { ...current, priorityTableSorting: { column, direction: direction?.toUpperCase?.() } };
      onSubmit({ ...lastFilters.current, sorting: result.priorityTableSorting });
      return result;
    });

  const sortRateQueryInput = (value: string) => {
    if (!HasPermission.check('allowSearchTripsByRate')) return null;
    const validRateColumns = new Set(['rate.rate', 'rateAmount']);
    const direction = validRateColumns.has(sorting?.column) ? sorting?.direction : undefined;
    switch (value) {
      case 'NO_RATE':
        return queryInput([], QueryInputType.ISNULL);
      case 'ALL_RATES':
        return queryInput([], QueryInputType.ISNOTNULL, direction, 0);
      default:
        return queryInput(value || [], value ? QueryInputType.OR : QueryInputType.DEFAULT);
    }
  };
  const onSubmit = async ({ sorting = state.sorting, ...filters }: any = {}): Promise<void> => {
    const payload = {
      latestScheduled: queryInput.date([filters?.from || TODAY, filters?.to || TODAY_EOD]),
      latestScheduledUtc:
        !sorting?.direction || sorting?.column === 'scheduled'
          ? queryInput([], QueryInputType.DEFAULT, sorting?.direction || SortDirectionEnum.Asc, 0)
          : queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 1),
    };
    if (sorting?.column) {
      let key = sorting.column;
      switch (key) {
        case 'payerProvider.iataAirlineCode': {
          key = 'servicerIataAirlineCode';
          break;
        }
        case 'payerProvider.displayName': {
          key = 'payerProviderId';
          break;
        }
        case 'scheduledTime':
        case 'scheduledDate': {
          key = 'scheduled';
          break;
        }
        case 'rate.rate': {
          key = 'rateAmount';
          break;
        }
      }
      payload[key] = queryInput(undefined, QueryInputType.DEFAULT, sorting.direction, 0);
    }
    lastFilters.current = filters;
    const entries = Object.entries(filters || {});
    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];
      if (key === 'search') continue;
      const isValidValue = Validation.isTruthy(value) || Validation.isNumber(value);
      //TODO: set type to query input generic
      let parsedValue;
      switch (key) {
        case 'from':
        case 'to':
        case 'format':
          continue;
        case 'rateAmount':
          parsedValue = sortRateQueryInput(value as string);
          break;
        default:
          parsedValue =
            isValidValue || (!!sorting?.column && sorting?.column === key)
              ? queryInput(
                  value,
                  isValidValue ? QueryInputType.OR : QueryInputType.DEFAULT,
                  sorting?.column === key ? sorting?.direction : undefined,
                  0
                )
              : null;
      }

      payload[key] = parsedValue;
    }
    setState(
      (current: TripsState): TripsState => ({
        ...current,
        format: filters?.format || current?.format || TripTableFormatEnum.Current,
        selected: [],
      })
    );
    await fetchTripsTable(payload, filters?.format || format || TripTableFormatEnum.Current);
    setState((current: TripsState): TripsState => ({ ...current, hasLoaded: true }));
  };

  const selectedRows: Trip[] = dedupe([...rows, ...priorityRows], 'id').filter((trip: Trip): boolean => selected.includes(trip.id));

  return (
    <>
      <Container
        className={getClasses(
          'Trips page-container',
          lastQuery.current?.format === TripTableFormatEnum.Current && !!priorityRows?.length ? 'TripsMulti' : undefined
        )}
        fluid
      >
        <PageInfo>
          Total Trips: {rows?.length || 0} / {totalCount || 0}
        </PageInfo>
        {!!loading && !!priorityTableLoading && (
          <PageInfo>
            <i className="fa fa-spinner fa-pulse" />
          </PageInfo>
        )}
        <TripsFilters
          selectedRows={selectedRows}
          data={rows}
          onSubmit={onSubmit}
          selected={selected}
          onSelected={onSelected}
          onSearch={(val: string): void => setState((current: TripsState): TripsState => ({ ...current, selected: [], search: val }))}
          onReset={(): { format: TripTableFormatEnum; sorting: Record<string, any> } => {
            setState(initTripsState);
            return { format: initTripsState.format, sorting: initTripsState.sorting };
          }}
          actions={{
            items: [
              { key: 'exportCsv', label: 'Export As CSV' },
              { key: 'print', label: 'Print' },
            ],
            onClick: (key: string) => {
              if (key === 'exportCsv') getCsv();
              if (key === 'print') printScreen();
            },
          }}
        />
        {lastQuery.current?.format === TripTableFormatEnum.Current && (priorityRows?.length > 0 || priorityTableLoading) && (
          <TripsTable
            name="priorityTripsTable"
            format={lastQuery.current?.format}
            label={lastQuery.current?.format === TripTableFormatEnum.Current ? 'Late Outbound / Flagged Trips' : undefined}
            data={priorityRows}
            refetch={refetchBoth}
            setRows={setData}
            setPriorityRows={setPriorityData}
            selected={selected}
            onSelected={onSelect}
            search={search}
            onSort={onPriorityTableSort}
            sorting={priorityTableSorting}
            loading={priorityTableLoading}
            selectedRows={selectedRows}
          />
        )}
        <TripsTable
          name="tripsTable"
          format={lastQuery.current?.format}
          label={
            lastQuery.current?.format === TripTableFormatEnum.Current && (priorityRows?.length > 0 || priorityTableLoading)
              ? 'All Trips'
              : undefined
          }
          data={rows}
          refetch={refetchBoth}
          setPriorityRows={
            !!priorityRows?.length && lastQuery.current?.format === TripTableFormatEnum.Current ? setPriorityData : undefined
          }
          setRows={setData}
          fetchMore={hasNextPage && rows.length < totalCount ? fetchMoreTripsTable : undefined}
          selected={selected}
          onSelected={onSelect}
          search={search}
          onSort={onSort}
          sorting={sorting}
          loading={loading}
          selectedRows={selectedRows}
        />
      </Container>
      {state.hasLoaded && (
        <HasPermission name="allowViewChat">
          <div className={`position-fixed bottom-0 {width:45vw;max-width:400px;max-height:${viewportHeight}px;right:25px;}`}>
            <ChatWidget />
          </div>
        </HasPermission>
      )}
    </>
  );
};

export default Trips;

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryString = (initQueryState = {}): any[] => {
  const [searchParams, setQueryString] = useSearchParams();

  const queryString = {};
  for (const entry of searchParams.entries()) {
    const [key, val] = entry;
    if (queryString[key] !== undefined) {
      if (Array.isArray(queryString[key])) {
        queryString[key].push(val);
      } else {
        queryString[key] = [queryString[key], val];
      }
    } else {
      queryString[key] = val;
    }
  }

  useEffect((): void => {
    setQueryString(initQueryState);
  }, []);

  return [queryString, setQueryString];
};

export default useQueryString;

import { UserConnectionInput, UserSearch } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchUsers: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (first: number = PAGE_SIZE, after: string = null, query: UserSearch[] = null, options: any = {}): Promise<any> => {
      const input: UserConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchUsers.userConnection'),
  query: gql`
    query SearchUsers($input: UserConnectionInput) {
      searchUsers {
        userConnection(input: $input) {
          totalCount
          edges {
            node {
              id
              createdAt
              username
              phone
              firstName
              lastName
              startDate
              dob
              address
              cityName
              stateCode
              zipCode
              timezone
              notes
              longTrip
              active
              airportCodes {
                id
                airportCode
              }
              groups {
                id
              }
              licenses {
                id
                stateCode
                number
                licenseTypeId
                expirationDate
              }
              avatar
              roleId
              role {
                id
                name
              }
              email
              employeeId
              companyId
              lastLogin {
                datetime
              }
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  `,
};

export default searchUsers;

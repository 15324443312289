import { useContext, useEffect } from 'react';

import { Socket } from 'socket.io-client';
import { SocketContext } from './context';

const useSocket = (events?: Record<string, Function>): Socket => {
  const socket = useContext(SocketContext);

  useEffect((): (() => void) => {
    if (!socket || !socket?.connected) return;
    Object.entries(events || {}).forEach(([event, handler]: [string, Function]): void =>
      socket.on(event, (response) => {
        const result = JSON.parse(response);
        return handler(result);
      })
    );
    // TODO: rework to prevent clearing self on component re-rendering
    return (): void => Object.entries(events || {}).forEach(([event]: [string, Function]): void => socket.off(event));
  }, [events, socket]);

  return socket;
};

export default useSocket;

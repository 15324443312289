import './styles.scss';

import ClientsTable from './ClientsTable';

const Clients = () => (
  <div className="Clients page-container">
    <ClientsTable />
  </div>
);

export default Clients;

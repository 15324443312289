import { DeleteLocationBulkInput, DeleteLocationBulkResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { DeleteLocationBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type DeleteLocationBulkGraphApiResponse = GraphApiResponse<typeof DeleteLocationBulkDocument>;

const title = 'Delete Locations';

const deleteLocationBulkResponseSelector = (res: DeleteLocationBulkGraphApiResponse): DeleteLocationBulkResponse =>
  !res.errors ? res?.deleteLocationBulk : undefined;

const [runDeleteLocationBulk] = graphApi(DeleteLocationBulkDocument, {
  onError: (res: DeleteLocationBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const deleteLocationBulk = async (locationIds: string[]): Promise<DeleteLocationBulkResponse> => {
  const input: DeleteLocationBulkInput = {
    search: locationIds.map((id: string): { id: string } => ({ id })),
  };
  const res = await runDeleteLocationBulk({ input });

  const selected = deleteLocationBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useDeleteLocationBulk = createGraphApiHook(deleteLocationBulk);

export default deleteLocationBulk;

import { Button } from 'react-bootstrap';
import { ReactNode } from 'react';
import useLocale from '@/hooks/useLocale';

export type GroupFooterProps = {
  onAdd?: false | (() => void);
  onCancel?: false | (() => void);
  onSave?: false | (() => void);
  locale?: Record<string, string>;
  loading?: boolean;
};
const GroupFooter = ({ onAdd, onCancel, onSave, locale: customLocale, loading }: GroupFooterProps): ReactNode => {
  const locale = useLocale(customLocale);

  return (
    <div className="SettingsPageGroup-Footer">
      {onAdd !== undefined && (
        <Button variant="green" className="text-white" onClick={onAdd ? onAdd : undefined} disabled={onAdd === false || loading}>
          {locale('ADD NEW')}
        </Button>
      )}
      {onCancel !== undefined && (
        <Button variant="secondary" onClick={onCancel ? onCancel : undefined} disabled={onCancel === false || loading}>
          {locale('CANCEL')}
        </Button>
      )}
      {onSave !== undefined && (
        <Button variant="primary" onClick={onSave ? onSave : undefined} disabled={onSave === false || loading}>
          {!loading && locale('SAVE')}
          {loading && <i className="fa fa-spinner fa-pulse" />}
        </Button>
      )}
    </div>
  );
};

export default GroupFooter;

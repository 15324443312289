import './styles.scss';

import { Container } from 'react-bootstrap';
import DashboardSandbox from './Dashboard';
import FilterSandbox from './Filters';
import Form from './Form';
import MapSandbox from './Map';
import MiscSandbox from './Misc';
import Modals from './Modals';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import State from './State';
import TableSandbox from './Table';
import { useParams } from 'react-router-dom';
import useSwitch from 'hooks/useSwitch';

const Sandbox = (): JSX.Element => {
  const { route } = useParams();
  const content = useSwitch(route, {
    form: <Form />,
    modals: <Modals />,
    state: <State />,
    misc: <MiscSandbox />,
    map: <MapSandbox />,
    table: <TableSandbox />,
    dashboard: <DashboardSandbox />,
    filters: <FilterSandbox />,
    '*': <p className="lead text-center m-0 p-4">Sandbox route &quot;{route}&quot; not found.</p>,
  });

  return (
    <ProtectedRoute title="Sandbox">
      <Container className="page-container" fluid>
        {content}
      </Container>
    </ProtectedRoute>
  );
};

export default Sandbox;

import { GetOverrideImportOptionsInput, OverrideOptions, OverrideOptionsResponse } from '../../../models/gen/graphql';

import { GetOverrideImportOptionsDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils/custom';

type GetOverrideImportOptionsGraphApiResponse = GraphApiResponse<typeof GetOverrideImportOptionsDocument>;

const title = 'Get Override Options';

const getOverrideImportOptionsResponseSelector = (res: GetOverrideImportOptionsGraphApiResponse): OverrideOptions[] =>
  !res.errors
    ? (res?.getOverrideImportOptions?.imports || []).map(({ overrideOptions }: OverrideOptionsResponse): OverrideOptions => overrideOptions)
    : undefined;

const [runGetOverrideImportOptions, runRefetchGetOverrideImportOptions] = graphApi(GetOverrideImportOptionsDocument, {
  onError: (res: GetOverrideImportOptionsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const getOverrideImportOptions = async (importIds: string[]): Promise<OverrideOptions[]> => {
  const input: GetOverrideImportOptionsInput = {
    imports: importIds,
  };
  const res = await runRefetchGetOverrideImportOptions({ input });
  return getOverrideImportOptionsResponseSelector(res);
};

export const useGetOverrideImportOptions = createGraphApiHook(getOverrideImportOptions);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimePickerRange {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 0;
  background-color: #fff;
}
.TimePickerRange.is-valid {
  border-color: var(--bs-success);
}
.TimePickerRange.is-valid:first-of-type .ant-picker-suffix {
  color: var(--bs-success);
}
.TimePickerRange.is-invalid {
  border-color: var(--bs-danger);
}
.TimePickerRange.is-invalid:first-of-type .ant-picker-suffix {
  color: var(--bs-danger);
}
.TimePickerRange.is-dirty {
  border-color: var(--bs-secondary);
}
.TimePickerRange .TimePickerRange-Separator {
  padding: 0.375rem 0.75rem;
}
.TimePickerRange .ant-picker input {
  padding: calc(0.375rem - 1px) 0.75rem;
}
.TimePickerRange .ant-picker:first-of-type .ant-picker-clear {
  padding-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/TimePickerRange/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,wCAAA;EACA,sCAAA;EACA,UAAA;EACA,sBAAA;AACF;AACE;EACE,+BAAA;AACJ;AAAI;EACE,wBAAA;AAEN;AACE;EACE,8BAAA;AACJ;AAAI;EACE,uBAAA;AAEN;AACE;EACE,iCAAA;AACJ;AACE;EACE,yBAAA;AACJ;AAEI;EACE,qCAAA;AAAN;AAEI;EACE,gBAAA;AAAN","sourcesContent":[".TimePickerRange {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  border: 1px solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n  padding: 0;\n  background-color: #fff;\n}\n.TimePickerRange.is-valid {\n  border-color: var(--bs-success);\n}\n.TimePickerRange.is-valid:first-of-type .ant-picker-suffix {\n  color: var(--bs-success);\n}\n.TimePickerRange.is-invalid {\n  border-color: var(--bs-danger);\n}\n.TimePickerRange.is-invalid:first-of-type .ant-picker-suffix {\n  color: var(--bs-danger);\n}\n.TimePickerRange.is-dirty {\n  border-color: var(--bs-secondary);\n}\n.TimePickerRange .TimePickerRange-Separator {\n  padding: 0.375rem 0.75rem;\n}\n.TimePickerRange .ant-picker input {\n  padding: calc(0.375rem - 1px) 0.75rem;\n}\n.TimePickerRange .ant-picker:first-of-type .ant-picker-clear {\n  padding-right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapDisplay {
  height: 400px;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  overflow: hidden;
}

.InfoWindow {
  margin: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/MapDisplay/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wCAAA;EACA,sCAAA;EACA,gBAAA;AACF;;AACA;EACE,cAAA;AAEF","sourcesContent":[".MapDisplay {\n  height: 400px;\n  border: 1px solid var(--bs-border-color);\n  border-radius: var(--bs-border-radius);\n  overflow: hidden;\n}\n\n.InfoWindow {\n  margin: 0.2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import './styles.scss';

import { Col, FormControl, Row } from 'react-bootstrap';
import { DATETIME_INPUT_FORMAT } from '../../constants';
import { Datetime, onEnter } from '../../utils';
import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import Logger from '../../utils/logs';

const dayHeaders = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thurday', 'Friday', 'Saturday'];
const CURRENT_DATE = new Datetime();
type CalendarProps = {
  name: string;
  value?: string | string[];
  selector: dayjs.ManipulateType;
  range: boolean;
  multiple?: boolean;
  className?: string;
  onChange?: any;
  onBlur?: any;
};
type CalendarState = {
  calendar: Dayjs[][];
  click: string;
  from: Dayjs;
  to?: Dayjs;
  selectedDays: string[];
};
const Calendar = ({
  name,
  value,
  selector = 'day',
  range = false,
  multiple = false,
  className = '',
  onChange,
  onBlur,
}: CalendarProps): JSX.Element => {
  const initCalendarState: CalendarState = {
    calendar: [],
    from: CURRENT_DATE.clone().asDayjs(),
    click: 'first',
    to: null,
    selectedDays:
      value && Array.isArray(value) ? value?.map((date: string): string => new Datetime(date).frontendDate) : [CURRENT_DATE.frontendDate],
  };
  const [state, setState] = useState(initCalendarState);
  const { selectedDays } = state;
  const { from, calendar, to, click } = state;
  const calendarRef = useRef<HTMLDivElement>(null);

  // Focus the element when the component mounts
  useEffect((): void => {
    calendarRef.current?.focus();
  }, []);

  // Set Calendar Display
  useEffect((): void => {
    const weeks = [];
    const startDay = from.startOf('month').startOf('week');
    const endDay = from.endOf('month').endOf('week');
    const day = new Datetime(startDay).subtract(1, 'day');

    for (let i = 1; i <= 6; i++) {
      weeks.push(
        Array(7)
          .fill(0)
          .map((): Dayjs => day.add(1, 'day').asDayjs())
      );
    }

    setState((current: CalendarState): CalendarState => ({ ...current, calendar: weeks }));
  }, [from]);
  // Set 'to' to a dayjs value if 'range' is true
  useEffect((): void => {
    if (!range) return;
    setState((current: CalendarState): CalendarState => ({ ...current, to: from.clone().add(2, 'day') }));
  }, [range]);
  // Call onChange when selectedDays changes
  useEffect(() => {
    onChange({ target: { name, value: selectedDays } });
  }, [selectedDays]);

  // Helper Funcs
  // check if day is the same as start or end date
  const isSelected = (day: Dayjs): boolean => {
    if (multiple) return state.selectedDays.some((d: string): boolean => new Datetime(d).asDayjs().isSame(day, 'day'));
    if (range) return from.isSame(day, 'day') || to.isSame(day, 'day');
    return from.isSame(day, 'day');
  };
  // check if day is before start month
  const beforeMonth = (day: Dayjs): boolean => {
    return day.isBefore(from.startOf('month'), 'day');
  };
  // check if day is after start month
  const afterMonth = (day: Dayjs): boolean => {
    return day.isAfter(from.endOf('month'), 'day');
  };
  // check if day is today
  const isToday = (day: Dayjs): boolean => {
    return day.isSame(CURRENT_DATE.dateInput, 'day');
  };
  // check if day is same as the start date of selector
  const isStart = (day: Dayjs): boolean => {
    return day.isSame(from.startOf(selector), 'day');
  };
  // check if day is same as the end date of selector
  const isEnd = (day: Dayjs): boolean => {
    return day.isSame(from.endOf(selector), 'day');
  };
  // check if day is between start date and end date of selector
  const isBetween = (day: Dayjs, start: Dayjs, end: Dayjs): boolean => {
    return day.isAfter(start.startOf(selector), 'day') && day.isBefore(end.endOf(selector), 'day');
  };
  // check if day is within start date and end date
  const isWithin = (day: Dayjs, start: Dayjs, end: Dayjs): boolean => {
    return day.isAfter(start) && day.isBefore(end);
  };

  const getDayStyles = (day: Dayjs): string => {
    switch (selector) {
      case 'week' || 'month':
        if (isStart(day)) return 'range-start';
        if (isEnd(day)) return 'range-end';
        if (isBetween(day, from, from)) return 'range';
      case 'day':
        if (isSelected(day)) return 'selected';
      default:
        if (beforeMonth(day)) return 'before';
        if (afterMonth(day)) return 'before';
        if (range && isWithin(day, from, to)) return 'range';
        if (isToday(day)) return 'today';
        return '';
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    setTimeout(() => {
      // Check if the currently focused element is not nested inside calendar
      if (calendarRef.current && !calendarRef.current.contains(document.activeElement)) {
        if (onBlur) onBlur(event);
      }
    }, 100);
  };

  // state handlers
  const handleOnChange =
    (day: Dayjs): (() => void) =>
    (): void => {
      if (multiple) {
        setState((current: CalendarState): CalendarState => {
          const alreadySelected = current.selectedDays.some((d: string): boolean => new Datetime(d).asDayjs().isSame(day, 'day'));
          if (alreadySelected) {
            // if the day is already selected, remove it from the selectedDays list
            return {
              ...current,
              selectedDays: current.selectedDays.filter((d: string): boolean => !new Datetime(d).asDayjs().isSame(day, 'day')),
            };
          } else {
            // otherwise, add it to the selectedDays list
            return { ...current, selectedDays: [...current.selectedDays, day.format(DATETIME_INPUT_FORMAT)] };
          }
        });
      }
      if (range) {
        let key: string;
        let clickValue: string;
        // set up state machine
        switch (click) {
          case 'first':
            // Start changing value
            key = 'from';
            clickValue = 'second';
            if (day.isAfter(to)) {
              // since we are updating the opposite key we want to start the user in the same state
              key = 'to';
              clickValue = 'first';
            }
            setState((current: CalendarState): CalendarState => ({ ...current, [key]: day, click: clickValue }));
          case 'second':
            // Start changing value
            key = 'to';
            clickValue = 'first';
            if (day.isBefore(from)) {
              // since we are updating the opposite key we want to start the user in the same state
              key = 'from';
              clickValue = 'second';
            }
            setState((current: CalendarState): CalendarState => ({ ...current, [key]: day, click: clickValue }));
          default:
            Logger.of('Calendar').error('onChange: click is in an invalid state');
            return;
        }
      }
      if (!range && !multiple) {
        setState((current: CalendarState): CalendarState => ({ ...current, from: day }));
      }
    };
  const onSetValue = (unit: dayjs.UnitType, value: number): void => {
    setState((current: CalendarState): CalendarState => {
      const output = {
        ...current,
        from: current.from.set(unit, value),
      };
      if (range) {
        output.to = output.from.add(2, 'day');
        output.click = 'first';
      }
      return output;
    });
  };
  const onSubtractMonth = (value: number): void => {
    setState((current: CalendarState): CalendarState => {
      const output = {
        ...current,
        from: current.from.subtract(value, 'month').startOf('month'), // These dayjs func don't take the same type of unitOfTime
      };
      if (range) {
        output.to = output.from.add(2, 'day');
        output.click = 'first';
      }
      return output;
    });
  };
  const onAddMonth = (value: number): void => {
    setState((current: CalendarState): CalendarState => {
      const output = {
        ...current,
        from: current.from.add(value, 'month').startOf('month'), // These dayjs func don't take the same type of unitOfTime
      };
      if (range) {
        output.to = output.from.add(2, 'day');
        output.click = 'first';
      }
      return output;
    });
  };
  const handleSetYear = (event: any): void => {
    const { value } = event.target;
    onSetValue('year', parseInt(value));
  };

  return (
    <section className={`Calendar ${className}`} tabIndex={0} onBlur={handleOnBlur} ref={calendarRef}>
      <div>
        <Row className="Calendar-Header">
          <div>
            <div onClick={(): void => onSubtractMonth(1)}>
              <i className="sv sv-chevron-left" />
            </div>
            <div>
              {from.format('MMMM')}
              <span className="ms-2">
                <FormControl
                  maxLength={4}
                  className="text-center {width:50px!;padding:0.2rem|0!;}"
                  defaultValue={from.format('YYYY')}
                  onKeyDown={onEnter(handleSetYear)}
                  onBlur={handleSetYear}
                  key={from.format('YYYY')}
                />
              </span>
            </div>
            <div onClick={(): void => onAddMonth(1)}>
              <i className="sv sv-chevron-right" />
            </div>
          </div>
        </Row>
        <Row className="Calendar-Body">
          <Col className="Calendar-Date">
            <Row xs={7} className="">
              {dayHeaders.map(
                (dayHeader: string, index: number): JSX.Element => (
                  <Col key={`${dayHeader}_${index}`} className="d-flex justify-content-center">
                    {dayHeader.slice(0, 2)}
                  </Col>
                )
              )}
            </Row>
            {calendar.map(
              (week: Dayjs[], weekIndex: number): JSX.Element => (
                <Row key={`week_${weekIndex}`} xs={7} className="">
                  {week.map(
                    (day: Dayjs, dayIndex: number): JSX.Element => (
                      <Col key={`${week}_${day}`} className="d-flex justify-content-center" onClick={handleOnChange(day)}>
                        <div key={`${week}_${day}_${dayIndex}`} className={`${getDayStyles(day)} day`}>
                          {day.format('D').toString()}
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              )
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Calendar;
export { CalendarState };

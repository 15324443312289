import { Datetime, customCreateHandler, validateObj } from '../../utils';

import { CreateUserBulkInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateUser = validateObj({
  active: (val: any): any => (val ? 1 : 0),
  address: (val: any): any => val || null,
  avatar: (val: any): any => val || null,
  cityName: (val: any): any => val || null,
  companyId: (val: any): any => val || '',
  countryCode: (val: any): any => parseInt(val) || 0,
  dob: (val: any): any => new Datetime(val).toString() || null,
  email: (val: any): any => val || null,
  employeeId: (val: any): any => val || null,
  firstName: (val: any): any => val || '',
  lastName: (val: any): any => val || '',
  longTrip: (val: any): any => (val ? 1 : 0),
  notes: (val: any): any => val || null,
  password: (val: any): any => val || '',
  phone: (val: any): any => val || null,
  providerId: (val: any): any => val || null,
  resetClientIp: (val: any): any => val || null,
  resetCode: (val: any): any => val || null,
  resetCreatedAtUtc: (val: any): any => val || null,
  roleId: (val: any): any => val || '',
  startDate: (val: any): any => new Datetime(val).toString() || null,
  stateCode: (val: any): any => val || null,
  timezone: (val: any): any => val || null,
  username: (val: any): any => val || null,
  vehicleId: (val: any): any => val || null,
  zipCode: (val: any): any => val || null,
});

const createUserBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (...users: any[]): Promise<any> => {
      const input: CreateUserBulkInput = {
        values: users.map(validateUser),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.createUserBulk?.output || [],
  handler: customCreateHandler('Create User Profile', 'createUserBulk'),
  query: gql`
    mutation CreateUserBulk($input: CreateUserBulkInput!) {
      createUserBulk(input: $input) {
        output {
          error
          node {
            # active
            # address
            # airports
            # availability {
            #   approved
            #   createdAt
            #   createdBy
            #   dayOfWeek
            #   end
            #   id
            #   repeatUntil
            #   start
            #   type
            #   updatedAt
            #   updatedBy
            #   userId
            # }
            # avatar
            # cityName
            # companyId
            # countryCode
            # createdAt
            # createdBy
            # dob
            # email
            # employeeId
            # firstName
            # groups {
            #   active
            #   companyId
            #   createdAt
            #   createdBy
            #   id
            #   name
            #   permissions {
            #     createdAt
            #     createdBy
            #     effect
            #     entity
            #     id
            #     resource
            #     updatedAt
            #     updatedBy
            #     verb
            #   }
            #   updatedAt
            #   updatedBy
            #   users {
            #     active
            #     address
            #     airports
            #     avatar
            #     cityName
            #     companyId
            #     countryCode
            #     createdAt
            #     createdBy
            #     dob
            #     email
            #     employeeId
            #     firstName
            #     id
            #     lastName
            #     longTrip
            #     notes
            #     password
            #     phone
            #     providerId
            #     resetClientIp
            #     resetCode
            #     resetCreatedAtUtc
            #     roleId
            #     startDate
            #     stateCode
            #     timezone
            #     updatedAt
            #     updatedBy
            #     username
            #     vehicleId
            #     zipCode
            #   }
            # }
            id
            # lastLogin {
            #   datetime
            # }
            # lastName
            # licenses {
            #   createdAt
            #   createdBy
            #   expirationDate
            #   id
            #   licenseTypeId
            #   number
            #   stateCode
            #   updatedAt
            #   updatedBy
            #   userId
            # }
            # longTrip
            # notes
            # password
            # phone
            # provider {
            #   billFrequency
            #   billingAddress
            #   billingCityCode
            #   billingStateCode
            #   billingZipCode
            #   contactPerson
            #   createdAt
            #   createdBy
            #   displayName
            #   emails {
            #     address
            #     createdAt
            #     createdBy
            #     id
            #     providerId
            #     type
            #     updatedAt
            #     updatedBy
            #   }
            #   faxNumber
            #   id
            #   importCode
            #   mobilePhoneNumber
            #   name
            #   occPhoneNumber
            #   primaryAddress
            #   primaryCityCode
            #   primaryEmail
            #   primaryLogoUrl
            #   primaryPhoneNumber
            #   primaryStateCode
            #   primaryZipCode
            #   referenceNumber
            #   type
            #   updatedAt
            #   updatedBy
            #   website
            # }
            # providerId
            # ratingDetails {
            #   averageRating
            #   rating {
            #     comment
            #     compliments
            #     createdAt
            #     createdBy
            #     id
            #     rating
            #     ratingType {
            #       createdAt
            #       createdBy
            #       id
            #       name
            #       updatedAt
            #       updatedBy
            #     }
            #     ratingTypeId
            #     tripId
            #     updatedAt
            #     updatedBy
            #     userId
            #   }
            # }
            # resetClientIp
            # resetCode
            # resetCreatedAtUtc
            # role {
            #   createdAt
            #   createdBy
            #   id
            #   name
            #   updatedAt
            #   updatedBy
            # }
            # roleId
            # startDate
            # stateCode
            # timezone
            # tripDetails {
            #   onTimePercentage
            #   totalAmountOfTrips
            # }
            # updatedAt
            # updatedBy
            # username
            # vehicleId
            # zipCode
          }
        }
      }
    }
  `,
};

export default createUserBulk;
export { validateUser };

import { CreateVehicleBulkInput, CreateVehicleBulkResponse, CreateVehicleInput, Vehicle } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreateVehicleBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { Validation } from '../../../utils';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type CreateVehicleBulkGraphApiResponse = GraphApiResponse<typeof CreateVehicleBulkDocument>;

const title = 'Create Vehicles';

export const createVehicleValidator = new Validation.Validator({
  year: (val: number): Validation.Validity => ({
    valid: !!val && Validation.isNumber(val) ? true : undefined,
  }),
  tollTag: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  stateRegistered: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  registrationExpirationDate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  phoneNumber: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isNumeric(val) ? true : val?.length ? false : undefined,
  }),
  model: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  make: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  licensePlate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
  }),
  emissionPermitExpirationDate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  airportPermitExpirationDate: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  'locationId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    message: !Validation.isValidUUID(val) ? 'Please select location.' : undefined,
  }),
  'active!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'capacity!': (val: string): Validation.Validity => ({
    valid: Validation.isNumber(parseInt(val)) && parseInt(val) > 0,
  }),
  'vin!': (val: string): Validation.Validity => ({
    valid: val?.length === 17,
    message: val?.length !== 17 ? 'Please enter a valid vin number.' : undefined,
  }),
  'trackingId!': (val: number): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val),
  }),
  'tracker!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val),
  }),
});

const createVehicleBulkResponseSelector = (res: CreateVehicleBulkGraphApiResponse): CreateVehicleBulkResponse =>
  !res.errors ? (res?.createVehicleBulk as CreateVehicleBulkResponse) : undefined;

const [runCreateVehicleBulk] = graphApi(CreateVehicleBulkDocument, {
  onError: (res: CreateVehicleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createVehicleBulk = async (vehicles: Partial<Vehicle>[]): Promise<CreateVehicleBulkResponse> => {
  const values: CreateVehicleInput[] = vehicles.map((vehicle: Vehicle): CreateVehicleInput => createVehicleValidator.create(vehicle));
  const input: CreateVehicleBulkInput = { values };

  const res = await runCreateVehicleBulk({ input });
  const selected = createVehicleBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateVehicleBulk = createGraphApiHook(createVehicleBulk);

export default createVehicleBulk;

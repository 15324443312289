import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError, queryInput } from '@/utils/custom';
import { UpdatePunchPolicyInput, UpdatePunchPolicyValuesInput } from '@/models/gen/graphql';

import { UpdatePunchPolicyBulkDocument } from '@/api/queries';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Update Punch Policy' } });

// Service Function Signature
type ServiceMethodPayload = { [key in keyof UpdatePunchPolicyValuesInput]: any } & { id: string }[];
interface ServiceMethod {
  (...payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// UpdatePunchPolicyBulk Service
// carmstrong @ 5/1/2024
export const { updatePunchPolicyBulk } = ((): {
  updatePunchPolicyBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(UpdatePunchPolicyBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof UpdatePunchPolicyBulkDocument>): ServiceMethod =>
      async (...payload: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const updates: UpdatePunchPolicyInput[] = payload.map(
          ({
            id,
            createdAt: _createdAt,
            createdBy: _createdBy,
            updatedAt: _updatedAt,
            updatedBy: _updatedBy,
            ...update
          }: { [key in keyof UpdatePunchPolicyValuesInput]: any } & {
            id?: string;
            createdAt?: string;
            createdBy?: string;
            updatedAt?: string;
            updatedBy?: string;
          }): UpdatePunchPolicyInput => ({
            query: [{ id: queryInput(id) }],
            value: { ...update },
          })
        );
        const res = await fn({ input: { updates } });
        return res;
      }
  );

  return {
    updatePunchPolicyBulk: mutate,
  };
})();

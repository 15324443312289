import './styles.scss';

import React, { useState } from 'react';
import Select, { SelectProps } from '@/components/Select';

import Details from '@/components/Details';
import FormField from '../../../../../components/FormField';
import LineChart from '@/components/GenericChart/components/LineChart';
import BarChart from '@/components/GenericChart/components/BarChart';
import { ChartTypes, ChartTypesObject } from '@/utils/charts';
import PieChart from '@/components/GenericChart/components/PieChart';
import { findDeepestChildrenLevel, getValuesAtDepth, range } from '@/utils';

type DynamicChartProps = {
  name: string;
  index: number;
  header: any;
  footer: any;
  columns: any;
  rows: any;
  onDetails: (key: string) => void;
};
type DynamicChartState = {
  xAxis: string;
  yAxis: string;
  chartType: ChartTypes;
};
const initDynamicChartState: DynamicChartState = {
  xAxis: undefined,
  yAxis: undefined,
  chartType: 'line',
};

const chartComponents = {
  line: LineChart,
  bar: BarChart,
  pie: PieChart,
};

const DynamicChart = ({ header, footer, columns, rows }: DynamicChartProps): JSX.Element => {
  const [state, setState] = useState<DynamicChartState>(initDynamicChartState);
  const { xAxis, yAxis, chartType } = state;
  const totalDepth = findDeepestChildrenLevel(rows, 'children') || 0;
  const totalRange = range(totalDepth, 1);
  const RenderedChart = chartComponents[chartType];

  const formatToolTip = (_params: any, row: any): string => {
    return Object.keys(row)
      .filter((item) => columns[item]?.value !== undefined)
      .map((key): any => `<strong>${columns[key].value}</strong>: ${row[key]}`)
      .join('<br />');
  };

  return (
    <>
      {header && (
        <Details data={Object.entries(header).map(([key, value]: [string, string]): Record<string, string> => ({ [key]: value }))} inline />
      )}
      <div className="d-flex justify-content-space-between gap-2">
        <FormField
          name="chartType"
          label="Chart Type"
          onChange={({ target: { value } }: any): void =>
            setState((current: DynamicChartState): DynamicChartState => ({ ...current, chartType: value }))
          }
          value={chartType}
          options={{
            input: {
              as: (props: SelectProps): JSX.Element => (
                <Select {...props}>
                  {Object.entries(ChartTypesObject).map(
                    ([key, { value }]: [string, { value: string }]): JSX.Element => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    )
                  )}
                </Select>
              ),
            },
          }}
        />
        <FormField
          name="xAxis"
          label="X Axis"
          onChange={({ target: { value } }: any): void =>
            setState((current: DynamicChartState): DynamicChartState => ({ ...current, xAxis: value }))
          }
          value={xAxis}
          options={{
            input: {
              as: (props: SelectProps): JSX.Element => (
                <Select {...props}>
                  {totalRange.map(
                    (val: number): JSX.Element => (
                      <option key={val} value={val}>
                        {`Level ${val}`}
                      </option>
                    )
                  )}
                </Select>
              ),
            },
          }}
        />
        <FormField
          name="yAxis"
          label="Y Axis"
          onChange={({ target: { value } }: any): void =>
            setState((current: DynamicChartState): DynamicChartState => ({ ...current, yAxis: value }))
          }
          value={yAxis}
          options={{
            input: {
              as: (props: SelectProps): JSX.Element => (
                <Select {...props}>
                  {Object.entries(columns).map(
                    ([key, { value }]: [string, { value: string }]): JSX.Element => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    )
                  )}
                </Select>
              ),
            },
          }}
        />
      </div>
      {chartType && (
        <RenderedChart xAxis={xAxis} yAxis={yAxis} data={getValuesAtDepth(rows, parseInt(xAxis)) ?? []} formatTooltip={formatToolTip} />
      )}
      {footer && (
        <Details data={Object.entries(footer).map(([key, value]: [string, string]): Record<string, string> => ({ [key]: value }))} inline />
      )}
    </>
  );
};

export default DynamicChart;

import ReactECharts from 'echarts-for-react';
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef } from 'react';

type GenericChartProps = ComponentPropsWithoutRef<typeof ReactECharts> & {
  className?: string;
  width?: string;
  height?: string;
  noData?: boolean;
};

const GenericChart = ({ className, width, height, noData, ...echartProps }: GenericChartProps, ref) => {
  const echartsRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEchartsInstance: () => {
      if (echartsRef.current) {
        return echartsRef.current.getEchartsInstance();
      }
      return null;
    },
  }));

  return (
    <div className={className ?? undefined} style={{ width: width ?? undefined, height: height ?? undefined }}>
      {!noData && <ReactECharts {...echartProps} ref={echartsRef} />}
      {noData && (
        <ReactECharts
          option={{
            graphic: {
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                text: 'No data available',
                fontSize: 20,
                fontWeight: 'bold',
                fill: '#999',
              },
            },
          }}
          ref={echartsRef}
        />
      )}
    </div>
  );
};

export default forwardRef(GenericChart);

import './styles.scss';

import { Accordion, Button, Offcanvas } from 'react-bootstrap';
import React, { useState } from 'react';

import GlobalStatePane from './GlobalStatePane';
import LoggingPane from './LoggingPane';
import ModalsPane from './ModalsPane';
import NavigationPane from './NavigationPane';
import NotificationsPane from './NotificationsPane';
import OptionsPane from './OptionsPane';

const initDebugToolsState = {
  open: false,
  orientation: 'end',
};
const DebugTools = () => {
  const [state, setState] = useState(initDebugToolsState);
  const { open, orientation } = state;

  const onToggle = () => setState((current) => ({ ...current, open: !current.open }));

  const panes = {
    Navigation: NavigationPane,
    'Global State': GlobalStatePane,
    Logging: LoggingPane,
    Notifications: NotificationsPane,
    Modals: ModalsPane,

    Options: OptionsPane,
  };

  return (
    <>
      {!open && (
        <Button variant="outline-secondary-subtle" className={`Debug-Button ${orientation}`} onClick={onToggle}>
          <i className="sv sv-cog" />
        </Button>
      )}
      <Offcanvas show={open} onHide={onToggle} className="Debug-Tools" backdrop={false} placement={orientation}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Debug</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <Accordion flush>
            {Object.entries(panes).map(([name, PaneComponent], index) => (
              <Accordion.Item eventKey={name} key={index}>
                <Accordion.Header>{name}</Accordion.Header>
                <Accordion.Body>
                  <PaneComponent debugState={[state, setState]} />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DebugTools;

namespace Toast {
  export type Notification = {
    title: string;
    content: string;
    type: Toast.Type;
    info?: string;
    options?: Toast.Options;
    timestamp?: number;
  };
  export type Options = {
    moreDetails?: string;
    redirect?: string;
    clearAll?: boolean; // This is used to clear all notifications should only be assigned to the first notification
  };
  export enum Type {
    DEFAULT = 'default',
    WARNING = 'secondary',
    DANGER = 'danger',
    SUCCESS = 'success',
    INFO = 'info',
  }
}

export default Toast;

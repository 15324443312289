import QueryDropdown, { QueryDropdownMultiProps, QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';
import { getAirportGroupPermissions, getAirportGroups } from '@/api/services/filters/airportGroups';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getAirports } from '@/api/services/filters/airports';

export type AirportDropdownProps = Omit<QueryDropdownProps, 'query'>;
export type AirportGroupDropdownProps = Omit<QueryDropdownMultiProps, 'query'>;

const AirportDropdown = ({ name = 'airport', ...props }: AirportDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirports();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'City' } }} />;
};

export const AirportGroupDropdown = ({ name = 'airport', ...props }: AirportGroupDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirportGroups();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({
        label: option?.displayName,
        value: option?.values?.length === 1 && option?.values?.[0] === option?.id ? option?.id : undefined,
        group: option?.values?.length > 1 ? option?.values : undefined,
        keywords: option?.displayName?.split?.(' ') || [],
      })
    );
  }, []);

  return (
    <QueryDropdown.Sticky {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'City' } }} />
  );
};

export const AirportGroupPermissionsDropdown = ({ name = 'airport', ...props }: AirportGroupDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirportGroupPermissions();
    console.log('response', res);
    return res.map?.(
      (option: FilterOption): DropdownItem => ({
        label: option?.displayName,
        value: option?.values?.length === 1 && option?.values?.[0] === option?.id ? option?.id : undefined,
        group: option?.values?.length > 1 ? option?.values : undefined,
        keywords: option?.displayName?.split?.(' ') || [],
      })
    );
  }, []);

  return (
    <QueryDropdown.Multi {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'City' } }} />
  );
};

export default AirportDropdown;

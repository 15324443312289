import { ConnectionDetails, QueryInputType, convertConnectionToDetails, handleError, queryInput } from '../../../utils/custom';
import {
  Permission,
  PermissionConnection,
  PermissionConnectionInput,
  PermissionSearch,
  SortDirectionEnum,
} from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchPermissionsDocument } from '../../queries';
import graphApi from '../..';

type SearchPermissionsGraphApiResponse = GraphApiResponse<typeof SearchPermissionsDocument>;
type SearchPermissionsOptions = { pageSize?: number; page?: number; merge?: true };

const SEARCH_PERMISSIONS_PAGE_SIZE = 1000;
const title = 'Search Permissions';

const searchPermissionsResponseSelector = (res: SearchPermissionsGraphApiResponse): ConnectionDetails<Permission> =>
  convertConnectionToDetails(res?.searchPermissions?.permissionConnection as PermissionConnection);

const [runSearchPermissions, runRefetchSearchPermissions] = graphApi(SearchPermissionsDocument, {
  onError: (res: SearchPermissionsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchPermissions = async (
  query?: PermissionSearch[],
  options: SearchPermissionsOptions = {}
): Promise<ConnectionDetails<Permission>> => {
  const { pageSize = SEARCH_PERMISSIONS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: PermissionConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchPermissions.permissionConnection' : undefined };

  const res = await runSearchPermissions({ input }, graphApiOptions);

  return searchPermissionsResponseSelector(res);
};

const refetchSearchPermissions = async (
  query?: PermissionSearch[],
  options: SearchPermissionsOptions = {}
): Promise<ConnectionDetails<Permission>> => {
  const { pageSize = SEARCH_PERMISSIONS_PAGE_SIZE, page = 0 } = options;

  const input: PermissionConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchPermissions({ input });

  return searchPermissionsResponseSelector(res);
};

export const getPermissions = async (): Promise<ConnectionDetails<Permission>> => {
  const query: PermissionSearch = {
    entity: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0),
    displayName: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 1),
  };
  return await searchPermissions([query], { pageSize: null });
};

export default searchPermissions;

import {
  DeletePermissionHasRoleBulkInput,
  DeletePermissionHasRoleBulkResponse,
  DeletePermissionHasRoleInput,
} from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { DeletePermissionHasRoleBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import graphApi from '../..';

type DeletePermissionHasRoleBulkGraphApiResponse = GraphApiResponse<typeof DeletePermissionHasRoleBulkDocument>;

const title = 'Remove Permissions from Role';

const deletePermissionHasRoleBulkResponseSelector = (
  res: DeletePermissionHasRoleBulkGraphApiResponse
): DeletePermissionHasRoleBulkResponse => (!res.errors ? res?.deletePermissionHasRoleBulk : undefined);

const [runDeletePermissionHasRoleBulk] = graphApi(DeletePermissionHasRoleBulkDocument, {
  onError: (res: DeletePermissionHasRoleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const deletePermissionHasRoleBulk = async (search: DeletePermissionHasRoleInput[]): Promise<DeletePermissionHasRoleBulkResponse> => {
  const input: DeletePermissionHasRoleBulkInput = { search };
  const res = await runDeletePermissionHasRoleBulk({ input });

  const selected = deletePermissionHasRoleBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default deletePermissionHasRoleBulk;

import { Rate, UpdateRateBulkInput, UpdateRateBulkResponse } from '../../../models/gen/graphql';
import { createNotification, generateUpdateBulkPayload, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdateRateBulkDocument } from '../../queries';
import { enforceRateDates } from './createRateBulk';
import graphApi from '../..';

type UpdateRateBulkGraphApiResponse = GraphApiResponse<typeof UpdateRateBulkDocument>;

const title = 'Update Rates';

const updateRateBulkResponseSelector = (res: UpdateRateBulkGraphApiResponse): UpdateRateBulkResponse =>
  !res.errors ? res?.updateRateBulk : undefined;

const [runUpdateRateBulk] = graphApi(UpdateRateBulkDocument, {
  onError: (res: UpdateRateBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateRateBulk = async (rates: Partial<Rate>[]): Promise<UpdateRateBulkResponse> => {
  const updates = generateUpdateBulkPayload(rates?.map(enforceRateDates));
  const input: UpdateRateBulkInput = { updates };
  const res = await runUpdateRateBulk({ input });

  const selected = updateRateBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateRateBulk;

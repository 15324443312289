import { PENDING_STATUS } from '../../constants';
import React from 'react';
import Select from '../Select';
import { properCase } from '../../utils';

const SelectPendingImportStatus = ({ onChange, value, ...props }) => {
  return (
    <Select name="status" onChange={onChange} value={value} placeholder="Status" {...props}>
      {PENDING_STATUS.map((name, n) => (
        <option value={name} key={n}>
          {properCase(name)}
        </option>
      ))}
    </Select>
  );
};

export default SelectPendingImportStatus;

import { ApproveUserAvailabilityInput, ApproveUserAvailabilityResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { ApproveUserAvailabilityDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import Logger from '@/utils/logs';
import Toast from '@/models/Toast';
import { Validation } from '@/utils/validations';
import graphApi from '@/api';

const log = Logger.of('ApproveUserAvailability');

type ApproveUserAvailabilityGraphApiResponse = GraphApiResponse<typeof ApproveUserAvailabilityDocument>;

const approveUserAvailabilityResponseSelector = (res: ApproveUserAvailabilityGraphApiResponse): ApproveUserAvailabilityResponse =>
  !res.errors ? (res?.approveUserAvailability as ApproveUserAvailabilityResponse) : undefined;

const [runApproveUserAvailability] = graphApi(ApproveUserAvailabilityDocument, {
  onError: (res: ApproveUserAvailabilityGraphApiResponse): void => {
    const title = res?.variables?.input?.approved === 0 ? 'Deny User Availability' : 'Approve User Availability';
    handleError(res, { notification: { title } });
  },
});

const approveUserAvailability = async (ids: Array<string>, approved: 0 | 1): Promise<ApproveUserAvailabilityResponse> => {
  if (!Validation.isNumber(approved)) throw new Error('Approved must be a number');
  if (!ids?.length) throw new Error('No user ids provided');
  if (!ids.every((str: string): boolean => Validation.isValidUUID(str))) throw new Error('Invalid user ids provided');

  const input: ApproveUserAvailabilityInput = { ids, approved };

  const res = await runApproveUserAvailability({ input });
  return approveUserAvailabilityResponseSelector(res);
};

export const handleApproveUserAvailability = async (ids: Array<string>): Promise<ApproveUserAvailabilityResponse> => {
  try {
    const res = await approveUserAvailability(ids, 1);
    if (res) createNotification('Success', Toast.Type.SUCCESS, 'Approve User Availability');
    return res;
  } catch (err) {
    const message = err.message || err;
    log.error('handleApproveUserAvailability', err.message).notify({ title: 'Approve User Availability', message });
  }
};
export const handleDenyUserAvailability = async (ids: Array<string>): Promise<ApproveUserAvailabilityResponse> => {
  try {
    const res = await approveUserAvailability(ids, 0);
    if (res) createNotification('Success', Toast.Type.SUCCESS, 'Deny User Availability');
    return res;
  } catch (err) {
    const message = err.message || err;
    log.error('handleDenyUserAvailability', err.message).notify({ title: 'Deny User Availability', message });
  }
};

export default approveUserAvailability;

import { CreateStopBulkInput, CreateStopInput } from '../../models/gen/graphql';
import { customCreateHandler, validateObj } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateStop = validateObj({
  tripId: (val: any): any => val || null,
  type: (val: any): any => val || null,
  locationId: (val: any): any => val || null,
  scheduled: (val: any): any => val || null,
  completed: (val: any): any => val || null,
  actual: (val: any): any => val || null,
});

const createStopBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (stops: CreateStopInput[]): Promise<any> => {
      const input: CreateStopBulkInput = { values: stops.map((stop: CreateStopInput): CreateStopInput => validateStop(stop)) };
      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): any[] => data?.createStopBulk?.output || [],
  handler: customCreateHandler('Create Stop', 'createStopBulk'),
  query: gql`
    mutation CreateStopBulk($input: CreateStopBulkInput!) {
      createStopBulk(input: $input) {
        output {
          error
          node {
            id
            # type
            # tripId
            # locationId
            # scheduled
            # actual
            # completed
            # createdAt
            # createdBy
            # updatedAt
            # updatedBy
            # trip {
            #   id
            #   manifestImportId
            #   providerId
            #   payerProviderId
            #   companyId
            #   traceId
            #   kind
            #   type
            #   status
            #   loopName
            #   driverId
            #   vehicleId
            #   trackFlightId
            #   split
            #   pilots
            #   attendants
            #   airportCode
            #   scheduled
            #   scheduledUtc
            #   servicerIataAirlineCode
            #   flightNumber
            #   puLocationId
            #   puActualDatetime
            #   puCompletedDatetime
            #   doLocationId
            #   doActualDatetime
            #   fromManifest
            #   completionId
            #   crewId
            #   rateId
            #   combineId
            #   createdAt
            #   createdBy
            #   updatedAt
            #   updatedBy
            #   deletedAt
            #   deletedBy
            #   isLate
            #   completion {
            #     id
            #     comment
            #     createdAt
            #     createdBy
            #     updatedAt
            #     updatedBy
            #     completionTypeId
            #     datetime
            #   }
            #   provider {
            #     id
            #   }
            #   puLocation {
            #     id
            #   }
            #   doLocation {
            #     id
            #   }
            #   trackFlight {
            #     id
            #   }
            #   driver {
            #     id
            #   }
            #   vehicle {
            #     id
            #   }
            #   remarks {
            #     id
            #   }
            #   fcrs {
            #     id
            #   }
            #   stops {
            #     id
            #   }
            #   ratings {
            #     id
            #   }
            #   issues {
            #     id
            #   }
            #   communications {
            #     id
            #   }
            #   flags {
            #     id
            #   }
            #   rate {
            #     id
            #   }
            # }
            # location {
            #   id
            # }
          }
        }
      }
    }
  `,
};

export default createStopBulk;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popover-Menu {
  font-weight: 600;
  padding: 0 3rem;
  --bs-popover-border-width: 0.25rem;
  --bs-popover-border-color: var(--bs-primary);
}
.Popover-Menu .popover-header {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0rem 0rem;
  background: transparent;
  border-bottom: 1px solid var(--bs-primary);
}
.Popover-Menu .popover-body {
  --bs-popover-body-padding-x: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/EditPopover/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EAUA,kCAAA;EACA,4CAAA;AARF;AAFE;EACE,wEAAA;EACA,uBAAA;EACA,0CAAA;AAIJ;AAFE;EAEE,8BAAA;AAGJ","sourcesContent":[".Popover-Menu {\n  font-weight: 600;\n  padding: 0 3rem;\n  --bs-popover-border-width: 0.25rem;\n  --bs-popover-border-color: var(--bs-primary);\n}\n.Popover-Menu .popover-header {\n  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0rem 0rem;\n  background: transparent;\n  border-bottom: 1px solid var(--bs-primary);\n}\n.Popover-Menu .popover-body {\n  --bs-popover-body-padding-x: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
  ConnectionDetails,
  HandleErrorInput,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails as merge,
} from '@/utils/custom';
import { ConnectionOptions, GraphApiMethod } from '@/api/core';
import { RateGroup, RateGroupSearch, SearchRateGroupResponseRateGroupConnectionArgs } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { SearchRateGroupDocument } from '@/api/queries';
import { TypedDocumentNode } from '@apollo/client';
import { getProperty } from '@/utils';
import graphApi from '@/api';

// Global Service Variables
const mergeKey = 'searchRates.rateGroupConnection';
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Search Rate Group' } });

// Service Function Signature
type ServiceMethodPayload = {
  [key in keyof RateGroupSearch]?: any;
}[];
interface ServiceMethod {
  (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<RateGroup>>;
}

// SearchRateGroup Service
export const { searchRateGroup, refetchRateGroup, useSearchRateGroup } = ((): {
  searchRateGroup: ServiceMethod;
  refetchRateGroup: ServiceMethod;
  useSearchRateGroup: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [fetch, refetch] = graphApi(SearchRateGroupDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<TypedDocumentNode>): ServiceMethod =>
      async (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<RateGroup>> => {
        //console.log(JSON.stringify(payload));
        const args: SearchRateGroupResponseRateGroupConnectionArgs = {
          input: {
            first: options?.pageSize || null,
            after: options?.pageSize > 0 ? (options?.pageSize * options?.page).toString() : null,
            query: payload,
          },
        };
        const opts = { merge: options?.merge ? mergeKey : undefined };
        //console.log({ payload, args });
        const res = await fn(args, opts);
        //console.log({ res });
        return convertConnectionToDetails(getProperty(mergeKey, res));
      }
  );
  const useService = createGraphApiHook(fetch, { refetch, merge });
  return {
    searchRateGroup: fetch,
    refetchRateGroup: refetch,
    useSearchRateGroup: useService,
  };
})();

import { TripConnectionInput } from '../../models/gen/graphql';

import GraphApi from '../../models/GraphApi';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const getTripIds: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, query: any[] = null, options: any = {}): Promise<any> => {
      const input: TripConnectionInput = {
        first,
        after,
        query: query && !!Object.keys(query).length ? query : null,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchTrips.tripConnection'),
  query: gql`
    query SearchTrips($input: TripConnectionInput) {
      searchTrips {
        tripConnection(input: $input) {
          edges {
            cursor
            node {
              id
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default getTripIds;

import { Stop, UpdateStopBulkInput } from '../../models/gen/graphql';
import { customUpdateHandler, generateUpdateBulkPayload, validateObj } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateTrip = validateObj({
  manifestImportId: (val: any): any => val || null,
  crewId: (val: any): any => val || 0,
  providerId: (val: any): any => val || null,
  loopName: (val: any): any => val || null,
  payerProviderId: (val: any): any => val || null,
  rateId: (val: any): any => val || null,
  traceId: (val: any): any => val || null,
  companyId: (val: any): any => '',
  kind: (val: any): any => val || 'ARR',
  type: (val: any): any => val || 'SCH',
  status: (val: any): any => val || 'ACTIVE',
  driverId: (val: any): any => val || null,
  vehicleId: (val: any): any => val || null,
  trackFlightId: (val: any): any => val || null,
  split: (val: any): any => val || 0,
  pilots: (val: any): any => val || 0,
  attendants: (val: any): any => val || 0,
  airportCode: (val: any): any => val || null,
  scheduled: (val: any): any => val || null,
  flightNumber: (val: any): any => val || null,
  puLocationId: (val: any): any => val || null,
  servicerIataAirlineCode: (val: any): any => val || '',
  puActualDatetime: (val: any): any => val || null,
  puCompletedDatetime: (val: any): any => val || null,
  doLocationId: (val: any): any => val || null,
  doActualDatetime: (val: any): any => val || null,
  fromManifest: (val: any): any => val || false,
  completionId: (val: any): any => val || null,
  comments: (val: any): any => val || null,
});

// TODO: Add a `test` method to validateTrip.

const updateStopBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...stops: Stop[]): Promise<any> => {
      const input: UpdateStopBulkInput = {
        updates: generateUpdateBulkPayload(stops),
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): any[] => data?.updateStopBulk?.response || [],
  handler: customUpdateHandler('Update Stop', 'updateStopBulk'),
  query: gql`
    mutation UpdateStopBulk($input: UpdateStopBulkInput!) {
      updateStopBulk(input: $input) {
        response {
          updated
          error {
            code
            debug
            message
          }
        }
      }
    }
  `,
};

export default updateStopBulk;
export { validateTrip };

import { GetVehicleTrackerDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getVehicleTracker = createGraphApiMockResponse(GetVehicleTrackerDocument, {
  getFilters: {
    filters: {
      vehicleTracker: [
        {
          id: 'PHONE_GPS',
          displayName: 'Phone GPS',
          __typename: 'AcceptInputOption',
        },
        {
          id: 'VAN_TRACKING',
          displayName: 'Van Tracking',
          __typename: 'AcceptInputOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

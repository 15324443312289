import { SimpleTableCell, SimpleTableHeader, SimpleTableSortableCell } from '../../../../components/SimpleTable';
import useTripTableState, { TripSortColumnEnum } from './hook';

import { Form } from 'react-bootstrap';
import HasPermission from '../../../../components/HasPermission';
import { SortDirectionEnum } from '@/models/gen/graphql';

const TripsTableHeader = (): React.JSX.Element => {
  const [sorting, onSort] = useTripTableState(({ state: { sorting, onSort } }) => [sorting, onSort]);
  const columnSortMap = sorting.reduce((acc, curr) => {
    acc[curr.column] = curr.direction;
    return acc;
  }, {}) as Record<TripSortColumnEnum, SortDirectionEnum>;

  return (
    <SimpleTableHeader>
      <SimpleTableCell>
        <TripTableSelectHeader />
      </SimpleTableCell>
      <SimpleTableCell>
        <div className="w-100 d-flex justify-content-around">
          <i className="sv sv-flag-empty fs-5 {padding-top:0.05rem;}" />
          <i className="sv sv-message2 fs-5" />
        </div>
      </SimpleTableCell>
      <SimpleTableSortableCell onSort={(): void => onSort(TripSortColumnEnum.type)} direction={columnSortMap[TripSortColumnEnum.type]}>
        Type
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.latestScheduled)}
        direction={columnSortMap[TripSortColumnEnum.latestScheduled]}
      >
        Date
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.latestScheduledUtc)}
        direction={columnSortMap.latestScheduledUtc}
      >
        Sch
      </SimpleTableSortableCell>
      <SimpleTableSortableCell onSort={(): void => onSort(TripSortColumnEnum.actual)} direction={columnSortMap[TripSortColumnEnum.actual]}>
        Act
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.airportCode)}
        direction={columnSortMap[TripSortColumnEnum.airportCode]}
      >
        City
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.servicerIataAirlineCode)}
        direction={columnSortMap.servicerIataAirlineCode}
      >
        Al
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.flightNumber)}
        direction={columnSortMap[TripSortColumnEnum.flightNumber]}
      >
        Flt
      </SimpleTableSortableCell>
      <SimpleTableSortableCell onSort={(): void => onSort(TripSortColumnEnum.pilots)} direction={columnSortMap[TripSortColumnEnum.pilots]}>
        Plt
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.attendants)}
        direction={columnSortMap[TripSortColumnEnum.attendants]}
      >
        FA
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.driverId)}
        direction={columnSortMap[TripSortColumnEnum.driverId]}
      >
        Drv
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.vehicleId)}
        direction={columnSortMap[TripSortColumnEnum.vehicleId]}
      >
        Van
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.puLocationId)}
        direction={columnSortMap[TripSortColumnEnum.puLocationId]}
      >
        P/U
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.doLocationId)}
        direction={columnSortMap[TripSortColumnEnum.doLocationId]}
      >
        D/O
      </SimpleTableSortableCell>
      <SimpleTableSortableCell
        onSort={(): void => onSort(TripSortColumnEnum.payerProvider)}
        direction={columnSortMap[TripSortColumnEnum.payerProvider]}
      >
        Clt
      </SimpleTableSortableCell>
      <SimpleTableCell className="text-center">
        <i className="sv sv-completion fs-5" />
      </SimpleTableCell>
      <SimpleTableCell className="text-center">
        <i className="sv sv-fcr fs-5" />
      </SimpleTableCell>
      <SimpleTableCell className="text-center">
        <i className="sv sv-combine-icon fs-5 text-secondary" />
      </SimpleTableCell>
      {HasPermission.check('allowSearchTripsByRate') && (
        <SimpleTableSortableCell
          onSort={(): void => onSort(TripSortColumnEnum.rateAmount)}
          direction={columnSortMap[TripSortColumnEnum.rateAmount]}
        >
          Rate
        </SimpleTableSortableCell>
      )}
    </SimpleTableHeader>
  );
};

const TripTableSelectHeader = (): React.JSX.Element => {
  const [trips, selected, onSelectAll] = useTripTableState(({ state }) => [state.trips, state.selected, state.onSelectAll]);
  return <Form.Check checked={trips.size === selected.size} onChange={onSelectAll} style={{ transform: 'scale(1.25)' }} />;
};

export default TripsTableHeader;

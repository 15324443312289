import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { QueryInputType, queryInput } from '@/utils/custom';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { SortDirectionEnum, User } from 'models/gen/graphql';
import { UserWithAvailabilitySearch, useSearchUsersWithAvailability } from '@/api/services/users/searchUsers';
import VirtualTable, { DynamicCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';
import { getClasses, parsePhoneNumber } from '@/utils/strings';

import AvailabilityDisplay from '@/components/AvailabilityDisplay';
import AvailabilityFilters from './AvailabilityFilters';
import { CURRENT_DATE } from '@/constants';
import { Datetime } from '@/utils/dates';
import ImageDisplay from '@/components/ImageDisplay';
import PageInfo from '@/components/PageInfo';
import { Validation } from '@/utils/validations';

const DAYS_OF_THE_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

type AvailabilityTableState = {
  search: string;
  sortDate: string;
  dayOfWeek: number;
  startTimeSortDirection: SortDirectionEnum;
  from: string;
  to: string;
};

const initAvailabilityTableState: AvailabilityTableState = {
  search: '',
  sortDate: new Datetime(CURRENT_DATE).dateInput,
  dayOfWeek: new Datetime(CURRENT_DATE).day,
  startTimeSortDirection: SortDirectionEnum.Asc,
  from: new Datetime(CURRENT_DATE.clone().startOf('week')).setTime('00:00:00').toString(),
  to: new Datetime(CURRENT_DATE.clone().endOf('week')).setTime('23:59:59').toString(),
};

const AvailabilityTable = (): JSX.Element => {
  const [state, setState] = useState({ ...initAvailabilityTableState });
  const { startTimeSortDirection, sortDate, from, search } = state;
  const appliedQuery = useRef(undefined);
  const [{ data, loading }, { refetch }] = useSearchUsersWithAvailability();
  const { rows = [] } = data || {};
  const { filteredRows } = useVirtualTable(setState, { rows, search });
  const onSubmit = useCallback(
    async (filters) => {
      const query = {
        user: {
          roleId: Validation.isValidUUID(filters?.roleId) ? queryInput(filters?.roleId) : null,
          firstName: queryInput(
            [],
            QueryInputType.DEFAULT,
            startTimeSortDirection === SortDirectionEnum.Asc && !!rows.length ? SortDirectionEnum.Asc : SortDirectionEnum.Desc,
            0
          ),
        },
        availability: {
          startTime: queryInput(
            [],
            QueryInputType.DEFAULT,
            startTimeSortDirection === SortDirectionEnum.Asc && !!rows.length ? SortDirectionEnum.Asc : SortDirectionEnum.Desc,
            0
          ),
          approved: filters?.viewRequests ? queryInput([], QueryInputType.ISNULL) : null,
        },
      };
      const airportCodes = filters?.city?.length ? filters?.city : null;

      setState(
        (current: AvailabilityTableState): AvailabilityTableState => ({
          ...current,
          sortDate: filters?.sortDate || new Datetime(filters?.from).add(current?.dayOfWeek, 'days').dateInput,
          from: filters?.from,
          to: filters?.to,
        })
      );

      const search: UserWithAvailabilitySearch = {
        query: [query?.user],
        availabilityQuery: [query?.availability],
        airportCodes,
        sortDate: filters?.sortDate || sortDate,
        startDatetime: filters?.from,
        endDatetime: filters?.to,
      };

      await refetch(search);

      appliedQuery.current = {
        filters,
        query,
        airportCodes,
        sortDate: filters?.sortDate || sortDate,
        from: filters?.from,
        to: filters?.to,
      };
    },
    [startTimeSortDirection, rows.length, sortDate, refetch]
  );

  const onSortDayOfWeek = useCallback(
    (i: number): any =>
      (): void => {
        const date = new Datetime(appliedQuery?.current?.from).startOf('week').add(i, 'days');
        const output = {
          ...state,
          startTimeSortDirection: startTimeSortDirection === SortDirectionEnum.Asc ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
        };
        if (date.dateInput !== sortDate) {
          output.sortDate = date.dateInput;
          output.dayOfWeek = i;
          output.startTimeSortDirection = SortDirectionEnum.Asc;
        }
        setState(output);
        onSubmit({ ...appliedQuery?.current?.filters, sortDate: output?.sortDate });
      },
    [state, startTimeSortDirection, sortDate, onSubmit]
  );
  const onRefetch = useCallback(async (): Promise<any> => await refetch(), [refetch]);

  const handleTableSearch = ({ search }: any): void =>
    setState((current: AvailabilityTableState): AvailabilityTableState => ({ ...current, search }));

  const headers = DAYS_OF_THE_WEEK.reduce((acc: {}, cur: string, i: number): Record<string, React.JSX.Element> => {
    acc[cur] = (
      <div className="AvailabilityTable-Header d-flex gap-2 justify-content-center">
        <Button className="d-flex flex-column" variant="icon" onClick={() => onSortDayOfWeek(i)()}>
          <strong>{new Datetime(appliedQuery?.current?.from).startOf('week').add(i, 'days').format('dddd')}</strong>
          <strong>{new Datetime(appliedQuery?.current?.from).startOf('week').add(i, 'days').format('MMMM D')}</strong>
        </Button>
        <div className="d-flex flex-column justify-content-center">
          {i !== new Datetime(sortDate).day ? (
            <i className="fa fa-sort opacity-25" />
          ) : (
            <i className={`fa fa-caret-${startTimeSortDirection === SortDirectionEnum.Desc ? 'down' : 'up'}`} />
          )}
        </div>
      </div>
    );
    return acc;
  }, {});

  const renderCell = useCallback(
    (i: number): (({ data }) => JSX.Element) =>
      function renderCell({ data }: { data: User }): JSX.Element {
        return (
          <div className="AvailabilityTable-Cell">
            <AvailabilityDisplay user={data} date={from} refetchParent={onRefetch} index={i} />
          </div>
        );
      },
    [from, onRefetch]
  );

  const renderRow = useCallback(
    ({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
      <VirtualTableRow
        context={{
          ...context,
          rowType: _type,
          data,
          index,
          className: getClasses('VirtualTable-Row', 'd-flex', context?.className),
        }}
      >
        <DynamicCell
          selector="user"
          placeholder="--"
          className="text-center alternate"
          width="calc(200% / 8)"
          render={({ data: user }: { data: User }): ReactNode => (
            <Row className="m-0 flex-nowrap">
              <Col className="profile-image d-none d-lg-flex">
                <ImageDisplay className="{width:4.5rem!;height:4.5rem!;}" src={user?.avatar} circle />
              </Col>
              <Col xs={12} md={8} className="profile-info flex-grow-1 ps-1 pe-0">
                <p className="m-0 text-center text-xl-start">
                  <strong className="d-block">
                    {user?.firstName || ''} {user?.lastName || ''}
                  </strong>
                  <small className="d-block">{parsePhoneNumber(user?.phone || '', ' ') || '-'}</small>
                  <small className="d-block employee-id">{user?.employeeId ? `#${user?.employeeId}` : '-'}</small>
                </p>
              </Col>
            </Row>
          )}
          options={{ showTooltip: false }}
        />
        {DAYS_OF_THE_WEEK.map(
          (dayOfWeek: string, i: number): JSX.Element => (
            <DynamicCell
              selector={dayOfWeek}
              placeholder="--"
              className={getClasses('text-center', i % 2 > 0 ? 'alternate' : '')}
              width="calc(100% / 8)"
              render={renderCell(i)}
              options={{ showTooltip: false }}
              key={dayOfWeek}
            />
          )
        )}
      </VirtualTableRow>
    ),
    [renderCell]
  );

  return (
    <div className="d-flex flex-column">
      <AvailabilityFilters
        dayOfWeek={state?.dayOfWeek}
        onSubmit={onSubmit}
        onReset={(): void => setState((current: AvailabilityTableState): AvailabilityTableState => ({ ...current, search: '' }))}
        onFilter={handleTableSearch}
      />
      <PageInfo>Total Users: {filteredRows?.length}</PageInfo>
      <VirtualTable
        name="availabilityTable"
        data={filteredRows}
        loading={loading}
        className="AvailabilityTable"
        header={{
          user: 'User',
          ...headers,
        }}
        rowRenderer={renderRow}
      />
    </div>
  );
};

export default AvailabilityTable;

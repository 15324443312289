import { useRef, useState } from 'react';

import { stringify } from '../utils';
import useEventListener from './useEventListener';
import useTimeout from './useTimeout';

type UseViewportState = {
  width: number;
  height: number;
  header: { width: number; height: number };
  content: { width: number; height: number };
  footer: { width: number; height: number };
};
type UseViewportReturn = [UseViewportState, () => void];
const initUseViewportState = {
  width: 0,
  height: 0,
  header: { width: 0, height: 0 },
  content: { width: 0, height: 0 },
  footer: { width: 0, height: 0 },
};
const useViewport = (): UseViewportReturn => {
  const [sizes, setSizes] = useState<UseViewportState>(initUseViewportState);
  const headerContainer = useRef<HTMLDivElement>(document.querySelector('#root > .Header'));
  const contentContainer = useRef<HTMLDivElement>(document.querySelector('#root > .RouteContent'));
  const footerContainer = useRef<HTMLDivElement>(document.querySelector('#root > #RouteContent-Footer'));

  const handleResize = (): void => {
    if (!headerContainer.current?.offsetWidth) {
      const header = document.querySelector('#root > .Header');
      headerContainer.current = header ? (header as HTMLDivElement) : undefined;
    }
    if (!contentContainer.current?.offsetWidth) {
      const container = document.querySelector('#root > .RouteContent');
      contentContainer.current = container ? (container as HTMLDivElement) : undefined;
    }
    if (!footerContainer.current?.offsetWidth) {
      const footer = document.querySelector('#root > #RouteContent-Footer');
      footerContainer.current = footer ? (footer as HTMLDivElement) : undefined;
    }
    const headerContainerWidth = headerContainer.current?.offsetWidth || 0;
    const headerContainerHeight = headerContainer.current?.offsetHeight || 0;
    const contentContainerWidth = contentContainer.current?.offsetWidth || 0;
    const contentContainerHeight = contentContainer.current?.offsetHeight || 0;
    const footerContainerHeight = footerContainer.current?.offsetHeight || 0;
    const footerContainerWidth = footerContainer.current?.offsetWidth || 0;
    const fullContentHeight = contentContainerHeight + footerContainerHeight;
    const update = {
      width: contentContainerWidth,
      height: fullContentHeight,
      header: { width: headerContainerWidth, height: headerContainerHeight },
      content: { width: contentContainerWidth, height: contentContainerHeight },
      footer: { width: footerContainerWidth, height: footerContainerHeight },
    };
    if (stringify.compare(sizes, update)) return;
    setSizes(update);
  };

  useEventListener('visibilitychange', handleResize);
  useEventListener('resize', handleResize);
  useTimeout(handleResize, 750);

  return [sizes, handleResize];
};

export default useViewport;

import './styles.scss';

import { Button, Col, Dropdown, Form, InputGroup, Row } from 'react-bootstrap';
import { Invoice, InvoiceLineItem } from '../../models/gen/graphql';
import React, { useEffect, useRef } from 'react';

import { LineItemTypeId } from '../../models/constants';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { OnChange } from '../../hooks/useOnChange';
import Portal from '../../components/Portal';
import Tippy from '@tippyjs/react';
import { formatDollars } from '../../utils/numbers';
import { stringify } from '../../utils/objects';
import { useAppState } from '../../store/appReducer';
import { useGetLineItemTypes } from '@/api/services/filters/lineItemTypes';

const InvoicePreviewTotals = ({
  data = {},
  items = [],
  onChange,
  loading = false,
}: {
  data: Partial<Invoice>;
  items?: Array<Partial<InvoiceLineItem>>;
  onChange: OnChange;
  loading?: boolean;
}): JSX.Element => {
  const [{ config: { genericLineItemTypeId = undefined } = {} }] = useAppState();
  const { total = 0, grandTotal = 0 } = data;
  const [{ data: lineItemTypes = [] }, { fetch: getLineItemTypes }] = useGetLineItemTypes();
  const lastItemsAndTotal = useRef({ items, total });
  const recalculateTotals = (items: any, total): void => {
    const newItems = items.map((item: any): any => {
      // resolve any line items that dont have a total
      if (!parseFloat(item?.total)) return { ...item, total: '0' };
      // if item is COMBINE_DISCOUT make sure the total is negative
      if (item?.lineItemTypeId === LineItemTypeId.COMBINE_DISCOUNT) return { ...item, total: Math.abs(parseFloat(item?.total)) };
      // ---
      // if the total is a percentage, calculate the new total (not setup on the backend yet)
      // if (item?.total?.endsWith?.('%')) return { ...item, total: `${(parseFloat(item?.total) / 100) * total}` };
      // ---
      return item;
    });
    let newGrandTotal: any = parseFloat(total || '0');
    // if any line item contains NET_15_DISCOUNT line item type, we need to factor in the discount
    items
      .filter((item: any): boolean => item?.lineItemTypeId === LineItemTypeId.NET_15_DISCOUNT)
      .forEach((): number => (newGrandTotal *= 0.98));
    newGrandTotal = newItems.reduce((acc: any, next: any): any => {
      // if any line item contains NET_15_DISCOUNT line item type, do not add it to the subtotal
      if (next?.lineItemTypeId === LineItemTypeId.NET_15_DISCOUNT) return acc;
      // if any line item contains COMBINE_DISCOUNT line item type, we need to factor in the discount
      if (next?.lineItemTypeId === LineItemTypeId.COMBINE_DISCOUNT) return acc - Math.abs(parseFloat(next?.total || '0'));
      // add any other line items to the grandTotal
      return acc + Math.abs(parseFloat(next?.total || '0'));
    }, newGrandTotal);
    newGrandTotal = parseFloat(newGrandTotal.toFixed(2));
    onChange({ target: { name: 'grandTotal', value: newGrandTotal } });
  };

  useEffect((): void => {
    getLineItemTypes();
  }, []);
  useEffect((): void => {
    if (stringify.compare(lastItemsAndTotal.current, { items, total })) return;
    lastItemsAndTotal.current = { items, total };
    recalculateTotals(items, total);
  }, [items, total]);

  return (
    <Portal container={document.getElementById('RouteContent-Footer')}>
      <div className="InvoicePreviewTotals d-flex flex-column justify-content-end w-100 px-2 {background-color:#fff;}">
        <Row className="summary-row border-top border-3">
          <Col md={11} className="d-flex align-items-center justify-content-end {border-right:1px|solid|#e0e0e0}">
            <strong>TOTAL:</strong>
          </Col>
          <Col md={1} className="d-flex align-items-center justify-content-center">
            <LoadingBlur size="sm" loading={loading} />
            <strong>USD&nbsp;{formatDollars(`${total || '0'}`)}</strong>
          </Col>
        </Row>
        <Row className="flex-grow-1 {max-height:11rem;overflow:auto;}">
          <Col>
            {items?.map?.(
              (item: any, i: number): JSX.Element => (
                <LineItem
                  key={i}
                  name={item?.name}
                  value={`USD ${item?.total}`}
                  type={item?.lineItemTypeId}
                  onChange={(event: any): void =>
                    onChange({
                      target: {
                        name: 'items',
                        value: items.map((item: InvoiceLineItem, index: number) => {
                          const target = event?.target ?? {};
                          const { name } = target;
                          let { value } = target;
                          if (name === 'total') value = (value || '').replace(/[^\d.]/g, '');
                          return i === index ? { ...item, [name]: value } : item;
                        }),
                      },
                    })
                  }
                  onRemove={(): void =>
                    onChange({ target: { name: 'items', value: items.filter((_: any, j: number): boolean => i !== j) } })
                  }
                />
              )
            )}
          </Col>
        </Row>
        <Row className="summary-row">
          <Col className="d-flex align-items-center justify-content-end pe-3">
            <Dropdown drop="up">
              <Dropdown.Toggle variant="success" id="dropdown-basic" as={LineItemTypeToggle} />
              <Portal container={document.body}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(): void =>
                      onChange({
                        target: { name: 'items', value: [...items, { name: '', total: 0, lineItemTypeId: genericLineItemTypeId }] },
                      })
                    }
                  >
                    <i className="fa fa-plus" /> Add New Item
                  </Dropdown.Item>
                  {lineItemTypes
                    ?.filter(({ id }: { id: string }): boolean => id !== genericLineItemTypeId)
                    ?.map(
                      ({ displayName, id }: { displayName: string; id: string }, l: number): JSX.Element => (
                        <Dropdown.Item
                          onClick={(): void =>
                            onChange({
                              target: {
                                name: 'items',
                                value: [
                                  ...(items || []),
                                  {
                                    name: displayName,
                                    total: id === LineItemTypeId.NET_15_DISCOUNT ? ((total || 0) * 0.02).toFixed(2) : 0,
                                    lineItemTypeId: id,
                                  },
                                ],
                              },
                            })
                          }
                          key={l}
                        >
                          <small>{displayName}</small>
                        </Dropdown.Item>
                      )
                    )}
                </Dropdown.Menu>
              </Portal>
            </Dropdown>
          </Col>
        </Row>
        <Row className="summary-row rounded-bottom overflow-hidden">
          <Col md={11} className="d-flex align-items-center justify-content-end bg-success bg-opacity-25 text-dark">
            <strong>GRAND TOTAL:</strong>
          </Col>
          <Col md={1} className="d-flex align-items-center justify-content-center bg-success bg-opacity-10 text-dark" name="GRAND_TOTAL">
            <LoadingBlur size="sm" loading={loading} />
            <span key={grandTotal}>USD&nbsp;{formatDollars(`${grandTotal || '0'}`)}</span>
          </Col>
        </Row>
      </div>
    </Portal>
  );
};
const LineItemTypeToggle = React.forwardRef(
  ({ children, onClick }: any, ref: any): JSX.Element => (
    <Tippy content="Add Line Item" placement="left">
      <Button variant="success" size="sm" className="transform:scale(0.65);translate:5px;" onClick={onClick} ref={ref} name="ADD_LINE_ITEM">
        <i className="fa fa-plus" />
        {children}
      </Button>
    </Tippy>
  )
);
LineItemTypeToggle.displayName = 'LineItemTypeToggle';

const LineItem = ({
  name,
  value,
  type,
  onChange,
  onRemove,
}: {
  name: string;
  value: string;
  type: LineItemTypeId;
  onChange: (event: any) => void;
  onRemove?: (event: any) => void;
}): JSX.Element => (
  <Row className="summary-row d-flex justify-content-end {color:#ccc!;font-style:italic!;}_*::placeholder">
    <Col md={11} className="d-flex align-items-center justify-content-end {border-right:1px|solid|#e0e0e0}">
      <Form.Control
        name="name"
        className={`{font-weight:bold!;outline:none!;border:0!;background:transparent!;} ${
          type === LineItemTypeId.NET_15_DISCOUNT ? 'cursor:not-allowed;' : ''
        } text-end shadow-none`}
        size="sm"
        value={name}
        placeholder="Name"
        onChange={onChange}
        readOnly={type === LineItemTypeId.NET_15_DISCOUNT}
        onFocus={(event: any): void => event.target.select()}
      />
    </Col>
    <Col md={1} className="d-flex align-items-center justify-content-center">
      <InputGroup
        size="sm"
        className={`${type === LineItemTypeId.NET_15_DISCOUNT || type === LineItemTypeId.COMBINE_DISCOUNT ? 'discount' : ''}`}
      >
        <Form.Control
          name="total"
          className={`{outline:none!;border:0!;background:transparent!;${
            type === LineItemTypeId.NET_15_DISCOUNT ? 'cursor:not-allowed;' : ''
          }} text-end shadow-none`}
          value={value}
          onChange={onChange}
          readOnly={type === LineItemTypeId.NET_15_DISCOUNT}
          onFocus={type !== LineItemTypeId.NET_15_DISCOUNT ? (event: any): void => event.target.select() : undefined}
        />
        <Tippy content="Remove Item" placement="left">
          <span>
            <Button className="{transform:scale(0.65);}" variant="danger" size="sm" onClick={onRemove}>
              <i className="fa fa-times" />
            </Button>
          </span>
        </Tippy>
      </InputGroup>
    </Col>
  </Row>
);

export default React.memo(InvoicePreviewTotals);

import { getLocalStorage, uuid } from '@/utils';

export const generatePayload = (
  eventType: string,
  payload?: Record<string, unknown>,
  overrides?: Record<string, unknown>
): Record<string, unknown> => ({
  id: uuid(),
  ...(overrides || {}),
  eventType,
  timestamp: Math.round(new Date().getTime() / 1000),
  source: {
    url: window.location.href,
    environment: JSON.parse(getLocalStorage('persist:app')?.config || '{}')?.env || null,
    userId: JSON.parse(getLocalStorage('persist:app')?.session || '{}')?.user?.id || null,
    userAgent: navigator.userAgent,
    ...((overrides?.source as Record<string, unknown>) || {}),
  },
  payload: payload,
});

export const getUserId = (): string | null => JSON.parse(getLocalStorage('persist:app')?.session || '{}')?.user?.id || null;

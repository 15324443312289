import { CreateVariableTimeInput, VariableTime } from '@/models/gen/graphql';
import { HandleErrorInput, handleError } from '@/utils/custom';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { CreateVariableTimeBulkDocument } from '@/api/queries';
import { GraphApiMethod } from '@/api/core';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Create Variable Time' } });

type ServiceMethodPayload = { [key in keyof CreateVariableTimeInput]: any }[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<VariableTime[]>;
}

// CreateVariableTimeBulk Service
export const { createVariableTimeBulk, useCreateVariableTimeBulk } = ((): {
  createVariableTimeBulk: ServiceMethod;
  useCreateVariableTimeBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(CreateVariableTimeBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof CreateVariableTimeBulkDocument>): ServiceMethod =>
      async (values: ServiceMethodPayload): Promise<VariableTime[]> => {
        const res = await fn({ input: { values } });
        return res?.createVariableTimeBulk?.output?.map(({ node }: { node: VariableTime }): VariableTime => node) || [];
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    createVariableTimeBulk: mutate,
    useCreateVariableTimeBulk: useService,
  };
})();

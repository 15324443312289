import { Datetime, diffKey } from './index';
import {
  VALID_ALPHA,
  VALID_ALPHANUMERIC,
  VALID_EMAIL,
  VALID_FULL_TIME_STRING,
  VALID_NUMERIC,
  VALID_TIME_STRING,
  VALID_UUID,
  VALID_WEBSITE,
} from '../constants';

export namespace Validation {
  export type Method = (value: any, context?: any) => Validity;
  export type Input = { [key: string]: any };
  export type ValidityResponse = { [key: string]: Validity };
  export enum ValidityType {
    INVARIANT = 0,
    VALID = 1,
    INVALID = 2,
    WARNING = 3,
    VALID_WARNING = 4,
    INVALID_WARNING = 5,
  }
  const invalid = [ValidityType.INVALID, ValidityType.INVALID_WARNING, false];
  const valid = [ValidityType.VALID, ValidityType.VALID_WARNING, true];
  export type Validity = {
    valid: ValidityType | undefined | boolean; // TODO: Update all uses of this to use the new ValidityType enum and remove "undefined | boolean" from this type.
    value?: any;
    message?: string;
    context?: any;
    defaultValue?: any;
  };
  export type Field = {
    method: Method;
    required: boolean;
    defaultValue: any;
  };
  export const isValid = (validity: Validity): undefined | boolean => {
    const validArray = Object.values(validity).map(({ valid }: Validity): ValidityType | undefined | boolean => valid);
    if (validArray.includes(ValidityType.INVALID) || validArray.includes(ValidityType.INVALID_WARNING) || validArray.includes(false))
      return false;
    if (validArray.includes(ValidityType.VALID) || validArray.includes(ValidityType.VALID_WARNING) || validArray.includes(true))
      return true;
    return undefined;
  };
  export const convertValidityTypeToBoolean = (validity: ValidityType | boolean): undefined | boolean => {
    if (typeof validity === 'boolean') return validity;
    if (valid.includes(validity)) return true;
    if (invalid.includes(validity)) return false;
    return undefined;
  };
  export class Validator {
    private fields: {
      [key: string]: Field;
    };
    valid: undefined | boolean = undefined;
    constructor(fields: { [key: string]: Method }, ...extensions: Validator[]) {
      this.fields = {};
      extensions.forEach((extension: Validator): void => {
        this.fields = { ...this.fields, ...(extension?.fields || {}) };
      });
      Object.entries(fields).forEach(([key, validationMethod]: [string, Method]): void => {
        let response;
        try {
          response = validationMethod(undefined);
        } catch (err) {
          response = {};
        }
        this.fields[key.replace(/!$/, '')] = {
          method: validationMethod,
          required: key.endsWith('!'),
          defaultValue: response?.defaultValue,
        };
      });
    }
    validate(input: any = {}, middleware?: { [key: string]: Method }): ValidityResponse {
      const result: ValidityResponse = {};
      Object.entries(this.fields).forEach(([key, field]: [string, any]): void => {
        const value = input?.[key];
        let response: Validity = { value, valid: undefined };
        try {
          response = {
            ...response,
            ...field.method(value),
          };
        } catch (err) {
          response.message = err?.message || `Unknown "${key}" validation error.`;
          response.valid = this.fields?.[key]?.required ? ValidityType.INVALID : ValidityType.INVARIANT;
        }
        if (middleware?.[key])
          response = middleware[key](value, {
            response,
          });
        result[key] = response;
      });
      const validityArray = Array.from(
        new Set(Object.values(result).map(({ valid }: Validity): ValidityType | undefined | boolean => valid))
      );
      this.valid =
        validityArray.includes(false) ||
        validityArray.includes(ValidityType.INVALID) ||
        validityArray.includes(ValidityType.INVALID_WARNING)
          ? false
          : validityArray.includes(true) || validityArray.includes(ValidityType.VALID) || validityArray.includes(ValidityType.VALID_WARNING)
            ? true
            : undefined;
      return result;
    }
    create(input: any = {}, middleware?: { [key: string]: any }): any {
      const result = {};
      Object.entries(input)
        .filter(
          ([key, value]: [string, any]): boolean => Object.keys({ ...this.fields, ...middleware }).includes(key) && value !== undefined
        )
        .forEach(([key, value]: [string, any]): void => {
          result[key] = value;
        });
      Object.entries(this.fields).forEach(([key, field]: [string, Field]): void => {
        let response = field?.method(result?.[key]);
        if (middleware?.[key])
          response = middleware[key](result[key], {
            response,
          });
        const value = response?.valid ? result?.[key] : !Validation.isNil(field?.defaultValue) ? field?.defaultValue : null;
        result[key] = value;
      });
      return result;
    }
    partial(input: any): any {
      const result = {};
      const full = this.create(input);
      Object.keys(input)
        .filter((key: string): boolean => this.keys.includes(key))
        .forEach((key: string): any => (result[key] = full[key]));
      return result;
    }
    get keys(): string[] {
      return Object.keys(this.fields);
    }
  }
  export const isNil = (val: any): boolean => val === undefined || val === null;
  export const isValidUUID = (str: string): boolean => !!VALID_UUID.test(str);
  export const isValidURL = (str: string): boolean => !!VALID_WEBSITE.test(str);
  export const isTruthy = (data: any): boolean => {
    // check data type
    switch (typeof data) {
      case 'object':
        if (Array.isArray(data)) return !!data?.length;
        else return !!Object.keys(data || {})?.length;
      default:
        return !!data;
    }
  };
  export const isDate = (date: string | number | Date): boolean => !!date && !!new Datetime(date).toString();
  export const isNumber = (val: any): boolean => typeof val === 'number' && !isNaN(val);
  export const isWithinThreshold = (val: number, min: number, max: number): boolean => min <= val && val <= max;
  export const isEmail = (str: string = ''): boolean => !!VALID_EMAIL.test(str);
  export const isAlphanumeric = (val: any): boolean => !!VALID_ALPHANUMERIC.test(val);
  export const isNumeric = (val: any): boolean => !!VALID_NUMERIC.test(val);
  export const isAlpha = (val: any): boolean => !!VALID_ALPHA.test(val);
  export const isCode = (val: any, length: number = 2): boolean => new RegExp(`^[A-Z]{${length}}`).test(val || '');
  export const isTimeString = (val: unknown): boolean => (typeof val !== 'string' ? false : !!VALID_TIME_STRING.test(val || ''));
  export const isFullTimeString = (val: unknown): boolean => (typeof val !== 'string' ? false : !!VALID_FULL_TIME_STRING.test(val || ''));
  export const isJson = (input: any): boolean => {
    try {
      if (typeof input !== 'string') input = JSON.stringify(input);
      JSON.parse(input);
      return true;
    } catch (err) {
      return false;
    }
  };
}

const getValidityType = (isValid: undefined | boolean, diff: boolean = false): Validation.ValidityType => {
  if (isValid === undefined) return Validation.ValidityType.INVARIANT;
  if (diff) return isValid ? Validation.ValidityType.VALID_WARNING : Validation.ValidityType.INVALID_WARNING;
  return isValid ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID;
};
//TODO: move to Validator class
export const fieldValidator = (
  object: any = {},
  objectArray: any[] = [],
  validationCriteria: Record<string, (value: any) => Validation.Validity> = {}
): any => {
  const cleanedValidationCriteria = {};
  Object.entries(validationCriteria).forEach(([key, val]: [string, (value: any) => Validation.Validity]): void => {
    cleanedValidationCriteria[key.replace(/!$/, '')] = val;
  });
  const validateKey = (key: string, val: any): any => {
    const isDiff = diffKey(key, [object, ...Object.values(objectArray)]).length > 1;
    const isValid = cleanedValidationCriteria?.[key]?.(val)?.valid;
    const message = cleanedValidationCriteria?.[key]?.(val)?.message || '';
    return { valid: getValidityType(isValid, isDiff), message };
  };
  const result = {};
  Object.keys(cleanedValidationCriteria).forEach((key: string): any => {
    result[key] = validateKey(key, object?.[key]);
  });
  return result;
};

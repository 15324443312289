import './styles.scss';

import { Col, Popover, Row } from 'react-bootstrap';
import { getClasses, parsePhoneNumber } from '../../utils/strings';

import CopyButton from '../CopyButton';
import ImageDisplay from '../ImageDisplay';
import React from 'react';
import { User } from '../../models/gen/graphql';

type Props = { data: User; [x: string]: any };
const EditAvailabilityHeader = ({ data: user, ...props }: Props): React.JSX.Element => {
  const classes = getClasses('EditAvailabilityHeader', props?.className);
  return (
    <Popover.Header {...props} className={classes}>
      <Row xs={'auto'} className="flex-nowrap">
        {/* Profile Image Display */}
        <Col>
          <ImageDisplay src={user?.avatar} circle className="border-primary" />
        </Col>
        <Col className="d-flex flex-column align-items-start justify-content-center">
          {!!user?.employeeId && (
            <span>
              #{user?.employeeId} <CopyButton className="ms-1" text={user?.employeeId} />
            </span>
          )}
          <span>
            {user?.firstName} {user?.lastName}
          </span>
          {!!user?.phone && (
            <span>
              {parsePhoneNumber(user?.phone, ' ')} <CopyButton className="ms-1" text={parsePhoneNumber(user?.phone, ' ')} />
            </span>
          )}
        </Col>
      </Row>
    </Popover.Header>
  );
};
export default EditAvailabilityHeader;

import './styles.scss';

import React, { useCallback } from 'react';
import SimpleTable, { SimpleTableProps, SimpleTableRowRendererProps } from '@/components/SimpleTable';
import TripsTableRow, { TripsTableRowEventHandlers } from './TripTableRow';

import HasPermission from '@/components/HasPermission';
import TripsTableHeader from './TripTableHeader';

const COLUMN_SIZES = (() => {
  const output = {
    select: '2rem',
    flagCom: '1fr',
    type: '1fr',
    scheduledDate: '1fr',
    scheduledTime: '1fr',
    actual: '1fr',
    airportCode: '1fr',
    servicerIataAirlineCode: '1fr',
    flightNumber: '0.75fr',
    pilots: '0.75fr',
    attendants: '0.75fr',
    driverId: '2fr',
    vehicleId: '2fr',
    puLocation: '2fr',
    doLocation: '2fr',
    payerProvider: '2fr',
    completionButton: '0.75fr',
    fcrButton: '0.75fr',
    combineButton: '0.75fr',
    rate: '1.5fr',
  };
  if (!HasPermission.check('allowSearchTripsByRate')) delete output.rate;
  return output;
})();

export type TripsTableProps = {
  ids: string[];
} & Omit<SimpleTableProps, 'columns' | 'header' | 'row' | 'data'> &
  TripsTableRowEventHandlers;

const TripsTable = ({
  ids,
  onEditTrip,
  onEditFlag,
  onEditCommunication,
  onEditCompletion,
  onEditFcr,
  onEditCombine,
  onEditRateReport,
  confirmIllegalCombines,
  ...simpleTableProps
}: TripsTableProps): JSX.Element => {
  const TripRow = useCallback(
    React.forwardRef(
      (props: SimpleTableRowRendererProps, ref: React.ForwardedRef<HTMLDivElement>): React.JSX.Element => (
        <TripsTableRow
          {...props}
          ref={ref}
          onEditTrip={onEditTrip}
          onEditFlag={onEditFlag}
          onEditCommunication={onEditCommunication}
          onEditCompletion={onEditCompletion}
          onEditFcr={onEditFcr}
          onEditCombine={onEditCombine}
          onEditRateReport={onEditRateReport}
          confirmIllegalCombines={confirmIllegalCombines}
        />
      )
    ),
    [onEditTrip, onEditFlag, onEditCommunication, onEditCompletion, onEditFcr, onEditCombine, onEditRateReport, confirmIllegalCombines]
  );

  return (
    <SimpleTable
      {...simpleTableProps}
      className={`TripsTable ${simpleTableProps.className}`}
      columns={COLUMN_SIZES}
      header={TripsTableHeader}
      row={TripRow}
      data={ids}
    />
  );
};

export default React.memo(TripsTable);

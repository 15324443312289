import React from 'react';
import { createWidget } from '../utils';

export const Payroll = createWidget({
  key: 'payroll_demo',
  label: 'Payroll (Demo)',
  description: (
    <>
      <p>Display payroll information.</p>
      <p>
        <small>
          <em>This is a demo widget with no functionality.</em>
        </small>
      </p>
    </>
  ),
  icon: <i className="sv sv-chart-growth" />,
  component: ({ options }) => {
    return (
      <div className="w-100 h-100 text-center p-2">
        <img src="/public/assets/images/RingChart.png" />
      </div>
    );
  },
  options: {
    settings: { minW: 2, minH: 6 },
  },
});

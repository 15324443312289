import { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import Logger from '../../utils/logs';

const log = Logger.of('ActiveSwitch');

type ActiveSwitchProps = {
  title: string;
  value: boolean;
  onHide: () => void;
  onSubmit: (input: boolean) => Promise<void>;
};

const ActiveSwitch = ({ title, value, onHide, onSubmit }: ActiveSwitchProps): React.JSX.Element => {
  const [checked, setChecked] = useState(value);
  useEffect((): void => {
    if (value === checked) return;
    setChecked(value);
  }, [value, checked]);

  return (
    <Form.Switch
      role="switch"
      className="ActiveSwitch"
      checked={checked}
      onChange={async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const isChecked = e?.target?.checked;
        setChecked(isChecked);
        onSubmit(isChecked)
          .catch((err: Error): void => {
            setChecked((current: boolean): boolean => !current);
            log.error(err).notify({ title });
          })
          .finally(() => {
            onHide();
          });
      }}
    />
  );
};

export default ActiveSwitch;

import GlassContainer from '../GlassContainer';
import { Middle } from '../Align';
import React from 'react';

const DefaultError = (): JSX.Element => {
  return (
    <div className="h-100 w-100 {background-image:url(/public/assets/images/Skyplan_Logo1.png);background-repeat:no-repeat;background-position:50%|20%;background-size:25%;}">
      <Middle.Center>
        <GlassContainer>
          <h1 className="m-0 p-0 text-center">
            Something went wrong.
            <small className="d-block mt-2">
              <em>
                Click <a onClick={(): void => window.location.reload()}>here</a> to refresh and try again.
              </em>
            </small>
          </h1>
        </GlassContainer>
      </Middle.Center>
    </div>
  );
};

export default DefaultError;

import { RunRateOverridePreviewInput, RunRateOverridePreviewResponse } from '@/models/gen/graphql';

import { RunRateOverridePreviewDocument } from '@/api/queries';
import graphApi from '@/api';
import { Datetime } from '@/utils';

const [getRateOverridePreview] = graphApi(RunRateOverridePreviewDocument);

const runRateOverridePreview = async (input: RunRateOverridePreviewInput): Promise<RunRateOverridePreviewResponse> => {
  try {
    input.startDatetime = new Datetime(input.startDatetime).toString();
    input.endDatetime = new Datetime(input.endDatetime).toString();
    const res = await getRateOverridePreview({ input });
    return res?.runRateOverridePreview;
  } catch (err) {
    console.log(err);
  }
};

export default runRateOverridePreview;

import { RunAttemptAssignDriverAndCombineTripsInput, RunAttemptAssignDriverAndCombineTripsResponse } from '@/models/gen/graphql';

import { GraphApiResponse } from '@/api/core';
import { ILLEGAL_COMBINES } from '../../../constants';
import { RunAttemptAssignDriverAndCombineTripsDocument } from '@/api/queries';
import { Validation } from '@/utils/validations';
import graphApi from '@/api';

type RunAttemptAssignDriverAndCombineTripsGraphApiResponse = GraphApiResponse<typeof RunAttemptAssignDriverAndCombineTripsDocument>;

const runAttemptAssignDriverAndCombineTripsValidator = new Validation.Validator({
  'driverId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  'tripId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  vehicleId: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val) ? true : val?.length ? false : undefined,
  }),
  scheduled: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isDate(val) ? true : val?.length ? false : undefined,
  }),
  'providerId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  'puLocationId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  'doLocationId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
  }),
  'attendants!': (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  'pilots!': (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
});

const runAttemptAssignDriverAndCombineTripsResponseSelector = (
  res: RunAttemptAssignDriverAndCombineTripsGraphApiResponse
): RunAttemptAssignDriverAndCombineTripsResponse =>
  !res.errors ? ((res?.runAttemptAssignDriverAndCombineTrips || {}) as RunAttemptAssignDriverAndCombineTripsResponse) : undefined;

const [runRunAttemptAssignDriverAndCombineTrips] = graphApi(RunAttemptAssignDriverAndCombineTripsDocument);

const runAttemptAssignDriverAndCombineTrips = async (
  input: RunAttemptAssignDriverAndCombineTripsInput
): Promise<RunAttemptAssignDriverAndCombineTripsResponse> => {
  input = runAttemptAssignDriverAndCombineTripsValidator.create(input);

  const res = await runRunAttemptAssignDriverAndCombineTrips({ input });

  const selected = runAttemptAssignDriverAndCombineTripsResponseSelector(res);

  // throw error with illegal combines
  if (selected?.output?.length > 0 && ILLEGAL_COMBINES.includes(selected?.appliedCombineType))
    throw { illegalCombines: selected.output, combineType: selected?.appliedCombineType };
  return selected;
};

export default runAttemptAssignDriverAndCombineTrips;

import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const getDriveTimeReportAsCSV: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (input: any = {}): Promise<any> => {
      const variables = {
        input: {
          after: null,
          airports: null,
          buckets: [
            {
              hour: null,
              minute: null,
              name: null,
            },
          ],
          dropoffLocationIds: null,
          endDatetime: null,
          first: null,
          iataAirlineCodes: null,
          payerProviderIds: null,
          pickupLocationIds: null,
          startDatetime: null,
          lowerThreshold: null,
          upperThreshold: null,
          ...input,
          format: input?.format || 'CLIENT',
        },
      };
      return fireQuery({
        variables,
        fetchPolicy: 'no-cache',
      });
    },
  selector: (data: any): any => data?.getDriveTimeReportAsCSV?.output || {},
  query: gql`
    query GetDriveTimeReportAsCSV($input: GetDriveTimeReportAsCSVInput!) {
      getDriveTimeReportAsCSV(input: $input) {
        output
      }
    }
  `,
};

export default getDriveTimeReportAsCSV;

import {
  CompletionCurrencyEnum,
  CreateCompletionInput,
  RunCompletionOnTripInput,
  RunCompletionOnTripResponse,
} from '../../models/gen/graphql';
import { OperationVariables, QueryResult } from '@apollo/client';

import { GraphApi } from '../../models';
import { Validation } from '../../utils';
import gql from 'graphql-tag';

const validateCreateCompletionInput = new Validation.Validator({
  'completionTypeId!': (val: string): Validation.Validity => ({
    valid: !!val,
    message: !Validation.isValidUUID(val) ? 'Please select a completion type.' : undefined,
    defaultValue: '',
  }),
  'cost!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'datetime!': (val: string): Validation.Validity => ({
    valid: !!val,
    defaultValue: '',
  }),
  'currency!': (val: any): Validation.Validity => ({
    valid: Object.values(CompletionCurrencyEnum).includes(val),
    defaultValue: CompletionCurrencyEnum.Usd,
  }),
  'comment!': (val: string): Validation.Validity => ({
    valid: !!val?.length && Validation.isAlphanumeric(val),
    defaultValue: '',
  }),
  // TODO: The fields below is metadata that should be populated by the BE
  'distanceMeters!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'efficiency!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'equivalency!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
});

export type RunCompletionOnTripWrapper = (tripIds: string[], completion: CreateCompletionInput) => Promise<RunCompletionOnTripResponse>;

const RunCompletionOnTrip: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (tripIds: string[], completion: CreateCompletionInput): Promise<QueryResult<any, OperationVariables>> => {
      const input: RunCompletionOnTripInput = {
        completion: validateCreateCompletionInput.create(completion),
        tripIds: tripIds,
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): RunCompletionOnTripResponse => data?.runCompletionOnTrip || {},
  query: gql`
    mutation RunCompletionOnTrip($input: RunCompletionOnTripInput!) {
      runCompletionOnTrip(input: $input) {
        error {
          code
          debug
          message
        }
        updated
      }
    }
  `,
};

export default RunCompletionOnTrip;

import LocationsTableComponent from '../../../pages/Locations/LocationsTable';
import React from 'react';
import { createWidget } from '../utils';

export const locationsTable = createWidget({
  key: 'locationsTable',
  label: 'Locations Table',
  description: (
    <>
      <p>Display the Locations table and search for results based on the filters provided.</p>
    </>
  ),
  icon: <i className="sv sv-network" />,
  component: ({ settings, ...props }) => {
    return (
      <>
        <LocationsTableComponent />
      </>
    );
  },
  options: {
    settings: { minW: 6, minH: 3 },
  },
});

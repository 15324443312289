import { Alias, Completion, Trip } from '@/models/gen/graphql';
import { Datetime, getClasses, zeroPadFlightNumber } from '@/utils';
import { ReactNode, useCallback, useMemo } from 'react';
import { SimpleTableCell, SimpleTableRowRendererProps, SimpleTableRowWithRef } from '@/components/SimpleTable';

import ActualCell from './Cells/ActualTimeCell';
import AssignDriverDropdownColumn from './Cells/AssignDriverCell';
import AssignVehicleCell from './Cells/AssignVehicleCell';
import CombineCell from './Cells/CombineCell';
import CompletionCell from './Cells/CompletionCell';
import { Confirmation } from '@/hooks/useConfirmation';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import FcrCell from './Cells/FcrCell';
import FlagAndCommunicationCell from './Cells/FlagAndCommunicationCell';
import { Form } from 'react-bootstrap';
import LocationCell from './Cells/LocationCell';
import { Middle } from '@/components/Align';
import RateCell from './Cells/RateCell';
import React from 'react';
import { formatTripTitle } from './utils';
import useTripTableState from './hook';

export type TripsTableRowEventHandlers = {
  onEditTrip: (data: Partial<Trip> | string, edit?: boolean) => void;
  onEditFlag: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string) => void;
  onEditCommunication: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string, offset: string) => void;
  onEditCompletion: (
    id: string,
    servicerIataAirlineCode: string,
    flightNumber: string,
    scheduled: string,
    completion: Completion,
    completionId: string
  ) => void;
  onEditFcr: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string) => void;
  onEditCombine: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string, combineId: string) => void;
  onEditRateReport: (selected: string[]) => void;
  confirmIllegalCombines: Confirmation.Method<{ title: string }>;
};
export type TripsTableRowProps = SimpleTableRowRendererProps & TripsTableRowEventHandlers;

const TripsTableRow = (
  {
    rowId,
    index,
    columns,
    onEditTrip,
    onEditFlag,
    onEditCommunication,
    onEditCompletion,
    onEditFcr,
    onEditCombine,
    onEditRateReport,
    confirmIllegalCombines,
    ...props
  }: TripsTableRowProps,
  ref?: React.ForwardedRef<HTMLDivElement>
): ReactNode => {
  const [data, isSelected, onSelect, onSetRow] = useTripTableState(({ state }) => [
    state.trips.get(rowId) || ({} as Trip),
    state.selected.get(rowId),
    state.onSelect,
    state.onSetRow,
  ]);

  const scheduledDatetime = useMemo((): Datetime => new Datetime(data.scheduled), [data.scheduled]);
  const scheduledUtcDatetime = useMemo((): Datetime => new Datetime(data.scheduledUtc), [data.scheduledUtc]);
  const flightNumber = useMemo((): string => zeroPadFlightNumber(data.flightNumber), [data.flightNumber]);
  const puLocationAliases = useMemo(
    (): string[] => (data.puLocation?.aliases || []).map(({ name }: Alias): string => name),
    [data.puLocation?.aliases]
  );
  const doLocationAliases = useMemo(
    (): string[] => (data.doLocation?.aliases || []).map(({ name }: Alias): string => name),
    [data.doLocation?.aliases]
  );

  const title = formatTripTitle(data.servicerIataAirlineCode, data.flightNumber, data.scheduled);

  const handleSelectRow = useCallback((): void => onSelect(rowId), [onSelect, rowId]);

  return (
    <SimpleTableRowWithRef
      {...props}
      onDoubleClick={(): void => onEditTrip(data)}
      columns={Object.values(columns)}
      className={getClasses(
        props.className,
        isSelected ? 'selected' : '',
        data.kind,
        data.type,
        data.status,
        data.isLate ? 'LATE' : '',
        isUpcomingTrip(scheduledUtcDatetime) ? 'UPCOMING' : '',
        data.state?.completion ? 'COMPLETED' : '',
        data.deletedAt ? 'DELETED' : '',
        data.curbsideAt ? 'CURBSIDE' : ''
      )}
      ref={ref}
    >
      <SimpleTableCell>
        <Middle.Center>
          <Form.Check name={`row-select_${index}`} checked={!!isSelected} onChange={handleSelectRow} style={{ transform: 'scale(1.25)' }} />
        </Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>
          <FlagAndCommunicationCell
            flags={data.flags}
            hasCommunication={!!data.communications?.length}
            onFlagClick={(): void => onEditFlag(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
            onCommunicationClick={(): void =>
              onEditCommunication(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled, data.offset)
            }
          />
        </Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left>{data.type || '--'}</Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left>{scheduledDatetime.format(DATE_FE_FORMAT_SHORT)}</Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left>{scheduledDatetime.time || '--'}</Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left>
          <ActualCell
            actual={data.trackFlight?.actual}
            arrivalGate={data.trackFlight?.arrivalGate}
            arrivalTerminal={data.trackFlight?.arrivalTerminal}
            label={data.trackFlight?.label}
            kind={data.kind}
            scheduled={data.scheduled}
          />
        </Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left>{data.airportCode || '--'}</Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>{data.servicerIataAirlineCode || '--'}</Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>{data.flightNumber > 0 ? flightNumber : '--'}</Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>{data.pilots ?? '--'}</Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>{data.attendants ?? '--'}</Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center className="align-items-center">
          <AssignDriverDropdownColumn
            rowId={data.id}
            title={title}
            airportCode={data.airportCode}
            actual={data.trackFlight?.actual}
            driver={data.driver}
            providerId={data.providerId}
            puLocationId={data.puLocationId}
            doLocationId={data.doLocationId}
            combineId={data.combineId}
            combineType={data.combineType}
            scheduled={data.scheduled}
            pilots={data.pilots}
            attendants={data.attendants}
            onSetRow={onSetRow}
            confirmIllegalCombines={confirmIllegalCombines}
          />
        </Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle>
          <AssignVehicleCell
            actual={data.trackFlight?.actual}
            scheduled={data.scheduled}
            airportCode={data.airportCode}
            driverId={data.driverId}
            rowId={data.id}
          />
        </Middle>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left className="gap-2">
          <LocationCell
            name={data.puLocation?.name}
            address={data.puLocation?.address}
            stateCode={data.puLocation?.stateCode}
            zipCode={data.puLocation?.zipCode}
            cityName={data.puLocation?.cityName}
            aliases={puLocationAliases}
          />
        </Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left className="gap-2">
          <LocationCell
            name={data.doLocation?.name}
            address={data.doLocation?.address}
            stateCode={data.doLocation?.stateCode}
            zipCode={data.doLocation?.zipCode}
            cityName={data.doLocation?.cityName}
            aliases={doLocationAliases}
          />
        </Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Left>{data.payerProvider?.displayName || '--'}</Middle.Left>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>
          <CompletionCell
            datetimeOfChange={data.state?.datetimeOfChange}
            displayName={data.state?.displayName}
            displayNameShort={data.state?.displayNameShort}
            onClick={(): void =>
              onEditCompletion(
                data.id,
                data.servicerIataAirlineCode,
                flightNumber,
                data.scheduled,
                data.state?.completion,
                data.completionId
              )
            }
          />
        </Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>
          <FcrCell
            count={data.fcrs?.length}
            onClick={(): void => onEditFcr(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
          />
        </Middle.Center>
      </SimpleTableCell>
      <SimpleTableCell>
        <Middle.Center>
          <CombineCell
            combineId={data.combineId}
            combineType={data.combineType}
            onClick={(): void => onEditCombine(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled, data.combineId)}
          />
        </Middle.Center>
      </SimpleTableCell>
      {!!columns['rate'] && (
        <SimpleTableCell>
          <Middle.Center>
            <RateCell rate={data.rate?.rate} rowId={data.id} onSetRow={onSetRow} onEditRateReport={onEditRateReport} />
          </Middle.Center>
        </SimpleTableCell>
      )}
    </SimpleTableRowWithRef>
  );
};

const isUpcomingTrip = (utcDatetime: Datetime): boolean =>
  utcDatetime.isSameUtc(undefined, 'day') && utcDatetime.diffUtc(undefined, 'hour') < 1;

export default React.forwardRef(TripsTableRow);

import { getClasses } from '@/utils';
import React, { HTMLAttributes } from 'react';
import { Spinner } from 'react-bootstrap';

type ChatEllipsesProps = HTMLAttributes<HTMLSpanElement> & {};
const ChatEllipses = ({ ...props }: ChatEllipsesProps) => {
  return (
    <span {...props} className={getClasses('ChatEllipses', props?.className)}>
      <Spinner size="sm" animation="grow" variant="secondary" />
      <Spinner size="sm" animation="grow" variant="secondary" />
      <Spinner size="sm" animation="grow" variant="secondary" />
    </span>
  );
};

export default ChatEllipses;

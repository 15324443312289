// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RatesHistoryButton {
  display: inline-block;
  margin: 0 2rem 0 auto;
  position: relative;
  top: 2.5rem;
  z-index: 5;
}
.RatesHistoryButton:hover {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/EditRatesModal/styles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AACF;AAAE;EACE,eAAA;EACA,0BAAA;AAEJ","sourcesContent":[".RatesHistoryButton {\n  display: inline-block;\n  margin: 0 2rem 0 auto;\n  position: relative;\n  top: 2.5rem;\n  z-index: 5;\n}\n.RatesHistoryButton:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

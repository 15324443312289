import { Trip, UpdateTripBulkInput } from '../../models/gen/graphql';
import { Validation, customUpdateHandler, generateUpdateBulkPayload } from '../../utils';

import { GraphApi } from '../../models';
import { createTripValidator } from './createTrips';
import gql from 'graphql-tag';

const updateTripValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
    combineId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createTripValidator
);

const updateTripBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...trips: Trip[]): Promise<any> => {
      trips = trips.map((trip: Trip): Trip => updateTripValidator.partial(trip));
      const updates = generateUpdateBulkPayload(trips);
      const input: UpdateTripBulkInput = {
        updates,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any[] => data?.updateTripBulk?.response || [],
  handler: customUpdateHandler('Update Trip', 'updateTripBulk'),
  query: gql`
    mutation UpdateTripBulk($input: UpdateTripBulkInput!) {
      updateTripBulk(input: $input) {
        response {
          error {
            code
            debug
            message
          }
          updated
        }
      }
    }
  `,
};

export default updateTripBulk;
export { updateTripValidator };

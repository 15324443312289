// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteTripsModal {
  --bs-modal-color: #000;
}
.DeleteTripsModal .Details .card-body > .row {
  justify-content: center;
  gap: 2rem;
}
.DeleteTripsModal .Details .card-body > .row .col .group {
  align-items: center;
}
.DeleteTripsModal .DeleteTripsModal-Header {
  display: block;
}
.DeleteTripsModal .DeleteTripsModal-Header .DeleteTripsModal-Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  padding: 0.75rem 0;
  margin-bottom: 1rem !important;
  color: var(--bs-primary);
  font-weight: 400;
  letter-spacing: 0.15rem;
  border-bottom: 1px solid var(--bs-primary);
  width: auto;
  text-transform: uppercase;
}
.DeleteTripsModal .DeleteTripsModal-Footer {
  display: flex;
  justify-content: space-between;
}
.DeleteTripsModal .DeleteTripsModal-Footer .btn {
  width: 11.6rem; /*approx: 140px to match design*/
}`, "",{"version":3,"sources":["webpack://./src/pages/Trips/components/DeleteTripsModal/styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACI;EACE,uBAAA;EACA,SAAA;AACN;AAAM;EACE,mBAAA;AAER;AAEE;EACE,cAAA;AAAJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,8BAAA;EACA,wBAAA;EACA,gBAAA;EACA,uBAAA;EACA,0CAAA;EACA,WAAA;EACA,yBAAA;AACN;AAEE;EACE,aAAA;EACA,8BAAA;AAAJ;AACI;EACE,cAAA,EAAA,gCAAA;AACN","sourcesContent":[".DeleteTripsModal {\n  --bs-modal-color: #000;\n}\n.DeleteTripsModal .Details .card-body > .row {\n  justify-content: center;\n  gap: 2rem;\n}\n.DeleteTripsModal .Details .card-body > .row .col .group {\n  align-items: center;\n}\n.DeleteTripsModal .DeleteTripsModal-Header {\n  display: block;\n}\n.DeleteTripsModal .DeleteTripsModal-Header .DeleteTripsModal-Title {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: start;\n  gap: 0.5rem;\n  padding: 0.75rem 0;\n  margin-bottom: 1rem !important;\n  color: var(--bs-primary);\n  font-weight: 400;\n  letter-spacing: 0.15rem;\n  border-bottom: 1px solid var(--bs-primary);\n  width: auto;\n  text-transform: uppercase;\n}\n.DeleteTripsModal .DeleteTripsModal-Footer {\n  display: flex;\n  justify-content: space-between;\n}\n.DeleteTripsModal .DeleteTripsModal-Footer .btn {\n  width: 11.6rem; /*approx: 140px to match design*/\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsPageGroup-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SettingsPageGroup-Header-Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 0;
  color: var(--bs-primary);
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}
.SettingsPageGroup-Header-Title i {
  font-size: 2.25rem;
}
.SettingsPageGroup-Footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem 0;
  gap: 1rem;
}
.SettingsPageGroup-Footer .btn {
  min-width: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/features/settings/components/SettingsPageGroup/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,iBAAA;EACA,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;AAAN;AACM;EACE,kBAAA;AACR;AAIE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,SAAA;AAFJ;AAII;EACE,gBAAA;AAFN","sourcesContent":[".SettingsPageGroup-Header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.SettingsPageGroup-Header-Title {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 1rem;\n  padding: 1.5rem 0;\n  color: var(--bs-primary);\n  font-size: 1.75rem;\n  font-weight: 500;\n  letter-spacing: 0.05rem;\n  text-transform: uppercase;\n}\n.SettingsPageGroup-Header-Title i {\n  font-size: 2.25rem;\n}\n.SettingsPageGroup-Footer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 1.5rem 0;\n  gap: 1rem;\n}\n.SettingsPageGroup-Footer .btn {\n  min-width: 10rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { FilterOption } from '@/models/gen/graphql';
import { GetServicerIataAirlineCodesDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetServicerIataAirlineCodesGraphApiResponse = GraphApiResponse<typeof GetServicerIataAirlineCodesDocument>;

const getServicerIataAirlineCodesResponseSelector = (res: GetServicerIataAirlineCodesGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.servicerIataAirlineCode as Array<FilterOption>;

const [runGetServicerIataAirlineCodes] = graphApi(GetServicerIataAirlineCodesDocument, {
  onError: (res: GetServicerIataAirlineCodesGraphApiResponse): void => handleError(res, { notification: { title: 'Get Vehicle Models' } }),
});

export const getServicerIataAirlineCodes = async (): Promise<Array<FilterOption>> => {
  const res = await runGetServicerIataAirlineCodes();
  return getServicerIataAirlineCodesResponseSelector(res);
};

export const useGetServicerIataAirlineCodes = createGraphApiHook(getServicerIataAirlineCodes);

import './styles.scss';

import AvailabilityTable from './AvailabilityTable';
import { Container } from 'react-bootstrap';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';

const Availability = () => {
  return (
    <ProtectedRoute title="Availability">
      <Container className="Availability page-container" fluid>
        <AvailabilityTable />
      </Container>
    </ProtectedRoute>
  );
};
export default Availability;

import { SearchPreviousImportsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const searchPreviousImports = createGraphApiMockResponse(SearchPreviousImportsDocument, {
  searchPreviousImports: {
    previousImportConnection: {
      edges: [
        {
          cursor: '1',
          node: {
            id: '7dba39e7-3882-4ad3-9be1-7c97173bed01',
            name: 'delta_01_03_2022',
            __typename: 'Import',
          },
          __typename: 'ImportEdge',
        },
        {
          cursor: '2',
          node: {
            id: '7dba39e7-3882-4ad3-9be1-7c97173bed01',
            name: 'united_01_03_2022',
            __typename: 'Import',
          },
          __typename: 'ImportEdge',
        },
        {
          cursor: '3',
          node: {
            id: '7dba39e7-3882-4ad3-9be1-7c97173bed01',
            name: 'american_01_03_2022',
            __typename: 'Import',
          },
          __typename: 'ImportEdge',
        },
      ],
      pageInfo: {
        startCursor: '0',
        endCursor: '6',
        hasPreviousPage: false,
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      totalCount: 6,
      __typename: 'ImportConnection',
    },
    __typename: 'SearchPreviousImportsResponse',
  },
});

import { RunApplyRateToSimilarTripsInput, RunApplyRateToSimilarTripsResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { RunApplyRateToSimilarTripsDocument } from '../../queries';
import { Toast } from '../../../models';
import graphApi from '../..';

type RunApplyRateToSimilarTripsGraphApiResponse = GraphApiResponse<typeof RunApplyRateToSimilarTripsDocument>;

const title = 'Apply Rate to Similar Trips';

const runApplyRateToSimilarTripsResponseSelector = (res: RunApplyRateToSimilarTripsGraphApiResponse): RunApplyRateToSimilarTripsResponse =>
  !res.errors ? res?.runApplyRateToSimilarTrips : undefined;

const [runRunApplyRateToSimilarTrips] = graphApi(RunApplyRateToSimilarTripsDocument, {
  onError: (res: RunApplyRateToSimilarTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const runApplyRateToSimilarTrips = async (rateIds: string[]): Promise<RunApplyRateToSimilarTripsResponse> => {
  const input: RunApplyRateToSimilarTripsInput = { rateIds };
  const res = await runRunApplyRateToSimilarTrips({ input });

  const selected = runApplyRateToSimilarTripsResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default runApplyRateToSimilarTrips;

import './styles.scss';

import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import React, { JSXElementConstructor, ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import { LoadingBlur } from 'components/LoadingSpinner';
import { Validation } from 'utils';
import useClassNames from '../../hooks/useClassNames';
import useUuid from '../../hooks/useUuid';

type headerOptions = {};
type bodyOptions = {
  className?: string;
};
type footerOptions = {
  cancelButtonVariant?: string;
  cancelButtonText?: string | JSX.Element;
  submitButtonVariant?: string;
  submitButtonText?: string | JSX.Element;
};
type Options = {
  Header?: JSX.Element;
  Body?: JSX.Element;
  Footer?: JSX.Element;
  header?: headerOptions;
  body?: bodyOptions;
  footer?: footerOptions;
  overlay?: { props?: { onToggle?: (nextShow) => boolean } };
};
type Props = {
  show?: boolean;
  title?: string;
  target: ReactElement<any, string | JSXElementConstructor<any>>;
  icon?: any;
  size?: 'xl' | 'sm' | 'lg' | 'md';
  onHide?: () => void;
  onSubmit?: () => void;
  options?: Options;
  children?: any;
  loading?: boolean;
  name: string;
};
type State = {
  show: boolean;
};
const initEditPopoverState: State = { show: false };

const EditPopover = ({ title, icon, show: forceShow, onHide, options = {}, children = [], target, loading }: Props): JSX.Element => {
  const [state, setState] = useState(initEditPopoverState);
  const { show } = state;
  const clickedAntComponent = useRef(false);
  const uuid = useUuid();
  const popoverClasses = useClassNames('Popover-Menu');

  const onShow = (): void => {
    setState((current: State): State => ({ ...current, show: true }));
  };

  const handleHide = (): void => {
    setState(initEditPopoverState);
    if (onHide) onHide();
  };
  // Function to handle the click event
  const handleClickOutside = (event): void => {
    clickedAntComponent.current = event.target.className?.includes('ant') || event.target.className.includes('btn');
  };

  useEffect((): void => {
    if (Validation.isNil(forceShow)) return;
    setState((current) => ({ ...current, show: forceShow }));
  }, [forceShow]);

  useEffect((): (() => void) => {
    // Attach the event listener to the document
    if (show) document.addEventListener('click', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return (): void => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [show]);

  const Header = useMemo(
    () =>
      options?.Header ? (
        options?.Header
      ) : title || icon ? (
        <Popover.Header>
          {icon && (typeof icon === 'string' ? <i className={`${icon} me-2`} /> : icon)}
          <span>{title}</span>
        </Popover.Header>
      ) : null,
    [icon, options?.Header, title]
  );

  return (
    <OverlayTrigger
      onToggle={(nextShow: boolean): void => {
        if (!nextShow && !clickedAntComponent.current) return handleHide();
        onShow();
      }}
      {...options?.overlay?.props}
      container={document.body}
      trigger="click"
      placement="auto"
      rootClose
      show={show}
      overlay={
        <Popover id={uuid} className={popoverClasses}>
          <LoadingBlur loading={loading} />
          <Button className="btn-close {position:absolute;top:.6rem;right:1rem;z-index:1000;}" variant="" onClick={handleHide} />
          {Header}
          {options?.Body ? (
            options?.Body
          ) : (
            <Popover.Body className={`${!Header ? '{margin-right:2rem;margin-top:1rem;}' : ''}`}>{children || <NoContent />}</Popover.Body>
          )}
          {options?.Footer ? options?.Footer : null}
        </Popover>
      }
    >
      <Button variant="icon" onClick={onShow}>
        {target}
      </Button>
    </OverlayTrigger>
  );
};

const NoContent = (): JSX.Element => <p className="text-center text-mute lead">No Content</p>;

export default EditPopover;

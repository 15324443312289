import EnumDropdown, { EnumDropdownProps } from '@/components/EnumDropdown';

import { ReactNode } from 'react';
import { TripTableFormatEnum } from '@/models/gen/graphql';

type TripsTableFormatDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  name?: string;
};

const { Priority: _p, United: _u, ...Enum } = TripTableFormatEnum;

const TripsTableFormatDropdown = ({ name = 'tripsTableFormat', options = {}, ...props }: TripsTableFormatDropdownProps): ReactNode => {
  return (
    <EnumDropdown
      {...props}
      enum={Enum}
      name={name}
      options={{ ...options, locale: options?.locale || { 'Select...': 'Format', Fcr: 'Complaint' } }}
    />
  );
};

export default TripsTableFormatDropdown;

import { CommunicationConnectionInput, CommunicationSearch } from '../../models/gen/graphql';

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const searchCommunications: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, query: CommunicationSearch[] = null, options: any = {}): Promise<any> => {
      const input: CommunicationConnectionInput = {
        first,
        after,
        query: query && !!Object.keys(query).length ? query : null,
      };

      return fireQuery({
        variables: {
          input,
        },
        ...options,
      });
    },
  selector: getConnectionDetails('searchCommunications.communicationConnection'),
  query: gql`
    query SearchCommunications($input: CommunicationConnectionInput) {
      searchCommunications {
        communicationConnection(input: $input) {
          edges {
            node {
              id
              message
              tripId
              createdAt
              createdBy
              creator {
                firstName
                lastName
              }
              updatedAt
              updatedBy
            }
          }
        }
      }
    }
  `,
};

export default searchCommunications;

import { Provider, UpdateProviderBulkInput, UpdateProviderBulkResponse, UpdateProviderInput } from '@/models/gen/graphql';
import { Validation, generateUpdateBulkPayload } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { UpdateClientBulkDocument } from '@/api/queries';
import { createClientValidator } from './createClientBulk';
import graphApi from '@/api';

type UpdateClientBulkGraphApiResponse = GraphApiResponse<typeof UpdateClientBulkDocument>;

const updateClientValidator = new Validation.Validator(
  {
    'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  },
  createClientValidator
);

const updateClientBulkResponseSelector = (res: UpdateClientBulkGraphApiResponse): UpdateProviderBulkResponse =>
  !res.errors ? res?.updateClientBulk : undefined;

const [runUpdateClientBulk] = graphApi(UpdateClientBulkDocument);

const updateClientBulk = async (clients: Partial<Provider>[]): Promise<UpdateProviderBulkResponse> => {
  clients = clients.map((client: Provider): Provider => updateClientValidator.partial(client));
  const updates: UpdateProviderInput[] = generateUpdateBulkPayload(clients);
  const input: UpdateProviderBulkInput = {
    updates,
  };
  const res = await runUpdateClientBulk({ input });

  return updateClientBulkResponseSelector(res);
};

export const updateClientActivationBulk = async (input: Array<Pick<Provider, 'id' | 'active'>>): Promise<UpdateProviderBulkResponse> => {
  return await updateClientBulk(input);
};

export default updateClientBulk;

import { OperationVariables, QueryResult } from '@apollo/client';

import { AcceptInputOption } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const RunCompletionOnTrip: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (): Promise<QueryResult<any, OperationVariables>> => {
      return fireQuery();
    },
  selector: (data: any): AcceptInputOption[] => data?.filters?.completionReasons || [],
  query: gql`
    query GetFilters {
      getFilters {
        filters {
          completionReasons {
            id
            displayName
            acceptInput
          }
        }
      }
    }
  `,
};

export default RunCompletionOnTrip;

import React, { useMemo, useRef, useState } from 'react';
import { handleError, titleCase, unCamelCase } from '@/utils';

export interface CreateGraphApiHookState<Data> {
  loading: boolean;
  data: Data;
  error: any;
}
type OptionalParameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? Partial<P> : never;
type OptionalMethod<T extends (...args: any) => any> = (...args: OptionalParameters<T>) => ReturnType<T>;
export interface CreateGraphApiHookMethods<T extends (...args: any) => Promise<any>> {
  fetch: T;
  refetch: OptionalMethod<T>;
  fetchMore: T;
  setData: React.Dispatch<React.SetStateAction<Awaited<ReturnType<T>>>>;
}
type CreateGraphApiHookOptions<T extends (...args: any) => Promise<any>> = {
  refetch?: OptionalMethod<T>;
  merge?: (res: Awaited<ReturnType<T>>, current: Awaited<ReturnType<T>>) => Awaited<ReturnType<T>>;
  bubble?: boolean;
};
export type CreateGraphApiHookReturn<T extends (...args: any) => Promise<any>> = [
  CreateGraphApiHookState<Awaited<ReturnType<T>>>,
  CreateGraphApiHookMethods<T>,
];
export type CreateGraphApiHookHook<T extends (...args: any) => Promise<any>> = () => CreateGraphApiHookReturn<T>;
/**
 * Generates a function comment for the given function body.
 *
 * @param {GraphApiMethod} method - the method to be executed by the graph API
 * @param {any} options - optional additional options for the graph API method
 * @returns {Function} - a function that can be executed to call the graph API method
 */
const createGraphApiHook =
  <AsyncMethod extends (...args: any) => Promise<any>>(
    method: AsyncMethod,
    options?: CreateGraphApiHookOptions<AsyncMethod>
  ): CreateGraphApiHookHook<AsyncMethod> =>
  (): CreateGraphApiHookReturn<AsyncMethod> => {
    const [data, setData] = useState<Awaited<ReturnType<AsyncMethod>>>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(undefined);

    const lastPayload = useRef<Parameters<AsyncMethod>>([] as Parameters<AsyncMethod>);

    const execute = useMemo(
      () =>
        async (
          fn: AsyncMethod | OptionalMethod<AsyncMethod>,
          payload: Parameters<AsyncMethod>,
          methodOptions?: { mergeResult: boolean }
        ): Promise<Awaited<ReturnType<AsyncMethod>>> => {
          try {
            setLoading(true);
            setError(undefined);
            const { mergeResult = false } = methodOptions || {};
            const res = await fn(...payload);
            if (!mergeResult) lastPayload.current = payload;
            setData(
              (current: Awaited<ReturnType<AsyncMethod>>): Awaited<ReturnType<AsyncMethod>> =>
                mergeResult && options?.merge ? options.merge(res, current) : res
            );
            return res;
          } catch (err) {
            const timer = setTimeout((): void => {
              handleError(err, { notification: { title: titleCase(unCamelCase(fn?.name)) } });
              clearTimeout(timer);
            }, 500);
            setError(err);
            if (options?.bubble === true) throw new Error(err);
          } finally {
            setLoading(false);
          }
        },
      []
    );

    const methods = useMemo(
      (): CreateGraphApiHookMethods<AsyncMethod> => ({
        fetch: (async (...payload: Parameters<AsyncMethod>): Promise<Awaited<ReturnType<AsyncMethod>>> =>
          execute(method, payload)) as AsyncMethod,
        refetch: (async (...payload: Parameters<AsyncMethod>): Promise<Awaited<ReturnType<AsyncMethod>>> =>
          execute(options?.refetch || method, payload?.length ? payload : lastPayload.current)) as OptionalMethod<AsyncMethod>,
        fetchMore: (async (...payload: Parameters<AsyncMethod>): Promise<Awaited<ReturnType<AsyncMethod>>> =>
          execute(method, payload, { mergeResult: true })) as AsyncMethod,
        setData,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [method, options]
    );

    return [{ data, loading, error }, methods];
  };

export default createGraphApiHook;

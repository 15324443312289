import { Button, Form, Modal } from 'react-bootstrap';
import React, { useRef } from 'react';

import useModal from '../../hooks/useModal';
import { useNavigate } from 'react-router-dom';

const DebugModal = (): JSX.Element => {
  const navigate = useNavigate();
  const [state, setState] = useModal('debugModal', {
    onSubmit: async (data: any): Promise<void> => {
      console.log('MODAL SUBMIT', data);
      onHide();
    },
  });
  const { show, size = 'xl', ...form } = state;
  const { test = '' } = form;
  const { onChange, onSubmit, onHide } = setState;
  const currentSize = useRef(size);

  return (
    <Modal className="{padding:1rem!;}>.modal-dialog>.modal-content" show={show} onHide={onHide} size={currentSize.current}>
      <Modal.Body>
        <Form.Control
          as="select"
          onChange={(event: any): void => {
            currentSize.current = event.target.value;
            onChange(event);
          }}
          name="size"
          value={currentSize.current}
        >
          <option value="sm">Small</option>
          <option value="md">Medium</option>
          <option value="lg">Large</option>
          <option value="xl">X Large</option>
        </Form.Control>
        <Form.Control onChange={onChange} name="test" value={test} />
        <Button className="shadow rounded-circle {position:absolute;top:0rem;right:0rem;}" variant="light" size="sm" onClick={onHide}>
          <i className="fa fa-times" />
        </Button>
        <pre>{JSON.stringify(form, null, 2)}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => navigate('/trips')}>
          Navigate
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DebugModal;

import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const getOnTimePerformanceReport: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (input: any = {}): Promise<any> => {
      const variables = {
        input: {
          startDatetime: null,
          payerProviderIds: null,
          iataAirlineCodes: null,
          format: 'AIRPORT',
          first: null,
          endDatetime: null,
          airports: null,
          after: null,
          ...input,
        },
      };
      return fireQuery({
        variables,
        fetchPolicy: 'no-cache',
      });
    },
  selector: (data: any): any => data?.getOnTimePerformanceReport?.output || {},
  query: gql`
    query GetOnTimePerformanceReport($input: GetOnTimePerformanceReportInput!) {
      getOnTimePerformanceReport(input: $input) {
        error {
          code
          message
          debug
        }
        output {
          tables {
            totalTrips
            ontimeOverall
            percentLate
            percentEfficiency
            totalReady
            totalPickups
            totalGps
            totalDrivers
            rows {
              totalTrips
              ontimeOverall
              percentLate
              percentEfficiency
              totalReady
              totalPickups
              totalGps
              rating
              percentReady
              percentPickup
              percentGps
              name
              children {
                name
                totalTrips
                ontimeOverall
                percentLate
                percentEfficiency
                totalPickups
                percentPickup
                totalGps
                percentGps
                totalReady
                percentReady
                rating
                children {
                  name
                  totalTrips
                  ontimeOverall
                  percentLate
                  percentEfficiency
                  totalPickups
                  percentPickup
                  totalGps
                  percentGps
                  totalReady
                  percentReady
                  rating
                  children {
                    name
                    totalTrips
                    ontimeOverall
                    percentLate
                    percentEfficiency
                    totalPickups
                    percentPickup
                    totalGps
                    percentGps
                    totalReady
                    percentReady
                    rating
                  }
                }
              }
            }
            rating
            percentReady
            percentPickup
            percentGps
            name
          }
        }
      }
    }
  `,
};

export default getOnTimePerformanceReport;

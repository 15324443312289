import './styles.scss';

import { Drawer, DrawerProps, Modal, ModalProps } from 'antd';
import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { getClasses, properCase } from '@/utils';

import ActionsDropdown from '@/components/ActionsDropdown';
import { Button } from 'react-bootstrap';

// NOTE: Adding new types requires the corresponding optional boolean to be added to DynamicContentProps Types.
export const DynamicContentAs = ['inline', 'modal', 'drawer'] as const;
export type DynamicContentProps = {
  // START GENERIC PROPS
  className?: string;
  children?: ReactNode;
  as?: (typeof DynamicContentAs)[number];
  // END GENERIC PROPS
  // START TYPES
  inline?: boolean;
  modal?: boolean;
  drawer?: boolean;
  // END TYPES
  // START TYPE PROPS
  // Modal/Drawer Props
  show?: boolean;
  onHide?: () => void;
  // Drawer Props
  placement?: 'top' | 'bottom' | 'left' | 'right';
  title?: ReactNode;
  // END TYPE PROPS
  options?: {
    modal?: { props?: ModalProps };
    drawer?: { props?: DrawerProps };
    inline?: { props?: HTMLAttributes<HTMLDivElement> };
  };
};

const DynamicContent = (props: DynamicContentProps): ReactNode => {
  const { options } = props;
  const inlineProps = options?.inline?.props || {};
  const modalProps = options?.modal?.props || {};
  const drawerProps = options?.drawer?.props || {};
  const typesAs = DynamicContentAs.map((a: string): string => a);
  const disableAs = typesAs.filter((a: string): boolean => props[a] === false);
  const enabledAs = typesAs.filter((a: string): boolean => props[a] !== undefined && disableAs.includes(a) === false);
  const defaultAs = props?.as || enabledAs[0] || 'inline';
  const [as, setAs] = useState<string>(defaultAs);
  const isShown = !!props?.show;

  const handleHide = (): void => {
    props?.onHide?.();
  };

  const controls = (
    <ul className="DynamicContent-Controls">
      {props?.onHide && ['modal', 'drawer'].includes(as) && (
        <li>
          <Button variant="icon" onClick={handleHide}>
            <i className="fa fa-times" />
          </Button>
        </li>
      )}
      {enabledAs.length > 1 && (
        <li>
          <ActionsDropdown
            onClick={setAs}
            value={as}
            items={[
              {
                key: 'view',
                label: 'View',
                children: enabledAs.map((key: string): { key: string; label: string } => ({
                  key,
                  label: properCase(key),
                })),
              },
            ]}
          />
        </li>
      )}
    </ul>
  );

  switch (as) {
    case 'modal':
      return (
        <DynamicContentModal {...modalProps} className={props?.className} open={isShown} onCancel={handleHide} controls={controls}>
          {props?.children}
        </DynamicContentModal>
      );
    case 'drawer':
      return (
        <DynamicContentDrawer
          {...drawerProps}
          className={props?.className}
          placement={props?.placement}
          open={isShown}
          onClose={handleHide}
          title={props?.title}
          controls={controls}
        >
          {props?.children}
        </DynamicContentDrawer>
      );
    case 'inline':
    default:
      return (
        <DynamicContentInline {...inlineProps} className={props?.className} controls={controls}>
          {props?.children}
        </DynamicContentInline>
      );
  }
};

type DynamicModelProps = { controls: ReactNode } & ModalProps;
const DynamicContentModal = ({ controls, children, ...props }: DynamicModelProps): ReactNode => (
  <Modal
    {...props}
    className={getClasses('DynamicContent DynamicContent-Modal', props?.className)}
    closeIcon={null}
    footer={null}
    destroyOnClose
  >
    {controls}
    {children}
  </Modal>
);

type DynamicDrawerProps = { controls: ReactNode } & DrawerProps;
const DynamicContentDrawer = ({ controls, children, ...props }: DynamicDrawerProps): ReactNode => (
  <Drawer {...props} className={getClasses('DynamicContent DynamicContent-Drawer', props?.className)} destroyOnClose>
    {controls}
    {children}
  </Drawer>
);

type DynamicInlineProps = { controls: ReactNode } & HTMLAttributes<HTMLDivElement>;
const DynamicContentInline = ({ controls, children, ...props }: DynamicInlineProps): ReactNode => (
  <div {...props} className={getClasses('DynamicContent DynamicContent-Inline', props?.className)}>
    {controls}
    {children}
  </div>
);
type DynamicContentTitleProps = { icon?: ReactNode; children?: ReactNode };
const DynamicContentTitle = (props: DynamicContentTitleProps) => {
  return (
    <h3 className="DynamicContent-Title">
      {props?.icon && props.icon}
      {props?.children}
    </h3>
  );
};

DynamicContent.Modal = DynamicContentModal;
DynamicContent.Drawer = DynamicContentDrawer;
DynamicContent.Inline = DynamicContentInline;
DynamicContent.Title = DynamicContentTitle;
export default DynamicContent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input {
  position: relative;
}
.Input[type=number] {
  -moz-appearance: none;
  appearance: none;
}
.Input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.Input[type=number] + .Input-Controls {
  display: flex;
  flex-direction: column;
}
.Input[type=number] + .Input-Controls > button {
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0;
  line-height: 0;
  width: 2rem;
}
.Input[type=number] + .Input-Controls > button:first-child {
  border-top-right-radius: var(--bs-border-radius);
}`, "",{"version":3,"sources":["webpack://./src/components/Input/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,qBAAA;EACA,gBAAA;AAEJ;AADI;EACE,wBAAA;AAGN;AADI;EACE,aAAA;EACA,sBAAA;AAGN;AAFM;EACE,YAAA;EACA,qCAAA;EACA,UAAA;EACA,cAAA;EACA,WAAA;AAIR;AAHQ;EACE,gDAAA;AAKV","sourcesContent":[".Input {\n  position: relative;\n}\n.Input[type=number] {\n  -moz-appearance: none;\n  appearance: none;\n}\n.Input[type=number]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n}\n.Input[type=number] + .Input-Controls {\n  display: flex;\n  flex-direction: column;\n}\n.Input[type=number] + .Input-Controls > button {\n  flex-grow: 1;\n  border: 1px solid rgba(0, 0, 0, 0.25);\n  padding: 0;\n  line-height: 0;\n  width: 2rem;\n}\n.Input[type=number] + .Input-Controls > button:first-child {\n  border-top-right-radius: var(--bs-border-radius);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { CreateProviderBulkInput, CreateProviderBulkResponse, CreateProviderInput, Provider, ProviderTypeEnum } from '@/models/gen/graphql';

import { CreateAirlineBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Validation } from '@/utils';
import graphApi from '@/api';

type CreateAirlineBulkGraphApiResponse = GraphApiResponse<typeof CreateAirlineBulkDocument>;

export const createAirlineValidator = new Validation.Validator({
  active: (val: any): Validation.Validity => ({
    valid: Validation.isNumber(val) ? true : undefined,
    defaultValue: 1,
  }),
  billingAddress: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val) ? true : val?.length ? false : undefined,
  }),
  billingCityCode: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][a-z ]', 'i').test(val) ? true : val?.length ? false : undefined,
  }),
  billingStateCode: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[A-Z]{2}').test(val) ? true : val?.length ? false : undefined,
  }),
  billingZipCode: (val: any): Validation.Validity => ({
    valid: !!val && !isNaN(val) && val?.length === 5 ? true : val?.length ? false : undefined,
  }),
  contactPerson: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val) ? true : val?.length ? false : undefined,
  }),
  companyId: (val: any): Validation.Validity => ({
    valid: undefined,
    defaultValue: '',
  }),
  'displayName!': (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val),
  }),
  faxNumber: (val: any): Validation.Validity => ({
    valid: !!val && !isNaN(val) && val?.length === 10 ? true : val?.length ? false : undefined,
  }),
  iataAirlineCode: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[0-9A-Z]{2,3}$').test(val), // this field is required for airline provider
  }),
  invoiceTermId: (val: any): Validation.Validity => ({
    valid: undefined, // we don't support setting invoice terms on an airline currently
  }),
  mobilePhoneNumber: (val: any): Validation.Validity => ({
    valid: !!val && !isNaN(val) && val?.length === 10 ? true : val?.length ? false : undefined,
  }),
  'name!': (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val),
  }),
  occPhoneNumber: (val: any): Validation.Validity => ({
    valid: !!val && !isNaN(val) && val?.length === 10 ? true : val?.length ? false : undefined,
  }),
  primaryEmail: (val: any): Validation.Validity => ({
    valid: Validation.isEmail(val) ? true : val?.length ? false : undefined,
  }),
  primaryAddress: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val) ? true : val?.length ? false : undefined,
  }),
  primaryCityCode: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val) ? true : val?.length ? false : undefined,
  }),
  primaryPhoneNumber: (val: any): Validation.Validity => ({
    valid: !!val && !isNaN(val) && val?.length === 10 ? true : val?.length ? false : undefined,
  }),
  primaryStateCode: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[A-Z]{2}').test(val) ? true : val?.length ? false : undefined,
  }),
  primaryZipCode: (val: any): Validation.Validity => ({
    valid: !!val && !isNaN(val) && val?.length === 5 ? true : val?.length ? false : undefined,
  }),
  referenceNumber: (val: any): Validation.Validity => ({
    valid: !!val && new RegExp('^[^ ][0-9a-z ]', 'i').test(val) ? true : val?.length ? false : undefined,
  }),
  type: (val: any): Validation.Validity => ({
    valid: undefined, // we want to always set the value to the default enum
    defaultValue: ProviderTypeEnum.Airline,
  }),
  website: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isValidURL(val) ? true : val?.length ? false : undefined,
  }),
});

const createAirlineBulkResponseSelector = (res: CreateAirlineBulkGraphApiResponse): CreateProviderBulkResponse =>
  !res.errors ? (res?.createAirlineBulk as CreateProviderBulkResponse) : undefined;

const [runCreateAirlineBulk] = graphApi(CreateAirlineBulkDocument);

const createAirlineBulk = async (airlines: Partial<Provider>[]): Promise<CreateProviderBulkResponse> => {
  const values: CreateProviderInput[] = airlines.map((airline: Provider): CreateProviderInput => createAirlineValidator.create(airline));
  const input: CreateProviderBulkInput = { values };

  const res = await runCreateAirlineBulk({ input });
  return createAirlineBulkResponseSelector(res);
};

export default createAirlineBulk;

import { Button, Space } from 'antd';
import React, { useState } from 'react';

import EditBucketsModal from '../Reports/DriveTime/EditBucketsModal';

const initModalsDisplayState = {
  show: false,
};
const Modals = () => {
  const [state, setState] = useState(initModalsDisplayState);
  const { show } = state;

  const handleToggleModal = (key: string) => {
    setState((current: any) => ({ ...current, [key]: !current[key] }));
  };

  const onSubmit = (...args) => console.log('SUBMITTING FORM!! ARGS:', args);
  const onHide = () => setState((current) => ({ ...current, show: false }));

  return (
    <div>
      <Space wrap>
        <Button onClick={() => handleToggleModal('show')}>Show Modal</Button>
      </Space>
      <EditBucketsModal show={show} onHide={onHide} onSubmit={onSubmit} value={[]} />
    </div>
  );
};

export default Modals;

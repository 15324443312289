import { CreateAirportHasVehicleBulkInput, CreateAirportHasVehicleBulkResponse } from '../../../models/gen/graphql';
import { createNotification, handleError, validateObj } from '../../../utils/custom';

import { CreateAirportHasVehicleBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type CreateAirportHasVehicleBulkGraphApiResponse = GraphApiResponse<typeof CreateAirportHasVehicleBulkDocument>;

const title = 'Create Airport Has Vehicle';

export const validateCreateAirportHasVehicle = validateObj({
  airportCode: (val: string): string => val || '',
  vehicleId: (val: string): string => val || '',
});

const createAirportHasVehicleBulkResponseSelector = (
  res: CreateAirportHasVehicleBulkGraphApiResponse
): CreateAirportHasVehicleBulkResponse =>
  !res.errors ? (res?.createAirportHasVehicleBulk as CreateAirportHasVehicleBulkResponse) : undefined;

const [runCreateAirportHasVehicleBulk] = graphApi(CreateAirportHasVehicleBulkDocument, {
  onError: (res: CreateAirportHasVehicleBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createAirportHasVehicleBulk = async (
  vehicles: Partial<CreateAirportHasVehicleBulkInput>[]
): Promise<CreateAirportHasVehicleBulkResponse> => {
  const input: CreateAirportHasVehicleBulkInput = {
    values: vehicles.map(validateCreateAirportHasVehicle),
  };

  const res = await runCreateAirportHasVehicleBulk({ input });
  const selected = createAirportHasVehicleBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateAirportHasVehicleBulk = createGraphApiHook(createAirportHasVehicleBulk);

export default createAirportHasVehicleBulk;

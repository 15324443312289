// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingBlur {
  position: absolute;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 999;
  transition: background-color 0.5s;
  pointer-events: none;
}
.LoadingBlur * {
  opacity: 0;
  transition: opacity 0.5s;
}
.LoadingBlur.active {
  pointer-events: all;
  background-color: rgba(255, 255, 255, 0.65);
}
.LoadingBlur.active * {
  opacity: 0.75;
}
.LoadingBlur.passive {
  pointer-events: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,wCAAA;EACA,YAAA;EACA,iCAAA;EACA,oBAAA;AADF;AAGE;EACE,UAAA;EACA,wBAAA;AADJ;AAIE;EACE,mBAAA;EACA,2CAAA;AAFJ;AAII;EACE,aAAA;AAFN;AAKE;EACE,+BAAA;AAHJ","sourcesContent":[".LoadingBlur {\n  position: absolute;\n  width: 100%;\n  max-width: 100vw;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: rgba(255, 255, 255, 0);\n  z-index: 999;\n  transition: background-color 0.5s;\n  pointer-events: none;\n}\n.LoadingBlur * {\n  opacity: 0;\n  transition: opacity 0.5s;\n}\n.LoadingBlur.active {\n  pointer-events: all;\n  background-color: rgba(255, 255, 255, 0.65);\n}\n.LoadingBlur.active * {\n  opacity: 0.75;\n}\n.LoadingBlur.passive {\n  pointer-events: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

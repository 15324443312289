// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RouteContent {
  position: relative;
  flex-grow: 1;
}
.RouteContent #Sidebar-Left,
.RouteContent #Sidebar-Right {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4rem;
  pointer-events: none;
}
.RouteContent #Sidebar-Left .btn,
.RouteContent #Sidebar-Right .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  pointer-events: all;
}
.RouteContent #Sidebar-Left {
  left: 0;
}
.RouteContent #Sidebar-Right {
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ProtectedRoute/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AAAE;;EAEE,kBAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;EACA,oBAAA;AAEJ;AADI;;EACE,qCAAA;EACA,wCAAA;EACA,mBAAA;AAIN;AADE;EACE,OAAA;AAGJ;AADE;EACE,QAAA;AAGJ","sourcesContent":[".RouteContent {\n  position: relative;\n  flex-grow: 1;\n}\n.RouteContent #Sidebar-Left,\n.RouteContent #Sidebar-Right {\n  position: absolute;\n  top: 0;\n  height: 100%;\n  width: 4rem;\n  pointer-events: none;\n}\n.RouteContent #Sidebar-Left .btn,\n.RouteContent #Sidebar-Right .btn {\n  border-top-right-radius: 0 !important;\n  border-bottom-right-radius: 0 !important;\n  pointer-events: all;\n}\n.RouteContent #Sidebar-Left {\n  left: 0;\n}\n.RouteContent #Sidebar-Right {\n  right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

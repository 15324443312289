// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatWidget {
  width: 100%;
  overflow: hidden auto;
  animation: slideIn 0.5s ease;
}
.ChatWidget p {
  margin: 0;
}
@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.ChatWidget-Unread {
  position: relative;
}
.ChatWidget-Unread .badge {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
.ChatWidget-Unread .sv-inbox {
  font-size: 24px;
}
.ChatWidget-Unread a {
  color: white;
  text-decoration: none;
}

.ChatWidget-Header {
  color: var(--bs-light);
  background-color: var(--bs-primary);
  letter-spacing: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,qBAAA;EAIA,4BAAA;AAFF;AADE;EACE,SAAA;AAGJ;AAAE;EACE;IACE,2BAAA;EAEJ;EAAE;IACE,wBAAA;EAEJ;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,YAAA;EACA,cAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,YAAA;EACA,qBAAA;AAEJ;;AAEA;EACE,sBAAA;EACA,mCAAA;EACA,sBAAA;AACF","sourcesContent":[".ChatWidget {\n  width: 100%;\n  overflow: hidden auto;\n  animation: slideIn 0.5s ease;\n}\n.ChatWidget p {\n  margin: 0;\n}\n@keyframes slideIn {\n  from {\n    transform: translateY(100%);\n  }\n  to {\n    transform: translateY(0);\n  }\n}\n\n.ChatWidget-Unread {\n  position: relative;\n}\n.ChatWidget-Unread .badge {\n  position: absolute;\n  top: -0.5rem;\n  right: -0.5rem;\n}\n.ChatWidget-Unread .sv-inbox {\n  font-size: 24px;\n}\n.ChatWidget-Unread a {\n  color: white;\n  text-decoration: none;\n}\n\n.ChatWidget-Header {\n  color: var(--bs-light);\n  background-color: var(--bs-primary);\n  letter-spacing: 0.3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

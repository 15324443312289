import './styles.scss';

import DynamicContent, { DynamicContentProps } from '@/components/DynamicContent';
import Filters, { FiltersProps } from '@/components/Filters';
import React, { ReactNode } from 'react';

import { getClasses } from '@/utils';

type DynamicContentPageProps = DynamicContentProps & {
  header?: ReactNode;
  footer?: ReactNode;
  filters?: FiltersProps;
  children?: ReactNode;
};
const DynamicContentPage = ({ header, footer, filters, children, ...dynamicContent }: DynamicContentPageProps) => (
  <DynamicContent {...dynamicContent} className={getClasses('DynamicContentPage', dynamicContent?.className)}>
    <div className="DynamicContentPage-Header">{header}</div>
    {filters && <Filters {...filters} />}
    <div className="DynamicContentPage-Body">{children}</div>
    <div className="DynamicContentPage-Footer">{footer}</div>
  </DynamicContent>
);

export default DynamicContentPage;

import { GetAvailabilityTypesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getAvailabilityTypes = createGraphApiMockResponse(GetAvailabilityTypesDocument, {
  getFilters: {
    filters: {
      availabilityTypes: [
        {
          id: 'APPROVED_ON',
          displayName: 'Approved Time On',
          __typename: 'FilterOption',
        },
        {
          id: 'APPROVED_OFF',
          displayName: 'Approved Time Off',
          __typename: 'FilterOption',
        },
        {
          id: 'REQUEST_OFF',
          displayName: 'Request Time Off',
          __typename: 'FilterOption',
        },
        {
          id: 'REQUEST_ON',
          displayName: 'Request Time On',
          __typename: 'FilterOption',
        },
      ],
    },
  },
});

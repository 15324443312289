import GenericFileUpload, { GenericFileType } from '@/components/FileUpload/new';

import React from 'react';
import generatePunchHistoryUploadUrls from '../../api/services/generatePunchPolicyUploadUrls';

const getEndpointsUrl = async (file: GenericFileType): Promise<string | string[]> => {
  try {
    const {
      file: { name: filename },
      md5,
    } = file;
    return await generatePunchHistoryUploadUrls([{ filename, md5 }]);
  } catch (err) {
    console.error(err);
  }
};
const PunchPolicyUpload = ({ onSubmit }) => {
  return (
    <div className={'{width:888px;}'}>
      <div className="d-flex flex-column justify-content-center text-center text-primary mb-4">
        <h1>No Reporting Data Found</h1>
        <p>Drag and drop or select upload to import a CSV to generate a report</p>
      </div>
      <GenericFileUpload
        options={{ supportedExtensions: ['csv'], multiple: false }}
        endpoint={getEndpointsUrl}
        onCancel={async () => console.log('Cancel')}
        onContinue={() => onSubmit()}
      />{' '}
    </div>
  );
};

export default PunchPolicyUpload;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Report .Details:not(.inline) {
  width: 90%;
  margin: 0 auto;
}
.Report .VirtualTable {
  border: var(--vt-item-border);
  border-width: 1px 0;
}
.Report .VirtualTable .VirtualTable-Cell {
  text-align: center;
}
.Report .VirtualTable .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {
  text-align: left;
}
.Report .VirtualTable .VirtualTable-Expanded + .VirtualTable-Row {
  border-top: var(--vt-item-border);
}
.Report .VirtualTable .VirtualTable-Expanded .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {
  text-align: center !important;
}
.Report .VirtualTable .VirtualTable-Expanded .VirtualTable-Expanded .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {
  text-align: right !important;
}
.Report .VirtualTable *:not(.VirtualTable-Header) .VirtualTable-Row {
  border-top: var(--vt-item-border);
}
.Report .VirtualTable *:not(.VirtualTable-Header) .VirtualTable-Row .VirtualTable-Cell {
  border: var(--vt-item-border);
  border-width: 0 0 0 1px;
}

.clientSummaryReport .VirtualTable .VirtualTable-Expanded .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {
  text-align: right !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Reports/DynamicReport/styles.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,cAAA;AAAJ;AAEE;EACE,6BAAA;EACA,mBAAA;AAAJ;AACI;EACE,kBAAA;AACN;AAEM;EACE,gBAAA;AAAR;AAIM;EACE,iCAAA;AAFR;AAKQ;EACE,6BAAA;AAHV;AAQU;EACE,4BAAA;AANZ;AAaM;EACE,iCAAA;AAXR;AAYQ;EACE,6BAAA;EACA,uBAAA;AAVV;;AAoBQ;EACE,4BAAA;AAjBV","sourcesContent":[".Report .Details:not(.inline) {\n  width: 90%;\n  margin: 0 auto;\n}\n.Report .VirtualTable {\n  border: var(--vt-item-border);\n  border-width: 1px 0;\n}\n.Report .VirtualTable .VirtualTable-Cell {\n  text-align: center;\n}\n.Report .VirtualTable .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {\n  text-align: left;\n}\n.Report .VirtualTable .VirtualTable-Expanded + .VirtualTable-Row {\n  border-top: var(--vt-item-border);\n}\n.Report .VirtualTable .VirtualTable-Expanded .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {\n  text-align: center !important;\n}\n.Report .VirtualTable .VirtualTable-Expanded .VirtualTable-Expanded .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {\n  text-align: right !important;\n}\n.Report .VirtualTable *:not(.VirtualTable-Header) .VirtualTable-Row {\n  border-top: var(--vt-item-border);\n}\n.Report .VirtualTable *:not(.VirtualTable-Header) .VirtualTable-Row .VirtualTable-Cell {\n  border: var(--vt-item-border);\n  border-width: 0 0 0 1px;\n}\n\n.clientSummaryReport .VirtualTable .VirtualTable-Expanded .VirtualTable-Row > .VirtualTable-Expand + .VirtualTable-Cell {\n  text-align: right !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

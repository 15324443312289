import React, { ReactNode, useState } from 'react';

import { Button } from 'react-bootstrap';
import EditAirlineForm from '../../components/EditAirlineForm';

const initState = {
  show: false,
};
const airlineId: string = '0efd99a1-66b0-4812-968d-d978b6ae2131';
const FormSandbox = (): ReactNode => {
  const [state, setState] = useState(initState);
  const { show } = state;

  const onHide = () => {
    setState((current) => ({ ...current, show: false }));
  };

  return (
    <>
      <Button onClick={() => setState((current) => ({ ...current, show: !current.show }))}>{'Show'}</Button>
      <div className="p-5">
        <EditAirlineForm
          show={show}
          value={airlineId}
          onSubmit={async (form) => {
            console.log('parent onSubmit called', form);
          }}
          onCancel={onHide}
          onHide={onHide}
          inline
          modal
          drawer
        />
      </div>
    </>
  );
};
export default FormSandbox;

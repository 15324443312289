import { Device, GetPortalConfigResponse } from '../../../models/gen/graphql';
import { GetMapConfigDocument, GetPortalConfigDocument } from '../../queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type GetPortalConfigGraphApiResponse = GraphApiResponse<typeof GetPortalConfigDocument>;
type GetMapConfigGraphApiResponse = GraphApiResponse<typeof GetMapConfigDocument>;

const device = Device.PortalV1;

const getPortalConfigResponseSelector = (res: GetPortalConfigGraphApiResponse | GetMapConfigGraphApiResponse): GetPortalConfigResponse =>
  res?.getPortalConfig as GetPortalConfigResponse;

const [runGetPortalConfig, runRefetchGetPortalConfig] = graphApi(GetPortalConfigDocument);
const [runGetMapConfig, runRefetchGetMapConfig] = graphApi(GetMapConfigDocument);

const getPortalConfig = async (): Promise<GetPortalConfigResponse> => {
  const res = await runRefetchGetPortalConfig({ input: { device } });
  return getPortalConfigResponseSelector(res);
};

export const getMapConfig = async (): Promise<string> => {
  const res = await runRefetchGetMapConfig({ input: { device } });
  const data = getPortalConfigResponseSelector(res);
  return data?.googleMapsApiKey || '';
};

export const useGetPortalConfig = createGraphApiHook(getPortalConfig);

export default getPortalConfig;

import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const getOnTimePerformanceReportAsCSV: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (input: any = {}): Promise<any> => {
      const variables = {
        input: {
          after: null,
          airports: null,
          endDatetime: null,
          first: null,
          iataAirlineCodes: null,
          payerProviderIds: null,
          startDatetime: null,
          ...input,
          format: input?.format || 'AIRPORT',
        },
      };
      return fireQuery({
        variables,
        fetchPolicy: 'no-cache',
      });
    },
  selector: (data: any): any => data?.getOnTimePerformanceReportAsCSV?.output || {},
  query: gql`
    query GetOnTimePerformanceReportAsCSV($input: GetOnTimePerformanceReportAsCSVInput!) {
      getOnTimePerformanceReportAsCSV(input: $input) {
        output
      }
    }
  `,
};

export default getOnTimePerformanceReportAsCSV;

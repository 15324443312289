// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripStop-TimeSchedule .input-group > small {
  font-weight: 700;
  position: absolute;
  top: -1.5rem;
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/EditTripsModal/styles.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AADN","sourcesContent":[".TripStop-TimeSchedule .input-group > small {\n  font-weight: 700;\n  position: absolute;\n  top: -1.5rem;\n  width: 100%;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

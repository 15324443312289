import './styles.scss';

import { Badge } from 'react-bootstrap';
import { FLAG_DISPLAY_MAX_COUNT } from '../../constants';
import { FlagWithTrip } from '../../models/gen/graphql';
import React from 'react';
import { pixelsToRem } from '../../utils/custom';

type Props = {
  flags?: FlagWithTrip[];
} & React.HTMLAttributes<HTMLSpanElement>;

const BADGE_REM_SIZE = pixelsToRem(4 + FLAG_DISPLAY_MAX_COUNT);

const FlagsButton = ({ flags = [], ...props }: Props): React.JSX.Element => (
  <div {...props} className={`FlagsButton ${props?.onClick ? '{cursor:pointer;}' : ''} ${props?.className}`}>
    {!flags?.length && <i className="sv sv-flag-empty" />}
    {!!flags?.length &&
      flags
        .slice(0, FLAG_DISPLAY_MAX_COUNT)
        .map(
          (flag: FlagWithTrip, f: number): React.JSX.Element => (
            <i
              className={`sv sv-flag-filled {left:${pixelsToRem(f * 5)}rem;z-index:${Math.round(FLAG_DISPLAY_MAX_COUNT / (f + 1))};}`}
              style={{ color: flag?.color }}
              key={f}
            />
          )
        )}
    {flags?.length > FLAG_DISPLAY_MAX_COUNT && (
      <Badge className={`{z-index:10;left:${BADGE_REM_SIZE}rem;}`}>+{flags?.length - FLAG_DISPLAY_MAX_COUNT}</Badge>
    )}
  </div>
);

export default FlagsButton;

import './styles.scss';

import React, { ReactNode } from 'react';

import { getClasses } from '@/utils';

const ChatMessageAvatar = ({ url, size = 'sm', icon }: { url?: string; size?: 'sm' | 'md' | 'lg'; icon?: ReactNode }): ReactNode => {
  return (
    <div className="ChatMessageAvatar">
      {url ? (
        <img src={url} alt="avatar" className={getClasses('ChatMessageImage', `ChatMessageImage-${size}`)} />
      ) : (
        icon || <i className={getClasses('ChatMessageIcon', `ChatMessageIcon-${size}`, 'fa fa-user')} />
      )}
    </div>
  );
};

export default ChatMessageAvatar;

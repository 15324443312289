import { GeneratePunchHistoryUploadUrlInput, GeneratePunchHistoryUploadUrlsInput } from '@/models/gen/graphql';

import { GeneratePunchHistoryUploadUrlsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GeneratePunchHistoryUploadUrlsResponseApi = GraphApiResponse<typeof GeneratePunchHistoryUploadUrlsDocument>;

const title = 'Generate Punch Policy Upload Urls';

const generatePunchHistoryUploadUrlsResponseSelector = (response: GeneratePunchHistoryUploadUrlsResponseApi) =>
  !response.errors
    ? response?.generatePunchHistoryUploadUrls?.output?.flatMap?.(({ presignedUploadUrl, contentType }) => [
        presignedUploadUrl,
        contentType,
      ])
    : undefined;

const [runGeneratePunchHistoryUploadUrls] = graphApi(GeneratePunchHistoryUploadUrlsDocument, {
  onError: (res: GeneratePunchHistoryUploadUrlsResponseApi): void => handleError(res, { notification: { title } }),
});

const generatePunchHistoryUploadUrls = async (input: GeneratePunchHistoryUploadUrlInput[]): Promise<string[] | undefined> => {
  const inputs: GeneratePunchHistoryUploadUrlsInput = { input };
  const response = await runGeneratePunchHistoryUploadUrls({ input: inputs });
  const selected = generatePunchHistoryUploadUrlsResponseSelector(response);
  return selected;
};

export const useGeneratePunchHistoryUploadUrls = createGraphApiHook(generatePunchHistoryUploadUrls);
export default generatePunchHistoryUploadUrls;

import { generateSlice } from 'utils';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'modals',
  version: 1,
  storage,
  blacklist: ['ChangePasswordModal', 'ResetPasswordModal'],
};
const initModalsState = {};
const setState = (state: any, action: any): void => ({
  ...state,
  [action.payload.name]: action.payload.data,
});
const showModal = (state: any, action: any): void => setState(state, { ...action, payload: action.payload || {} });
const hideModal = (state: any, action: any): void => setState(state, { ...action, payload: action.payload || {} });
const modalsSlice = generateSlice('modals', initModalsState, {
  setState,
  showModal,
  hideModal,
});

export { modalsSlice };
export default persistReducer(persistConfig, modalsSlice.reducer);

import { GET_KINDS } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectKind = (props) => (
  <SelectFromQuery
    cache="Kinds"
    placeholder="All Kinds"
    query={GET_KINDS}
    selector={(data) =>
      (data?.getFilters?.filters?.kind || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }))
    }
    {...props}
  />
);

export default SelectKind;

import React, { useRef, useState } from 'react';

import { Badge } from 'react-bootstrap';

interface TagInputProps {
  name?: string;
  value: string[];
  onChange: (tags: string[]) => void;
  placeholder?: string;
  onValidate?: (tag: string) => boolean;
}
const TagInput = ({ name, value, onChange, placeholder, onValidate = (): boolean => true }: TagInputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = (): void => {
    const isValid = onValidate(inputValue);
    if (!isValid) return;
    if (inputValue.trim() !== '') {
      const newTags = Array.from(new Set([...value, inputValue.trim()]));
      onChange(newTags);
      setInputValue('');
      containerRef.current.scrollLeft =
        containerRef.current.scrollLeft + (containerRef.current.scrollWidth - containerRef.current.clientWidth);
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const newTags = value.filter((tag) => tag !== tagToRemove);
    onChange(newTags);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && inputValue === '' && value.length > 0) {
      const newTags = [...value];
      newTags.pop();
      onChange(newTags);
    } else if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  return (
    <div
      className="TagInput d-flex align-items-center border border-primary rounded p-1 flex-grow-1 flex-wrap flex-row gap-1 {overflow:auto;-ms-overflow-style:none;scrollbar-width:none;} {display:none;}::-webkit-scrollbar"
      ref={containerRef}
    >
      {value.map((tag, index) => (
        <Badge key={index} bg="primary" className="me-1 fs-6 fw-normal rounded border-box">
          {tag}{' '}
          <span className="text-white pointer" onClick={() => handleRemoveTag(tag)}>
            <i className="fa fa-times ms-2"></i>
          </span>
        </Badge>
      ))}
      <input
        ref={inputRef}
        name={name}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        className="border-0 flex-grow-1 {line-height:1.5rem;outline:none;}}"
      />
    </div>
  );
};

export default TagInput;

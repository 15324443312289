import { useEffect, useMemo, useRef, useState } from 'react';

import { Validation } from 'utils';

// export type UseValidationReturn = [boolean, Record<string, Validation.Validity>, boolean];
const useValidation = <T>(
  validator: Validation.Validator,
  input: T,
  middleware?: { [key: string]: Validation.Method }
): [boolean, Record<keyof T, Validation.Validity>, boolean] => {
  const originalInput = useRef(input);
  const [validity, setValidity] = useState<Record<keyof T, Validation.Validity>>();
  const [dirty, setDirty] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validatorObj = useMemo((): Validation.Validator => validator, []);

  useEffect((): void => {
    if (!validatorObj || !input) return;
    const result: any = validatorObj.validate(input, middleware);
    let isDirty = false;
    Object.entries(result).forEach(([key]: [string, unknown]): void => {
      result[key].dirty = !!((validity || {})?.[key] || {})?.['dirty'] || result?.[key]?.value !== originalInput.current?.[key];
      isDirty = isDirty || result[key].dirty;
    });
    setValidity((_current: Record<keyof T, Validation.Validity>): Record<keyof T, Validation.Validity> => result);
    setDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorObj, input, middleware]);

  return [validatorObj?.valid, validity, dirty];
};

export default useValidation;

import { Button, Form } from 'react-bootstrap';
import React, { useState } from 'react';

import { createNotification } from '@/utils';

const initNotificationsPaneState = {
  title: '',
  type: undefined,
  content: '',
};
const NotificationsPane = () => {
  const [state, setState] = useState(initNotificationsPaneState);
  const { title, type, content } = state;

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((current) => ({ ...current, [name]: value }));
  };

  return (
    <>
      <Form.Group>
        <Form.Label className="m-0">
          <small>Title</small>
        </Form.Label>
        <Form.Control onChange={onChange} name="title" value={title} />
      </Form.Group>
      <Form.Group className="mt-2">
        <Form.Label className="m-0">
          <small>Content</small>
        </Form.Label>
        <Form.Control onChange={onChange} name="content" value={content} />
      </Form.Group>
      <Form.Group className="mt-2">
        <Form.Label className="m-0">
          <small>Type</small>
        </Form.Label>
        <Form.Control as="select" onChange={onChange} name="type" value={type}>
          <option>Default</option>
          {['Primary', 'Secondary', 'Success', 'Danger', 'Warning', 'Info', 'Light', 'Dark'].map((variant, v) => (
            <option value={variant.toLowerCase()} key={v}>
              {variant}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group className="mt-2 d-flex justify-content-end">
        <Button className="ms-2" variant="outline-success" onClick={() => createNotification(content, type, title)} disabled={!content}>
          Notify
        </Button>
      </Form.Group>
    </>
  );
};

export default NotificationsPane;

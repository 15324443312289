// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Grid {
  --gc-border-color: rgba(0, 0, 0, 0.1);
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-flow: row;
  gap: 0;
  border: 1px solid var(--gc-border-color);
  border-radius: var(--bs-border-radius);
}
.Grid .Grid-Cell {
  padding: 0.375rem 0.75rem;
  grid-column: auto/span 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Grid .Grid-Cell:not(:first-child):not(.Grid-Row-Start) {
  border-left: 1px solid var(--gc-border-color);
}
.Grid .Grid-Cell.Grid-Row-Start:not(:first-of-type), .Grid .Grid-Cell.Grid-Row-Start:not(:first-of-type) ~ :not(.Grid-Row-Start) {
  border-top: 1px solid var(--gc-border-color);
}

.Grid .Grid-Cell.Header {
  background-color: var(--bs-secondary-subtle);
  font-weight: 700;
}
.Grid .Grid-Cell.LATE {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/Grid/styles.scss"],"names":[],"mappings":"AAEA;EACE,qCAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,MAAA;EACA,wCAAA;EACA,sCAAA;AADF;AAEE;EACE,yBAAA;EACA,wBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAEM;EACE,6CAAA;AAAR;AAKQ;EAEE,4CAAA;AAJV;;AAcI;EACE,4CAAA;EACA,gBAAA;AAXN;AAaI;EACE,qBAAA;AAXN","sourcesContent":[".Grid {\n  --gc-border-color: rgba(0, 0, 0, 0.1);\n  display: grid;\n  width: 100%;\n  height: 100%;\n  grid-auto-flow: row;\n  gap: 0;\n  border: 1px solid var(--gc-border-color);\n  border-radius: var(--bs-border-radius);\n}\n.Grid .Grid-Cell {\n  padding: 0.375rem 0.75rem;\n  grid-column: auto/span 1;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.Grid .Grid-Cell:not(:first-child):not(.Grid-Row-Start) {\n  border-left: 1px solid var(--gc-border-color);\n}\n.Grid .Grid-Cell.Grid-Row-Start:not(:first-of-type), .Grid .Grid-Cell.Grid-Row-Start:not(:first-of-type) ~ :not(.Grid-Row-Start) {\n  border-top: 1px solid var(--gc-border-color);\n}\n\n.Grid .Grid-Cell.Header {\n  background-color: var(--bs-secondary-subtle);\n  font-weight: 700;\n}\n.Grid .Grid-Cell.LATE {\n  background-color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { DeleteCompletionBulkInput, DeleteCompletionInput } from '../../models/gen/graphql';
import { customDeleteHandler, validateObj } from '../../utils';

import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const validateDeleteCompletion = validateObj({
  id: (val: any): any => val || '',
});

const deleteCompletionBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (...ids: string[]): Promise<any> => {
      const input: DeleteCompletionBulkInput = {
        search: ids.map(
          (id: string): DeleteCompletionInput => ({
            id,
          })
        ),
      };

      return fireQuery({
        variables: {
          input,
        },
      });
    },
  selector: (data: any): number => data?.deleteCompletionBulk?.deleted || 0,
  handler: customDeleteHandler('Delete Completion on Trip', 'completion', 'deleteCompletionBulk'),
  query: gql`
    mutation DeleteCompletionBulk($input: DeleteCompletionBulkInput!) {
      deleteCompletionBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteCompletionBulk;
export { validateDeleteCompletion };

import React, { useState } from 'react';
import {
  handleDeleteUserAvailabilityAndUnassignBulk,
  handleDeleteUserAvailabilityBulk,
} from '../../api/services/users/deleteUserAvailabilityBulk';

import { Button } from 'react-bootstrap';
import { Datetime } from 'utils';
import EditModal from '../../components/EditModal/new';
import Logger from '../../utils/logs';
import { UserAvailability } from 'models/gen/graphql';

const log = Logger.of('DeleteAvailabilityConfirmationModal');

type State = {
  confirmRemoveFromSchedule: boolean;
  deleteSeries: boolean;
};
type Props = {
  data: UserAvailability[];
  date: string;
  show: boolean;
  onHide: () => void;
};

const initDeleteAvailabilityConfirmationModalState: State = { confirmRemoveFromSchedule: false, deleteSeries: false };

const DeleteAvailabilityConfirmationModal = ({ show, onHide, data, date }: Props): JSX.Element => {
  const [state, setState] = useState(initDeleteAvailabilityConfirmationModalState);
  const { confirmRemoveFromSchedule, deleteSeries } = state;

  const handleHide = async (): Promise<void> => {
    setState(initDeleteAvailabilityConfirmationModalState);
    await onHide();
  };

  const onDelete = async (deleteSeries: boolean = false): Promise<void> => {
    setState({ confirmRemoveFromSchedule: true, deleteSeries });
  };

  const deleteAndRemove = async (): Promise<void> => {
    try {
      await handleDeleteUserAvailabilityAndUnassignBulk(data, deleteSeries);
    } catch (err) {
      log.error(err);
    } finally {
      handleHide();
    }
  };
  const deleteAndKeep = async (): Promise<void> => {
    try {
      await handleDeleteUserAvailabilityBulk(data, deleteSeries);
    } catch (err) {
      log.error(err);
    } finally {
      handleHide();
    }
  };

  const availability = data?.[0];
  const hasSeries = !!availability?.repeatUntil;
  const startDateTime = new Datetime(availability.startDate).setTime(availability.startTime);
  const endDateTime = new Datetime(availability.endDate).setTime(availability.endTime);

  const deleteSeriesString = !hasSeries
    ? ''
    : `or Delete ${startDateTime.format('dddd')} Series from ${startDateTime.time} - ${endDateTime.time}`;

  const text = confirmRemoveFromSchedule
    ? 'Do you want to unassign the driver from currently assigned trips?'
    : `Deleting availability for ${new Datetime(date).frontendDate} ${deleteSeriesString}`.trim() + '. Are you sure ?';

  return (
    <EditModal
      size="sm"
      show={show}
      onHide={handleHide}
      title={confirmRemoveFromSchedule ? 'Unassign From Trips' : 'Delete Availability'}
      name={'deleteAvailability'}
      options={{ Footer: null }}
    >
      <p className="mt-3 mb-4">{text}</p>
      <div className="text-end {margin-left:0.5rem;margin-top:0.5rem;}>button">
        <Button name="CANCEL" variant="secondary" onClick={handleHide}>
          Cancel
        </Button>
        {confirmRemoveFromSchedule && (
          <>
            <Button name="REMOVE" variant="red" onClick={deleteAndRemove}>
              Remove
            </Button>
            <Button name="KEEP" variant="primary" onClick={deleteAndKeep}>
              Keep
            </Button>
          </>
        )}
        {!confirmRemoveFromSchedule && (
          <>
            <Button name="SUBMIT" variant="primary" onClick={() => onDelete()}>
              Delete
            </Button>
            {hasSeries && (
              <Button name="DELETE_SERIES" variant="primary" onClick={() => onDelete(true)}>
                Delete Availability Series
              </Button>
            )}
          </>
        )}
      </div>
    </EditModal>
  );
};

export default DeleteAvailabilityConfirmationModal;

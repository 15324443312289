import { Stop, UpdateResponse, UpdateStopBulkInput } from '@/models/gen/graphql';
import { createNotification, generateUpdateBulkPayload, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { UpdateStopBulkDocument } from '@/api/queries';
import graphApi from '@/api';

type UpdateStopBulkGraphApiResponse = GraphApiResponse<typeof UpdateStopBulkDocument>;

const title = 'Update Stops';

const updateStopBulkResponseSelector = (res: UpdateStopBulkGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.updateStopBulk?.response || [] : undefined;

const [runUpdateStopBulk] = graphApi(UpdateStopBulkDocument, {
  onError: (res: UpdateStopBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateStopBulk = async (stops: Stop[]): Promise<UpdateResponse[]> => {
  const input: UpdateStopBulkInput = {
    updates: generateUpdateBulkPayload(stops),
  };
  const res = await runUpdateStopBulk({ input });

  const selected = updateStopBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateStopBulk;

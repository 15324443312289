import { GetAirportGroupPermissionsDocument } from '../../api/queries';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectAirportGroupPermission = ({ ...props }) => (
  <SelectFromQuery
    cache="AirportGroupPermission"
    placeholder="All Airports"
    query={GetAirportGroupPermissionsDocument}
    selector={(data) =>
      (data?.getFilters?.filters?.airportGroupPermissions || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
        values: opt.values,
      }))
    }
    {...props}
  />
);

export default SelectAirportGroupPermission;

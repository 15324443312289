import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getImportContractProvider } from '@/api/services/filters/importContractProvider';

export type ImportContractProviderDropdownProps = Omit<QueryDropdownProps, 'query'>;

const ImportContractProviderDropdown = ({ name = 'airline', ...props }: ImportContractProviderDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getImportContractProvider();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return (
    <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Airline' } }} />
  );
};

export default ImportContractProviderDropdown;

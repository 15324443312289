// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PunchPolicyTimeControls .FormField-Input {
  text-align: center;
}
.PunchPolicyTimeControls .append,
.PunchPolicyTimeControls .prepend {
  padding: 0;
  background-color: var(--bs-secondary-subtle);
}
.PunchPolicyTimeControls .append .number-field-modifier,
.PunchPolicyTimeControls .prepend .number-field-modifier {
  padding: 0.5rem 0.5rem;
  width: 100%;
  height: 100%;
}
.PunchPolicyTimeControls span.index-badge {
  color: white !important;
  display: flex;
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/PunchPolicy/components/PunchPolicyTimeControls/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;;EAEE,UAAA;EACA,4CAAA;AAAJ;AACI;;EACE,sBAAA;EACA,WAAA;EACA,YAAA;AAEN;AACE;EACE,uBAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AACJ","sourcesContent":[".PunchPolicyTimeControls .FormField-Input {\n  text-align: center;\n}\n.PunchPolicyTimeControls .append,\n.PunchPolicyTimeControls .prepend {\n  padding: 0;\n  background-color: var(--bs-secondary-subtle);\n}\n.PunchPolicyTimeControls .append .number-field-modifier,\n.PunchPolicyTimeControls .prepend .number-field-modifier {\n  padding: 0.5rem 0.5rem;\n  width: 100%;\n  height: 100%;\n}\n.PunchPolicyTimeControls span.index-badge {\n  color: white !important;\n  display: flex;\n  width: 1.35rem;\n  height: 1.35rem;\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import './styles.scss';

import { Alert, Button } from 'react-bootstrap';
import FooterDrawer, { FooterDrawerProps } from '@/components/FooterDrawer';
import React, { useEffect, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';
import { getClasses, parseObj } from '@/utils';

import { LoadingBlur } from '@/components/LoadingSpinner';
import { Middle } from '@/components/Align';
import { ReportTable } from '@/pages/Reports/DynamicReport';
import Tippy from '@tippyjs/react';
import { TripTableFormatEnum } from '@/models/gen/graphql';
import { reportTypes } from '@/pages/Reports';
import useHashNavigation from '@/hooks/useHashNavigation';
import useLocale from '@/hooks/useLocale';
import { useParams } from 'react-router-dom';
import { useReportQuery } from '@/api/services/reports/reportQuery';

type TripsFilters = {
  airportCode: string[];
  payerProviderId: string;
  doLocationId: string[];
  flightNumber: string;
  from: string;
  puLocationId: string[];
  to: string;
  time: string;
  type: string[];
  servicerIataAirlineCode: string;
  rateAmount: string;
  format: TripTableFormatEnum;
};

const initTripsFilters: TripsFilters = {
  servicerIataAirlineCode: '',
  airportCode: [],
  payerProviderId: '',
  doLocationId: [],
  flightNumber: '',
  from: TODAY,
  puLocationId: [],
  time: undefined,
  to: TODAY_EOD,
  type: [],
  rateAmount: '',
  format: TripTableFormatEnum.All,
};

type DetailsDrawerProps = Omit<FooterDrawerProps, 'show'> & {
  report?: string;
  query?: any;
  show?: Record<string, unknown>;
  locale?: Record<string, string>;
};

const DetailsDrawer = ({
  show,
  onClose,
  report,
  query = {},
  className,
  locale: detailsDrawerLocale = {},
}: DetailsDrawerProps): JSX.Element => {
  const { type = Object.keys(reportTypes)?.[0] } = useParams();
  const reportType = (report || type) === 'complaintReport' ? 'fcrReport' : report || type;
  const [{ loading, data: { output = undefined, error: gqlError = undefined } = {}, error: apiError = undefined }, { refetch }] =
    useReportQuery('genericReport');
  const [data, setData] = useState(output);
  const error = gqlError || apiError;
  const locale = useLocale(detailsDrawerLocale);
  const [, navigateWithHash] = useHashNavigation();

  useEffect((): void => {
    if (!show) return setData(undefined);
    const { __id, ...input } = show;
    const result = { ...query, ...input };
    refetch(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect((): void => {
    if (!output) return;
    setData(output);
  }, [output]);

  const { tables = [] } = parseObj(data || {});
  const classes = getClasses('DetailsDrawer', className);

  const convertDetailsToTrips = (input): TripsFilters => {
    if (!input) return initTripsFilters;
    const { airports, startDatetime, endDatetime, clients, rates, types } = input;
    return {
      ...initTripsFilters,
      ...(airports && { airportCode: airports }),
      ...(startDatetime && { from: startDatetime }),
      ...(endDatetime && { to: endDatetime }),
      ...(clients?.[0] && { payerProviderId: clients[0] }),
      ...(rates?.[0] && { rateAmount: rates[0] }),
      ...(types && { type: types }),
    };
  };

  return (
    <FooterDrawer
      className={classes}
      show={show}
      onClose={onClose}
      title={
        <div className="d-flex">
          <span className="z-2">{locale('Detailed Report')}</span>
          <span className="flex-grow-1" />
          <span className="z-2 pe-2">
            {reportType === 'clientSummaryReport' && (
              <Button
                variant="text"
                className="z-3 p-0"
                onClick={() => {
                  const { __id, ...input } = show;
                  navigateWithHash('/trips', convertDetailsToTrips(input));
                }}
              >
                <Tippy content={locale('Open Trips Page')}>
                  <u className="text-primary">{locale('Go To Trips')}</u>
                </Tippy>
              </Button>
            )}
          </span>
          <span className="z-2 pe-2">
            <Button
              variant="text"
              className="z-3 p-0"
              onClick={() => {
                const { __id, ...input } = show;
                navigateWithHash('/reports/detailedReport', input);
              }}
            >
              <Tippy content={locale('Open Detailed Report')}>
                <u className="text-primary">{locale('Go To Detailed Report')}</u>
              </Tippy>
            </Button>
          </span>
        </div>
      }
    >
      <LoadingBlur loading={!show || !!loading} size="lg" />
      {error && (
        <div className="pt-5">
          <Middle.Center>
            <Alert className="text-center" variant="danger">
              <h5>
                {locale('Something went wrong.')}
                <small className="d-block mt-3">
                  <em>{error?.message || error}</em>
                </small>
              </h5>
            </Alert>
          </Middle.Center>
        </div>
      )}
      {!error && (
        <>
          {!!tables.length &&
            tables.map(
              (table: any, t: number): JSX.Element => (
                <ReportTable
                  name={`${reportType}TableDetails`}
                  columns={table?.metadata?.columns}
                  rows={table?.children || []}
                  index={t}
                  key={t}
                  condensed={true}
                />
              )
            )}
        </>
      )}
    </FooterDrawer>
  );
};

export default DetailsDrawer;

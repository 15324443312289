// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessage {
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatMessage/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":[".ChatMessage {\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

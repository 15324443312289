import { Col, Row } from 'react-bootstrap';
import { CompletionCurrencyEnum, FilterOption } from '../../models/gen/graphql';
import { Datetime, Validation } from '../../utils';
import React, { useEffect, useMemo } from 'react';

import EditCompletionModalFooter from '../../components/EditCompletionModal/EditCompletionModalFooter';
import EditModal from '../../components/EditModal/new';
import FormField from '../../components/FormField';
import { LoadingBlur } from '../../components/LoadingSpinner';
import SelectCurrency from 'components/SelectCurrency';
import { getCompletionReasons } from '../../queries';
import useDb from '../../hooks/useDb';
import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import useTrips from '../../hooks/useTrips';

const initCompletion = {
  completionTypeId: '',
  datetime: new Datetime().toString(),
  cost: '0.00',
  currency: CompletionCurrencyEnum.Usd,
  comment: '',
};
const EditCompletion = (): JSX.Element => {
  const [, { handleSubmitCompletion, handleDeleteCompletionBulk }] = useTrips();
  const [state, setState] = useModal('EditCompletion', {
    onSubmit: async (): Promise<void> => {
      onHide();
    },
  });
  const { show = false, tripId = '', servicerIataAirlineCode = '', flightNumber = 0, scheduled = '' } = state;
  const { onHide, onSubmit } = setState;
  const [completion, onChange, setCompletion] = useForm(initCompletion);
  const { completionTypeId, datetime, cost, currency, comment } = completion;

  // Init Queries

  const { data: getCompletionReasonsResponse, loading } = useDb('CompletionReasons', getCompletionReasons.query);
  const getCompletionReasonsData = useMemo(
    () => ({
      rows: getCompletionReasonsResponse?.getFilters?.filters?.completionReasons || [],
    }),
    [getCompletionReasonsResponse]
  );

  const handleCostChange = (event: any): void => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);
    if (!Validation.isNumber(parsedValue)) return onChange({ target: { name, value: '' } });

    let output: number = 0;
    if (parsedValue > 0) output = parseFloat(parsedValue.toFixed(2));

    onChange({ target: { name, value: output } });
  };

  // CRUD Handler
  const handleSubmit = async (): Promise<void> => {
    await handleSubmitCompletion([tripId], completion);
    onSubmit();
  };

  const handleDeleteCompletion = async (): Promise<void> => {
    await handleDeleteCompletionBulk([tripId]);
    onSubmit();
  };

  const title = `Trip Completion - ${(servicerIataAirlineCode || '').toUpperCase()}${flightNumber || ''} ${
    scheduled ? new Datetime(scheduled).frontendDatetimeShort : ''
  }`;

  const selectedCompletion = getCompletionReasonsData.rows.find((node: any): boolean => node.id === completionTypeId);
  const showCost =
    ['taxi', 'lyft'].map((str: string): boolean => !!selectedCompletion?.displayName.toLowerCase().includes(str)).includes(true) || false;
  const validity = useMemo((): any => {
    const result: any = {};
    result.completionTypeId = {
      valid: !!completionTypeId,
      message: !Validation.isValidUUID(completionTypeId) ? 'Please select a completion type.' : undefined,
    };
    result.cost = {
      valid: !showCost || Validation.isNumber(cost),
    };
    result.datetime = {
      valid: !!datetime,
    };
    result.currency = {
      valid: !showCost || Object.values(CompletionCurrencyEnum).includes(currency),
    };
    result.comment = {
      valid:
        (!!comment?.length && Validation.isAlphanumeric(comment)) ||
        (completionTypeId &&
          completionTypeId === getCompletionReasonsData?.rows?.find((completion) => completion?.displayName === 'Curbside')?.id),
    };
    return result;
  }, [completionTypeId, cost, currency, comment, datetime, showCost, getCompletionReasonsData]);
  const valid = useMemo(
    (): boolean =>
      !Object.values(validity)
        .map(({ valid }: any): boolean => valid)
        .includes(false),
    [validity]
  );
  useEffect((): void => {
    if (!show) return setCompletion(initCompletion);
    setCompletion((current: any): any => ({
      ...current,
      ...state?.completion,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <EditModal
      name="editCompletion"
      icon="sv sv-completion"
      loading={loading}
      title={title}
      show={show}
      onHide={onHide}
      size="lg"
      options={{
        Footer: <EditCompletionModalFooter onHide={onHide} onReset={handleDeleteCompletion} onUpdate={valid ? handleSubmit : undefined} />,
      }}
      onSubmit={valid ? handleSubmit : false}
      onCancel={handleDeleteCompletion}
    >
      <LoadingBlur loading={loading} />
      <Row className="d-flex justify-content-center mt-3">
        {/* TextArea Component */}
        <Col className="{height:100%;}_*" md={5}>
          <FormField
            onChange={onChange}
            type="textarea"
            name="comment"
            value={comment || ''}
            valid={validity?.comment?.valid}
            placeholder="Reason For Manual Completion"
            options={{ input: { className: '{resize:none;}' } }}
            condensed
          />
        </Col>
        {/* Radio Component */}
        <Col className="d-flex flex-column" xs={3}>
          {(getCompletionReasonsData?.rows || []).map(
            (node: FilterOption, n: number): JSX.Element => (
              <FormField
                id={n + '_completionType'}
                onChange={onChange}
                value={node.id}
                type="radio"
                name="completionTypeId"
                label={node.displayName}
                checked={node.id === completionTypeId}
                valid={validity?.completionTypeId?.valid}
                condensed
                key={n}
              />
            )
          )}
        </Col>
        <Col md={4}>
          <FormField
            label="Completed At:"
            name="datetime"
            type="time"
            value={datetime ? new Datetime(datetime).time : ''}
            onChange={onChange.time}
            valid={validity?.datetime?.valid}
          />
          <FormField
            label="Cost:"
            name="cost"
            type="number"
            step={'0.01'}
            min={0}
            value={cost}
            valid={validity?.cost?.valid}
            onChange={handleCostChange}
            placeholder="Cost"
            options={{ group: { className: showCost ? '' : '{visibility:hidden;}' } }}
          />
          {/* Select Currency */}
          <FormField
            label="Currency:"
            name="currency"
            value={currency}
            onChange={onChange}
            valid={validity?.currency?.valid}
            placeholder="Currency"
            currencyEnumType="completion"
            options={{ group: { className: showCost ? '' : '{visibility:hidden;}' }, input: { as: SelectCurrency } }}
          />
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditCompletion;

import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import React, { useMemo, useRef } from 'react';

import FileType from '../../models/FileType';
import Logger from '../../utils/logs';
import { MAX_FILE_SIZE_MB } from '../../constants';
import TextArea from 'antd/es/input/TextArea';
import useForm from '../../hooks/useForm';
import useUsers from '../../hooks/useUsers';

const log = Logger.of('ReportProblem');

type ReportProblemFormState = {
  images: FileType.Image[];
  message: string;
};

const TOTAL_FILE_SIZE_MB = 10;
const initReportProblemFormState: ReportProblemFormState = { images: [], message: '' };

const ReportProblemForm = (): JSX.Element => {
  const [state, onChange, setState] = useForm(initReportProblemFormState);
  const { images, message } = state;
  const imageUploadRef = useRef(undefined);

  const totalMegabytes = useMemo((): number => {
    let count = 0;
    images.forEach((img: FileType.Image): number => (count += img.size));
    return count;
  }, [state]);

  const [userState, { handleReportProblem }] = useUsers();

  const onRemoveImage =
    (i: number): any =>
    (e: any): void => {
      setState((current: ReportProblemFormState): ReportProblemFormState => {
        const temp = current?.images || [];
        temp.splice(i, 1);
        return {
          ...current,
          images: temp,
        };
      });
    };

  const handleSubmit = async (e: any): Promise<void> => {
    if (totalMegabytes > TOTAL_FILE_SIZE_MB) {
      log.warn(`FileType.Images uploaded exceeds the maximum limit (${totalMegabytes} / ${TOTAL_FILE_SIZE_MB} MB).`).notify({
        message: `Oops! It seems like the total size of the images you uploaded exceeds the maximum limit (${totalMegabytes} / ${TOTAL_FILE_SIZE_MB} MB).
      Please reduce the file sizes or select fewer images to continue.`,
      });
      return;
    }
    await handleReportProblem({ message, images: images.map((img: FileType.Image): string | ArrayBuffer => img.src) });
    setState(initReportProblemFormState);
  };

  const handleUploadFile = (event: any): void => {
    const { files } = event.target;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const megabytes = file.size / (1024 * 1024);

      // Check the file size before proceeding
      if (megabytes > MAX_FILE_SIZE_MB) {
        log.warn(`File ${file.name} exceeds the maximum file size (${megabytes.toFixed(2)} / ${MAX_FILE_SIZE_MB} MB).`).notify({
          message: `File ${file.name} exceeds the maximum file size (${megabytes.toFixed(2)} / ${MAX_FILE_SIZE_MB} MB).`,
        });
        continue; // Skip to the next file
      }

      const reader = new FileReader();
      reader.onloadend = (): void => {
        setState(
          (current: ReportProblemFormState): ReportProblemFormState => ({
            ...current,
            images: [...(current?.images || []), { src: reader.result, size: megabytes, name: file.name }],
          })
        );
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Card className="primary mt-5 py-4">
        <Container className="{padding:0|100px!;}">
          <Card.Header className="px-0">
            <h3>REPORT A PROBLEM</h3>
          </Card.Header>
          <Card.Body className="px-0">
            <Row>
              <Col>
                <p>Provide a very detailed explanation of the issue and what you were doing when it happened.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="message">Message:</Form.Label>
                  <TextArea
                    id={'message'}
                    name="message"
                    value={message}
                    onChange={onChange}
                    showCount
                    maxLength={200}
                    className="{height:100px;} {resize:none;}>textarea"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button name="UPLOAD_IMAGE" variant="outline-primary" onClick={(): void => imageUploadRef.current.click()}>
                  <i className="sv sv-upload2 me-2 {font-size:1.5rem;}" />
                  Upload Images
                  <input name="images" type="file" multiple className="d-none" onChange={handleUploadFile} ref={imageUploadRef} />
                </Button>
              </Col>
              <Col className="flex-grow-1" />
              <Col className="d-flex justify-content-end gap-2">
                <Button onClick={(): void => window.history.back()} variant="secondary" name="CANCEL">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} variant="primary" name="SUBMIT">
                  Save
                </Button>
              </Col>
            </Row>
            {!!images.length && (
              <>
                <Row className="mt-3">
                  <Col xs="auto">
                    <Form.Label>Images:</Form.Label>
                  </Col>
                </Row>
                <Row>
                  {images.map(
                    (img: FileType.Image, s: number): JSX.Element => (
                      <Col xs={'auto'} key={s}>
                        <div
                          className={`{width:100px;height:100px;background-size:cover;background-position:center;} position-relative text-end border border-gray rounded`}
                          style={{
                            backgroundImage: `url(${img?.src})`,
                          }}
                        >
                          <Button
                            onClick={onRemoveImage(s)}
                            variant="light"
                            className="position-absolute {top:-10px;right:-10px;width:20px;height:20px;} d-flex justify-content-center align-items-center border-primary rounded-circle"
                          >
                            <i className="sv sv-cross" />
                          </Button>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </>
            )}
          </Card.Body>
        </Container>
      </Card>
    </>
  );
};

export default ReportProblemForm;

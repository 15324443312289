import { GetCompaniesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getCompanies = createGraphApiMockResponse(GetCompaniesDocument, {
  getFilters: {
    filters: {
      companies: [
        {
          id: '078d589c-af48-4a28-b079-d5bd6b4eb851',
          displayName: 'SkyHop Global',
          __typename: 'FilterOption',
        },
        {
          id: '078d589c-af48-4a28-b079-d5bd6b4eb851',
          displayName: 'Skyhop Tech',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

import '@/components/ChatWidget/ChatStatus/styles.scss';

import React, { HTMLAttributes } from 'react';

import ChatEllipses from '@/components/ChatWidget/ChatStatus/ChatEllipses';
import { getClasses } from '@/utils';
import { useChatState } from '@/hooks/useSocket/chat/useChatRooms';

// import { useDebouncedCallback } from '@/hooks/useDebounceCallback';
// import { getUserId } from '@/hooks/useSocket/utils';

type ChatStatusProps = HTMLAttributes<HTMLSpanElement> & {
  roomId: string;
};
const ChatStatus = ({ roomId, ...props }: ChatStatusProps) => {
  const usersTyping = useChatState(({ state }) => state.usersTyping.filter((user) => user.room_id === roomId));
  return (
    <span {...props} className={getClasses('ChatStatus', props?.className)}>
      {usersTyping?.length === 1 && (
        <>
          <ChatEllipses /> {usersTyping?.[0]?.username || 'User'} is typing...
        </>
      )}
      {usersTyping?.length === 2 && (
        <>
          <ChatEllipses />
          {usersTyping?.[0]?.username || 'User'} & {usersTyping?.[1]?.username || 'User'} are typing...
        </>
      )}
      {usersTyping?.length > 2 && (
        <>
          <ChatEllipses /> Multiple people are typing...
        </>
      )}
    </span>
  );
};

export default ChatStatus;

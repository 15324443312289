import { AuditSnapshotWithNameConnectionInput, RateSearch } from '../../models/gen/graphql';

import GraphApi from '../../models/GraphApi';
import { PAGE_SIZE } from '../../constants';
import gql from 'graphql-tag';

const searchSummaryReportSnapshots: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (first: number = PAGE_SIZE, after: string = null, query: RateSearch[] = null, options: any = {}): Promise<any> => {
      const input: AuditSnapshotWithNameConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: (data) => data?.searchSummaryReportSnapshots?.summaryReportSnapshotConnection || {},
  query: gql`
    query SearchSummaryReportSnapshots($input: AuditSnapshotWithNameConnectionInput) {
      searchSummaryReportSnapshots {
        summaryReportSnapshotConnection(input: $input) {
          edges {
            cursor
            node {
              companyId
              createdAt
              id
              name
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default searchSummaryReportSnapshots;
2;

import { ConnectionDetails, createNotification } from '@/utils/custom';
import { Provider, ProviderSearch, SortDirectionEnum } from '@/models/gen/graphql';
import { QueryInputType, onEnter, parsePhoneNumber, queryInput } from '@/utils';
import { SEARCH_PROVIDERS_PAGE_SIZE, useSearchAirlinesTable } from '@/api/services/providers/searchProviders';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';
import { useRef, useState } from 'react';

import ActiveSwitch from '@/components/ActiveSwitch';
import { Badge } from 'react-bootstrap';
import ConfirmationButton from '@/components/ConfirmationButton';
import EditAirlinesModal from '@/components/EditAirlinesModal';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import PageInfo from '@/components/PageInfo';
import Toast from '@/models/Toast';
import deleteAirlineBulk from '@/api/services/airlines/deleteAirlineBulk';
import { getClasses } from '@/utils/strings';
import { stringify } from '@/utils/objects';
import { updateAirlineActivationBulk } from '@/api/services/airlines/updateAirlineBulk';
import useHotkeys from '@/hooks/useHotkeys';

type AirlineTableState = {
  selected: string[];
  editing: boolean;
  creating: boolean;
  search: string;
  sorting: { column: string; direction: string };
};

const initAirlinesTableState: AirlineTableState = {
  selected: [],
  editing: false,
  creating: false,
  search: '',
  sorting: { column: undefined, direction: undefined },
};
const AirlinesTable = (): JSX.Element => {
  // Init State
  const [state, setState] = useState(initAirlinesTableState);
  const { editing, creating, selected, search, sorting } = state;

  // HotKeys
  const searchbarRef = useRef(null);
  useHotkeys('airlines', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: (): any => searchbarRef.current.focus(),
    },
    'shift+ctrl+c': {
      name: 'Toggle Create Trip',
      description: 'Opens and closes the Create Trip modal',
      action: (): any =>
        setState((current: AirlineTableState): AirlineTableState => ({ ...current, creating: !current?.creating, editing: false })),
    },
    'shift+ctrl+e': {
      name: 'Toggle Edit Trip(s)',
      description: 'Opens and closes the Edit Trip modal',
      action: (): void => {
        if (selected?.length > 0) {
          setState((current: AirlineTableState): AirlineTableState => ({ ...current, creating: false, editing: !current?.editing }));
        }
      },
    },
  });

  // Init GraphApi Queries
  const [{ data, loading }, { fetch: searchAirlines, refetch, fetchMore }] = useSearchAirlinesTable();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows, search });

  // State Handlers
  const onHide = async (): Promise<any> => {
    setState((current: AirlineTableState): AirlineTableState => ({ ...current, editing: false, creating: false, selected: [] }));
    await refetch();
  };
  const lastQuery = useRef(null);
  const handleSearch = async (): Promise<void> => {
    const query: ProviderSearch = {
      createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc, 1),
    };
    await (stringify.compare(lastQuery.current, query) ? refetch() : searchAirlines([query]));
    lastQuery.current = query;
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Provider>> => {
    if (loading || !hasNextPage) return;
    const result = await fetchMore([lastQuery.current], {
      page: Math.round(after / SEARCH_PROVIDERS_PAGE_SIZE),
      merge: true,
    });
    return result;
  };

  return (
    <>
      <PageInfo>
        {filteredRows.length} / {totalCount || 0} Airlines
      </PageInfo>
      <Filters
        name="airlinesTable"
        onSubmit={handleSearch}
        onReset={(): void => setState((current: AirlineTableState): AirlineTableState => ({ ...current, search: '' }))}
        submitOnMount
        primary={({ values, onChange }): JSX.Element => {
          const { search } = values;
          return (
            <>
              <FormField
                prepend={<i className="sv sv-magnifier fs-4" />}
                name="search"
                onChange={onChange}
                onBlur={(): void => setState((current: AirlineTableState): AirlineTableState => ({ ...current, search }))}
                onKeyDown={onEnter((): void => setState((current: AirlineTableState): AirlineTableState => ({ ...current, search })))}
                placeholder="Search"
                value={search || ''}
                style={{ width: 300 }}
                condensed
              />
            </>
          );
        }}
        alternate={(): JSX.Element => {
          return (
            <>
              {/* Create Button */}
              <FormButton
                icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
                name="CREATE_AIRLINE"
                variant="outline-gray"
                onClick={(): any =>
                  setState((current: AirlineTableState): AirlineTableState => ({ ...current, creating: true, editing: false }))
                }
              >
                <span>Add Airline</span>
              </FormButton>
              {/* Edit Bulk Button */}
              <FormButton
                icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
                name="EDIT_AIRLINE"
                variant="outline-gray"
                onClick={(): any =>
                  setState((current: AirlineTableState): AirlineTableState => ({ ...current, creating: false, editing: true }))
                }
                disabled={selected?.length === 0}
              >
                <span>Edit Airline{selected.length > 1 ? 's' : ''}</span>
                {selected?.length > 1 && (
                  <Badge className="ms-2" pill bg="success">
                    {selected?.length}
                  </Badge>
                )}
              </FormButton>
              {/* Delete Button */}
              <ConfirmationButton
                icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
                tooltip={`Delete Airline${selected.length > 1 ? 's' : ''}`}
                name="DELETE_AIRLINE"
                variant="outline-gray"
                disabled={!selected?.length}
                onConfirm={async (): Promise<void> => {
                  await deleteAirlineBulk(selected);
                  await onHide();
                }}
                options={{
                  confirmation: {
                    Body: {
                      message: `Deleting ${selected?.length} Airline(s). Are you sure?`,
                    },
                  },
                }}
              />
            </>
          );
        }}
      />
      <VirtualTable
        name="airlines"
        data={filteredRows}
        loading={loading}
        selected={selected}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        header={{
          name: 'Airline',
          displayName: 'Short Name',
          contactPerson: 'Contact Person',
          primaryEmail: 'Email',
          primaryPhoneNumber: 'Phone',
          type: 'Type',
          active: 'Active',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void =>
              setState(
                (current: AirlineTableState): AirlineTableState => ({
                  ...current,
                  selected: Array.from(new Set([data?.id])),
                  editing: true,
                })
              )
            }
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="name"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 6)"
              sorting={makeSortable('name')}
            />
            <DynamicCell
              selector="displayName"
              placeholder="--"
              className="text-center"
              width="calc(100% / 6)"
              sorting={makeSortable('displayName')}
            />
            <DynamicCell
              selector="contactPerson"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 6)"
              sorting={makeSortable('contactPerson')}
            />
            <DynamicCell
              selector="primaryEmail"
              placeholder="--"
              className="text-center"
              width="calc(100% / 6)"
              sorting={makeSortable('primaryEmail')}
            />
            <DynamicCell
              selector="primaryPhoneNumber"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 6)"
              render={({ value }: { value: string }): string =>
                _type !== 'header' && value !== '--' ? parsePhoneNumber(value, ' ') : value
              }
              sorting={makeSortable('primaryPhoneNumber')}
            />
            <DynamicCell selector="type" placeholder="--" className="text-center" width="calc(100% / 6)" sorting={makeSortable('type')} />
            <DynamicCell
              selector="active"
              placeholder="--"
              className="text-center"
              width="calc(100% / 12)"
              sorting={makeSortable('active')}
              render={({ data, value }: { data: Provider; value: string }): JSX.Element | string => (
                <ActiveSwitch
                  title="Update Airline Activation"
                  value={!!data?.active}
                  onHide={(): void => {
                    refetch();
                  }}
                  onSubmit={async (checked: boolean): Promise<void> => {
                    const input: Pick<Provider, 'id' | 'active'> = {
                      id: data?.id,
                      active: checked ? 1 : 0,
                    };
                    const res = await updateAirlineActivationBulk([input]);
                    if (res) createNotification('Success', Toast.Type.SUCCESS, 'Update Airline Activation');
                  }}
                />
              )}
            />
          </VirtualTableRow>
        )}
      />
      <EditAirlinesModal
        show={!!editing || !!creating}
        data={
          editing ? (filteredRows || []).find((node: Provider): boolean => node.id === selected[0]) : creating ? { active: 0 } : undefined
        }
        onHide={onHide}
        options={{
          selected: filteredRows?.filter((row: Provider): boolean => selected?.includes(row.id)),
        }}
        loading={false}
        name="editAirlines"
      />
    </>
  );
};

export default AirlinesTable;

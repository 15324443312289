import { useCallback, useEffect, useRef } from 'react';

const useDelayCallback = <T extends (...args: any) => void>(
  callback: T,
  delay: number
): [start: (...args: Parameters<T>) => void, stop: () => void] => {
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  const clearDelay = useCallback((): void => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }, []);

  const runDelay = useCallback(
    (...args: Parameters<T>): void => {
      if (timeoutRef.current) clearDelay();
      timeoutRef.current = setTimeout((): void => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  // clear timeout on unmount
  useEffect((): (() => void) => {
    return (): void => clearDelay();
  }, [clearDelay]);

  return [runDelay, clearDelay];
};

export default useDelayCallback;

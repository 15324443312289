import { GET_COMPANIES } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectCompany = (props) => (
  <SelectFromQuery
    cache="Company"
    placeholder="All Companies"
    query={GET_COMPANIES}
    selector={(data) => {
      return (data?.getFilters?.filters?.companies || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }));
    }}
    {...props}
  />
);

export default SelectCompany;

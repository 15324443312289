import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails } from '../../../utils/custom';
import { Import, ImportConnection, ImportConnectionInput, ImportSearch } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { SearchImportsDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchImportGraphApiResponse = GraphApiResponse<typeof SearchImportsDocument>;
export type SearchImportOptions = { pageSize?: number; page?: number; merge?: true };

export const SEARCH_IMPORTS_PAGE_SIZE = 1000;
const title = 'Search Imports';

export const searchImportResponseSelector = (res: SearchImportGraphApiResponse): ConnectionDetails<Import> =>
  convertConnectionToDetails(res?.searchImports?.importConnection as ImportConnection);

const [runSearchImports, runRefetchSearchImports] = graphApi(SearchImportsDocument, {
  onError: (res: SearchImportGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const searchImports = async (query: ImportSearch[], options: SearchImportOptions = {}): Promise<ConnectionDetails<Import>> => {
  const { pageSize = SEARCH_IMPORTS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: ImportConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions = { merge: merge ? 'searchImports.importConnection' : undefined };

  const res = await runSearchImports({ input }, graphApiOptions);

  return searchImportResponseSelector(res);
};

const refetchSearchImports = async (query: ImportSearch[], options: SearchImportOptions = {}): Promise<ConnectionDetails<Import>> => {
  const { pageSize = SEARCH_IMPORTS_PAGE_SIZE, page = 0 } = options;

  const input: ImportConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };

  const res = await runRefetchSearchImports({ input });

  return searchImportResponseSelector(res);
};

export const useSearchImports = createGraphApiHook(searchImports, {
  refetch: refetchSearchImports,
  merge: mergeConnectionDetails,
});

export default searchImports;

import { Button } from 'react-bootstrap';
import Tippy from '@tippyjs/react';

const FcrCell = ({ count, onClick }: { count: number; onClick: () => void }): React.JSX.Element => (
  <Tippy content="Complaints">
    <Button variant="transparent" className="p-0" onClick={onClick}>
      <i className={`sv sv-fcr fs-5 ${count ? 'text-purple' : 'text-primary'}`} />
    </Button>
  </Tippy>
);

export default FcrCell;

import { AirportWithVehicleConnectionInput, AirportWithVehicleMaskInput, AirportWithVehicleSearch } from '../../models/gen/graphql';

import GraphApi from '../../models/GraphApi';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

// This query is to us in tandem with searchVehicles
const searchAirportWithVehicles: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (
      first: number = PAGE_SIZE,
      after: string | null = null,
      query: AirportWithVehicleSearch[] | null = null,
      distinctMask: AirportWithVehicleMaskInput = { id: true },
      options: any = {}
    ): Promise<any> => {
      const input: AirportWithVehicleConnectionInput = {
        first,
        after,
        query: query && !(Object.values(query) || []).every((queryInput: any): boolean => !queryInput) ? query : null,
        distinctMask: distinctMask,
      };

      return fireQuery({
        variables: { input },
        ...options,
      });
    },
  selector: getConnectionDetails('searchAirportWithVehicle.airportWithVehicleConnection'),
  query: gql`
    query SearchAirportWithVehicles($input: AirportWithVehicleConnectionInput) {
      searchAirportWithVehicle {
        airportWithVehicleConnection(input: $input) {
          edges {
            cursor
            node {
              id
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};

export default searchAirportWithVehicles;

import { CreateCombineTripInput, CreateCombineTripResponse, UpdateTripValuesInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { CombineTripsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import graphApi from '@/api';

type CombineTripsGraphApiResponse = GraphApiResponse<typeof CombineTripsDocument>;

const title = 'Combine Trips';

const combineTripsResponseSelector = (res: CombineTripsGraphApiResponse): CreateCombineTripResponse =>
  !res.errors ? ((res?.combineTrips || {}) as CreateCombineTripResponse) : undefined;

const [runCombineTrips] = graphApi(CombineTripsDocument, {
  onError: (res: CombineTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const combineTrips = async (values: UpdateTripValuesInput = {}, tripIds: string[]): Promise<CreateCombineTripResponse> => {
  const input: CreateCombineTripInput = { tripIds, values };
  const res = await runCombineTrips({ input });

  const selected = combineTripsResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default combineTrips;

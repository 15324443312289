import './styles.scss';

import { Button, Form, ProgressBar } from 'react-bootstrap';
import React, { useState } from 'react';
import { Validation, bytesToSize, getClasses, uuid } from '@/utils';

import { FileUploader } from 'react-drag-drop-files';
import axios from 'axios';
import md5 from 'md5';
import useLocale from '@/hooks/useLocale';

type GenericFileUploadOptions = {
  showFiles?: boolean;
  supportedExtensions?: string[];
  label?: string;
  multiple?: boolean;
};

type GenericFileUploadProps = {
  endpoint: ((file) => Promise<string | string[]>) | string;
  options?: GenericFileUploadOptions;
  onSuccess?: () => Promise<any>;
  onError?: () => Promise<any>;
  onCancel?: () => Promise<any>;
  onContinue?: (files) => Promise<any>;
  locale?: Record<string, string>;
  condensed?: boolean;
};

enum FileUploadStage {
  'Processing' = 'PROCESSING',
  'Uploading' = 'UPLOADING',
  'Done' = 'DONE',
  'Error' = 'ERROR',
}

type GenericFileType = {
  file: File;
  md5: string;
  id: string;
  progress: number;
  stage: FileUploadStage;
};

type GenericFileProps = {
  name: any;
  size: any;
  progress?: number;
  error: GenericErrorType;
  locale?: Record<string, string>;
  stage: FileUploadStage;
};

type GenericErrorType = {
  message?: string;
};

type GenericFileUploadState = {
  files: GenericFileType[];
  errors: Record<string, GenericErrorType>;
  loading: boolean;
};

// TODO: Change the way uploading works when given condensed={true}
const GenericFileUpload = ({
  endpoint,
  locale: customLocale = {},
  options = {},
  onError,
  onSuccess,
  onContinue,
  onCancel,
  condensed = false,
}: GenericFileUploadProps) => {
  const [state, setState] = useState<GenericFileUploadState>({
    files: [],
    errors: {},
    loading: false,
  });
  const { files, errors, loading } = state;
  const { showFiles = true, label = '', multiple = true, supportedExtensions = [] } = options;
  const locale = useLocale(customLocale);
  const processFileForUpload = async (file: File): Promise<GenericFileType> => {
    try {
      return new Promise<GenericFileType>((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve({ file, md5: md5(reader.result), id: uuid(), progress: 0, stage: FileUploadStage.Processing });
      });
    } catch (error) {
      if (onError) await onError();
    }
  };

  const isFileSupported = (file) => {
    const fileExtensionRegex = /[0-9a-z]+$/i;
    return options.supportedExtensions?.length > 0 ? supportedExtensions.includes(file.name.match(fileExtensionRegex)[0]) : true;
  };

  const handleCancel = async () => {
    onCancel?.();
    setState((current) => ({ ...current, files: [] }));
  };

  const handleContinue = async (files) => {
    onContinue?.(files);
    setState((current) => ({ ...current, files: [] }));
  };

  const uploadFile = async (processedFile: GenericFileType, url: string, contentType?: string) => {
    try {
      if (!processedFile) throw new Error('Nothing to upload.');
      if (!url) throw new Error('No url provided.');
      if (!isFileSupported(processedFile.file)) throw new Error('File type not supported');
      if (Validation.isNil(processedFile.file.type)) {
        throw new Error('No contentType provided.');
      }
      const headers: any = {
        'Content-Type': contentType !== undefined ? contentType : processedFile.file.type,
      };
      await axios.put(url, processedFile.file, {
        headers,
      });
      setState((current) => ({
        ...current,
        files: current.files.map((f) => (f.id === processedFile.id ? { ...f, progress: 100, stage: FileUploadStage.Done } : f)),
      }));
    } catch (err) {
      setState((current) => ({
        ...current,
        files: current.files.map((f) => (f.id === processedFile.id ? { ...f, progress: 100, stage: FileUploadStage.Error } : f)),
        errors: { ...current.errors, [processedFile.id]: { message: err.message } },
      }));
    }
  };

  const getEndpointUrl = async (file: GenericFileType) => {
    try {
      const response = typeof endpoint === 'string' ? endpoint : await endpoint(file);
      let url = response;
      let contentType = file.file.type;
      if (Array.isArray(response)) {
        url = response[0];
        contentType = response[1] !== undefined ? response[1] : contentType;
      }
      setState((current) => ({
        ...current,
        files: current.files.map((f) => (f.id === file.id ? { ...f, stage: FileUploadStage.Uploading } : f)),
      }));
      return response;
    } catch (err) {
      setState((current) => ({
        ...current,
        files: current.files.map((f) => (f.id === file.id ? { ...f, progress: 100, stage: FileUploadStage.Error } : f)),
        errors: { ...current.errors, [file.id]: { message: err.message } },
      }));
    }
  };

  const handleUpload = async (file: File) => {
    try {
      const processedFile = await processFileForUpload(file);
      setState((current) => ({
        ...current,
        files: [...current.files, ...[processedFile]],
        errors: { ...current.errors, [processedFile.id]: undefined },
      }));
      const response = await getEndpointUrl(processedFile);
      const url = Array.isArray(response) ? response[0] : response;
      const contentType = Array.isArray(response) ? response[1] : file.type;
      await uploadFile(processedFile, url, contentType);
    } catch (err) {
      if (onError) await onError();
    }
  };

  const handleChange = async (files: File[] | File) => {
    setState((current) => ({ ...current, loading: true }));
    files = multiple ? Object.values(files) : [files];
    try {
      const promises = files.map(async (file) => handleUpload(file));
      await Promise.allSettled(promises);
      if (onSuccess) await onSuccess();
    } catch (e) {
      if (onError) await onError();
    } finally {
      setState((current) => ({ ...current, loading: false }));
    }
  };

  return (
    <div className={getClasses('FileUpload', 'flex flex-column', condensed ? 'condensed' : undefined)}>
      <Form.Group className="mb-3">
        <Form.Label id="file-upload-label">{!(files || []).length && <div>{label}</div>}</Form.Label>
        <FileUploader classes="drag-and-drop border border-primary rounded text-center" handleChange={handleChange} multiple={multiple}>
          <div className="drag-area d-flex flex-column mb-1">
            <i className="upload-icon fa fa-2x fa-file-lines text-secondary my-2" />
            <div className="m-0 d-flex gap-1 justify-content-center">
              <span>{locale('Drag and Drop or')}</span>
              <strong>{locale('Browse')}</strong>
            </div>
          </div>
          {!condensed && (
            <Button className="mt-2 px-5" name="SUBMIT_UPLOAD">
              {locale('Upload')}
            </Button>
          )}
        </FileUploader>
      </Form.Group>
      {!condensed && (
        <div className="d-flex flex-column gap-3 width-100">
          {showFiles &&
            (files || []).map(({ file: { name, size }, id, progress, stage }, index) => (
              <GenericFile
                key={index}
                name={name}
                size={size}
                progress={progress}
                error={errors[id]}
                stage={stage || FileUploadStage.Processing}
                locale={customLocale}
              />
            ))}
        </div>
      )}
      <div className="justify-content-end flex gap-2">
        {onCancel && (
          <Button className="mt-2 px-5" name="CANCEL" onClick={handleCancel} variant="secondary">
            {locale('Cancel')}
          </Button>
        )}
        {onContinue && (
          <Button className="mt-2 px-5" name="CONTINUE" disabled={files?.length === 0 || loading} onClick={() => handleContinue(files)}>
            {locale('Continue')}
          </Button>
        )}
      </div>
    </div>
  );
};

const GenericFile = ({ name, size, progress, error, stage, locale: customLocale }: GenericFileProps) => {
  const locale = useLocale(customLocale);
  const messageMap = {
    PROCESSING: `${locale('Processing')} ${bytesToSize(size)}`,
    UPLOADING: `${locale('Uploading')} ${bytesToSize(size)}`,
    DONE: `${locale('Uploaded Successfully')} ${bytesToSize(size)}`,
    ERROR: error?.message || locale('Error occurred'),
  };
  return (
    <div className={getClasses('width-100 border rounded d-flex p-4 gap-3', error ? 'border-danger' : 'border-primary')}>
      <div className="d-flex justify-content-center align-items-center">
        <i className="sv sv-file-image fs-3" />
      </div>
      <div className="d-flex flex-column w-100">
        <div className="d-flex w-100 gap-0">
          <p className="justify-content-start m-0">{name || 'Untitled'}</p>
          <p className="flex-grow-1 m-0"></p>
          <p className={getClasses('justify-content-end m-0', error ? 'text-danger fw-bold' : 'text-primary')}>{messageMap[stage]}</p>
        </div>
        <ProgressBar
          now={progress || 0}
          variant={error ? 'danger' : 'primary'}
          style={{
            height: '0.25rem',
          }}
        />
      </div>
    </div>
  );
};

export default GenericFileUpload;
export type { GenericFileType };

import { LocationTypeEnum } from '../../models/gen/graphql';
import React from 'react';
import SelectLocation from '../SelectLocation';
import { queryInput } from '../../utils';

const SelectExchangeLocation = (props) => (
  <SelectLocation
    placeholder="All Exchange Locations"
    query={{ type: queryInput(LocationTypeEnum.Exchange) }}
    options={{ search: { fetchPolicy: 'no-cache' } }}
    {...props}
  />
);
export default SelectExchangeLocation;

import { GenerateUploadProfileImageUrlInput, GenerateUploadProfileImageUrlResponse } from '@/models/gen/graphql';

import { GenerateUploadProfileImageUrlDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import graphApi from '@/api';
import { handleError } from '@/utils/custom';
import { validateObj } from '@/utils';

type GenerateUploadProfileImageUrlGraphApiResponse = GraphApiResponse<typeof GenerateUploadProfileImageUrlDocument>;

const title = 'Generate Upload Profile Image URL';

const validateGenerateUploadProfileImageUrlQuery = validateObj({
  userId: (val: any): any => val || null,
  filename: (val: any): any => val || null,
});

const generateUploadProfileImageUrlResponseSelector = (
  res: GenerateUploadProfileImageUrlGraphApiResponse
): GenerateUploadProfileImageUrlResponse => res?.generateUploadProfileImageUrl;

const [runGenerateUploadProfileImageUrl] = graphApi(GenerateUploadProfileImageUrlDocument, {
  onError: (res: GenerateUploadProfileImageUrlGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const generateUploadProfileImageUrl = async (userId: string, filename: string): Promise<GenerateUploadProfileImageUrlResponse> => {
  const input: GenerateUploadProfileImageUrlInput = validateGenerateUploadProfileImageUrlQuery({ userId, filename });

  const res = await runGenerateUploadProfileImageUrl({ input });
  const data = generateUploadProfileImageUrlResponseSelector(res);

  return data;
};

export default generateUploadProfileImageUrl;
export { validateGenerateUploadProfileImageUrlQuery };

import { GraphApi } from '../../models';
import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';
import { queryInput } from '../../utils';

const getCompletionByTripId: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (tripIds: string[], options: any = {}): Promise<any> => {
      // Handle validation.
      if (!tripIds?.length) return;
      const input = {
        first: tripIds?.length || PAGE_SIZE,
        after: null,
        query: {
          id: queryInput(tripIds),
        },
      };
      return fireQuery({
        variables: {
          input,
        },
        ...options,
      });
    },
  selector: getConnectionDetails('searchTrips.tripConnection'),
  query: gql`
    query SearchTrips($input: TripConnectionInput) {
      searchTrips {
        tripConnection(input: $input) {
          edges {
            cursor
            node {
              id
              completionId
              puCompletedDatetime
              completion {
                id
                comment
                completionTypeId
                cost
                datetime
                currency
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  `,
};
export default getCompletionByTripId;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditModal .ErrorContainer .toast:last-of-type {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/EditModal/styles.scss"],"names":[],"mappings":"AAEI;EACE,mBAAA;AADN","sourcesContent":[".EditModal .ErrorContainer .toast:last-of-type {\n  margin-bottom: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

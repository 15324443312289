import { ForgotPasswordDocument } from '@/api/queries';
import { ForgotPasswordInput } from '@/models/gen/graphql';
import { GraphApiResponse } from '@/api/core';
import Toast from '@/models/Toast';
import { createNotification } from '@/utils/custom';
import graphApi from '@/api';

type ForgotPasswordGraphApiResponse = GraphApiResponse<typeof ForgotPasswordDocument>;

const title = 'Forgot Password';

const forgotPasswordResponseSelector = (res: ForgotPasswordGraphApiResponse): boolean => res?.forgotPassword?.success || false;

const [runForgotPassword] = graphApi(ForgotPasswordDocument);

const forgotPassword = async (key: 'email' | 'username', value: string): Promise<boolean> => {
  const input: ForgotPasswordInput = {
    [key]: value,
  };

  const res = await runForgotPassword({ input });
  const success = forgotPasswordResponseSelector(res);

  if (success) createNotification('Success', Toast.Type.SUCCESS, title);

  return success;
};

export default forgotPassword;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssignDriverDropdownCell input {
  background-color: transparent !important;
  border: none;
  text-align: center;
}
.AssignDriverDropdownCell input:focus {
  box-shadow: none !important;
}
.AssignDriverDropdownCell input::placeholder {
  color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Trips/components/AssignDriverDropdownCell/styles.scss"],"names":[],"mappings":"AACE;EACE,wCAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AACI;EACE,2BAAA;AACN;AACI;EACE,sBAAA;AACN","sourcesContent":[".AssignDriverDropdownCell input {\n  background-color: transparent !important;\n  border: none;\n  text-align: center;\n}\n.AssignDriverDropdownCell input:focus {\n  box-shadow: none !important;\n}\n.AssignDriverDropdownCell input::placeholder {\n  color: #000 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

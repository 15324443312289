import { Button, Col, Row } from 'react-bootstrap';
import { CreatePotentialTripBulkResponse, PotentialTrip, TripKindEnum, TripStatusEnum } from '../../models/gen/graphql';
import { DATETIME_FORMAT, DATE_INPUT_FORMAT, TIME_FORMAT } from '../../constants';
import { Datetime, Validation, createNotification, queryInput } from '../../utils';
import React, { useEffect, useMemo } from 'react';
import createPotentialTripBulk, { createPotentialTripBulkValidator } from '../../api/services/imports/createPotentialTripBulk';

import EditModal from '../../components/EditModal/new';
import FormField from 'components/FormField';
import { OnChange } from '../../hooks/useOnChange';
import Portal from '../../components/Portal';
import { Position } from '../../models/constants';
import PreviewFile from '../PreviewFile';
import SelectAirlineIata from '../SelectAirlineIata';
import SelectAirport from 'components/SelectAirport';
import SelectClient from 'components/SelectClient';
import SelectKind from 'components/SelectKind';
import SelectLocation from '../SelectLocation';
import SelectType from 'components/SelectType';
import { Toast } from '../../models';
import searchProviders from '../../api/services/providers/searchProviders';
import { useAppState } from 'store/appReducer';
import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import useUuid from '../../hooks/useUuid';

const EditPotentialTripsModal = (): JSX.Element => {
  const [{ config: { upsProviderId = '' } = {}, settings }] = useAppState();
  const previewPosition = settings?.potentialTripsModal?.previewPosition || Position.Top;
  const [state, setState] = useModal('EditPotentialTrips', {
    onSubmit: async (data: any): Promise<void> => {
      const { persist = false } = data;
      const payload = { ...form };
      payload.scheduled = payload?.scheduled ? new Datetime(payload?.scheduled).toString() : new Datetime().toString();
      await onCreatePotentialTrip(payload);

      if (!persist) onHide();
    },
  });
  const { show = false, loading = false, trip = {}, importId = undefined, url = undefined, filename = undefined } = state;

  const { onSubmit, onHide } = setState;
  const [form, onChange, setForm]: [any, OnChange, any] = useForm((): any => {
    const now = new Datetime();
    return {
      scheduled: now.toString(),
      upsProviderId,
      ...trip,
      pilots: trip?.pilots || 0,
      attendants: trip?.attendants || 0,
      status: trip?.status || TripStatusEnum.Active,
    };
  });
  const date = new Datetime(form?.scheduled).format(DATE_INPUT_FORMAT);
  const time = new Datetime(form?.scheduled).format(TIME_FORMAT);

  useEffect((): void => {
    setForm(state?.trip);
  }, [show]);

  const uuid = useUuid();

  const onBlurFlightNumber = (): void => {
    setForm((current: any): any => {
      let updated = `${current?.flightNumber || ''}`.replace(/^[0]+/, '');
      updated = updated.padStart(4, '0');
      return { ...current, flightNumber: updated };
    });
  };
  const onCreatePotentialTrip = async (trip: any): Promise<CreatePotentialTripBulkResponse | void> => {
    const obj = { tripId: '' };
    if (!trip?.providerId) {
      createNotification('contact support: no provider id, servicerIataAirlineCode not set', Toast.Type.WARNING, 'Create Potential Trip');
    } else {
      const searchProvidersResponse = await searchProviders([{ id: queryInput(trip?.providerId) }]);
      const rows = searchProvidersResponse?.rows || [];
      if (!rows?.length) return createNotification('contact support: provider not found', Toast.Type.DANGER, 'Create Potential Trip');
      const provider = rows[0];
      obj['servicerIataAirlineCode'] = provider?.iataAirlineCode;
    }
    Object.entries({ ...trip, importId })
      .filter(([key]: [string, any]): boolean => !['id'].includes(key))
      .forEach(([key, val]: [string, any]): void => (obj[key] = val));
    const res = await createPotentialTripBulk(obj as PotentialTrip);
    return res;
  };

  const validity = useMemo(
    (): any => {
      const result = (createPotentialTripBulkValidator || ((): any => ({})))(form, upsProviderId);
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPotentialTripBulkValidator, form, upsProviderId]
  );
  const isValid = Validation.isValid(validity);
  const onChangeDateTime = (event: any): void => {
    const { name, value } = event.target;
    const scheduled = new Datetime(name === 'date' ? value : date).setTime(name === 'time' ? value : time).format(DATETIME_FORMAT);
    setForm((current: any): any => ({ ...current, scheduled }));
  };

  return (
    <EditModal
      show={show}
      title={`Potential Trip`}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onSubmit={false}
      options={{
        Footer: (
          <div className="d-flex justify-content-end gap-3 pt-1 pb-2 me-3">
            <Button variant="secondary" className="{width:10rem}" onClick={onHide}>
              CANCEL
            </Button>
            <Button variant="primary" className="{width:10rem}" onClick={onSubmit} disabled={!isValid}>
              SAVE
            </Button>
          </div>
        ),
      }}
      loading={loading}
      name="editPotentialTrips"
    >
      <Row>
        <Col md={5}>
          <Row>
            <Col>
              <FormField
                condensed
                label="Type:"
                placeholder="Type"
                name="type"
                value={form?.type || ''}
                onChange={onChange}
                valid={validity?.type?.valid}
                searchable
                options={{
                  input: {
                    as: SelectType,
                  },
                }}
              />
            </Col>
            <Col>
              <FormField
                condensed
                label="FLT #:"
                name="flightNumber"
                value={!Validation.isNil(form?.flightNumber) ? `${form?.flightNumber}`.padStart(4, '0') : ''}
                onChange={(event: any): void => {
                  const { name, value } = event.target;
                  onChange({ target: { name, value: value.toUpperCase().replace(/^[0]+/, '').padStart(4, '0').substring(0, 4) || '' } });
                }}
                onBlur={onBlurFlightNumber}
                valid={validity?.flightNumber?.valid}
              />
            </Col>
            <Col>
              <FormField
                condensed
                label="PLT:"
                type="number"
                name="pilots"
                value={form?.pilots || 0}
                onChange={onChange}
                valid={form?.pilots > 0 || form?.attendants > 0}
                min={0}
              />
            </Col>
            <Col>
              <FormField
                condensed
                label="F/A:"
                type="number"
                name="attendants"
                value={form?.attendants || 0}
                onChange={onChange}
                valid={form?.attendants > 0 || form?.pilots > 0}
                min={0}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormField label="Date:" type="date" name="date" value={date} onChange={onChangeDateTime} valid={!!date} />
            </Col>
            <Col>
              <FormField label="Time:" type="time" name="time" value={time} onChange={onChangeDateTime} valid={!!time} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormField
                label="Client:"
                placeholder="Select Client"
                name="payerProviderId"
                value={form?.payerProviderId || ''}
                onChange={onChange}
                valid={validity?.payerProviderId?.valid}
                searchable
                options={{
                  group: {
                    className: 'mt-0',
                  },
                  input: {
                    as: SelectClient,
                  },
                }}
              />
            </Col>
            <Col>
              <FormField
                label="Airline:"
                placeholder="Select Airline"
                name="servicerIataAirlineCode"
                value={form?.servicerIataAirlineCode || ''}
                onChange={onChange}
                valid={validity?.servicerIataAirlineCode?.valid}
                searchable
                options={{
                  group: {
                    className: 'mt-0',
                  },
                  input: {
                    as: SelectAirlineIata,
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormField
                condensed
                label="Airport:"
                placeholder="Select Airport"
                name="airportCode"
                value={form?.airportCode || ''}
                onChange={(event: any): void => {
                  const { name, value } = event?.target || {};
                  if (!value) return;
                  setForm((current: any): any => ({
                    ...(current || {}),
                    [name]: value,
                    puLocationId: '',
                    doLocationId: '',
                    stops: (current?.stops || []).map((stop: any): any => ({
                      ...stop,
                      airportFilter: value,
                      locationId: '',
                    })),
                  }));
                }}
                valid={validity?.airportCode?.valid}
                searchable
                options={{
                  input: {
                    as: SelectAirport,
                  },
                }}
              />
            </Col>
            <Col>
              <FormField
                condensed
                label="Crew ID:"
                name="crewId"
                type="number"
                value={form?.crewId || ''}
                onChange={onChange.int}
                valid={validity?.crewId?.valid}
                options={{
                  input: {
                    className: 'CrewId',
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormField
                label={'ARR/DEP:'}
                placeholder="Select Kind"
                name="kind"
                value={form?.kind || ''}
                onChange={onChange}
                valid={validity?.kind?.valid}
                searchable
                options={{
                  input: {
                    as: SelectKind,
                  },
                }}
              />
            </Col>
            <Col>
              <FormField label={'Loop Name:'} name="loopName" value={form?.loopName || ''} onChange={onChange} />
            </Col>
          </Row>
          <div id="potential-trips-portal-section-1"></div>
        </Col>
        <Col id="potential-trips-portal-section-2" md={7}></Col>
      </Row>
      <Row id="potential-trips-portal-section-3"></Row>

      {/* PORTALED FIELDS */}
      <Portal
        container={document.getElementById(
          previewPosition === Position.Top ? 'potential-trips-portal-section-1' : 'potential-trips-portal-section-2'
        )}
      >
        <Row>
          <Col>
            <FormField
              condensed
              label="First Name:"
              name="firstName"
              value={form?.firstName || ''}
              onChange={onChange}
              valid={validity?.firstName?.valid}
            />
          </Col>
          <Col>
            <FormField
              condensed
              label="Last Name:"
              name="lastName"
              value={form?.lastName || ''}
              onChange={onChange}
              valid={validity?.lastName?.valid}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              label={
                <>
                  Include in
                  <br />
                  Future Manifests
                </>
              }
              id={`future_${uuid}`}
              type="switch"
              name="fromManifest"
              checked={!!form?.fromManifest}
              onChange={onChange.toggleInt}
              reverse
              options={{
                input: {
                  className: 'pe-5 ps-0 text-start {transform:scale(2)|translate(-0.25rem,0.35rem)!;}>input {margin-right:3rem;}>label',
                },
              }}
            />
          </Col>
          <Col>
            <FormField label={'Split:'} type="number" name="split" value={form?.split || ''} onChange={onChange} />
          </Col>
        </Row>
        <Row>
          <FormField
            condensed
            query={{
              airportCode: queryInput(form?.kind === TripKindEnum.Arr ? form?.airportCode : undefined),
            }}
            inputOptions={{ removeAirportPrefix: true }}
            label={`Pickup`}
            placeholder="Select Location"
            name={`puLocationId`}
            value={form?.puLocationId}
            onChange={onChange}
            valid={validity?.puLocationId?.valid}
            searchable
            onCreate={(): void => {}}
            options={{ input: { as: SelectLocation }, group: { className: 'ps-1' } }}
          />
        </Row>
        <Row>
          <FormField
            query={{
              airportCode: queryInput(form?.kind === TripKindEnum.Dep ? form?.airportCode : undefined),
            }}
            inputOptions={{ removeAirportPrefix: true }}
            label={`Dropoff`}
            placeholder="Select Location"
            name={`doLocationId`}
            value={form?.doLocationId}
            onChange={onChange}
            valid={validity?.doLocationId?.valid}
            searchable
            onCreate={(): void => {}}
            options={{ input: { as: SelectLocation }, group: { className: 'ps-1' } }}
          />
        </Row>
      </Portal>

      {/* PORTALED PREVIEW */}
      <Portal
        container={document.getElementById(
          previewPosition === Position.Top ? 'potential-trips-portal-section-2' : 'potential-trips-portal-section-3'
        )}
      >
        {!!url && (
          <div className="potential-trips-preview h-100 py-3">
            <PreviewFile className="h-100 border-2 border-primary" src={url} globalSettingsPath="potentialTripsModal" />
          </div>
        )}
        {!url && (
          <div className="PreviewFile-mini border-3 border-primary w-100 h-100 rounded border d-flex flex-column justify-content-center bg-white">
            <p className="text-center lead text-muted m-0">Preview not found.</p>
          </div>
        )}
      </Portal>
    </EditModal>
  );
};

export default EditPotentialTripsModal;

import { CompletedCell } from '@/components/VirtualTable/TemplateCell/tables/punchReportTableDetails';
import React from 'react';
import { TemplatedCell } from '..';
import Tippy from '@tippyjs/react';
import { getClasses } from '@/utils/strings';

export const TimeCell = ({ value }: { value: string }): JSX.Element => {
  const classes = getClasses(value.startsWith('+') ? 'text-danger' : undefined);
  const time = classes ? value.replace('+', '') : value;
  return (
    <>
      <span className={classes}>{time}</span>
    </>
  );
};

const OverflowCell = (props: any): JSX.Element => (
  <Tippy content={<strong>{props?.value || props?.children}</strong>} delay={500}>
    <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      {...props}
    />
  </Tippy>
);

export default (key: string): TemplatedCell => {
  switch (key) {
    case 'pickup':
    case 'dropoff':
    case 'client':
    case 'driver':
      return { cell: OverflowCell, props: { className: 'text-start', minWidth: '7rem' } };
    case 'group':
    case 'puDate':
    case 'puTime':
    case 'act':
    case 'city':
    case 'airline':
    case 'flt':
    case 'van':
    case 'drive_time':
      return { props: { className: 'text-start', minWidth: '4rem', maxWidth: '6rem' } };
    case 'pu':
    case 'rfp':
      return { cell: TimeCell, props: { className: 'text-start', minWidth: '4rem', maxWidth: '6rem' } };
    case 'rate':
      return { props: { className: 'text-start', maxWidth: '4rem' } };
    case 'completion':
      return { props: { className: 'd-none' } };
    case 'completed':
      return { cell: CompletedCell, props: { className: 'text-start', minWidth: '7rem' } };
    default:
      return {};
  }
};

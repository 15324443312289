// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PunchPolicySlider .RangeSlider {
  height: 3rem;
}
.PunchPolicySlider .RangeSlider.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.PunchPolicySlider .RangeSlider .RangeSlider-Track {
  height: 0.3rem;
}
.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Tick:before {
  top: -1rem;
  background: var(--bs-gray-subtle);
  border-radius: 0.25rem;
  height: 0.9rem;
  width: 3px;
}
.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Tick.first .RangeSlider-TickLabel {
  left: -1.5rem;
}
.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Tick.last .RangeSlider-TickLabel {
  right: -4rem;
}
.PunchPolicySlider .RangeSlider .RangeSlider-Track span.RangeSlider-Handle {
  line-height: 1.5rem;
}
.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Segment {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/PunchPolicy/components/PunchPolicySlider/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,oBAAA;EACA,YAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAM;EACE,UAAA;EACA,iCAAA;EACA,sBAAA;EACA,cAAA;EACA,UAAA;AAER;AAAM;EACE,aAAA;AAER;AAAM;EACE,YAAA;AAER;AACI;EACE,mBAAA;AACN;AACI;EACE,YAAA;AACN","sourcesContent":[".PunchPolicySlider .RangeSlider {\n  height: 3rem;\n}\n.PunchPolicySlider .RangeSlider.disabled {\n  pointer-events: none;\n  opacity: 0.5;\n}\n.PunchPolicySlider .RangeSlider .RangeSlider-Track {\n  height: 0.3rem;\n}\n.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Tick:before {\n  top: -1rem;\n  background: var(--bs-gray-subtle);\n  border-radius: 0.25rem;\n  height: 0.9rem;\n  width: 3px;\n}\n.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Tick.first .RangeSlider-TickLabel {\n  left: -1.5rem;\n}\n.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Tick.last .RangeSlider-TickLabel {\n  right: -4rem;\n}\n.PunchPolicySlider .RangeSlider .RangeSlider-Track span.RangeSlider-Handle {\n  line-height: 1.5rem;\n}\n.PunchPolicySlider .RangeSlider .RangeSlider-Track .RangeSlider-Segment {\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { Image } from 'react-bootstrap';
import React from 'react';
import UsersTableComponent from '../../../pages/Users/UsersTable';
import { createWidget } from '../utils';

export const UsersTable = createWidget({
  key: 'usersTable',
  label: 'Users Table',
  description: (
    <>
      <p>Display the Users table and search for results based on the filters provided.</p>
      <Image src="/public/assets/images/users-table.png" width="100%" />
    </>
  ),
  icon: <i className="fa fa-users" />,
  component: ({ settings, ...props }) => {
    return (
      <>
        <UsersTableComponent />
      </>
    );
  },
  options: {
    settings: { minW: 6, minH: 3 },
    onAdd: (config) => ({ ...config, w: 8 }),
  },
});

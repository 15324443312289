import { getProperty, setProperty, stringify } from '@/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface ComplexFormItem<I = Record<string, unknown>> {
  index: number;
  data: I;
  [key: string]: any;
}

const useComplexForm = <I = Record<string, unknown>>(
  initData: I[],
  metadata?: (item: I, { update, addition, deletion }: { update: Partial<I>; addition: Partial<I>; deletion: I }) => Record<string, any>
): [
  {
    data: ComplexFormItem<I>[];
    items: ComplexFormItem<I>[];
    updates: ComplexFormItem<I>[];
    additions: ComplexFormItem<I>[];
    deletions: ComplexFormItem<I>[];
  },
  {
    getValue: <T = unknown>(index: number, selector: string, defaultValue?: T) => T;
    onChange: <T = unknown>(index: number, selector: string) => (value: T) => void;
    onAdd: () => void;
    onDelete: (index: number) => () => void;
    getItem: (index: number) => ComplexFormItem<I>;
    setItem: (index: number, selector: string, value: unknown) => void;
    onReset: () => void;
    setAdditions: (additions: ComplexFormItem<I>[]) => void;
    setUpdates: (updates: ComplexFormItem<I>[]) => void;
    setDeletions: (deletions: ComplexFormItem<I>[]) => void;
  },
] => {
  const [additions, setAdditions] = useState<ComplexFormItem<I>[]>([]);
  const [updates, setUpdates] = useState<ComplexFormItem<I>[]>([]);
  const [deletions, setDeletions] = useState<ComplexFormItem<I>[]>([]);
  const wrapItem = useCallback(
    (item: I, index: number): ComplexFormItem<I> => ({
      ...(metadata?.(item, {
        update: updates.find((update: ComplexFormItem<I>): boolean => update?.index === index)?.data,
        addition: additions.find((addition: ComplexFormItem<I>): boolean => addition?.index === index)?.data,
        deletion: deletions.find((deletion: ComplexFormItem<I>): boolean => deletion?.index === index)?.data,
      }) || {}),
      data: item,
      index,
    }),
    [additions, deletions, metadata, updates]
  );
  const [data, setData] = useState<ComplexFormItem<I>[]>(initData.map(wrapItem));

  const lastData = useRef<I[]>([]);
  useEffect((): void => {
    if (stringify.compare(lastData.current, initData)) return;
    lastData.current = initData;
    setData(initData.map(wrapItem));
  }, [initData, wrapItem]);

  const items = useMemo(
    (): ComplexFormItem<I>[] =>
      [
        ...data.reduce((acc: ComplexFormItem<I>[], item: ComplexFormItem<I>, index: number): ComplexFormItem<I>[] => {
          const result = [...acc];
          const update = updates.find((update: ComplexFormItem<I>): boolean => update?.index === item.index);
          if (update !== undefined && update !== null)
            result[index] = { ...item, ...update, data: { ...item.data, ...(update?.data || {}) } };
          const deletion = deletions.find((deletion: ComplexFormItem<I>): boolean => deletion?.index === item.index);
          if (deletion !== undefined && deletion !== null) result[index] = { ...item, data: null };
          return result;
        }, data),
        ...additions,
      ].map(({ index, data: item }: ComplexFormItem<I>): ComplexFormItem<I> => {
        const meta =
          metadata?.(item, {
            update: updates.find((update: ComplexFormItem<I>): boolean => update?.index === index)?.data,
            addition: additions.find((addition: ComplexFormItem<I>): boolean => addition?.index === index)?.data,
            deletion: deletions.find((deletion: ComplexFormItem<I>): boolean => deletion?.index === index)?.data,
          }) || {};
        return { ...meta, index, data: item };
      }),
    [data, additions, updates, deletions, metadata]
  );

  const getItem = useCallback(
    (index: number): ComplexFormItem<I> => items.find((item: ComplexFormItem<I>): boolean => item?.index === index),
    [items]
  );
  const setItem = useCallback(
    (index: number, selector: string, value: unknown): void => {
      const addition = additions.find((item: ComplexFormItem<I>): boolean => item?.index === index);
      if (addition) {
        setAdditions((current: ComplexFormItem<I>[]): ComplexFormItem<I>[] =>
          current.map((item: ComplexFormItem<I>): ComplexFormItem<I> => {
            if (item?.index !== index) return item;
            const update = setProperty({ ...item.data }, selector, value);
            const meta = metadata?.(update as I, { update: null, addition: update, deletion: null }) || {};
            return { ...item, ...meta, index, data: update };
          })
        );
      } else {
        setUpdates((current: ComplexFormItem<I>[]): ComplexFormItem<I>[] => {
          const original = items.find((item: ComplexFormItem<I>): boolean => item?.index === index);
          const existing = current.find((item: ComplexFormItem<I>): boolean => item?.index === index);
          if (existing)
            return current.map((item: ComplexFormItem<I>): ComplexFormItem<I> => {
              if (item?.index !== index) return item;
              const updated = { ...(original.data || {}), ...item.data, [selector]: value };
              const meta = metadata?.(updated as I, { update: updated, addition: null, deletion: null }) || {};
              return { ...item, ...meta, index, data: { ...item?.data, [selector]: value } };
            });
          const change: Partial<I> = setProperty({}, selector, value);
          const updated = { ...(original?.data || {}), ...change };
          const meta = metadata?.(updated as I, { update: change, addition: null, deletion: null }) || {};
          return [
            ...current,
            {
              ...meta,
              index,
              data: change,
            } as ComplexFormItem<I>,
          ];
        });
      }
    },
    [additions, items, metadata]
  );
  const getValue = useCallback(
    <T = unknown>(index: number, selector: string, defaultValue: unknown = ''): T =>
      getProperty(selector, getItem(index)?.data, defaultValue),
    [getItem]
  );
  const onChange =
    <T = unknown>(index: number, selector: string): ((value: unknown) => void) =>
    (value: T): void =>
      setItem(index, selector, value);
  const onDelete =
    (index: number): (() => void) =>
    (): void => {
      const addition = additions.find((item: ComplexFormItem<I>): boolean => item?.index === index);
      if (addition) {
        setAdditions((current: ComplexFormItem<I>[]): ComplexFormItem<I>[] =>
          current.filter((item: ComplexFormItem<I>): boolean => item?.index !== index)
        );
      } else {
        setDeletions((current: ComplexFormItem<I>[]): ComplexFormItem<I>[] => [...current, getItem(index)]);
      }
    };
  const onAdd = useCallback(
    (): void => setAdditions((current: ComplexFormItem<I>[]): ComplexFormItem<I>[] => [...current, wrapItem({} as I, items.length)]),
    [wrapItem, items.length]
  );
  const onReset = useCallback((): void => {
    setAdditions([]);
    setDeletions([]);
    setUpdates([]);
  }, []);

  return [
    { data, items, updates, additions, deletions },
    { getValue, onChange, onDelete, onAdd, getItem, setItem, onReset, setAdditions, setUpdates, setDeletions },
  ];
};

export default useComplexForm;

import { Middle } from '@/components/Align';
import { HTMLAttributes } from 'react';
import { Container, Row } from 'react-bootstrap';

type MessageCenterNoContentProps = HTMLAttributes<HTMLDivElement>;
const MessageCenterNoContent = ({ ...props }: MessageCenterNoContentProps) => {
  return (
    <Container {...props} fluid className="d-flex flex-column bg-white {max-height:100%;}">
      <Row className="{align-self:stretch;overflow:auto;min-height:min-content;} flex-grow-1">
        <Middle.Center>
          <h3 className="text-gray text-center">No Messages</h3>
        </Middle.Center>
      </Row>
    </Container>
  );
};

export default MessageCenterNoContent;

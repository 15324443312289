// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RouteContent.MessageCenter {
  padding: 0;
  overflow: hidden;
}

.MessageCenter-Button {
  width: 100%;
  border-radius: 0;
  border-width: 0;
  text-transform: uppercase;
}
.MessageCenter-Button.btn-secondary {
  box-shadow: 4px 3px 4px 0px rgba(0, 0, 0, 0.25) inset, -4px -3px 4px 0px rgba(65, 65, 65, 0.12) inset;
}

.MessageCenter .MessageCenter-Container {
  max-height: 100%;
  height: 100%;
}

.MessageCenterItem {
  border-bottom: 1px solid var(--bs-secondary-border-subtle);
}
.MessageCenterItem.selected::after {
  content: "";
  display: block;
  position: absolute;
  border-width: 4px;
  pointer-events: none;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid var(--bs-secondary);
}`, "",{"version":3,"sources":["webpack://./src/features/MessageCenter/styles.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EAMA,yBAAA;AAJF;AADE;EACE,qGACE;AAEN;;AAIA;EACE,gBAAA;EACA,YAAA;AADF;;AAIA;EACE,0DAAA;AADF;AAEE;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,qCAAA;AAAJ","sourcesContent":[".RouteContent.MessageCenter {\n  padding: 0;\n  overflow: hidden;\n}\n\n.MessageCenter-Button {\n  width: 100%;\n  border-radius: 0;\n  border-width: 0;\n  text-transform: uppercase;\n}\n.MessageCenter-Button.btn-secondary {\n  box-shadow: 4px 3px 4px 0px rgba(0, 0, 0, 0.25) inset, -4px -3px 4px 0px rgba(65, 65, 65, 0.12) inset;\n}\n\n.MessageCenter .MessageCenter-Container {\n  max-height: 100%;\n  height: 100%;\n}\n\n.MessageCenterItem {\n  border-bottom: 1px solid var(--bs-secondary-border-subtle);\n}\n.MessageCenterItem.selected::after {\n  content: \"\";\n  display: block;\n  position: absolute;\n  border-width: 4px;\n  pointer-events: none;\n  z-index: 1;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  border: 3px solid var(--bs-secondary);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

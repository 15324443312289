import { DeleteProviderBulkInput, DeleteProviderBulkResponse, DeleteProviderInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { DeleteClientBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import graphApi from '@/api';

type DeleteClientBulkGraphApiResponse = GraphApiResponse<typeof DeleteClientBulkDocument>;

const title = 'Delete Clients';

const deleteClientBulkResponseSelector = (res: DeleteClientBulkGraphApiResponse): DeleteProviderBulkResponse =>
  !res.errors ? res?.deleteClientBulk : undefined;

const [runDeleteClientBulk] = graphApi(DeleteClientBulkDocument, {
  onError: (res: DeleteClientBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteClientBulk = async (ids: string[]): Promise<DeleteProviderBulkResponse> => {
  const input: DeleteProviderBulkInput = { search: ids.map((id: string): DeleteProviderInput => ({ id })) };
  const res = await runDeleteClientBulk({ input });

  const selected = deleteClientBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default deleteClientBulk;

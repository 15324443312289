import { DeleteStopBulkInput, DeleteStopInput } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { DeleteStopBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type DeleteStopBulkGraphApiResponse = GraphApiResponse<typeof DeleteStopBulkDocument>;

const title = 'Delete Stops';

const deleteStopBulkResponseSelector = (res: DeleteStopBulkGraphApiResponse): number =>
  !res.errors ? res?.deleteStopBulk?.deleted || 0 : undefined;

const [runDeleteStopBulk] = graphApi(DeleteStopBulkDocument, {
  onError: (res: DeleteStopBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteStopBulk = async (ids: string[]): Promise<number> => {
  const input: DeleteStopBulkInput = { search: ids.map((id: string): DeleteStopInput => ({ id })) };

  const res = await runDeleteStopBulk({ input });
  const selected = deleteStopBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteStopBulk = createGraphApiHook(deleteStopBulk);

export default deleteStopBulk;

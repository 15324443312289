import React, { ReactNode, useEffect, useState } from 'react';

import { INDEXED_DB_VERSION } from '../../constants';
import useIndexedDb from '@/hooks/useIndexedDb';

export type WidgetOptions = {};
export type WidgetConfigComponent = ({ options, setOptions }) => JSX.Element;
export type WidgetSettings = {
  key: string;
  label: string;
  icon: JSX.Element;
  component: (props: any) => JSX.Element;
  description?: ReactNode;
  options?: WidgetOptions;
  config?: WidgetConfigComponent;
};
export const createWidget = (settings: WidgetSettings): any => ({
  key: settings.key,
  label: settings.label,
  icon: settings.icon,
  component: (props: any): JSX.Element => {
    const Component = settings.component;
    return <Component {...props} />;
  },
  description: settings?.description,
  options: {
    ...(settings?.options || {}),
  },
  config: settings?.config,
});
export const useLocalDashboard = (id: string): any => {
  const [dashboard, setDashboard] = useState(null);
  const upgrade = (db) => {
    const tables = ['dashboards', 'widgets'];
    tables.forEach((table) => {
      if (!db.objectStoreNames.contains(table)) db.createObjectStore(table);
    });
  };
  const [fetchDashboard, putDashboard, deleteDashboard] = useIndexedDb('local', 'dashboards', { upgrade }, INDEXED_DB_VERSION);
  const [fetchWidget, putWidget, deleteWidget] = useIndexedDb('local', 'widgets', { upgrade }, INDEXED_DB_VERSION);

  const getDashboard = async (dashboardId: string): Promise<void> => {
    const dashboard = await fetchDashboard(dashboardId, testDashboard);
    const result = { ...dashboard, widgets: [] };
    const { widgets = [] } = dashboard;
    const widgetPromises = widgets.map(async (widgetId) => {
      if (!widgetId) return;
      const widget = await fetchWidget(widgetId, testWidget);
      return widget;
    });
    const widgetsResult = await Promise.all(widgetPromises);
    result.widgets = widgetsResult;
    setDashboard(result);
  };
  const saveDashboard = async (): Promise<void> => {
    const { widgets = [], ...r } = dashboard;
    await Promise.all([Promise.all(widgets.map(putWidget)), putDashboard({ ...r, widgets: widgets.map((w) => w.i) })]);
  };

  useEffect((): void => {
    if (!id) return;
    getDashboard(id);
  }, [id]);

  return [dashboard, setDashboard, { getDashboard, saveDashboard, deleteDashboard, deleteWidget }];
};

const testDashboard = {
  id: '1234',
  name: 'Test Dashboard',
  owner: 'cory',
  sharing: [],
  widgets: ['1'],
};
const testWidget = {
  i: '1',
  w: 3,
  h: 1,
  x: 0,
  y: 0,
  minW: 3,
  minH: 1,
  maxW: 3,
  maxH: 1,
  moved: false,
  static: false,
  widget: {
    type: 'title',
    settings: [{ id: '1', key: 'title', value: 'Test Widget' }],
  },
};

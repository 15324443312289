import { LatestRateCurrencyEnum, LatestRateTypeEnum, RateCurrencyEnum, RateTypeEnum, TripTableConnectionInput } from '@/models/gen/graphql';
import { SearchRatesDocument, SearchRatesTableDocument } from '@/api/queries';

import { VariablesOf } from '@graphql-typed-document-node/core';
import { createGraphApiMockResponse } from '@/api/core';

export const searchRates = createGraphApiMockResponse(
  SearchRatesDocument,
  {
    searchRates: {
      rateConnection: {
        edges: [
          {
            cursor: '1',
            node: {
              airportCode: null,
              companyId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractStartDatetime: null,
              contractEndDatetime: null,
              createdAt: null,
              createdBy: null,
              currency: RateCurrencyEnum.Usd,
              duration: 60,
              endDatetime: '2030-12-31T23:59:59',
              id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              name: 'Delta [ FFL -> Mar ]',
              notes: '',
              payerProviderId: null,
              payerProvider: null,
              radius: 5,
              rate: 55,
              startDatetime: '2020-01-01T00:00:00',
              thatLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thisLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thatLocation: {
                name: 'Fort Lauderdale-Hollywood International Airport',
                __typename: 'Location',
              },
              thisLocation: {
                name: 'Lago Mar Beach Resort & Club',
                __typename: 'Location',
              },
              tripDistance: 12,
              type: RateTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'Rate',
            },
            __typename: 'RateEdge',
          },
          {
            cursor: '2',
            node: {
              airportCode: null,
              companyId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractStartDatetime: null,
              contractEndDatetime: null,
              createdAt: null,
              createdBy: null,
              currency: RateCurrencyEnum.Usd,
              duration: 60,
              endDatetime: '2030-12-31T23:59:59',
              id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              name: 'Any Provider [ FFL -> Mar ]',
              notes: '',
              payerProviderId: null,
              payerProvider: null,
              radius: 5,
              rate: 55,
              startDatetime: '2020-01-01T00:00:00',
              thatLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thisLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thatLocation: {
                name: 'Fort Lauderdale-Hollywood International Airport',
                __typename: 'Location',
              },
              thisLocation: {
                name: 'Lago Mar Beach Resort & Club',
                __typename: 'Location',
              },
              tripDistance: 12,
              type: RateTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'Rate',
            },
            __typename: 'RateEdge',
          },
          {
            cursor: '3',
            node: {
              airportCode: null,
              companyId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractStartDatetime: null,
              contractEndDatetime: null,
              createdAt: null,
              createdBy: null,
              currency: RateCurrencyEnum.Usd,
              duration: 60,
              endDatetime: '2030-12-31T23:59:59',
              id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              name: 'Delta [ FFL -> Mar ] Eco',
              notes: '',
              payerProviderId: null,
              payerProvider: null,
              radius: 5,
              rate: 50,
              startDatetime: '2020-01-01T00:00:00',
              thatLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thisLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thatLocation: {
                name: 'Fort Lauderdale-Hollywood International Airport',
                __typename: 'Location',
              },
              thisLocation: {
                name: 'Lago Mar Beach Resort & Club',
                __typename: 'Location',
              },
              tripDistance: 12,
              type: RateTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'Rate',
            },
            __typename: 'RateEdge',
          },
          {
            cursor: '4',
            node: {
              airportCode: null,
              companyId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractStartDatetime: null,
              contractEndDatetime: null,
              createdAt: null,
              createdBy: null,
              currency: RateCurrencyEnum.Usd,
              duration: 60,
              endDatetime: '2030-12-31T23:59:59',
              id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              name: 'Delta [ FFL -> Mar ] Trike',
              notes: '',
              payerProviderId: null,
              payerProvider: null,
              radius: 5,
              rate: 20,
              startDatetime: '2020-01-01T00:00:00',
              thatLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thisLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thatLocation: {
                name: 'Fort Lauderdale-Hollywood International Airport',
                __typename: 'Location',
              },
              thisLocation: {
                name: 'Lago Mar Beach Resort & Club',
                __typename: 'Location',
              },
              tripDistance: 12,
              type: RateTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'Rate',
            },
            __typename: 'RateEdge',
          },
          {
            cursor: '5',
            node: {
              airportCode: null,
              companyId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              contractStartDatetime: null,
              contractEndDatetime: null,
              createdAt: null,
              createdBy: null,
              currency: RateCurrencyEnum.Usd,
              duration: 60,
              endDatetime: '2030-12-31T23:59:59',
              id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              name: 'Delta [ FFL -> Mar ] Trike',
              notes: '',
              payerProviderId: null,
              payerProvider: null,
              radius: 5,
              rate: 60,
              startDatetime: '2020-01-01T00:00:00',
              thatLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thisLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thatLocation: {
                name: 'Fort Lauderdale-Hollywood International Airport',
                __typename: 'Location',
              },
              thisLocation: {
                name: 'Lago Mar Beach Resort & Club',
                __typename: 'Location',
              },
              tripDistance: 12,
              type: RateTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'Rate',
            },
            __typename: 'RateEdge',
          },
        ],
        pageInfo: {
          endCursor: '5',
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: '0',
          __typename: 'PageInfo',
        },
        totalCount: 5,
        __typename: 'RateConnection',
      },
      __typename: 'SearchRateResponse',
    },
  },
  {
    variables: (opts: VariablesOf<TripTableConnectionInput>): boolean => !!opts,
  }
);

export const searchRatesTable = createGraphApiMockResponse(
  SearchRatesTableDocument,
  {
    searchRates: {
      rateTableConnection: {
        edges: [
          {
            cursor: '1',
            node: {
              airportCode: null,
              companyId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              createdAt: null,
              createdBy: null,
              currency: LatestRateCurrencyEnum.Usd,
              duration: 60,
              endDatetime: '2030-12-31T23:59:59',
              id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              payerProviderId: null,
              payerProvider: null,
              rate: 55,
              startDatetime: '2020-01-01T00:00:00',
              thatLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thisLocationId: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
              thatLocation: {
                name: 'Fort Lauderdale-Hollywood International Airport',
                id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
                __typename: 'Location',
              },
              thisLocation: {
                name: 'Lago Mar Beach Resort & Club',
                id: '9fe10734-995f-4ca6-9c5b-24ab7c556f2d',
                __typename: 'Location',
              },
              tripDistance: 12,
              type: LatestRateTypeEnum.Contract,
              updatedAt: null,
              updatedBy: null,
              __typename: 'LatestRate',
            },
            __typename: 'LatestRateEdge',
          },
        ],
        pageInfo: {
          endCursor: '1',
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: '0',
          __typename: 'PageInfo',
        },
        totalCount: 1,
        __typename: 'LatestRateConnection',
      },
      __typename: 'SearchRateResponse',
    },
  },
  {
    variables: (opts: VariablesOf<TripTableConnectionInput>): boolean => !!opts,
  }
);

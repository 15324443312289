import { CreatePunchPolicyInput, PunchPolicy } from '@/models/gen/graphql';
import { HandleErrorInput, handleError } from '@/utils/custom';

import { CreatePunchPolicyBulkDocument } from '@/api/queries';
import { GraphApiMethod } from '@/api/core';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Create Punch Policy' } });

// Service Function Signature
type ServiceMethodPayload = { [key in keyof CreatePunchPolicyInput]: any }[];
interface ServiceMethod {
  (...payload: ServiceMethodPayload): Promise<PunchPolicy[]>;
}

// CreatePunchPolicyBulk Service
// carmstrong @ 5/2/2024
export const { createPunchPolicyBulk } = ((): {
  createPunchPolicyBulk: ServiceMethod;
} => {
  const [mutate] = graphApi(CreatePunchPolicyBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof CreatePunchPolicyBulkDocument>): ServiceMethod =>
      async (...payload: ServiceMethodPayload): Promise<PunchPolicy[]> => {
        const values = payload.map(({ id, ...policy }: CreatePunchPolicyInput & { id?: string }): CreatePunchPolicyInput => policy);
        const res = await fn({ input: { values } });
        return res?.createPunchPolicyBulk?.output?.map(({ node }: { node: PunchPolicy }): PunchPolicy => node) || [];
      }
  );

  return {
    createPunchPolicyBulk: mutate,
  };
})();

import { Col, Row } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import getNormalizedAddress, { NormalizedAddress } from '@/api/services/locations/getNormalizedAddress';

import DynamicContent from '@/components/DynamicContent';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { Location } from '@/models/gen/graphql';
import LocationCard from '@/components/LocationCard';

type NormalizeAddressModalProps = {
  show: boolean;
  onHide: () => void;
  loading: boolean;
  location: Location;
  onSubmit: (data: Location) => void;
};

type NormalizedAddressState = {
  normalizedAddress: NormalizedAddress;
  loading: boolean;
  called: boolean;
  error: string;
};
const initNormalizedAddressState: NormalizedAddressState = {
  normalizedAddress: undefined,
  loading: false,
  called: false,
  error: undefined,
};
const NormalizeAddressModal = ({
  show,
  onHide,
  onSubmit,
  loading: forceLoading,
  location: initLocation,
}: NormalizeAddressModalProps): JSX.Element => {
  const [state, setState] = useState(initNormalizedAddressState);
  const { normalizedAddress, loading, error } = state;

  const handleNormalizeAddress = useCallback(
    async (location: Location): Promise<void> => {
      if (!location) return;
      try {
        setState((current: NormalizedAddressState): NormalizedAddressState => ({ ...current, loading: true, error: undefined }));
        const normalizedAddress = await getNormalizedAddress(location);
        if (!normalizedAddress) return onHide();
        setState((current: NormalizedAddressState): NormalizedAddressState => ({ ...current, normalizedAddress }));
      } catch (err) {
        console.error(err.message || err);
        setState((current: NormalizedAddressState): NormalizedAddressState => ({ ...current, error: err.message || err }));
      } finally {
        setState((current: NormalizedAddressState): NormalizedAddressState => ({ ...current, loading: false }));
      }
    },
    [onHide]
  );

  useEffect((): void => {
    if (!show || !initLocation) return;
    handleNormalizeAddress(initLocation);
  }, [show, initLocation, handleNormalizeAddress]);

  return (
    <DynamicContent
      className={`NormalizeAddressModal${forceLoading ? ' loading' : ''}`}
      show={show}
      onHide={onHide}
      modal
      options={{ modal: { props: { centered: true } } }}
    >
      <DynamicContent.Title>Normalize Address</DynamicContent.Title>
      <LoadingBlur loading={loading || forceLoading} />
      {!loading && !!error && (
        <Row>
          <Col className="d-flex flex-column gap-3 text-center">
            <span className="text-danger">{error}</span>
            <span className="text-muted">Please double check the address supplied, and try again.</span>
          </Col>
        </Row>
      )}
      {loading && !error && (
        <Row>
          <Col className="d-flex flex-column gap-3 text-center mt-5 mb-3">
            <span>Getting normalized address...</span>
          </Col>
        </Row>
      )}
      {!loading && !error && (
        <>
          <Row>
            <Col className="d-flex flex-column gap-1 text-muted text-center">
              <span>It is important to follow a standard of normalization.</span>
              <span>Please ensure which address below to keep.</span>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <LocationCard
                title="Normalized Address"
                location={normalizedAddress ? { ...initLocation, ...normalizedAddress } : initLocation}
                onClick={onSubmit}
                options={{
                  button: {
                    text: 'Use Normalized Address',
                    variant: 'primary',
                  },
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </DynamicContent>
  );
};

export default NormalizeAddressModal;

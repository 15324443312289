import { Datetime, getClasses } from '@/utils';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableProps, VirtualTableRow } from '@/components/VirtualTable';

import { Import } from '@/models/gen/graphql';
import PromiseButton from '@/components/PromiseButton';
import React from 'react';

type Props = {
  selected?: string[];
  onSelect?: (id: string | string[]) => void;
  loading?: boolean;
  onSort?: (column: string) => void;
  onDoubleClick?: (data: Import) => void;
  onDownload?: (id: string, name?: string) => Promise<void>;
  downloading?: boolean;
} & VirtualTableProps<Import>;

const header = {
  createdAt: 'Date',
  name: 'Name',
  statusDisplayName: 'Status',
  provider: { displayName: 'Provider' },
  importedBy: 'Imported By',
  email: { from: 'From', subject: 'Subject' },
  type: 'Type',
  download: <i className="sv sv-download2" />,
};

const PendingImportsTable = ({
  data,
  selected,
  onSelect,
  onDoubleClick,
  onDownload,
  onSort,
  downloading,
  ...props
}: Props): JSX.Element => (
  <VirtualTable
    {...props}
    name="pendingImportsTable"
    data={data}
    selected={selected}
    header={header}
    rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
      <VirtualTableRow
        context={{
          ...context,
          rowType: _type,
          data,
          index,
          selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
        }}
        className={getClasses(selected.includes(index) ? 'selected' : '')}
        onDoubleClick={(): void => onDoubleClick(data)}
      >
        <SelectCell onClick={onSelect} />
        <DynamicCell
          selector="createdAt"
          placeholder="--"
          className="text-center alternate"
          width="calc((100% / 7) * 0.5)"
          sorting={onSort('createdAt')}
          render={({ value }: { value: string }): string => new Datetime().setAsUTC(value).toLocaleDatetime().frontendDatetimeShort}
        />
        <DynamicCell
          selector="statusDisplayName"
          placeholder="--"
          className={getClasses('text-center', data?.status)}
          width="calc(100% / 7)"
          sorting={onSort('statusDisplayName')}
        />
        <DynamicCell
          selector="provider.iataAirlineCode|provider.displayName"
          placeholder="--"
          className="text-center alternate"
          width="calc((100% / 7) * 0.75)"
          sorting={onSort('provider.iataAirlineCode|provider.displayName')}
        />
        <DynamicCell
          selector="importedBy"
          placeholder="--"
          className="text-center "
          width="calc(100% / 7)"
          sorting={onSort('importedBy')}
        />
        <DynamicCell
          selector="email.from"
          placeholder="--"
          className="text-center alternate"
          width="calc((100% / 7) * 1.25)"
          sorting={onSort('email.from')}
        />
        <DynamicCell
          selector="email.subject"
          placeholder="--"
          className="text-center "
          width="calc((100% / 7) * 1.75)"
          sorting={onSort('email.subject')}
        />
        <DynamicCell
          selector="type"
          placeholder="--"
          className="text-center alternate"
          width="calc((100% / 7) * 0.75)"
          sorting={onSort('type')}
        />
        <DynamicCell
          selector="download"
          placeholder="--"
          className="text-center fs-2"
          width="calc(100% / 11)"
          render={({ data }) => (
            <PromiseButton
              variant={'transparent'}
              onClick={() => onDownload(data.id)}
              icon={<i className="sv sv-download2 fs-2" />}
              feedback="Download Manifest"
            />
          )}
        />
      </VirtualTableRow>
    )}
  />
);

export default React.memo(PendingImportsTable);

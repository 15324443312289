import { CreateTripBulkInput, CreateTripInput, Trip, TripKindEnum, TripStatusEnum, TripTypeEnum } from 'models/gen/graphql';

import { GraphApi } from '../../models';
import { Validation } from '../../utils';
import gql from 'graphql-tag';

const createTripValidator = new Validation.Validator({
  airportCode: (val: any): Validation.Validity => ({ valid: !!val }),
  attendants: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  comments: (val: any): Validation.Validity => ({ valid: !!val }),
  'companyId!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val), defaultValue: '' }),
  completionId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  crewId: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isNumber(parseInt(val)) ? true : val?.length ? false : undefined,
    defaultValue: 0,
  }),
  firstName: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
  lastName: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
  doActualDatetime: (val: any): Validation.Validity => ({ valid: !!val }),
  doLocationId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  driverId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  flightNumber: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val) && val >= 0 && val <= 9999 }),
  fromManifest: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: false }),
  'kind!': (val: any): Validation.Validity => ({ valid: Object.values(TripKindEnum).includes(val), defaultValue: TripKindEnum.Arr }),
  loopName: (val: any): Validation.Validity => ({ valid: !!val, defaultValue: '' }),
  manifestImportId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  payerProviderId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  providerId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  pilots: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  puActualDatetime: (val: any): Validation.Validity => ({ valid: !!val }),
  puCompletedDatetime: (val: any): Validation.Validity => ({ valid: !!val }),
  puLocationId: (val: any): Validation.Validity => ({ valid: !!val }),
  scheduled: (val: any): Validation.Validity => ({ valid: !!val, defaultValue: '' }),
  servicerIataAirlineCode: (val: any): Validation.Validity => ({ valid: !!val }),
  'split!': (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  'status!': (val: any): Validation.Validity => ({
    valid: Object.values(TripStatusEnum).includes(val),
    defaultValue: TripStatusEnum.Cancelled,
  }),
  traceId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  trackFlightId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  'type!': (val: any): Validation.Validity => ({ valid: Object.values(TripTypeEnum).includes(val), defaultValue: TripTypeEnum.Sch }),
  vehicleId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
});

const createTrips: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (trips: Trip[]): Promise<any> => {
      const values: CreateTripInput[] = trips.map((trip: Trip): CreateTripInput => createTripValidator.create(trip));
      const input: CreateTripBulkInput = { values };
      return fireQuery({ variables: { input } });
    },
  selector: (data: any): any => data?.createTrips?.response || [],
  query: gql`
    mutation CreateTrips($input: CreateTripsInput!) {
      createTrips(input: $input) {
        response {
          error
          node {
            id
            # manifestImportId
            # providerId
            # payerProviderId
            # companyId
            # traceId
            # kind
            # type
            # status
            # loopName
            # driverId
            # vehicleId
            # trackFlightId
            # split
            # pilots
            # attendants
            # airportCode
            # scheduled
            # servicerIataAirlineCode
            # flightNumber
            # puLocationId
            # puActualDatetime
            # puCompletedDatetime
            # doLocationId
            # doActualDatetime
            # fromManifest
            # completionId
            # crewId
            # rateId
            # combineId
            # createdAt
            # createdBy
            # updatedAt
            # updatedBy
            # deletedAt
            # deletedBy
            # Augemented data
            # isLate
            # scheduledUtc
          }
        }
      }
    }
  `,
};

export default createTrips;
export { createTripValidator };

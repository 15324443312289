import { EditableCell } from '@/components/VirtualTable/components/EditableCell';
import React from 'react';
import SuggestedRate from '@/pages/InvoicePreview/components/SuggestedRate';
import { formatDollars } from '@/utils';
import getCorrectRatesForTrips from '@/api/services/rates/getCorrectRatesForTrips';
import runRateAmountOnTrips from '@/api/services/trips/runRateAmountOnTrips';

export type EditableRateCellProps = {
  tripId: string;
  rateAmount: number;
  loading?: boolean;
  onChange: (data?: string | number) => Promise<void>;
  [key: string]: any;
};

const EditableRateCell = ({ tripId, rateAmount, onChange, loading = false, ...props }: EditableRateCellProps) => {
  const formatCell = (val: string) => {
    if (Number(val)) {
      return formatDollars(val);
    }
    return val;
  };

  const onSuggestRate = async (id: string): Promise<number> => {
    try {
      const suggestedRate = await getCorrectRatesForTrips([id]);
      return suggestedRate?.[0]?.newRate?.rate;
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeRate = async (rate?: number | string) => {
    try {
      await runRateAmountOnTrips([
        {
          tripId,
          amount: parseFloat(`${rate ?? rateAmount}`),
        },
      ]);
      await onChange?.();
    } catch (err) {
      console.error(err);
    }
  };

  const changeRate = async (val: number | string) => {
    try {
      onChangeRate(val);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <EditableCell
      {...props}
      loading={loading}
      display={({ children, onCancel, onValueChange }) => (
        <div className="d-flex flex-column gap-2">
          <SuggestedRate
            onCancel={onCancel}
            suggestion={{
              getSuggestion: async (): Promise<number> => {
                return onSuggestRate(tripId);
              },
              applySuggestion: async (suggestion: number): Promise<void> => {
                await onValueChange?.(suggestion);
                await changeRate(suggestion);
              },
              messages: {
                notFound: 'No suggested rate found',
              },
            }}
          />
          {children}
        </div>
      )}
      onEdit={async (val: string) => changeRate(val)}
      format={formatCell}
      options={{
        input: {
          type: 'number',
          autoFocus: true,
        },
      }}
    />
  );
};

export default EditableRateCell;

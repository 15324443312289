import './styles.scss';

import { Container } from 'react-bootstrap';
import ProtectedRoute from '../../components/ProtectedRoute';
import React from 'react';
import UsersTable from './UsersTable';

const Users = () => (
  <ProtectedRoute title="Users">
    <Container fluid className="Users page-container">
      <UsersTable />
    </Container>
  </ProtectedRoute>
);

export default Users;

import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { DATE_FE_FORMAT_FULL, TODAY, TODAY_EOD } from '../../constants';
import { Datetime, generateQueryString, getBulkValues, queryInput } from '../../utils';
import { Rate, RateCurrencyEnum, RateTypeEnum } from '../../models/gen/graphql';
import React, { useEffect, useMemo } from 'react';
import { Validation, fieldValidator } from '../../utils/validations';
import createRateBulk, { RATES_VALIDATION_CRITERIA } from '../../api/services/rates/createRateBulk';
import { getDiff, stringify } from '../../utils/objects';

import EditModal from '../../components/EditModal/new';
import FormField from '../../components/FormField';
import { RateModalType } from '../../pages/Rates/useRatesModals';
import SelectAirport from '../SelectAirport';
import SelectClient from '../../components/SelectClient';
import SelectCurrency from '../../components/SelectCurrency';
import SelectLocation from '../../components/SelectLocation';
import SelectRateType from '../SelectRateType';
import Tippy from '@tippyjs/react';
import TippyWhen from '../TippyWhen';
import { searchTripsForRates } from '../../api/services/trips/searchTrips';
import updateRateBulk from '../../api/services/rates/updateRateBulk';
import useEvent from '../../hooks/useEvent';
import useModal from '../../hooks/useModal';
import { useNavigate } from 'react-router-dom';

type BulkFields = {
  name: string;
  type: RateTypeEnum;
  rate: number;
  currency: RateCurrencyEnum;
  startDatetime: string;
  endDatetime: string;
};
type EditRatesModalForm = {
  airportCode: string;
  payerProviderId: string;
  thisLocationId: string;
  thatLocationId: string;
  radius: number;
  tripDistance: number;
  duration: number;
  bulkFields: BulkFields[];
};
export const initEditRatesModalForm: EditRatesModalForm = {
  airportCode: null,
  payerProviderId: null,
  thisLocationId: null,
  thatLocationId: null,
  radius: null,
  tripDistance: null,
  duration: null,
  bulkFields: [
    {
      name: null,
      type: null,
      rate: null,
      currency: RateCurrencyEnum.Usd,
      startDatetime: new Datetime(TODAY).frontendDate,
      endDatetime: new Datetime(TODAY).frontendDate,
    },
  ],
};
const EditRatesModal = () => {
  const openRatesModal = useEvent('useRatesModals:triggerModal');
  const navigate = useNavigate();

  const [state, setState] = useModal('EditRates', {
    onSubmit: async (): Promise<void> => {
      let res;
      // if rate has no id, we are creating, otherwise we are editing
      if (!data?.id) {
        const { bulkFields, ...sharedFields } = form;
        res = await createRateBulk((bulkFields || []).map((setOfBulkFields: BulkFields) => ({ ...sharedFields, ...setOfBulkFields })));
      } else {
        // make sure the 'before' and 'after' objects are in the same format and have the same keys so we can properly compare them
        const initFormCopy = { ...initEditRatesModalForm, bulkFields: [{ ...initEditRatesModalForm.bulkFields[0] }] };
        const initialValues: EditRatesModalForm = Object.keys(data).reduce((acc, fieldName) => {
          if (initEditRatesModalForm.bulkFields[0].hasOwnProperty(fieldName)) acc.bulkFields[0][fieldName] = data[fieldName];
          acc[fieldName] = data[fieldName];
          return acc;
        }, initFormCopy);
        const { bulkFields: bulkValues, ...sharedValues } = initialValues;
        const { bulkFields, ...sharedFields } = form;
        const before = { ...sharedValues, ...bulkValues[0] };
        const after = { ...sharedFields, ...bulkFields[0] };

        // Close modal if rate has not changed
        if (stringify.compare(before, after)) return onHide();

        const [{ ...partial }] = getDiff(before, after);
        const rateUpdate: Partial<Rate>[] = selectedRows.map((rate: Rate): any => ({ ...partial, id: rate?.id }));
        // get aggregate associated trips to determine our next action
        const tripData = await searchTripsForRates([{ rateId: queryInput(selectedRows.map((row) => row.id)) }], { pageSize: 100 });
        const { totalCount } = tripData;
        const associatedTrips = tripData?.rows || [];
        // Only run update if there are no associated trips, or if we're bulk editing and there are less than 100 associated trips
        if (!totalCount || (selectedRows.length > 1 && totalCount < 100)) {
          res = await updateRateBulk(rateUpdate);
          // Only Open ConfirmTripRatesModal if there are from 1 to 100 associated trips, and we're only editing 1 rate
        } else if (totalCount > 0 && totalCount <= 100 && selectedRows.length === 1) {
          openRatesModal({
            type: RateModalType.ConfirmTripRates,
            payload: { data: associatedTrips, rate: { ...form }, rateUpdate },
          });
          return onHide();
          // Only Open MassConfirmModal if there are more than 100 associated trips
        } else if (totalCount > 100) {
          openRatesModal({ type: RateModalType.MassConfirm, payload: { totalCount: totalCount, rateUpdate } });
          return onHide();
        }
      }
      onHide();
      if (!!res && selectedRows?.length === 1)
        openRatesModal({ type: RateModalType.ApplySimilar, payload: { rateId: data?.id || res?.output?.[0]?.node?.id } });
    },
  });
  const { onHide, onSubmit, onChange } = setState;
  const { show = false, selectedRows = [], title, loading, options = {}, dropCity = undefined, form = {} } = state;
  let data = selectedRows?.[0] || {};
  const fields = useMemo(() => (data?.id ? getBulkValues(selectedRows) : undefined), [selectedRows, data]);

  // Validation
  const [sharedFieldsValidationCriteria, bulkFieldsValidationCriteria] = useMemo((): any => {
    const { bulkFields: initBulkFields, ...initSharedFields } = initEditRatesModalForm;
    const [shared, bulk] = Object.entries(RATES_VALIDATION_CRITERIA).reduce(
      ([sharedFields, bulkFields], [key, val]) => {
        if (initSharedFields.hasOwnProperty(key.replace(/!$/, ''))) sharedFields[key] = val;
        if (initBulkFields[0].hasOwnProperty(key.replace(/!$/, ''))) bulkFields[key] = val;
        return [sharedFields, bulkFields];
      },
      [{}, {}]
    );
    return [shared, bulk];
  }, []);
  const [validity, bulkValidity] = useMemo((): any => {
    const { bulkFields, ...sharedFields } = form;
    const sharedFieldsValidity = fieldValidator(sharedFields, selectedRows, sharedFieldsValidationCriteria);
    const bulkFieldsValidity = (bulkFields || []).map((setOfBulkFields: BulkFields) =>
      fieldValidator(setOfBulkFields, selectedRows, bulkFieldsValidationCriteria)
    );
    return [sharedFieldsValidity, bulkFieldsValidity];
  }, [fieldValidator, form]);
  const dateOverlaps = useMemo(() => {
    if (form?.bulkFields?.length > 1) {
      const dates = form?.bulkFields?.map((f: BulkFields) => ({
        ...f,
        startDatetime: new Datetime(f?.startDatetime).setTime('00:00:00'),
        endDatetime: new Datetime(f?.endDatetime).setTime('23:59:59'),
      }));
      return [];
    }
    return [];
  }, [form?.bulkFields]);
  const isValid = Validation.isValid(validity) && bulkValidity.every(Validation.isValid) && dateOverlaps.length === 0;

  const handleShowRateHistory = (): void => {
    const stringSearch = generateQueryString({
      type: form?.type,
      airportCode: form?.airportCode,
      currency: form?.currency,
      payerProviderId: form?.payerProviderId,
      thisLocationId: form?.thisLocationId,
      thatLocationId: form?.thatLocationId,
      id: form?.id,
    });
    onHide();
    navigate(`/rates?${stringSearch}`);
  };

  useEffect((): void => {
    if (!!show && !!data?.id) {
      const initFormCopy = { ...initEditRatesModalForm, bulkFields: [{ ...initEditRatesModalForm.bulkFields[0] }] };
      const values: EditRatesModalForm = Object.keys(data).reduce((acc, fieldName) => {
        if (initEditRatesModalForm.bulkFields[0].hasOwnProperty(fieldName)) acc.bulkFields[0][fieldName] = data[fieldName];
        acc[fieldName] = data[fieldName];
        return acc;
      }, initFormCopy);
      setState((current) => ({ ...current, form: { ...initFormCopy, ...values } }));
    }
  }, [show]);

  return (
    <EditModal
      show={show}
      title={`${data?.id ? 'Edit' : 'Create'} Rate`}
      icon="fa fa-location-dot"
      onHide={onHide}
      options={{
        Header: data?.id ? (
          <div className="RatesHistoryButton" onClick={handleShowRateHistory}>
            HISTORY
          </div>
        ) : undefined,
        Footer: (
          <Row className="mt-3 gap-2">
            <Row>
              <Col className="ps-4 pe-1">
                <Button variant="secondary" className="w-100" onClick={onHide}>
                  CANCEL
                </Button>
              </Col>
              <Col className="ps-1 pe-0">
                <TippyWhen isTrue={!isValid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
                  <Button name="SUBMIT" variant="primary" className="w-100" onClick={onSubmit} disabled={!isValid}>
                    {!data?.id ? 'CREATE' : 'UPDATE'}
                  </Button>
                </TippyWhen>
              </Col>
            </Row>
            {data?.id && selectedRows?.length === 1 && (
              <Row>
                <Col className="ps-4 pe-0">
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={() => {
                      data = { ...(selectedRows[0] || {}), id: undefined };
                      onSubmit();
                    }}
                  >
                    SAVE AS NEW
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
        ),
      }}
      loading={loading}
      name="editRates"
    >
      <FormField
        name="form.payerProviderId"
        label="client"
        onChange={onChange}
        value={form?.payerProviderId || ''}
        placeholder="Client"
        valid={validity?.payerProviderId?.valid}
        options={{
          input: {
            as: SelectClient,
          },
          group: {
            className: 'pt-0',
          },
        }}
        searchable
      />
      <Row>
        <Col sm={3}>
          <FormField
            label="Airport"
            placeholder="Airport"
            name="form.airportCode"
            value={form?.airportCode || ''}
            onChange={(event: any) => {
              const { value } = event?.target;
              setState((current) => ({
                ...current,
                dropCity: value,
                form: {
                  ...current.form,
                  airportCode: value,
                  thisLocationId: '',
                  thatLocationId: '',
                },
              }));
            }}
            valid={validity?.airportCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirport,
              },
              group: {
                className: 'p-0',
              },
            }}
            condensed
          />
        </Col>
        <Col sm={{ span: 8, offset: 1 }}>
          <FormField
            name="form.thatLocationId"
            label="location A"
            onChange={onChange}
            value={form?.thatLocationId || ''}
            placeholder="Location A"
            inputOptions={{ removeAirportPrefix: true }}
            valid={validity?.thatLocationId?.valid}
            query={{ airportCode: queryInput(form?.airportCode || null) }}
            options={{
              input: {
                as: SelectLocation,
              },
              group: {
                className: 'pt-0',
              },
            }}
            condensed
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <FormField
            placeholder="Drop City"
            name="dropCity"
            label="Drop City"
            value={dropCity || ''}
            onChange={onChange}
            searchable
            options={{ input: { as: SelectAirport } }}
          />
        </Col>
        <Col sm={{ span: 8, offset: 1 }}>
          <FormField
            name="form.thisLocationId"
            label="location B"
            onChange={onChange}
            value={form?.thisLocationId || ''}
            placeholder="Location B"
            query={{ airportCode: queryInput(dropCity || form?.airportCode || null) }}
            inputOptions={{ removeAirportPrefix: true }}
            valid={validity?.thisLocationId?.valid}
            options={{
              input: {
                as: SelectLocation,
              },
              group: {
                className: 'pt-0',
              },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <FormField
            name="form.duration"
            label="duration"
            onChange={onChange}
            value={form?.duration || ''}
            placeholder="Duration"
            condensed
            disabled
          />
        </Col>
        <Col sm={{ span: 8, offset: 1 }}>
          <FormField
            name="form.tripDistance"
            label="distance"
            onChange={onChange}
            value={form?.tripDistance || ''}
            placeholder="Distance"
            condensed
            disabled
          />
        </Col>
      </Row>
      {Array.isArray(form?.bulkFields) &&
        (form?.bulkFields || []).map((setOfBulkFields: BulkFields, index: number) => (
          <BulkFields
            data={setOfBulkFields}
            onChange={onChange}
            onChangeType={(event) => {
              const newBulkFields = form?.bulkFields?.map((setOfBulkFields: BulkFields) => ({
                ...setOfBulkFields,
                type: event?.target?.value,
              }));
              onChange({ target: { name: 'form.bulkFields', value: newBulkFields } });
            }}
            onAdd={
              !data?.id
                ? () => {
                    const newBulkFields = stringify.parse(form?.bulkFields || []);
                    const nextDay = new Datetime(form?.bulkFields?.[index]?.endDatetime).add(1, 'day').frontendDate;
                    newBulkFields.splice(index + 1, 0, {
                      ...initEditRatesModalForm.bulkFields[0],
                      type: form?.bulkFields?.[index]?.type,
                      startDatetime: nextDay,
                      endDatetime: nextDay,
                    });
                    onChange({ target: { name: 'form.bulkFields', value: newBulkFields } });
                  }
                : undefined
            }
            onRemove={
              !data?.id && form?.bulkFields?.length !== 1
                ? () => {
                    const newBulkFields = stringify.parse(form?.bulkFields || []);
                    newBulkFields.splice(index, 1);
                    onChange({ target: { name: 'form.bulkFields', value: newBulkFields } });
                  }
                : undefined
            }
            validity={bulkValidity?.[index]}
            dateOverlaps={dateOverlaps}
            index={index}
            key={index}
          />
        ))}
    </EditModal>
  );
};

const BulkFields = ({ data, onChange, onChangeType, validity, index, dateOverlaps = [], onAdd, onRemove }) => {
  const [from, to] = useMemo(() => {
    return [
      new Datetime(data?.startDatetime || TODAY).format(DATE_FE_FORMAT_FULL),
      new Datetime(data?.endDatetime || TODAY_EOD).format(DATE_FE_FORMAT_FULL),
    ];
  }, [data?.startDatetime, data?.endDatetime]);
  const datesHaveOverlap = dateOverlaps.some((overlappingIndex) => overlappingIndex === index);

  return (
    <div className="position-relative mt-3">
      <div className="d-flex flex-column justify-content-center position-absolute h-100 {right:-3rem;top:0.5rem;}">
        {!!onRemove && (
          <Tippy content="Delete" placement="right">
            <Button
              size="sm"
              variant="outline-danger"
              className="d-inline-block {transform:scale(0.75);translate:-0.5rem;}"
              onClick={onRemove}
            >
              <i className="fa fa-minus" />
            </Button>
          </Tippy>
        )}
        {!!onAdd && (
          <Tippy content="Add Below" placement="right">
            <Button
              size="sm"
              variant="outline-success"
              className="d-inline-block {transform:scale(0.75);translate:-0.5rem;}"
              onClick={onAdd}
            >
              <i className="fa fa-plus" />
            </Button>
          </Tippy>
        )}
      </div>
      <div className="d-flex flex-column gap-2 py-2">
        <Row>
          <Col sm={3}>
            <SelectRateType
              label="Type"
              name={`form.bulkFields.${index}.type`}
              value={data?.type}
              onChange={(event) => (event?.target?.value ? onChangeType(event) : undefined)}
              valid={validity?.type?.valid}
              condensed
            />
          </Col>
          <Col sm={{ span: 8, offset: 1 }} className="position-relative">
            <FormField
              name="dates"
              label="Dates"
              type="daterange"
              value={[from, to]}
              format={DATE_FE_FORMAT_FULL}
              onChange={onChange.dateRange(`form.bulkFields.${index}.startDatetime`, `form.bulkFields.${index}.endDatetime`)}
              condensed
              valid={
                datesHaveOverlap
                  ? Validation.ValidityType.INVALID
                  : validity?.startDatetime?.valid === Validation.ValidityType.VALID_WARNING ||
                      validity?.endDatetime?.valid === Validation.ValidityType.VALID_WARNING
                    ? Validation.ValidityType.VALID_WARNING
                    : true
              }
              options={{
                feedback: { className: 'text-end pe-1' },
              }}
              feedback={datesHaveOverlap ? 'Overlapping dates' : undefined}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <FormField
              type="number"
              name={`form.bulkFields.${index}.rate`}
              label="rate"
              onChange={(event) => {
                const { name, value } = event.target;
                const rate = Math.min(Math.max(parseFloat(value), 0), 9999);
                onChange({ target: { name, value: rate } });
              }}
              value={data?.rate === 0 ? 0 : data?.rate || ''}
              placeholder="Amount"
              valid={validity?.rate?.valid}
              condensed
            />
          </Col>
          <Col sm={{ span: 8, offset: 1 }}>
            <FormField
              name={`form.bulkFields.${index}.currency`}
              label="currency"
              onChange={onChange}
              value={data?.currency || ''}
              placeholder="Currency"
              valid={validity?.currency?.valid}
              options={{
                input: {
                  as: SelectCurrency,
                },
                group: {},
              }}
              condensed
            />
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <FormField
              name={`form.bulkFields.${index}.name`}
              label="Rate Name"
              onChange={onChange}
              value={data?.name || ''}
              placeholder="Add Rate Name"
              disabled={(fields?.name || []).length > 1}
              valid={validity?.name?.valid}
              condensed
            />
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default EditRatesModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditAvailabilityPopover {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
}
.EditAvailabilityPopover > .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0;
}
.EditAvailabilityPopover > .col > .row {
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  --bs-gutter-x: 1.2rem;
}
.EditAvailabilityPopover > .col > .row:last-of-type {
  padding-bottom: 1rem;
}
.EditAvailabilityPopover .FormField-Label {
  margin: 0 !important;
}
.EditAvailabilityPopover :not(.Select).btn {
  text-transform: uppercase;
  font-size: 0.9rem;
}

.ConfirmationModal {
  --bs-modal-zindex: 1075;
}

.EditAvailabilityHeader {
  padding: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/EditAvailabilityPopover/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,UAAA;AACJ;AAAI;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EAIA,qBAAA;AADN;AAFM;EACE,oBAAA;AAIR;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,iBAAA;AADJ;;AAKA;EACE,uBAAA;AAFF;;AAKA;EACE,eAAA;AAFF","sourcesContent":[".EditAvailabilityPopover {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 400px;\n}\n.EditAvailabilityPopover > .col {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n  padding: 0;\n}\n.EditAvailabilityPopover > .col > .row {\n  width: 100%;\n  flex-wrap: nowrap;\n  display: flex;\n  justify-content: center;\n  --bs-gutter-x: 1.2rem;\n}\n.EditAvailabilityPopover > .col > .row:last-of-type {\n  padding-bottom: 1rem;\n}\n.EditAvailabilityPopover .FormField-Label {\n  margin: 0 !important;\n}\n.EditAvailabilityPopover :not(.Select).btn {\n  text-transform: uppercase;\n  font-size: 0.9rem;\n}\n\n.ConfirmationModal {\n  --bs-modal-zindex: 1075;\n}\n\n.EditAvailabilityHeader {\n  padding: 1rem 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { GetGroupsDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getGroups = createGraphApiMockResponse(GetGroupsDocument, {
  getFilters: {
    filters: {
      groups: [
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Drivers',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Dispatchers-dev',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Admins',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Asst. Leader',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Regional',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Driver',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Dispatch',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Driver Training',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Dispatch Manager',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'HR',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Finance',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Leader On Duty',
          __typename: 'FilterOption',
        },
        {
          id: 'b5b63e8c-2a32-4459-87e8-1382a7603a5c',
          displayName: 'Manager',
          __typename: 'FilterOption',
        },
      ],
    },
  },
});

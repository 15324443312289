import { RunArchiveImportsInput, RunArchiveImportsResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { GraphApiResponse } from '../../core';
import { RunArchiveImportsDocument } from '../../queries';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type RunArchiveImportsGraphApiResponse = GraphApiResponse<typeof RunArchiveImportsDocument>;

const title = 'Archive Imports';

const runArchiveImportsResponseSelector = (res: RunArchiveImportsGraphApiResponse): RunArchiveImportsResponse => res?.runArchiveImports;

const [runRunArchiveImports, runRefetchRunArchiveImports] = graphApi(RunArchiveImportsDocument, {
  onError: (res: RunArchiveImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runArchiveImports = async (importIds: string[]): Promise<RunArchiveImportsResponse> => {
  const input: RunArchiveImportsInput = {
    importIds,
  };

  const res = await runRunArchiveImports({ input });
  const selected = runArchiveImportsResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

const refetchRunArchiveImports = async (importIds: string[]): Promise<RunArchiveImportsResponse> => {
  const input: RunArchiveImportsInput = {
    importIds,
  };

  const res = await runRefetchRunArchiveImports({ input });
  const selected = runArchiveImportsResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useRunArchiveImports = createGraphApiHook(runArchiveImports, { refetch: refetchRunArchiveImports });

export default runArchiveImports;

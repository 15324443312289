// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeZone {
  width: 7rem;
  text-align: start;
  white-space: nowrap !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TimeZone/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,8BAAA;AACF","sourcesContent":[".TimeZone {\n  width: 7rem;\n  text-align: start;\n  white-space: nowrap !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

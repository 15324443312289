import { useEffect, useRef } from 'react';

/* useInterval
  A cleaner method for useInterval that gets the same effect with
  setTimeout as to avoid memory leaks.
*/

//TODO: add visibility api logic

const useInterval = (callback: Function, delay: number, fireOnMount: boolean = false): void => {
  const savedCallback = useRef<Function>(null);

  // TODO: this useEffect logic can be moved to the start function
  useEffect((): void => {
    if (fireOnMount) callback();
  }, []);

  useEffect((): void => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect((): (() => void) => {
    let running = false;
    let savedTimeout = null;

    const tick = async (): Promise<void> => {
      if (!running) return;
      await savedCallback.current();
      savedTimeout = setTimeout(tick, delay);
    };
    const stop = (): void => {
      running = false;
      if (savedTimeout !== null) clearTimeout(savedTimeout);
    };

    if (delay === null) return;
    const start = (): void => {
      running = true;
      savedTimeout = setTimeout(tick, delay);
    };
    start();
    const onVisibilityChange = (): void => {
      if (document.visibilityState === 'hidden') stop();
      else if (document.visibilityState === 'visible') {
        // TODO: This line can be moved to the start function allowing us to remove the useEffect on line 14
        if (fireOnMount) savedCallback.current();
        start();
      }
    };
    window.addEventListener('visibilitychange', onVisibilityChange);
    return (): void => {
      stop();
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [delay]);
};

export default useInterval;

import { Location, UpdateLocationByIdResponse } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdateLocationByIdDocument } from '../../queries';
import { Validation } from '../../../utils/validations';
import { createLocationValidator } from './createLocationBulk';
import graphApi from '../..';

type UpdateLocationByIdGraphApiResponse = GraphApiResponse<typeof UpdateLocationByIdDocument>;

const title = 'Update Locations';

const updateLocationByIdValidator = new Validation.Validator(
  {
    'id!': (val: string): Validation.Validity => ({
      valid: !!val && Validation.isValidUUID(val),
    }),
  },
  createLocationValidator
);
const updateLocationByIdResponseSelector = (res: UpdateLocationByIdGraphApiResponse): UpdateLocationByIdResponse =>
  !res.errors ? (res?.updateLocationById as UpdateLocationByIdResponse) : undefined;

export const [runUpdateLocationById] = graphApi(UpdateLocationByIdDocument, {
  onError: (res: UpdateLocationByIdGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const updateLocationById = async (location: Partial<Location>): Promise<UpdateLocationByIdResponse> => {
  if (!location?.id) throw new Error('Invalid location. Missing id.');
  location = updateLocationByIdValidator.partial(location);

  const input = {
    locationId: location?.id,
    value: {},
  };
  if (location?.airports !== undefined) input['airportCodes'] = location.airports;
  if (location?.aliases !== undefined) input['aliases'] = location.aliases;
  if (location?.active !== undefined) input.value['active'] = location.active;
  if (location?.address !== undefined) input.value['address'] = location.address;
  if (location?.cityName !== undefined) input.value['cityName'] = location.cityName;
  if (location?.coordinates !== undefined) input.value['coordinates'] = {};
  if (location?.coordinates?.lat !== undefined) input.value['coordinates'].lat = location.coordinates.lat;
  if (location?.coordinates?.lng !== undefined) input.value['coordinates'].lng = location.coordinates.lng;
  if (location?.countryCode !== undefined) input.value['countryCode'] = location.countryCode;
  if (location?.name !== undefined) input.value['name'] = location.name;
  if (location?.phoneNumber !== undefined) input.value['phoneNumber'] = location.phoneNumber;
  if (location?.radius !== undefined) input.value['radius'] = location.radius;
  if (location?.stateCode !== undefined) input.value['stateCode'] = location.stateCode;
  if (location?.type !== undefined) input.value['type'] = location.type;
  if (location?.zipCode !== undefined) input.value['zipCode'] = location.zipCode;

  const res = await runUpdateLocationById({ input });
  const data = updateLocationByIdResponseSelector(res);

  if (data) createNotification('Success', Toast.Type.SUCCESS, title);

  return data;
};

export default updateLocationById;

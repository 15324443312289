import EditUsersModal from '.';
import Logger from '../../utils/logs';
import React from 'react';
import { User } from 'models/gen/graphql';
import { queryInput } from 'utils';
import { useAppState } from 'store/appReducer';
import { useSearchUsers } from '@/api/services/users/searchUsers';

interface EditProfileModalProps {
  show: boolean;
  onHide: () => any;
}

const log = Logger.of('EditProfileModal');

const EditProfileModal = ({ show = false, onHide }: EditProfileModalProps): JSX.Element => {
  const [{ session }, setAppState] = useAppState();
  const userSession = session?.user || {};

  const [{ loading }, { refetch: searchUsers }] = useSearchUsers();

  const handleSubmit = async (): Promise<any> => {
    if (!userSession?.id || !show) return;
    const res = await searchUsers([{ id: queryInput([userSession?.id]) }], { pageSize: 1 });
    const user: User = res?.rows?.[0];
    if (!user) log.warn('handleSubmit: no user to update session, using previous user data.');
    setAppState((current: any): any => ({
      ...current,
      session: { ...(current?.session || {}), user: user || current?.session?.user },
    }));
  };

  return <EditUsersModal show={!!show} data={show ? userSession : undefined} onHide={onHide} onSubmit={handleSubmit} loading={loading} />;
};

export default React.memo(EditProfileModal);

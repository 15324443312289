import '@/components/ChatWidget/styles.scss';

import { Badge, Stack } from 'react-bootstrap';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import useChatRooms, { ChatRoomType, useChatState } from '@/hooks/useSocket/chat/useChatRooms';

import ChatList from '@/components/ChatWidget/ChatList';
import HasPermission from '../HasPermission';
import { Link } from 'react-router-dom';
import { getClasses } from '@/utils';
import useInterval from '@/hooks/useInterval';
import useViewport from '@/hooks/useViewport';
import { TODAY, TODAY_EOD } from '@/constants';

type ChatWidgetProps = HTMLAttributes<HTMLDivElement> & {};

const ChatWidget = ({ ...props }: ChatWidgetProps) => {
  const [rooms, , { totalUnread, getRooms }] = useChatRooms(ChatRoomType.CURRENT_CHAT);
  const [setUsersTyping] = useChatState(({ setState }) => [setState]);
  const [hidden, setHidden] = useState(false);
  const canHide = HasPermission.check('allowHideChat');
  const [
    {
      content: { height: viewportHeight },
    },
  ] = useViewport();
  useInterval(() => {
    setUsersTyping((current) => {
      const now = Date.now();
      return {
        ...current,
        usersTyping: current.usersTyping.filter((user: any) => now - user.timestamp < 3000),
      };
    });
  }, 3000);

  useEffect(() => {
    getRooms({
      format: ChatRoomType.CURRENT_CHAT,
      start_datetime: TODAY,
      end_datetime: TODAY_EOD,
      user_provider_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props} className={getClasses('ChatWidget', props?.className)}>
      <>
        <ChatWidget.Header
          unread={totalUnread}
          className={getClasses('position-sticky top-0 rounded-top-4 px-5 py-2 {z:999}', canHide ? 'pointer' : undefined)}
          onClick={() => canHide && setHidden(!hidden)}
        />
        {!hidden && <ChatList rooms={rooms} className={`{max-height:${viewportHeight - 30}px;}`} />}
      </>
    </div>
  );
};

type ChatWidgetHeaderProps = HTMLAttributes<HTMLDivElement> & {
  onClick?: () => void;
  unread?: number;
};
const ChatWidgetHeader = ({ onClick, ...props }: ChatWidgetHeaderProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div {...props} className={getClasses('ChatWidget-Header', props?.className)} onClick={onClick}>
      <Stack direction="horizontal">
        <ChatWidget.Title className="fs-5 text-uppercase" />
        <div className="ms-auto" />
        <ChatWidget.Unread unread={0} />
      </Stack>
    </div>
  );
};

type ChatWidgetTitleProps = HTMLAttributes<HTMLSpanElement>;
const ChatWidgetTitle = ({ ...props }: ChatWidgetTitleProps) => {
  return (
    <span {...props} className={getClasses('ChatWidget-Title', props?.className)}>
      Message Center
    </span>
  );
};

type ChatWidgetUnreadProps = HTMLAttributes<HTMLDivElement> & {
  unread?: number;
};
const ChatWidgetUnread = ({ unread, ...props }: ChatWidgetUnreadProps) => {
  return (
    <div {...props} className={getClasses('ChatWidget-Unread', props?.className)}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus*/}
      <Link to="/message-center">
        <span role="button">
          <i className="sv sv-inbox" />
        </span>
        {unread > 0 && (
          <Badge bg="secondary" pill>
            {unread}
          </Badge>
        )}
      </Link>
    </div>
  );
};

ChatWidget.Header = ChatWidgetHeader;
ChatWidget.Title = ChatWidgetTitle;
ChatWidget.Unread = ChatWidgetUnread;
export default ChatWidget;

import { GenerateUploadProfileImageUrlInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import gql from 'graphql-tag';
import { validateObj } from '../../utils';

const validateGenerateUploadProfileImageUrlQuery = validateObj({
  userId: (val: any): any => val || null,
  filename: (val: any): any => val || null,
});

const generateUploadProfileImageUrl: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (userId: string, filename: string): Promise<any> => {
      const input: GenerateUploadProfileImageUrlInput = validateGenerateUploadProfileImageUrlQuery({
        userId,
        filename,
      });

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any => data?.generateUploadProfileImageUrl || {},
  query: gql`
    mutation GenerateUploadProfileImageUrl($input: GenerateUploadProfileImageUrlInput!) {
      generateUploadProfileImageUrl(input: $input) {
        contentType
        error {
          code
          debug
          message
        }
        url
        userId
      }
    }
  `,
};

export default generateUploadProfileImageUrl;
export { validateGenerateUploadProfileImageUrlQuery };

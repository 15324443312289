import MapCoordinatesInput, { MapCoordinate } from '../../components/MapDisplay/MapCoordinatesInput';

import Collapsible from '../../components/Collapsible';
import EditImageModal from '../../components/EditImageModal';
import PickupPointInput from '../../components/PickupPointInput';
import React from 'react';
import Styles from '../../models/Styles';
import useForm from '../../hooks/useForm';

const MapSandbox = (): JSX.Element => {
  const [form, onChange, setForm] = useForm({
    pickupPoints: [
      {
        coordinates: {
          lat: 26.0742,
          lng: -80.1506,
        },
        radius: 600,
        color: Styles.MARKER_COLOR_LIST[0],
      },
    ],
    showUploadImageModal: false,
    images: [],
  });

  return (
    <>
      <h2 className="m-3 mt-0 p-3">Map Sandbox</h2>
      <Collapsible
        title="Pickup Points"
        className="{max-height:100%!;}>.Collapsible-Body {font-size:2rem!;padding-bottom:0.5rem;margin-bottom:0.5rem;border-bottom:1px|solid|#000;}>.Collapsible-Header"
      >
        <MapCoordinatesInput
          name="pickupPoints"
          value={form?.pickupPoints || []}
          onChange={onChange}
          onAddPoint={(event: google.maps.MapMouseEvent, input: { target: { name: string; value: MapCoordinate } }): void => {
            setForm((current: any): any => ({
              ...current,
              pickupPoints: [
                ...current.pickupPoints,
                {
                  ...input.target.value,
                  iataCode: '',
                  startDatetime: '',
                  endDatetime: '',
                  color: Styles.MARKER_COLOR_LIST[(current.pickupPoints || []).length % Styles.MARKER_COLOR_LIST.length],
                },
              ],
            }));
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        />
        {(form?.pickupPoints || []).map(
          (point, index: number): React.JSX.Element => (
            <PickupPointInput
              variant={point?.color}
              name={`pickupPoints.${index}`}
              value={point}
              onChange={onChange}
              onUploadClick={(): void => onChange({ target: { name: 'showUploadImageModal', value: true } })}
              key={index}
              provider
              point
              dateRange
              imageUpload
            />
          )
        )}
      </Collapsible>
      <EditImageModal
        show={form.showUploadImageModal}
        data={form.images.map((image) => image.src)}
        onHide={(): void => onChange({ target: { name: 'showUploadImageModal', value: false } })}
        onSubmit={(images): void => {
          setForm((current: any): any => ({ ...current, images, showUploadImageModal: false }));
        }}
      />
      <pre className="mt-3">{JSON.stringify(form, null, 2)}</pre>
    </>
  );
};

export default MapSandbox;

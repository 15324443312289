import { Button, Col, Row } from 'react-bootstrap';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';

import EditModal from '../EditModal/new';
import FileType from '../../models/FileType';
import Logger from '../../utils/logs';
import Thumbnail from '../ImageDisplay/Thumbnail';
import md5 from 'md5';

const log = Logger.of('EditImageModal');
const MAX_IMAGE_UPLOAD_SIZE_MB = 10;

type EditImageModalProps = {
  name?: string;
  title?: string;
  data?: FileType.Image[];
  loading?: boolean;
  show: boolean;
  onHide: () => void;
  onSubmit: (files: Record<string, File>, images: FileType.Image[]) => void;
};
type EditImageModalState = {
  images: FileType.Image[];
  files: Record<string, File>;
  loading: boolean;
};

const initEditImageModalState: EditImageModalState = {
  images: [],
  files: {},
  loading: false,
};

const EditImageModal = ({
  name = 'editImage',
  title = 'Upload Images',
  data,
  loading,
  show,
  onHide,
  onSubmit,
}: EditImageModalProps): React.JSX.Element => {
  const [form, setForm] = useState(initEditImageModalState);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      setForm((current: EditImageModalState): EditImageModalState => ({ ...current, loading: true }));
      const { files } = event.target;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const megabytes = file.size / (1024 * 1024);

        // Check the file size before proceeding
        if (megabytes > MAX_IMAGE_UPLOAD_SIZE_MB) {
          log.warn(`File ${file.name} exceeds the maximum file size (${megabytes.toFixed(2)} / ${MAX_IMAGE_UPLOAD_SIZE_MB} MB).`).notify({
            message: `File ${file.name} exceeds the maximum file size (${megabytes.toFixed(2)} / ${MAX_IMAGE_UPLOAD_SIZE_MB} MB).`,
            title,
          });
          continue; // Skip to the next file
        }

        const reader = new FileReader();
        reader.onloadend = (): void => {
          const hashedFile = md5(reader.result);
          setForm(
            (current: EditImageModalState): EditImageModalState => ({
              ...current,
              images: [...(current?.images || []), { src: reader.result, size: megabytes, name: file.name, md5: hashedFile }],
              files: { ...current?.files, [hashedFile]: file },
            })
          );
        };
        if (file) reader.readAsDataURL(file);
      }
    } catch (err) {
      log.error('onImageUpload', err.message || err);
    } finally {
      setForm((current: EditImageModalState): EditImageModalState => ({ ...current, loading: false }));
    }
  };
  const onRemoveImage =
    (i: number): MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLImageElement> =>
    (): void => {
      setForm((current: EditImageModalState): EditImageModalState => {
        const temp = [...(current?.images || [])];
        temp.splice(i, 1);
        return {
          ...current,
          images: temp,
        };
      });
    };
  const handleHide = (): void => {
    setForm(initEditImageModalState);
    onHide();
  };

  const handleSubmit = async (): Promise<void> => {
    setForm((current: EditImageModalState): EditImageModalState => ({ ...current, loading: true }));
    await onSubmit(form.files, form.images);
    handleHide();
  };
  const handleImageUpload = (): void => {
    imageInputRef.current.click();
  };

  useEffect((): void => {
    if (!data?.length || !show) return;
    setForm((current: EditImageModalState): EditImageModalState => ({ ...current, images: data }));
  }, [data, show]);

  return (
    <EditModal title={title} name={name} show={show} loading={!!loading || !!form?.loading} options={{ Footer: null }} onHide={handleHide}>
      <Row className="g-2 my-3">
        {form.images.map(
          (image: FileType.Image, i: number): React.JSX.Element => (
            <Col xs="auto" key={i}>
              <Thumbnail className="border-primary" src={image?.src as string} onClick={onRemoveImage(i)} />
            </Col>
          )
        )}
        {!form.images.length && (
          <Col xs={12} className="text-center {font-size:1.5rem} border border-primary rounded-1 p-3">
            <i className="sv sv-picture " />
            <br />
            No Images
          </Col>
        )}
      </Row>
      <Row xs="auto" className="justify-content-end gx-2 mt-3">
        <Col>
          <Button variant="dark" onClick={handleImageUpload}>
            <i className="sv sv-upload2 me-2 {font-size:1.5rem;}" />
            Upload
            <input name="images" type="file" className="d-none" onChange={onUploadImage} multiple ref={imageInputRef} />
          </Button>
        </Col>
        <Col>
          <Button variant="secondary" onClick={handleHide}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    </EditModal>
  );
};

export default React.memo(EditImageModal);

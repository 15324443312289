import { GetStatesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getStates = createGraphApiMockResponse(GetStatesDocument, {
  getFilters: {
    filters: {
      states: [
        {
          id: 'AL',
          displayName: 'Alabama',
          __typename: 'FilterOption',
        },
        {
          id: 'AK',
          displayName: 'Alaska',
          __typename: 'FilterOption',
        },
        {
          id: 'AZ',
          displayName: 'Arizona',
          __typename: 'FilterOption',
        },
        {
          id: 'AR',
          displayName: 'Arkansas',
          __typename: 'FilterOption',
        },
        {
          id: 'CA',
          displayName: 'California',
          __typename: 'FilterOption',
        },
        {
          id: 'CO',
          displayName: 'Colorado',
          __typename: 'FilterOption',
        },
        {
          id: 'CT',
          displayName: 'Conneticut',
          __typename: 'FilterOption',
        },
        {
          id: 'DE',
          displayName: 'Delaware',
          __typename: 'FilterOption',
        },
        {
          id: 'DC',
          displayName: 'District of Columbia',
          __typename: 'FilterOption',
        },
        {
          id: 'FL',
          displayName: 'Florida',
          __typename: 'FilterOption',
        },
        {
          id: 'GA',
          displayName: 'Georgia',
          __typename: 'FilterOption',
        },
        {
          id: 'HI',
          displayName: 'Hawaii',
          __typename: 'FilterOption',
        },
        {
          id: 'ID',
          displayName: 'Idoha',
          __typename: 'FilterOption',
        },
        {
          id: 'IL',
          displayName: 'Illinois',
          __typename: 'FilterOption',
        },
        {
          id: 'IN',
          displayName: 'Indiana',
          __typename: 'FilterOption',
        },
        {
          id: 'IA',
          displayName: 'Iowa',
          __typename: 'FilterOption',
        },
        {
          id: 'KS',
          displayName: 'Kansas',
          __typename: 'FilterOption',
        },
        {
          id: 'KY',
          displayName: 'Kentucky',
          __typename: 'FilterOption',
        },
        {
          id: 'LA',
          displayName: 'Lousiana',
          __typename: 'FilterOption',
        },
        {
          id: 'ME',
          displayName: 'Maine',
          __typename: 'FilterOption',
        },
        {
          id: 'MD',
          displayName: 'Maryland',
          __typename: 'FilterOption',
        },
        {
          id: 'MA',
          displayName: 'Massachusetts',
          __typename: 'FilterOption',
        },
        {
          id: 'MI',
          displayName: 'Michigan',
          __typename: 'FilterOption',
        },
        {
          id: 'MN',
          displayName: 'Minnesota',
          __typename: 'FilterOption',
        },
        {
          id: 'MS',
          displayName: 'Mississippi',
          __typename: 'FilterOption',
        },
        {
          id: 'MO',
          displayName: 'Missouri',
          __typename: 'FilterOption',
        },
        {
          id: 'MT',
          displayName: 'Montana',
          __typename: 'FilterOption',
        },
        {
          id: 'NE',
          displayName: 'Nebraska',
          __typename: 'FilterOption',
        },
        {
          id: 'NV',
          displayName: 'Nevada',
          __typename: 'FilterOption',
        },
        {
          id: 'NH',
          displayName: 'New Hampshire',
          __typename: 'FilterOption',
        },
        {
          id: 'NJ',
          displayName: 'New Jersey',
          __typename: 'FilterOption',
        },
        {
          id: 'NM',
          displayName: 'New Mexico',
          __typename: 'FilterOption',
        },
        {
          id: 'NY',
          displayName: 'New York',
          __typename: 'FilterOption',
        },
        {
          id: 'NC',
          displayName: 'North Carolina',
          __typename: 'FilterOption',
        },
        {
          id: 'ND',
          displayName: 'North Dakota',
          __typename: 'FilterOption',
        },
        {
          id: 'OH',
          displayName: 'Ohio',
          __typename: 'FilterOption',
        },
        {
          id: 'OK',
          displayName: 'Oklahoma',
          __typename: 'FilterOption',
        },
        {
          id: 'OR',
          displayName: 'Oregon',
          __typename: 'FilterOption',
        },
        {
          id: 'PA',
          displayName: 'Pennsylvania',
          __typename: 'FilterOption',
        },
        {
          id: 'RI',
          displayName: 'Rhode Island',
          __typename: 'FilterOption',
        },
        {
          id: 'SC',
          displayName: 'South Carolina',
          __typename: 'FilterOption',
        },
        {
          id: 'SD',
          displayName: 'South Dakota',
          __typename: 'FilterOption',
        },
        {
          id: 'TN',
          displayName: 'Tennessee',
          __typename: 'FilterOption',
        },
        {
          id: 'TX',
          displayName: 'Texas',
          __typename: 'FilterOption',
        },
        {
          id: 'UT',
          displayName: 'Utah',
          __typename: 'FilterOption',
        },
        {
          id: 'VT',
          displayName: 'Vermont',
          __typename: 'FilterOption',
        },
        {
          id: 'VA',
          displayName: 'Virginia',
          __typename: 'FilterOption',
        },
        {
          id: 'WA',
          displayName: 'Washington',
          __typename: 'FilterOption',
        },
        {
          id: 'WV',
          displayName: 'West Virginia',
          __typename: 'FilterOption',
        },
        {
          id: 'WI',
          displayName: 'Wisconsin',
          __typename: 'FilterOption',
        },
        {
          id: 'WY',
          displayName: 'Wyoming',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

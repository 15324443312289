import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const searchPreviousImports: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (importId: string, providerId: string, first: number = null, after: string = null): Promise<any> => {
      return fireQuery({
        variables: {
          input: { first, after, importId, providerId },
        },
        fetchPolicy: 'no-cache',
      });
    },
  selector: (data: any): any => {
    const {
      edges,
      totalCount,
      pageInfo: { hasNextPage = false, endCursor = '0' } = {},
    } = data?.searchPreviousImports?.previousImportConnection || {};
    const rows = (edges || []).map(({ node }: any): any => node || {});
    return { rows, hasNextPage, endCursor, totalCount };
  },
  query: gql`
    query PreviousImportConnection($input: PreviousImportConnectionInput) {
      searchPreviousImports {
        previousImportConnection(input: $input) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node {
              id
              name
            }
            cursor
          }
        }
      }
    }
  `,
};

export default searchPreviousImports;

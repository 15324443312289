import { DefaultChartProps } from '@/utils/charts';
import GenericChart from '../..';
import { useEffect, useRef } from 'react';

type PieChartProps = DefaultChartProps;

const PieChart = ({ yAxis, xAxis, data, loading }: PieChartProps) => {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      const instance = chartRef.current.getEchartsInstance();
      loading ? instance?.showLoading() : instance?.hideLoading();
    }
  }, [chartRef, loading]);

  const series = {
    type: 'pie',
    data: Object.entries(data).map(([name, values]) => {
      const valueAsNumber = parseFloat(values?.[0]?.[yAxis]);
      return {
        name,
        value: !Number.isNaN(valueAsNumber) ? valueAsNumber : values?.[0]?.[yAxis],
      };
    }),
    // TODO: Fix tooltip to allow for the new child-based rendering. Right now we'll show default tooltips just so that the value can be seen.
    // But it needs to be able to show ALL of the values in a way that works for a pie chart since it doesn't have any axes.
    tooltip: {
      show: true,
    },
  };

  return (
    <GenericChart
      noData={data?.length === 0 || !yAxis || !xAxis}
      ref={chartRef}
      option={{
        series,
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        tooltip: {
          trigger: 'item',
          textStyle: { fontSize: 12 },
        },
      }}
    />
  );
};

export default PieChart;

import { CreateImageBulkInput, CreateImageInput, CreateImageResponse, Image } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils/custom';

import { CreateImageBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import Logger from '../../../utils/logs';
import { Toast } from '../../../models';
import { Validation } from '../../../utils/validations';
import graphApi from '../..';
import md5 from 'md5';
import { uploadFile } from '../../../utils/promises';

type CreateImageGraphApiResponse = GraphApiResponse<typeof CreateImageBulkDocument>;

const log = Logger.of('Upload Image');
const title = 'Create Pickup Point Image';

export const createImageValidator = new Validation.Validator({
  'filename!': (val: string): Validation.Validity => ({
    valid: !!val,
    defaultValue: '',
  }),
  'md5!': (val: string): Validation.Validity => ({
    valid: !!val,
    defaultValue: '',
  }),
  path: (val: string): Validation.Validity => ({
    valid: false,
    defaultValue: '',
  }),
  'pickupPointId!': (val: string): Validation.Validity => ({
    valid: !!val && Validation.isValidUUID(val),
    defaultValue: '',
  }),
});
const createImageResponseSelector = (res: CreateImageGraphApiResponse): Image[] =>
  !res.errors ? (res?.createImageBulk?.output || []).map(({ node }: CreateImageResponse): Image => node) : undefined;

const [runCreateImageBulk] = graphApi(CreateImageBulkDocument, {
  onError: (res: CreateImageGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createImageBulk = async (images: Pick<Image, 'filename' | 'path' | 'md5' | 'pickupPointId'>[]): Promise<Image[]> => {
  const values: CreateImageInput[] = convertImagesToCreateImageInputs(images);
  const input: CreateImageBulkInput = {
    values,
    generateUploadUrl: 1, // always pass 1 to generate upload url. For every image
  };

  const res = await runCreateImageBulk({ input });
  return createImageResponseSelector(res);
};

export const createImagesWithFiles = async (files: File[], pickupPointId: string): Promise<Image[]> => {
  if (!files.length) throw new Error('No files provided.');
  if (!Validation.isValidUUID(pickupPointId)) throw new Error('Failed to Upload Pickup Point Image: Invalid pickup point provided.');

  const md5ToFileMap: { [md5: string]: File } = {};
  const createImagePayload: Pick<Image, 'filename' | 'path' | 'md5' | 'pickupPointId'>[] = [];

  files.forEach((img: File): void => {
    const hashedFile = md5(img);
    if (!hashedFile) throw new Error('Failed to process image. Try again.');
    md5ToFileMap[hashedFile] = img;
    createImagePayload.push({
      filename: img.name,
      pickupPointId,
      md5: hashedFile,
      path: '',
    });
  });

  const response = await createImageBulk(createImagePayload);
  uploadImageBulk(response, md5ToFileMap); // upload images to S3

  return response;
};
// helper method that handles the upload to S3
export const uploadImageBulk = async (images: Image[], md5ToFileMap: { [md5: string]: File }): Promise<void> => {
  try {
    let uploadImageProgress = 0;
    for (let i = 0; i < images?.length; i++) {
      const { uploadUrl: url, contentType, md5 } = images[i];
      if (!contentType) throw new Error('Unsupported content type. Try again.');
      if (!url) throw new Error('No url provided.');
      if (!md5) throw new Error('No md5 provided.');

      const file = md5ToFileMap[md5];
      if (!file) throw new Error('Failed to process image. Try again.');

      const bundle = { file, url, contentType };
      await uploadFile(bundle, {
        onUploadProgress: (progressEvent: any): void => {
          const { loaded, total } = progressEvent;
          const percent = (loaded * 100) / total;
          uploadImageProgress = ((i + 1) / images.length) * Math.round(percent);
        },
      });
    }
    if (uploadImageProgress === 100) createNotification('Done', Toast.Type.SUCCESS, 'Upload Pickup Point Image');
    else throw new Error('Some image(s) failed to upload. Try again.');
  } catch (err) {
    log.error('uploadImageBulk', err?.message || err).notify({ title: 'Upload Pickup Point Image' });
  }
};
// helper method to convert Image to CreateImageInput
export const convertImagesToCreateImageInputs = (images: Partial<Image>[]): CreateImageInput[] =>
  images.map((image: Image): CreateImageInput => createImageValidator.create(image));

export default createImageBulk;

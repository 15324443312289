import React, { useState } from 'react';
import { RateTypeEnum, ReportSeriesEnum, TimeIntervalBucket } from '@/models/gen/graphql';
import { TODAY, TODAY_EOD } from '@/constants';
import { getHoursAndMinutesFromMinutes, properCase, queryInput, removeNonWord, stringify, titleCase } from '@/utils';

import EditBucketsModal from '@/pages/Reports/DriveTime/EditBucketsModal';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import RatingInput from '@/components/RatingInput';
import Select from '@/components/Select';
import SelectAirlineIata from '@/components/SelectAirlineIata';
import SelectAirportGroup from '@/components/SelectAirportGroup';
import SelectClient from '@/components/SelectClient';
import SelectCompany from '@/components/SelectCompany';
import SelectCurrency from '@/components/SelectCurrency';
import SelectFromEnum from '@/components/SelectFromEnum';
import SelectKind from '@/components/SelectKind';
import SelectLocation from '@/components/SelectLocation';
import SelectReason from '@/components/SelectReason';
import SelectStatus from '@/components/SelectStatus';
import SelectType from '@/components/SelectType';
import SelectUser from '@/components/SelectUser';
import SelectRateType from '@/components/SelectRateType';
import { useAppState } from '@/store/appReducer';

const DriverSelect = ({ report, ...props }: any): JSX.Element => {
  const [{ config: { driverRoleId = undefined } = {} } = {}] = useAppState();
  return (
    <FormField
      {...props}
      placeholder="Driver"
      options={{
        input: {
          as: (props: any): JSX.Element => (
            <SelectUser
              {...props}
              options={{
                cache: `${props?.name}_${report?.airports?.join(',')}`,
                ttl: 0,
                variables: {
                  input: {
                    airports: report?.airports || [],
                    query: {
                      roleId: queryInput(driverRoleId),
                    },
                  },
                },
              }}
            />
          ),
        },
      }}
      searchable
      condensed
      multiple
    />
  );
};

const BucketsFilter = ({ name, value, onChange }: any): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <FormButton variant="outline-gray" onClick={(): void => setShow(true)}>
        Shifts
      </FormButton>
      <EditBucketsModal
        value={(value || []).map((bucket: TimeIntervalBucket): { name: string; time: number } => ({
          ...bucket,
          time: bucket.hour * 60 + bucket.minute,
        }))}
        show={show}
        onHide={(): void => setShow(false)}
        onSubmit={(buckets: { name: string; time: number }[] = []): void => {
          const result: TimeIntervalBucket[] = buckets.reduce((acc: any[], { name, time }: { name: string; time: number }): any[] => {
            const [hour, minute] = getHoursAndMinutesFromMinutes(time);
            return [...acc, { name, hour, minute }];
          }, []);
          onChange({ target: { name, value: result } });
          setShow(false);
        }}
      />
    </>
  );
};

const reportFilters = {
  airlines: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Airline" options={{ input: { as: SelectAirlineIata } }} searchable condensed multiple />
  ),
  airports: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      placeholder="Airport"
      options={{ input: { as: SelectAirportGroup } }}
      searchable
      condensed
      sticky={undefined}
      onChange={(event) => {
        event.target.value = stringify.compare(event.target.value, props.value) ? undefined : event.target.value;
        props.onChange(event);
      }}
    />
  ),
  clients: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Client" options={{ input: { as: SelectClient } }} searchable condensed multiple />
  ),
  currencies: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Currency" options={{ input: { as: SelectCurrency } }} searchable condensed multiple />
  ),
  doLocations: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="D/O Location" options={{ input: { as: SelectLocation } }} searchable condensed multiple />
  ),
  drivers: DriverSelect,
  endDatetime: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} name="endDatetime" value={Array.isArray(props?.value) ? props?.value?.[0] : props?.value} type="date" condensed />
  ),
  kinds: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Kind" options={{ input: { as: SelectKind } }} searchable condensed multiple />
  ),
  puLocations: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="P/U Location" options={{ input: { as: SelectLocation } }} searchable condensed multiple />
  ),
  startDatetime: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      name="startDatetime"
      value={Array.isArray(props?.value) ? props?.value?.[0] : props?.value}
      type="date"
      condensed
    />
  ),
  dateRange: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      name="dateRange"
      type="daterange"
      value={props?.values || [TODAY, TODAY_EOD]}
      onChange={props.onChange.dateRange('startDatetime', 'endDatetime')}
      condensed
    />
  ),
  types: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Type" options={{ input: { as: SelectType } }} searchable condensed multiple />
  ),
  groupBy: ({ filter, ...props }: any): JSX.Element =>
    !!filter && (
      <FormField
        {...props}
        value={Array.isArray(props?.value) ? props?.value?.[0] : props?.value}
        options={{
          input: {
            as: (props: any): JSX.Element => (
              <Select {...props}>
                {(filter || []).map(
                  (f: any): JSX.Element => (
                    <option key={f} value={Array.isArray(f) ? f?.[0] : f}>
                      {f}
                    </option>
                  )
                )}
              </Select>
            ),
          },
        }}
        searchable
        condensed
        placeholder="Group By"
      />
    ),
  complexFilters: ({ filter, ...props }: any): JSX.Element => {
    return (
      !!filter && (
        <FormField
          {...props}
          value={props?.value}
          options={{
            input: {
              as: (props: any): JSX.Element => (
                <Select {...props}>
                  {(filter || []).map((f: any): JSX.Element => {
                    return (
                      <option key={f} value={Array.isArray(f) ? f?.[0] : f}>
                        {titleCase(removeNonWord(f, ' '))}
                      </option>
                    );
                  })}
                </Select>
              ),
            },
          }}
          searchable
          condensed
          multiple
          placeholder="Options"
        />
      )
    );
  },
  reason: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Reason" options={{ input: { as: SelectReason } }} searchable condensed multiple />
  ),
  rating: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      options={{
        input: {
          as: RatingInput,
        },
      }}
      condensed
    />
  ),
  companies: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      placeholder="Company"
      options={{
        input: {
          as: SelectCompany,
        },
      }}
      searchable
      condensed
      multiple
    />
  ),
  status: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField {...props} placeholder="Status" options={{ input: { as: SelectStatus } }} searchable condensed />
  ),
  series: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      name="series"
      placeholder="Series"
      options={{ input: { as: (props) => <SelectFromEnum {...props} source={ReportSeriesEnum} /> } }}
      getLabel={(key: string): string => properCase(key.replace(/^series/i, ''))}
      condensed
    />
  ),
  thresholds: (props: any): JSX.Element => {
    const handleOnChange = (name: string) => (event: any) => {
      const { value } = event.target;
      props.onChange({
        target: { name: props?.name || 'thresholds', value: name === 'min' ? [value, props?.value?.[1]] : [props?.value?.[0], value] },
      });
    };
    return (
      <>
        <FormField name="min" placeholder="Min" type="number" value={props?.value?.[0] || ''} onChange={handleOnChange('min')} condensed />
        <FormField name="max" placeholder="Max" type="number" value={props?.value?.[1] || ''} onChange={handleOnChange('max')} condensed />
      </>
    );
  },
  buckets: BucketsFilter,
  rateTypes: ({ report: _report, ...props }: any): JSX.Element => (
    <FormField
      {...props}
      placeholder="Rate Type"
      options={{ input: { as: (props) => <SelectFromEnum {...props} source={RateTypeEnum} /> } }}
      searchable
      condensed
      multiple
    />
  ),
};

export default reportFilters;

import { GetLicenseTypesDocument } from '@/api/queries';
import { createGraphApiMockResponse } from '@/api/core';

export const getLicenseTypes = createGraphApiMockResponse(GetLicenseTypesDocument, {
  getFilters: {
    filters: {
      licenseType: [
        {
          id: 'ae037fed-3e91-4c41-bbc5-ef0fffec2400',
          displayName: 'Class E',
          __typename: 'FilterOption',
        },
        {
          id: 'ae037fed-3e91-4c41-bbc5-ef0fffec2400',
          displayName: 'CDL',
          __typename: 'FilterOption',
        },
        {
          id: 'ae037fed-3e91-4c41-bbc5-ef0fffec2400',
          displayName: 'Airport Permit',
          __typename: 'FilterOption',
        },
        {
          id: 'ae037fed-3e91-4c41-bbc5-ef0fffec2400',
          displayName: 'Emission Permit',
          __typename: 'FilterOption',
        },
      ],
      __typename: 'Filters',
    },
    __typename: 'GetFiltersResponse',
  },
});

import { GET_VEHICLE_TRACKER } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectVehicleTracker = (props) => (
  <SelectFromQuery
    cache="VehicleTracker"
    placeholder="All Exchange Locations"
    query={GET_VEHICLE_TRACKER}
    selector={(data) => {
      return (data?.getFilters?.filters?.vehicleTracker || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }));
    }}
    {...props}
  />
);

export default SelectVehicleTracker;

import { DeleteLicenseBulkInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import gql from 'graphql-tag';

const deleteLicenseBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute) =>
    async (...ids: string[]): Promise<any> => {
      const input: DeleteLicenseBulkInput = {
        search: ids.map((id: string): { id: string } => ({ id })),
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): number => data?.deleteLicenseBulk?.deleted || 0,
  query: gql`
    mutation DeleteLicenseBulk($input: DeleteLicenseBulkInput!) {
      deleteLicenseBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteLicenseBulk;

import './styles.scss';

import { Datetime, getClasses } from '../../utils';
import React, { useRef, useState } from 'react';

import { TIME_FORMAT_FULL } from '../../constants';
import useEventListener from '@/hooks/useEventListener';
import useInterval from '../../hooks/useInterval';

const TimeZone = ({
  icon,
  label,
  timezone,
  className = undefined,
  onHover,
}: {
  icon: any;
  label: string;
  timezone: string;
  className?: string;
  onHover?: (isOver: boolean) => void;
}): JSX.Element => {
  const [time, setTime] = useState(new Datetime().asDayjs().tz(timezone).format(TIME_FORMAT_FULL));
  const tipRef = useRef<HTMLDivElement>(null);

  const classes = getClasses('TimeZone', className);

  useEventListener('mouseenter', (): void => onHover?.(true), tipRef.current);
  useEventListener('mouseleave', (): void => onHover?.(false), tipRef.current);
  useInterval((): void => {
    setTime(new Datetime().asDayjs().tz(timezone).format(TIME_FORMAT_FULL));
  }, 1000);

  return (
    <div className="d-flex" ref={tipRef}>
      {icon && <div className="d-flex flex-column justify-content-center">{icon}</div>}
      {label && (
        <div className={classes}>
          <strong>{label}</strong>: {time}
        </div>
      )}
    </div>
  );
};

export default TimeZone;

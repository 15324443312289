import { Col } from 'react-bootstrap';
import DateInput from 'components/DateInput';
import { Datetime } from 'utils';
import { OnChange } from 'hooks/useOnChange';
import React from 'react';
import TimeInput from 'components/TimeInput';
import useClassNames from '../../hooks/useClassNames';

type Props = {
  className?: string;
  name: string;
  value: string;
  onChange: OnChange;
  onBlur?: any;
  isValid?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  disableOnly?: {
    date?: boolean;
    time?: boolean;
  };
};

const DatetimeInput = ({ value: valueInput, className, onChange, name, disableOnly, disabled, ...props }: Props): JSX.Element => {
  const parsed = new Datetime(valueInput);
  const dateInputClasses = useClassNames(className, 'me-2');
  const timeInputClasses = useClassNames(className);

  const disableDate = disableOnly?.date || disabled;
  const disableTime = disableOnly?.time || disabled;

  return (
    <Col className="d-flex">
      <DateInput
        {...props}
        disabled={disableDate}
        className={dateInputClasses}
        name={name}
        value={parsed.dateInput}
        onChange={onChange.date}
      />
      <TimeInput
        {...props}
        disabled={disableTime}
        className={timeInputClasses}
        name={name}
        value={parsed.fullTime}
        onChange={onChange.time}
      />
    </Col>
  );
};

export default DatetimeInput;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditAirlineForm .section-label {
  margin-top: 1.5rem;
}
.EditAirlineForm .EditAirlineForm-Footer {
  margin-top: 2rem;
}
.EditAirlineForm .EditAirlineForm-Footer .btn {
  width: 100%;
}

.DynamicContent-Drawer .EditAirlineForm .col-6 {
  width: 100% !important;
}

.DynamicContent-Modal .EditAirlineForm .col-6 {
  width: 50% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/EditAirlineForm/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AACI;EACE,WAAA;AACN;;AAKE;EACE,sBAAA;AAFJ;;AAOE;EACE,qBAAA;AAJJ","sourcesContent":[".EditAirlineForm .section-label {\n  margin-top: 1.5rem;\n}\n.EditAirlineForm .EditAirlineForm-Footer {\n  margin-top: 2rem;\n}\n.EditAirlineForm .EditAirlineForm-Footer .btn {\n  width: 100%;\n}\n\n.DynamicContent-Drawer .EditAirlineForm .col-6 {\n  width: 100% !important;\n}\n\n.DynamicContent-Modal .EditAirlineForm .col-6 {\n  width: 50% !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

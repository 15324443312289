import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError } from '@/utils/custom';
import createGraphApiHook, { CreateGraphApiHookHook } from '../../../hooks/createGraphApiHook';

import { DeleteRateGroupBulkDocument } from '@/api/queries';
import { DeleteRateGroupInput } from '@/models/gen/graphql';
import graphApi from '@/api';

// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Delete Rate Group' } });

// Service Function Signature
type ServiceMethodPayload = string[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// DeleteRateGroupBulk Service
export const { deleteRateGroupBulk, useDeleteRateGroupBulk } = ((): {
  deleteRateGroupBulk: ServiceMethod;
  useDeleteRateGroupBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(DeleteRateGroupBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof DeleteRateGroupBulkDocument>): ServiceMethod =>
      async (ids: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const search: DeleteRateGroupInput[] = ids.map((id: string): DeleteRateGroupInput => ({ id }));
        const res = await fn({ input: { search } });
        return res;
      }
  );
  const useService = createGraphApiHook(mutate);
  return {
    deleteRateGroupBulk: mutate,
    useDeleteRateGroupBulk: useService,
  };
})();

import { appState, useAppState } from '../../store/appReducer';

import { PortalAuth } from '../../models/gen/graphql';

const HasPermission = ({ name, inverse, children = [] }: { name: keyof PortalAuth; inverse?: boolean; children?: any }): JSX.Element => {
  const [appState] = useAppState();
  const { config: { auth: { [name]: permission = false } = {} } = {} } = appState || {};

  if (!permission || (inverse !== undefined && !!permission)) return null;
  return children;
};

HasPermission.check = (name?: keyof PortalAuth): boolean => {
  const [{ config: { auth = {} } = {} }] = appState();
  const { [name]: permission = false } = auth;
  if (!name) return !!Object.keys(auth).length;
  return !!permission;
};

export default HasPermission;

import React, { ForwardedRef, forwardRef, useMemo } from 'react';
import Select, { SelectProps } from '@/components/Select';

import { titleCase } from '@/utils';
import useLocale from '@/hooks/useLocale';

export type SelectFromEnumProps = {
  source: Record<string, string>;
  getLabel?: (key: string, val: string) => string;
  locale?: Record<string, string>;
} & SelectProps;

const SelectFromEnum = (
  {
    value,
    source,
    getLabel = (key: string): string => titleCase(key).replace(/_/g, ' '),
    locale: defaultLocale = {},
    ...props
  }: SelectFromEnumProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLInputElement>
): JSX.Element => {
  const locale = useLocale(defaultLocale);

  const options = useMemo(
    (): JSX.Element[] =>
      Object.entries(source)
        .filter(([key, val]: [string, string]): boolean => !!getLabel(key, val))
        .map(
          ([key, val]: [string, string]): JSX.Element => (
            <option value={val} key={key}>
              {locale(getLabel(key, val))}
            </option>
          )
        ),
    [getLabel, locale, source]
  );

  return (
    <Select {...props} ref={ref} value={value}>
      {options}
    </Select>
  );
};

export default forwardRef(SelectFromEnum);

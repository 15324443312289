// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
}
.NotFound > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.NotFound > div > * {
  display: flex;
  flex-direction: column;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AACN","sourcesContent":[".NotFound {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  justify-content: center;\n}\n.NotFound > div {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.NotFound > div > * {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionsDropdown {
  position: relative;
}

.ant-dropdown-menu {
  border-radius: var(--bs-border-radius) !important;
  padding: 0 !important;
  overflow: hidden !important;
  box-shadow: var(--bs-box-shadow) !important;
}
.ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  color: #000 !important;
  font-weight: 600 !important;
  line-height: 1.5em !important;
  min-height: 1.5em !important;
}
.ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 0 !important;
  font-weight: 600 !important;
  line-height: 1.5em !important;
  min-height: 1.5em !important;
}
.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background-color: rgba(var(--bs-success-rgb), 0.75) !important;
  color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ActionsDropdown/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AACA;EACE,iDAAA;EACA,qBAAA;EACA,2BAAA;EACA,2CAAA;AAEF;AADE;EACE,sBAAA;EACA,2BAAA;EACA,6BAAA;EACA,4BAAA;AAGJ;AADE;EACE,2BAAA;EACA,2BAAA;EACA,6BAAA;EACA,4BAAA;AAGJ;AAFI;EAEE,8DAAA;EACA,sBAAA;AAGN","sourcesContent":[".ActionsDropdown {\n  position: relative;\n}\n\n.ant-dropdown-menu {\n  border-radius: var(--bs-border-radius) !important;\n  padding: 0 !important;\n  overflow: hidden !important;\n  box-shadow: var(--bs-box-shadow) !important;\n}\n.ant-dropdown-menu .ant-dropdown-menu-submenu-title {\n  color: #000 !important;\n  font-weight: 600 !important;\n  line-height: 1.5em !important;\n  min-height: 1.5em !important;\n}\n.ant-dropdown-menu .ant-dropdown-menu-item {\n  border-radius: 0 !important;\n  font-weight: 600 !important;\n  line-height: 1.5em !important;\n  min-height: 1.5em !important;\n}\n.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {\n  background-color: rgba(var(--bs-success-rgb), 0.75) !important;\n  color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { GET_STATES } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectState = (props) => (
  <SelectFromQuery
    cache="State"
    placeholder="All States"
    query={GET_STATES}
    selector={(data) =>
      (data?.getFilters?.filters?.states || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
        values: opt.values,
      }))
    }
    {...props}
  />
);

export default SelectState;

import { PAGE_SIZE } from '../../constants';
import { getConnectionDetails } from '../../utils';
import gql from 'graphql-tag';

const getFcrTypes = {
  query: gql`
    query FcrTypeConnection($input: FcrTypeConnectionInput) {
      searchFcrTypes {
        fcrTypeConnection(input: $input) {
          edges {
            node {
              id
              name
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          totalCount
        }
      }
    }
  `,
  wrapper: (fireQuery) => async () => {
    // Handle validation.
    return fireQuery({
      variables: {
        input: {
          first: PAGE_SIZE,
          after: null,
          query: null,
        },
      },
    });
  },
  selector: getConnectionDetails('searchFcrTypes.fcrTypeConnection'),
};

export default getFcrTypes;

import {
  ConnectionDetails,
  HandleErrorInput,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails as merge,
} from '@/utils/custom';
import { ConnectionOptions, GraphApiMethod } from '@/api/core';
import { SearchVariableTimeResponseVariableTimeConnectionArgs, VariableTime, VariableTimeSearch } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { SearchVariableTimesDocument } from '@/api/queries';
import { TypedDocumentNode } from '@apollo/client';
import { getProperty } from '@/utils';
import graphApi from '@/api';

// Global Service Variables
const mergeKey = 'searchVariableTimes.variableTimeConnection';
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Search Variable Times' } });

// Service Function Signature
type ServiceMethodPayload = {
  [key in keyof VariableTimeSearch]?: any;
}[];
interface ServiceMethod {
  (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<VariableTime>>;
}

// SearchVariableTimes Service
export const { searchVariableTimes, refetchVariableTime, useSearchVariableTimes } = ((): {
  searchVariableTimes: ServiceMethod;
  refetchVariableTime: ServiceMethod;
  useSearchVariableTimes: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [fetch, refetch] = graphApi(SearchVariableTimesDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<TypedDocumentNode>): ServiceMethod =>
      async (payload?: ServiceMethodPayload, options?: ConnectionOptions): Promise<ConnectionDetails<VariableTime>> => {
        const args: SearchVariableTimeResponseVariableTimeConnectionArgs = {
          input: {
            first: options?.pageSize || null,
            after: options?.pageSize > 0 ? (options?.pageSize * options?.page).toString() : null,
            query: payload,
          },
        };
        const opts = { merge: options?.merge ? mergeKey : undefined };
        const res = await fn(args, opts);
        return convertConnectionToDetails(getProperty(mergeKey, res));
      }
  );
  const useService = createGraphApiHook(fetch, { refetch, merge });
  return {
    searchVariableTimes: fetch,
    refetchVariableTime: refetch,
    useSearchVariableTimes: useService,
  };
})();

import { useEffect, useState } from 'react';

import { H } from 'highlight.run';
import { useAppState } from '../../store/appReducer';

type State = {
  initialized: boolean;
  recording: boolean;
};
const initHighlightProviderState = {
  initialized: false,
  recording: false,
};

const HighlightProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, setState] = useState(initHighlightProviderState);
  const { initialized = false, recording = false } = state;
  const [{ config = {}, session = {} }] = useAppState();

  useEffect((): void => {
    /* Initialize Highlight
      This effect is here to connect to our existing Highlight project.
      The project ID should always be returned to allow this in order for
      the error boundary to work, regardless of whether we are recording
      the user or not.
    */
    if (!!initialized || !config?.env || !config?.highlightApiKey) return;
    H.init(config?.highlightApiKey, {
      serviceName: 'portal-spa',
      tracingOrigins: [
        'localhost',
        'dev.skyvibe.io',
        'stage.skyvibe.io',
        'prod.skyvibe.io',
        'api.dev.skyvibe.io/graphql',
        'api.stage.skyvibe.io/graphql',
        'api.prod.skyvibe.io/graphql',
      ],
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
      },
      manualStart: true,
      environment: config?.env,
    });
    setState((current: State): State => ({ ...current, initialized: true }));
  }, [config?.env, config?.highlightApiKey]);
  useEffect((): void => {
    /* Start Highlight
      This effect starts recording the user's session if the permission is
      set to true. Otherwise, it will not begin recording the user's session.
    */

    // @TODO: remove this return when we are ready to record sessions.
    return;

    if (!initialized || !!recording || !session?.user?.id || !config?.auth?.allowRecordSession) return;
    const { user = {} } = session || {};
    const flatUser = {};
    Object.entries(user).forEach(([k, v]: any): void => {
      if (['string', 'number', 'boolean'].includes(typeof v)) flatUser[k] = v;
    });
    // Identify only allows flat objects, so we have to remove all nested
    // objects from the user before identifying them.
    H.identify(user.email, flatUser);
    H.start();
    setState((current: State): State => ({ ...current, recording: true }));
  }, [initialized, session]);

  return children;
};

export default HighlightProvider;

import { GraphApiMethodOptions, GraphApiProvider, GraphApiResponse } from './core';
import { getCookies, getErrors, mergeEdges } from '../utils';

import { DocumentNode } from 'graphql';
import Logger from '../utils/logs';
import { apolloClient } from '../components/ApolloRoot';
import axios from 'axios';
import platform from 'platform';

// This logic is already implimented in ApolloRoot, but other providers will need it.
const { 'client-api-url': endpoint, hash: appVersion } = window?.['__config__'] || {};
const log = Logger.of('GraphApiProvider');
const generateHeaders = (headers: any = {}): any => {
  const { token } = getCookies('token');
  if (!token) log.warn('Invalid Session: Token not defined.');
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'sky-language': navigator.language || 'unknown',
      'sky-app': 'portal',
      'sky-app-version': appVersion || 'unknown',
      'sky-platform': platform?.['name'] || 'unknown',
      'sky-platform-version': platform?.['version'] || 'unknown',
      'sky-device-type': platform?.['os']?.toString?.() || 'unknown',
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
};
const generateResponse = (res: any): Partial<GraphApiResponse> => {
  const errors = getErrors(res);
  return {
    ...res?.data,
    errors: errors?.length ? errors : undefined,
  };
};
// Our current Provider creates the methods needed to use an apolloClient
// with GraphApi.

// Other Providers can be added to this file if they are needed.

const convertGraphApiOptionsToApolloOptions = (options: GraphApiMethodOptions): Record<string, any> => {
  if (!options) return {};
  const { merge = '', ...rest } = options;
  const [main, sub] = merge.split('.');
  if (!main || !sub || !merge) return rest;
  return {
    ...rest,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'no-cache',
    updateQuery: mergeEdges(main, sub),
  };
};
export const apolloProvider: GraphApiProvider = {
  query: async (query: DocumentNode, payload: Record<string, any>, options?: GraphApiMethodOptions): Promise<any> =>
    await apolloClient.query({
      query,
      variables: payload,
      ...convertGraphApiOptionsToApolloOptions(options),
    }),
  refetch: async (query: DocumentNode, payload: Record<string, any>, options?: GraphApiMethodOptions): Promise<any> =>
    await apolloClient.query({
      query,
      variables: payload,
      ...convertGraphApiOptionsToApolloOptions(options),
      fetchPolicy: 'no-cache',
    }),
  mutate: async (mutation: DocumentNode, payload: Record<string, any>, options?: GraphApiMethodOptions): Promise<any> =>
    await apolloClient.mutate({
      mutation,
      variables: payload,
      ...convertGraphApiOptionsToApolloOptions(options),
    }),
  response: (res: any): Partial<GraphApiResponse> => generateResponse(res),
};

const generateAxiosPayload = (query: DocumentNode, payload: Record<string, any>, options?: GraphApiMethodOptions): any => ({
  operationName: query?.['definitions']?.[0]?.['name']?.['value'],
  query: query?.loc?.source?.body || '',
  variables: payload,
  ...(options || {}),
});
const fireAxiosRequest = async (query: DocumentNode, payload: Record<string, any>, options?: GraphApiMethodOptions): Promise<any> =>
  await axios.post(
    `${endpoint}?${query?.['definitions']?.[0]?.['name']?.['value']}`,
    generateAxiosPayload(query, payload, options),
    generateHeaders()
  );
export const axiosProvider: GraphApiProvider = {
  query: fireAxiosRequest,
  mutate: fireAxiosRequest,
  response: (res: any): Partial<GraphApiResponse> => generateResponse(res?.data),
};

import { CreateDownloadImportUrLsInput, DownloadImportResponse, Import } from '../../../models/gen/graphql';

import { CreateDownloadImportUrLsDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils';

type CreateDownloadImportURLsGraphApiResponse = GraphApiResponse<typeof CreateDownloadImportUrLsDocument>;

const title = 'Create Download Import URLs';

const createDownloadImportUrLsResponseSelector = (res: CreateDownloadImportURLsGraphApiResponse): Import[] =>
  !res.errors ? res?.createDownloadImportURLs?.imports?.map((node: DownloadImportResponse): Import => node?.import) : undefined;

const [runCreateDownloadImportURLs] = graphApi(CreateDownloadImportUrLsDocument, {
  onError: (res: CreateDownloadImportURLsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const createDownloadImportURLs = async (ids: string | string[]): Promise<Import[]> => {
  const input: CreateDownloadImportUrLsInput = {
    ids: Array.isArray(ids) ? ids : [ids],
  };
  const res = await runCreateDownloadImportURLs({ input });
  const selected = createDownloadImportUrLsResponseSelector(res);

  return selected;
};

export const useCreateDownloadImportURLs = createGraphApiHook(createDownloadImportURLs);

export default createDownloadImportURLs;

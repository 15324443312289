import React, { ReactNode } from 'react';

import Portal from '@/components/Portal';

type FooterProps = {
  children?: ReactNode;
};
const Footer = ({ children }: FooterProps): ReactNode => (
  <Portal.When container={document.querySelector('#RouteContent-Footer')}>{children}</Portal.When>
);

export default Footer;

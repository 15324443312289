import './styles.scss';

import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import { Datetime, Validation, getRangeHandleColor } from '@/utils';
import React, { useMemo } from 'react';

import { Dayjs } from 'dayjs';
import EditModal from '@/components/EditModal/new';
import FormField from '@/components/FormField';
import RangeSlider from '@/components/RangeSlider';
import TimeInput from '../../../components/TimeInput';
import { getHoursAndMinutesFromMinutes } from '@/utils/numbers';
import { sortProperties } from '@/utils/arrays';
import useForm from '@/hooks/useForm';

type Bucket = { name: string; time: number };
type State = {
  buckets: Array<Bucket>;
};

const EOD_MINUTES = 1439;

const EditBucketsModal = ({
  show,
  value = [],
  onHide,
  onSubmit,
}: {
  show: boolean;
  value?: Array<Bucket>;
  onHide: () => void;
  onSubmit: (buckets: Array<Bucket>) => void;
}) => {
  const initEditBucketsState: State = { buckets: value };
  const [state, onChange, setState] = useForm<State>(initEditBucketsState);
  const { buckets } = state;

  const onCreateBucket = (): void =>
    setState((current: State): State => {
      const buckets = [...(current.buckets || [])];
      return {
        ...current,
        buckets: [
          ...buckets,
          {
            name: `New Shift ${buckets.length + 1}`,
            time: Validation.isNumber(buckets.at(-1)?.time) ? (buckets?.at(-1)?.time + 60) % EOD_MINUTES : 0,
          },
        ],
      };
    });
  const onTimeInputChange =
    (bucket: Bucket, index: number): ((dates: [Dayjs, Dayjs], dateStrings: [string, string], info: Record<string, any>) => void) =>
    (_dates: [Dayjs, Dayjs], times: [string, string], info: Record<string, any>): void => {
      const [startTime, endTime] = times;
      if (!startTime || !endTime) return;
      setState((current: State): State => {
        const buckets = [...(current?.buckets || [])];
        buckets[index].time = convertHourMinutesToBucketTime(startTime);
        let endIndex = index + 1;
        if (!buckets?.[endIndex] || index === buckets.length - 1) endIndex = 0;
        let newEndTime = convertHourMinutesToBucketTime(endTime);
        if (info?.range === 'start') newEndTime += 1; // Add one minute since we're setting the value to minus 1 minute
        buckets[endIndex].time = newEndTime;
        return { ...current, buckets: sortProperties(buckets, ['time'], (a: Bucket, b: Bucket): number => a.time - b.time) };
      });
    };

  const handleSubmit = (): void => {
    setState((current) => {
      onSubmit(current.buckets);
      return current;
    });
  };
  const handleChangeTimes = (value: number[]): void => {
    setState(
      (current: State): State => ({
        ...current,
        buckets: current.buckets.map((bucket: Bucket, index: number): Bucket => ({ ...bucket, time: value[index] })),
      })
    );
  };

  // event handlers to sync different time inputs
  const onSyncTimeRangeInputs = (event): void => {
    const element = getSyncTimeInputNode(event);
    if (!element) return;
    element.className += ' ant-picker-input-active';
  };
  const onUnsyncTimeRangeInputs = (event): void => {
    const element = getSyncTimeInputNode(event);
    if (!element) return;
    element.className = element.className
      .split(' ')
      .filter((className: string): boolean => className !== 'ant-picker-input-active')
      .join(' ');
  };

  const Footer = useMemo(
    () => (
      <Modal.Footer>
        <Button variant="secondary" onClick={onCreateBucket}>
          ADD SHIFT
        </Button>
        <Button variant="dark" onClick={() => setState(initEditBucketsState)}>
          RESET TO DEFAULT
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          SAVE
        </Button>
      </Modal.Footer>
    ),
    []
  );

  return (
    <EditModal
      className="EditBucketsModal"
      show={show}
      onHide={onHide}
      title="Shifts"
      size="lg"
      options={{
        Footer: Footer,
      }}
    >
      <Row>
        <Col className="flex-grow-1">
          <div className="">
            <RangeSlider
              min={0}
              max={24 * 60 - 1}
              tickSize={120}
              stepSize={(24 * 60) / (24 * 4)}
              value={buckets.map((bucket: Bucket): number => bucket?.time)}
              tickLabel={(value: number): string =>
                getHoursAndMinutesFromMinutes(value)
                  .map((val: number): string => `${val}`.padStart(2, '0'))
                  .join(':')
              }
              handleLabel={(_value: number, index: number): number => index + 1}
              handleTooltip={(value: number, index: number): string =>
                `${buckets?.[index]?.name}: ${getHoursAndMinutesFromMinutes(value)
                  .map((val: number): string => `${val}`.padStart(2, '0'))
                  .join(':')}`
              }
              onChange={({ target: { value } }: { target: { value: number[] } }): void => handleChangeTimes(value)}
            />
          </div>
        </Col>
      </Row>
      {buckets.map((bucket: any, index: number): JSX.Element => {
        const nextBucket = buckets.at((index + 1) % buckets.length);
        return (
          <Row className="mt-2 d-flex align-items-center justify-content-center" key={index}>
            <Col xs={7}>
              <FormField
                name={`buckets.${index}.name`}
                label={
                  <Badge className="rounded-circle" bg={getRangeHandleColor(index)}>
                    {index + 1}
                  </Badge>
                }
                value={bucket.name || ''}
                onChange={onChange}
                inline
                condensed
              />
            </Col>
            <Col>
              <TimeInput.Range
                name={`buckets.${index}.time`}
                className="px-3 border-0"
                value={[
                  new Datetime().setTime(convertBucketTimeToHourMinutes(bucket.time)).asDayjs(),
                  new Datetime()
                    .setTime(convertBucketTimeToHourMinutes(nextBucket.time === 0 ? EOD_MINUTES : nextBucket.time - 1))
                    .asDayjs(),
                ]}
                onCalendarChange={onTimeInputChange(bucket, index)}
                onFocus={onSyncTimeRangeInputs}
                onBlur={onUnsyncTimeRangeInputs}
                needConfirm={false}
              />
            </Col>
            <Col xs="auto">
              <Button
                tabIndex={-1}
                variant="outline-grey-subtle"
                onClick={(): void =>
                  setState(
                    (current: State): State => ({
                      ...current,
                      buckets: current.buckets.filter((_bucket: Bucket, b: number): boolean => b !== index),
                    })
                  )
                }
              >
                <i className="sv sv-trash2 {font-size:1.5rem;} text-danger" />
              </Button>
            </Col>
          </Row>
        );
      })}
    </EditModal>
  );
};

// helper method to get the ant-picker-input of the next/prev element based on what's currently focused
const getSyncTimeInputNode = (event): HTMLElement => {
  // get time range nodes rendered
  const timeRangePickerNodeList = Array.from(document.querySelectorAll('.EditBucketsModal .ant-picker.ant-picker-range'));
  // get the index of the focused element
  let targetPosition: number = timeRangePickerNodeList.findIndex((el: Element): boolean => el.contains(event.target));
  // determine whether we get the next or previous element based on the target position
  const isStart = event.target.getAttribute('date-range') === 'start';
  if (isStart) targetPosition = targetPosition - 1;
  else targetPosition = targetPosition + 1;

  // add focused ant focus class to element
  const element = timeRangePickerNodeList
    .at(targetPosition % timeRangePickerNodeList.length)
    .querySelector(`.ant-picker-input > input[date-range="${isStart ? 'end' : 'start'}"]`)?.parentElement;

  if (!element) console.log('no element');

  return element;
};
const convertBucketTimeToHourMinutes = (time: number): string =>
  Validation.isNumber(time)
    ? getHoursAndMinutesFromMinutes(time)
        .map((val: number): string => `${val}`.padStart(2, '0'))
        .join(':')
    : undefined;

const convertHourMinutesToBucketTime = (time: string): number => {
  const [hour = '00', minutes = '00'] = time.split(':');
  return parseInt(hour) * 60 + parseInt(minutes);
};

export default React.memo(EditBucketsModal);

import NavigationDropdown, { NavigationDropdownProps } from '@/components/NavigationDropdown';

import { DropdownItem } from '@/components/Dropdown';
import { ReactNode } from 'react';
import { reportTypes } from '@/pages/Reports';

export type ReportTypeDropdownProps = Omit<NavigationDropdownProps, 'items'>;

const reportTypeDropdownItems: DropdownItem[] = Object.entries(reportTypes).map(
  ([value, [label]]: [string, string[]]): DropdownItem => ({ label, value: `/reports/${value}` })
);

const ReportTypeDropdown = (props: ReportTypeDropdownProps): ReactNode => (
  <NavigationDropdown name="reportTypeNavigationDropdown" {...props} items={reportTypeDropdownItems} />
);

export default ReportTypeDropdown;

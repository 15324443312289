import LoadingSpinner, { LoadingBlur } from '@/components/LoadingSpinner';
import React, { ReactNode, createContext, useContext, useMemo, useRef, useState } from 'react';
import SelectPolicyType, { PolicyTypeEnum } from './components/SelectPolicyType';
import './styles.scss';

import { Button, Col, Container, Row } from 'react-bootstrap';
import Filters from '@/components/Filters';
import PageInfo from '@/components/PageInfo';
import PunchPolicyDetails from './components/PunchPolicyDetails';
import { Location, PunchPolicy } from '@/models/gen/graphql';
import { refetchLocationWithPunchPolicySearch } from './api/services/searchLocationsWithPunchPolicy';
import { useSearchParams } from 'react-router-dom';
import VirtualList from '@/components/VirtualList';
import { getClasses } from '@/utils/strings';
import { Datetime, createNotification, getDiff, onEnter, queryInput, stringify, timeToMinutes } from '@/utils';
import { Toast } from '@/models';
import Footer from './components/Footer';
import { updatePunchPolicyBulk } from './api/services/updatePunchPolicyBulk';
import { GraphApiResponse } from '@/api/core';
import { createPunchPolicyBulk } from './api/services/createPunchPolicyBulk';
import { deletePunchPolicyBulk } from './api/services/deletePunchPolicyBulk';
import { TIME_FORMAT_FULL, TODAY } from '@/constants';
import FormField from '@/components/FormField';
import FormButton from '@/components/FormButton';
import SelectLocation from '@/components/SelectLocation';
import SelectAirport from '../../components/SelectAirport';
import { Middle } from '../../components/Align';

export type FetchPunchPolicyInput = {
  type?: PolicyTypeEnum;
  eventStartDatetime?: string;
  airportCode?: string;
};
export type PunchPolicyState = {
  openLocations: string[];
  locations: Partial<Location>[];
  original: Location[];
  type: PolicyTypeEnum;
  lastQuery: FetchPunchPolicyInput;
  loading: boolean;
  search?: string;
};
const initPunchPolicyState: PunchPolicyState = {
  openLocations: [],
  locations: [],
  original: [],
  type: PolicyTypeEnum.punchPolicies,
  lastQuery: null,
  loading: false,
  search: '',
};
const PunchPolicyContext = createContext<[PunchPolicyState, React.Dispatch<React.SetStateAction<PunchPolicyState>>]>([
  initPunchPolicyState,
  (): void => {},
]);
export const usePunchPolicyContext = (): [PunchPolicyState, React.Dispatch<React.SetStateAction<PunchPolicyState>>] =>
  useContext(PunchPolicyContext);

const PunchPolicy = (): JSX.Element => {
  const [params, setParams] = useSearchParams();
  const [state, setState] = useState<PunchPolicyState>({
    ...initPunchPolicyState,
    type: (params.get('type') as PolicyTypeEnum) || PolicyTypeEnum.punchPolicies,
  });
  const { lastQuery, loading, search, locations, original } = state;
  const lastInput = useRef<FetchPunchPolicyInput>({});

  const context = useMemo((): [PunchPolicyState, React.Dispatch<React.SetStateAction<PunchPolicyState>>] => [state, setState], [state]);

  // const { filteredRows: filteredLocations, onSearch } = useVirtualTable(setState, { rows: locations, search });
  const onSearch = (search: string): void => {
    setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, search }));
    document.getElementById('filters-searchbar')?.focus();
  };
  const filteredLocations = useMemo(
    (): Partial<Location>[] =>
      search
        ? locations?.filter(({ displayName }: Partial<Location>): boolean => displayName?.toLowerCase().includes(search?.toLowerCase()))
        : locations,
    [locations, search]
  );

  const timeWrapPunchPolicies = (policies: PunchPolicy[]): PunchPolicy[] => {
    return policies
      .sort((a: PunchPolicy, b: PunchPolicy): number => timeToMinutes(a.startTime) - timeToMinutes(b.startTime))
      .map((policy: PunchPolicy, i: number): PunchPolicy => {
        const nextPolicy = policies[(i + 1) % policies.length];
        const result = {
          ...policy,
          startTime: new Datetime().setTime(policy.startTime).asDayjs().set('seconds', 0).format(TIME_FORMAT_FULL),
          endTime: new Datetime().setTime(nextPolicy?.startTime).asDayjs().set('seconds', 59).format(TIME_FORMAT_FULL),
        };
        if (result.eventStartDatetime) result.eventStartDatetime = new Datetime(result.eventStartDatetime).startOf('day').datetimeInput;
        if (result.eventEndDatetime) result.eventEndDatetime = new Datetime(result.eventEndDatetime).endOf('day').datetimeInput;
        return result;
      });
  };

  const fetchPunchPolicies = async (values: FetchPunchPolicyInput = lastInput.current): Promise<void> => {
    const { eventStartDatetime = TODAY, airportCode } = values;
    const type = values?.type || state?.type || PolicyTypeEnum.punchPolicies;
    const payload = { type, eventStartDatetime, airportCode };
    try {
      setParams({ type: state?.type || PolicyTypeEnum.punchPolicies });
      setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, loading: true }));
      lastInput.current = payload;
      const { rows } = await refetchLocationWithPunchPolicySearch(payload);
      setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, locations: rows, original: rows }));
    } catch (err) {
      createNotification(err?.message || err, Toast.Type.DANGER, 'Search Locations With Punch Policy');
    } finally {
      setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, loading: false, lastQuery: payload }));
    }
  };

  const updatePunchPolicies = async (): Promise<void> => {
    try {
      setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, loading: true }));
      const newLocations: Location[] = stringify.parse(
        state.locations.filter((location: Location): boolean => !!location?.id && !!location?.createdAt)
      );
      const [{ locations: diff }] = getDiff({ locations: stringify.parse(original) }, { locations: newLocations });
      const locationIndexes = Object.keys(diff || {});

      // Update punch policy times to ensure no gaps as well as proper format
      locationIndexes.forEach((index: string): void => {
        const location = newLocations[index];
        const policies = location?.punchPolicies || [];

        if (policies.length) {
          location.punchPolicies = timeWrapPunchPolicies(policies);
        }
      });

      const previous = locationIndexes.flatMap((index: string): PunchPolicy => original[index].punchPolicies || []);
      const changes = locationIndexes.flatMap((index: string): PunchPolicy => newLocations[index].punchPolicies || []);
      const updates = changes.filter(
        (policy: PunchPolicy): boolean =>
          policy?.id &&
          !stringify.compare(
            policy,
            previous.find(({ id }: PunchPolicy): boolean => id === policy.id)
          )
      );
      const deletions = previous
        .filter(({ id: previousId }: PunchPolicy): boolean => !changes.map(({ id }: PunchPolicy): string => id).includes(previousId))
        .map(({ id }: PunchPolicy): string => id);
      const additions = [
        ...changes.filter(
          ({ id: changeId }: PunchPolicy): boolean => !previous.map(({ id }: PunchPolicy): string => id).includes(changeId)
        ),
        ...state.locations
          .filter((location: Location): boolean => location?.id && !location?.createdAt)
          .flatMap((location: Location): PunchPolicy[] => timeWrapPunchPolicies(location?.punchPolicies)),
      ];
      const transaction = [];
      if (updates.length) transaction.push(async (): Promise<GraphApiResponse> => updatePunchPolicyBulk(...updates));
      if (additions.length) transaction.push(async (): Promise<PunchPolicy[]> => createPunchPolicyBulk(...additions));
      if (deletions.length) transaction.push(async (): Promise<GraphApiResponse> => deletePunchPolicyBulk(...deletions));
      if (transaction.length) await Promise.all(transaction.map((fn: any): Promise<any> => fn()));
    } catch (err) {
      createNotification(err?.message || err, Toast.Type.DANGER, 'Update Punch Policies');
    } finally {
      await fetchPunchPolicies();
      setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, loading: false }));
    }
  };

  const classes = getClasses('PunchPolicy');

  const punchPolicyRenderer = (index: number, data: Partial<Location>): ReactNode => (
    <>
      {!data?.id && (
        <Row className="AddedLocation">
          <Col>
            <FormField
              key={index}
              name="selectLocation"
              label="Location name:"
              onChange={(event) => {
                const id = event?.target?.value;
                const displayName = event?.target?.innerHTML;
                setState((current: PunchPolicyState): PunchPolicyState => {
                  const newLocations = stringify.parse(current?.locations);
                  newLocations.splice(index, 1, { id, displayName, punchPolicies: [] });
                  return {
                    ...current,
                    locations: newLocations,
                  };
                });
              }}
              placeholder="Select Location"
              valid={false}
              query={{ airportCode: queryInput(lastQuery?.airportCode || null) }}
              filter={({ value }: { value: string }) => {
                const locationIds = locations.map((location: Location): string => location?.id);
                return !locationIds.includes(value);
              }}
              options={{
                input: {
                  as: SelectLocation,
                },
                group: {
                  className: 'pt-0',
                },
              }}
              condensed
            />
          </Col>
        </Row>
      )}
      {data?.id && (
        <PunchPolicyDetails
          key={index}
          id={data?.id}
          name={data?.displayName}
          policies={data?.punchPolicies || []}
          isSpecialEvent={lastQuery?.type === PolicyTypeEnum.specialEvents}
        />
      )}
    </>
  );

  return (
    <PunchPolicyContext.Provider value={context}>
      {loading && (
        <PageInfo>
          <LoadingSpinner size="sm" />
        </PageInfo>
      )}
      <Filters
        onSubmit={fetchPunchPolicies}
        onReset={(): void => setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, search: '' }))}
        submitOnMount
        primary={({ values, onChange }): JSX.Element => (
          <>
            <SelectPolicyType
              name="type"
              value={state?.type || PolicyTypeEnum.punchPolicies}
              onChange={(event) => {
                setState((current: PunchPolicyState): PunchPolicyState => ({ ...current, type: event.target.value }));
                fetchPunchPolicies({ ...values, type: event.target.value });
              }}
            />
            {state?.type === PolicyTypeEnum.specialEvents && (
              <FormField
                name="eventStartDatetime"
                type="date"
                value={values.eventStartDatetime || TODAY}
                onChange={onChange}
                options={{
                  input: {
                    className: 'punch-policy-start-date',
                  },
                }}
                condensed
              />
            )}
            <SelectAirport name="airportCode" placeholder="City" value={values.airportCode} onChange={onChange} searchable />
          </>
        )}
        controls={({ values: { search }, onChange }): JSX.Element => (
          <>
            <FormField
              prepend={<i className="sv sv-magnifier fs-4" />}
              id={'filters-searchbar'}
              name="search"
              value={search || ''}
              onChange={onChange}
              onBlur={(): void => onSearch(search)}
              onKeyDown={onEnter((): void => onSearch(search))}
              placeholder="Search"
              style={{ maxWidth: 300 }}
              condensed
              inline
            />
          </>
        )}
        alternate={(): JSX.Element => (
          <>
            {state?.type === PolicyTypeEnum.specialEvents && (
              <FormButton
                icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
                name="ADD_SPECIAL_EVENT"
                variant="outline-gray"
                onClick={(): void =>
                  setState(
                    (current: PunchPolicyState): PunchPolicyState => ({
                      ...current,
                      locations: [{}, ...current.locations],
                    })
                  )
                }
              >
                Add Special Event
              </FormButton>
            )}
          </>
        )}
      />
      <Container className={classes} fluid>
        <LoadingBlur loading={loading} />
        {!loading && !filteredLocations?.length && (
          <Middle.Center className="pt-5">
            <h3 className="d-flex flex-column gap-3 text-gray-subtle text-center">
              <i className="sv sv-map-marker fa-2x" />
              <span>No Locations Found</span>
            </h3>
          </Middle.Center>
        )}
        <VirtualList data={filteredLocations} itemRenderer={punchPolicyRenderer} />
      </Container>
      <Footer>
        <div className="w-100 d-flex justify-content-end gap-2 border-top p-2">
          <Button variant="secondary" onClick={(): Promise<void> => fetchPunchPolicies()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(): Promise<void> => updatePunchPolicies()}
            disabled={loading || stringify.compare(original, locations)}
          >
            Save
          </Button>
        </div>
      </Footer>
    </PunchPolicyContext.Provider>
  );
};

export default PunchPolicy;

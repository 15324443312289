import { DownloadImportUrlResponse, RunDownloadImportInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { RunDownloadImportsDocument } from '../../queries';
import { Toast } from '../../../models';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type RunDownloadImportsGraphApiResponse = GraphApiResponse<typeof RunDownloadImportsDocument>;

const title = 'Download Imports';

const runDownloadImportsResponseSelector = (res: RunDownloadImportsGraphApiResponse): Array<DownloadImportUrlResponse> =>
  !res.errors ? res?.runDownloadImport?.output : undefined;

const [runRunDownloadImports] = graphApi(RunDownloadImportsDocument, {
  onError: (res: RunDownloadImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runDownloadImports = async (importId: string): Promise<Array<DownloadImportUrlResponse>> => {
  const input: RunDownloadImportInput = { importIds: [importId] };
  const response = await runRunDownloadImports({ input });
  const selected = runDownloadImportsResponseSelector(response);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export const useRunDownloadImports = createGraphApiHook(runDownloadImports);

export default runDownloadImports;

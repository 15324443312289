import './styles.scss';

import { Badge, Button, Collapse, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { SortDirectionEnum, Trip, TripTableFormatEnum } from '@/models/gen/graphql';
import { TODAY, TODAY_EOD } from '@/constants';
import { getResultFromState, onEnter, zeroPadFlightNumber } from '@/utils';
import { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import useTripTableState, { TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ClientDropdown from '@/components/ClientDropdown';
import DateRangePicker from '@/components/DateRangePicker';
import FormButton from '@/components/FormButton';
import HasPermission from '@/components/HasPermission';
import Input from '@/components/Input';
import LocationQueryDropdown from '@/components/LocationDropdown';
import QueryFilters from '@/components/QueryFilters';
import RateValuesDropdown from '@/components/RateValuesDropdown';
import React from 'react';
import TripActionsDropdown from '@/features/Trips/components/TripActionsDropdown';
import { TripTypeDropdownMulti } from '@/components/TripTypeDropdown';
import TripsTableFormatDropdown from '@/components/TripsTableFormatDropdown';
import { createComponentQueryState } from '@/state';

export type TripsFiltersState = {
  from: string;
  to: string;
  airportCode: string[];
  payerProviderId: string;
  doLocationId: string[];
  puLocationId: string[];
  flightNumber: string;
  type: string[];
  servicerIataAirlineCode: string;
  search: string;
  rateAmount: string;
  format: TripTableFormatEnum;
};
export const initTripsFiltersState: TripsFiltersState = {
  servicerIataAirlineCode: '',
  airportCode: [],
  payerProviderId: '',
  doLocationId: [],
  flightNumber: '',
  from: TODAY,
  puLocationId: [],
  to: TODAY_EOD,
  type: [],
  search: '',
  rateAmount: '',
  format: TripTableFormatEnum.Current,
};

const useTripFilters = createComponentQueryState(initTripsFiltersState);

type TripFiltersProps = {
  onCreate?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSubmit: (filters: TripsFiltersState) => Promise<void>;
  onReset?: (filters: TripsFiltersState) => Promise<void>;
  onSearch?: (search: string) => void;
  disabled?: boolean;
  sorting?: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>;
};
export type TripFiltersRefMethods = {
  quickFilter: (input: TripsFiltersState | ((current: TripsFiltersState) => TripsFiltersState)) => void;
};

const TripFilters = (
  { onCreate, onDelete, onEdit, onSubmit, onReset, onSearch, disabled, sorting }: TripFiltersProps,
  ref: React.ForwardedRef<TripFiltersRefMethods>
): React.JSX.Element => {
  const [state, setState, saveState] = useTripFilters(({ state, setState, saveState }) => [state, setState, saveState]);
  const selected = useTripTableState(({ state }) => state.selected);
  const [lastQuery, setLastQuery] = useState(state);
  const [expanded, setExpanded] = useState(true);

  const handleChange = useCallback(
    (name: string) =>
      (value: unknown): void =>
        setState((current) => ({ ...current, [name]: value })),
    [setState]
  );
  const navigate = useNavigate();

  const handleSubmit = useCallback((): void => {
    saveState();
    setLastQuery(state);
    saveState();
    onSubmit(state);
  }, [state, saveState, onSubmit]);

  // This is exposed via ref so the parent can utilize shortcut functionality
  const handleQuickFilter = useCallback(
    (input: TripsFiltersState | ((current: TripsFiltersState) => TripsFiltersState)): void => {
      const result = getResultFromState(input, state);
      setState(result);
      setLastQuery(result);
      saveState();
      onSubmit(result);
    },
    [state, saveState, onSubmit, setState]
  );

  const handleReset = useCallback((): void => {
    setState(initTripsFiltersState);
    setLastQuery(initTripsFiltersState);
    saveState();
    onReset(initTripsFiltersState);
  }, [setState, saveState, onReset]);

  useImperativeHandle(ref, () => ({ quickFilter: handleQuickFilter }), [handleQuickFilter]);

  const onExpand = (): void => setExpanded((current: boolean): boolean => !current);
  const handleSearch = (): void => onSearch(state.search);

  useEffect((): void => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const selectedForDelete = useMemo(
    (): Trip[] => Array.from(selected.values()).filter((trip: Trip): boolean => !trip.deletedAt),
    [selected]
  );

  return (
    <QueryFilters>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <QueryFilters.Control>
            <DateRangePicker
              name="dates"
              value={[state.from, state.to] as any}
              onChange={(event) => {
                const [from, to] = event.target.value?.split?.(' - ') || [null, null];
                handleChange('from')(from);
                handleChange('to')(to);
              }}
            />
          </QueryFilters.Control>
          <QueryFilters.Input>
            <AirportGroupDropdown
              name="airportCode"
              value={state.airportCode}
              onChange={handleChange('airportCode')}
              options={{ locale: { 'Select...': 'Airport' } }}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <TripsTableFormatDropdown
              name="format"
              value={state.format || initTripsFiltersState?.format}
              onChange={handleChange('format')}
              options={{
                showClearButton: false,
              }}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <Input
              name="flightNumber"
              type="number"
              placeholder="Flight"
              value={state.flightNumber || ''}
              onChange={(val) => handleChange('flightNumber')(val.replace(/\D/g, ''))}
              onBlur={() =>
                handleChange('flightNumber')(
                  state.flightNumber.length > 0 ? zeroPadFlightNumber(parseInt(state.flightNumber)) : state.flightNumber
                )
              }
              onKeyDown={onEnter(handleSubmit)}
              maxLength={4}
            />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <Button variant="outline-light" className="border-white" onClick={onExpand}>
              {!expanded ? 'More' : 'Less'}
            </Button>
            <Button variant="success" className="border-white" onClick={handleSubmit}>
              GO
            </Button>
            <Button variant="outline-light" onClick={handleReset}>
              Reset
            </Button>
          </QueryFilters.Control>
          <QueryFilters.Control>
            <InputGroup>
              <InputGroup.Text style={{ backgroundColor: 'transparent', borderColor: '#fff', color: '#fff' }}>
                <i className="sv sv-magnifier fs-4" />
              </InputGroup.Text>
              <Input
                id={'filters-searchbar'}
                name="search"
                value={state.search || ''}
                onChange={handleChange('search')}
                onBlur={handleSearch}
                onKeyDown={onEnter(handleSearch)}
                placeholder="Search"
              />
            </InputGroup>
          </QueryFilters.Control>
        </div>
        <Collapse in={expanded}>
          <div>
            <div className="d-flex gap-2">
              <QueryFilters.Input>
                <ClientDropdown
                  name="payerProviderId"
                  value={state.payerProviderId || initTripsFiltersState?.payerProviderId}
                  onChange={handleChange('payerProviderId')}
                  options={{ locale: { 'Select...': 'Clients' } }}
                />
              </QueryFilters.Input>
              {HasPermission.check('allowSearchTripsByRate') && (
                <QueryFilters.Input>
                  <RateValuesDropdown name="rateAmount" placeholder="Rate" value={state.rateAmount} onChange={handleChange('rateAmount')} />
                </QueryFilters.Input>
              )}
              <QueryFilters.Input>
                <AirlineIataDropdown
                  name="servicerIataAirlineCode"
                  value={state.servicerIataAirlineCode || initTripsFiltersState?.servicerIataAirlineCode}
                  onChange={handleChange('servicerIataAirlineCode')}
                />
              </QueryFilters.Input>
              <QueryFilters.Input>
                <TripTypeDropdownMulti name="type" value={state.type} onChange={handleChange('type')} />
              </QueryFilters.Input>
              <QueryFilters.Input>
                <LocationQueryDropdown
                  name="puLocationId"
                  value={state.puLocationId}
                  onChange={handleChange('puLocationId')}
                  airports={state.airportCode?.length ? state?.airportCode : null}
                  options={{
                    showAirportPrefix: false,
                    locale: { 'Select...': 'Pickups' },
                  }}
                />
              </QueryFilters.Input>
              <QueryFilters.Input>
                <LocationQueryDropdown
                  name="doLocationId"
                  value={state.doLocationId}
                  onChange={handleChange('doLocationId')}
                  airports={state.airportCode?.length ? state.airportCode : null}
                  options={{
                    showAirportPrefix: false,
                    locale: { 'Select...': 'Dropoffs' },
                  }}
                />
              </QueryFilters.Input>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="d-flex flex-grow-1 justify-content-end gap-2">
        <QueryFilters.Control>
          <Link to="/trips">
            <FormButton variant="secondary">Return to v1</FormButton>
          </Link>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
            name="CREATE_TRIP"
            variant="outline-secondary-subtle"
            onClick={onCreate}
          >
            Create Trip
          </FormButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            icon={<i className="sv sv-download2 {font-size:1.5rem;}" />}
            variant="outline-secondary-subtle"
            onClick={(): void => navigate('/manifests?type=MANIFEST')}
          >
            Import
          </FormButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
            name="EDIT_TRIP"
            variant="outline-secondary-subtle"
            onClick={onEdit}
            disabled={!selected.size}
          >
            {selected.size > 1 ? 'Bulk Edit' : 'Edit'}
            {selected.size > 1 && (
              <Badge className="ms-2" pill bg="success">
                {selected.size}
              </Badge>
            )}
          </FormButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            name="DELETE_TRIP"
            disabled={!selectedForDelete.length}
            onClick={onDelete}
            variant="outline-danger"
            tooltip="Delete Trip(s)"
            icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
          />
        </QueryFilters.Control>
        <QueryFilters.Control>
          <TripActionsDropdown
            format={lastQuery.format}
            from={lastQuery.from}
            to={lastQuery.to}
            airportCode={lastQuery.airportCode}
            payerProviderId={lastQuery.payerProviderId}
            puLocationId={lastQuery.puLocationId}
            doLocationId={lastQuery.doLocationId}
            rateAmount={lastQuery.rateAmount}
            servicerIataAirlineCode={lastQuery.servicerIataAirlineCode}
            type={lastQuery.type}
          />
        </QueryFilters.Control>
      </div>
    </QueryFilters>
  );
};

export default React.memo(React.forwardRef(TripFilters));

import { GraphApiMethod, GraphApiResponse } from '@/api/core';
import { HandleErrorInput, handleError, queryInput } from '@/utils/custom';
import { UpdateBufferTimeInput, UpdateBufferTimeValuesInput } from '@/models/gen/graphql';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { UpdateBufferTimeBulkDocument } from '@/api/queries';
import graphApi from '@/api';

export type BufferTimeUpdate = Partial<UpdateBufferTimeValuesInput> & { id: string };
// Global Service Variables
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Update Buffer Time Setting' } });

// Service Function Signature
type ServiceMethodPayload = BufferTimeUpdate[];
interface ServiceMethod {
  (payload: ServiceMethodPayload): Promise<GraphApiResponse>;
}

// UpdateBufferTimeBulk Service
export const { updateBufferTimeBulk, useUpdateBufferTimeBulk } = ((): {
  updateBufferTimeBulk: ServiceMethod;
  useUpdateBufferTimeBulk: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [mutate] = graphApi(UpdateBufferTimeBulkDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<typeof UpdateBufferTimeBulkDocument>): ServiceMethod =>
      async (payload: ServiceMethodPayload): Promise<GraphApiResponse> => {
        const updates: UpdateBufferTimeInput[] = payload.map(
          ({ id, ...update }): UpdateBufferTimeInput => ({
            query: [{ id: queryInput(id) }],
            value: { ...update },
          })
        );
        const res = await fn({ input: { updates } });
        return res;
      }
  );

  const useService = createGraphApiHook(mutate, { bubble: true });
  return {
    updateBufferTimeBulk: mutate,
    useUpdateBufferTimeBulk: useService,
  };
})();

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvoicePreviewHeader .ant-picker-input input {
  font-size: 1rem !important;
  color: var(--bs-primary);
}
.InvoicePreviewHeader li > h6 {
  display: flex;
}

.InvoicePreviewTotals {
  position: relative;
}
.InvoicePreviewTotals .summary-row {
  height: 2.5rem;
  background: white;
  border-bottom: 1px solid #e0e0e0;
}
.InvoicePreviewTotals:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba(200, 200, 210, 0), rgba(200, 200, 210, 0.25));
  padding-right: 50px;
  pointer-events: none;
}
.InvoicePreviewTotals .discount::before {
  content: "-";
  font-size: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/InvoicePreview/styles.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;EACA,wBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;;AAIA;EACE,kBAAA;AADF;AAEE;EACE,cAAA;EACA,iBAAA;EACA,gCAAA;AAAJ;AAGE;EAEE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,8EAAA;EACA,mBAAA;EACA,oBAAA;AAFJ;AAKE;EACE,YAAA;EACA,kBAAA;AAHJ","sourcesContent":[".InvoicePreviewHeader .ant-picker-input input {\n  font-size: 1rem !important;\n  color: var(--bs-primary);\n}\n.InvoicePreviewHeader li > h6 {\n  display: flex;\n}\n\n.InvoicePreviewTotals {\n  position: relative;\n}\n.InvoicePreviewTotals .summary-row {\n  height: 2.5rem;\n  background: white;\n  border-bottom: 1px solid #e0e0e0;\n}\n.InvoicePreviewTotals:after {\n  content: \"\";\n  position: absolute;\n  bottom: 100%;\n  left: 0;\n  z-index: 500;\n  width: 100%;\n  height: 60px;\n  background: linear-gradient(rgba(200, 200, 210, 0), rgba(200, 200, 210, 0.25));\n  padding-right: 50px;\n  pointer-events: none;\n}\n.InvoicePreviewTotals .discount::before {\n  content: \"-\";\n  font-size: 1.25rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

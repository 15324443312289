import { GetSummaryReportSnapshotInput } from '../../models/gen/graphql';
import GraphApi from '../../models/GraphApi';
import gql from 'graphql-tag';

const getSummaryReportSnapshot: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (id: string): Promise<any> => {
      const input: GetSummaryReportSnapshotInput = { id };
      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): any => data?.getSummaryReportSnapshot || {},
  query: gql`
    query GetSummaryReportSnapshot($input: GetSummaryReportSnapshotInput!) {
      getSummaryReportSnapshot(input: $input) {
        error {
          code
          debug
          message
        }
        tables {
          name
          count
          total
          average
          percentage
          children {
            name
            count
            total
            average
            percentage
            children {
              name
              count
              total
              average
              percentage
              children {
                name
                count
                total
                average
                percentage
              }
            }
          }
          values {
            amount
            count
            total
          }
        }
      }
    }
  `,
};

export default getSummaryReportSnapshot;

import React, { createContext, useEffect, useMemo, useState } from 'react';

import Logger from '../utils/logs';
import { camelCase } from '../utils';

/* useActions
  This hook can be used to create very specific hooks for components
  that come bundled with their own actions to cater to precise state changes
  and pre-defined callbacks to minimise code duplication.

  It can be used within a component immediately, or to create a standalone
  hook in a separate file to be reused in multiple components.

  The second piece of logic added to this hook uses the hook's initial state
  to automatically create setter methods for each of the initial state's
  keys.

  Examples:
    Immediately called in a component:
    ./myComponent.js
      ...
      const [count, { increment, decrement }] = useActions(0, setCount => ({
        increment: amount => setCount(current => current += amount),
        decrement: amount => setCount(current => current -= amount),
      }));
      ...
      <button onClick={() => decrement(1)}>Subtract 1</button>
      <button onClick={() => increment(1)}>Add 1</button>
      ...

    Or as a standalone hook (Preferred):
    ./useCounter.js
      ...
      const useCounter = initCount => useActions(initCount, setCount => ({
        increment: amount => setCount(current => current += amount),
        decrement: amount => setCount(current => current -= amount),
      }));
      ...
    ./myComponent.js
      ...
      const [count, { increment, decrement }] = useCounter(0);
      ...
      <button onClick={() => decrement(1)}>Subtract 1</button>
      <button onClick={() => increment(1)}>Add 1</button>
      ...
*/

const ActionsContext = createContext();
const useActions = (initState, actionMemo) => {
  const [state, setState] = useState(initState);

  const actions = useMemo(() => actionMemo(setState), [actionMemo, setState]);
  const methodName = actions.__name || 'useActions';
  const bundledSetState = useMemo(() => {
    const wrappedSetState = setState;
    if (typeof initState === 'object' && !Array.isArray(initState)) {
      Object.keys(initState).forEach(
        (key) =>
          (actions[camelCase(`set ${key}`)] =
            actions[camelCase(`set ${key}`)] ||
            ((val) => setState((current) => ({ ...current, [key]: typeof val === 'function' ? val(current[key]) : val }))))
      );
    }
    Object.entries(actions).forEach(
      ([name, fn]) =>
        (wrappedSetState[name] = wrapFunction(logStart(methodName, name), fn, logFinish(methodName, name), logError(methodName, name)))
    );
    return wrappedSetState;
  }, [actions, setState, initState]);
  const createProvider = (Context = ActionsContext) =>
    React.memo(({ children }) => <Context.Provider value={[state, bundledSetState]}>{children}</Context.Provider>);

  useEffect(() => {
    Logger.of('useActions').info('USING', `${methodName}`);
  }, []);

  return [state, bundledSetState, createProvider];
};

const logStart =
  (methodName, name) =>
  (...args) => {
    Logger.of(methodName).info('STARTING', `${name}...`);
    Logger.of(methodName).info(args.length, 'ARGUMENT(S):', `(${args.map((arg) => typeof arg).join(', ')})`);
    args.forEach((arg) => Logger.of(methodName).info(typeof arg === 'string' ? `"${arg}"` : arg));
  };
const logFinish = (methodName, name) => (result, time) => {
  Logger.of(methodName).info(`RESULT ${name}${time ? ` in ${time}` : ''}:`);
  Logger.of(methodName).info(result);
  Logger.of(methodName).info(`FINISHED ${name}.`);
};
const logError = (methodName, name) => (err) => {
  Logger.of(methodName).error(`ERROR ${name}: ${err.message || err}`);
};
const wrapFunction = (start, fn, finish, caught) => {
  if (fn.constructor.name === 'AsyncFunction') {
    return async (...args) => {
      const startTimestamp = new Date().getTime();
      start(...args);
      try {
        const result = await fn(...args);
        const endTimestamp = new Date().getTime();
        finish(result, `${(endTimestamp - startTimestamp) / 1000}s`);
        return result;
      } catch (err) {
        caught(err);
        return;
      }
    };
  }
  return (...args) => {
    start(...args);
    try {
      const result = fn(...args);
      finish(result);
      return result;
    } catch (err) {
      caught(err);
      return;
    }
  };
};

export default useActions;
export { ActionsContext };

import { useChatState, useSelectedRoomState } from '@/hooks/useSocket/chat/useChatRooms';

import ChatListHeader from '@/components/ChatWidget/ChatList/ChatListHeader';
import { ListGroup } from 'react-bootstrap';
import { getClasses } from '@/utils';

const MessageCenterChatList = () => {
  const rooms = useChatState(({ state: { rooms } }) => rooms);
  const [selectedRoom, setSelectedRoomState] = useSelectedRoomState(({ state: { room }, setState }) => [room, setState]);
  return (
    <ListGroup variant="flush">
      {Object.entries(rooms).map(([roomId, room], i) => {
        return (
          <ListGroup.Item
            className={getClasses('MessageCenterItem', selectedRoom?.id === roomId ? 'selected' : undefined)}
            key={i}
            action
            onClick={() => {
              setSelectedRoomState({ room: { ...room, id: roomId } });
            }}
          >
            <ChatListHeader.MessageCenter
              roomId={room?.id}
              displayImageUrl={room?.displayImageUrl}
              servicerIataAirlineCode={room?.trip?.servicerIataAirlineCode}
              scheduledTime={room?.trip?.tripScheduled}
              name={room?.displayName}
              unread={room?.unreadCount}
              latestMessage={room?.latestMessage}
            />
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default MessageCenterChatList;

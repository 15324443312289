import { Button, Col, Row } from 'react-bootstrap';
import { Group, Role, SortDirectionEnum } from 'models/gen/graphql';
import { QueryInputType, onEnter, queryInput, stringify } from '../../utils';
import React, { useMemo } from 'react';
import { updateGroupPermissions, updateRolePermissions } from '../../api/services/permissions';

import EditGroupsModal from './EditGroupsModal';
import EditPermissionsModal from './EditPermissionsModal';
import Filters from '../../components/Filters';
import FormField from '../../components/FormField';
import { LoadingBlur } from 'components/LoadingSpinner';
import PermissionsCard from '../../components/PermissionsCard';
import deleteGroupBulk from '../../api/services/groups/deleteGroupBulk';
import useForm from 'hooks/useForm';
import { useSearchGroups } from '../../api/services/groups/searchGroups';
import { useSearchRoles } from '../../api/services/roles/searchRoles';

type RoleTableState = {
  createGroup: boolean;
  search: string;
  selected: { [id: string]: Role | Group };
  editGroupPermissions: boolean;
  editRolePermissions: boolean;
};

const defaultQuery = {
  name: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc),
};

const initRoleTableState: RoleTableState = {
  createGroup: false,
  search: '',
  selected: undefined,
  editGroupPermissions: false,
  editRolePermissions: false,
};
const RolesTable = (): JSX.Element => {
  const [state, onChange, setState] = useForm(initRoleTableState);
  const { search, createGroup, selected, editGroupPermissions, editRolePermissions } = state;

  const [{ data: roleConnectionDetail, loading: loadingSearchRoles }, { fetch: searchRoles, refetch: refetchRoles }] = useSearchRoles();
  const [{ data: groupConnectionDetail, loading: loadingSearchGroups }, { fetch: searchGroups, refetch: refetchGroups }] =
    useSearchGroups();
  const { rows: roles = [] } = roleConnectionDetail || {};
  const { rows: groups = [] } = groupConnectionDetail || {};
  const loading = loadingSearchRoles || loadingSearchGroups;

  const onToggle =
    (key: string): any =>
    (): void =>
      setState((current: RoleTableState): RoleTableState => ({ ...current, [key]: !current[key] }));

  const onHide = async (): Promise<void> => {
    onSubmit();
    setState(
      (current: RoleTableState): RoleTableState => ({
        ...initRoleTableState,
        search: current.search,
      })
    );
  };

  const onSubmit = async (): Promise<void> => {
    await refetchGroups([defaultQuery]);
    await refetchRoles([defaultQuery]);
  };
  const onReset = (): void => {
    setState((current: RoleTableState): RoleTableState => ({ ...current, search: '' }));
    onSubmit();
  };
  const onBlur = (event) => {
    const { name, value } = event.target;
    setState((current: RoleTableState): RoleTableState => ({ ...current, [name]: value }));
    onSubmit();
  };

  const filteredRoles = useMemo(
    (): Role[] =>
      search
        ? (roles || []).filter(
            (role: Role): boolean =>
              !!stringify(role || {})
                .toLowerCase()
                .includes(search.toLowerCase())
          )
        : roles,
    [search, roles]
  );
  const filteredGroups = useMemo(
    (): Group[] =>
      search
        ? (groups || []).filter(
            (group: Group): boolean =>
              !!stringify(group || {})
                .toLowerCase()
                .includes(search.toLowerCase())
          )
        : groups,
    [search, groups]
  );

  return (
    <>
      <div className="d-flex flex-column gap-4 bg-glass p-5">
        <Filters
          name="roleFilters"
          onSubmit={onSubmit}
          onReset={onReset}
          submitOnMount
          primary={({ values, onChange }): JSX.Element => {
            const { search } = values;
            return (
              <>
                <FormField
                  name="search"
                  onChange={onChange}
                  onBlur={onBlur}
                  onKeyDown={onEnter(onBlur)}
                  value={search || ''}
                  placeholder="Search"
                  condensed
                  style={{ width: 300 }}
                />
              </>
            );
          }}
        />
        <LoadingBlur size="xl" loading={loading} />
        <Row className="g-4 {position:relative!;}">
          {/* Roles section */}
          <Col xs={12} className="d-flex justify-content-between flex-nowrap">
            <h2 className="d-inline">Roles</h2>
            <Button
              variant=""
              className="d-flex justify-content-between flex-nowrap align-items-center"
              name="CREATE_ROLE"
              onClick={onToggle('createGroup')}
            >
              <span className="d-flex justify-content-center align-items-center p-1 me-3 border border-success rounded-1 me-1">
                <i className="fa fa-plus text-success" />
              </span>
              Create New Role
            </Button>
          </Col>
          {!(filteredGroups || []).length && <p className="lead">No Roles found.</p>}
          {(filteredGroups || []).map(
            (group: Group, g: number): JSX.Element => (
              <Col xs={12} sm={6} md={4} lg={3} key={g}>
                <PermissionsCard
                  name={group?.name || '-'}
                  title={'Role'}
                  permissions={group?.permissions || []}
                  onSetting={(): void => {
                    setState(
                      (current: RoleTableState): RoleTableState => ({
                        ...current,
                        editRolePermissions: false,
                        editGroupPermissions: true,
                        selected: { [group?.id]: group },
                      })
                    );
                  }}
                  onDelete={async (): Promise<void> => {
                    await deleteGroupBulk([group?.id]);
                    await refetchGroups();
                  }}
                />
              </Col>
            )
          )}
        </Row>
      </div>
      <EditGroupsModal title="Create Role" show={createGroup} onHide={onHide} />
      <EditPermissionsModal
        show={editRolePermissions || editGroupPermissions}
        data={selected ? Object.values(selected)[0] : undefined}
        onHide={onHide}
        onSubmit={async (data: { creates: string[]; deletes: string[] }): Promise<void> => {
          const id = Object.keys(selected)[0];
          if (editRolePermissions) await updateRolePermissions(data, id);
          else if (editGroupPermissions) await updateGroupPermissions(data, id);
          onHide();
        }}
      />
    </>
  );
};

export default RolesTable;

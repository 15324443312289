import { GET_LICENSE_TYPES } from '../../queries/Filter';
import React from 'react';
import SelectFromQuery from '../SelectFromQuery';

const SelectLicenseType = (props) => (
  <SelectFromQuery
    cache="LicenseType"
    placeholder="Select Type"
    query={GET_LICENSE_TYPES}
    selector={(data) =>
      (data?.getFilters?.filters?.licenseType || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
      }))
    }
    {...props}
  />
);

export default SelectLicenseType;

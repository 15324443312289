import { DeleteTripBulkInput } from '../../models/gen/graphql';
import { GraphApi } from '../../models';
import { customDeleteHandler } from 'utils';
import gql from 'graphql-tag';

const deleteTripBulk: GraphApi.Query.Bundle = {
  wrapper:
    (fireQuery: GraphApi.Query.Execute): any =>
    async (comment: string, ...ids: string[]): Promise<any> => {
      const input: DeleteTripBulkInput = {
        search: ids.map((id: string): { id: string } => ({ id })),
        comment: comment ? comment : null,
      };

      return fireQuery({
        variables: { input },
      });
    },
  selector: (data: any): number => data?.deleteTripBulk?.deleted || 0,
  handler: customDeleteHandler('Delete Trip', 'trip', 'deleteTripBulk'),
  query: gql`
    mutation DeleteTripBulk($input: DeleteTripBulkInput!) {
      deleteTripBulk(input: $input) {
        deleted
      }
    }
  `,
};

export default deleteTripBulk;

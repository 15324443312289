import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { CreateUserAvailabilityInput, User, UserAvailability, UserAvailabilityTypeEnum } from 'models/gen/graphql';
import { Datetime, Validation } from '../../utils';
import React, { useEffect, useState } from 'react';
import { createUserAvailabilityValidator, handleCreateAvailability } from '../../api/services/users/createUserAvailabilityBulk';
import { handleApproveUserAvailability, handleDenyUserAvailability } from '../../api/services/users/approveUserAvailability';
import { handleUpdateAvailability, handleUpdateAvailabilitySeries } from '../../api/services/users/updateUserAvailabilityBulk';

import ConfirmationButton from '../ConfirmationButton';
import EditAvailabilityHeader from './EditAvailabilityHeader';
import EditPopover from 'components/EditPopover';
import FormField from 'components/FormField';
import Logger from '../../utils/logs';
import SelectActiveStatus from 'components/SelectActiveStatus';
import SelectAvailabilityType from '../SelectAvailabilityType';
import SelectFromEnum from '../SelectFromEnum';
import useForm from '../../hooks/useForm';
import useValidation from '../../hooks/useValidation';

const log = Logger.of('EditAvailabilityPopover');

interface Props {
  title?: string;
  date: string;
  onSubmit?: any;
  onHide: any;
  onCancel: () => void;
  data: { availability?: UserAvailability; user: User };
  onDelete: any;
  children: any;
}
type State = Partial<UserAvailability> & CreateUserAvailabilityInput & { repeat: 0 | 1 | boolean };

const EditAvailabilityPopover = ({ title, data, date, onDelete, onSubmit, onHide, onCancel, children }: Props): JSX.Element => {
  const [show, setShow] = useState(undefined);
  // init state
  const [form, onChange, setForm] = useForm<State>(undefined);
  const [isValid, validity] = useValidation(createUserAvailabilityValidator, form);

  const { availability, user } = data;
  const hasSeries = !!availability?.repeatUntil;
  const mode = availability?.id ? 'edit' : 'create';
  const isRequest = mode === 'edit' && Validation.isNil(availability?.approved);

  const setAvailabilityToForm = (): void => {
    if (availability) {
      setForm({
        ...availability,
        repeat: !!availability?.repeatUntil,
      });
    } else {
      const newDate = new Datetime().setDate(date);
      setForm({
        startDate: newDate.dateInput,
        startTime: undefined,
        endDate: newDate.dateInput,
        endTime: undefined,
        dayOfWeek: newDate.day,
        availabilityType: '',
        repeat: 0,
        reason: '',
        userId: user?.id,
      });
    }
  };

  useEffect((): void => {
    setAvailabilityToForm();
  }, [data]);

  useEffect((): (() => void) => {
    setShow(undefined);
    return (): void => {
      setShow(undefined);
    };
  });

  const handleHide = (): void => {
    setShow(false);
    onHide();
    setAvailabilityToForm();
  };
  const handleCancel = (): void => {
    setShow(false);
    onCancel();
    setAvailabilityToForm();
  };

  // handler func
  const handleSubmit = async (): Promise<void> => {
    try {
      if (onSubmit) await onSubmit(form);
      else if (mode === 'edit') {
        const updateUserAvailabilityPayload: UserAvailability = {
          startDate: form?.startDate,
          startTime: Validation.isDate(form?.startTime) ? new Datetime(form?.startTime).fullTime : form?.startTime,
          endDate: form?.endDate,
          endTime: Validation.isDate(form?.endTime) ? new Datetime(form?.endTime).fullTime : form?.endTime,
          repeatUntil: form?.repeatUntil,
          dayOfWeek: form?.dayOfWeek,
          type: form?.type,
          reason: form?.reason,
          userId: form?.userId,
          id: form?.id,
          approved: form?.approved,
        };
        // call update
        await handleUpdateAvailability(updateUserAvailabilityPayload, availability);
      } else {
        // call create
        const createUserAvailabilityPayload: CreateUserAvailabilityInput = {
          startDate: form.startDate,
          startTime: Validation.isDate(form.startTime) ? new Datetime(form.startTime).fullTime : form.startTime,
          endDate: form.endDate,
          endTime: Validation.isDate(form.endTime) ? new Datetime(form.endTime).fullTime : form.endTime,
          repeatUntil: !!form?.repeat && form.repeatUntil ? form.repeatUntil : null,
          dayOfWeek: form.dayOfWeek,
          availabilityType: form.availabilityType,
          reason: form.reason,
          userId: form.userId,
        };
        await handleCreateAvailability(createUserAvailabilityPayload);
      }
    } catch (err) {
      log.error('handleSubmit', err.message || err);
    } finally {
      handleHide();
    }
  };
  const handleDenyAvailability = async (): Promise<void> => {
    try {
      if (!isRequest) throw new Error('Unable to deny non availability requests.');
      await handleDenyUserAvailability([availability?.id]);
    } catch (err) {
      const message = err.message || err;
      log.error('handleDenyAvailability', message).notify({ title: 'Deny User Availability', message });
    } finally {
      handleHide();
    }
  };
  const handleApproveAvailability = async (): Promise<void> => {
    try {
      if (!isRequest) throw new Error('Unable to approve non availability requests.');
      await handleApproveUserAvailability([availability?.id]);
    } catch (err) {
      const message = err.message || err;
      log.error('handleApproveAvailability', message).notify({ title: 'Approve User Availability', message });
    } finally {
      handleHide();
    }
  };
  const handleUpdateSeries = async (): Promise<void> => {
    try {
      const updateSeriesPayload: UserAvailability = {
        startDate: form?.startDate,
        startTime: Validation.isDate(form?.startTime) ? new Datetime(form?.startTime).fullTime : form?.startTime,
        endDate: form?.endDate,
        endTime: Validation.isDate(form?.endTime) ? new Datetime(form?.endTime).fullTime : form?.endTime,
        repeatUntil: form?.repeatUntil,
        dayOfWeek: form?.dayOfWeek,
        type: form?.type,
        reason: form?.reason,
        userId: form?.userId,
        id: form?.id,
        approved: form?.approved,
      };
      if (onSubmit) await onSubmit(updateSeriesPayload);
      else if (mode === 'edit') await handleUpdateAvailabilitySeries(updateSeriesPayload, availability);
    } catch (err) {
      log.error('handleUpdateSeries', err.message || err);
    } finally {
      handleHide();
    }
  };
  const handleDelete = (): void => {
    handleCancel();
    onDelete(availability?.id);
  };

  return (
    <EditPopover
      show={show}
      target={children}
      title={title || ''}
      size="xl"
      onHide={handleCancel}
      onSubmit={undefined}
      loading={false}
      name="editAvailability"
      options={{
        Header: <EditAvailabilityHeader data={user} />,
      }}
    >
      <Row className="EditAvailabilityPopover ">
        <Col>
          {/* Time Inputs */}
          <Row xs={2}>
            <Col>
              <FormField
                label="START TIME:"
                type="time"
                name="startTime"
                onChange={onChange.time}
                value={Validation.isDate(form?.startTime) ? new Datetime(form?.startTime).fullTime : form?.startTime}
                valid={validity?.startTime?.valid}
                disabled={isRequest}
                condensed
              />
            </Col>
            <Col>
              <FormField
                label="END TIME:"
                type="time"
                name="endTime"
                onChange={onChange.time}
                value={Validation.isDate(form?.endTime) ? new Datetime(form?.endTime).fullTime : form?.endTime}
                valid={validity?.endTime?.valid}
                disabled={isRequest}
                condensed
              />
            </Col>
          </Row>
          {/* Repeat Inputs */}
          <Row xs={2}>
            <Col>
              <FormField
                label="REPEAT:"
                name="repeat"
                onChange={onChange}
                value={form?.repeat}
                disabled={isRequest || mode === 'edit'}
                placeholder={'Repeat?'}
                statusType="repeat"
                options={{ input: { as: SelectActiveStatus } }}
                condensed
              />
            </Col>
            <Col>
              <FormField
                label="REPEAT UNTIL DATE:"
                type="date"
                name="repeatUntil"
                onChange={onChange.date}
                disabled={!form?.repeat || isRequest || mode === 'edit'}
                value={form?.repeatUntil || ''}
                valid={validity?.repeatUntil?.valid}
                condensed
              />
            </Col>
          </Row>
          <Row xs={2}>
            {/* Request Type Input */}
            <Col>
              {mode === 'edit' ? (
                <FormField
                  label="REQUEST TYPE:"
                  name="type"
                  onChange={(event): void => {
                    return event?.target?.value ? onChange(event) : undefined;
                  }}
                  disabled={true}
                  value={form?.type || ''}
                  valid={validity?.type?.valid}
                  source={UserAvailabilityTypeEnum}
                  getLabel={(key: string) => key.toUpperCase()}
                  placeholder="Request Type"
                  options={{ input: { as: SelectFromEnum } }}
                  condensed
                />
              ) : (
                <FormField
                  label="REQUEST TYPE:"
                  name="availabilityType"
                  onChange={(event): void => {
                    return event?.target?.value ? onChange(event) : undefined;
                  }}
                  valid={!!form?.availabilityType}
                  value={form?.availabilityType || ''}
                  placeholder="Request Type"
                  options={{ input: { as: SelectAvailabilityType } }}
                  condensed
                />
              )}
            </Col>
            {/* Reason Input */}
            <Col>
              <FormField
                label="REASON:"
                name="reason"
                onChange={onChange}
                valid={validity?.type?.valid}
                value={form?.reason || ''}
                disabled={isRequest || (mode === 'edit' && availability?.approved === 1)}
                placeholder="Reason"
                maxLength={128}
                condensed
              />
            </Col>
          </Row>
          {!isRequest && (
            <>
              {/* Buttons */}
              <Row xs={2} className="flex-wrap gy-3">
                {/* Save Button */}
                <Col xs={mode === 'create' ? 12 : undefined}>
                  <Button variant="primary" className="shadow w-100" name="SUBMIT" onClick={handleSubmit} disabled={!isValid}>
                    Save
                  </Button>
                </Col>
                {/* Edit Series Button */}
                {hasSeries && (
                  <Col>
                    <Button
                      variant="primary"
                      className="shadow w-100 "
                      name="UPDATE_SERIES"
                      onClick={handleUpdateSeries}
                      disabled={!isValid}
                    >
                      Save Series
                    </Button>
                  </Col>
                )}
                {/* Delete Button */}
                {mode === 'edit' && (
                  <Col xs={hasSeries ? 12 : { span: 6, order: 'first' }}>
                    <Button variant="red" className="shadow w-100 " name="DELETE" onClick={handleDelete}>
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
          {isRequest && (
            <Row xs={1} className="flex-wrap gy-3">
              <Col>
                <ConfirmationButton variant="red" className="shadow w-100" name="DENIED" onConfirm={handleDenyAvailability}>
                  Denied
                </ConfirmationButton>
              </Col>
              {/* Approve Button */}
              <Col>
                <Button variant="success" className="shadow w-100" name="APPROVE" onClick={handleApproveAvailability}>
                  Approved
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </EditPopover>
  );
};

export default React.memo(EditAvailabilityPopover);
